import React from "react";
import ReactDOM from "react-dom";
import { StrictMode } from 'react';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { unregister } from "./registerServiceWorker";

import App from "./App";
import PiwikTracker from "./components/PiwikTracker";
import "./components/Storage";

import config from "./api/config";

import "./style.scss";

const piwik = PiwikTracker({
  url: config.TRACKING.url,
  clientTrackerName: config.TRACKING.script,
  siteId: config.TRACKING.siteId,
});

const history = createBrowserHistory();

ReactDOM.render(
  <StrictMode>
    <Router history={piwik.connectToHistory(history)}>
      <App piwik={piwik} />
    </Router>
  </StrictMode>,
  document.getElementById("root")
);

unregister();
