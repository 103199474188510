import config from './config';
import { getAccessToken } from './user';


const resetTest = cb => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/reset-test`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export { resetTest };
