import React, { Component } from 'react';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getGTC } from '../../api/documents';


class GTC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gtc_html: undefined
    };

    this.getDocument = this.getDocument.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading General Terms & Conditions...",
        notFound: "No document found.",
        backToAgreements: "Back to agreements"
      },
      nl: {
        loading: "Algemene algemene voorwaarden laden ...",
        notFound: "Geen document gevonden.",
        backToAgreements: "Terug naar overeenkomsten"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.getDocument();
  }

  getDocument() {
    if (!this.props.selectedCompany) return;

    getGTC(this.props.lang, (err, res) => {
      if (err)
        return window.reportError.send(err);

      if (res.error)
        this.setState({ error: this.strings.notFound })
      else this.setState({
        gtc_html: res || { contract_html: 'about:blank' }
      }, () => {
        window.frames.gtc.document.open('text/html', 'replace')
        window.frames.gtc.document.write(this.state.gtc_html)
        window.frames.gtc.document.close()
      }
      )
    })
  }

  render() {
    if (!this.state.gtc_html) {
      return (<Loader>{this.strings.loading}</Loader>);
    }
    return (
      <div className="row">
        <div className="col-sm p-0">
          <iframe name="gtc" id="gtc" width="100%" height="800px" title="gtc"></iframe>
        </div>
      </div>
    );
  }
}

export default GTC;
