import React, { Component } from 'react';
import { triggerAggregation } from '../../api/eans';
import { Typeahead } from 'react-bootstrap-typeahead';

import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone'

import 'moment/locale/nl-be';
import 'react-day-picker/lib/style.css'

moment.tz.setDefault('Europe/Brussels')


class TriggerMvAggregation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sdps: [],
      selectedEan: undefined,
      selectedStartDate: undefined,
      selectedEndDate: undefined
    }

    this.onSelect = this.onSelect.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.triggerAgg = this.triggerAgg.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading EANs...",
        noSdp: "The company doesn't have any SDPs.",
        emptyInfo: "If you leave the EAN and dates field empty it will trigger aggregation for all eans and dates. You can combine ean with empty dates or empty ean with a date range.",
        triggerMvAggregation: "Retrigger Meter Values Sharding and Aggregation",
        period: "Period:",
        from: "From:",
        to: "To:",
        aggregate: "Start",
        confirmAggregation: "Confirm triggering of sharding and aggregation process",
        confirmAggregationDescription: "You are about to trigger sharding and aggregation process for the selected ean and dates.",
        cancel: "Cancel",
        triggered: "You've successfully triggered sharding and aggregation process",
        noContract: "No contract",
        signed: "Contract signed",
        unsigned: "Contract not signed"
      },
      nl: {
        loading: "EANs laden...",
        noSdp: "Het bedrijf heeft geen SDPs.",
        emptyInfo: "Als u het veld EAN en datums leeg laat, wordt de aggregatie van alle eans en datums geactiveerd. U kunt ean combineren met lege datums of lege ean met een datumbereik.",
        triggerMvAggregation: "Herhalen van sharding en aggregatie van meetwaarden",
        period: "Periode:",
        from: "Van:",
        to: "Naar:",
        aggregate: "Starten",
        confirmAggregation: "Confirm triggering of sharding en aggregatieproces",
        confirmAggregationDescription: "U staat op het punt het sharding en aggregatieproces te activeren voor de geselecteerde ean en datums.",
        cancel: "Annuleren",
        triggered: "U heeft het sharding en aggregatieproces met succes geactiveerd",
        noContract: "Geen contract",
        signed: "Contract ondertekend",
        unsigned: "Contract niet getekend"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.sdps)
      return {
        sdps: nextProps.sdps.sort((x, y) => (!x.hasContract || x.hasContract < y.hasContract) ? 1 : -1)
      }
    else return null
  }

  onSelect(selection) {
    this.setState({
      selectedEan: selection[0] ? selection[0].ean : undefined
    })
  }

  onDateChange(date, id) {
    if (id === 'startDate')
      this.setState({
        selectedStartDate: date
      })
    else if (id === 'endDate')
      this.setState({
        selectedEndDate: date
      })
  }

  triggerAgg() {

    const ean = this.state.selectedEan || null
    let dates = null

    let startDate = this.state.selectedStartDate ? moment(this.state.selectedStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
    let endDate = this.state.selectedEndDate ? moment(this.state.selectedEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : null

    if (startDate || endDate) {

      dates = []

      if (startDate)
        dates.push(startDate)

      if (endDate)
        dates.push(endDate)
    }

    triggerAggregation(ean, dates, (err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        triggered: true,
        selectedEan: undefined,
        selectedStartDate: undefined,
        selectedEndDate: undefined
      });

      setTimeout(() => {
        this.setState({ triggered: undefined })
      }, 5000);
    });
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.triggerMvAggregation}
        </div>
        <div className="card-body">
          <p className="alert alert-info">
            <i className="fas fa-info-circle mr-2"></i> {this.strings.emptyInfo}
          </p>
          <div className="form-group row">
            <div className="col-sm-3">
              <label htmlFor="eans" className="col-form-label">EAN(s):</label>
            </div>
            <div className="col-sm-9">
              {
                !this.props.sdps ?
                  <Loader inline="true">{this.strings.loading}</Loader>
                  :
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                    </p>
                    :
                    <div className="special-select">
                      <Typeahead
                        id="eansSelector"
                        options={this.state.sdps}
                        labelKey={x => `${x.ean} (${x.installation_ids || x.installation_id})`}
                        onChange={this.onSelect}
                        selected={this.state.sdps.filter(x => x.ean === this.state.selectedEan)}
                      />
                    </div>
              }
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-3">
              <label htmlFor="eans" className="col-form-label">{this.strings.period}</label>
            </div>
            <div className="col-sm-9">
              <div className="row">
                <div className="col-sm-6">
                  <label>{this.strings.from}</label>
                  <DayPickerInput
                    value={this.state.selectedStartDate || ''}
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    formatDate={(date, format) => { return moment(date).format(format) }}
                    inputProps={{ className: 'form-control', id: 'startDate', name: 'shard_agg_from', readOnly: true }}
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                      locale: this.props.lang
                    }}
                    onDayChange={(date) => this.onDateChange(moment(date).format("DD-MM-YYYY"), 'startDate')}
                  />
                </div>
                <div className="col-sm-6">
                  <label>{this.strings.to}</label>
                  <DayPickerInput
                    value={this.state.selectedEndDate || ''}
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    formatDate={(date, format) => { return moment(date).format(format) }}
                    inputProps={{ className: 'form-control', id: 'endDate', name: 'shard_agg_to', readOnly: true }}
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                      locale: this.props.lang
                    }}
                    onDayChange={(date) => this.onDateChange(moment(date).format("DD-MM-YYYY"), 'endDate')}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            this.state.triggered ?
              <p className="alert alert-info mt-2">
                <i className="fas fa-info-circle mr-2"></i> {this.strings.triggered}
              </p>
              : null
          }
          <button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#confirm-aggregation">{this.strings.aggregate}</button>
        </div>

        <div className="modal fade" id="confirm-aggregation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"><i className="fas fa-exclamation-triangle text-primary mr-2"></i>{this.strings.confirmAggregation}</h5>
              </div>
              <div className="modal-body">
                <p>
                  {this.strings.confirmAggregationDescription}
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.triggerAgg}>{this.strings.aggregate}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TriggerMvAggregation;
