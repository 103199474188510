import React, { Component } from 'react';
import LocalizedStrings from '../components/Localization';
import SubHeader from '../components/SubHeader';

import { unsubscribe, resubscribe } from '../api/user';


class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      status: {}
    }

    this.reSubscribe = this.reSubscribe.bind(this)

    this.strings = LocalizedStrings({
      en: {
        loading: "Unsubscribing....",
        error: "An error occurred, please try again.",
        wrongLink: "Wrong unsubscription link. Please contact us at support@yuso.be",
        subscription: "Subscription",
        email: "Your email",
        successfully: "has been successfully",
        unsubscribed: "unsubscribed from daily pricing emails.",
        resubscribed: "re-subscribed for daily pricing emails.",
        unsubscribedAccident: "Unsubscribed by accident?",
        reSubscribe: "Resubscribe",
        resubscribeAgain: "You can resubscribe again on",
        thisPage: "this page"
      },
      nl: {
        loading: "Afmelden...",
        subscription: "Subscription",
        error: "Er is een fout opgetreden, probeer het opnieuw.",
        wrongLink: "Verkeerde afmeldlink. Neem contact met ons op via support@yuso.be",
        email: "Jouw email",
        successfully: "is succesvol",
        unsubscribed: "afgemeld voor dagelijkse prijs emails.",
        resubscribed: "opnieuw ingeschreven voor dagelijkse prijs emails.",
        unsubscribedAccident: "Afgemeld per ongeluk?",
        reSubscribe: "Opnieuw aanmelden",
        resubscribeAgain: "U kunt zich opnieuw inschrijven op",
        thisPage: "deze pagina"
      }
    }, this.props.lang);
  }

  componentDidMount() {

    unsubscribe(this.props.email, this.props.hash,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          if (res.error.wrong_link)
            this.setState({ error: this.strings.wrongLink })
          else
            this.setState({ error: this.strings.error })
        }
        else {
          this.setState({ status: res.status })
        }
      }
    )
  }

  reSubscribe() {

    resubscribe(this.props.email, this.props.hash,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          if (res.error.wrong_link)
            this.setState({ status: {}, error: this.strings.wrongLink })
          else
            this.setState({ status: {}, error: this.strings.error })
        }
        else {
          this.setState({ error: undefined, status: res.status })
        }
      }
    )
  }

  render() {
    return (
      <div>
        <SubHeader name={this.strings.subscription} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            {
              !this.state.error && !this.state.status ?
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <i className="fas fa-spinner fa-spin text-muted loader" />
                  <h4 className="text-muted font-weight-light my-3">
                    {this.strings.loading}
                  </h4>
                </div>
              : null
            }
            {
              this.state.error ?
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <h4 className="text-muted font-weight-light my-3 text-center">
                    {this.state.error}
                  </h4>
                </div>
              : null
            }
            {
              this.state.status.unsubscribe ?
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <h4 className="text-muted font-weight-light my-3 text-center">
                    {this.strings.email} {this.props.email} {this.strings.successfully}
                    <br />
                    {this.strings.unsubscribed}
                  </h4>

                  <p className="mt-4 text-center">
                    {this.strings.unsubscribedAccident}
                    <br/>
                    {this.strings.resubscribeAgain} <a href="/dashboards" className="text-primary">{this.strings.thisPage}</a>.
                  </p>
                  {/* <button type="button" className="btn btn-primary btn-lg" onClick={this.reSubscribe}>{this.strings.reSubscribe}</button> */}
                </div>
                : null
            }
            {
              this.state.status.resubscribe ?
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <h4 className="text-muted font-weight-light my-3 text-center">
                    {this.strings.email} {this.props.email} {this.strings.successfully}
                    <br />
                    {this.strings.resubscribed}
                  </h4>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Unsubscribe;
