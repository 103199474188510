import React, { Component } from 'react';
import { CtaPanel } from '../../components/CallToAction';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import Notifications from '../../components/Notifications';
import LocalizedStrings from '../../components/Localization';

import { getUserCompanies } from '../../api/user';

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: []
    }

    this.timer = null;

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        portal: "to the My Yuso Portal,",
        details: "check your user details below.",
        user: "User information",
        logout: "Logout",
        contact: "Wrong user details? Contact us at",
        user2: "User can",
        access: "access",
        the: "the",
        companies: "companies",
        below: "below",
        newCompany: "Want to add new company? Contact us at",
        fetching: "Fetching companies..."
      },
      nl: {
        welcome: "Welkom",
        portal: "naar de My Yuso Portal,",
        details: "controleer hieronder jouw gebruikersgegevens.",
        user: "Gebruikers informatie",
        logout: "Uitloggen",
        contact: "Verkeerde gebruikersgegevens? Contacteer ons op",
        user2: "Gebruiker heeft",
        access: "toegang",
        the: "tot de",
        companies: "bedrijven",
        below: "hieronder",
        newCompany: "Wil je een nieuw bedrijf toevoegen? Contacteer ons op",
        fetching: "Bedrijven ophalen..."
      }
    }, this.props.lang);
  }

  componentDidMount() {

    this.timer = setTimeout(() => {

      getUserCompanies(
        (err, res) => {
          if (err)
            return window.reportError.send(err);

          this.setState({ companies: res });
        }
      );
    }, 2000);
  }

  componentWillUnmount() {

    if (this.timer)
      clearTimeout(this.timer)
  }

  render() {
    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-lg-7 px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.portal}<br />
              {this.strings.details}
            </div>
            <table className="table mb-5">
              <thead>
                <tr>
                  <th className="clearfix">{this.strings.user} <span className="text-info font-weight-light text-underline cursor-pointer float-right" onClick={this.props.onLogout}>{this.strings.logout}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.selectedCompany ? this.props.selectedCompany.firstName + ' ' + this.props.selectedCompany.lastName : 'N/A'}</td>
                </tr>
                <tr>
                  <td>
                    <a className="text-lowercase" href={`mailto:${this.props.user.username}`}>{this.props.user.username}</a>
                  </td>
                </tr>
                <tr>
                  <td>Role: {this.props.selectedCompany.role}</td>
                </tr>
                <tr>
                  <td className="bg-white text-muted pt-3"><small>{this.strings.contact} <a href="mailto:info@yuso.be">info@yuso.be</a></small></td>
                </tr>
              </tbody>
            </table>

            <hr className="border-warning" />

            <h4 className="text-primary font-weight-light mt-5 mb-4">{this.strings.user2} <strong>{this.strings.access}</strong> {this.strings.the} <strong>{this.strings.companies}</strong> {this.strings.below}</h4>
            {
              this.state.companies.length ?
                <table className="table table-striped table-sm">
                  <tbody>
                    {
                      this.state.companies && this.state.companies.map(
                        (company) => {
                          return (
                            <tr key={company.companyNumber}>
                              <td>{company.companyName}</td>
                            </tr>
                          );
                        }
                      )
                    }
                    <tr>
                      <td colSpan="2" className="bg-white text-muted pt-3"><small>{this.strings.newCompany} <a href="mailto:info@yuso.be">info@yuso.be</a></small></td>
                    </tr>
                  </tbody>
                </table> : <Loader inline="true">{this.strings.fetching}</Loader>
            }
          </div>
          <div className="col-lg-5 px-2 px-xl-5 py-3">
            <Notifications lang={this.props.lang} className="my-4 ml-4" />
            <CtaPanel
              className={'my-4 ml-4 ' + (!this.props.info.sdps.has_sdps ? 'd-none' : '')}
              link="/services#flexibility"
              type="request_flexibility"
              lang={this.props.lang}
              buttonClassName="w-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetails;
