import React, { Component } from 'react';

class SubHeader extends Component {
  render() {
    return (
      <div className="row section-header px-2 px-xl-5 pb-3" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/${this.props.image || this.props.type}.jpg')` }}>
        <img
          className={this.props.hideIcon ? 'd-none': ''}
          src={`${process.env.PUBLIC_URL}/${this.props.image || this.props.type}.png`}
          alt={this.props.name}
        />
        {this.props.name}
      </div>
    );
  }
}

export default SubHeader;
