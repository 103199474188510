import React, { Component } from 'react';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import { getBSI } from '../../api/documents';

class BSI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bsi: undefined
    };

    this.getBSI = this.getBSI.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading BSI PDF...",
        notFound: "No BSI PDF found."
      },
      nl: {
        loading: "BSI PDF laden ...",
        notFound: "Geen BSI PDF gevonden."
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.getBSI();
  }
  getBSI() {

    getBSI(this.props.lang, (err, res) => {
      if (err)
        return window.reportError.send(err);
  
      if (res.error)
        this.setState({ error: this.strings.notFound })
      else {
        this.setState({ bsi: res || 'about:blank'
        });
      }
    });
  }

  render() {
    if (!this.state.bsi) {
      return (<Loader>{this.strings.loading}</Loader>);
    }
    return (
      <div className="row">
        <div className="col-sm p-0" >
          <iframe name="bsi" id="bsi" width="100%" height="800px" title="bsi" src={this.state.bsi}></iframe>
        </div>
      </div>
    );
  }
}

export default BSI;