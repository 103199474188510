import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea } from 'recharts';
import moment from 'moment-timezone'
import { ContractAreaLineAndLabel } from './ContractAreaLineAndLabel';
import LocalizedStrings from '../../components/Localization';

moment.tz.setDefault('Europe/Brussels')

class ChartPL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addWidth: 0
        }

        this.tickFormatter = this.tickFormatter.bind(this)
        this.labelFormatter = this.labelFormatter.bind(this)
        this.tooltipFormatter = this.tooltipFormatter.bind(this)

        this.strings = LocalizedStrings({
            en: {
                total: "Total amount",
                tooltipLabelHour: "Hour",
                tooltipLabelDay: "Day",
                tooltipLabelMonth: "Month"
            },
            nl: {
                total: "Totaal bedrag",
                tooltipLabelHour: "Uur",
                tooltipLabelDay: "Dag",
                tooltipLabelMonth: "Maand"
            }
        }, this.props.lang);
    }

    componentDidMount() {

        setTimeout(() => {

            let addWidth = 0

            let chart_width = window.document.querySelector('.pl-chart .recharts-cartesian-grid')
            let area_width = window.document.querySelector('.pl-chart .recharts-reference-area')

            if (chart_width && area_width) {
                chart_width = chart_width.getBoundingClientRect().width
                area_width = area_width.getBoundingClientRect().width

                addWidth = (chart_width - area_width) || 0
            }

            if (addWidth > 0) {
                this.setState({
                    addWidth
                })
            }
        }, 0);
    }

    tickFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.metervalues[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                break;

            case 'M':
                format = 'YYYY-MM'
                break;

            default:
                break;
        }

        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    labelFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.metervalues[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        let tooltipLabel = this.strings.tooltipLabelHour
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                tooltipLabel = this.strings.tooltipLabelDay
                break;

            case 'M':
                format = 'YYYY-MM'
                tooltipLabel = this.strings.tooltipLabelMonth
                break;

            default:
                break;
        }

        return tooltipLabel + ': ' + moment(value, 'YYYY-MM-DD HH:mm:ss').format(format) + (this.props.aggregation === 'W' ? ' – ' + moment(value, 'YYYY-MM-DD HH:mm:ss').add(6, 'days').format(format) : '')
    }

    tooltipFormatter(value) {
        return parseFloat(value).toFixed(3) // always profit/loss to 3 decimals
    }

    render() {
        if (!this.props.metervalues || !this.props.allSdps || !this.props.allSdps.length) {
            return <div />
        }

        if (!this.props.metervalues.length) {
            return <div />
        }

        let start = this.props.metervalues.find(x => x.dtlt === this.props.minContractDate + (this.props.aggregation === 'H' ? ' 23:00:00' : ' 00:00:00'))
        let index = this.props.metervalues.indexOf(start)

        let last = this.props.metervalues[this.props.metervalues.length - 1].dtlt

        if (moment(last, 'YYYY-MM-DD HH:mm:ss') < moment(this.props.minContractDate, 'YYYY-MM-DD'))
            index = this.props.metervalues.length - 1

        return (
            [
                <div className="row" key="0">
                    <div className="col-sm">
                        <h5 className="text-center text-info mb-3">{this.strings.total} (€) <sup>*</sup></h5>
                    </div>
                </div>,
                <div className="row mb-4" key="1">
                    <div className="col-sm" style={{ height: '300px' }}>
                        <ResponsiveContainer>
                            <BarChart data={this.props.metervalues} className="pl-chart">
                                <XAxis dataKey="index" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip labelFormatter={this.labelFormatter} formatter={this.tooltipFormatter} />
                                <Bar
                                    name={this.strings.total}
                                    dataKey="pl"
                                    unit=" €"
                                    fill="#dddc01"
                                />
                                <ReferenceArea
                                    x2={index}
                                    isFront={false} fill="#ccc"
                                    fillOpacity={0.25}
                                    label={<ContractAreaLineAndLabel
                                        data={this.props.metervalues}
                                        lang={this.props.lang}
                                        minContractDate={this.props.minContractDate}
                                        notStarted={this.props.notStarted}
                                        isBarChart={true}
                                        addWidth={this.state.addWidth}
                                        showContractLine={index !== this.props.metervalues.length - 1}
                                        aggregation={this.props.aggregation} />}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ]
        )
    }
}

export default ChartPL;
