import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LocalizedStrings from '../Localization';
import Loader from '../Loader';

import { sendMandate } from '../../api/user';
import { getSignedMandates } from '../../api/documents';
import { getMandateEANs } from '../../api/mandates'
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const unsupportedDGOs = ['Resa', 'Sibelga']


class SendMandate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEANs: [],
      mandateEANs: [],
      mandatePoints: [],
      mandateSentEANs: [], // used for already sent mandate EANs
      unsupportedDGOEANs: [], // used for the unsupported DGO EANs
      mandates: undefined,
      mandate_file: undefined
    }

    this.onEanChange = this.onEanChange.bind(this);
    this.onMandateChange = this.onMandateChange.bind(this);
    this.updateMandates = this.updateMandates.bind(this);
    this.onSendMandate = this.onSendMandate.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Mandate and EAN fields are required.",
        error: "Error while sending mandate. Please try again.",
        emailSent: "Mandate email was successfully sent!",
        mandate: "Send Signed Mandate to Fluvius",
        sendSignedMandate: "Send mandate e-mail",
        ean: "EAN",
        loadingEans: "Loading EANs...",
        mandateFile: "Mandate file",
        noMandates: "No mandates found",
        hasMetervalues: "There is already metervalues for the EAN(s) below:",
        mandateAlreadySent: "You've selected EAN(s) for which mandate is already sent to Fluvius:",
        unsupportedDGOs: "You've selected EAN(s) which belong to unsuppored DGO and mandate can't be send:",
        loadingMandates: "Loading mandate files...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        eans: "The following EANs are linked to mandate:",
        noMandateEANs: "No EANs linked with selected document, pleasee select EANs manually.",
        selectEANs: "Select EANs",
        addEans: "Add EANs to the list"
      },
      nl: {
        errorRequired: "Mandaat en EAN velden zijn vereist.",
        error: "Fout tijdens het verzenden van mandaat. Probeer het opnieuw.",
        emailSent: "Mandaat-e-mail is succesvol verzonden!",
        mandate: "Stuur een Ondertekend Mandaat naar Fluvius",
        sendSignedMandate: "Stuur mandaat e-mail",
        ean: "EAN",
        loadingEans: "EANs laden...",
        mandateFile: "Mandaatbestand",
        noMandates: "Geen mandaten gevonden",
        hasMetervalues: "Er zijn al meetwaarden voor de EAN(s) hieronder:",
        mandateAlreadySent: "U hebt EAN(s) geselecteerd waarvoor het mandaat al naar Fluvius is verzonden:",
        unsupportedDGOs: "U hebt EAN(s) geselecteerd die behoren tot niet-ondersteunde DGO en machtiging kan niet worden verzonden:",
        loadingMandates: "Mandaatbestanden laden...",
        noSdp: "Het bedrijf heeft geen SDPs.",
        addSdp: "Voeg nieuwe SDP toe",
        eans: "De volgende EANs zijn gekoppeld aan mandaat:",
        noMandateEANs: "Geen EAN's gekoppeld aan het geselecteerde document, selecteer EAN's handmatig.",
        selectEANs: "Selecteer EANs",
        addEans: "Voeg EANs toe aan de lijst"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.updateMandates()
  }

  onEanChange(selectedEANs) {
    this.setState({
      selectedEANs: selectedEANs.map(x => x.ean),
      mandateSentEANs: this.props.sdps.filter(sdp => selectedEANs.indexOf(sdp.ean) > -1 && sdp.mandateSent),
      unsupportedDGOEANs: this.props.sdps.filter(sdp => selectedEANs.indexOf(sdp.ean) > -1 && unsupportedDGOs.indexOf(sdp.dgo) > -1)
    })
  }

  onMandateChange(e) {
    this.setState({
      mandate_file: e.target.value
    }, () => {
      this.getMandateEANs()
    })
  }

  getMandateEANs() {
    this.setState({ mandatePoints: 'loading' })

    getMandateEANs(this.props.selectedCompany.companyNumber, this.state.mandate_file, (err, res) => {
      if (err)
        return window.reportError.send(err);
      this.setState({
        mandateEANs: res.map(x => x.ean),
        mandatePoints: res
      })
    })
  }

  updateMandates() {
    getSignedMandates(this.props.selectedCompany.company_id, (err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        mandates: res.map(x => x.split('/')[1])
      })
    })
  }

  onSendMandate(e) {
    e.preventDefault();

    let EANlist = this.state.mandateEANs.concat(this.state.selectedEANs)

    if (this.state.unsupportedDGOEANs.length) return

    this.setState({ error: false })

    if (!this.state.mandate_file || !EANlist.length) {
      this.setState({ error: this.strings.errorRequired })
      return;
    }

    sendMandate(this.props.selectedCompany.company_id, { ...this.state, EANlist },
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.emailSent }, () => {
            this.props.updateSdps()
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.mandate}
        </div>
        <div className="card-body clearfix">
          {
            !this.props.sdps ?
              <Loader inline="true">{this.strings.loadingEans}</Loader>
              :
              <div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.onSendMandate} noValidate>
                      <div className="form-group row required required-select mb-0">
                        <label htmlFor="mandate_file" className="col-sm-3 col-form-label">{this.strings.mandateFile}</label>
                        <div className="col-sm-9">
                          {
                            this.state.mandates ?
                              <div>
                                {
                                  this.state.mandates.length ?
                                    <select className="form-control mb-3 required" onChange={this.onMandateChange} id="mandate_file" name="mandate_file" value={this.state.mandate_file} required>
                                      <option value=""></option>
                                      {
                                        this.state.mandates.map(mandate => {
                                          return (
                                            <option value={mandate} key={mandate}>{mandate}</option>
                                          )
                                        })
                                      }
                                    </select>
                                    : <div className="col-form-label">{this.strings.noMandates}</div>
                                }
                              </div>
                              : <Loader inline="true">{this.strings.loadingMandates}</Loader>
                          }
                        </div>
                      </div>

                      {
                        this.state.mandatePoints === 'loading' ? <div className="form-group row"><div className="col-12 text-center"><Loader inline /></div></div> :
                          this.state.mandate_file && this.state.mandatePoints.length ?
                            <div className="form-group row m-0">
                              <label htmlFor="contractActivationEAN" className="col-12 col-form-label">{this.strings.eans}</label>
                              <div className="col-12">
                                <ul>
                                  {
                                    this.state.mandatePoints.map(sdp => {
                                      return <li key={sdp.ean}>{sdp.ean} ({sdp.installation_id})</li>
                                    })
                                  }
                                </ul>
                              </div>
                            </div>
                            : this.state.mandate_file ?
                              <div className={'alert alert-warning mb-3'}>
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.noMandateEANs}
                              </div>
                              : null
                      }

                      {
                        !this.state.mandate_file ? null :
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <div className="input-group special-select">
                                <Typeahead
                                  id="eans"
                                  multiple
                                  options={this.props.sdps.filter(x => this.state.mandateEANs.indexOf(x.ean) < 0)}
                                  labelKey={x => `${x.ean} (${x.installation_ids || x.installation_id})`}
                                  placeholder={this.strings[this.state.mandatePoints.length ? 'addEans' : 'selectEANs']}
                                  clearButton
                                  onChange={this.onEanChange}
                                />
                              </div>
                            </div>
                          </div>
                      }

                      <div className={'alert alert-info mb-3 ' + (this.props.sdps.filter(x => x.hasMetervalues).length ? '' : 'd-none')}>
                        <i className="fas fa-info-circle mr-2"></i> {this.strings.hasMetervalues}
                        <br />
                        <ul>
                          {
                            this.props.sdps.filter(x => x.hasMetervalues).map(sdp => {
                              return <li key={sdp.ean}>{sdp.ean}</li>
                            })
                          }
                        </ul>
                      </div>

                      <div className={'alert alert-warning mb-3 ' + (this.state.mandateSentEANs.length ? '' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.mandateAlreadySent}
                        <br />
                        <ul>
                          {
                            this.state.mandateSentEANs.map(sdp => {
                              return <li key={sdp.ean}>{sdp.ean}</li>
                            })
                          }
                        </ul>
                      </div>

                      <p className={'alert alert-warning mb-3 ' + (this.state.error ? '' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                      </p>

                      <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                        <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                      </p>

                      {
                        this.state.unsupportedDGOEANs.length ?
                          <div className="alert alert-danger mb-3">
                            <i className="fas fa-minus-circle mr-2"></i> {this.strings.unsupportedDGOs}
                            <br />
                            <ul>
                              {
                                this.state.unsupportedDGOEANs.map(sdp => {
                                  return <li key={sdp.ean}>{sdp.ean} &ndash; <strong>DGO: {sdp.dgo}</strong></li>
                                })
                              }
                            </ul>
                          </div>
                          : null
                      }

                      <button type="submit" disabled={this.state.unsupportedDGOEANs.length} className="btn btn-primary float-right">{this.strings.sendSignedMandate}</button>
                    </form>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default SendMandate;
