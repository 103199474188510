import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LocalizedStrings from '../Localization';
import Loader from '../Loader';

import { sendContractProposal } from '../../api/user';


class ContractProposal extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      type: {}
    }

    this.state = {
      ...this.initialState,
      user_id: '',
      user: null,
      lang: this.props.lang
    }

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onSendContractProposal = this.onSendContractProposal.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "User and Type fields are required.",
        error: "Error while sending contract proposal. Please try again.",
        errorNoContracts: 'All contracts have signature date or have ended',
        emailSent: "Contract proposal email was successfully sent!",
        proposal: "Send Contract Proposal",
        sendEmail: "Send contract proposal email to the selected user:",
        loadingUsers: "Loading users...",
        user: "User",
        noContacts: "No contacts found",
        sendContractProposal: "Send contract proposal e-mail",
        type: "Type",
        supply: "Supply",
        injection: "Injection",
        loadingEans: "Loading EANs...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP"
      },
      nl: {
        errorRequired: "Gebruikers en Type velden zijn vereist.",
        error: "Fout bij het verzenden van een contractvoorstel. Probeer het opnieuw.",
        emailSent: "Contractvoorstel e-mail is succesvol verzonden!",
        errorNoContracts: 'Alle contracten hebben een datum van ondertekening of zijn geëindigd',
        proposal: "Stuur Contractvoorstel",
        sendEmail: "Stuur een e-mail met een contractvoorstel naar de geselecteerde gebruiker:",
        loadingUsers: "Gebruiker opzoeken...",
        user: "Gebruiker",
        noContacts: "Geen contacten gevonden",
        sendContractProposal: "Stuur een e-mail met het contractvoorstel",
        type: "Type",
        supply: "Levering",
        injection: "Injectie",
        loadingEans: "EANs laden...",
        noSdp: "Het bedrijf heeft geen SDPs.",
        addSdp: "Voeg nieuwe SDP toe"
      }
    }, this.props.lang);
  }

  onTypeChange(e) {

    let type = { ...this.state.type, [e.target.name]: e.target.checked }

    this.setState({
      type
    })
  }

  onUserChange(e) {
    this.setState({
      user_id: e.target.value,
      user: this.props.usersForCompany.find(user => user.id === parseInt(e.target.value, 10))
    })
  }

  onSendContractProposal(e) {
    e.preventDefault();

    this.setState({ error: false })

    if (!this.state.user_id || !(this.state.type.supply || this.state.type.injection)) {
      this.setState({ error: this.strings.errorRequired })
      return;
    }

    sendContractProposal(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {

          if (res.error.not_found === true) {

            this.setState({ error: this.strings.errorNoContracts }, () => {
              setTimeout(() => {
                this.setState({ error: null })
              }, 5000)
            })

          } else {

            this.setState({ error: this.strings.error }, () => {
              setTimeout(() => {
                this.setState({ error: null })
              }, 5000)
            })

          }
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.emailSent }, () => {
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {

    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.proposal}
        </div>
        <div className="card-body clearfix">
          {
            !this.props.sdps ?
              <Loader inline="true">{this.strings.loadingEans}</Loader>
              :
              <div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.onSendContractProposal} noValidate>
                      <p>
                        {this.strings.sendEmail}
                      </p>
                      <div className="form-group row required required-select">
                        <label htmlFor="user_id_proposal" className="col-sm-3 col-form-label">{this.strings.user}</label>
                        <div className="col-sm-9">
                          {
                            this.props.usersForCompany ?
                              <div>
                                {
                                  this.props.usersForCompany.length ?
                                    <select className="form-control mb-3 required" onChange={this.onUserChange} id="user_id_proposal" name="user_id_proposal" value={this.state.user_id || ''} required>
                                      <option value="" disabled></option>
                                      {
                                        this.props.usersForCompany.map(user => {
                                          return (
                                            user.role !== 'admin' ? <option value={user.id} key={user.email}>{user.firstName} {user.lastName} - {user.email}</option> : null
                                          )
                                        })
                                      }
                                    </select>
                                    : <div className="col-form-label">{this.strings.noContacts}</div>
                                }
                              </div>
                              : <Loader inline="true">{this.strings.loadingUsers}</Loader>
                          }
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{this.strings.type}</label>
                        <div className="col-sm-9 mt-2">
                          {
                            this.props.sdps && this.props.sdps.filter(sdp => sdp.type === 'offtake_hourly' && sdp.hasContract).length ?
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="supply" checked={this.state.send_email} id="contract_supply" onChange={this.onTypeChange} />
                                <label className="form-check-label" htmlFor="contract_supply">
                                  {this.strings.supply}
                                </label>
                              </div> : null
                          }
                          {
                            this.props.sdps && this.props.sdps.filter(sdp => sdp.type === 'injection_hourly' && sdp.hasContract).length ?
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="injection" checked={this.state.send_email} id="contract_injection" onChange={this.onTypeChange} />
                                <label className="form-check-label" htmlFor="contract_injection">
                                  {this.strings.injection}
                                </label>
                              </div> : null
                          }
                        </div>
                      </div>

                      <p className={'alert alert-warning mb-3 ' + (this.state.error ? '' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                      </p>

                      <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                        <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                      </p>

                      <button type="submit" className="btn btn-primary float-right">{this.strings.sendContractProposal}</button>
                    </form>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ContractProposal;
