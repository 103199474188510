import React, { Component } from 'react';
import LocalizedStrings from './Localization';

class Error extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: undefined
    };

    this.closeAlert = this.closeAlert.bind(this);

    this.strings = LocalizedStrings({
      en: {
        error: "There was an error. Please try again or reload the page."
      },
      nl: {
        error: "Er is een fout opgetreden. Probeer het opnieuw of laad de pagina opnieuw."
      }
    }, this.props.lang);


    const errorHandler = (e) => {
      this.setState({ error: e.detail.error })
    };

    document.addEventListener('appError', errorHandler, false);
  }

  closeAlert() {
    this.setState({ error: undefined })
  }

  render() {

    return (
      <div className={this.state.error ? 'global-error' : 'd-none'}>
        <div className="alert alert-warning shadow p-4 mt-2" role="alert">
          <i className="fas fa-exclamation-triangle mr-2"></i>{this.strings.error}
          <button type="button" className="close" aria-label="Close" onClick={this.closeAlert}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

export default Error;
