import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LocalizedStrings from '../components/Localization';

class NotFound404 extends Component {
  constructor(props) {
    super(props);

    this.strings = LocalizedStrings({
      en: {
        notFound: "Page Not Found",
        goto: "Did you get lost? Go to our",
        homepage: "homepage",
        contact: "or contact us at"
      },
      nl: {
        notFound: "Pagina niet gevonden",
        goto: "Ben je verloren gelopen? Ga naar onze",
        homepage: "Startpagina",
        contact: "of neem contact met ons op via"
      }
    }, this.props.lang);
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <div className="text-center py-5">
          <h3 className="mb-3">{this.strings.notFound}</h3>
          <p>
            {this.strings.goto} <Link to="/">{this.strings.homepage}</Link> {this.strings.contact} <a href="mailto:support@yuso.be">support@yuso.be</a>
          </p>
        </div>
      </div>
    );
  }
}

export default NotFound404;
