import config from './config';
import { getAccessToken } from './user';


const addNewCustomer = (customer_info, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/new-customer`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(customer_info)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export { addNewCustomer };
