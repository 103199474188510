import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getStatement } from '../../api/documents';


class Statement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: undefined
    };

    this.getUrl = this.getUrl.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading statement...",
        notFound: "No statement found.",
        backToStatements: "Back to statements"
      },
      nl: {
        loading: "Afrekeningen laden...",
        notFound: "Geen afrekening gevonden.",
        backToStatements: "Terug naar afrekeningen"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (parseInt(nextProps.company_id) !== parseInt(nextProps.selectedCompany.company_id)) {

      let url = window.location.pathname.replace(nextProps.company_id.toString(), nextProps.selectedCompany.company_id.toString())

      return { redirectTo: url }
    }
    else

      return null
  }

  componentDidMount() {
    this.getUrl();
  }

  getUrl() {
    getStatement(
      this.props.company_id,
      this.props.document_key,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {

          this.setState({
            error: this.strings.notFound
          });
        }
        else {

          this.setState({
            url: res || 'about:blank'
          });
        }
      }
    );
  }

  render() {
    if (this.state.redirectTo)
      return <Redirect to={this.state.redirectTo} />

    if (this.state.error) {
      return (
        <div className="text-center">
          <div className="spacer"></div>
          <div className="spacer"></div>
          <h4 className="font-weight-light text-muted mb-4">{this.state.error}</h4>
          <Link to="/documents/statements" className="btn btn-secondary">{this.strings.backToStatements}</Link>
          <div className="spacer"></div>
          <div className="spacer"></div>
        </div>
      )
    }

    if (!this.state.url) {
      return (<Loader>{this.strings.loading}</Loader>);
    }

    return (
      <div className="row">
        <div className="col-sm p-0">
          <iframe name="doc" id="doc" width="100%" height="800px" title="doc" src={this.state.url}></iframe>
        </div>
      </div>
    );
  }
}

export default Statement;
