import React, { Component } from 'react';

class FormPanel extends Component {
  render() {
    return (
      <div className="mb-2 border-bottom border-light">
        <h5 onClick={() => { this.props.activateStage(this.props.type) }} className={'text-primary mb-3 cursor-pointer ' + (this.props.className || '') + (this.props.type === this.props.stage ? 'font-weight-bold' : 'font-weight-light')}>
          <i className={'fas mr-2 ' + this.props.icon}></i>
          {this.props.title}
        </h5>
        <div className={this.props.type === this.props.stage ? '' : 'd-none'}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default FormPanel;
