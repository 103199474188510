import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class DropdownItem extends Component {
    render () {
        return (
            <Link className={'dropdown-item ' + (this.props.className || '')} to={this.props.href}>{this.props.name}</Link>
        );
    }
}

export default DropdownItem
