import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone'
import SubHeader from '../../components/SubHeader';
import { CtaPanel } from '../../components/CallToAction';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getStatements } from '../../api/documents';

moment.locale('nl')
moment.tz.setDefault('Europe/Brussels')

class Statements extends Component {
    constructor(props){
        super(props);
        this.state = {
            statements: undefined,
            searchTerm: undefined
        }

        this.updateStatementsList = this.updateStatementsList.bind(this);
        this.updateSearchTerm = this.updateSearchTerm.bind(this);
        this.getDocumentPeriod = this.getDocumentPeriod.bind(this);

        this.strings = LocalizedStrings({
            en: {
                statements: "Statements",
                fetching: "Fetching statements...",
                notFound: "No statements found.",
                statementsDesc: "Below you will find a list of all invoicing statements concerning your company.",
                learn: "Learn how to read the statements",
                here: "here",
                howTo1: "How to read the statements",
                howTo2: "The settlement consists of 4 documents:",
                howTo3: "an overview that balances the amounts to be received and paid",
                howTo4: "a self-bill with the total receivables to be received (eg revenue from injection energy)",
                howTo5: "an invoice with the total amount of credit to be paid (eg supplies of energy or grid costs related to the injection)",
                howTo6: "a detail document which gives more detail about the calculations of the amounts included in the invoice and self-bill. Each installation has a separate detail document for each month.",
                howTo7: "You must include the self-bill and the invoice in your accounts (the self-bill in the sales diary and the invoice in the purchase diary).",
                howTo8: "In view of the application of self-billing, you as a producer no longer have to make an invoice and deliver it to Yuso.",
                close: "Close",
                search: "Search",
                period: "Period",
                document: "Document"
            },
            nl: {
                statements: "Afrekeningen",
                fetching: "Ophalen van afrekeningen...",
                notFound: "Geen afrekeningen gevonden.",
                statementsDesc: "Hieronder vind je een lijst met alle afrekeningen voor jouw bedrijf.",
                learn: "Leer hoe je de afrekeningen moet lezen",
                here: "via deze link",
                howTo1: "Hoe de afrekeningen lezen",
                howTo2: "De afrekening bestaat uit 4 documenten:",
                howTo3: "een overzicht dat de te ontvangen en te betalen tegoeden saldeert",
                howTo4: "een self-bill met het totaal aan te ontvangen tegoeden (bv. opbrengsten uit injectie-energie)",
                howTo5: "een factuur met het totaal aan te betalen tegoeden (bv. leveringen energie of netkosten verbonden aan de injectie)",
                howTo6: "een detail document welke meer detail geeft over de berekeningen van de bedragen opgenomen in de factuur en self-bill. Elke installatie heeft voor elke maand een apart detail document.",
                howTo7: "De self-bill en de factuur dien je op te nemen in je boekhouding (de self-bill in het dagboek van de verkopen en de factuur in het dagboek van de aankopen).",
                howTo8: "Dankzij het gebruik van self-billing dien je als producent geen factuur meer op te maken en te bezorgen aan Yuso.",
                close: "Sluiten",
                search: "Zoeken",
                period: "Periode",
                document: "Document"
            }
        }, this.props.lang);
    }

    componentDidMount(){
        this.updateStatementsList();
    }

    updateStatementsList(){
        if (!this.props.selectedCompany) return;

        const dis = this;

        getStatements(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                dis.setState({
                    statementsList: res.sort((a, b) => {
                        let a_date = moment(a.split('-')[1].replace('.pdf', ''), 'YYMM');
                        let b_date = moment(b.split('-')[1].replace('.pdf', ''), 'YYMM');

                        if (a_date.isValid() && b_date.isValid())
                            return b_date.toDate() - a_date.toDate();

                        return 0;
                    })
                });
            }
        );
    }

    updateSearchTerm(e){
        this.setState({searchTerm: e.target.value});
    }

    getDocumentPeriod(doc) {
        let date = moment(doc.split('-')[1].replace('.pdf', ''), 'YYMM');

        if (date.isValid())
            return date.format('YYYY-MM');

        return 'N/A';
    }

    render() {
        if(!this.state.statementsList){
            return (
                <div>
                    <SubHeader name={this.strings.statements} image="Documents" type="Statements" />
                    <Loader>
                        {this.strings.fetching}
                    </Loader>
                </div>
            );
        }

        if (!this.state.statementsList.length) {
            return (
                <div>
                    <SubHeader name={this.strings.statements} image="Documents" type="Statements" />
                    <div className="row">
                        <div className="col-sm px-2 px-xl-5 py-3">
                            <div className="spacer"></div>
                            <p>{this.strings.notFound}</p>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <SubHeader name={this.strings.statements} image="Documents" type="Statements" />
                <div className="row">
                    <div className="col-lg-7 px-2 px-xl-5 py-3">
                        <div className="spacer"></div>
                        <div className="mb-3">
                            {this.strings.statementsDesc}
                            <br />
                            {this.strings.learn} <span className="text-primary cursor-pointer" data-toggle="modal" data-target="#statements-help">{this.strings.here}</span>.

                            <div className="modal fade" id="statements-help" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">{this.strings.howTo1}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>
                                                {this.strings.howTo2}
                                            </p>
                                            <ul>
                                                <li>{this.strings.howTo3}</li>
                                                <li>{this.strings.howTo4}</li>
                                                <li>{this.strings.howTo5}</li>
                                                <li>{this.strings.howTo6}</li>
                                            </ul>

                                            <p>
                                                {this.strings.howTo7}
                                            </p>

                                            <p>
                                                {this.strings.howTo8}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            <input className="form-control" placeholder={this.strings.search} type="text" value={this.state.searchTerm} onChange={this.updateSearchTerm} />
                        </p>
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>{this.strings.period}</th>
                                    <th>{this.strings.document}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.statementsList.filter(
                                        (doc) => {
                                            if (!this.state.searchTerm) {
                                                return true;
                                            }
                                            return (doc.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1);
                                        }
                                    ).map(
                                        (doc) => {
                                            return (
                                                <tr key={doc}>
                                                    <td>
                                                        {this.getDocumentPeriod(doc.split('/')[1])}
                                                    </td>
                                                    <td>
                                                        <Link className="text-primary"
                                                            to={`/documents/statement/${this.props.selectedCompany.company_id}/${doc.split("/")[1]}`}
                                                        >
                                                            {doc.split("/")[1]}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-5 px-2 px-xl-5 py-3">

                        <CtaPanel
                            className={'my-4 ml-4 ' + (!this.props.info.sdps.has_sdps ? 'd-none' : '')}
                            link="/services#flexibility"
                            type="request_flexibility"
                            lang={this.props.lang}
                            buttonClassName="w-100" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Statements;
