import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import moment from 'moment-timezone'

import LocalizedStrings from '../Localization';

moment.tz.setDefault('Europe/Brussels')


class EanPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: '',
      selectedEANs: [],
      options: [],
      type: ''
    }

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onEanChange = this.onEanChange.bind(this);
    this.onEanSelect = this.onEanSelect.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.getEanTooltip = this.getEanTooltip.bind(this);
    this.getSwitchStatusTooltip = this.getSwitchStatusTooltip.bind(this);

    this.strings = LocalizedStrings({
      en: {
        type: "Select SDP type",
        supply: "Supply",
        injection: "Injection",
        filter: "Filter EANs list...",
        selectAll: "Select all",
        clearSelection: "Clear selection",
        noSPDs: "No SPDs found of the selected type.",
        sdpStatusDesc: "Status",
        sdpReasonDesc: "Reason",
        notSwitched: "Not submitted for switching"
      },
      nl: {
        type: "Selecteer SDP-type",
        supply: "Levering",
        injection: "Injectie",
        selectAll: "Selecteer alles",
        clearSelection: "Wis selectie",
        noSPDs: "Geen SPDs gevonden van het geselecteerde type.",
        sdpStatusDesc: "Staat",
        sdpReasonDesc: "Reden",
        notSwitched: "Niet ingediend voor overstappen"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  onFieldChange = (event) => {
    const value = event.target.value;
    const id = event.target.id;

    this.setState({
      [id]: event.target.type === 'checkbox' ? event.target.checked : value,
      options: id === 'type'
        ? this.props.sdps.filter(sdp => sdp.type_period === 'hourly' && sdp.type_sdp === value)
        : this.state.options
    });

    if (id === 'type') {
      this.props.onSelect(value);
    }
    this.clearSelection();
  };


  onFilter(e) {
    let val = e.target.value.toUpperCase()

    this.setState({
      filter: val,
      options: this.props.sdps.filter(x => x.ean.indexOf(val) > -1)
    })
  }

  onEanChange(e) {
    const selection = e.target.value;
    let [id, ean, type] = selection.split('-');
    id !== 'null' ? id = parseInt(id) : id = null

    const selectedEan = this.props.sdps.find(x => x.contract_id === id && x.type === type) || {}
    this.setState({ selection })

    if (this.props.onEanChange)
      this.props.onEanChange(ean, type, selectedEan, id)
  }

  onEanSelect(e) {

    let eans = [...this.state.selectedEANs]

    if (e.target.checked) {
      eans.push(e.target.value)
    }
    else {
      eans = eans.filter(x => x !== e.target.value)
    }

    this.setState({
      selectedEANs: eans
    }, () => {
      if (this.props.onEanChange)
        this.props.onEanChange(this.state.selectedEANs)
    })
  }

  selectAll() {
    this.setState({
      selectedEANs: this.props.sdps.map(sdp => sdp.type_sdp === this.state.type && sdp.hasContract ? sdp.ean : null).filter(x => x !== null)
    }, () => {
      if (this.props.onEanChange)
        this.props.onEanChange(this.state.selectedEANs)
    })
  }

  clearSelection() {
    this.setState({
      selectedEANs: []
    }, () => {
      if (this.props.onEanChange)
        this.props.onEanChange(this.state.selectedEANs)
    })
  }

  getEanTooltip(sdp) {
    return `<div>
      ${sdp.ean}
      <br/>
      ${sdp.installation_id} | ${sdp.type_sdp} | ${sdp.type_period} | ${sdp.contract_status}
    </div>`
  }

  getSwitchStatusTooltip(sdp) {
    return `<div class="text-left" style="width: 300px;">
      ${this.strings.sdpStatusDesc}: ${sdp.switchStatusDesc || this.strings.notSwitched} ${sdp.switchStatus ? `(${sdp.switchStatus})` : ''}
      <br/>
      ${this.strings.sdpReasonDesc}: ${sdp.switchStatusReasonDesc || 'N/A'} ${sdp.switchStatusReason ? `(${sdp.switchStatusReason})` : ''}
    </div>`
  }

  render() {
    return (
      this.props.sdps && this.props.sdps.length ?
        this.props.checkbox ?
          <div>
            {
              this.state.type !== '' && this.props.sdps.filter(sdp => sdp.type_period === 'hourly' && sdp.type_sdp === this.state.type).length > 5 ?
                <div className="input-group row mb-3">
                  <select className="form-control col-4" id="type" value={this.state.type || ''} onChange={this.onFieldChange}>
                    <option value="" disabled>{this.strings.type}</option>
                    <option value="offtake">{this.strings.supply}</option>
                    <option value="injection">{this.strings.injection}</option>
                  </select>
                  <input className="input-group-append form-control col-8" type="text" placeholder={this.strings.filter} value={this.state.filter} onChange={this.onFilter} />
                </div>
                :
                <select className="form-control mb-3" id="type" value={this.state.type || ''} onChange={this.onFieldChange}>
                  <option value="" disabled>{this.strings.type}</option>
                  <option value="offtake">{this.strings.supply}</option>
                  <option value="injection">{this.strings.injection}</option>
                </select>
            }
            {
              this.state.type === '' ? null : this.state.options.length ?
                <div>
                  <div className="checkbox-list">
                    {
                      this.state.options.map(sdp => {
                        return <div className="form-check" key={`${sdp.ean}-${sdp.type}`}>
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="EAN" checked={this.state.selectedEANs.find(ean => ean === sdp.ean) ? true : false} value={sdp.ean} onChange={this.onEanSelect} disabled={this.props.disabled && (!sdp.hasContract || sdp.ean.startsWith('EANTMP')) ? true : false} />
                            {
                              this.props.switchStatus ?
                                <span data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.getSwitchStatusTooltip(sdp)}>
                                  {
                                    (sdp.switchStatus === '39') /* MySupplierWeb approved */ ?
                                      <i className="fas fa-check text-green mx-1"></i>
                                      :
                                      <i className="fas fa-times text-danger mx-1"></i>
                                  }
                                </span>
                                : null
                            }
                            <span data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.getEanTooltip(sdp)}>
                              {sdp.ean} &ndash; {sdp.installation_id}
                            </span>
                          </label>
                        </div>
                      })
                    }
                  </div>
                  <div className="mt-2">
                    <span className="text-underline text-primary cursor-pointer mr-3" onClick={this.selectAll}>{this.strings.selectAll}</span>
                    <span className="text-underline text-primary cursor-pointer" onClick={this.clearSelection}>{this.strings.clearSelection}</span>
                  </div>
                </div>
                :
                <p>
                  {this.strings.noSPDs}
                </p>
            }
          </div>
          :
          <select className="form-control form-ean" id={this.props.id} name="EAN" value={(this.props.selection !== undefined ? this.props.selection : this.state.selection) || ''} onChange={this.onEanChange} required={this.props.required} multiple={this.props.multiple}>
            <option value="" disabled></option>
            {
              this.props.sdps.map(sdp => {
                return <option key={`${sdp.ean}-${sdp.contract_id}`} value={`${sdp.contract_id}-${sdp.ean}-${sdp.type}`} disabled={this.props.disabled && (!sdp.hasContract || sdp.ean.startsWith('EANTMP')) ? true : false}>
                  {
                    this.props.info !== undefined && this.props.info === false
                      ? `${sdp.ean} - ${sdp.installation_id}`
                      : this.props.info !== undefined && this.props.info === true
                        ? `${sdp.ean} - ${sdp.installation_id} | ${sdp.type_sdp}${sdp.type_sdp === 'offtake' ? '\u00A0\u00A0' : ''} | ${sdp.type_period} | ${sdp.contract_status} | ${sdp.startDate}`
                        : `${sdp.ean} - ${sdp.installation_id} | ${sdp.type_sdp}${sdp.type_sdp === 'offtake' ? '\u00A0\u00A0' : ''} | ${sdp.type_period} | ${sdp.contract_status}`
                  }
                </option>
              })
            }
          </select>
        :
        <div></div>
    )
  }
}

export default EanPicker;
