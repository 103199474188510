import config from './config';
import { getAccessToken } from './user';

const getPrices = (company_id, eans, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/cert-prices/${company_id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(eans)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getCertificatePrices = (cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/prices`,
        {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const addCertificatePrices = (data, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/prices`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const updateCertificatePrices = (data, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/prices`,
        {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const deleteCertificatePrices = (period, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/prices/${period}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const generateCertificatePrices = (cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/certificates/generate`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export {
  getPrices,
  getCertificatePrices,
  addCertificatePrices,
  updateCertificatePrices,
  deleteCertificatePrices,
  generateCertificatePrices
};
