import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone'

import EanPicker from './EanPicker';
import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';

import 'moment/locale/nl-be';
import 'react-day-picker/lib/style.css'

import { uploadSignedAgreement } from '../../api/documents';

moment.tz.setDefault('Europe/Brussels')

class UploadContract extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      selectedEANs: [],
      signatureDate: new Date(),
      specialCase: false,
      comment: ''
    }

    this.state = {
      ...this.initialState,
      agreement_file: undefined,
      type: ''
    }

    this.uploadContract = this.uploadContract.bind(this);
    this.onEanChange = this.onEanChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSignatureDateChange = this.onSignatureDateChange.bind(this);
    this.onSpecialCase = this.onSpecialCase.bind(this);
    this.onCommentTyped = this.onCommentTyped.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Missing required field",
        eanMissing: "Please select at least one EAN above",
        error: "Error while uploading the agreement. Please try again.",
        errors: "These errors occurred:",
        errorBigFile: "Contract file is too big.",
        errorBigFile1: "The contract file is too big",
        errorBigFile2: "The maximum supported size is 25 MB, please consider",
        errorBigFile3: "compressing it",
        uploaded: "Contract was successfully uploaded!",
        contract: "Upload Agreement",
        loadingStatus: "Loading agreements status...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        ean: "EAN",
        select: "Select EAN...",
        loading: "Loading EANs...",
        agreementFile: "Choose agreement file",
        signatureDate: "Signature date",
        specialCase: "Special case contract",
        comment: "Comment",
        upload: "Upload"
      },
      nl: {
        errorRequired: "Verplicht veld ontbreekt",
        eanMissing: "Selecteer minimaal één EAN hierboven",
        error: "Fout tijdens het uploaden van de overeenkomst. Probeer het opnieuw.",
        errors: "Deze fouten zijn opgetreden:",
        errorBigFile: "Contractbestand is te groot.",
        errorBigFile1: "Het contractbestand is te groot",
        errorBigFile2: "De maximale ondersteunde grootte is 25 MB, alsjeblieft",
        errorBigFile3: "comprimeren",
        uploaded: "Contract is succesvol geüpload!",
        contract: "Upload Contract",
        loadingStatus: "Status van contract laden ...",
        noSdp: "Het bedrijf heeft geen aansluitingen.",
        addSdp: "Voeg een nieuwe aansluiting toe",
        ean: "EAN",
        select: "Selecteer EAN...",
        loading: "EANs laden...",
        agreementFile: "Kies een bestand",
        signatureDate: "Datum handtekening",
        specialCase: "Speciaal geval contract",
        comment: "Commentaar",
        upload: "Uploaden"
      }
    }, this.props.lang);
  }

  handleSelectedValue = (type) => {
    this.setState({ type });

  };

  uploadContract(e) {
    e.preventDefault();

    this.setState({ errorUploadContract: false });

    let error = [];

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)
      }
    }

    if (this.state.errorBigFile) {
      error.push(this.strings.errorBigFile)
    }

    if (!this.state.selectedEANs.length) {
      error.push(this.strings.eanMissing)
    }

    if (error.length) {
      this.setState({ errorUploadContract: error })
      return;
    }

    uploadSignedAgreement(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ errorUploadContract: [this.strings.error] });
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.uploaded }, () => {
            setTimeout(() => {
              this.props.updateCompanies(this.props.selectedCompany.company_id, true);
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  onEanChange(selectedEANs) {
    let sdpType = this.state.type;
    let filteredSDPs = sdpType === 'offtake' ?
      this.props.sdps.filter(x => selectedEANs.includes(x.ean) && x.type === 'offtake_hourly') :
      this.props.sdps.filter(x => selectedEANs.includes(x.ean) && x.type === 'injection_hourly');

    let mappedSDPs = filteredSDPs.map(z => {
      return {
        ean: z.ean,
        contract_id: z.contract_id,
        companyNumber: this.props.selectedCompany.companyNumber
      };
    });

    this.setState({
      selectedEANs: mappedSDPs
    });
  }

  onFileChange(e) {
    const file = e.target.files[0];
    const _this = this;

    this.setState({ errorBigFile: false })

    if (file) {

      let reader = new FileReader();

      reader.onloadend = () => {
        _this.setState({
          agreement_file: {
            base64: reader.result.split(',').pop(),
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate
          }
        }, () => {
          if (file.size > 25 * 1000 * 1000) {
            this.setState({ errorBigFile: true })
          }
        })
      }

      reader.readAsDataURL(file);
    }
  }

  onSignatureDateChange(signatureDate) {
    this.setState({
      signatureDate
    });
  }

  onSpecialCase(e) {
    this.setState({
      specialCase: e.target.checked,
      comment: ''
    });
  }

  onCommentTyped(e) {
    this.setState({
      comment: e.target.value
    })
  }

  render() {
    if (this.props.selectedCompany.role === 'admin') {
      return (
        <div className="card mb-4">
          <div className="card-header">
            {this.strings.contract}
          </div>
          <div className="card-body">
            {
              !this.props.sdps ?
                <Loader inline="true" className="mt-2 mb-3">{this.strings.loadingStatus}</Loader> :
                <div>
                  {
                    !this.props.sdps.length ?
                      <p className="alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                        <br />
                        <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                      </p>
                      :
                      <form onSubmit={this.uploadContract} noValidate>

                        <div className="form-group row">
                          <label htmlFor="uploadContractEAN" className="col-sm-3 col-form-label">{this.strings.ean}</label>
                          <div className="col-sm-9">
                            <EanPicker
                              sdps={this.props.sdps}
                              onEanChange={this.onEanChange}
                              id="uploadContractEAN"
                              lang={this.props.lang}
                              checkbox={true}
                              disabled={true}
                              onSelect={this.handleSelectedValue}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-12">
                            <div className="custom-file mb-3">
                              <input type="file" className="custom-file-input" name="agreement_file" onChange={this.onFileChange} required />
                              <label className="custom-file-label" htmlFor="agreement_file">{this.state.agreement_file ? this.state.agreement_file.name : this.strings.agreementFile}</label>
                            </div>
                            <small className={'form-text text-danger ' + (this.state.errorBigFile ? '' : 'd-none')}>
                              {this.strings.errorBigFile1} {this.state.agreement_file && (this.state.agreement_file.size / 1000 / 1000).toFixed(2)} MB. {this.strings.errorBigFile2} <a className="text-danger text-underline" href="https://smallpdf.com/compress-pdf" target="_blank" rel="noopener noreferrer">{this.strings.errorBigFile3}</a>.
                            </small>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label htmlFor="signature_date" className="col-sm-6 col-form-label">{this.strings.signatureDate}</label>
                          <div className="col-sm-6">
                            <DayPickerInput
                              value={this.state.signatureDate}
                              required
                              format="DD-MM-YYYY"
                              placeholder="DD-MM-YYYY"
                              formatDate={(date, format) => { return moment(date).format(format) }}
                              inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'signature_date', name: 'signature_date', required: true }}
                              dayPickerProps={{
                                selectedDays: this.state.signatureDate,
                                disabledDays: { after: new Date() },
                                localeUtils: MomentLocaleUtils,
                                locale: this.props.lang
                              }}
                              onDayChange={this.onSignatureDateChange}
                            />
                          </div>
                        </div>

                        <div className="form-group row mb-3">
                          <div className="col-sm">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="special_case" checked={this.state.specialCase} value={this.state.specialCase} onChange={this.onSpecialCase} />
                                {this.strings.specialCase}
                              </label>
                            </div>
                          </div>
                        </div>

                        {
                          this.state.specialCase ?
                            <div className="row mb-3">
                              <div className="col-sm">
                                <textarea className="form-control" placeholder={this.strings.comment} onChange={this.onCommentTyped} value={this.state.comment}></textarea>
                              </div>
                            </div>
                            : null
                        }

                        <div className={'alert alert-warning mb-3 ' + (this.state.errorUploadContract ? 'd-block' : 'd-none')}>
                          <i className="fas fa-exclamation-triangle mr-2"></i>{this.strings.errors}
                          <ul>
                            {
                              this.state.errorUploadContract ? this.state.errorUploadContract.sort().map(error => (<li key={error}>{error}</li>)) : null
                            }
                          </ul>
                        </div>

                        <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                          <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                        </p>

                        <button type="submit" className="btn btn-primary float-right">{this.strings.upload}</button>
                      </form>
                  }
                </div>
            }
          </div>
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export default UploadContract;
