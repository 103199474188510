import React, { Component } from 'react';

class Loader extends Component {
  render() {

    let spinner = 'fas fa-spinner fa-spin';

    if(this.props.inline) {
      return (
        <div className={this.props.className || ''} title={this.props.title || null}>
          <i className={spinner + ' mr-2'}></i>
          {this.props.children}
        </div>
      );
    }

    return (
      <div className={'d-flex flex-column justify-content-center align-items-center p-5 ' + this.props.className || ''}>
        <div className="spacer"></div>
        <i className={spinner + ' text-muted loader'} />
        <h4 className="text-muted font-weight-light mt-5">
          {this.props.children}
        </h4>
        <div className="spacer"></div>
      </div>
    );
  }
}

export default Loader;
