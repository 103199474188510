import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";

import DropdownPanel from "../../components/DropdownPanel";
import Loader from "../../components/Loader";
import LocalizedStrings from "../../components/Localization";

moment.tz.setDefault("Europe/Brussels");

class Offtake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      isArchived: false
    };

    this.toggleDetails = this.toggleDetails.bind(this);

    this.strings = LocalizedStrings(
      {
        en: {
          fetching: "Fetching supply points...",
          notConnected: "You have not yet connected any supply points to Yuso.",
          getStarted: "To get started, please",
          addSDP: "add new supply point",
          technical: "Technical data",
          point: "of the supply point",
          preswitchDate: "Latest preswitch",
          more: "More",
          less: "Less",
          details: "details",
          ean: "EAN",
          meterType: "Meter type",
          dataAvailableFrom: "Data available from",
          technology: "Technology",
          operator: "Distribution operator",
          serviceComponentError:
            "The correct service component is not yet available for this SDP. Contact [DNB] to solve this.",
          tariff: "Tariff type",
          thisPointError: "This point is",
          inPortfolio: "In Portfolio",
          notInPortfolio: "Not in Portfolio",
          physicalStatus: "Physical Status",
          voltage: "Voltage level",
          nace: "NACE code",
          address: "Address",
          mandate: "Meterdata permissions",
          validMandate: "Valid mandate",
          endedMandate: "Ended mandate",
          requestSent: "Mandate request sent to customer",
          noMaterDataPermissions: "No meter data permissions",
          contract: "Contract parameters",
          formula: "Formula",
          epexHourly: "EPEX Spot BE DA hourly prices",
          fee: "Platform fee",
          gsc: "Cost GSC",
          wkc: "Cost WKC",
          product: "Product",
          certificate_price: "EUR per certificate with contribution factor of",
          start: "Start contract",
          end: "End contract",
          moduleMig6: "Running process - Module",
          labelMig6: "Running process - Label",
          startDateMig6: "Running process - Start Date",
          supplierMig6: "Running process - Supplier",
          switchInfoMig6: "MIG6 switch information",
          switchInfoYuso: "My Yuso switch information",
          endDateNotIncluded: "The contract ends on",
          endDateNotIncluded2: ", not including that date.",
          month: "Extended month-to-month",
          volume: "Annual volume",
          capacity: "Power capacity",
          installation: "Installation ID",
          noContract: "No contract for this SDP yet.",
          noContract2: "You can create a contract for this SDP here.",
          noSignedContract1: "No active contract yet.",
          noSignedContract2: "You can view your contract here.",
          endedContract1: "You contract ended on",
          endedContract2: "You can renew it here.",
          switchInfo: "MIG6 switch information",
          switchState: "SDP state:",
          withYuso: "SDP is currently with Yuso",
          notWithYuso: "SDP is currently not with Yuso",
          switchDate: "Switch date",
          eocDate: "EOC date",
          switchStatus: "Switch Status",
          switchStatusReason: "Switch Status Reason",
          eocStatus: "EOC Status",
          eocStatusReason: "EOC Status Reason",
          internallySwitched: "Internally switched",
          serviceComponent: "Service Component",
          serviceConfiguration: "Service Configuration",
          underConstruction: "Under Construction",
          disconnected: "Disconnected",
          connected: "Connected",
          unlocked: "Unlocked",
          switchApproved: "Approved",
          switchRejected: "Rejected",
          notSwtiched: "Not switched",
          status: "Status",
          contractId: "Contract id"
        },
        nl: {
          fetching: "Leveringspunten ophalen...",
          notConnected: "U hebt nog geen leveringspunten aangesloten bij Yuso.",
          getStarted: "Om te beginnen,",
          serviceComponent: "Service Component",
          serviceConfiguration: "Service Configuratie",
          addSDP: "voeg nieuw leveringspunt toe",
          technical: "Technische data",
          point: "van het leveringspunt",
          more: "Meer",
          less: "Minder",
          preswitchDate: "Laatste preswitch",
          details: "gegevens",
          ean: "EAN",
          meterType: "Metertype",
          thisPointError: "This point is",
          inPortfolio: "In Portfolio",
          notInPortfolio: "Not in Portfolio",
          physicalStatus: "Physical Status",
          serviceComponentError:
            "The correct service component is not yet available for this SDP. Contact [DNB] to solve this.",
          dataAvailableFrom: "Gegevens beschikbaar vanaf",
          technology: "Technologie",
          operator: "Distributienetbeheerder",
          tariff: "Tarieftype",
          voltage: "Spanningsniveau",
          nace: "NACE-code",
          address: "Adres",
          mandate: "Meterdata-machtigingen",
          validMandate: "Valid mandate",
          endedMandate: "Ended mandate",
          requestSent: "Mandate request sent to customer",
          noMaterDataPermissions: "No meter data permissions",
          contract: "Contract parameters",
          formula: "Formule",
          epexHourly: "EPEX Spot BE DA uurtarieven",
          fee: "Platformkosten",
          gsc: "Kosten GSC",
          wkc: "Kosten WKC",
          product: "Product",
          certificate_price: "EUR per certificaat met bijdrage factor van",
          start: "Start contract",
          end: "Einde contract",
          moduleMig6: "Lopende processen - Module",
          labelMig6: "Lopende processen - Label",
          startDateMig6: "Lopende processen - Startdatum",
          supplierMig6: "Lopende processen - Leverancier",
          switchInfoYuso: "My Yuso schakel informatie",
          switchInfoMig6: "MIG6 schakel informatie",
          endDateNotIncluded: "Het contract eindigt op",
          endDateNotIncluded2: ", exclusief die datum.",
          month: "Automatische maandelijks verlengd",
          volume: "Jaarvolume",
          capacity: "Vermogen capaciteit",
          installation: "Installatie ID",
          noSignedContract1: "Nog geen actief contract.",
          noSignedContract2: "U kunt uw contract hier bekijken.",
          endedContract1: "Uw contract is geëindigd op",
          endedContract2: "Je kunt het hier vernieuwen.",
          noContract: "Nog geen contract voor deze SDP.",
          noContract2: "U kunt hier een contract voor deze SDP aanmaken.",
          switchState: "SDP staat:",
          withYuso: "SDP is momenteel bij Yuso",
          notWithYuso: "SDP is momenteel niet bij Yuso",
          switchDate: "Switch datum",
          eocDate: "EOC datum",
          switchStatus: "Switch Status",
          switchStatusReason: "Switch Status Reden",
          eocStatus: "EOC Status",
          eocStatusReason: "EOC Status Reden",
          internallySwitched: "Intern geschakeld",
          switchApproved: "Goedgekeurd",
          switchRejected: "Afgewezen",
          notSwtiched: "Niet geswitched",
          status: "Status",
          contractId: "Contract id"
        },
      },
      this.props.lang
    );
  }

  toggleDetails() {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  }

  render() {
    if (!this.props.sdps) {
      return (
        <div className="mb-3">
          <Loader inline="true">{this.strings.fetching}</Loader>
        </div>
      );
    }

    if (this.props.sdps.sdps_offtake_hourly.length === 0) {
      return (
        <div className="list-group mb-3">
          <p>{this.strings.notConnected}</p>
          <p>
            {this.strings.getStarted}{" "}
            <Link to="/services/onboarding#sdp">{this.strings.addSDP}</Link>.
          </p>
        </div>
      );
    }

    return (
      <div className="list-group">
        {this.props.sdps.sdps_offtake_hourly.map((sdp) => {
          return (
            sdp.archived && !this.props.isArchived ? null :
              <DropdownPanel
                key={sdp.ean}
                sdp={sdp}
                isCollapsed="true"
                ean={sdp.ean}
                installation_id={sdp.installation_id}
                type={sdp.type}
                address={`${sdp.addressStreet} ${sdp.addressNumber}, ${sdp.addressZip} ${sdp.addressCity}, ${sdp.addressCountry}`}
                setDeleteSdp={this.props.setDeleteSdp}
                setArchiveSdp={this.props.setArchiveSdp}
                selectedCompany={this.props.selectedCompany}
                lang={this.props.lang}
                archived={sdp.archived}
                isArchived={this.state.isArchived}
              >
                {sdp.service_component ? null : (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6 className="text-danger font-weight-light m-0">
                      <strong>{this.strings.serviceComponentError}</strong>
                    </h6>
                  </div>
                )}
                {sdp.physicalStatus === "E23" ||
                  sdp.physicalStatus === "E30" ||
                  sdp.physicalStatus === "Under construction" ||
                  sdp.physicalStatus === "Disconnected" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {sdp.physicalStatus === "E23" ||
                          sdp.physicalStatus === "Disconnected" ? (
                          <span className="text-danger">
                            {this.strings.thisPointError}{" "}
                            {this.strings.disconnected}
                          </span>
                        ) : sdp.physicalStatus === "E30" ||
                          sdp.physicalStatus === "Under construction" ? (
                          <span className="text-danger">
                            {this.strings.thisPointError}{" "}
                            {this.strings.underConstruction}
                          </span>
                        ) : null}
                      </strong>
                    </h6>
                  </div>
                ) : sdp.physicalStatus === "E23" ||
                  sdp.physicalStatus === "Disconnected" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {this.strings.thisPointError} {this.strings.disconnected}
                      </strong>
                    </h6>
                  </div>
                ) : sdp.physicalStatus === "E30" ||
                  sdp.physicalStatus === "Under construction" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {this.strings.thisPointError}{" "}
                        {this.strings.underConstruction}
                      </strong>
                    </h6>
                  </div>
                ) : null}
                <div className="d-flex align-items-center mt-4 mb-3">
                  <h4 className="text-primary font-weight-light m-0">
                    <strong>{this.strings.technical}</strong> {this.strings.point}
                  </h4>
                  <span
                    className="link text-secondary cursor-pointer text-underline ml-auto"
                    onClick={this.toggleDetails}
                  >
                    {!this.state.showDetails
                      ? this.strings.more
                      : this.strings.less}{" "}
                    {this.strings.details}
                  </span>
                </div>
                <table className="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>{this.strings.ean}</td>
                      <td>{sdp.ean}</td>
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.installation}</td>
                          <td>{sdp.installation_id}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.meterType}</td>
                          <td>{sdp.meterType}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.dataAvailableFrom}</td>
                          <td>{sdp.first_mv_dtlt}</td>
                        </tr>,
                      ]
                      : null}
                    <tr>
                      <td>{this.strings.operator}</td>
                      <td>{sdp.dgo}</td>
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.tariff}</td>
                          <td>{sdp.dgo_tariff_id}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.voltage}</td>
                          <td>{sdp.voltageLevel}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.nace}</td>
                          <td>{sdp.nacebelCode}</td>
                        </tr>,
                      ]
                      : null}
                    <tr>
                      <td>{this.strings.address}</td>
                      <td>
                        {sdp.addressStreet} {sdp.addressNumber}, {sdp.addressZip}{" "}
                        {sdp.addressCity}, {sdp.addressCountry}
                      </td>
                    </tr>
                    <tr>
                      <td>{this.strings.serviceComponent}</td>
                      <td>
                        {sdp.service_component ? sdp.service_component : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>{this.strings.serviceConfiguration}</td>
                      <td>{sdp.service_config ? sdp.service_config : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>{this.strings.physicalStatus}</td>
                      {
                        <td
                          className={
                            sdp.physicalStatus === "E23" ||
                              sdp.physicalStatus === "Disconnected" ||
                              sdp.physicalStatus === "E30" ||
                              sdp.physicalStatus === "Under construction"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {sdp.physicalStatus === "E23" ||
                            sdp.physicalStatus === "Disconnected" ? (
                            <span>{this.strings.disconnected}</span>
                          ) : sdp.physicalStatus === "E30" ||
                            sdp.physicalStatus === "Under construction" ? (
                            <span>{this.strings.underConstruction}</span>
                          ) : sdp.physicalStatus === "E22" ||
                            sdp.physicalStatus === "Connected" ? (
                            <span>{this.strings.connected}</span>
                          ) : null}
                        </td>
                      }
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.volume}</td>
                          <td>
                            {sdp.volume &&
                              sdp.volume !== 0 &&
                              sdp.volume !== null
                              ? sdp.volume + " MWh"
                              : "N/A"}
                          </td>
                        </tr>,
                        <tr>
                          <td>{this.strings.capacity}</td>
                          <td>
                            {sdp.grid_connection_capacity &&
                              sdp.grid_connection_capacity !== 0 &&
                              sdp.grid_connection_capacity !== null
                              ? sdp.grid_connection_capacity + " kVA"
                              : sdp.grid_connection_capacity === null
                                ? sdp.power + " kW"
                                : "N/A"}
                          </td>
                        </tr>,
                        <tr>
                          <td>{this.strings.preswitchDate}</td>
                          <td>
                            {sdp.preswitch_dtlt !== null
                              ? moment(
                                sdp.preswitch_dtlt,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD/MM/YYYY")
                              : "N/A"}
                          </td>
                        </tr>,
                      ]
                      : null}
                  </tbody>
                </table>
                <h4 className="text-primary mt-4 mb-3 font-weight-light">
                  <strong>{this.strings.mandate}</strong> {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>{this.strings.mandate}</td>
                      <td>
                        {sdp.mandate
                          ? sdp.validTo === null ||
                            moment(sdp.validTo).isAfter(moment())
                            ? this.strings.validMandate
                            : this.strings.endedMandate
                          : sdp.mandateSent
                            ? this.strings.requestSent
                            : this.strings.noMaterDataPermissions}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="text-primary mt-4 mb-3 font-weight-light">
                  <strong>{this.strings.contract}</strong> {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  {sdp.hasContract ? (
                    <tbody>
                      {this.state.showDetails
                        ? [
                          <tr>
                            <td>{this.strings.formula}</td>
                            <td>{this.strings.epexHourly}</td>
                          </tr>,
                          <tr>
                            <td>{this.strings.gsc}</td>
                            <td>
                              €{" "}
                              {numeral(
                                this.props.sdps.certificate_prices.priceGSC *
                                this.props.sdps.certificate_prices
                                  .contributionGSC
                              ).format("0.00")}{" "}
                              / MWh
                              <br />
                              {this.props.sdps.certificate_prices.priceGSC}{" "}
                              {this.strings.certificate_price}{" "}
                              {
                                this.props.sdps.certificate_prices
                                  .contributionGSC
                              }
                            </td>
                          </tr>,
                          <tr>
                            <td>{this.strings.wkc}</td>
                            <td>
                              €{" "}
                              {numeral(
                                this.props.sdps.certificate_prices.priceWKC *
                                this.props.sdps.certificate_prices
                                  .contributionWKC
                              ).format("0.00")}{" "}
                              / MWh
                              <br />
                              {this.props.sdps.certificate_prices.priceWKC}{" "}
                              {this.strings.certificate_price}{" "}
                              {
                                this.props.sdps.certificate_prices
                                  .contributionWKC
                              }
                            </td>
                          </tr>
                        ]
                        : null}
                      <tr>
                        <td>{this.strings.start}</td>
                        <td>
                          {sdp.startDate
                            ? moment(sdp.startDate).format("DD-MM-YYYY")
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                          <td>{this.strings.end}</td>
                          <td>
                            {sdp.endDate ? (
                              <div>
                                {moment(sdp.endDate).format("DD-MM-YYYY")}
                                <small className="form-text text-muted">
                                  <strong>{this.strings.note}</strong>{" "}
                                  {this.strings.endDateNotIncluded}{" "}
                                  {moment(sdp.endDate).format("DD-MM-YYYY")}
                                  {this.strings.endDateNotIncluded2}
                                </small>
                              </div>
                            ) : (
                              this.strings.month
                            )}
                          </td>
                        </tr>
                      <tr>
                        <td>{this.strings.fee}</td>
                        <td>
                          € {numeral(sdp.balancingFee).format("0.00")} / MWh
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.product}</td>
                        <td>
                          {sdp.product_name}
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.status}</td>
                        <td>
                          {sdp.coh_status}
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.contractId}</td>
                        <td>
                          {sdp.contract_id}
                        </td>
                      </tr>
                    </tbody>
                  ) : null}
                  <tbody>
                    {!sdp.hasContract ? (
                      <tr>
                        <td
                          colSpan="2"
                          className="bg-danger text-white text-center py-3"
                        >
                          <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                          <strong>
                            {this.strings.noContract}{" "}
                            <Link
                              to={`/services/onboarding#contract`}
                              className="text-white"
                            >
                              {this.strings.noContract2}
                            </Link>
                          </strong>
                        </td>
                      </tr>
                    ) : !sdp.signatureDate ? (
                      [
                        <br />,
                        <tr>
                          <td
                            colSpan="2"
                            className="bg-danger text-white text-center py-3"
                          >
                            <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                            <strong>
                              {this.strings.noSignedContract1}{" "}
                              <Link
                                to={`/documents/pendingAgreement/${this.props.selectedCompany.companyNumber}/supply`}
                                className="text-white"
                              >
                                {this.strings.noSignedContract2}
                              </Link>
                            </strong>
                          </td>
                        </tr>,
                      ]
                    ) : sdp.contract_status === "ended" ? (
                      [
                        <br />,
                        <tr>
                          <td
                            colSpan="2"
                            className="bg-warning text-white text-center py-3"
                          >
                            <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                            <strong>
                              {this.strings.endedContract1} {sdp.endDate}.{" "}
                              <Link
                                to={`/admin/manage-company#contracts`}
                                className="text-white"
                              >
                                {this.strings.endedContract2}
                              </Link>
                            </strong>
                          </td>
                        </tr>,
                      ]
                    ) : null}
                  </tbody>
                </table>
                {sdp.hasContract
                  ? [
                    <h4 className="text-primary mt-4 mb-3 font-weight-light">
                      <strong>{this.strings.switchInfoYuso}</strong>{" "}
                      {this.strings.point}
                    </h4>,
                    <table className="table table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>{this.strings.switchState}</td>
                          <td
                            className={
                              (!sdp.withYuso && sdp.contract_status === "active") ||
                                (sdp.withYuso && sdp.contract_status !== "active")
                                ? "text-danger"
                                : null
                            }
                          >
                            {sdp.withYuso
                              ? this.strings.withYuso
                              : this.strings.notWithYuso}
                          </td>
                        </tr>
                        {sdp.switchDate ? (
                          <tr>
                            <td>{this.strings.switchDate}</td>
                            <td>
                              {moment(sdp.switchDate).format("DD-MM-YYYY")}
                            </td>
                          </tr>
                        ) : null}
                        {sdp.switchStatus === "39" ? (
                          <tr>
                            <td>{this.strings.switchStatus}</td>
                            <td>{this.strings.switchApproved}</td>
                          </tr>
                        ) :
                          (sdp.switchStatus === '41' ? <tr><td>{this.strings.switchStatus}</td><td>{this.strings.switchRejected}</td></tr>
                            :
                            <tr><td>{this.strings.switchStatus}</td><td>{this.strings.notSwtiched}</td></tr>
                          )}
                        {sdp.switchStatusReason ? (
                          <tr>
                            <td>{this.strings.switchStatusReason}</td>
                            <td>{sdp.switchStatusReason}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{this.strings.switchStatusReason}</td>
                            <td>N/A</td>
                          </tr>
                        )}
                        {!this.state.showDetails && sdp.eocDate ? (
                          <tr>
                            <td>{this.strings.eocDate}</td>
                            <td>{moment(sdp.eocDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        ) : null}
                        {this.state.showDetails && sdp.switchDate
                          ? [
                            // <tr>
                            //   <td>{this.strings.switchStatus}</td>
                            //   <td>{sdp.switchStatus}</td>
                            // </tr>,
                            // <tr>
                            //   <td>{this.strings.switchStatusReason}</td>
                            //   {sdp.switchType === "Internal Switch" ? (
                            //     <td>{this.strings.internallySwitched}</td>
                            //   ) : (
                            //     <td>
                            //       {sdp.switchStatusReason} (
                            //       {sdp.switchStatusReasonDesc})
                            //     </td>
                            //   )}
                            // </tr>,
                            <tr>
                              <td>{this.strings.preswitchDate}</td>
                              <td>
                                {sdp.preswitch_dtlt !== null
                                  ? moment(
                                    sdp.preswitch_dtlt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YYYY")
                                  : "N/A"}
                              </td>
                            </tr>,
                          ]
                          : null}
                        {this.state.showDetails && sdp.eocDate
                          ? [
                            <tr>
                              <td>{this.strings.eocDate}</td>
                              <td>
                                {moment(sdp.eocDate).format("DD-MM-YYYY")}
                              </td>
                            </tr>,
                            <tr>
                              <td>{this.strings.eocStatus}</td>
                              <td>{sdp.eocStatus}</td>
                            </tr>,
                            <tr>
                              <td>{this.strings.eocStatusReason}</td>
                              {sdp.eocStatusReason === "COMPLETED" ? (
                                <td>
                                  {sdp.eocStatusReason} (
                                  {this.strings.internallySwitched})
                                </td>
                              ) : (
                                <td>
                                  {sdp.eocStatusReason} (
                                  {sdp.eocStatusReasonDesc})
                                </td>
                              )}
                            </tr>,
                          ]
                          : null}
                      </tbody>
                    </table>,
                  ]
                  : null}
                <h4 className="text-primary mt-4 mb-3 font-weight-light">
                  <strong>{this.strings.switchInfoMig6}</strong>{" "}
                  {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  <tbody>
                    {sdp.withYusoColumn === "Y" ? (
                      <tr>
                        <td>{this.strings.switchStatus}</td>
                        <td>{this.strings.inPortfolio}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.switchStatus}</td>
                        <td>{this.strings.notInPortfolio}</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_Startdate ? (
                      <tr>
                        <td>{this.strings.startDateMig6}</td>
                        <td>
                          {moment(sdp.runningprocesses_Startdate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.switchStatusReason}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_module ? (
                      <tr>
                        <td>{this.strings.moduleMig6}</td>
                        <td>{sdp.runningprocesses_module}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.moduleMig6}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_label ? (
                      <tr>
                        <td>{this.strings.labelMig6}</td>
                        <td>{sdp.runningprocesses_label}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.labelMig6}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_RequestorInvolvedBEAssociatedParty ? (
                      <tr>
                        <td>{this.strings.supplierMig6}</td>
                        <td>
                          {
                            sdp.runningprocesses_RequestorInvolvedBEAssociatedParty
                          }
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <hr className="mb-4" />
                <hr className="mb-4" />
              </DropdownPanel>
          );
        })}
      </div>
    );
  }
}

export default Offtake;
