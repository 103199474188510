import config from "./config";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
 
const userPool = new CognitoUserPool({
  UserPoolId: "us-east-1_1epcyobz1",
  ClientId: "r46go67fr9bj7b828t5ah656n",
});
 
const login = (user, pass, cb) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: user,
    Password: pass,
  });
  const cognitoUser = new CognitoUser({
    Username: user,
    Pool: userPool,
  });
 
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      cb(null, cognitoUser);
    },
    onFailure: (err) => {
      cb(err);
    },
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      cb({ newPassRequired: true });
    },
  });
};
 
const getUserInfo = (cb) => {
  const cognitoUser = userPool.getCurrentUser();
  window.cognitoUser = userPool.getCurrentUser();
 
  if (cognitoUser === null) return cb("User not logged in.");
 
  cognitoUser.getSession((err, session) => {
    if (err) {
      return cb(err);
    }
 
    cognitoUser.getUserAttributes(cb);
  });
};
 
const getTokens = (cb) => {
  // const user = JSON.parse(window.reactStorage.getItem("user"));
  // if (user.amazon) {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    // if no user found, clear the localStorage
    window.reactStorage.clear();
    cb("Current user not found in pool.");
    return (window.location = "/");
  }

  currentUser.getSession(cb);
}
//   else if (user.google) {
//     cb(null, {accessToken:{jwtToken:user.google.jwt_token}})
//   } else {
//     cb("Unknown identity provider.")
//   }
// };
 
const getAccessToken = (cb) => {
  getTokens((err, res) => {
    if (err) return cb(err);
 
    cb(null, res.accessToken.jwtToken);
  });
};
 
const newPassword = (user, oldPass, newPass, cb) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: user,
    Password: oldPass,
  });
  const cognitoUser = new CognitoUser({
    Username: user,
    Pool: userPool,
  });
 
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      // send notification of the Amazon Cognito account confirmation
      getAccessToken((err, token) => {
        if (err) return cb(err);
 
        fetch(`${config.BASE_URL}/api/users/userConfirmed`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: cognitoUser.username }),
        })
          .then(
            (res) => {
              return;
            } // don't do anything
          )
          .catch(
            (err) => {
              return;
            } // don't do anything
          );
      });
 
      // return the logged in user object
      cb(null, cognitoUser);
    },
    onFailure: (err) => {
      cb(err);
    },
    newPasswordRequired: function (userAttributes, requiredAttributes) {
      delete userAttributes.email_verified;
      delete userAttributes.phone_number_verified;
      cognitoUser.completeNewPasswordChallenge(newPass, userAttributes, this);
    },
  });
};
 
const getUsers = (company_id, query, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
 
    fetch(`${config.BASE_URL}/api/users/getUsers/${company_id}?q=${query}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};

const getUserCompany = (company_id, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getUserCompany?company_id=${company_id || ''}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data === null) {
          return cb(new Error("Received null response from server"));
        }
        cb(null, data);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        return cb(err);
      });
  });
};

const getUserCompanies = (cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getUserCompanies`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const getUsersForCompany = (company_id, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getUsersForCompany/${company_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
const getUserInfoForApproval = (company_id, user, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getUserInfoForApproval/${user}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const approveUser = (userData, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    const {
      id,
      email,
      firstName,
      lastName,
      companyName,
      company_id,
      managedBy,
      user_status,
      lang,
      strings
    } = userData;

    fetch(`${config.BASE_URL}/api/users/approve/${id}`,
 
    {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id, 
        email,
        firstName,
        lastName,
        companyName,
        company_id,
        managedBy,
        user_status,
        lang,
        strings
       })
    })
    .then((res) => {
      res.json().then((res) => {
        cb(null, res);
      })
    })
    .catch((err) => {
      return cb(err);
    });
  });
};

const rejectUser = (userData, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    const {
      id,
      email,
      firstName,
      lastName,
      companyName,
      company_id,
      managedBy,
      user_status,
      lang,
      strings
    } = userData;
    fetch(`${config.BASE_URL}/api/users/reject/${id}`,
 
    {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id,
        email,
        firstName,
        lastName,
        companyName,
        company_id,
        managedBy,
        user_status,
        lang,
        strings
       })
    })
    .then((res) => {
      res.json().then((res) => {
        cb(null, res);
      })
    })
    .catch((err) => {
      return cb(err);
    });
  });
};
 
const getUserDetails = (user_id, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getUserDetails/${user_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const addUser = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/addUser/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 


const editUser = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/editUser/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const linkUser = (company_id, users, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/linkUser/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ users }),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const sendContractProposal = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/sendContractProposal/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const sendContractActivation = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/sendContractActivation/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const sendConfirmationEmail = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/sendConfirmationEmail/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};

const sendApprovalEmail = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/sendApprovalEmail/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const sendMandate = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/sendMandate/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const signContract = (company_id, data, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/signContract/${company_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const unlinkUser = (company_id, user_id, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/unlinkUser/${company_id}/${user_id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const deleteUser = (company_id, user_id, block_user, comment, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(
      `${config.BASE_URL}/api/users/deleteUser/${company_id}/${user_id}?block_user=${block_user}&comment=${comment}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const checkCognitoAccount = (email, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/checkCognitoAccount`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const userCheck = (email, cb) => {
  fetch(`${config.BASE_URL}/api/user-check/${email}`)
      .then(res => res.json())
      .then(data => {
          const approved = data.approved;
          cb(null, data, approved); 
      })
      .catch(error => {
          cb(error);
      });
};

const forgotPasswordInitiate = (email, lang, cb) => {
  fetch(`${config.BASE_URL}/api/user-check/cognito`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      lang,
    }),
  })
    .then((res) => {
      res.json().then((res) => {
        if (res.status === "EXISTS_CONFIRMED") {
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
          });
          cognitoUser.forgotPassword({
            onSuccess: (data) => {
              return cb(null, data);
            },
            onFailure: (err) => {
              return cb(err);
            },
          });
        } else cb(null, res);
      });
    })
    .catch((err) => {
      return cb(err);
    });
};
 
const forgotPasswordComplete = (user, verificationCode, newPassword, cb) => {
  const cognitoUser = new CognitoUser({
    Username: user,
    Pool: userPool,
  });
 
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: () => {
      return cb();
    },
    onFailure: (err) => {
      return cb(err);
    },
  });
};
 
const getNotifications = (cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/getNotifications`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const setNotifications = (notifications, cb) => {
  getAccessToken((err, token) => {
    if (err) return cb(err);
    fetch(`${config.BASE_URL}/api/users/setNotifications`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notifications),
    })
      .then((res) => {
        res.json().then((res) => {
          cb(null, res);
        });
      })
      .catch((err) => {
        return cb(err);
      });
  });
};
 
const unsubscribe = (email, hash, cb) => {
  fetch(`${config.BASE_URL}/api/subscription/unsubscribe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, hash }),
  })
    .then((res) => {
      res.json().then((res) => {
        cb(null, res);
      });
    })
    .catch((err) => {
      return cb(err);
    });
};
 
const resubscribe = (email, hash, cb) => {
  fetch(`${config.BASE_URL}/api/subscription/resubscribe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, hash }),
  })
    .then((res) => {
      res.json().then((res) => {
        cb(null, res);
      });
    })
    .catch((err) => {
      return cb(err);
    });
};
 
export {
  login,
  newPassword,
  getAccessToken,
  getUserInfo,
  getUsers,
  getUserCompany,
  getUserCompanies,
  getUserInfoForApproval,
  getUsersForCompany,
  getUserDetails,
  addUser,
  approveUser,
  rejectUser,
  editUser,
  linkUser,
  sendContractProposal,
  sendContractActivation,
  sendConfirmationEmail,
  sendApprovalEmail,
  sendMandate,
  signContract,
  unlinkUser,
  deleteUser,
  checkCognitoAccount,
  userCheck,
  forgotPasswordInitiate,
  forgotPasswordComplete,
  getNotifications,
  setNotifications,
  unsubscribe,
  resubscribe
};