import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import moment from 'moment-timezone'

import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getSdps } from '../../api/sdps';

moment.tz.setDefault('Europe/Brussels')


class EanPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEans: [],
            sdps: {
                injection: undefined,
                offtake: undefined
            },
            sdpsList: undefined,
            options: [],
            selectAll: false,
            filter: '',
        }

        this.isSelected = this.isSelected.bind(this);
        this.displayValue = this.displayValue.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateEans = this.updateEans.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.getTooltip = this.getTooltip.bind(this);
        this.onFilter = this.onFilter.bind(this);

        this.strings = LocalizedStrings({
            en: {
                loading: "Loading available SDPs...",
                listSelected: "List of selected EAN(s):",
                etc: "etc.",
                selectEans: "Select EAN(s)",
                eansSelected: "EAN(s) selected",
                selectAll: "Select all",
                deselectAll: "Deselect all",
                noHourlySdp: "No SDPs available with an hourly contract.",
                contact: "Contact Yuso",
                moreInfo: "for more information.",
                notActive: "Contract ended on",
                noMatch: "No match",
                hasFlexibility: "Consult Yuso Flex for more details."
            },
            nl: {
                loading: "Laden van beschikbare SDPs...",
                listSelected: "Lijst met geselecteerde EAN(s):",
                etc: "enz.",
                selectEans: "Selecteer EAN(s)",
                eansSelected: "EAN(s) geselecteerd",
                selectAll: "Selecteer alles",
                deselectAll: "Deselecteer alles",
                noHourlySdp: "Geen aansluitingspunten beschikbaar met een uurcontract.",
                contact: "Neem contact op met Yuso",
                moreInfo: "voor meer informatie.",
                notActive: "Contract beëindigd op",
                noMatch: "Geen match",
                hasFlexibility: "Raadplegen Yuso Flex voor meer details."
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.updateEans();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    updateEans() {
        if (!this.props.selectedCompany) return;

        getSdps(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    sdps: {
                        injection: res.sdps_injection_hourly.filter(sdp => sdp.hasContract),
                        offtake: res.sdps_offtake_hourly.filter(sdp => sdp.hasContract),
                        production: res.sdps_production,
                        all: res.sdps_injection_hourly.concat(res.sdps_offtake_hourly).filter(sdp => sdp.hasContract)
                    }
                }, () => {

                    if (this.props.isCertificates)
                        this.setState({
                            sdpsList: this.state.sdps.injection
                        }, () => {
                            this.onSelectAll()
                        })

                    else if (this.props.isInjection)
                        this.setState({
                            sdpsList: this.state.sdps.injection
                        }, () => {
                            this.onChange({ target: { value: this.state.sdps.injection.length ? !this.props.hideFlexibility ? this.state.sdps.injection[0].ean : this.state.sdps.injection.find(x => !x.hasFlexibility) ? this.state.sdps.injection.find(x => !x.hasFlexibility).ean : null : null, checked: true } }, true);
                        })

                    else if (this.props.isProduction)
                        this.setState({
                            sdpsList: this.state.sdps.production
                        }, () => {
                            this.onChange({ target: { value: this.state.sdps.production.length ? this.state.sdps.production[0].ean : null, checked: true } }, true);
                        })

                    else this.setState({
                        sdpsList: this.state.sdps.offtake
                    }, () => {
                        this.onChange({ target: { value: this.state.sdps.offtake.length ? !this.props.hideFlexibility ? this.state.sdps.offtake[0].ean : this.state.sdps.offtake.find(x => !x.hasFlexibility) ? this.state.sdps.offtake.find(x => !x.hasFlexibility).ean : null : null, checked: true } }, true);
                    })

                });
            }
        );
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                openMenu: false,
                filter: ''
            })
        }
    }

    isSelected(ean) {
        return this.state.selectedEans.indexOf(ean) > -1
    }

    displayValue() {
        if (this.state.openMenu)
            return this.state.filter

        let label;

        if (this.state.sdpsList.length === 0)
            label = 'N/A';
        else if (this.state.selectedEans.length === 0)
            label = this.strings.selectEans;
        else if (this.state.selectedEans.length === 1)
            label = this.state.selectedEans[0];
        else if (this.state.selectedEans.length > 1)
            label = this.state.selectedEans.length + ' ' + this.strings.eansSelected;

        return label;
    }

    onClick() {
        if (this.state.sdpsList.length === 0) return;

        this.setState({
            openMenu: true,
            options: this.state.sdpsList,
            filter: this.state.openMenu ? this.state.filter : ''
        })
    }

    onChange(e, firstTime) {

        let selectedEans = [...this.state.selectedEans]

        if (e.target.checked) {
            selectedEans.push(e.target.value)
        }
        else {
            selectedEans = selectedEans.filter(x => x !== e.target.value)
        }

        this.setState({
            selectedEans
        }, () => {
            if (this.props.onChange) {
                let selectedSdps = this.state.sdpsList.filter(x => this.state.selectedEans.indexOf(x.ean) > -1)

                this.props.onChange(selectedSdps, this.state.sdpsList, firstTime);
            }
        });
    }

    onFilter(e) {
        let val = e.target.value.toUpperCase()

        this.setState({
            filter: val,
            options: this.state.sdpsList.filter(x => x.ean.indexOf(val) > -1)
        })
    }

    onSelectAll() {

        let selectAll = !this.state.selectAll

        let selectedEans = selectAll ? this.state.sdpsList.map(x => this.props.hideFlexibility && x.hasFlexibility ? null : x.ean) : []

        this.setState({
            selectAll,
            selectedEans
        }, () => {
            if (this.props.onChange) {
                let selectedSdps = this.state.sdpsList.filter(x => this.state.selectedEans.indexOf(x.ean) > -1)

                this.props.onChange(selectedSdps, this.state.sdpsList);
            }
        });
    }

    getTooltip(eans) {

        const limit = 10

        if (!eans.length)
            return 'No EAN(s) selected'
        else
            return `<div class='eans-tooltip'>${this.strings.listSelected}<br/><ul>${eans.slice(0, limit).map(x => `<li>${x}</li>`).join('')}${eans.length > limit ? `<li>${this.strings.etc}</li>` : ''}</ul></div>`
    }

    render() {
        if (!this.state.sdpsList) {
            return (
                <div className="mb-2 mr-sm-2">
                    <Loader inline="true">{this.strings.loading}</Loader>
                </div>
            );
        }

        if (!this.props.hideFlexibility ? !this.state.sdpsList.length : !this.state.sdpsList.filter(x => !x.hasFlexibility).length) {
            return (
                <div className="text-center mb-4 w-100">
                    {this.strings.noHourlySdp} <a href="mailto:info@yuso.be">{this.strings.contact}</a> {this.strings.moreInfo}
                    <br />
                </div>
            );
        }

        return (
            <div className="ean-dropdown" ref={this.setWrapperRef}>
                <input className="form-control picker-select mb-2 mr-sm-2" style={{ width: '245px' }} value={this.displayValue()} onClick={this.onClick} onChange={this.onFilter} disabled={this.props.disabled && !this.state.openMenu} data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.getTooltip(this.state.selectedEans)} />
                {
                    this.state.openMenu ?
                        <div className="menu d-block">
                            <div className="form-check d-flex justify-content-start align-items-center m-3 pb-2 border-bottom border-warning">
                                <input className="form-check-input" type="checkbox" id="select-all" checked={this.state.selectAll} onChange={this.onSelectAll} />
                                <label className="form-check-label" htmlFor="select-all"><span className="font-weight-bold">{!this.state.selectAll ? this.strings.selectAll : this.strings.deselectAll}</span></label>
                            </div>
                            {
                                !this.state.options.length ?
                                    <div className="form-check d-flex justify-content-start align-items-center m-3">
                                        <label className="form-check-label">{this.strings.noMatch}</label>
                                    </div>
                                    : this.state.options.map(
                                        (sdp) => {
                                            return (
                                                <div key={sdp.ean} className="form-check d-flex justify-content-start align-items-center m-3">
                                                    {
                                                        sdp.contract_status === 'ended' ? <i className="fas fa-exclamation-circle text-danger mr-2" title={this.strings.notActive + ' ' + sdp.endDate} data-toggle="tooltip"></i> : null
                                                    }
                                                    {
                                                        this.props.hideFlexibility && sdp.hasFlexibility ? <i className="fas fa-exclamation-circle text-warning mr-2" title={this.strings.hasFlexibility} data-toggle="tooltip"></i> : null
                                                    }
                                                    <input className="form-check-input mr-2" type="checkbox" value={sdp.ean} id={'ean_' + sdp.ean} onChange={this.onChange} checked={this.isSelected(sdp.ean)} disabled={this.props.hideFlexibility && sdp.hasFlexibility} />
                                                    <label className="form-check-label text-truncate" htmlFor={'ean_' + sdp.ean} title={`${sdp.ean} – ${sdp.addressStreet} ${sdp.addressNumber}, ${sdp.addressZip} ${sdp.addressCity}, ${sdp.addressCountry}`}>
                                                        {sdp.ean} &nbsp;&nbsp;&ndash;&nbsp;&nbsp; {sdp.addressStreet} {sdp.addressNumber}, {sdp.addressZip} {sdp.addressCity}, {sdp.addressCountry}
                                                    </label>
                                                </div>
                                            )
                                        }
                                    )
                            }
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default EanPicker;
