import React, { Component } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment-timezone'
import numeral from 'numeral';

import EanPicker from './EanPicker';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getPrices } from '../../api/certificates';

moment.tz.setDefault('Europe/Brussels')

const periods = ['last12months', 'last24months']


class Certificates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: 'last12months',
      prices: undefined,
      produced: undefined,
      sdps: []
    }

    this.onEanChange = this.onEanChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.getCertPrices = this.getCertPrices.bind(this);
    this.getChartPrices = this.getChartPrices.bind(this);
    this.getChartGvoProduced = this.getChartGvoProduced.bind(this);

    this.strings = LocalizedStrings({
      en: {
        fetching: "Fetching certificates...",
        last12months: "Last 12 months",
        last24months: "Last 24 months",
        pricesGvo: "Number of Guarantees of Origin",
        amountsProduced: "Amounts produced",
        amountsReceived: "Amounts received",
        go: "GO",
        price: "price",
        total: "Total",
        income: "income",
        missing: "Missing",
        prices: "GSC and WKC certificate prices for supply (€ per certificate)",
        GSCprice: "GSC price",
        WKCprice: "WKC price"
      },
      nl: {
        fetching: "Certificaten ophalen...",
        last12months: "Laatste 12 maanden",
        last24months: "Laatste 24 maanden",
        pricesGvo: "Aantal Garanties van Oorsprong",
        amountsProduced: "Geproduceerde bedragen",
        amountsReceived: "Ontvangen bedragen",
        go: "GO",
        price: "prijs",
        total: "Totale",
        income: "inkomsten",
        missing: "Ontbrekende",
        prices: "GSC en WKC certificaatprijzen voor levering (€ per certificaat)",
        GSCprice: "GSC prijs",
        WKCprice: "WKC prijs"
      }
    }, this.props.lang);
  }

  onEanChange(sdps) {
    this.setState({
      sdps
    }, () => {
      this.getCertPrices();
    })
  }

  onPeriodChange(event) {
    let period = event.target.value

    this.setState({ period })
  }

  getCertPrices() {
    if (!this.props.selectedCompany) return;

    getPrices(this.props.selectedCompany.company_id, { eans: this.state.sdps.map(x => x.ean) },
      (err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        prices: res.prices,
        produced: res.produced.reverse(),
        received: res.received.reverse()
      });
    })
  }

  getChartPrices() {
    let period = 12;

    switch (this.state.period) {
      case 'last12months':
        period = 12
        break;

      case 'last24months':
        period = 24
        break;

      default:
        break;
    }

    const start = this.state.prices.length > period ? this.state.prices.length - period : 0
    const prices = this.state.prices.slice(start, this.state.prices.length);

    const ticks = prices.map(x => x.periodFormated);

    return {
      prices,
      ticks
    }
  }

  getChartGvoProduced() {

    if (!this.state.produced || !this.state.received)
      return {}

    let period = 12;

    switch (this.state.period) {
      case 'last12months':
        period = 12
        break;

      case 'last24months':
        period = 24
        break;

      default:
        break;
    }

    const startp = this.state.produced.length > period ? this.state.produced.length - period : 0
    const produced = this.state.produced.slice(startp, this.state.produced.length);

    const startr = this.state.received.length > period ? this.state.received.length - period : 0
    const received = this.state.received.slice(startr, this.state.received.length);

    const ticks = produced.map(x => x.period);

    const data = produced.map(x => {
      let d = received.find(y => y.period === x.period);
      
      x.received = 0;
      if (d)
        x.received = d.received

      return x
    })

    return {
      data,
      ticks
    }
  }

  render() {

    let cert = this.getChartGvoProduced()

    let totalProduced = 0, totalReceived = 0, missing;

    if (cert.data)
      totalProduced = cert.data.reduce(
        (carry, el) => { return carry + el.produced },
        0
      );

    if (cert.data)
      totalReceived = cert.data.reduce(
        (carry, el) => { return carry + el.received },
        0
      );

    missing = totalProduced - totalReceived;

    return (
      <div>
        <div className="row">
          <div className="col-sm py-2 mb-4">
            <form className="form-inline d-flex flex-column flex-md-row justify-content-center" onSubmit={(e) => { e.preventDefault() }}>
              <EanPicker
                isCertificates={true}
                onChange={this.onEanChange}
                selectedCompany={this.props.selectedCompany}
                lang={this.props.lang}
              />

              <select className="form-control mb-2 mr-sm-2" onChange={this.onPeriodChange} value={this.state.period}>
                {
                  periods.map(period => {
                    return <option key={period} value={period} onClick={this.onPeriodChange}>{this.strings[period]}</option>
                  })
                }
              </select>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {
              this.state.prices || this.state.produced || this.state.received ?
                <div>
                  {
                    this.state.produced.length || this.state.received.length ?
                      <div>
                        <div className="row mt-3 mb-5">
                          <div className="col-sm d-flex justify-content-around flex-column flex-md-row">
                            <div className="mb-2 text-center">
                              <h5 className="text-primary font-weight-light mb-2">
                                {this.strings.go} <span className="font-weight-bold">{this.strings.price}</span>
                              </h5>
                              <h4 className="text-primary font-weight-light">
                                {this.state.sdps[0] ? `€${this.state.sdps[0].GOprice} per GO` : 'N/A'}
                              </h4>
                            </div>
                            <div className="border-right border-warning w-0 mb-2"></div>
                            <div className="mb-2 text-center">
                              <h5 className="text-primary font-weight-light mb-2">
                                {this.strings.total} <span className="font-weight-bold">{this.strings.income}</span>
                              </h5>
                              <h4 className="text-primary font-weight-light">
                                €{numeral(totalReceived * (this.state.sdps[0] ? this.state.sdps[0].GOprice : 0)).format('0,000.00')}
                              </h4>
                            </div>
                            <div className="border-right border-warning w-0 mb-2"></div>
                            <div className="mb-2 text-center">
                              <h5 className="text-primary font-weight-light mb-2">
                                <span className="font-weight-bold">{this.strings.missing}</span> {this.strings.go}
                              </h5>
                              <h4 className="text-primary font-weight-light">
                                {missing ? `€${numeral(missing * (this.state.sdps[0] ? this.state.sdps[0].GOprice : 0)).format('0,000.00')}` : 'N/A'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-primary text-center">{this.strings.pricesGvo}</h4>
                        <div className="row mb-5">
                          <div className="col-sm height-300">
                            <ResponsiveContainer>
                              <AreaChart data={cert.data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                  <linearGradient id="colorProduced" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#006087" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#006087" stopOpacity={0.1} />
                                  </linearGradient>
                                  <linearGradient id="colorReceived" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#dddc01" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#dddc01" stopOpacity={0.1} />
                                  </linearGradient>
                                </defs>
                                <Legend verticalAlign="top" height={36} />
                                <XAxis
                                  dataKey="period"
                                  minTickGap={15}
                                  ticks={cert.ticks}
                                />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Area
                                  name={this.strings.amountsProduced}
                                  dataKey="produced"
                                  stroke="#006087"
                                  fillOpacity={1} fill="url(#colorProduced)"
                                  type="stepAfter"
                                  dot={false}
                                />
                                <Area
                                  name={this.strings.amountsReceived}
                                  dataKey="received"
                                  stroke="#dddc01"
                                  fillOpacity={1} fill="url(#colorReceived)"
                                  type="stepAfter"
                                  dot={false}
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>
                      : null
                  }
                  <h4 className="text-primary text-center">{this.strings.prices}</h4>
                  <div className="row mb-5">
                    <div className="col-sm height-300">
                      <ResponsiveContainer>
                        <AreaChart data={this.getChartPrices().prices} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                          <defs>
                            <linearGradient id="colorGCS" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#006087" stopOpacity={0.8} />
                              <stop offset="95%" stopColor="#006087" stopOpacity={0.1} />
                            </linearGradient>
                            <linearGradient id="colorWKC" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#dddc01" stopOpacity={0.8} />
                              <stop offset="95%" stopColor="#dddc01" stopOpacity={0.1} />
                            </linearGradient>
                          </defs>
                          <Legend verticalAlign="top" height={36} />
                          <XAxis
                            dataKey="periodFormated"
                            minTickGap={15}
                            ticks={this.getChartPrices().ticks}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area
                            name={this.strings.GSCprice}
                            dataKey="priceGSC"
                            stroke="#006087"
                            fillOpacity={1} fill="url(#colorGCS)"
                            type="stepAfter"
                            dot={false}
                          />
                          <Area
                            name={this.strings.WKCprice}
                            dataKey="priceWKC"
                            stroke="#dddc01"
                            fillOpacity={1} fill="url(#colorWKC)"
                            type="stepAfter"
                            dot={false}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                :
                <Loader>{this.strings.fetching}</Loader>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Certificates;
