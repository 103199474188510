import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { uploadSwitchConfirmation } from '../../api/documents';

import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone'

import 'moment/locale/nl-be';
import 'react-day-picker/lib/style.css'

moment.tz.setDefault('Europe/Brussels')


class UploadSwitchConfirmation extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      selectedEan: {},
      direction: '',
      requestDate: new Date(),
      switchDate: new Date(),
    }

    this.state = {
      ...this.initialState,
      confirmation_file: undefined,
      sdps: []
    }

    this.uploadSwitchConfirmation = this.uploadSwitchConfirmation.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onRequestDateChange = this.onRequestDateChange.bind(this);
    this.onSwitchDateChange = this.onSwitchDateChange.bind(this);
    this.onDirectionChange = this.onDirectionChange.bind(this);
    this.onSwitchTypeChange = this.onSwitchTypeChange.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Missing required field",
        error: "Error while uploading the switch confirmation. Please try again.",
        errors: "These errors occurred:",
        uploaded: "Switch confirmation was successfully uploaded!",
        switch: "Upload Switch Confirmation",
        autoSwitchWarning: "This operation is now performed automatically by the auto-verify switch process, so only upload switch confirmation if it does not yet exists.",
        loadingStatus: "Loading agreements status...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        ean: "EAN",
        select: "Select EAN...",
        loading: "Loading EANs...",
        confirmationFile: "Choose switch confirmation file",
        requestDate: "Request date",
        switchDate: "Switch date",
        upload: "Upload",
        startDate: "Start date",
        noContract: "No contract",
        signed: "Contract signed",
        unsigned: "Contract not signed",
        injection: "Injection",
        offtake: "Supply",
        production: "Production",
        offtakeInjection: "Supply & Injection",
        switchType: "Switch type",
        direction: "Direction"
      },
      nl: {
        errorRequired: "Verplicht veld ontbreekt",
        error: "Fout tijdens het uploaden van de switchbevestiging. Probeer het opnieuw.",
        errors: "Deze fouten zijn opgetreden:",
        uploaded: "Switchbevestiging is succesvol geüpload!",
        switch: "Upload Switchbevestiging",
        autoSwitchWarning: "Deze bewerking wordt nu automatisch uitgevoerd door het automatische verificatie-schakelproces, dus gebruik dit enkel als er nog geen schakelbevestiging is.",
        loadingStatus: "Status switchbevestiging laden ...",
        noSdp: "Het bedrijf heeft geen aansluitingen.",
        addSdp: "Voeg nieuwe aansluiting toe",
        ean: "EAN",
        select: "Selecteer EAN...",
        loading: "EANs laden...",
        confirmationFile: "Kies bestand",
        requestDate: "Datum van aanvraag",
        switchDate: "Switch datum",
        upload: "Uploaden",
        startDate: "Startdatum",
        noContract: "Geen contract",
        signed: "Contract ondertekend",
        unsigned: "Contract niet getekend",
        injection: "Injectie",
        offtake: "Levering",
        production: "Productie",
        offtakeInjection: "Levering & Injectie",
        switchType: "Overstap type",
        direction: "Richting"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.sdps)
      return {
        sdps: nextProps.sdps.filter(x => x.type !== 'production').sort((x, y) => (!x.hasContract || x.hasContract < y.hasContract) ? 1 : -1)
      }
    else return null
  }

  uploadSwitchConfirmation(e) {
    e.preventDefault();

    this.setState({ errorUploadSwitchConfirmation: false });

    let error = [];

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)
      }
    }

    if (error.length) {
      this.setState({ errorUploadSwitchConfirmation: error })
      return;
    }

    uploadSwitchConfirmation(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ errorUploadSwitchConfirmation: [this.strings.error] });
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.uploaded }, () => {
            this.props.updateCompanies(this.props.selectedCompany.company_id, true);
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  onSelect(selection) {
    if (selection.length) {
      const newSelectedEan = selection[0];
      this.setState({
        selectedEan: newSelectedEan,
        requestDate: newSelectedEan.requestDate ? moment(newSelectedEan.requestDate).toDate() : new Date(),
        switchDate: newSelectedEan.switchDate ? moment(newSelectedEan.switchDate).toDate() : new Date(),
        direction: newSelectedEan.direction || '',
        switchType: newSelectedEan.switchType || ''
      });
    } else {
      this.setState({ ...this.initialState });
    }
  }

  onFileChange(e) {
    const file = e.target.files[0];
    const _this = this;

    if (file) {

      let reader = new FileReader();

      reader.onloadend = () => {
        _this.setState({
          confirmation_file: {
            base64: reader.result.split(',').pop(),
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate
          }
        })
      }

      reader.readAsDataURL(file);
    }
  }

  onRequestDateChange(requestDate) {
    this.setState({
      requestDate,
      selectedEan: { ...this.state.selectedEan, requestDate: requestDate }
    });
  }

  onSwitchDateChange(switchDate) {
    this.setState({
      switchDate,
      selectedEan: { ...this.state.selectedEan, switchDate: switchDate }
    });
  }

  onDirectionChange(event) {
    const direction = event.target.value;
    this.setState({
      direction,
      selectedEan: {
        ...this.state.selectedEan,
        direction: direction
      },
    });
  }

  onSwitchTypeChange(event) {
    const switchType = event.target.value;
    this.setState({
      switchType,
      selectedEan: {
        ...this.state.selectedEan,
        switchType: switchType
      },
    });
  }


  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.switch}
        </div>
        <div className="card-body">
          {
            !this.props.sdps ?
              <Loader inline="true" className="mt-2 mb-3">{this.strings.loadingStatus}</Loader> :
              <div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.uploadSwitchConfirmation} noValidate>

                      <p className="alert alert-warning">
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.autoSwitchWarning}
                      </p>

                      <div className="form-group row">
                        <label htmlFor="uploadSwitchConfirmationEAN" className="col-sm-3 col-form-label">{this.strings.ean}</label>
                        <div className="col-sm-9 special-select">
                          <Typeahead
                            id="uploadSwitchConfirmationEAN"
                            options={this.state.sdps}
                            labelKey={x => `${x.ean}`}
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {results.map((x) => (
                                  <MenuItem option={x} key={x.ean} disabled={!x.hasContract}>
                                    {x.ean} <span>| {this.strings[x.type_sdp_label || x.type_sdp]}{!x.hasContract ? (' | ' + this.strings.noContract) : ` | ${x.signatureDate === null ? this.strings.unsigned : this.strings.signed} | ${this.strings.startDate}: ${x.startDate}`}</span>
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                            onChange={this.onSelect}
                            filterBy={!this.state.selectedEan.ean ? undefined : () => true}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="SdpDirection" className="col-sm-3 col-form-label">Direction</label>
                        <div className="col-sm-9 special-select">

                          <select className="form-control" name="direction" value={this.state.direction} onChange={this.onDirectionChange} required>
                            <option value="" disabled selected></option>
                            {this.state.selectedEan.service_component === "SC_OFFTAKE" ||
                              this.state.selectedEan.service_component === "SC_COMMOFF" ? (
                              <>
                                <option value="Supply">Supply</option>
                                <option value="Injection+Supply">Injection and Supply</option>
                              </>
                            ) : this.state.selectedEan.service_component === "SC_OFFINJE" ? (
                              <option value="Injection+Supply">Injection and Supply</option>
                            ) : this.state.selectedEan.service_component === "SC_INJECTI" ||
                              this.state.selectedEan.service_component === "SC_COMMINJ" ? (
                              <>
                                <option value="Injection">Injection</option>
                                <option value="Injection+Supply">Injection and Supply</option>
                              </>
                            ) : null}
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="SdpSwitchType" className="col-sm-3 col-form-label">{this.strings.switchType}</label>
                        <div className="col-sm-9 special-select">

                          <select className="form-control" name="switchType" value={this.state.switchType} onChange={this.onSwitchTypeChange} required>
                            <option value="" disabled selected></option>
                            <>
                              <option value="Supplier Switch">Supplier Switch</option>
                              <option value="Move-in">Move-in </option>
                              <option value="Initiate Local Production">Initiate Local Production (ILP)</option>
                            </>
                          </select>
                        </div>
                      </div>

                      <div className="custom-file mb-3">
                        <input type="file" className="custom-file-input" name="confirmation_file" onChange={this.onFileChange} required />
                        <label className="custom-file-label" htmlFor="confirmation_file">{this.state.confirmation_file ? this.state.confirmation_file.name : this.strings.confirmationFile}</label>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="request_date" className="col-sm-6 col-form-label">{this.strings.requestDate}</label>
                        <div className="col-sm-6">
                          <DayPickerInput
                            value={this.state.requestDate}
                            required
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'request_date', name: 'request_date', required: true }}
                            dayPickerProps={{
                              selectedDays: this.state.requestDate,
                              localeUtils: MomentLocaleUtils,
                              locale: this.props.lang
                            }}
                            onDayChange={this.onRequestDateChange}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="confirmation_switch_date" className="col-sm-6 col-form-label">{this.strings.switchDate}</label>
                        <div className="col-sm-6">
                          <DayPickerInput
                            value={this.state.switchDate}
                            required
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'confirmation_switch_date', name: 'switch_date', required: true }}
                            dayPickerProps={{
                              selectedDays: this.state.switchDate,
                              localeUtils: MomentLocaleUtils,
                              locale: this.props.lang
                            }}
                            onDayChange={this.onSwitchDateChange}
                          />
                        </div>
                      </div>

                      <div className={'alert alert-warning mb-3 ' + (this.state.errorUploadSwitchConfirmation ? 'd-block' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i>{this.strings.errors}
                        <ul>
                          {
                            this.state.errorUploadSwitchConfirmation ? this.state.errorUploadSwitchConfirmation.sort().map(error => (<li key={error}>{error}</li>)) : null
                          }
                        </ul>
                      </div>

                      <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                        <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                      </p>

                      <button type="submit" className="btn btn-primary float-right">{this.strings.upload}</button>
                    </form>
                }
              </div>
          }
        </div>
      </div>
    )
  }
}

export default UploadSwitchConfirmation;
