import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import LanguageChooser from './LanguageChooser';

class Form extends Component {
  render() {
    return (
      <div>
        <header className="row">
          <div className="col-sm py-4 d-flex">
            <Link to="/" className="mr-auto">
              <img
                style={{width:'250px', marginLeft:'40px'}}
                src={`${process.env.PUBLIC_URL}/yuso-portal.png`}
                alt='Yuso'
              />
            </Link>
            <LanguageChooser lang={this.props.lang} changeLanguage={this.props.changeLanguage} />
          </div>
        </header>
        <div className="row">
          <div className="col-md">
            {this.props.children}
          </div>
          <div className="col-md p-0">
            <img
              style={{ maxWidth: '100%' }}
              src={`${process.env.PUBLIC_URL}/solar-wind.jpg`}
              alt="Solar/Wind"
            />
          </div>
        </div>
        <Footer lang={this.props.lang} />
      </div>
    );
  }
}

export default Form;
