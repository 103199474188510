import React, { Component } from 'react';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import { getBONWI } from '../../api/documents';

class BONWI extends Component {
  constructor(props) {
    super(props);
    this.state = {
        bonwi: undefined
    };

    this.getBONWI = this.getBONWI.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading BONWI PDF...",
        notFound: "No BONWI PDF found."
      },
      nl: {
        loading: "BONWI PDF laden ...",
        notFound: "Geen BONWI PDF gevonden."
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.getBONWI();
  }
  getBONWI() {

    getBONWI(this.props.lang, (err, res) => {
      if (err)
        return window.reportError.send(err);
  
      if (res.error)
        this.setState({ error: this.strings.notFound })
      else {
        this.setState({ bonwi: res || 'about:blank'
        });
      }
    });
  }

  render() {
    if (!this.state.bonwi) {
      return (<Loader>{this.strings.loading}</Loader>);
    }
    return (
      <div className="row">
        <div className="col-sm p-0" >
          <iframe name="bonwi" id="bonwi" width="100%" height="800px" title="bonwi" src={this.state.bonwi}></iframe>
        </div>
      </div>
    );
  }
}

export default BONWI;