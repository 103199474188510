import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from '../components/Localization';

import { forgotPasswordInitiate, forgotPasswordComplete } from '../api/user';

class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            emailSend: false,
            verificationCode: '',
            newPass: '',
            passwordChangeAttempted: false,
            passwordChangeSuccessfull: false,
            newPassError: '',
            newPassStatus: ''
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(this);
        this.handleNewPassChange = this.handleNewPassChange.bind(this);

        this.handleForgotPasswordInitiate = this.handleForgotPasswordInitiate.bind(this);
        this.handleForgotPasswordComplete = this.handleForgotPasswordComplete.bind(this);

        this.strings = LocalizedStrings({
            en: {
                error: "Error occurred, please try again",
                errorNotExist: "User does not exist, try creating new account at yuso.be",
                statusRecreatedUser: "Your user was recreated and confirmation email was sent to your inbox",
                statusCheckEmail: "Please check your email inbox for verification code",
                forgot: "Enter your email address below",
                enterEmail: "to receive a verification code",
                email: "E-mail",
                sendEmail: "Send Verification Email",
                enterCode: "Enter the verification code",
                received: "check your email inbox",
                code: "Verification Code",
                newPassword: "New Password",
                change: "Change Password",
                passwordChanged: "Your password has been successfully changed",
                login: "you can log in to My Yuso",
                passwordError: "Something went wrong while resetting your password",
                tryAgain: "please try again"
            },
            nl: {
                error: "Er is een fout opgetreden, probeer het opnieuw",
                errorNotExist: "Gebruiker bestaat niet, probeer een nieuw account aan te maken op yuso.be",
                statusRecreatedUser: "Jouw gebruiker is opnieuw gemaakt en de bevestigings e-mail is naar jouw inbox verzonden",
                statusCheckEmail: "Controleer je e-mailinbox voor verificatiecode",
                forgot: "Voer je e-mailadres in",
                enterEmail: "om een verificatie code te ontvangen",
                email: "E-mail",
                sendEmail: "Verzend verificatie-e-mail",
                enterCode: "Vul de verificatie code in",
                received: "verificatie code werd verzonden. Gelieve je inbox te controleren.",
                code: "Verificatie code",
                newPassword: "nieuw paswoord",
                change: "Verander je wachtwoord",
                passwordChanged: "Jouw wachtwoord is succesvol veranderd",
                login: "Je kan nu inloggen op My Yuso",
                passwordError: "Er ging iets mis bij het opnieuw instellen van jouw wachtwoord",
                tryAgain: "probeer het opnieuw"
            }
        }, this.props.lang);
    }

    handleEmailChange(e){
        this.setState({
            email: e.target.value
        });
        e.preventDefault();
    }

    handleVerificationCodeChange(e){
        this.setState({
            verificationCode: e.target.value
        });
        e.preventDefault();
    }

    handleNewPassChange(e){
        this.setState({
            newPass: e.target.value
        });
        e.preventDefault();
    }

    handleForgotPasswordInitiate(e){
        e.preventDefault();
        const self = this;
        
        self.setState({
            newPassError: '',
            newPassStatus: ''
        });
        forgotPasswordInitiate(
            this.state.email,
            this.props.lang,
            (err, res) => {
                
                if (!res || (res && res.error)) {
                    self.setState({
                        newPassError: this.strings.error
                    });
                    return
                }
                if (res.status === 'NOT_EXISTS') {
                    self.setState({
                        newPassError: this.strings.errorNotExist
                    });
                }
                else if (res.status === 'EXISTS_NOT_CONFIRMED') {
                    self.setState({
                        newPassStatus: this.strings.statusRecreatedUser
                    });
                }
                else {
                    self.setState({
                        newPassStatus: this.strings.statusCheckEmail
                    });
                    if (err) {
                        window.reportError.send(err);

                        self.setState({
                            newPassError: err.message
                        });
                    }else{
                        self.setState({
                            newPassError: null,
                            emailSend: true
                        });
                    }
                }
            }
        );
    }

    handleForgotPasswordComplete(e){
        e.preventDefault();
        const self = this;
        forgotPasswordComplete(
            this.state.email,
            this.state.verificationCode,
            this.state.newPass,
            (err, res) => {
                if (err) {
                    window.reportError.send(err);
                    self.setState({
                        newPassError: err.message,
                        passwordChangeAttempted: true,
                        passwordChangeSuccessfull: false
                    });
                }else{
                    self.setState({
                        newPassError: null,
                        passwordChangeAttempted: true,
                        passwordChangeSuccessfull: true
                    });
                }
            }
        );
    }

    render() {
        return (
            <div>
                {(!this.state.emailSend)?this.renderSendEmail():""}
                {(this.state.emailSend && !this.state.passwordChangeAttempted)?this.renderChangePassword():""}
                {(this.state.passwordChangeAttempted && !this.state.passwordChangeSuccessfull)?this.renderFail(): ""}
                {(this.state.passwordChangeAttempted && this.state.passwordChangeSuccessfull)?this.renderSuccess(): ""}
            </div>
        );
    }

    renderSendEmail() {
        return (
            <form onSubmit={this.handleForgotPasswordInitiate} className="px-5 py-4">
                <h1 className="form-title mb-3">
                    <strong>{this.strings.forgot},</strong>
                    <br />
                    {this.strings.enterEmail}
                </h1>
                <div className={"alert alert-danger " + (this.state.newPassError ? "" : "invisible")}>
                    {this.state.newPassError}
                </div>
                <div className={"alert alert-info " + (this.state.newPassStatus ? "" : "d-none")}>
                    {this.state.newPassStatus}
                </div>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        id="inputVerificationCode"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        placeholder={this.strings.email}
                        autoComplete="off"
                        type="email"
                    />
                </div>
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        className={"btn btn-warning float-right px-5 py-3"}
                    >
                        {this.strings.sendEmail} <i className="fas fa-paper-plane"></i>
                    </button>
                </div>
            </form>
        );
    }

    renderChangePassword(){
        return (
            <form onSubmit={this.handleForgotPasswordComplete} className="px-5 py-4">
                <h1 className="form-title mb-3">
                    <strong>{this.strings.enterCode},</strong>
                    <br />
                    {this.strings.received}
                </h1>
                <div className={"alert alert-danger " + (this.state.newPassError ? "" : "invisible")}>
                    {this.state.newPassError}
                </div>
                <div className={"alert alert-info " + (this.state.newPassStatus ? "" : "d-none")}>
                    {this.state.newPassStatus}
                </div>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        id="inputVerificationCode"
                        value={this.state.verificationCode}
                        onChange={this.handleVerificationCodeChange}
                        placeholder={this.strings.code}
                        autoComplete="off"
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        id="inputNewPass"
                        value={this.state.newPass}
                        onChange={this.handleNewPassChange}
                        placeholder={this.strings.newPassword}
                        autoComplete="off"
                        type="password"
                    />
                </div>
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        className={"btn btn-warning float-right px-5 py-3"}
                    >
                        {this.strings.change} <i className="fas fa-key"></i>
                    </button>
                </div>
            </form>
        );
    }

    renderSuccess(){
        return (
            <h4 className="h-100 d-flex justify-content-center align-items-center text-center p-5" style={{ lineHeight: '2.5rem' }}>
                <span>
                    {this.strings.passwordChanged}, <Link to="/">{this.strings.login}</Link>
                </span>
            </h4>
        );
    }

    renderFail(){
        return (
            <h4 className="h-100 d-flex justify-content-center align-items-center text-center p-5" style={{lineHeight:'2.5rem'}}>
                <span>
                    {this.strings.passwordError}, <Link to="/forgot-password">{this.strings.tryAgain}</Link>
                </span>
            </h4>
        );
    }
}

export default ForgotPassword;
