import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from '../Localization';

import { getPartners } from '../../api/partner';


class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: []
        };

        this.timer = null;

        this.updatePartners = this.updatePartners.bind(this);

        this.strings = LocalizedStrings({
            en: {
                partnerTitle: "Partner",
                loading: "Loading partners...",
                first: "First Name",
                last: "Last Name",
                email: "E-mail",
                phone: "Phone",
                role: "Role",
                user: "user",
                partner: "partner",
                admin: "admin",
                partnerLabel: "Partner",
                notifications: "Notifications",
                unsubscribed: "Unsubscribed",
                subscribed: "Subscribed",
                note: "NOTE",
                roleNote: "To manage user roles go to",
                manageUsers: "Manage Users",
                or: "or",
                managePartners: "Manage Partners",
                page: "page.",
                emailNote: "The user's email address can't be changed. In case you need to change it please add a new user."
            },
            nl: {
                partnerTitle: "Partner",
                loading: "Laden van partners...",
                first: "Voornaam",
                last: "Achternaam",
                email: "E-mail",
                phone: "Telefoon",
                role: "Role",
                user: "gebruiker",
                partner: "partner",
                admin: "beheerder",
                note: "NOTITIE",
                roleNote: "Ga naar om gebruikersrollen te beheren",
                manageUsers: "Gebruikers beheren",
                or: "of",
                managePartners: "Beheer partners",
                page: "bladzijde.",
                emailNote: "Het e-mailadres van de gebruiker kan niet worden gewijzigd. Voeg een nieuwe gebruiker toe als u deze moet wijzigen.",
            }
        }, this.props.lang);
    }

    updatePartners() {
        getPartners((err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partners: res
            })
        })
    }

    componentDidMount() {

        this.timer = setTimeout(() => {

            this.updatePartners();
        }, 2000);
    }

    componentWillUnmount() {

        if (this.timer)
            clearTimeout(this.timer)
    }

    render() {

        let form_items = []
        if (this.props.fullForm && this.props.manageUsers)
            form_items = [
                { label: this.strings.first, name: 'contact_first_name', required: true, disabled: this.props.disabled },
                { label: this.strings.last, name: 'contact_last_name', required: true, disabled: this.props.disabled },
                { label: this.strings.email, name: 'contact_email', required: this.props.readOnlyEmail ? false : true, disabled: this.props.readOnlyEmail || this.props.disabled, email: 'yes' },
                { label: this.strings.phone, name: 'contact_phone', required: false, disabled: this.props.disabled },
                { label: this.strings.notifications, name: 'contact_notifications', required: true, disabled: this.props.disabled },
                { label: this.strings.role, name: 'contact_role', required: false, disabled: this.props.disabled },
                { label: this.strings.partnerLabel, name: 'contact_partner_id', required: false, disabled: this.props.disabled }
            ]
        else if (this.props.fullForm)
            form_items = [
                { label: this.strings.first, name: 'contact_first_name', required: true, disabled: this.props.disabled },
                { label: this.strings.last, name: 'contact_last_name', required: true, disabled: this.props.disabled },
                { label: this.strings.email, name: 'contact_email', required: this.props.readOnlyEmail ? false : true, disabled: this.props.readOnlyEmail || this.props.disabled, email: 'yes' },
                { label: this.strings.phone, name: 'contact_phone', required: false, disabled: this.props.disabled },
                { label: this.strings.notifications, name: 'contact_notifications', required: true, disabled: this.props.disabled },
                { label: this.strings.role, name: 'contact_role', required: false, disabled: true },
                { label: this.strings.partnerLabel, name: 'contact_partner_id', required: false, disabled: true }
            ]
        else form_items = [
            { label: this.strings.first, name: 'contact_first_name', required: true, disabled: this.props.disabled },
            { label: this.strings.last, name: 'contact_last_name', required: true, disabled: this.props.disabled },
            { label: this.strings.email, name: 'contact_email', required: this.props.readOnlyEmail ? false : true, disabled: this.props.readOnlyEmail || this.props.disabled, email: 'yes' },
            { label: this.strings.phone, name: 'contact_phone', required: false, disabled: this.props.disabled }
            // { label: this.strings.role, name: 'contact_role', required: false, disabled: true }
        ]

        return (
            <div>
                {
                    form_items.map(item => {

                        if (item.name === 'contact_partner_id' && this.props.value.role !== 'partner')
                            return null

                        if (item.name === 'contact_notifications')
                            return <div className={'form-group row'} key={item.name}>
                                <label htmlFor={item.name} className="col-sm-3 col-form-label">
                                    {item.label}
                                </label>
                                <div className="col-sm-9">
                                    <select className="form-control" id={item.name} name={item.name} required={true} value={this.props.value[item.name] || ''} onChange={this.props.onFieldChange}>
                                        <option value="0">{this.strings.unsubscribed}</option>
                                        <option value="1">{this.strings.subscribed}</option>
                                        <option value="2">{this.strings.extremePrices}</option>
                                    </select>
                                </div>
                            </div>

                        if (item.name === 'contact_role' && this.props.manageUsers)
                            return <div className={'form-group row'} key={item.name}>
                                <label htmlFor={item.name} className="col-sm-3 col-form-label">
                                    {item.label}
                                </label>
                                <div className="col-sm-9">
                                    <select className="form-control" id={item.name} name={item.name} required={true} value={this.props.value[item.name] || ''} onChange={this.props.onFieldChange}>
                                        <option value="0">{this.strings.user}</option>
                                        <option value="1">{this.strings.partner}</option>
                                        <option value="2">{this.strings.admin}</option>
                                    </select>
                                </div>
                            </div>

                        return (
                            <div className={'form-group row ' + (item.required ? 'required' : '')} key={item.name}>
                                <label htmlFor={item.name} className="col-sm-3 col-form-label">
                                    {item.label}
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        key={item.name}
                                        type="text"
                                        className="form-control"
                                        id={item.name}
                                        name={item.name}
                                        required={item.required}
                                        readOnly={item.disabled}
                                        email={item.email}
                                        value={item.name !== 'contact_role' ? this.props.value[item.name] || '' : this.props.value[item.name] || 'user'}
                                        onChange={this.props.onFieldChange} />
                                    {
                                        item.name === 'contact_email' && item.disabled ?
                                            <div className="form-text text-muted">
                                                <strong>{this.strings.note}: </strong>{this.strings.emailNote}
                                            </div>
                                            : item.name === 'contact_role' && item.disabled ?
                                                <div className="form-text text-muted">
                                                    <strong>{this.strings.note}: </strong>{this.strings.roleNote} <em><Link to="/admin/manage-users">{this.strings.manageUsers}</Link></em> {this.strings.or} <em><Link to="/admin/manage-partners">{this.strings.managePartners} </Link></em> {this.strings.page}
                                                </div>
                                                : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default Contact;
