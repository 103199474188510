import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import Loader from '../Loader';
import LocalizedStrings from '../Localization';
import { getDgos, updateSdp } from '../../api/sdps';
import { submit, switchRequest } from '../../api/mig6';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone';

import 'react-day-picker/lib/style.css';
import 'moment/locale/nl-be';

moment.tz.setDefault('Europe/Brussels')


class SdpSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEan: {},
      dgos: [],
      sdps: [],
      sendingReq: false
    }

    this.getDgosList = this.getDgosList.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onEffectiveDateChange = this.onEffectiveDateChange.bind(this);
    this.clearEffectiveDate = this.clearEffectiveDate.bind(this);
    this.requestPSL = this.requestPSL.bind(this);
    this.compatibilityCheck = this.compatibilityCheck.bind(this);
    this.blockSwitch = this.blockSwitch.bind(this);
    this.closeInfoBox = this.closeInfoBox.bind(this);
    this.sendRequest = this.sendRequest.bind(this);

    // TBD - remove unused strings
    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Missing required field",
        errors: "These errors occurred:",
        error: "Error while updating the SDP information. Please try again.",
        infoMissingIBAN: "The IBAN is missing for the selected company. You can trigger the switch process for this injection EAN but it's recommended to first add the correct IBAN",
        errorMissingIBANandDDMandate: "The IBAN and DD Mandate are missing for the selected company. Please first add both in order to continue with the switch process this offtake EAN",
        errorMissingIBAN: "The IBAN is missing for the selected company. Please first add it in order to continue with the switch process for this offtake EAN",
        errorMissingDDMandate: "The DD Mandate is missing for the selected company. Please first add in order to continue with the switch process for this offtake EAN",
        noEffectiveDates: "You need to specify effective date to perform switch request",
        sdpSwitchApproved: "SDP Switch request was APPROVED!",
        switch: "SDP Switch Request",
        loading: "Loading EANs...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        switchRequest: "Start SDP Switch on Atrias:",
        module: "Module",
        label: "Label",
        startAccess: "Start access",
        consumerMoveIn: "Consumer Move-in",
        initiateLocalProduction: "Initiate Local production",
        combinedSwitch: "Combined switch",
        customerSwitch: "Customer switch",
        supplierSwitch: "Supplier switch",
        moveIn: "Move In",
        effectiveDate: "Effective date",
        psl: "Preswitch Light",
        noPreswitch: "No Preswitch information present. Please request a preswitch light.",
        notRecognized: "EAN not recognized",
        notAvailable: "Portal was not available. Please try again later...",
        errorCode: "Error. Please contact Yuso technical support",
        noSC: "Swtich cannot be performed. No Service Component or Meter Configuration present. To refresh the data please request a preswitch light.",
        incompatibleSC: "Swtich cannot be performed. There is incompatibility in the Service Component or the Meter Configuration. To refresh the data please request a preswitch light.",
        sc: "Service Component",
        mc: "Meter Configuration",
        ps: "Physical Status",
        requestDate: "Request Date:",
        switchDate: "Switch Date:",
        switchStatus: "Switch Status:",
        switchReason: "Switch Reason:",
        eocDate: "EOC Date:",
        eocStatus: "EOC Status:",
        eocReason: "EOC Reason:",
        previousSwitchInfo: "Previous Switch Info:",
        switchInfo: "The selected SDP is already with us. SDP Switch Info:",
        previousEOCInfo: "Previous EOC Info:",
        eocInfo: "EOC Info:",
        contractNotSigned: "Contract not signed.",
        clear: "Clear",
        sendRequest: "Send Request",
        sdpStatus: "SDP Switch Status",
        startDate: "Start date",
        noContract: "No contract",
        signed: "Contract signed",
        unsigned: "Contract not signed",
        unsupportedDGO: "DGO not supported",
        injection: "Injection",
        offtake: "Supply",
        production: "Production",
        offtakeInjection: "Supply & Injection",
        note: "NOTE",
        dateNote: "Contract date will be updated same as the switch effective date.",
        pslDate: "PSL date"
      },
      nl: {
        errorRequired: "Ontbrekende vereiste veld",
        errors: "Deze fouten zijn opgetreden:",
        error: "Fout tijdens het updaten van de SDP-informatie. Probeer het opnieuw.",
        infoMissingIBAN: "Het IBAN ontbreekt voor het geselecteerde bedrijf. U kunt het schakelproces voor deze EAN-injectie activeren, maar het wordt aanbevolen om eerst de juiste IBAN toe te voegen",
        errorMissingIBANandDDMandate: "Het IBAN en DD-Mandaat ontbreken voor het geselecteerde bedrijf. Voeg eerst beide toe om door te gaan met het schakelproces van deze EAN",
        errorMissingIBAN: "Het IBAN ontbreekt voor het geselecteerde bedrijf. Voeg deze eerst toe om door te gaan met het schakelproces voor deze afname EAN",
        errorMissingDDMandate: "Het DD-mandaat ontbreekt voor het geselecteerde bedrijf. Voeg eerst toe om door te gaan met het schakelproces voor deze afname EAN",
        noEffectiveDates: "Je moet een effectieve datum opgeven om een overschakelingsverzoek uit te voeren.",
        switch: "SDP Switch Verzoek",
        loading: "EANs laden...",
        noSdp: "Het bedrijf heeft geen SDPs.",
        addSdp: "Voeg nieuwe SDP toe",
        switchRequest: "Start SDP overschakeling op Atrias:",
        noPreswitch: "Geen informatie over voorafschakelcontrole beschikbaar. Vraag een voorafschakelcontrole aan.",
        sdpSwitchApproved: "SDP overschakelingsverzoek is GOEDGEKEURD!",
        module: "Module",
        label: "Label",
        startAccess: "Start access",
        consumerMoveIn: "Consumer Move-in",
        initiateLocalProduction: "Initiate Local production",
        combinedSwitch: "Combined switch",
        customerSwitch: "Customer switch",
        supplierSwitch: "Supplier switch",
        moveIn: "Move In",
        effectiveDate: "Effectieve datum",
        psl: "Preswitch Light",
        noSC: "Overschakeling kan niet worden uitgevoerd. Geen servicecomponent of meterconfiguratie aanwezig. Vraag een voorafschakelcontrole aan om de gegevens te vernieuwen.",
        incompatibleSC: "Overschakeling kan niet worden uitgevoerd. Er is incompatibiliteit in de servicecomponent of meterconfiguratie. Vraag een voorafschakelcontrole aan om de gegevens te vernieuwen.",
        sc: "Servicecomponent",
        mc: "Meterconfiguratie",
        ps: "Fysieke status",
        requestDate: "Aanvraagdatum:",
        switchDate: "Switch Datum:",
        switchStatus: "Switch Status:",
        switchReason: "Switch Reden:",
        eocDate: "EOC Datum:",
        eocStatus: "EOC Status:",
        eocReason: "EOC Reden:",
        errorCode: "Fout. Neem contact op met de technische ondersteuning van Yuso",
        notAvailable: "Portal was niet beschikbaar. Probeer het later opnieuw ...",
        notRecognized: "EAN niet herkend",
        previousSwitchInfo: "Vorige overschakelingsinformatie:",
        switchInfo: "De geselecteerde SDP is al bij ons. SDP overschakelinformatie:",
        previousEOCInfo: "Vorige EOC-informatie:",
        eocInfo: "EOC-informatie:",
        sendRequest: "Aanvraag verzenden",
        contractNotSigned: "Contract not signed.",
        clear: "Uitwissen",
        sdpStatus: "SDP Switch Status",
        startDate: "Startdatum",
        noContract: "Geen contract",
        signed: "Contract ondertekend",
        unsigned: "Contract niet getekend",
        unsupportedDGO: "DGO niet ondersteund",
        injection: "Injectie",
        offtake: "Levering",
        production: "Productie",
        offtakeInjection: "Levering & Injectie",
        note: "NOTITIE",
        dateNote: "Contractdatum wordt bijgewerkt naar dezelfde datum als de effectieve datum van de overstap.",
        pslDate: "PSL datum"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.sdps)
      return {
        sdps: nextProps.sdps.filter(x => x.type !== 'production').sort((x, y) => (!x.hasContract || x.hasContract < y.hasContract || !x.dgoPortal) ? 1 : -1)
      }
    else return null
  }

  componentDidMount() {
    this.getDgosList();
  }

  getDgosList() {
    getDgos((err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        dgos: res.map(x => x.dgo)
      }, () => {
        if (this.state.selectedEan.dgo) {

          this.setState({
            ...this.state.selectedEan,
            dgo: this.state.dgos.find(dgo => dgo.replace(/[^a-z0-9]/gi, '').toLowerCase() === this.state.selectedEan.dgo.replace(/[^a-z0-9]/gi, '').toLowerCase()) || ''
          })
        }
      });
    })
  }

  onSelect(selection) {
    if (selection.length) {
      let selectedEan = selection[0]
      selectedEan.dgo = this.state.dgos.find(dgo => dgo.replace(/[^a-z0-9]/gi, '').toLowerCase() === selection[0].dgo.replace(/[^a-z0-9]/gi, '').toLowerCase())

      if (selectedEan.startDate)
        selectedEan.effectiveDate = moment(selectedEan.startDate).isBefore(moment().startOf('day')) ? moment().format('YYYY-MM-DD') : selectedEan.startDate

      this.setState({ selectedEan })
    }
    else
      this.setState({ selectedEan: {} })
  }

  onFieldChange(e) {
    if (this.state.selectedEan.ean) {
      let field = {}
      field[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value

      if (e.target.id === 'ean') {
        e.target.value = 'EAN' + e.target.value.replace(/[^\d]/g, '').toUpperCase().trim();
        field[e.target.id] = e.target.value
      }

      if (e.target.name === 'module')
        field.label = undefined

      this.setState({
        selectedEan: { ...this.state.selectedEan, ...field }
      })
    }
  }

  onEffectiveDateChange(effectiveDate) {
    this.setState({
      selectedEan: { ...this.state.selectedEan, effectiveDate: moment(effectiveDate).format('YYYY-MM-DD') }
    })
  }

  clearEffectiveDate() {
    this.setState({
      selectedEan: { ...this.state.selectedEan, effectiveDate: '' }
    })
  }

  requestPSL() {
    this.setState({ reqPSL: true })

    submit(this.state.selectedEan.ean, "PRESWITCH_UPDATE", this.state.selectedEan.type_sdp, (err, res) => {

      if (err) {
        return window.reportError.send(err);
      }

      let updatedEan = res.ean

      if (res.available === true) {
        this.setState({
          selectedEan: {
            ...this.state.selectedEan,
            ...updatedEan
          },
          reqPSL: false,
          update: true
        }, () => {
          if (this.state.selectedEan.wrongDirection === true) {
            this.setState({
              pslInfo: `${this.strings.wrongDirectionMessage} ${this.strings.wrongDirectionMessage1} ${this.state.selectedEan.curretComponent}. ${this.strings.wrongDirectionMessage2} ${this.state.selectedEan.type_sdp} ${this.strings.wrongDirectionMessage3} ${this.strings.wrongDirectionMessage4}`
            }, () => {
              setTimeout(() => {
                this.setState({ pslInfo: undefined })
              }, 6000);
            })
          } else {
            updateSdp(this.props.selectedCompany.company_id, this.state.selectedEan,
              (err, res) => {
                if (err)
                  return window.reportError.send(err);

                if (res.error) {
                  this.setState({ errors: [this.strings.error] })
                }

                if (res.status === 'OK') {
                  this.setState({ pslInfo: "i", update: undefined }, () => {
                    this.props.updateSdps()
                    setTimeout(() => {
                      this.setState({ pslInfo: undefined })
                    }, 3000);
                  })
                }
              })
          }

        })
      } else {
        if (res.recognized && res.recognized === "notRecognized") {
          this.setState({
            reqPSL: false,
            pslInfo: this.strings.notRecognized
          }, () => {
            setTimeout(() => {
              this.setState({ pslInfo: undefined })
            }, 5000);
          })
        } else if (res.error && res.error === true) {
          this.setState({
            reqPSL: false,
            pslInfo: this.strings.errorCode
          }, () => {
            setTimeout(() => {
              this.setState({ pslInfo: undefined })
            }, 5000);
          })
        }
        else {
          this.setState({
            reqPSL: false,
            pslInfo: this.strings.notAvailable
          }, () => {
            setTimeout(() => {
              this.setState({ pslInfo: undefined })
            }, 5000);
          })
        }

      }

    })
  }

  compatibilityCheck(sdp) {

    if (sdp.meterType === 'DMR' && ["SC_OFFINJE", "SC_OFFTAKE"].includes(sdp.service_component) && ["301MN", "300MN", "304MN"].includes(sdp.service_config) && (sdp.physicalStatus === 'Connected'))
      return true

    else if (sdp.meterType === 'DMR' && ["SC_OFFINJE", "SC_OFFTAKE"].includes(sdp.service_component) && (sdp.physicalStatus === 'Disconnected' || sdp.physicalStatus === 'Under construction'))
      return true

    else if (sdp.meterType === 'AMR' && ((sdp.type_sdp === "injection" && ["SC_COMMINJ", "SC_INJECTI"].includes(sdp.service_component)) || (sdp.type_sdp === "offtake" && ["SC_COMMOFF", "SC_OFFTAKE"].includes(sdp.service_component))))
      return true

    else return false

  }

  blockSwitch() {
    // not actually blocked, warning is displayed 
    return this.state.selectedEan.type_sdp === 'offtake' && (!this.props.selectedCompany.iban || !this.props.selectedCompany.dd_mandate)
  }

  closeInfoBox() {
    this.setState({ errors: undefined })
  }

  formatSoapErrorMessage(error) {
    console.log(error);
  
    let formattedMessage = "An error occurred, please try again later.";
  
    try {
      // Extracting the JSON part from the error string
      const jsonPart = error.split('! ')[1];
      if (!jsonPart) {
        throw new Error('No JSON part in error message');
      }
  
      const errorObj = JSON.parse(jsonPart);
      // Check if the error is a SOAP fault
      if (errorObj && errorObj.Fault && errorObj.Fault.detail && errorObj.Fault.detail.SoapFaultDoc && errorObj.Fault.detail.SoapFaultDoc.faultMessage) {
        formattedMessage = errorObj.Fault.detail.SoapFaultDoc.faultMessage;
      } else if (errorObj && errorObj.errorMsg) {
        // Existing logic for non-SOAP fault errors
        formattedMessage = errorObj.errorMsg;
      }
    } catch (parseError) {
      console.log('Error parsing:', parseError);
      return "An error occurred, unable to parse error message.";
    }
    return formattedMessage;
  }
  

  
  
  sendRequest(e) {
    e.preventDefault()

    this.setState({ errors: false, sendingReq: true })

    let error = [];

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++)
      if (form[i].required && !form[i].value.trim().length)
        error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)

    if (!this.state.selectedEan.effectiveDate)
      error.push(this.strings.noEffectiveDates)

    if (error.length) {
      this.setState({ errors: error, sendingReq: false })
      console.log(this.state.errors);
      return;
    }

    let selectedEan = this.state.selectedEan
    selectedEan.user = this.props.selectedCompany.fullName


    switchRequest(this.props.selectedCompany.company_id, selectedEan,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.errorMsg) {

        const formattedError = this.formatSoapErrorMessage(res.errorMsg);
        this.setState({ errors: [formattedError], sendingReq: false }, () => {
        });
      }

        else
          this.setState({
            info: res.status === 'OK' ? this.strings.sdpSwitchApproved : res.info,
            sendingReq: false
          }, () => {
            if (res.status === 'OK')
              setTimeout(() => {
                this.props.updateSdps()
                this.setState({ info: undefined, errors: undefined, selectedEan: {} })
              }, 10000);

          })
      })
  }
  
  render() {
    let sdp = this.state.selectedEan
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.switch}
        </div>
        <div className="card-body">
          {
            !this.props.sdps ?
              <Loader inline="true">{this.strings.loading}</Loader>
              :
              <div>

                <div className="mb-2">
                  <div className="alert alert-info m-0">
                    <i className="fas fa-exclamation-triangle text-danger mr-2"></i> Beta version. Please take any issues with IT.
                  </div>
                </div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.sendRequest}>
                      <p>{this.strings.switchRequest}</p>
                      <div className="form-group row">
                        <label htmlFor="switchSdpInformationEAN" className="col-sm-3 col-form-label">SDP</label>
                        <div className="col-sm-9 special-select">
                          <Typeahead
                            id="switchSdpInformationEAN"
                            options={this.state.sdps}
                            labelKey={x => `${x.ean}`}
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {results.map((x) => (
                                  <MenuItem option={x} key={x.ean} disabled={!x.hasContract || x.dgoPortal !== "Atrias"}>
                                    {x.ean} {x.dgoPortal === "Atrias" ? <span>| {this.strings[x.type_sdp_label || x.type_sdp]}{!x.hasContract ? (' | ' + this.strings.noContract) : ` | ${x.signatureDate === null ? this.strings.unsigned : this.strings.signed} | ${this.strings.startDate}: ${x.startDate}`}</span> : <span>| {this.strings.unsupportedDGO}</span>}
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                            onChange={this.onSelect}
                            filterBy={!sdp.ean ? undefined : () => true}
                          />
                        </div>
                      </div>

                      {
                        sdp.ean ?
                          sdp.switchDate && sdp.switchStatus === '39' && (!sdp.eocDate || (sdp.eocDate && sdp.eocStatus !== '39')) ?
                            //SDP is with us
                            //Add clear previous switch data button TBD
                            <div>
                              {
                                !sdp.switchDate ? null :
                                  <div className="mb-2">
                                    <div className="alert alert-success m-0">
                                      <strong>{this.strings.switchInfo}</strong><br />
                                      {sdp.requestDate ? <span><br /><strong>{this.strings.requestDate}</strong> {sdp.requestDate}</span> : null}
                                      <br /><strong>{this.strings.switchDate}</strong> {sdp.switchDate}
                                      {sdp.switchStatusDesc ? <span><br /><strong>{this.strings.switchStatus}</strong> {sdp.switchStatusDesc} ({sdp.switchStatus})</span> : null}
                                      {sdp.switchStatusReason ? <span><br /><strong>{this.strings.switchReason}</strong> {sdp.switchStatusReasonDesc} ({sdp.switchStatusReason})</span> : null}
                                    </div>
                                  </div>
                              }
                              {
                                !sdp.eocDate ? null :
                                  <div className="mb-2">
                                    <div className="alert alert-dark m-0">
                                      <strong>{this.strings.eocInfo}</strong><br />
                                      {sdp.eocRequestDate ? <span><br /><strong>{this.strings.requestDate}</strong> {sdp.eocRequestDate}</span> : null}
                                      <br /><strong>{this.strings.eocDate}</strong> {sdp.eocDate}
                                      {sdp.eocStatusDesc ? <span><br /><strong>{this.strings.eocStatus}</strong> {sdp.eocStatusDesc} ({sdp.eocStatus})</span> : null}
                                      {sdp.eocStatusReason ? <span><br /><strong>{this.strings.eocReason}</strong> {sdp.eocStatusReasonDesc} ({sdp.eocStatusReason})</span> : null}
                                    </div>
                                  </div>
                              }
                            </div>
                            : !sdp.preswitch_id || !sdp.service_component || !sdp.service_config ?
                              <div>
                                <div className="mb-2">
                                  <div className="alert alert-info m-0">
                                    <i className="fas fa-info-circle mr-2"></i> {!sdp.preswitch_id ? this.strings.noPreswitch : this.strings.noSC}
                                  </div>
                                </div>
                                < button type="button" className="btn btn-warning float-right" disabled={this.state.reqPSL} onClick={this.requestPSL}>
                                  {
                                    this.state.reqPSL ? <span><i className="fas fa-spinner fa-spin mr-2"></i> {this.strings.psl}</span> : this.strings.psl
                                  }
                                </button>
                              </div>
                              : !sdp.signatureDate ?
                                <div>
                                  <div className="mb-2">
                                    <div className="alert alert-info m-0">
                                      <i className="fas fa-info-circle mr-2"></i> {this.strings.contractNotSigned}
                                    </div>
                                  </div>
                                </div>
                                : !this.compatibilityCheck(sdp) ?
                                  <div>
                                    <div className="alert alert-light">
                                      <strong>{this.strings.sc}: {sdp.service_component}</strong>
                                      <br />
                                      <strong>{this.strings.mc}: {sdp.service_config}</strong>
                                      <br />
                                      <strong>{this.strings.ps}: {sdp.physicalStatus}</strong>
                                      <br />
                                      <i>{this.strings.pslDate}: {sdp.preswitch_dtlt}</i>
                                    </div>
                                    <div className="mb-2">
                                      <div className="alert alert-warning m-0">
                                        <i className="fas fa-info-circle mr-2"></i> {this.strings.incompatibleSC}
                                      </div>
                                    </div>
                                    < button type="button" className="btn btn-warning float-right" disabled={this.state.reqPSL} onClick={this.requestPSL}>
                                      {
                                        this.state.reqPSL ? <span><i className="fas fa-spinner fa-spin mr-2"></i> {this.strings.psl}</span> : this.strings.psl
                                      }
                                    </button>
                                  </div>
                                  :
                                  <div>
                                    <div className="alert alert-light">
                                      <strong>{this.strings.sc}: {sdp.service_component}</strong>
                                      <br />
                                      <strong>{this.strings.mc}: {sdp.service_config}</strong>
                                      <br />
                                      <strong>{this.strings.ps}: {sdp.physicalStatus}</strong>
                                      <br />
                                      <i>{this.strings.pslDate}: {sdp.preswitch_dtlt}</i>
                                    </div>
                                    <div className="form-group row required required-select">
                                      <label htmlFor="switchType" className="col-sm-3 col-form-label">{this.strings.module}</label>
                                      <div className="col-sm-9">
                                        <select className="form-control" id="module" name="module" value={sdp.module || ''} onChange={this.onFieldChange} require key={0}>
                                          <option value="" disabled></option>
                                          <option value="BG1">{this.strings.startAccess}</option>
                                          <option value="E65">{this.strings.consumerMoveIn}</option>
                                          <option value="BG3" disabled>{this.strings.initiateLocalProduction}</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="form-group row required required-select disabled">
                                      <label htmlFor="switchType" className="col-sm-3 col-form-label">{this.strings.label}</label>
                                      <div className="col-sm-9">
                                        <select className="form-control" id="label" name="label" value={sdp.label || ''} onChange={this.onFieldChange} disabled={!["BG1", "BG3", "E65"].includes(sdp.module)} required>
                                          {
                                            ["BG1", "BG3"].includes(sdp.module)
                                              ? [
                                                <option value="" disabled key={0}></option>,
                                                <option value="BS6" key={1} disabled>{this.strings.combinedSwitch}</option>,
                                                <option value="BS8" key={2} disabled>{this.strings.customerSwitch}</option>,
                                                <option value="BS9" key={3}>{this.strings.supplierSwitch}</option>
                                              ]
                                              : sdp.module === "E65"
                                                ? [
                                                  <option value="" disabled key={0}></option>,
                                                  <option value="BS2" key={1}>{this.strings.moveIn}</option>
                                                ] : null
                                          }
                                        </select>
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label htmlFor="sdp_switch_date" className="col-sm-4 col-form-label">{this.strings.effectiveDate}</label>
                                      <div className="col-sm-8">
                                        <div className="input-group d-flex flex-nowrap">
                                          <DayPickerInput
                                            value={sdp.effectiveDate ? moment(sdp.effectiveDate, 'YYYY-MM-DD').toDate() : ''}
                                            format="DD-MM-YYYY"
                                            placeholder="DD-MM-YYYY"
                                            formatDate={(date, format) => { return moment(date).format(format) }}
                                            inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'sdp_switch_date', name: 'switch_date' }}
                                            dayPickerProps={{
                                              selectedDays: null,
                                              disabledDays: (date) => sdp.label === 'BS9' && ["SC_OFFTAKE", "SC_COMMOFF", "SC_OFFINJE"].includes(sdp.service_component) ? moment(date) < moment().startOf('day').subtract('60', 'days') : moment(date) < moment().startOf('day'),
                                              localeUtils: MomentLocaleUtils,
                                              locale: this.props.lang
                                            }}
                                            onDayChange={this.onEffectiveDateChange}
                                          />
                                          <div className="input-group-append">
                                            <button type="button" className="btn btn-secondary" onClick={this.clearEffectiveDate}>{this.strings.clear}</button>
                                          </div>
                                        </div>
                                        {
                                          sdp.startDate === sdp.effectiveDate ? null :
                                            <div className="form-text text-muted">
                                              <strong>{this.strings.note}: </strong>{this.strings.dateNote}
                                            </div>
                                        }
                                      </div>
                                    </div>


                                    <div className={'alert alert-warning mb-3 ' + (this.state.errors ? 'd-block' : 'd-none')}>
                                      <div onClick={this.closeInfoBox}><i className="fas fa-window-close float-right"></i></div>
                                      <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.errors}

                                      <ul>
                                        {this.state.errors
                                          ? this.state.errors.sort().map(error => (
                                            <li key={error || error.message}>
                                              {typeof error === "string" ? error : this.formatSoapErrorMessage(error)}
                                            </li>
                                          ))
                                          : null}
                                      </ul>

                                      {/* <ul>
                                          {this.state.errors ? this.state.errors.sort().map(error => (<li key={error}>{error}</li>)) : null}
                                      </ul> */}
                                      {/* 
                                      <ul>
                                        {
                                          this.state.errors ? this.state.errors.sort().map(error => (<li key={error || error}>{error}</li>)) : null
                                        }
                                      </ul> */}
                                    </div>

                                    {
                                      sdp.type_sdp === 'injection' && !this.props.selectedCompany.iban ?
                                        <p className="alert alert-warning">
                                          <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.infoMissingIBAN}
                                        </p>
                                        : null
                                    }

                                    {
                                      sdp.type_sdp === 'offtake' && (!this.props.selectedCompany.iban || !this.props.selectedCompany.dd_mandate) ?
                                        <p className="alert alert-danger">
                                          <i className="fas fa-minus-circle mr-2"></i>
                                          {
                                            !this.props.selectedCompany.iban && !this.props.selectedCompany.dd_mandate ?
                                              <span>{this.strings.errorMissingIBANandDDMandate}</span>
                                              :
                                              !this.props.selectedCompany.iban ?
                                                this.strings.errorMissingIBAN
                                                :
                                                !this.props.selectedCompany.dd_mandate ?
                                                  this.strings.errorMissingDDMandate
                                                  : null
                                          }
                                        </p>
                                        : null
                                    }

                                    {
                                      !sdp.switchDate ? null :
                                        <div className="mb-2">
                                          <div className="alert alert-success m-0">
                                            <strong>{this.strings.previousSwitchInfo}</strong><br />
                                            {sdp.requestDate ? <span><br /><strong>{this.strings.requestDate}</strong> {sdp.requestDate}</span> : null}
                                            <br /><strong>{this.strings.switchDate}</strong> {sdp.switchDate}
                                            {sdp.switchStatusDesc ? <span><br /><strong>{this.strings.switchStatus}</strong> {sdp.switchStatusDesc} ({sdp.switchStatus})</span> : null}
                                            {sdp.switchStatusReason ? <span><br /><strong>{this.strings.switchReason}</strong> {sdp.switchStatusReasonDesc} ({sdp.switchStatusReason})</span> : null}
                                          </div>
                                        </div>
                                    }

                                    {
                                      !sdp.eocDate ? null :
                                        <div className="mb-2">
                                          <div className="alert alert-dark m-0">
                                            <strong>{this.strings.previousEOCInfo}</strong><br />
                                            {sdp.eocRequestDate ? <span><br /><strong>{this.strings.requestDate}</strong> {sdp.eocRequestDate}</span> : null}
                                            <br /><strong>{this.strings.eocDate}</strong> {sdp.eocDate}
                                            {sdp.eocStatusDesc ? <span><br /><strong>{this.strings.eocStatus}</strong> {sdp.eocStatusDesc} ({sdp.eocStatus})</span> : null}
                                            {sdp.eocStatusReason ? <span><br /><strong>{this.strings.eocReason}</strong> {sdp.eocStatusReasonDesc} ({sdp.eocStatusReason})</span> : null}
                                          </div>
                                        </div>
                                    }

                                    <div className="clearfix mb-3">
                                      <button type="submit" className="btn btn-primary float-right" disabled={this.blockSwitch() || this.state.sendingReq}>
                                        <i className={`fas fa-${!this.state.sendingReq ? 'paper-plane' : 'spinner fa-spin'} mr-2`}></i> {this.strings.sendRequest}
                                      </button>
                                    </div>

                                    <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                                      <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                                    </p>
                                  </div>
                          : null
                      }
                    </form>
                }
                <p className={'alert alert-info ' + (this.state.pslInfo ? '' : 'd-none')}>
                  <i className="fas fa-info-circle mr-2"></i> {this.state.pslInfo}
                </p>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default SdpSwitch;