import React, { Component } from 'react';
import LocalizedStrings from './Localization';

/**
 * Define the list of languages here
 */
const languages = [
  {
    code: 'nl',
    name: 'Nederlands'
  },
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'fr',
    name: 'Français'
  }
];

class LanguageChooser extends Component {
  constructor(props) {
    super(props);

    this.changeLanguage = this.changeLanguage.bind(this);

    this.strings = LocalizedStrings({
      en: {
        title: "Change your language"
      },
      nl: {
        title: "Verander de taal"
      }
    }, this.props.lang);
  }

  changeLanguage(lang) {
    window.reactStorage.setItem('lang', lang);

    this.props.changeLanguage(lang);
  }

  render() {
    return (
      <div className="language-chooser">
        <ul className="d-flex justify-content-center align-items-center">
          {
            languages ? languages.filter(lang => lang.code !== this.props.lang).map(lang => {
              return (
                <li key={lang.code} onClick={() => { this.changeLanguage(lang.code) } }>
                  {lang.name}
                </li>
              )
            }) : null
          }
        </ul>
      </div>
    );
  }
}

export default LanguageChooser;
