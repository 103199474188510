import React, { Component } from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "../components/Localization";

import { login, newPassword, userCheck } from "../api/user";
import Loader from "../components/Loader";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: this.props.company || "",
      email: this.props.user || "",
      password: "",
      initialPassword: "",
      loginStage: "initial",
      redirectToReferrer: false,
      approved:null
    };

    this.userLogin = this.userLogin.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleInitialPasswordChange =
    this.handleInitialPasswordChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleInitialPasswordSubmit =
    this.handleInitialPasswordSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.strings = LocalizedStrings(
      {
        en: {
          wait: "Please wait...",
          welcome: "Welcome to My Yuso",
          loginEnter: "sign in to go to My Yuso",
          incorrectCredentials:
            "Incorrect email address or password. Please try again.",
          noSuchUser: "Non existing user. Please create account first.",
          notApproved: "Your account is not approved yet.",
          email: "E-mail",
          password: "Password",
          login: "Login",
          forgot: "Forgot your password?",
          firstTime: "This is your first time on My Yuso",
          enterPassword: "please enter a password",
          company: "Company:",
          username: "Username:",
          passwordValidation:
            "Your password must be minimum 8 characters long.",
          changePassword: "Save Password & Log in",
        },
        nl: {
          wait: "Even geduld aub...",
          welcome: "Welkom bij My Yuso",
          loginEnter: "meld je aan om naar My Yuso te gaan",
          incorrectCredentials:
            "Onjuist e-mailadres of wachtwoord. Probeer het opnieuw.",
          noSuchUser: "Niet-bestaande gebruiker. Maak eerst een account aan.",
          email: "E-mail",
          password: "Wachtwoord",
          login: "Login",
          forgot: "Je wachtwoord vergeten?",
          firstTime: "Dit is je eerste keer op My Yuso",
          enterPassword: "Voer een wachtwoord in",
          company: "Bedrijf:",
          username: "Gebruikersnaam:",
          passwordValidation:
            "Jouw wachtwoord moet minimaal 8 tekens lang zijn.",
          changePassword: "Wachtwoord opslaan & Log in",
        },
      },
      this.props.lang
    );
  }

  componentDidMount() {
    if (this.props.firstTimeLogin) {
      this.setState(
        {
          company: this.props.company,
          email: this.props.user,
          password: this.props.pass,
          loginStage: "first-time-logging-in",
        },
        () => {
          this.userLogin();
        }
      );
    }
  }

  userLogin() {
    userCheck(this.state.email, (err, res, approved) => {
        if (err) return window.reportError.send(err);

        if (res.error) {
            this.setState({ loginStage: "no-such-user" });
            return;
        }
        if (!this.props.firstTimeLogin && approved !== 1) {
            this.setState({ loginStage: 'not-approved' });
            return;
        }

        login(this.state.email, this.state.password, (err, result) => {
            if (err && err.newPassRequired) {
                this.setState({ loginStage: "new-password-required" });
            } else if (err) {
                this.setState({ loginStage: "retry" });
            } else {
                this.setState({ loginStage: "logged-in" });
                // let userInfo = {
                //   id: result.signInUserSession.idToken.payload.sub,
                //   username: result.username,
                //   amazon: result,
                // };
                this.props.onLogin(result, this.props.emailLogin);
            }
        });
    });
}

  handleBlur (e){
    const email = e.target.value;
    userCheck(email, (err, approved) => {
      if (err) {
        console.error(err);
        return;
      }
      this.setState({
        email,
        approved
      });
    });
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleInitialPasswordChange(e) {
    this.setState({ initialPassword: e.target.value });
  }

  handleLoginSubmit(e) {
    e.preventDefault();

    this.setState({ loginStage: "logging-in" });

    this.userLogin();
  }

  handleInitialPasswordSubmit(e) {
    e.preventDefault();

    if (this.state.initialPassword.length < 8) {
      this.setState({ loginStage: "new-password-required-retry" });
      return null;
    }

    newPassword(
      this.state.email,
      this.state.password,
      this.state.initialPassword,
      (err, result) => {
        if (err && err.newPassRequired) {
          this.setState({ loginStage: "new-password-required" });
        }
        if (err) {
          this.setState({ loginStage: "retry" });
        } else {
          this.setState({ loginStage: "logged-in" });
          this.props.onLogin(result, this.props.firstTimeLogin);
        }
      }
    );
  }

  renderLoginForm() {
    // const CLIENT_ID = config.GOOGLE_OAUTH2_CLIENT_ID;
    return (
      <form onSubmit={this.handleLoginSubmit} className="px-5 py-4">
        <h1 className="form-title mb-3">
          <strong>{this.strings.welcome},</strong>
          <br />
          {this.strings.loginEnter}
        </h1>
        <div style={{ height: "60px" }}>
          <div
            className={
              "alert alert-danger " +
              (this.state.loginStage === "retry" ? "" : "d-none")
            }
          >
            {this.strings.incorrectCredentials}
          </div>
          <div
            className={
              "alert alert-danger " +
              (this.state.loginStage === "no-such-user" ? "" : "d-none")
            }
          >
            {this.strings.noSuchUser}
          </div>
          <div
            className={
              "alert alert-danger " +
              (this.state.loginStage === "not-approved" ? "" : "d-none")
            }
          >
            {this.strings.notApproved}
          </div>
        </div>
        <div className="form-group">
          <input
            className="form-control p-3"
            id="inputEmail"
            value={this.state.email}
            onChange={this.handleEmailChange}
            placeholder={this.strings.email}
            autoComplete="off"
            type="email"
          />
        </div>
        <div className="form-group">
          <input
            className="form-control p-3"
            id="inputPassword"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            placeholder={this.strings.password}
            autoComplete="off"
            type="password"
          />
        </div>
        <div className="form-group d-flex flex-column flex-lg-row justify-content-between">
          {/* <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleAccountButton onLogin={this.props.onLogin}></GoogleAccountButton>
          </GoogleOAuthProvider> */}
          <button
            type="submit"
            className="btn btn-warning px-5 py-2"
            disabled={
              this.state.loginStage === "logging-in" ? "disabled" : undefined
            }
          >
            {this.strings.login} <i className="fas fa-arrow-right"></i>
          </button>
        </div>
        <small className="text-center text-lg-left mb-2">
          <Link to="/forgot-password" className="text-muted">
            {this.strings.forgot}
          </Link>
        </small>
      </form>
    );
  }

  renderInitialPasswordForm() {
    return (
      <form onSubmit={this.handleInitialPasswordSubmit} className="px-5 py-4">
        <h1 className="form-title mb-3">
          <strong>{this.strings.firstTime},</strong>
          <br />
          {this.strings.enterPassword}
        </h1>
        <div style={{ height: "60px" }}>
          <div
            className={
              "alert alert-danger " +
              (this.state.loginStage === "new-password-required-retry"
                ? ""
                : "d-none")
            }
          >
            {this.strings.passwordValidation}
          </div>
        </div>
        <table className="table table-sm">
          <tbody>
            <tr className={this.state.company ? "" : "d-none"}>
              <td>{this.strings.company}</td>
              <th>{this.state.company}</th>
            </tr>
            <tr>
              <td style={{ width: "20%" }}>{this.strings.username}</td>
              <th>{this.state.email}</th>
            </tr>
          </tbody>
        </table>
        <div className="form-group">
          <input
            className="form-control p-3"
            id="initialPassword"
            value={this.state.initialPassword}
            onChange={this.handleInitialPasswordChange}
            placeholder={this.strings.password}
            autoComplete="off"
            type="password"
          />
        </div>
        <div className="form-group clearfix">
          <button
            type="submit"
            className="btn btn-warning float-right px-5 py-3"
            disabled={
              this.state.loginStage === "logging-in" ? "disabled" : undefined
            }
          >
            {this.strings.changePassword} <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </form>
    );
  }

  render() {
    if (this.state.loginStage === "first-time-logging-in") {
      return <Loader>{this.strings.wait}</Loader>;
    }
    if (
      this.state.loginStage === "new-password-required" ||
      this.state.loginStage === "new-password-required-retry"
    ) {
      return this.renderInitialPasswordForm();
    }
    return this.renderLoginForm();
  }
}

export default Login;
