import React, { Component } from 'react'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea } from 'recharts'
import moment from 'moment-timezone'
import { ContractAreaLineAndLabel } from './ContractAreaLineAndLabel';
import LocalizedStrings from '../../components/Localization';

moment.tz.setDefault('Europe/Brussels')

class ChartMetervalues extends Component {
    constructor(props) {
        super(props);

        this.tickFormatter = this.tickFormatter.bind(this)
        this.labelFormatter = this.labelFormatter.bind(this)
        this.tooltipFormatter = this.tooltipFormatter.bind(this)

        this.strings = LocalizedStrings({
            en: {
                supply: "Supply",
                injection: "Injection",
                production: "Production",
                tooltipLabelHour: "Hour",
                tooltipLabelDay: "Day",
                tooltipLabelMonth: "Month"
            },
            nl: {
                supply: "Levering",
                injection: "Injectie",
                production: "Productie",
                tooltipLabelHour: "Uur",
                tooltipLabelDay: "Dag",
                tooltipLabelMonth: "Maand"
            }
        }, this.props.lang);
    }

    tickFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.metervalues[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                break;

            case 'M':
                format = 'YYYY-MM'
                break;

            default:
                break;
        }

        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    labelFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.metervalues[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        let tooltipLabel = this.strings.tooltipLabelHour
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                tooltipLabel = this.strings.tooltipLabelDay
                break;

            case 'M':
                format = 'YYYY-MM'
                tooltipLabel = this.strings.tooltipLabelMonth
                break;

            default:
                break;
        }

        return tooltipLabel + ': ' + moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    tooltipFormatter(value) {
        return parseFloat(value).toFixed(3) // always meter values to 3 decimals
    }

    render() {
        if (!this.props.metervalues || !this.props.allSdps || !this.props.allSdps.length) {
            return <div />
        }

        if (!this.props.metervalues.length) {
            return <div />
        }

        let start = this.props.metervalues.find(x => x.dtlt === this.props.minContractDate + (this.props.aggregation === 'H' ? ' 23:00:00' : ' 00:00:00'))
        let index = this.props.metervalues.indexOf(start)

        let last = this.props.metervalues[this.props.metervalues.length - 1].dtlt

        if (moment(last, 'YYYY-MM-DD HH:mm:ss') < moment(this.props.minContractDate, 'YYYY-MM-DD'))
            index = this.props.metervalues.length - 1

        return (
            [
                <div className="row" key="0">
                    <div className="col-sm">
                        <h5 className="text-center text-info mb-3">{this.props.isInjection ? this.strings.injection : this.props.isProduction ? this.strings.production : this.strings.supply} (kWh) <sup>*</sup></h5>
                    </div>
                </div>,
                <div className="row mb-4" key="1">
                    <div className="col-sm height-300">
                        <ResponsiveContainer>
                            <AreaChart data={this.props.metervalues}>
                                <XAxis dataKey="index" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip labelFormatter={this.labelFormatter} formatter={this.tooltipFormatter} />
                                <Area
                                    name={this.props.isInjection ? this.strings.injection : this.strings.supply}
                                    dataKey="mv"
                                    unit=" kWh"
                                    stroke="#dddc01"
                                    strokeWidth={2}
                                    fill="#dddc01"
                                    fillOpacity={.3}
                                    type="stepAfter"
                                    dot={false}
                                />
                                {
                                    !this.props.isProduction ?
                                        <ReferenceArea
                                            x2={index}
                                            isFront={false} fill="#ccc"
                                            fillOpacity={0.25}
                                            label={<ContractAreaLineAndLabel
                                                data={this.props.metervalues}
                                                lang={this.props.lang}
                                                minContractDate={this.props.minContractDate}
                                                showContractLine={index !== this.props.metervalues.length - 1}
                                                notStarted={this.props.notStarted} />}
                                        />
                                        : null
                                }
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ]
        )
    }
}

export default ChartMetervalues
