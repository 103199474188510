import $ from 'jquery'
import 'bootstrap/js/dist/modal'

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import SubHeader from '../../components/SubHeader';
import { CallToAction, CtaTitle, CtaContent, CtaPanel } from '../../components/CallToAction';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getPendingAgreements, getCompletedAgreements, getSignedMandates, getSwitchConfirmations, getEocConfirmations, checkGDriveContract, syncGDriveContract, deleteDocument, getBSI, getBONWI } from '../../api/documents';

moment.tz.setDefault('Europe/Brussels')

class Agreements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingAgreements: undefined,
            completedAgreements: undefined,
            switchConfirmations: undefined,
            eocConfirmations: undefined,
            signedMandates: undefined,
            bsi: undefined,
            bonwi: undefined,
            onGDrive: {},
            deleteDocumentType: '',
            deleteDocumentFilename: '',
            deleteDocumentStatus: undefined,
            gtcVersion: "2023-04",
            bsiVersion: "2022-09",
            bonwiVersion: "2022-08"
        };

        this.updateAgreements = this.updateAgreements.bind(this);
        this.checkContract = this.checkContract.bind(this);
        this.syncContract = this.syncContract.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);

        this.strings = LocalizedStrings({
            en: {
                error: "Error while sending your contract. Please try again.",
                contractSupply: "Agreement Yuso Platform - Supply",
                contractInjection: "Agreement Yuso Platform - Injection",
                contractMandate: "Request for historic metering data mandate",
                agreements: "Agreements",
                fetching: "Fetching agreements...",
                notFound: "No agreements found.",
                agreementsDesc: "Here you will find an overview of all agreements between your company and Yuso, as well as proposals that have to be signed.",
                waiting: "are waiting for you",
                signed: "to be signed",
                finalize: "To finalize your agreement, you only need to complete a few details.",
                created: "Created on",
                name: "Name",
                syncLoading: "Loading GDrive status...",
                synced: "Agreement is synced to GDrive",
                notSynced: "Agreement is not synced to GDrive. Click to sync it",
                instructions: "Instructions",
                signing: "for signing",
                signingDesc1: "Download",
                signingDesc2: "this document and",
                signingDesc3: "send a signed version",
                signingDesc4: "to",
                signingDesc5: "The contract includes the pricing proposal of Yuso together with a direct debit mandate to retrieve invoiced amounts from your bank account.",
                gtc: "Contract-YUSO-general-terms-and-conditions",
                gtcURL: "/gtc",
                bsi: "Calculation of the Belgian Solar Imbalance Cost Index",
                bsiURL: "/bsi",
                bonwi: "Calculation of the Belgian Onshore Wind Imbalance Cost Index",
                bonwiURL: "/bonwi",
                current: "Current",
                signed2: "Signed",
                agreements2: "Agreements",
                view: "View",
                loadingMandate: "Loading mandates...",
                mandates: "Mandates",
                noMandate: "No mandates found.",
                loadingConfirmations: "Loading confirmations...",
                switch: "Switch",
                eoc: "EOC",
                confirmations: "Confirmations",
                noSwitch: "No switch confirmations found.",
                delete: "Delete",
                agreement: "agreement",
                mandate: "mandate",
                close: "Close",
                confirmDelete: "Do you want to permanently delete",
                deleteWarning: "Be careful as this operation cannot be undone!",
                documentDeleted: "Document successfully deleted!"
            },
            nl: {
                error: "Fout bij het verzenden van jouw contract. Probeer het opnieuw.",
                contractSupply: "Overeenkomst Yuso Platform - Afname",
                contractInjection: "Overeenkomst Yuso Platform - Injectie",
                contractMandate: "Verzoek om historisch gegevensmandaat",
                agreements: "Overeenkomsten",
                fetching: "Overeenkomst ophalen...",
                notFound: "Geen overeenkomsten gevonden.",
                agreementsDesc: "Hier vind je een overzicht van alle overeenkomsten tussen je bedrijf en Yuso, evenals voorstellen die moeten worden ondertekend.",
                waiting: "wachten om",
                signed: "ondertekend te worden",
                finalize: "Om jouw overeenkomst af te ronden, hoef je slechts enkele details in te vullen.",
                created: "Gemaakt op",
                name: "Naam",
                syncLoading: "De GDrive status laden...",
                synced: "Overeenkomst wordt gesynchroniseerd met GDrive",
                notSynced: "Overeenkomst wordt niet gesynchroniseerd met GDrive. Klik om het te synchroniseren",
                instructions: "Instructies",
                signing: "voor ondertekening",
                signingDesc1: "Download",
                signingDesc2: "dit document",
                signingDesc3: "en stuur een getekende versie",
                signingDesc4: "naar",
                signingDesc5: "Het Contract bevat het prijsvoorstel van Yuso samen met een direct debit mandaat om kosten van je bank account te debiteren.",
                gtc: "Cntract-YUSO-algemene-voorwaarden",
                gtcURL: "/algemenevoorwaarden",
                bsi: "Berekening van de Belgian Solar Imbalance Cost Index",
                bsiURL: "/bsi",
                bonwi: "Berekening van de Belgian Onshore Wind Imbalance Cost Index",
                bonwiURL: "/bonwi",
                current: "Huidige",
                signed2: "Getekende",
                agreements2: "Overeenkomsten",
                view: "Bekijk",
                loadingMandate: "Mandaten laden...",
                mandates: "Mandaten",
                noMandate: "Geen mandaten gevonden.",
                loadingConfirmations: "Laden bevestigingen ...",
                switch: "Switch",
                eoc: "EOC",
                confirmations: "Bevestigingen",
                noSwitch: "Geen schakelbevestigingen gevonden.",
                delete: "Verwijder",
                agreement: "overeenkomst",
                mandate: "mandaat",
                close: "Sluiten",
                confirmDelete: "Wilt u permanent verwijderen?",
                deleteWarning: "Wees voorzichtig want dit kan niet ongedaan worden gemaakt!",
                documentDeleted: "Document succesvol verwijderd!"
            },
            fr: {
                contractSupply: "Contrat Yuso Plate-forme - Prélèvement",
                contractInjection: "Contrat Yuso Plate-forme - Injection"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.updateAgreements();
    }

    updateAgreements() {
        if (!this.props.selectedCompany) return;

        getBSI(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    bsi: res
                });
            });

        getBONWI(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    bonwi: res
                });
            });

        getPendingAgreements(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    pendingAgreements: res
                });
            });

        getCompletedAgreements(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                res.forEach(ag => {
                    this.checkContract(ag.split('/')[1])
                });

                this.setState({
                    completedAgreements: res.sort((x, y) => x.indexOf("contract-update") < y.indexOf("contract-update") ? 1 : -1)
                });
            });

        getSignedMandates(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    signedMandates: res
                });
            });

        if (this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner') {

            getSwitchConfirmations(
                this.props.selectedCompany.company_id,
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        switchConfirmations: res
                    });
                });

            getEocConfirmations(
                this.props.selectedCompany.company_id,
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        eocConfirmations: res
                    });
                });
        }
    }

    checkContract(filename) {
        checkGDriveContract(this.props.selectedCompany.company_id, filename,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    onGDrive: { ...this.state.onGDrive, [filename]: res.status }
                });
            });
    }

    syncContract(filename) {
        const synced = this.state.onGDrive[filename]

        if (!synced && !this.syncing) {
            this.syncing = true

            syncGDriveContract(this.props.selectedCompany.company_id, filename,
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    if (res.status === 'OK')
                        this.updateAgreements();

                    this.syncing = false
                });
        }
    }

    deleteDocument(type, filename) {
        deleteDocument(type, filename,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.DeleteMarker) {

                    this.setState({
                        deleteDocumentStatus: this.strings.documentDeleted
                    })

                    switch (this.state.deleteDocumentType) {
                        case 'agreements':
                            this.setState({
                                completedAgreements: this.state.completedAgreements.filter(doc => doc !== `${this.state.deleteDocumentType}/${this.state.deleteDocumentFilename}`)
                            })
                            break;
                        case 'mandates':
                            this.setState({
                                signedMandates: this.state.signedMandates.filter(doc => doc !== `${this.state.deleteDocumentType}/${this.state.deleteDocumentFilename}`)
                            })
                            break;
                        default:
                            return;
                    }

                    $('#status-document').modal('show')
                }
            }
        )
    }

    render() {
        let s = this.state, docs = undefined

        if (this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner')
            docs = s.pendingAgreements === undefined || s.completedAgreements === undefined || s.switchConfirmations === undefined || s.eocConfirmations === undefined || s.signedMandates === undefined ? -1
                : (s.pendingAgreements && (s.pendingAgreements.offtake_hourly.length || s.pendingAgreements.injection_hourly.length || s.pendingAgreements.mandate.length)) || s.completedAgreements.length || s.switchConfirmations.length || s.eocConfirmations.length || s.signedMandates.length ? 1
                    : 0
        else docs = s.pendingAgreements === undefined || s.completedAgreements === undefined || s.signedMandates === undefined ? -1
            : (s.pendingAgreements && (s.pendingAgreements.offtake_hourly.length || s.pendingAgreements.injection_hourly.length || s.pendingAgreements.mandate.length)) || s.completedAgreements.length || s.signedMandates.length ? 1
                : 0

        if (docs < 0)
            return (
                <div>
                    <SubHeader name={this.strings.agreements} image="Documents" type="Agreements" />
                    <Loader>
                        {this.strings.fetching}
                    </Loader>
                </div>
            );

        if (docs === 0)
            return (
                <div>
                    <SubHeader name={this.strings.agreements} image="Documents" type="Agreements" />
                    <div className="row">
                        <div className="col-sm px-2 px-xl-5 py-3">
                            <div className="spacer"></div>
                            <p>{this.strings.notFound}</p>
                        </div>
                    </div>
                </div>
            )

        return (
            <div>
                <SubHeader name={this.strings.agreements} image="Documents" type="Agreements" />
                <div className="row">
                    <div className="col-lg-7 px-2 px-xl-5 py-3">
                        <div className="spacer"></div>
                        <p className="mb-3">
                            {this.strings.agreementsDesc}
                        </p>
                        <div className="mt-5">
                            <div className={this.state.pendingAgreements && (this.state.pendingAgreements.offtake_hourly.length || this.state.pendingAgreements.injection_hourly.length || this.state.pendingAgreements.mandate.length) ? '' : 'd-none'}>
                                <h4 className="text-primary font-weight-light mb-4"><img className="mr-2 position-relative" style={{ width: '25px', top: '-3px' }} src={`${process.env.PUBLIC_URL}/warning-yellow.png`} alt="Warning" /><strong>{this.strings.agreements}</strong> {this.strings.waiting} <strong>{this.strings.signed}</strong></h4>
                                {
                                    this.state.pendingAgreements && (this.state.pendingAgreements.offtake_hourly.length || this.state.pendingAgreements.injection_hourly.length) ?
                                        (<p>{this.strings.finalize}</p>) : null
                                }
                                <table className="table table-sm table-fixed mb-4">
                                    <thead>
                                        <tr>
                                            <th>{this.strings.name}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.pendingAgreements && this.state.pendingAgreements.offtake_hourly.length ?
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="text-nowrap text-truncate">
                                                                <Link className="text-primary" title={this.strings.contractSupply}
                                                                    to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/supply`}>
                                                                    {this.strings.contractSupply}
                                                                </Link>
                                                            </div>
                                                            <Link className="btn btn-secondary btn-sm px-3 ml-auto"
                                                                to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/supply`}>
                                                                <i className="fas fa-file-alt mr-2"></i> {this.strings.view}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr> : null
                                        }
                                        {
                                            this.state.pendingAgreements && this.state.pendingAgreements.injection_hourly.length ?
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="text-nowrap text-truncate">
                                                                <Link className="text-primary" title={this.strings.contractInjection}
                                                                    to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/injection`}>
                                                                    {this.strings.contractInjection}
                                                                </Link>
                                                            </div>
                                                            <Link className="btn btn-secondary btn-sm px-3 ml-auto"
                                                                to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/injection`}>
                                                                <i className="fas fa-file-alt mr-2"></i> {this.strings.view}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr> : null
                                        }
                                        {
                                            this.state.pendingAgreements && this.state.pendingAgreements.mandate.length ?
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="text-nowrap text-truncate">
                                                                <Link className="text-primary" title={this.strings.contractMandate}
                                                                    to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/mandate`}>
                                                                    {this.strings.contractMandate}
                                                                </Link>
                                                            </div>
                                                            <Link className="btn btn-secondary btn-sm px-3 ml-auto"
                                                                to={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/mandate`}>
                                                                <i className="fas fa-file-alt mr-2"></i> {this.strings.view}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr> : null
                                        }
                                    </tbody>
                                </table>

                                <p className={'alert alert-warning mt-3 ' + (this.state.error ? '' : 'd-none')}>
                                    <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                                </p>

                                <p className={'alert alert-info mt-3 ' + (this.state.info ? '' : 'd-none')}>
                                    <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                                </p>

                                <hr className="border-top border-info my-5" />
                            </div>

                            <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.current}</strong> {this.strings.agreements2}</h4>
                            {
                                this.state.completedAgreements.length ?
                                    <table className="table table-striped table-sm table-fixed">
                                        <thead>
                                            <tr>
                                                <th>{this.strings.name}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.completedAgreements.map(
                                                    (ag, j) => {
                                                        return (
                                                            <tr className="row m-0" key={j}>
                                                                <td className="col-md-11">
                                                                    <div className="text-nowrap text-truncate w-100">
                                                                        {
                                                                            this.props.selectedCompany.role === 'admin' && !this.props.selectedCompany.demo ?
                                                                                this.state.onGDrive[ag.split('/')[1]] === undefined ?
                                                                                    <i className="fas fa-spinner fa-spin text-muted mr-2" title={this.strings.syncLoading}></i>
                                                                                    :
                                                                                    <i className={'fas fa-cloud-upload-alt mr-2 ' + (this.state.onGDrive[ag.split('/')[1]] ? 'text-primary' : 'text-danger cursor-pointer')} title={this.state.onGDrive[ag.split('/')[1]] ? this.strings.synced : this.strings.notSynced} onClick={() => this.syncContract(ag.split('/')[1])}></i>
                                                                                : null
                                                                        }
                                                                        <Link className="text-primary" title={ag.split('/')[1]}
                                                                            to={`/documents/completedAgreement/${this.props.selectedCompany.company_id}/${ag.split('/')[1]}`}>
                                                                            {ag.split('/')[1]}
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.props.selectedCompany.role === 'admin' ?
                                                                        <td className="commands col-md-1 text-right">
                                                                            <i className="fas fa-times text-danger cursor-pointer mr-2" title={`${this.strings.delete} ${this.strings.agreement}`} data-toggle="modal" data-target="#delete-document" onClick={() => {
                                                                                this.setState({ deleteDocumentType: 'agreements', deleteDocumentFilename: ag.split('/')[1] });
                                                                            }}></i>
                                                                        </td>
                                                                        : null
                                                                }
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            <tr className="row m-0" key='gtc'>
                                                <td className="col-md-11">
                                                    <div className="text-nowrap text-truncate w-100">
                                                        <i className="far fa-file-alt text-primary ml-1 mr-2"></i>
                                                        <Link className="text-primary font-weight-light" to={this.strings.gtcURL}>
                                                            {this.state.gtcVersion}-{this.strings.gtc}
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="row m-0" key='bsi'>
                                                <td className="col-md-11">
                                                    <div className="text-nowrap text-truncate w-100">
                                                        <i className="far fa-file-alt text-primary ml-1 mr-2"></i>
                                                        <Link className="text-primary font-weight-light" to={this.strings.bsiURL}>
                                                            {this.state.bsiVersion}-{this.strings.bsi}
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="row m-0" key='bonwi'>
                                                <td className="col-md-11">
                                                    <div className="text-nowrap text-truncate w-100">
                                                        <i className="far fa-file-alt text-primary ml-1 mr-2"></i>
                                                        <Link className="text-primary font-weight-light" to={this.strings.bonwiURL}>
                                                            {this.state.bonwiVersion}-{this.strings.bonwi}
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    :
                                    <p>
                                        {this.strings.notFound}
                                    </p>
                            }

                            {
                                !this.state.signedMandates ?
                                    <Loader inline="true" className="mt-5">{this.strings.loadingMandate}</Loader>
                                    :
                                    <div className="mt-5">
                                        <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.signed2}</strong> {this.strings.mandates}</h4>
                                        {
                                            this.state.signedMandates.length ?
                                                <table className="table table-striped table-sm table-fixed">
                                                    <thead>
                                                        <tr>
                                                            <th>{this.strings.name}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.signedMandates.map(
                                                                (ma, j) => {
                                                                    return (

                                                                        <tr className="row m-0" key={j}>
                                                                            <td className="col-md-11">

                                                                                <div className="text-nowrap text-truncate w-100">
                                                                                    <Link className="text-primary" title={ma.split('/')[1]}
                                                                                        to={`/documents/signedMandate/${this.props.selectedCompany.company_id}/${ma.split('/')[1]}`}>
                                                                                        {ma.split('/')[1]}
                                                                                    </Link>
                                                                                </div>
                                                                            </td>
                                                                            {
                                                                                this.props.selectedCompany.role === 'admin' ?
                                                                                    <td className="commands col-md-1 text-right">
                                                                                        <i className="fas fa-times text-danger cursor-pointer mr-2" title={`${this.strings.delete} ${this.strings.mandate}`} data-toggle="modal" data-target="#delete-document" onClick={() => {
                                                                                            this.setState({ deleteDocumentType: 'mandates', deleteDocumentFilename: ma.split('/')[1] });
                                                                                        }}></i>
                                                                                    </td>
                                                                                    : null
                                                                            }
                                                                        </tr>
                                                                    );
                                                                }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <p>
                                                    {this.strings.noMandate}
                                                </p>
                                        }
                                    </div>
                            }

                            <div className="modal fade" id="delete-document" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <p>
                                                {this.strings.confirmDelete} <strong><u>{this.state.deleteDocumentFilename}</u></strong>? {this.strings.deleteWarning}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { this.deleteDocument(this.state.deleteDocumentType, this.state.deleteDocumentFilename) }}>{this.strings.delete}</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="status-document" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <p>
                                                {this.state.deleteDocumentStatus}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeDelete}>{this.strings.close}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="border-top border-info my-5" />

                            {
                                this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                    !this.state.switchConfirmations ?
                                        <Loader inline="true">{this.strings.loadingConfirmations}</Loader>
                                        :
                                        <div className="mt-5">
                                            <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.switch}</strong> {this.strings.confirmations}</h4>
                                            {
                                                this.state.switchConfirmations.length ?
                                                    <table className="table table-striped table-sm table-fixed">
                                                        <thead>
                                                            <tr>
                                                                <th>{this.strings.name}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.switchConfirmations.map(
                                                                    (sc, j) => {
                                                                        return (
                                                                            <tr key={j}>
                                                                                <td>
                                                                                    <div className="text-nowrap text-truncate w-100">
                                                                                        <Link className="text-primary" title={sc.split('/')[1]}
                                                                                            to={`/documents/switchConfirmation/${this.props.selectedCompany.company_id}/${sc.split('/')[1]}`}>
                                                                                            {sc.split('/')[1]}
                                                                                        </Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <p>
                                                        {this.strings.noSwitch}
                                                    </p>
                                            }
                                        </div>
                                    :
                                    null
                            }

                            {
                                this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                    !this.state.eocConfirmations ?
                                        <Loader inline="true" className="mt-5">{this.strings.loadingConfirmations}</Loader>
                                        :
                                        <div className="mt-5">
                                            <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.eoc}</strong> {this.strings.confirmations}</h4>
                                            {
                                                this.state.eocConfirmations.length ?
                                                    <table className="table table-striped table-sm table-fixed">
                                                        <thead>
                                                            <tr>
                                                                <th>{this.strings.name}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.eocConfirmations.map(
                                                                    (sc, j) => {
                                                                        return (
                                                                            <tr key={j}>
                                                                                <td>
                                                                                    <div className="text-nowrap text-truncate w-100">
                                                                                        <Link className="text-primary" title={sc.split('/')[1]}
                                                                                            to={`/documents/eocConfirmation/${this.props.selectedCompany.company_id}/${sc.split('/')[1]}`}>
                                                                                            {sc.split('/')[1]}
                                                                                        </Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <p>
                                                        {this.strings.noSwitch}
                                                    </p>
                                            }
                                        </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 px-2 px-xl-5 py-3">
                        <CtaPanel
                            className={'my-4 ml-4 ' + (!this.props.info.sdps.has_sdps || this.props.info.pending_contracts.has_contracts ? 'd-none' : '')}
                            link="/services#flexibility"
                            type="request_flexibility"
                            lang={this.props.lang}
                            buttonClassName="w-100" />

                        <CallToAction className={'my-4 ml-4 ' + (!this.props.info.pending_contracts.has_contracts ? 'd-none' : '')}>
                            <CtaTitle><strong>{this.strings.instructions}</strong> {this.strings.signing}</CtaTitle>
                            <CtaContent>
                                <strong>{this.strings.signingDesc1}</strong> {this.strings.signingDesc2} <strong>{this.strings.signingDesc3}</strong> {this.strings.signingDesc4} <a href="mailto:info@yuso.be" className="text-body">info@yuso.be</a>. {this.strings.signingDesc5}
                            </CtaContent>
                        </CallToAction>

                        <CtaPanel
                            className={'my-4 ml-4 ' + (this.props.info.billing.email && this.props.info.billing.iban ? 'd-none' : '')}
                            link="/account/company"
                            type="add_billing"
                            warning="true"
                            lang={this.props.lang}
                            buttonClassName="w-100" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Agreements;
