import config from './config';
import { getAccessToken } from './user';


const getCompany = (company_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/company/${company_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const companyCheck = (company_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/company/companyCheck/${company_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (data) => { 
              cb(null, data);
            }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const contactCheck = (company_id, contact_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/company/contactCheck/${company_id}/${contact_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (data) => { 
              cb(null, data);
            }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}


const updateCompany = (company_id, company_info, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/company/updateCompany/${company_id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(company_info)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const deleteCompany = (company_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/company/deleteCompany/${company_id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const filterCompanies = (filter, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      fetch(
        `${config.BASE_URL}/api/company/filterCompanies`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(filter)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getLegalForm = (cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/company/legalform`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export { getCompany, companyCheck, contactCheck, updateCompany, deleteCompany, filterCompanies, getLegalForm };
