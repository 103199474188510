import React, { Component } from 'react';
import LocalizedStrings from '../../components/Localization';
import NewContract from '../../components/forms/NewContract';


class ContractGeneration extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            contractType: undefined,
            selectedSdp: undefined,
            options: [],
            hideEans: true
        }

        this.state = { ...this.initialState };

        this.updateSdps = this.updateSdps.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.showAllEans = this.showAllEans.bind(this);

        this.strings = LocalizedStrings({
            en: {
                generateContract: "Generate contract for your SDPs",
                noSdpsWithoutContract: "There are no SDPs without a contract.",
                search: "Search SDP",
                type: "Type",
                supply: "Supply",
                injection: "Injection",
                showAll: "Show all",
                contractParameters: "Contract parameters",
                supplyPoint: "of the supply point",
                injectionPoint: "of the injection point",
                sdpPoint: "of the SDP",
            },
            nl: {
                generateContract: "Genereer een contract voor uw SDP's",
                noSdpsWithoutContract: "Er zijn geen SDP's zonder contract.",
                search: "Zoek SDP",
                type: "Type",
                supply: "Levering",
                injection: "Injectie",
                showAll: "Toon alles",
                contractParameters: "Contract parameters",
                supplyPoint: "van het leveringspunt",
                injectionPoint: "van het injectiepunt",
                sdpPoint: "van de SDP",
            }
        }, this.props.lang);

    }

    componentDidMount() {
        this.setState({ options: this.props.sdps })
    }

    componentWillReceiveProps(newProps) {
        this.setState({ options: newProps.sdps })
    }

    updateSdps(activeStage) {
        this.setState({ selectedSdp: undefined }, () => this.props.updateSdps(activeStage))
    }

    onFieldChange(e) {
        switch (e.target.name) {
            case 'contractType':
                this.setState({
                    contractType: e.target.value
                })
                break;

            case 'sdpFilter':
                this.setState({
                    sdpFilter: e.target.value,
                    selectedSdp: e.target.value ? this.state.selectedSdp : undefined,
                    options: this.props.sdps.filter(x => this.state.contractType ? x.ean.indexOf(e.target.value) > -1 && x.type_sdp === this.state.contractType : x.ean.indexOf(e.target.value) > -1)
                })
                break;

            case 'sdpTypeFilter':
                this.setState({
                    contractType: e.target.value,
                    options: !e.target.value ? this.props.sdps : this.props.sdps.filter(x => this.state.sdpFilter ? x.ean.indexOf(this.state.sdpFilter) > -1 && x.type_sdp === e.target.value : x.type_sdp === e.target.value)
                })
                break;

            default:
                break;
        }
    }

    showAllEans() {
        this.setState({ hideEans: false })
    }

    render() {

        return (
            <div className="row">
                {
                    !this.props.sdps.length ?
                        [
                            <div className="col-12 p-4">
                                <h4 className="text-primary font-weight-light mb-4">
                                    <strong>{this.strings.contractParameters}</strong>&nbsp;
                                    {
                                        this.state.selectedSdp
                                            ? this.state.selectedSdp.type_sdp === 'offtake'
                                                ? <span>{this.strings.supplyPoint}</span>
                                                : <span>{this.strings.injectionPoint}</span>
                                            : <span>{this.strings.sdpPoint}</span>
                                    }
                                </h4>
                            </div>,
                            <div className="col-2"></div>,
                            <div className="col-8">
                                <p className="alert alert-info"><i className="fas fa-info-circle mr-2"></i>{this.strings.noSdpsWithoutContract}</p>
                            </div>,
                            <div className="col-2"></div>
                        ]
                        :
                        [
                            <div className="col-lg-6 px-2 px-xl-5 py-3">
                                <h4 className="text-primary font-weight-light mb-4">
                                    <strong>{this.strings.contractParameters}</strong>&nbsp;
                                    {
                                        this.state.selectedSdp ?
                                            this.state.selectedSdp.type_sdp === 'offtake'
                                                ? <span>{this.strings.supplyPoint}</span>
                                                : <span>{this.strings.injectionPoint}</span>
                                            : <span>{this.strings.sdpPoint}</span>
                                    }
                                </h4>
                                <div className="input-group mb-2">
                                    <input type="search" className="form-control" placeholder={this.strings.search} id="sdpFilter" name="sdpFilter" value={this.state.selectedSdp ? this.state.selectedSdp.ean + ' - ' + this.state.selectedSdp.installation_id : this.state.sdpFilter || ""} onChange={this.onFieldChange} autoComplete="off" />
                                    <select className="form-control" style={{ flexGrow: 0.3 }} type="text" id="sdpTypeFilter" name="sdpTypeFilter" value={this.state.selectedSdp ? this.state.selectedSdp.type_sdp : this.state.sdpTypeFilter || ""} onChange={this.onFieldChange}>
                                        <option value="" selected>{this.strings.type}</option>
                                        <option value="offtake">{this.strings.supply}</option>
                                        <option value="injection">{this.strings.injection}</option>
                                    </select>
                                </div>
                                {
                                    this.state.options && this.state.options.length && !this.state.selectedSdp ?
                                        <table className="table table-hover table-sm sdps-list">
                                            <tbody>
                                                {
                                                    this.state.options.map((sdp, idx) => {
                                                        return (
                                                            <tr key={sdp.ean + ' - ' + sdp.installation_id} className={idx > 2 && this.state.hideEans ? 'd-none' : ''} onClick={() => { this.setState({ selectedSdp: sdp }) }}>
                                                                <td>
                                                                    <div className="cursor-pointer text-primary" >{sdp.ean} - {sdp.installation_id}</div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                {
                                                    this.state.options.length > 2 && this.state.hideEans ?
                                                        <tr>
                                                            <td className="text-center bg-light" onClick={this.showAllEans}>
                                                                <div className="cursor-pointer text-primary">{this.strings.showAll}</div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                            </tbody>
                                        </table>
                                        : null
                                }
                            </div>,
                            <div className="col-lg-12 px-5 px-xl-6 py-3">
                                {
                                    this.state.selectedSdp ?
                                        <NewContract
                                            selectedCompany={this.props.selectedCompany}
                                            updateInfo={this.props.updateInfo}
                                            lang={this.props.lang}
                                            sdp={this.state.selectedSdp}
                                            updateSdps={this.updateSdps}
                                        />
                                        : null
                                }
                            </div>
                        ]
                }

            </div>
        );
    }
}

export default ContractGeneration;
