import React, { Component } from 'react';
import { CtaPanel } from '../../components/CallToAction';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import BillingDetails from './BillingDetails';

import { getUsersForCompany } from '../../api/user';

class CompanyDetails extends Component {
  constructor(props) {

    super(props);

    this.state = {
      usersForCompany: undefined
    }

    this.updateUsersForCompany = this.updateUsersForCompany.bind(this);

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        portal: "to the My Yuso Portal,",
        details: "check your company details below.",
        company: "Company information",
        contact: "Wrong company details? Contact us at",
        manager: "Account Manager information",
        questions: "Have questions? Contact yor account manager",
        users: "Users",
        below: "below have",
        access: "access",
        toCompany: "to this company",
        newUser: "Want to add new user? Contact us at",
        fetching: "Fetching users...",
        vat: "VAT ID:",
        nace: "NACE code:",
        noUsers: "No users found."
      },
      nl: {
        welcome: "Welkom",
        portal: "naar de My Yuso Portal,",
        details: "controleer hieronder jouw bedrijfsgegevens.",
        company: "Bedrijfsinformatie",
        contact: "Verkeerde bedrijfsgegevens? Contacteer ons op",
        manager: "Accountmanager-informatie",
        questions: "Vragen hebben? Neem contact op met je accountmanager",
        users: "Gebruikers",
        below: "hieronder hebben",
        access: "toegang",
        toCompany: "voor dit bedrijf",
        newUser: "Wil je een nieuwe gebruiker toevoegen? Contacteer ons op",
        fetching: "Gebruikers ophalen...",
        vat: "BTW ID:",
        nace: "NACE-code:",
        noUsers: "Geen gebruikers gevonden."
      }
    }, this.props.lang);
  }

  componentDidMount() {

    this.updateUsersForCompany();
  }

  updateUsersForCompany() {
    if (!this.props.selectedCompany) return;

    getUsersForCompany(
      this.props.selectedCompany.company_id,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({
          usersForCompany: res
        });
      }
    );
  }

  render() {
    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-lg-7 px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.portal}<br />
              {this.strings.details}
            </div>
            <table className="table mb-5">
              <thead>
                <tr>
                  <th className="clearfix">{this.strings.company}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <strong>{this.props.selectedCompany.companyName}</strong>
                    </div>
                  </td>
                </tr>
                <tr className={!this.props.selectedCompany.addressStreet ? 'd-none' : ''}>
                  <td>{this.props.selectedCompany.addressStreet} {this.props.selectedCompany.addressNumber}, {this.props.selectedCompany.addressZip} {this.props.selectedCompany.addressCity},{this.props.selectedCompany.addressCountry}</td>
                </tr>
                <tr>
                  <td>{this.strings.vat} {this.props.selectedCompany.vatCode}</td>
                </tr>
                <tr className={!this.props.selectedCompany.nacebelCode ? 'd-none' : ''}>
                  <td>{this.strings.nace} {this.props.selectedCompany.nacebelCode}</td>
                </tr>
                <tr>
                  <td className="bg-white text-muted pt-3"><small>{this.strings.contact} <a href="mailto:info@yuso.be">info@yuso.be</a></small></td>
                </tr>
              </tbody>
            </table>

            <table className="table mb-5">
              <thead>
                <tr>
                  <th className="clearfix">{this.strings.manager}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>{this.props.selectedCompany.signup_partner_name}</strong>
                  </td>
                </tr>
                <tr className={this.props.selectedCompany.signup_contact_person && this.props.selectedCompany.signup_contact_person.trim().toLowerCase() !== this.props.selectedCompany.signup_partner_name.trim().toLowerCase() ? '' : 'd-none'}>
                  <td>{this.props.selectedCompany.signup_contact_person}</td>
                </tr>
                <tr className={this.props.selectedCompany.signup_contact_email ? '' : 'd-none'}>
                  <td>
                    <a className="text-lowercase" href={`mailto:${this.props.selectedCompany.signup_contact_email}`}>{this.props.selectedCompany.signup_contact_email}</a>
                  </td>
                </tr>
                <tr>
                  <td className="bg-white text-muted pt-3"><small>{this.strings.questions} <a href={`mailto:${this.props.selectedCompany.signup_contact_email}`}>{this.props.selectedCompany.signup_contact_email}</a></small></td>
                </tr>
              </tbody>
            </table>

            <BillingDetails
              selectedCompany={this.props.selectedCompany}
              updateCompanies={this.props.updateCompanies}
              lang={this.props.lang}
              hideDDMandate={true}
            />

            <hr className="border-warning" />

            <h4 className="text-primary font-weight-light mt-5 mb-4"><strong>{this.strings.users}</strong> {this.strings.below} <strong>{this.strings.access}</strong> {this.strings.toCompany}</h4>
            {
              this.state.usersForCompany ?
                <div>
                  {
                    this.state.usersForCompany.length ?
                      <table className="table table-striped table-sm">
                        <tbody>
                          {
                            this.state.usersForCompany.filter(x => ((this.props.selectedCompany.demo && x.email === this.props.demo.contact.email) || !this.props.selectedCompany.demo) && x.role !== 'admin').map(
                              (user) => {
                                return (
                                  <tr key={user.email}>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                  </tr>
                                )
                              })
                          }
                          <tr>
                            <td colSpan="2" className="bg-white text-muted pt-3"><small>{this.strings.newUser} <a href="mailto:info@yuso.be">info@yuso.be</a></small></td>
                          </tr>
                        </tbody>
                      </table>
                      : <p>{this.strings.noUsers}</p>
                  }
                </div>
                : <Loader inline="true">{this.strings.fetching}</Loader>
            }
          </div>
          <div className="col-lg-5 px-2 px-xl-5 py-3">
            <CtaPanel
              className={'my-4 ml-4 ' + (!this.props.info.sdps.has_sdps ? 'd-none' : '')}
              link="/services#flexibility"
              type="request_flexibility"
              lang={this.props.lang}
              buttonClassName="w-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyDetails;
