import $ from "jquery";
import "bootstrap/js/dist/modal";

import React, { Component } from "react";
import Loader from "../../components/Loader";
import LocalizedStrings from "../../components/Localization";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

import { submit } from "../../api/mig6";

import { getEans } from "../../api/eans";
import { getSdps, getDgos, checkSdp, getTMPEans, addSdp } from "../../api/sdps";
import NumberInput from "../../components/NumberInput";
import "react-day-picker/lib/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

class NewSdpForm extends Component {
  constructor(props) {
    super(props);

    this.emptyEan = {
      gsrn: "EAN",
      tmpGsrn: false,
      newInstallation: false,
      direction: "",
      typeOfMeter: null,
      apStreetName: "",
      appHouseNumber: "",
      apZip: "",
      apCity: "",
      country: "",
      dgoName: "",
      power: null,
      injectionPowers: {},
      installations: {},
      powerUnit: "kW", // default
      injectionVolume: "",
      supplyVolume: "",
      preswitchLight: "",
      preswitchTime: "",
      serviceComponentConfiguration: "",
      serviceComponentIdentification: "",
      volumeUnit: "MWh", // default
      filled: false,
      reset: true,
      checked: false,
      injectionEan: null,
      technologies: ""
    };

    this.initialState = {
      selectedEan: { ...this.emptyEan },
      selectedEans: [],
      missingData: {},
      options: undefined,
      isLoading: false,
      isAvailable: undefined,
      addingSpd: false,
      noEanTyped: undefined,
      noEan: false,
      warningEan: {},
      directionWarning: true,
      error: false,
      errorAMR: false,
      newSpdId: undefined,
      injectionSdps: undefined,
      dgos: [],
      directionWarningSelect: false,
      consent: false,
      sdpCheck: undefined,
      expandedForm: false,
      type: "",
      ean: "",
      inputValue: "",
      isAdminOrPartner: false,
      isEditable: false,
    };
    this.state = { ...this.initialState };

    this.addSdpButton = React.createRef();
    this.eanInput = React.createRef();
    this.getDgosList = this.getDgosList.bind(this);
    this.onAddNewSdp = this.onAddNewSdp.bind(this);
    this.onEanSearch = this.onEanSearch.bind(this);
    this.onInputEanSearch = this.onInputEanSearch.bind(this);
    this.onEanSelected = this.onEanSelected.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.openInfo = this.openInfo.bind(this);
    this.numberField = this.numberField.bind(this);
    this.getOposite = this.getOposite.bind(this);
    this.getTypeLocal = this.getTypeLocal.bind(this);
    this.getDirection = this.getDirection.bind(this);
    this.onEanClicked = this.onEanClicked.bind(this);
    this.sdpCheck = this.sdpCheck.bind(this);
    this.checkEans = this.checkEans.bind(this);
    // this.checkDirection = this.checkDirection.bind(this);
    this.tmpGsrn = this.tmpGsrn.bind(this);
    this.formChange = this.formChange.bind(this);
    this.handleTechnologyChange = this.handleTechnologyChange.bind(this);
    this.handlePowerChange = this.handlePowerChange.bind(this);
    // this.handleInstallationsChange = this.handleInstallationsChange.bind(this);

    this.strings = LocalizedStrings({
        en: {
          loading: "Loading...",
          portalAvailable:
            "Portal is not available Preswtich was not done. Please try again later",
          successfullPreswtich: "Preswitch was successfull",
          companyLinkedError: "This EAN is not linked with Company",
          internalError:
            "Internal error preswitch was not successfull. Please contact Yuso techical support",
          directionWarningSelect:
            "SDPs for DMR meeters can not be added without the Supply direction",
          errorRequired: "All marked fields are required.",
          errorMinLength: "The EAN number must contain 18 digits.",
          errorAlreadyAdded: "This EAN number is already added.",
          errorWrongFormat:
            "The VREG ID must start with 'PVZG', 'PVZ', 'G' or 'WKK'. Example: PVZG12345, PVZ123456, G12345678",
          errorAMR:
            "This connection point doesn't have an AMR or DMR meter that can be settled on a quarter-hourly basis by the network operator. Consequently, Yuso cannot make a proposal for your company. If you have any questions about this please contact",
          selectDesc:
            "Please select service delivery point type below to see description for it.",
          supplyDesc:
            "To make a proposal for the supply of electricity by Yuso or make an analysis, we need a few details from you: the EAN number of your supply meter, the capacity of your connection and the expected annal offtake from the grid. The EAN number can be found on the invoice of your current supplier.",
          injectionDesc:
            "To make an proposal for purchase of the injected energy or make an analysis, we need a few details from you: the EAN number of your injection meter, the installated capacity of your production installation and the annual energy volume expected to be injected on the grid. For existing installations, the EAN number can be found on your current invoice. For new installation, your local grid company or installer can provide you with the EAN number.",
          productionDesc: "",
          disabledEan: "Disabled ean is already with the selected company",
          disabledEans: "Disabled eans are already with the selected company",
          wrongDirection: "Wrong direction",
          wrongDirection1: "The entered EAN number is of type",
          wrongDirection2: "but you're trying to add it as",
          wrongDirection3: "Please change the direction to",
          wrongDirection4: "or select the correct EAN",
          error: "There was an error while adding the SDP. Please try again.",
          selectType: "Please first select the type of the delivery point:",
          sdpTypeWarning:
            "The Injection EAN number is different from the Consumption EAN number. Make sure you choose the correct direction.",
          consentRequired:
            "Please confirm your AMR digital meter with the checkbox above.",
          technical: "Technical data",
          supplyPoint: "of the supply point",
          injectionPoint: "of the injection point",
          productionPoint: "of the production point",
          sdpPoint: "of the SDP",
          supply: "Supply",
          preswitchLight: "Preswitch light",
          injOfftake: "Injection/Offtake",
          injection: "Injection",
          switchType: "Switch type",
          switchTypeMoveIn: "New installation",
          switchTypeNormalSwitch: "Supplier switch",
          switchTypeSwitchAfterEoC: "Supplier switch at end of the contract",
          internalSwitch: "Internal switch",
          ean: "EAN",
          lookup: "Lookup EAN",
          lookupUsers: "Lookup users",
          errorEmptyEan:
            "Please type full or part of the EAN number in the field above.",
          noEansFound: "No EAN(s) found, please enter the EAN info below.",
          searching: "Looking up EAN...",
          dgo: "DGO",
          for: "for",
          streetAddress: "Street address",
          streetNumber: "Street number",
          zipCode: "Zip code",
          city: "City",
          forWord: "for",
          serviceComponentConfiguration: "Service configuration",
          technology: "Technology",
          supplyPower: "Power capacity of your offtake connection",
          injectionPower: "Power capacity of your production installation",
          power: "Power capacity",
          offtakePower: "Offtake power capacity",
          supplyVolume: "Expected annual offtake volume",
          injectionVolume: "Expected annual injection volume",
          volume: "Expected annual volume",
          balancingFee: "Platform fee",
          priceGSC: "Price GSC",
          priceWKC: "Price WKC",
          GOprice: "GO price",
          addedSdp: "We successfully added your SDP with the unique reference",
          clear: "Clear",
          form: "form",
          addBtn: "Add",
          adding: "Adding...",
          supplyPoint2: "Supply Point",
          injectionPoint2: "Injection Point",
          point: "SDP",
          note: "NOTE:",
          isAlready: "is already with selected company",
          previousFeeSupply:
            "The company has previous supply contract with Platfrom fee of",
          previousFeeInjection:
            "The company has previous injection contract with Platfrom fee of",
          previousPriceGSC:
            "The company has previous supply contract with GSC price of",
          previousPriceWKC:
            "The company has previous supply contract with WKC price of",
          previousGOprice:
            "The company has previous injection contract with GO price of",
          leaveEmptyCert:
            "Leave the field above empty to keep the previous value.",
          consentTitle: "Confirmation required",
          consent:
            "I confirm that my connection point has a digital meter with quarter-hour measurements (AMR meter). If this is not the case Yuso has the right to cancel their services.",
          moreInfo: "More information",
          moreInfoDesc:
            "As Yuso works with hourly prices we can only deliver our service to companies that have a digital meter that registers measurements for each quarter. To find out your type of meter you can check the documents which you receive from your local network operator. If your network operator comes by once a year or once a month to read out your metering data manually, you can not sign up for the services of Yuso.",
          confirm: "I confirm",
          meterType: "Meter type",
          Continu: "Continuous",
          Maandelijks: "Monthly",
          Jaarlijks: "Annually",
          Forfaitair: "Flat rate",
          na: "N/A",
          direction: "Direction",
          injectionLink: "Injection EAN",
          noInjectionPoints: "There are no injection points",
          adminFields:
            "This fields are visible and can be set only by administrators",
          if: "If",
          notRecognized: "EAN not recognized",
          partner: "partner",
          adminitrator: "adminitrator",
          user: "common user",
          physicalStatus: "Physical status",
          serviceComponentIdentification: "Service Component",
          unsupportedMeter: "Unsupported meter type",
          unsupportedMeter1:
            "This connection point doesn't have an AMR or DMR meter that can be settled on a quarter-hourly basis by the network operator. Consequently, Yuso cannot make a proposal for your company. If you have any questions about this please contact",
          digitalMeters: "digital meters",
          close: "Close",
          added1: "There is already an",
          added6: "SDP with",
          added2: "the selected company",
          added3: "another company",
          added4: ", currently",
          not: "not ",
          addedMessage: "This",
          addedMessage1:
            "Only proceed if you want to switch this point to another company.",
          addedMessage2: "is already linked to another company",
          added5: "with Yuso for the EAN you are trying to add.",
          addedDirection:
            "There is already an SDP for the selected DMR's direction.",
          internalSwitch3:
            "for the EAN you are trying to add that currently is with Yuso.",
          internalSwitch4:
            "To do an Internal Switch of the point onboard the point for the new company.",
          internalSwitch5:
            "To do an Internal Switch of the point, please contact",
          internalSwitch6: "to onboard your measurement point.",
          contractsPage: "Contracts page",
          disclaimer: "DISCLAIMER: ",
          disclaimerInfo:
            "For Supply and Injection if volume is not correct or representative within a margin of 20% Yuso maintains the right to change the platform fee and partnerfee.",
          diffCompanyWithYusoInfo:
            "There is an SDP point currently with Yuso for the selected EAN with company",
          diffCompanyWithYusoInfo2:
            "Proceeding with adding of this SDP will result with internal switch for selected EAN.",
          diffCompanyNotWithYusoInfo:
            "There is an SDP point currently NOT with Yuso for the selected EAN with company",
          diffCompanyNotWithYusoInfo2: "with EOC date",
          sameCompanyNotWithYusoInfo:
            "There is an SDP point currently NOT with Yuso for the selected EAN with this company with EOC date",
          companyNotWithYusoInfo: "and EOC status reason - ",
          country: "Country",
          belgium: "Belgium",
          theNetherlands: "The Netherlands",
          production: "Production",
          enterEan: "Add EAN",
          enterEanLater: "Enter EAN",
          newInstallation: "New Installation",
          solar: "Solar",
          wind: "Wind",
          thermal: "Thermal",
          hydro: "Hydro-electric head installations",
          other: "Other",
          sdpAdded: "You successfully added a measurement point",
          sdpAddedDesc:
            "Add all of your measurement points and request a contract proposal.",
          add: "Add",
          newPoint: "new point",
          requestSimulation: "Request a simulation",
          requestSimulationDesc3:
            "Yuso will request the meterdata with the grid company. It can take a few days before we receive this.",
          requestSimulationDesc4:
            "Once we receive the data Yuso will come back via email to go through the simulation.",
          giveMandate1: "Give",
          giveMandate2: "mandate for meterdata",
          invalidEANFormat:
            "Please enter EAN as: 'EAN + 18 digits' or 18 digits only."
        },
        nl: {
          loading: "Bezig met laden...",
          addedMessage: "Geselecteerde",
          addedMessage1:
            "Ga alleen verder als u het punt van bedrijf wilt wijzigen.",
          addedMessage2: "is al actief bij",
          forWord: "voor",
          disabledEan: "Gehandicapte EAN is al bij het geselecteerde bedrijf.",
          disabledEans:
            "Gehandicapte EANS zijn al bij het geselecteerde bedrijf.",
          notRecognized: "EAN niet herkend",
          directionWarningSelect:
            "SDP's voor DMR-meters kunnen niet worden toegevoegd zonder de Aanvoerrichting",
          portalAvailable:
            "Portal is niet beschikbaar Preswtich was niet klaar. Probeer het later opnieuw",
          successfullPreswtich: "Preswitch was succesvol",
          internalError:
            "Interne fout preswitch was niet succesvol. Neem contact op met de technische ondersteuning van Yuso",
          errorRequired: "Alle gemarkeerde velden zijn verplicht.",
          errorMinLength: "Het EAN-nummer moet uit 18 cijfers bestaan.",
          errorAlreadyAdded: "Dit EAN-nummer is al toegevoegd.",
          errorWrongFormat:
            "De VREG ID moeten beginnen met 'PVZG', 'PVZ', 'G' of 'WKK'. Voorbeeld: PVZG12345, PVZ123456, G12345678",
          errorAMR:
            "Dit aansluitpunt beschikt niet over een AMR- of DMR-meter die door de netbeheerder op kwartuurbasis kan worden afgerekend. Daarom kan Yuso geen voorstel doen voor uw bedrijf. Heeft u hier vragen over, neem dan contact op met",
          selectDesc:
            "Selecteer hieronder het type service afleveringspunt om de beschrijving ervan te bekijken.",
          supplyDesc:
            "Om een ​​voorstel te doen voor de levering van elektriciteit door Yuso of om een ​​analyse te maken, hebben we nog enkele gegevens nodig: het EAN-nummer van jouw leveringsmeter, de capaciteit van jouw verbinding en de verwachte jaarlijkse afname van het net. Het EAN-nummer staat steeds vermeldt op de factuur van jouw huidige leverancier.",
          injectionDesc: "Om een ​​voorstel te doen voor het opkopen van de geïnjecteerde energie of om een ​​analyse uit te voeren, hebben we nog enkele gegevens nodig: het EAN-nummer van jouw injectiemeter, de geïnstalleerde capaciteit van jouw productie-installatie en het verwachte jaarlijkse injectievolume. Voor bestaande installaties is het EAN-nummer te vinden op jouw huidige factuur. Voor een nieuwe installatie kan jouw lokale netwerkbedrijf of installateur je het EAN-nummer geven.",
          productionDesc: "",
          for: "voor",
          wrongDirection: "Verkeerde kant",
          wrongDirection1: "De ingevoerde EAN nummer is van het type",
          wrongDirection2: "maar je probeert het toe te voegen als",
          wrongDirection3: "Wijzig de richting in",
          wrongDirection4: "of selecteer de juiste EAN",
          error: "Er is een fout opgetreden tijdens het toevoegen van de SDP. Probeer het opnieuw.",
          selectType: "Selecteer eerst het type afleverpunt:",
          sdpTypeWarning: "Het Injection EAN-nummer verschilt van het Consumption EAN-nummer. Zorg ervoor dat u de juiste richting kiest.",
          consentRequired: "Bevestig jouw AMR digitale meter met het bovenstaande selectievakje.",
          technical: "Technische data",
          supplyPoint: "van het leveringspunt",
          injectionPoint: "van het injectiepunt",
          productionPoint: "van het productiepunt",
          preswitchLight: "Voorschakellicht",
          sdpPoint: "van de SDP",
          supply: "Levering",
          injOfftake: "Afname/Injectie",
          injection: "Injectie",
          switchType: "Overstap type",
          serviceComponentConfiguration: "Service Configuratie",
          switchTypeMoveIn: "Nieuwe installatie",
          switchTypeNormalSwitch: "Overstap naar andere leverancier",
          switchTypeSwitchAfterEoC: "Overstap naar andere leverancier bij einde van contract",
          internalSwitch: "Intern overstap",
          ean: "EAN",
          lookup: "EAN opzoeken",
          lookupUsers: "Zoek gebruikers",
          errorEmptyEan: "Typ het volledige of een deel van het EAN-nummer in het bovenstaande veld.",
          noEansFound: "Geen EAN(s) gevonden, voer hieronder de EAN info in.",
          searching: "EAN opzoeken...",
          dgo: "DGO",
          streetAddress: "Straat",
          streetNumber: "Huisnummer",
          zipCode: "Postcode",
          city: "Stad",
          technology: "Technologie",
          supplyPower: "Capaciteit van je afnameaansluiting",
          injectionPower: "Vermogen van je productie-installatie",
          power: "Vermogen",
          supplyVolume: "Verwacht jaarlijks afnamevolume",
          injectionVolume: "Verwacht jaarlijks injectievolume",
          volume: "Verwacht jaarvolume",
          balancingFee: "Platformkosten",
          priceGSC: "Prijs GSC",
          priceWKC: "Prijs WKC",
          GOprice: "GO prijs",
          addedSdp: "We hebben jouw SDP met succes toegevoegd met de unieke referentie",
          clear: "Wis",
          form: "alle velden",
          addBtn: "Toevoegen",
          adding: "Toevoegen...",
          supplyPoint2: "Leveringspunt",
          injectionPoint2: "Injectiepunt",
          point: "SDP",
          note: "NOTITIE:",
          selectSwitchType: "Kies het type schakelaar om de startdatum te selecteren",
          previousFeeSupply: "Het bedrijf heeft vorige leveringscontract met Platformkosten van",
          previousFeeInjection: "Het bedrijf heeft eerder een injectiecontract met Platformkosten van",
          previousPriceGSC: "Het bedrijf heeft vorig leveringscontract met GSC-prijs van",
          previousPriceWKC: "Het bedrijf heeft vorige leveringscontract met WKC-prijs van",
          previousGOprice: "Het bedrijf heeft eerder een injectiecontract afgesloten met de GO-prijs van",
          leaveEmptyCert: "Laat het veld hierboven leeg om de vorige waarde te behouden.",
          consentTitle: "Bevestiging vereist",
          consent: "Ik bevestig dat mijn aansluitingspunt beschikt over een digitale meter die op kwartierbasis wordt uitgelezen door de netbeheerder (AMR meter). Indien dit niet het geval is heeft Yuso het recht om de dienstenovereenkomst te annuleren.",
          moreInfo: "Meer informatie",
          moreInfoDesc: "Aangezien Yuso werkt met uurlijkse prijzen kunnen we onze service voorlopig enkel aanbieden aan bedrijven die beschikken over een digitale meter die de meetgegevens automatisch registreert en doorstuurt naar de netbeheerder. Indien de netbeheerder jaarlijks of maandelijks langskomt om de meetgegevens uit te lezen kan Yuso geen contract aanbieden. Het type van jouw meter kan je terugvinden op de documenten die je ontvangt van je lokale netbeheerder.",
          confirm: "Ik bevestig",
          meterType: "Metertype",
          Continu: "Continu",
          Maandelijks: "Maandelijks",
          Jaarlijks: "Jaarlijks",
          Forfaitair: "Forfaitair",
          isAlready: "is al bij het geselecteerde bedrijf",
          na: "N/A",
          direction: "Richting",
          injectionLink: "Injectie EAN",
          noInjectionPoints: "Er zijn geen injectiepunten",
          adminFields: "Deze velden zijn zichtbaar en kunnen alleen door beheerders worden ingesteld",
          if: "Als",
          partner: "partner",
          adminitrator: "beheerder",
          user: "gemeenschappelijke gebruiker",
          physicalStatus: "Fysieke toestand",
          serviceComponentIdentification: 'Service Component',
          unsupportedMeter: "Niet-ondersteund type meter",
          unsupportedMeter1: "Dit aansluitpunt beschikt niet over een AMR- of DMR-meter die door de netbeheerder op kwartuurbasis kan worden afgerekend. Daarom kan Yuso geen voorstel doen voor uw bedrijf. Heeft u hier vragen over, neem dan contact op met",
          digitalMeters: "digitale meters",
          close: "Sluiten",
          added1: "Er is al een",
          added6: "SDP met",
          added2: "het geselecteerde bedrijf",
          added3: "een ander bedrijf",
          added4: ", momenteel",
          not: "niet ",
          added5: "met Yuso voor de EAN die u probeert toe te voegen.",
          addedDirection: "Er is al een SDP voor de geselecteerde DMR-richting.",
          internalSwitch3: "voor de EAN die u probeert toe te voegen en die momenteel bij Yuso . is.",
          internalSwitch4:
            "Om een interne wisseling van het punt aan boord van het punt voor het nieuwe bedrijf te doen.",
          internalSwitch5:
            "Om een interne wisseling van het punt te doen, neem contact op met",
          internalSwitch6: "aan boord van uw meetpunt.",
          renewContractInfo:
            "Er is een SDP-punt voor deze EAN om te verlengen of een nieuw contract aan te maken ga naar",
          contractsPage: "Contracten pagina",
          disclaimer: "DISCLAIMER: ",
          disclaimerInfo:
            "Voor levering en injectie als het volume niet correct of representatief is binnen een marge van 20%, behoudt Yuso zich het recht voor om de platformvergoeding en Partner commissie te wijzigen.",
          diffCompanyWithYusoInfo:
            "Er is momenteel een SDP-punt bij Yuso voor de geselecteerde EAN met bedrijf",
          diffCompanyWithYusoInfo2:
            "Doorgaan met het toevoegen van deze SDP zal resulteren in een interne switch voor de geselecteerde EAN.",
          diffCompanyNotWithYusoInfo:
            "Er is momenteel een SDP-punt NIET bij Yuso voor de geselecteerde EAN met bedrijf",
          diffCompanyNotWithYusoInfo2: "met EOC-datum",
          sameCompanyNotWithYusoInfo:
            "Er is momenteel een SDP-punt NIET bij Yuso voor de geselecteerde EAN met dit bedrijf met EOC-datum",
          companyNotWithYusoInfo: "en EOC-statusreden -",
          country: "Land",
          belgium: "Belgium",
          theNetherlands: "The Netherlands",
          production: "Productie",
          enterEan: "EAN toevoegen",
          enterEanLater: "Voer EAN in",
          newInstallation: "Nieuwe installatie",
          solar: "Zonne",
          wind: "Wind",
          hydro: "Hydro",
          thermal: "Thermische",
          hydroelectricHeadInstallations: "Hydro-elektrisch hoofd installaties",
          other: "Ander",
          sdpAdded: "U heeft met succes een meetpunt toegevoegd",
          sdpAddedDesc:
            "Voeg al je meetpunten toe en vraag een contractvoorstel aan.",
          add: "Voeg",
          newPoint: "een nieuw punt toe",
          requestSimulation: "Vraag een simulatie aan",
          requestSimulationDesc3:
            "Yuso zal de meterdata opvragen bij het netwerkbeheerder. Het kan enkele dagen duren voordat we dit ontvangen.",
          requestSimulationDesc4:
            "Zodra we de gegevens hebben ontvangen, komt Yuso via e-mail terug om de simulatie te doorlopen.",
          giveMandate1: "Geef",
          giveMandate2: "toegang tot meetwaarden",
        },
      },
      this.props.lang
    );
  }

  componentDidMount() {
    this.props.changeType(this.state.selectedEan.direction);
    this.getDgosList();
    this.updateEditability();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedCompany.role !== this.props.selectedCompany.role ||
      prevState.selectedEan !== this.state.selectedEan
    ) {
      this.updateEditability();
    }
  }

  getOposite(type) {
    if (type === "supply") return this.strings.injection.toLowerCase();
    else if (type === "injection") return this.strings.supply.toLowerCase();
    else return "N/A";
  }

  getTypeLocal(type) {
    if (type === "supply") return this.strings.supply.toLowerCase();
    else if (type === "injection") return this.strings.injection.toLowerCase();
    else if (type === "production")
      return this.strings.production.toLowerCase();
    else return "N/A";
  }

  formChange(ean) {
    if (this.state.selectedEan.gsrn.length === 21) {
    }
    let selectedOption = this.state.selectedEans.find(
        (x) => x.gsrn === ean.gsrn && x.meterType === ean.meterType
      ),
      selectedEan = {
        ...this.state.selectedEan,
        ...selectedOption,
        country:
          this.state.selectedEan.country === "Belgium" ||
          this.state.selectedEan.country === "BE"
            ? "Belgium"
            : this.state.selectedEan.country === "NL"
            ? "The Netherlands"
            : "",
        dgoName:
          this.state.dgos.find(
            (dgo) =>
              dgo.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
              selectedOption.dgo.replace(/[^a-z0-9]/gi, "").toLowerCase()
          ) || "",
        direction: this.getDirection(selectedOption.meterType, true),
        filled: true,
        reset: false,
        technologies: this.state.selectedEan.technologies
      };
    if (selectedOption.selected === true) {
      this.setState({
        selectedEan,
        directionWarningSelect: false,
        missingData: {
          typeOfMeter: !selectedEan.typeOfMeter,
          direction: !selectedEan.direction,
          apStreetName: !selectedEan.apStreetName,
          appHouseNumber: !selectedEan.appHouseNumber,
          apZip: !selectedEan.apZip,
          apCity: !selectedEan.apCity,
          country: !selectedEan.country,
          dgoName: !selectedEan.dgoName,
          technology: !selectedEan.technologies
        },
        sdpCheck: undefined,
        expandedForm: !this.state.expandedForm,
      });
    }
  }

  tmpGsrn() {
    if (this.state.selectedEan && (
      this.state.selectedEan.gsrn === "EAN" ||
      this.state.selectedEan.gsrn.length === 21 
    ))
     {
      this.setState(
        !this.state.selectedEan.tmpGsrn
          ? {
              selectedEan: {
                ...this.emptyEan,
                gsrn: "EANTMP000000000000000",
                filled: true,
                tmpGsrn: true,
                selected: true,
                country: this.props.selectedCompany.addressCountry,
                injectionPowers: {}
              },
              expandedForm: true,
              isButtonDisabled: true,
            }
          : {
              selectedEan: { ...this.emptyEan, filled: false, tmpGsrn: false },
              expandedForm: false,
            },
        () => {
          if (this.state.selectedEan.tmpGsrn)
            getTMPEans((err, res) => {
              if (err) return window.reportError.send(err);

              this.setState(
                {
                  selectedEan: {
                    ...this.state.selectedEan,
                    gsrn: "EANTMP" + res,
                  },
                  selectedEans: [
                    { ...this.state.selectedEan, gsrn: "EANTMP" + res },
                  ],
                },
                this.getDgosList
              );
            });
          else this.props.changeType("");
        }
      );
    } else {
      this.setState({
        noEanTyped: true,
      });
    }
  }

  onAddNewSdp(event) {
    event.persist();

    if (!this.props.selectedCompany) return;

    event.preventDefault();

    this.setState({ error: false, errorAMR: false, addingSpd: true });

    let hasError = false;

    let eans = this.state.selectedEans;
    for (let i = 0; i < eans.length; i++) {
      if (
        eans[i].apCity === "" ||
        eans[i].apStreetName === "" ||
        eans[i].apZip === "" ||
        eans[i].appHouseNumber === "" ||
        eans[i].country === "" ||
        eans[i].direction === "" ||
        eans[i].dgoName === ""
      ) {
        hasError = true;
        break;
      } else if (
        eans[i].direction === 'injection' &&
        (eans[i].injectionVolume === "" || eans[i].technologies === "")
      ) {
        hasError = true;
        break;
      } else if (
        eans[i].direction === "supply" &&
        eans[i].supplyVolume === ""
      ) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      this.setState({ error: this.strings.errorRequired, addingSpd: false });
    } else {
      this.setState({ error: false, errorAMR: false, addingSpd: true });

      for (let i = 0; i < eans.length; i++) {
        checkSdp(this.props.selectedCompany.company_id, eans[i], (err, res) => {
          if (err) {
            return window.reportError.send(err);
          }

            if (res.error) {
              if (res.error.existing) {
                this.setState({ addingSpd: false, error: this.strings.errorAlreadyAdded });
              } else if (res.error.wrongDirection) {
                this.setState({ addingSpd: false, error: `${this.strings.wrongDirection1} "${this.getOposite(this.state.selectedEan.direction)}", ${this.strings.wrongDirection2} "${this.getTypeLocal(this.state.selectedEan.direction)}".` });
              } else if (res.error.nonAMR) {
                this.setState({ addingSpd: false, errorAMR: true });
              } else {
                this.setState({ addingSpd: false, error: this.strings.error });
              }
            } else if (res.status) {
              if (res.status === "OK" || res.status === "CONSENT") {
                addSdp(
                  this.props.selectedCompany.company_id,
                  eans[i],
                  (err, res) => {
                    if (err) {
                      return window.reportError.send(err);
                    }

                  if (res.error) {
                    if (res.error.existing) {
                      this.setState({
                        addingSpd: false,
                        error: this.strings.errorAlreadyAdded,
                      });
                    } else if (res.error.wrongDirection) {
                      this.setState({
                        addingSpd: false,
                        error: `${
                          this.strings.wrongDirection1
                        } "${this.getOposite(
                          this.state.selectedEan.direction
                        )}", ${
                          this.strings.wrongDirection2
                        } "${this.getTypeLocal(
                          this.state.selectedEan.direction
                        )}".`,
                      });
                    } else if (res.error.nonAMR) {
                      this.setState({ addingSpd: false, errorAMR: true });
                    } else {
                      this.setState({
                        addingSpd: false,
                        error: this.strings.error,
                      });
                    }
                  } else {
                    if (eans.length === i + 1) {
                      this.setState(
                        { addingSpd: false, newSpdId: res.id },
                        () => {
                          this.props.updateSdps();
                          this.props.updateMandates();
                        }
                      );
                    }
                  }
                }
              );
            }
          }
        });
      }
    }
  }

  onEanSearch() {
    if (this.state.selectedEan.gsrn.length > 3 && this.state.selectedEan.gsrn.length === 21) {
      this.setState({ isLoading: true, noEanTyped: false, selectedEans: [], selectedEan: { ...this.emptyEan, injectionPowers: {} }, sdpCheck: undefined, isButtonDisabled: true }, this.sdpCheck);
      let eanTemp = this.state.selectedEan.gsrn

      submit(
        this.state.selectedEan.gsrn,
        "PRESWITCH",
        undefined,
        (err, res) => {
          let errorMessage = "";
          if (res.error) {
            if (
              res.error.includes(
                "Cannot read property 'companyName' of undefined"
              )
            ) {
              errorMessage = "This EAN is not linked to any company.";
            } else {
              errorMessage = "An error occured please try again later.";
            }

            this.setState({
              isLoading: false,
              isAvailable: { error: true, message: errorMessage },
            });
            return;
          }
          let preSelected =
            res.error === undefined && res.available === undefined
              ? res.clientResponse.find(
                  (x) => x.selected === true && x.sdpAvailable !== true
                )
              : undefined;

          if (preSelected) {
            const newCountry = this.state.selectedEan.gsrn.startsWith("EAN54")
              ? "Belgium"
              : this.state.selectedEan.gsrn.startsWith("EAN87")
              ? "The Netherlands"
              : this.props.selectedCompany.addressCountry;

            const updatedSelectedEan = {
              ...this.state.selectedEan,
              ...preSelected,
              dgoName:
                this.state.dgos.find(
                  (dgo) =>
                    dgo.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
                    preSelected.dgo.replace(/[^a-z0-9]/gi, "").toLowerCase()
                ) || "",
              direction: this.getDirection(preSelected.meterType, true),
              filled: true,
              reset: false,
              country: newCountry,
            };

            this.setState((prevState) => ({
              selectedEan: updatedSelectedEan,
              selectedEans: [...prevState.selectedEans, updatedSelectedEan],
            }));
          }

        this.setState({
          isLoading: false,
          options: res.clientResponse,
          isAvailable: res ? res : false,
          selectedEan: {
            ...this.emptyEan,
            ...this.state.selectedEan,
            gsrn: this.state.selectedEan.gsrn,
            country: this.state.selectedEan.gsrn.startsWith('EAN54') ? 'Belgium' : this.state.selectedEan.gsrn.startsWith('EAN87') ? 'The Netherlands' : this.props.selectedCompany.addressCountry,
            direction: this.state.selectedEan.country === 'The Netherlands' ? 'injection+supply' : this.state.selectedEan.direction
          },
          expandedForm: res.clientResponse !== undefined && !res.clientResponse.length ? true : false
        }, () => {
          this.sdpCheck();
          if ((this.state.isAvailable.available !== undefined && this.state.isAvailable.available === false) || (this.state.isAvailable.error !== undefined && this.state.isAvailable.error === true)) {
            this.setState({
              isLoading: true,
              expandedForm: false,
              options: undefined
            })

                if (preSelected) {
                  const options = [...this.state.options];

                  options.forEach((x) => {
                    if (
                      x.meterType === preSelected.meterType &&
                      x.gsrn === preSelected.gsrn
                    )
                      x.selected = false;
                  });

                  this.setState({
                    options,
                  });
                }

                this.checkEans(eanTemp);
              }
            }
          );
        }
      );
    } else {
      this.setState({
        noEanTyped: true,
      });
    }
  }

  checkEans(ean) {
    this.setState({
      isLoading: true,
      noEanTyped: false,
      selectedEans: [],
      selectedEan: { ...this.emptyEan },
    });
    getEans(ean, (err, res) => {
      if (err) {
        return window.reportError.send(err);
      }
      this.setState(
        {
          isLoading: false,
          options: res,
          selectedEan: {
            ...this.emptyEan,
            gsrn: ean,
            country: ean.startsWith("EAN54")
              ? "Belgium"
              : ean.startsWith("EAN87")
              ? "The Netherlands"
              : this.props.selectedCompany.addressCountry,
            direction: ean.startsWith("EAN87")
              ? "injection+supply"
              : this.state.selectedEan.direction,
          },
          expandedForm: !res.length ? true : false,
        },
        () => {
          if (this.state.options.length) {
            if (this.state.selectedEan.gsrn.length === 21 && !res.length)
              this.sdpCheck();
          } else {
            this.setState({
              selectedEan: {
                ...this.emptyEan,
                gsrn: ean,
                tmpGsrn: true,
                filled: true,
                country: ean.startsWith("EAN54")
                  ? "Belgium"
                  : ean.startsWith("EAN87")
                  ? "The Netherlands"
                  : "",
              },
              selectedEans: [
                {
                  ...this.emptyEan,
                  gsrn: ean,
                  tmpGsrn: true,
                  selected: true,
                  country: ean.startsWith("EAN54")
                    ? "Belgium"
                    : ean.startsWith("EAN87")
                    ? "The Netherlands"
                    : this.props.selectedCompany.addressCountry,
                  direction: this.state.selectedEan.direction,
                },
              ],
              noEan: true,
            });
          }
        }
      );
    });
  }

  onInputEanSearch(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onEanSearch();
    }
  }

  onEanSelected(event) {
    let selectedOption;

    if (event.target.name) {
      selectedOption = this.state.options.find(
        (x) =>
          x.gsrn === event.target.value && x.meterType === event.target.name
      );
    } else {
      selectedOption = this.state.options.find(
        (x) => x.gsrn === event.target.value
      );
    }

    let selectedEan = {
      ...this.state.selectedEan,
      ...selectedOption,
      dgoName:
        this.state.dgos.find(
          (dgo) =>
            dgo.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
            selectedOption.dgo.replace(/[^a-z0-9]/gi, "").toLowerCase()
        ) || "",
      direction: this.getDirection(selectedOption.meterType, true),
      filled: true,
      reset: false,
      power: '',
      supplyVolume: '',
      injectionVolume: ''
    };

    const options = [...this.state.options];

    if (event.target.name) {
      options.forEach((x) => {
        if (x.meterType === event.target.name && x.gsrn === event.target.value)
          x.selected = true;
      });
    } else {
      options.forEach((x) => {
        if (x.gsrn === event.target.value) x.selected = true;
      });
    }

    this.setState({
      options,
    });

    let eanCheck = this.state.selectedEans.find(
      (x) =>
        x.gsrn === selectedEan.gsrn && x.meterType === selectedEan.meterType
    );

    if (!eanCheck) {
      this.state.selectedEans.push(selectedEan);
      selectedEan["selected"] = true;
    }

    if (selectedEan.gsrn.startsWith("EAN54")) {
      selectedEan.country = "Belgium";
    } else if (selectedEan.gsrn.startsWith("EAN87")) {
      selectedEan.country = "The Netherlands";
    } else {
      selectedEan.country = "";
    }

    this.setState(
      (prevState) => ({
        selectedEans: prevState.selectedEans.map((ean) =>
          ean === selectedEan ? selectedEan : ean
        ),
        selectedEan,
        missingData: {
          typeOfMeter: !selectedEan.typeOfMeter,
          direction: !selectedEan.direction,
          apStreetName: !selectedEan.apStreetName,
          appHouseNumber: !selectedEan.appHouseNumber,
          apZip: !selectedEan.apZip,
          apCity: !selectedEan.apCity,
          country: !selectedEan.country,
          dgoName: !selectedEan.dgoName,
          technology: !selectedEan.technologies,
          supplyVolume: !selectedEan.supplyVolume,
          injectionVolume: !selectedEan.injectionVolume,
          power: !selectedEan.power,
          injectionPowers: prevState.selectedEan.technologies.split(',').reduce((acc, tech) => {
            acc[tech.trim()] = !prevState.selectedEan.injectionPowers[tech.trim()];
            return acc;
          }, {}),
        },
        sdpCheck: undefined,
      }),
      () => {
        this.checkDirection();
        this.sdpCheck();

        if (this.props.changeType) this.props.changeType(selectedEan.direction);
      }
    );
  }

  sdpCheck() {
    if (this.state.selectedEan.direction && this.state.selectedEan.filled) {
      checkSdp(
        this.props.selectedCompany.company_id,
        {
          preCheck: true,
          ean: this.state.selectedEan.gsrn,
          direction: this.state.selectedEan.direction,
          typeOfMeter: this.state.selectedEan.typeOfMeter,
        },
        (err, res) => {
          if (err) {
            return window.reportError.send(err);
          }
          this.setState({
            sdpCheck: {
              ...res,
              status:
                res.list.company === this.props.selectedCompany.companyName &&
                this.state.selectedEan.direction === res.list.direction
                  ? "FORBIDDEN"
                  : "OK",
              sameCompany:
                res.list.company === this.props.selectedCompany.companyName
                  ? true
                  : false,
            },
          });
        }
      );
    }
  }

  onFieldChange(event) {
    const { name, value } = event.target;
    const ean = this.eanInput.current.value;
    const desiredValue = /^(EAN\d{18}|\d{18})$/;
    if (
      (this.props.selectedCompany.role === "admin" && ean === "") ||
      ean.match(desiredValue)
    ) {
      this.setState({
        isButtonDisabled: false,
        showError: false,
        ean: ean,
      });
    } else {
      this.setState({ isButtonDisabled: true, showError: true });
    }

    let selectedEan

    if (event.target) {
      this.setState({ [name]: value });

      if (name === "ean" && this.state.selectedEans.length) {
        this.setState({
          ...this.initialState,
          options: undefined,
          selectedEans: [],
        });
        return;
      }

      selectedEan = { ...this.state.selectedEan };
      if (selectedEan.country === "BE" || selectedEan.country === "NL") {
        selectedEan.country =
          selectedEan.country === "BE"
            ? "Belgium"
            : selectedEan.country === "NL"
            ? "The Netherlands"
            : "";
      }

      switch (name) {
        case 'type':
          selectedEan.direction = value
          this.setState({ type: value, selectedEan }, () => {
            this.checkDirection()
            this.sdpCheck()
          });
          if (this.props.changeType)
            this.props.changeType(value);
          if (value === "production" && this.props.selectedCompany)
            getSdps(this.props.selectedCompany.company_id,
              (err, res) => {
                if (err)
                  return window.reportError.send(err);

              this.setState({
                injectionSdps: res.sdps_injection_hourly,
              });
            });
          break;

        case "ean":
          const gsrn =
            "EAN" +
            event.target.value.replace(/[^\d]/g, "").toUpperCase().trim();
          selectedEan = {
            ...this.state.selectedEan,
            gsrn,
            country: gsrn.startsWith("EAN54")
              ? "Belgium"
              : gsrn.startsWith("EAN87")
              ? "The Netherlands"
              : this.props.selectedCompany.addressCountry,
          };
          // this.setState({ type: '', missingData: {}, expandedForm: false, options: gsrn.length <= 3 ? this.state.options : undefined, sdpCheck: undefined })
          break;

        case "typeOfMeter":
          selectedEan.typeOfMeter = event.target.value;
          break;

        case "address":
          selectedEan.apStreetName = event.target.value;
          break;

        case "number":
          selectedEan.appHouseNumber = event.target.value;
          break;

        case "zip":
          selectedEan.apZip = event.target.value;
          break;

        case "city":
          selectedEan.apCity = event.target.value;
          break;

        case "country":
          selectedEan.country = event.target.value;

          if (selectedEan.country === "The Netherlands")
            selectedEan.direction = "injection+supply";
          break;

        case "dgo":
          selectedEan.dgoName = event.target.value;
          break;

        case "supplyVolume":
          selectedEan.supplyVolume =
            event.target.value && event.target.value >= 0
              ? event.target.value
              : "";
          break;

        case "injectionVolume":
          selectedEan.injectionVolume =
            event.target.value && event.target.value >= 0
              ? event.target.value
              : "";
          break;

        case "volumeUnit":
          selectedEan.volumeUnit = event.target.value
            ? event.target.value
            : "MWh";
          break;

        case "power":
          selectedEan.power =
            event.target.value && event.target.value >= 0
              ? event.target.value
              : event.target.value < 0
              ? 0
              : "";
          break;

        case "powerUnit":
          selectedEan.powerUnit = event.target.value
            ? event.target.value
            : "kW";
          break;

        case "newInstallation":
          selectedEan.newInstallation = event.target.checked;
          break;

        case 'technology':
          selectedEan.technologies = event.target.value
          break;

        default:
          break;
      }

      if (name === "ean") {
        this.setState(
          {
            selectedEan,
          },
          this.getDgosList
        );
      } else {
        const selectedEans = [...this.state.selectedEans];

        for (let i = 0; i < selectedEans.length; i++) {
          if (
            this.state.selectedEan.gsrn.substring(0, 6).toString() ===
              "EANTMP" ||
            this.state.noEan === true
          ) {
            selectedEan.reset = false;
            selectedEan.selected = true;
            selectedEans[i] = selectedEan;
          } else {
            if (
              selectedEans[i].gsrn === this.state.selectedEan.gsrn &&
              selectedEans[i].meterType === this.state.selectedEan.meterType
            ) {
              selectedEan.reset = false;
              selectedEans[i] = selectedEan;
            }
          }
        }
        this.setState(
          {
            selectedEans,
            selectedEan,
          },
          this.getDgosList
        );
      }
    }
  }

  handleTechnologyChange (event)  {
    const { name, checked } = event.target;
    let selectedTechnologies;
  
    if (checked) {
      selectedTechnologies = this.state.selectedEan.technologies ? `${this.state.selectedEan.technologies},${name}` : name;
    } else {
      selectedTechnologies = this.state.selectedEan.technologies.split(',').filter(tech => tech !== name).join(',');
    }
  
    this.setState(prevState => ({
      selectedEan: {
        ...prevState.selectedEan,
        technologies: selectedTechnologies
      }
    }));
  }

  handlePowerChange(tech, event) {
    const value = event.target.value;
    this.setState(prevState => {
        const selectedEan = { ...prevState.selectedEan };
        selectedEan.injectionPowers[tech] = value;
        return { selectedEan };
    });
}

  onConfirm() {
    this.setState(
      {
        consent: true,
      },
      () => {
        this.addSdpButton.current.click();
      }
    );
  }

  resetForm() {
    this.setState({
      ...this.initialState,
      selectedEan:{
        gsrn: "EAN",
        injectionPowers: {}
      },
      selectedEans: [],
      isButtonDisabled: false,
    });
    this.getDgosList();
  }

  getDgosList() {
    getDgos((err, res) => {
      if (err) {
        return window.reportError.send(err);
      }

      this.setState({
        dgos: res
          .filter((x) => x.country === this.state.selectedEan.country)
          .map((x) => x.dgo),
      });
    });
  }

  openInfo() {
    this.setState({ moreInfo: !this.state.moreInfo });
  }

  numberField(e) {
    if (!/^[0-9.Ee]+$/.test(String.fromCharCode(e.which))) e.preventDefault();
  }

  getDirection(direction, typeFlag) {
    switch (direction) {
      case "Afname":
        return !typeFlag ? this.strings.supply : "supply";

      case "Injectie":
        return !typeFlag ? this.strings.injection : "injection";
      case "injection+offtake":
        return !typeFlag ? this.strings.injOfftake : "injection+offtake";
      default:
        return !typeFlag ? "N/A" : "";
    }
  }

  onEanClicked(ean) {
    let eanClicked;

    if (this.state.selectedEans.length) {
      eanClicked = this.state.selectedEans.find(
        (x) => x.gsrn === ean.gsrn && x.meterType === ean.meterType
      );
    }

    if (eanClicked !== undefined) {
      if (
        (ean.gsrn === eanClicked.gsrn &&
          ean.meterType !== eanClicked.meterType) ||
        ean.gsrn !== eanClicked.gsrn
      ) {
        this.onEanSelected({
          target: {
            value: ean.gsrn,
            name: ean.meterType,
          },
        });
      } else {
        if (
          eanClicked.typeOfMeter === "DMR" &&
          eanClicked.meterType === "Afname" &&
          !eanClicked.eansTable
        ) {
          this.setState({
            directionWarningSelect: true,
          });
        } else {
          let indexArray = this.state.selectedEans.findIndex(
            (x) =>
              x.gsrn === eanClicked.gsrn && x.meterType === eanClicked.meterType
          );

          let newSelectedEans = this.state.selectedEans.filter(
            (x, index) => index !== indexArray
          );
          if (newSelectedEans.length) {
            this.setState({
              selectedEans: newSelectedEans,
              directionWarningSelect: false,
              expandedForm: false,
              selectedEan: {
                ...this.state.selectedEan,
                power: '',
                injectionVolume: '',
                supplyVolume: ''
              }
            });
          } else {
            this.setState({
              selectedEans: [],
              sdpCheck: undefined,
              isAvailable: undefined,
              // companyLinked: undefined,
              expandedForm: false,
            });
          }
        }
      }
    }
  }

  checkDirection() {
    let type = this.state.selectedEan.direction;
    let direction = this.state.selectedEan.meterType;

    if (type && direction) {
      if (
        (direction === "Afname" && type !== "supply") ||
        (direction === "Injectie" && type !== "injection")
      ) {
        this.setState({
          warningEan: { ...this.state.selectedEan },
          directionWarning: true,
        });

        $("#warning-direction").modal("show");
      } else
        this.setState({
          directionWarning: false,
        });
    }
  }

  shouldAllowEdit = () => {
    const { selectedEan } = this.state;
    const role = this.props.selectedCompany.role;
    const isAdminOrPartner = role === "admin" || role === "partner";

    const eligibleDGOs = [
      "Sibelga-IE",
      "Sibelga-SE",
      "ORES (Brabant wallon)",
      "ORESE (Mouscron)",
      "ORES (Hainaut Électricité)",
      "ORES (Luxembourg)",
      "ORES (Namur)",
      "ORES (Verviers)",
      "ORES (Hainaut Électricité2)",
      "AIESH",
      "AIEG",
      "Resa",
      "Regie de Wavre",
    ];

    const isEligibleDGO = eligibleDGOs.includes(selectedEan.dgoName);
    const isAMRMeterType = selectedEan.typeOfMeter === "AMR";
    const isServiceComponentOfftake =
      selectedEan.serviceComponentConfiguration === "SC_OFFTAKE";
    const isTemporaryEan = selectedEan.gsrn ? selectedEan.gsrn.substring(0, 6).toString() === 'EANTMP'  : ''

    return (
      (isAdminOrPartner &&
      isAMRMeterType &&
      isServiceComponentOfftake &&
      isEligibleDGO ) ||
      isTemporaryEan
    );
  };

  updateEditability = () => {
    this.setState({ isEditable: this.shouldAllowEdit() });
  };

  render() {
    if (!this.props.selectedCompany) {
      return <Loader inline="true">{this.strings.loading}</Loader>;
    }
    const { isEditable } = this.state;

    return (
      <div className="row">
        <div className="col-lg-7 p-xl-6 p-4">
          {!this.state.newSpdId ? (
            <form onSubmit={this.onAddNewSdp} noValidate>
              <h4 className="text-primary font-weight-light mb-4">
                <strong>{this.strings.technical}</strong>&nbsp;
                {this.state.selectedEan.direction === "supply" ? (
                  <span>{this.strings.supplyPoint}</span>
                ) : this.state.selectedEan.direction === "injection" ? (
                  <span>{this.strings.injectionPoint}</span>
                ) : this.state.selectedEan.direction === "production" ? (
                  <span>{this.strings.productionPoint}</span>
                ) : (
                  <span>{this.strings.sdpPoint}</span>
                )}
              </h4>

              <div className="form-group row">
                <div className="col-sm">
                  <div className="input-group">
                    <input
                      type="text"
                      name="ean"
                      id="eanInput"
                      ref={this.eanInput}
                      placeholder={
                        this.props.selectedCompany.role === "partner"
                          ? ""
                          : "Leave it empty if adding temporary EAN"
                      }
                      value={this.state.ean}
                      onChange={(e) => this.onFieldChange(e)}
                      onKeyDown={this.onInputEanSearch}
                      onBlur={this.sdpCheck}
                      className="form-control"
                      aria-label="EAN number"
                      maxLength="21"
                      minLength="21"
                      required={this.props.selectedCompany.role !== "admin"}
                      readOnly={this.state.selectedEan.tmpGsrn}
                    />
                    <div className="input-group-append">
                      {this.state.ean ||
                      this.props.selectedCompany.role !== "admin" ? (
                        <button
                          type="button"
                          onClick={this.onEanSearch}
                          disabled={this.state.isButtonDisabled}
                          className="btn btn-warning text-primary px-3 border-dark"
                        >
                          {this.strings.lookup}
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={this.state.isButtonDisabled}
                          onClick={this.tmpGsrn}
                          className="btn btn-secondary text-primary px-3 border-dark"
                        >
                          {this.strings.enterEan}
                        </button>
                      )}
                    </div>
                  </div>
                  {this.state.showError && (
                    <p style={{ color: "red" }} className="error">
                      {this.strings.invalidEANFormat}
                    </p>
                  )}
                </div>
              </div>

                <Loader className={'mb-3 ' + (this.state.isLoading ? 'd-block' : 'd-none')} inline="true">{this.strings.searching}</Loader>
                {this.state.options && !this.state.isLoading ? (
                  this.state.options.length ? (
                    <small>
                      <table className="table table-sm mb-3">
                        <thead>
                          <tr>
                            <th></th>
                            <th className="text-primary">{this.strings.ean}</th>
                            <th className="text-primary">{this.strings.dgo}</th>
                            <th className="text-primary">{this.strings.direction}</th>
                            <th className="text-primary">{this.strings.meterType}</th>
                            <th className="text-primary">{this.strings.physicalStatus}</th>
                            <th className="text-primary">{this.strings.serviceComponentConfiguration}</th>
                            <th className="text-primary">{this.strings.serviceComponentIdentification}</th>
                            <th className="text-primary"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.options.map((ean, index) => {
                            return (
                              <tr
                                key={index}
                                className={
                                  (ean.typeOfMeter &&
                                    (ean.typeOfMeter === 'MMR' || ean.typeOfMeter === 'YMR')) ||
                                  (ean.typeOfMeter === 'DMR' && ean.dgoName === 'Sibelga-IE') ||
                                  (ean.sdpCompany === this.props.selectedCompany.companyName &&
                                    ean.sdpAvailable === true)
                                    ? 'bg-light opacity-50'
                                    : ''
                                }
                              >
                                <td
                                  className="border-warning pt-2"
                                  onClick={
                                    (ean.typeOfMeter === 'MMR' || ean.typeOfMeter === 'YMR') ||
                                    (ean.typeOfMeter === 'DMR' && ean.dgoName === 'Sibelga-IE') ||
                                    (ean.sdpCompany === this.props.selectedCompany.companyName &&
                                      ean.sdpAvailable === true &&
                                      this.props.selectedCompany.role !== 'admin')
                                      ? null
                                      : () => this.onEanClicked(ean)
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id={'ean_' + ean.gsrn}
                                    name={ean.meterType}
                                    value={ean.gsrn}
                                    checked={
                                      this.state.selectedEans.map((x) => x.gsrn).includes(ean.gsrn) &&
                                      this.state.selectedEans.map((x) => x.meterType).includes(ean.meterType)
                                    }
                                    onChange={this.onEanSelected}
                                    style={{ marginTop: '2px' }}
                                    disabled={
                                      (ean.typeOfMeter &&
                                        (ean.typeOfMeter === 'MMR' || ean.typeOfMeter === 'YMR')) ||
                                      (ean.typeOfMeter === 'DMR' && ean.dgoName === 'Sibelga-IE') ||
                                      (ean.sdpCompany === this.props.selectedCompany.companyName &&
                                        ean.sdpAvailable === true)
                                    }
                                  />
                                </td>
                                <td className="border-warning pt-2" style={{ width: '33%' }}>
                                  <label
                                    className="m-0"
                                    htmlFor={'ean_' + ean.gsrn}
                                    onClick={
                                      (ean.typeOfMeter === 'MMR' || ean.typeOfMeter === 'YMR') ||
                                      (ean.typeOfMeter === 'DMR' && ean.dgoName === 'Sibelga-IE')
                                        ? null
                                        : () => this.onEanClicked(ean)
                                    }
                                  >
                                    <strong>{ean.gsrn}</strong>
                                    <br />
                                    {ean.apStreetName !== null ? ean.apStreetName : ean.appHouseNumber}{' '}
                                    {ean.appHouseNumber !== null && ean.apStreetName !== null
                                      ? ean.appHouseNumber
                                      : ''}
                                    ,
                                    <br />
                                    {ean.apZip !== null ? ean.apZip : ean.apCity}{' '}
                                    {ean.apCity !== null && ean.apZip !== null ? ean.apCity : ''},{' '}
                                    {ean.country !== null ? ean.country : ''}
                                  </label>
                                </td>
                                <td className="border-warning pt-2">
                                  <label className="m-0">{ean.dgo}</label>
                                </td>
                                <td className="border-warning pt-2">
                                  <label className="m-0">{this.getDirection(ean.meterType) || 'N/A'}</label>
                                </td>
                                <td className="border-warning pt-2">
                    <label className="m-0">{ean.typeOfMeter || 'N/A'}</label>
                  </td>
                  <td className="border-warning pt-2">
                    <label className="m-0">{ean.physicalStatusType || 'N/A'}</label>
                  </td>
                  <td className="border-warning pt-2">
                    <label className="m-0">
                      {ean.serviceComponentIdentification !== undefined
                        ? ean.serviceComponentIdentification
                        : 'N/A'}
                    </label>
                  </td>
                  <td className="border-warning pt-2">
                    <label className="m-0">
                      {ean.serviceComponentConfiguration !== undefined
                        ? ean.serviceComponentConfiguration
                        : 'N/A'}
                      {this.state.selectedEans.length && ean.selected === true ? (
                        <button
                          type="button"
                          className="icon-button"
                          onClick={
                            (ean.typeOfMeter === 'MMR' || ean.typeOfMeter === 'YMR') ||
                            (ean.typeOfMeter === 'DMR' && ean.dgoName === 'Sibelga-IE')
                              ? null
                              : () => {
                                  // e.preventDefault();
                                  this.setState({ error: null });
                                  this.formChange(ean);
                                }
                          }
                        >
                          <i className="fas fa-edit fa-lg text-primary"></i>
                        </button>
                      ) : (
                        <></>
                      )}
                    </label>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </small>
    ) : (
      <small>
        <table className="table table-sm table-borderless mb-3">
          <tbody>
            <tr>
              <td>{this.strings.noEansFound}</td>
            </tr>
          </tbody>
        </table>
      </small>
    )
  ) : null
}

              {this.state.options &&
              this.state.options.map((x) => x.sdpAvailable).includes(true) &&
              this.state.options[0].sdpCompany !==
                this.props.selectedCompany.companyName ? (
                this.state.sdpCheck && this.state.sdpCheck.list.length ? (
                  this.state.sdpCheck.list.map((x, idx) => {
                    return (
                      <p className="alert alert-info" key={idx}>
                        <i className="fas fa-info-circle mr-2" />
                        {x.info === "ADDED_SAMEKBO" ? (
                          <span>
                            {this.strings.addedMessage} {x.direction} {x.ean}{" "}
                            {this.strings.addedMessage2} {x.company}.{" "}
                            {this.strings.addedMessage1}
                          </span>
                        ) : x.info === "ADDED_DIFFKBO" ? (
                          <span>
                            {this.strings.added1}{" "}
                            {this.props.selectedCompany.role === "admin"
                              ? x.company
                              : this.strings.added3}{" "}
                            {this.strings.added4}{" "}
                            {x.withYuso ? null : this.strings.not}
                            {this.strings.added5}
                          </span>
                        ) : x.info === "INTERNAL_SWITCH" ? (
                          this.state.sdpCheck.status === "FORBIDDEN" ? (
                            <span>
                              {this.strings.added1} {x.direction}{" "}
                              {this.strings.added6} {this.strings.added3}{" "}
                              {this.strings.internalSwitch3}{" "}
                              {this.strings.internalSwitch5}{" "}
                              <a
                                className="text-decoration-none"
                                href="mailto:sales@yuso.be"
                              >
                                sales@yuso.be
                              </a>{" "}
                              {this.strings.internalSwitch6}
                            </span>
                          ) : (
                            <span>
                              {this.strings.addedMessage} {x.direction} {x.ean}{" "}
                              {this.strings.addedMessage2} {x.company}.{" "}
                              {this.strings.addedMessage1}
                            </span>
                          )
                        ) : null}
                      </p>
                    );
                  })
                ) : this.state.sdpCheck &&
                  this.state.sdpCheck.status === "FORBIDDEN" &&
                  this.state.sdpCheck.dmr ? (
                  <p className="alert alert-info">
                    <i className="fas fa-info-circle mr-2" />
                    {this.strings.addedDirection}
                  </p>
                ) : null
              ) : null}
              {this.state.directionWarningSelect === true ? (
                <p className="alert alert-warning">
                  <i className="fas fa-exclamation-triangle mr-2"></i>
                  {this.strings.directionWarningSelect}
                </p>
              ) : null}
              {this.state.sdpCheck &&
              this.state.sdpCheck.status !== "OK" ? null : (
                <div>
                  <div
                    className={`form-group row required${
                      this.state.expandedForm ? "" : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">EAN</label>
                    <div className="col-sm">
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        value={this.state.selectedEan.gsrn}
                        onChange={this.onFieldChange}
                        name="ean"
                        required
                      />
                    </div>
                  </div>

                  <div
                    className={`form-group row required required-select${
                      this.state.expandedForm ||
                      this.state.missingData.typeOfMeter ||
                      this.state.noEan === true
                        ? ""
                        : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">
                      {this.strings.meterType}
                    </label>
                    <div className="col-sm">
                      <select
                        className="form-control"
                        name="typeOfMeter"
                        value={this.state.selectedEan.typeOfMeter || ""}
                        onChange={this.onFieldChange}
                        required
                      >
                        <option value="" disabled></option>
                        <option value="AMR">AMR</option>
                        <option value="DMR">DMR</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className={`form-group row required required-select${
                      this.state.expandedForm ||
                      this.state.missingData.direction
                        ? ""
                        : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">
                      {this.strings.direction}
                    </label>
                    <div className="col-sm">
                      <select
                        className="form-control"
                        name="type"
                        value={this.state.selectedEan.direction || ""}
                        onChange={this.onFieldChange}
                        required
                        disabled={!isEditable}
                      >
                        {this.state.selectedEan.gsrn ? (this.state.selectedEan.gsrn.startsWith("EAN87") ||
                        this.state.selectedEan.country === "The Netherlands") ? (
                          <option value="injection+supply">
                            {this.strings.injection} &amp; {this.strings.supply}
                          </option>
                        ) : (
                          [
                            <option key={1} value="" disabled></option>,
                            <option key={2} value="injection+supply">
                              {this.strings.injection} &amp;{" "}
                              {this.strings.supply}
                            </option>,
                            <option key={3} value="injection">
                              {this.strings.injection}
                            </option>,
                            <option key={4} value="supply">
                              {this.strings.supply}
                            </option>,
                            <option key={5} value="production">
                              {this.strings.production}
                            </option>,
                          ]
                        ) : null }
                      </select>
                      {!isEditable &&
                      this.state.selectedEan.gsrn && !this.state.selectedEan.gsrn.startsWith("EAN87") ? (
                        <small className="form-text text-danger">
                          <strong>{this.strings.note}</strong>{" "}
                          {this.strings.sdpTypeWarning}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  {this.state.selectedEan.direction === "production" ? (
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        {this.strings.injectionLink}
                      </label>
                      <div className="col-sm-9 special-select">
                        {this.state.injectionSdps === undefined ? (
                          <label className="col-form-label">
                            <Loader inline="true">
                              {this.strings.loading}
                            </Loader>
                          </label>
                        ) : this.state.injectionSdps.length === 0 ? (
                          <label className="col-form-label">
                            {this.strings.noMatchFound}
                          </label>
                        ) : (
                          <Typeahead
                            id="sdpsSelector"
                            options={this.state.injectionSdps}
                            labelKey={(x) => `${x.ean} (${x.installation_id})`}
                            renderMenu={(results, menuProps) => (
                              <Menu {...menuProps}>
                                {results.map((x) =>
                                  !x.all ? (
                                    <MenuItem
                                      option={x}
                                      key={x.installation_id}
                                    >
                                      {x.ean} ({x.installation_id})
                                    </MenuItem>
                                  ) : x.divider ? (
                                    <Menu.Divider key={0} />
                                  ) : (
                                    <MenuItem option={x} key={x.label}>
                                      {this.strings[x.label]}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                            )}
                            onChange={(selected) =>
                              this.setState((prevState) => ({
                                selectedEan: {
                                  ...prevState.selectedEan,
                                  injectionEan: selected?.[0]?.ean ?? null,
                                },
                              }))
                            }
                            clearButton
                          />
                        )}
                      </div>
                    </div>
                  ) : null}

                  {this.state.selectedEan.direction !== 'supply' && (
                    <div className={`${this.state.expandedForm || this.state.missingData.technology ? '' : 'd-none'}`}>
                      <div className="form-group row required">
                        <label className="col-sm-3 col-form-label">{this.strings.technology}</label>
                        <div className="col-sm-9 mt-2">
                          {["Solar", "Wind", "Thermal", "Hydro-electric head installations", "Other"].map((tech) => (
                            <div className="form-check form-check-inline" key={tech}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={tech}
                                checked={this.state.selectedEan.technologies ? this.state.selectedEan.technologies.includes(tech) : ''}
                                onChange={this.handleTechnologyChange}
                                required
                              />
                              <label className="form-check-label">{tech}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                      <div className={`form-group row required${this.state.expandedForm || this.state.missingData.apStreetName ? '' : ' d-none'}`}>
                        <label className="col-sm-3 col-form-label">{this.strings.streetAddress}</label>
                        <div className="col-sm">
                          <input className="form-control" type="text" value={this.state.selectedEan.apStreetName} onChange={this.onFieldChange} name="address" required />
                        </div>
                      </div>

                  <div
                    className={`form-group row required${
                      this.state.expandedForm ||
                      this.state.missingData.appHouseNumber
                        ? ""
                        : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">
                      {this.strings.streetNumber}
                    </label>

                    <div className="col-sm">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.selectedEan.appHouseNumber}
                        onChange={this.onFieldChange}
                        name="number"
                        required
                      />
                    </div>
                  </div>

                  <div
                    className={`form-group row required${
                      this.state.expandedForm || this.state.missingData.apZip
                        ? ""
                        : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">
                      {this.strings.zipCode}
                    </label>
                    <div className="col-sm">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.selectedEan.apZip}
                        onChange={this.onFieldChange}
                        name="zip"
                        required
                      />
                    </div>
                  </div>

                  <div
                    className={`form-group row required${
                      this.state.expandedForm || this.state.missingData.apCity
                        ? ""
                        : " d-none"
                    }`}
                  >
                    <label className="col-sm-3 col-form-label">
                      {this.strings.city}
                    </label>
                    <div className="col-sm">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.selectedEan.apCity}
                        onChange={this.onFieldChange}
                        name="city"
                        required
                      />
                    </div>
                  </div>

                      <div className={`form-group row required required-select${this.state.expandedForm || this.state.missingData.country ? '' : ' d-none'}`}>
                        <label className="col-sm-3 col-form-label">{this.strings.country}</label>
                        <div className="col-sm">
                          <select className="form-control" name="country" value={this.state.selectedEan.country || ''} onChange={this.onFieldChange} required>
                            <option value="" disabled></option>
                            <option value="Belgium">{this.strings.belgium}</option>
                            <option value="The Netherlands">{this.strings.theNetherlands}</option>
                          </select>
                        </div>
                      </div>
                      <div className={`form-group row required required-select${this.state.expandedForm || this.state.missingData.dgoName ? '' : ' d-none'}`}>
                        <label className="col-sm-3 col-form-label">DGO</label>
                        <div className="col-sm">
                          <select className="form-control" name="dgo" value={this.state.selectedEan.dgoName || ''} onChange={this.onFieldChange} required>
                            <option value="" disabled></option>
                            {
                              this.state.dgos.map(dgo => {
                                return (
                                  <option key={dgo} value={dgo}>{dgo}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                      {["supply", "injection+supply"].includes(this.state.selectedEan.direction) ? (
                          <div className={`form-group row required required-select ${this.state.expandedForm || (this.state.selectedEan.direction === "supply" && this.state.missingData.supplyVolume) ? '' : ' d-none'}`}>
                            <label className="col-sm-7 col-form-label">
                               {this.strings.supplyVolume}
                            </label>
                            <div className="col-sm">
                              <div className="input-group">
                                <NumberInput
                                  className="form-control required"
                                  type="number"
                                  name='supplyVolume'
                                  value={this.state.selectedEan.supplyVolume}

                                  min="0"
                                  onKeyPress={this.numberField}
                                  onChange={this.onFieldChange}
                                  required
                                />
                                <div className="input-group-append">
                                  <select className="form-control bg-warning select-unit" name="volumeUnit" value={this.state.selectedEan.volumeUnit} onChange={this.onFieldChange}>
                                    <option value="Wh">Wh</option>
                                    <option value="kWh">kWh</option>
                                    <option value="MWh">MWh</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null} 

                      {["injection", "injection+supply"].includes(this.state.selectedEan.direction) ? (
                          <div className={`form-group row required required-select ${this.state.expandedForm || (this.state.selectedEan.direction === "injection" && this.state.missingData.injectionVolume) ? '' : ' d-none'}`}>
                            <label className="col-sm-7 col-form-label">
                               {this.strings.injectionVolume}
                            </label>
                            <div className="col-sm">
                              <div className="input-group">
                                <NumberInput
                                  className="form-control required"
                                  type="number"
                                  name='injectionVolume'
                                  value={this.state.selectedEan.injectionVolume}

                                  min="0"
                                  onKeyPress={this.numberField}
                                  onChange={this.onFieldChange}
                                  required
                                />
                                <div className="input-group-append">
                                  <select className="form-control bg-warning select-unit" name="volumeUnit" value={this.state.selectedEan.volumeUnit} onChange={this.onFieldChange}>
                                    <option value="Wh">Wh</option>
                                    <option value="kWh">kWh</option>
                                    <option value="MWh">MWh</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null} 

                        {(this.state.selectedEan.direction === "injection+supply" || this.state.selectedEan.direction === "supply" || this.state.selectedEan.direction === "production")  ? (
                          <div className={`form-group row ${this.state.expandedForm || this.state.missingData.power ? '' : ' d-none'}`}>
                            <label className="col-sm-7 col-form-label">
                            {this.state.selectedEan.direction === 'supply' || this.state.selectedEan.direction === 'injection+supply'
                                ? `${this.strings.offtakePower}`
                                : `${this.strings.power}`}
                              {/* {this.strings.forWord} {this.state.selectedEan.gsrn} */}
                            </label>
                            <div className="col-sm">
                              <div className="input-group">
                                <NumberInput
                                  className="form-control"
                                  type="number"
                                  name="power"
                                  value={this.state.selectedEan.power}
                                  min="0"
                                  onKeyPress={this.numberField}
                                  onChange={this.onFieldChange}
                                  required
                                />
                                <div className="input-group-append">
                                  <select className="form-control bg-warning select-unit" name="powerUnit" defaultValue="kW" onChange={this.onFieldChange}>
                                    <option value="W">W</option>
                                    <option value="kW">kW</option>
                                    <option value="MW">MW</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>  
                        ) : null}

                      {this.state.selectedEan.direction !== 'supply' &&
                          (this.state.selectedEan.technologies ? 
                              [...new Set(this.state.selectedEan.technologies.split(','))].map((tech) => {
                                  const lowerCaseTech = tech.toLowerCase();
                                  return (
                                      <div key={tech} className={`form-group row ${this.state.expandedForm || this.state.missingData.injectionPowers ? '' : 'd-none'}`}>
                                          <label className="col-sm-7 col-form-label">
                                              {tech} {this.strings.power} 
                                          </label>
                                          <div className="col-sm">
                                              <div className="input-group">
                                                  <NumberInput
                                                      className="form-control"
                                                      type="number"
                                                      name={`${tech}Power`}
                                                      value={this.state.selectedEan.injectionPowers[tech] || ''}
                                                      min="0"
                                                      onKeyPress={this.numberField}
                                                      onChange={(e) => this.handlePowerChange(tech, e)}
                                                      required={this.state.selectedEan.technologies.includes(tech)}
                                                  />
                                                  <div className="input-group-append">
                                                      <select className="form-control bg-warning select-unit" name={`powerUnit-${lowerCaseTech}`} defaultValue="kWp" onChange={(e) => this.handlePowerUnitChange(tech, e)}>
                                                          <option value="W">Wp</option>
                                                          <option value="kWp">kWp</option>
                                                          <option value="MWp">MWp</option>
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              }) : null)
                      }
                      <div className="modal fade" id="consent-amr" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">{this.strings.consentTitle}</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <small><span aria-hidden="true"><i className="fas fa-times"></i></span></small>
                              </button>
                            </div>
                            <div className="modal-body">
                              <p>
                                {this.strings.consent}
                              </p>
                              <span className="text-primary text-underline cursor-pointer d-inline-block mt-2" onClick={this.openInfo}>
                                <i className="fas fa-info-circle mr-2"></i>{this.strings.moreInfo}
                              </span>
                              <div className={this.state.moreInfo ? 'my-3' : 'd-none'}>
                                {this.strings.moreInfoDesc}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.onConfirm}>{this.strings.confirm}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        this.state.errorAMR ?
                          <p className="alert alert-danger">
                            <i className="fas fa-minus-circle mr-2"></i>{this.strings.errorAMR} <a className="text-decoration-none" href="mailto:sales@yuso.be">sales@yuso.be</a>.
                          </p>
                          : null
                      }
                      <div>
                        <p className={'alert alert-warning ' + (this.state.error ? 'd-block' : 'd-none')}>
                          <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                        </p>
                        <p className={'alert alert-info ' + (this.state.newSpdId ? 'd-block' : 'd-none')}>
                          <i className="fas fa-info-circle mr-2"></i> {this.strings.addedSdp} <strong>{this.state.newSpdId}</strong>
                        </p>
                      </div>
                      <div>
                      {
                        (this.state.selectedEans.length &&
                          this.state.selectedEans.filter((x) => x.selected === true).length) ||
                        (this.state.selectedEan.gsrn ? this.state.selectedEan.gsrn.substring(0, 6).toString() === 'EANTMP' : '') ||
                        this.state.noEan === true ? (
                          <div className="d-flex">
                            <button
                              type="reset"
                              className="btn btn-secondary text-capitalize px-4 py-3"
                              onClick={this.resetForm}
                            >
                              <strong>{this.strings.clear}</strong> {this.strings.form}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary text-capitalize px-4 py-3 ml-auto"
                              ref={this.addSdpButton}
                              style={{ width: '240px' }}
                            >
                              {this.state.addingSpd ? <i className="fas fa-spinner fa-spin mr-2"></i> : null}
                              {this.state.addingSpd ? this.strings.adding : <span><strong>{this.strings.addBtn}</strong></span>}
                            </button>
                          </div>
                        ) : null
                      }
                  </div>
                  {this.state.selectedEans.length &&
                  this.state.selectedEans.filter(
                    (x) => x.direction !== "production"
                  ) ? (
                    <div className="form-group row">
                      <div className="col-sm text-right mt-3">
                        <small className="form-text text-danger">
                          {" "}
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          <strong>{this.strings.disclaimer}</strong>{" "}
                          {this.strings.disclaimerInfo}
                        </small>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="modal fade"
                    id="warning-metertype"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            <i className="fas fa-exclamation-triangle text-danger mr-2"></i>{" "}
                            {this.strings.unsupportedMeter}
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>
                            {this.strings.unsupportedMeter1}{" "}
                            <a
                              className="text-decoration-none"
                              href="mailto:sales@yuso.be"
                            >
                              sales@yuso.be
                            </a>
                            .
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            {this.strings.close}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal fade"
                    id="warning-direction"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            <i className="fas fa-exclamation-triangle text-danger mr-2"></i>{" "}
                            {this.strings.wrongDirection}
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>
                            {this.strings.wrongDirection1}{" "}
                            <strong>
                              {this.getOposite(
                                this.state.selectedEan.direction
                              )}
                            </strong>
                            , {this.strings.wrongDirection2}{" "}
                            <strong>
                              {this.getTypeLocal(
                                this.state.selectedEan.direction
                              )}
                            </strong>
                            . {this.strings.wrongDirection3}{" "}
                            <strong>
                              {this.getOposite(
                                this.state.selectedEan.direction
                              )}
                            </strong>{" "}
                            {this.strings.wrongDirection4}.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            {this.strings.close}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.options &&
              this.state.options.map((x) => x.sdpAvailable).includes(true) &&
              this.state.options[0].sdpCompany ===
                this.props.selectedCompany.companyName ? (
                this.props.selectedCompany.role === "admin" ? (
                  this.state.options
                    .filter((x) => x.sdpAvailable === true)
                    .map((x, idx) => {
                      return (
                        <p key={idx}>
                          <i className="fas fa-exclamation-triangle mr-2"></i>
                          {x.gsrn}-{x.meterType} {this.strings.isAlready}
                        </p>
                      );
                    })
                ) : this.state.options.filter((x) => x.sdpAvailable === true)
                    .length === 1 ? (
                  <p className="alert alert-warning">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    {this.strings.disabledEan}
                  </p>
                ) : (
                  <p className="alert alert-warning">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    {this.strings.disabledEans}
                  </p>
                )
              ) : null}
              {this.state.isAvailable &&
              this.state.isAvailable.available === false &&
              !this.state.isAvailable.recognized ? (
                <p className="alert alert-warning">
                  <i className="fas fa-exclamation-triangle mr-2"></i>
                  {this.strings.portalAvailable}
                </p>
              ) : this.state.isAvailable &&
                this.state.isAvailable.error === true ? (
                <p className="form-text text-danger">
                  <i className="fas fa-info-circle mr-2" />
                  {this.state.isAvailable.message}
                </p>
              ) : this.state.isAvailable &&
                this.state.isAvailable.available === undefined ? (
                <p className="alert alert-info">
                  <i className="fas fa-info-circle mr-2" />
                  {this.strings.successfullPreswtich}
                </p>
              ) : null}
            </form>
          ) : (
            [
              <div class="cta-box d-flex flex-column bg-warning p-4 home-cta mb-3">
                <h4 class="text-white mb-3">{this.strings.sdpAdded}</h4>
                <div class="mb-3 h-100 ">{this.strings.sdpAddedDesc}</div>
                <div
                  class="btn bg-white text-primary px-4 py-3 w-100"
                  onClick={this.resetForm}
                >
                  <strong>{this.strings.add}</strong> {this.strings.newPoint}
                </div>
              </div>,
              <div class="cta-box d-flex flex-column bg-warning p-4 home-cta">
                <h4 class="text-white mb-3">
                  {this.strings.requestSimulation}
                </h4>
                <span>
                  <ol>
                    <li>{this.strings.requestSimulationDesc3}</li>
                    <li>{this.strings.requestSimulationDesc4}</li>
                  </ol>
                </span>
                <div
                  class="btn bg-white text-primary px-4 py-3 w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.activateStage(undefined, "mandate");
                  }}
                >
                  {this.strings.giveMandate1}{" "}
                  <strong>{this.strings.giveMandate2}</strong>
                </div>
              </div>,
            ]
          )}
        </div>
        <div className="col-lg-5 p-xl-6 p-4">
          {this.state.type ? (
            <div className="mb-3">
              {this.state.type === "supply" ? (
                <span>{this.strings.supplyDesc}</span>
              ) : this.state.type === "injection" ? (
                <span>{this.strings.injectionDesc}</span>
              ) : this.state.type === "production" ? (
                <span>{this.strings.productionDesc}</span>
              ) : (
                <span>{this.strings.selectDesc}</span>
              )}
            </div>
          ) : null}
          {this.state.expandedForm || this.state.selectedEan.filled ? (
            <img
              src={`${process.env.PUBLIC_URL}/yuso-banner.jpg`}
              alt="Yuso Banner"
              className="p-4 w-100"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default NewSdpForm;
