import $ from "jquery";
import "bootstrap/js/dist/modal";

import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";

import DropdownPanel from "../../components/DropdownPanel";
import Loader from "../../components/Loader";
import LocalizedStrings from "../../components/Localization";

import { addVregIds } from "../../api/sdps";

moment.tz.setDefault("Europe/Brussels");

class Injection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      vreg_id: "",
      isArchived: false
    };

    this.toggleDetails = this.toggleDetails.bind(this);
    this.saveVregIds = this.saveVregIds.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);

    this.strings = LocalizedStrings(
      {
        en: {
          errorReqired: "The VREG ID field is required.",
          fixedFee: "Fixed fee",
          partnerFee: "Partner fee",
          imbalanceIndex: "Solar Imbalance index",
          windImbalanceIndex: "Wind Onshore Imbalance index",
          errorWrongFormat:
            "The VREG ID must start with 'PVZG', 'PVZ', 'G' or 'WKK'. Example: PVZG12345, G12345678",
          errorSave: "Error occurred while saving. Please try again.",
          fetching: "Fetching injection points...",
          notConnected:
            "You have not yet connected any injection points to Yuso.",
          getStarted: "To get started, please",
          addSDP: "add new injection point",
          technical: "Technical data",
          point: "of the injection point",
          thisPointError: "This point is ",
          inPortfolio: "In Portfolio",
          notInPortfolio: "Not in Portfolio",
          more: "More",
          less: "Less",
          details: "details",
          ean: "EAN",
          serviceComponentError:
            "The correct service component is not yet available for this SDP. Contact [DNB] to solve this.",
          meterType: "Meter type",
          dataAvailableFrom: "Data available from",
          technology: "Technology",
          operator: "Distribution operator",
          tariff: "Tariff type",
          voltage: "Voltage level",
          vreg: "VREG ID",
          preswitchDate: "Latest preswitch",
          vregDesc:
            "This identifier starts with 'PVZG', 'PVZ', 'G' or 'WKK'. This number is assigned to your installation by the VREG. Yuso needs this identifier in order to correctly process the GVO transactions.",
          vregDesc2: "Click for help how to find the VREG ID",
          address: "Address",
          mandate: "Meterdata permissions",
          validMandate: "Valid mandate",
          endedMandate: "Ended mandate",
          requestSent: "Mandate request sent to customer",
          noMaterDataPermissions: "No meter data permissions",
          contract: "Contract parameters",
          formula: "Formula",
          epexHourly: "EPEX Spot BE DA hourly prices",
          epexMonthly: "EPEX Spot BE DA monthly average",
          fee: "Platform fee",
          go: "GO price",
          start: "Start contract",
          end: "End contract",
          endDateNotIncluded: "The contract ends on",
          endDateNotIncluded2: ", not including that date.",
          month: "Extended month-to-month",
          pnu: "Percentage of normal hours",
          psu: "Percentage of quiet hours",
          volume: "Annual volume",
          capacity: "Power capacity",
          installation: "Installation ID",
          noContract: "No contract for this SDP yet.",
          noContract2: "You can create a contract for this SDP here.",
          noSignedContract1: "No active contract yet.",
          noSignedContract2: "You can view your contract here.",
          endedContract1: "You contract ended on",
          endedContract2: "You can renew it here.",
          physicalStatus: "Physical Status",
          lockStatus: "Lock Status",
          howTo1: "How to find your VREG ID?",
          howTo2:
            "The Vreg ID can be found by looking at the certificate data on the",
          howTo3: "VREG certificate platform",
          howTo4: "Go to Portefeuille → Overzicht Portefeuille",
          howTo5: "Click the search button",
          howTo6:
            "Your installation IDs should be displayed in the last column",
          howTo7:
            "If you don't have login credentials of the VREG certificates platform you can follow the instructions in the",
          howTo8: "VREG platform manual",
          howTo9: "or you can",
          howTo10: "contact them",
          howTo11: "NOTE:",
          howTo12:
            "To enter multiple VREG IDs, separate them with comma. Example: PVZG12345, PVZ123456",
          close: "Close",
          moduleMig6: "Running process - Module",
          labelMig6: "Running process - Label",
          startDateMig6: "Running process - Start Date",
          supplierMig6: "Running process - Supplier",
          switchInfoYuso: "My Yuso switch information",
          switchInfoMig6: "MIG6 switch information",
          switchState: "SDP state:",
          withYuso: "SDP is currently with Yuso",
          notWithYuso: "SDP is currently not with Yuso",
          switchDate: "Switch date",
          eocDate: "EOC date",
          switchStatus: "Switch Status",
          switchStatusReason: "Switch Status Reason",
          eocStatus: "EOC Status",
          eocStatusReason: "EOC Status Reason",
          internallySwitched: "Internally switched",
          serviceComponent: "Service Component",
          serviceConfiguration: "Service Configuration",
          underConstruction: "Under Construction",
          locked: "Locked",
          disconnected: "Disconnected",
          connected: "Connected",
          unlocked: "Unlocked",
          switchApproved: "Approved",
          switchRejected: "Rejected",
          notSwtiched: "Not switched",
          product: "Product",
          status: "Status",
          contractId: "Contract id"
        },
        nl: {
          errorReqired: "Het veld VREG ID is vereist.",
          serviceComponent: "Service Component",
          serviceConfiguration: "Service Configuratie",
          fixedFee: "Vaste vergoeding",
          imbalanceIndex: "Zonne Onbalans index",
          windImbalanceIndex: "Wind Onshore Onbalans index",
          partnerFee: "Partner commissie",
          errorWrongFormat:
            "De VREG ID moeten beginnen met 'PVZG', 'PVZ', 'G' of 'WKK'. Voorbeeld: PVZG12345, G12345678",
          errorSave:
            "Er is een fout opgetreden tijdens het opslaan. Probeer het opnieuw.",
          fetching: "Injectiepunten ophalen...",
          notConnected: "U hebt nog geen injectiepunten aangesloten bij Yuso.",
          getStarted: "Om te beginnen",
          addInjection: "voeg nieuw injectiepunt toe",
          technical: "Technische data",
          point: "van het injectiepunt",
          preswitchDate: "Laatste preswitch",
          thisPointError: "This point is",
          inPortfolio: "In Portfolio",
          notInPortfolio: "Not in Portfolio",
          more: "Meer",
          less: "Minder",
          details: "gegevens",
          ean: "EAN",
          serviceComponentError:
            "The correct service component is not yet available for this SDP. Contact [DNB] to solve this.",
          meterType: "Metertype",
          dataAvailableFrom: "Gegevens beschikbaar vanaf",
          technology: "Technologie",
          operator: "Distributienetbeheerder",
          tariff: "Tarieftype",
          voltage: "Spanningsniveau",
          vreg: "VREG ID",
          vregDesc:
            "Deze identificatie begint met 'PVZG', 'PVZ', 'G' of 'WKK'. Dit nummer wordt door de VREG aan jouw installatie toegewezen. Yuso heeft deze ID nodig om de GVO-transacties correct te verwerken.",
          vregDesc2: "Klik voor hulp om de VREG ID te vinden",
          address: "Adres",
          mandate: "Meterdata-machtigingen",
          validMandate: "Geldig mandaat",
          endedMandate: "Mandaat beëindigd",
          requestSent: "Mandaatverzoek verzonden naar klant",
          noMaterDataPermissions: "Geen machtigingen voor metergegevens",
          contract: "Contract parameters",
          formula: "Formule",
          epexHourly: "EPEX Spot BE DA uurtarieven",
          epexMonthly: "EPEX Spot BE DA maandelijks gemiddelde",
          fee: "Platformkosten",
          go: "GO prijs",
          start: "Start contract",
          end: "Einde contract",
          endDateNotIncluded: "Het contract eindigt op",
          endDateNotIncluded2: ", exclusief die datum.",
          month: "Automatisch maandelijkse verlengd",
          pnu: "Percentage van de normale uren",
          psu: "Percentage rustige uren",
          volume: "Jaarvolume",
          capacity: "Vermogen capaciteit",
          installation: "Installatie ID",
          noContract: "Nog geen contract voor deze SDP.",
          noContract2: "U kunt hier een contract voor deze SDP aanmaken.",
          noSignedContract1: "Nog geen actief contract.",
          noSignedContract2: "U kunt uw contract hier bekijken.",
          endedContract1: "Uw contract is geëindigd op",
          endedContract2: "Je kunt het hier vernieuwen.",
          physicalStatus: "Fysieke toestand",
          lockStatus: "Slotstatus",
          howTo1: "Waar vind ik mijn VREG ID?",
          howTo2:
            "Het Vreg ID van de productie installatie kan worden gevonden op het",
          howTo3: "VREG certificaatplatform",
          howTo4: "Ga naar Portefeuille → Overzicht Portefeuille",
          howTo5: "Klik op de zoekknop",
          howTo6: "Jouw installatie IDs worden in de laatste kolom weergegeven",
          howTo7:
            "Als u geen login voor het VREG certificaatplatform hebt, kan u de",
          howTo8: "VREG handleiding",
          howTo9: "volgen of",
          howTo10: "contacteer hen",
          howTo11: "NOOT:",
          howTo12:
            "Als u meerdere VREG IDs wilt invoeren, scheidt u deze met een komma. Voorbeeld: PVZG12345, PVZ123456",
          close: "Sluiten",
          moduleMig6: "Lopende processen - Module",
          labelMig6: "Lopende processen - Label",
          startDateMig6: "Lopende processen - Startdatum",
          supplierMig6: "Lopende processen - Leverancier",
          switchInfoYuso: "My Yuso schakel informatie",
          switchInfoMig6: "MIG6 schakel informatie",
          switchState: "SDP staat:",
          withYuso: "SDP is momenteel bij Yuso",
          notWithYuso: "SDP is momenteel niet bij Yuso",
          switchDate: "Switch datum",
          eocDate: "EOC datum",
          switchStatus: "Switch Status",
          switchStatusReason: "Switch Status Reden",
          eocStatus: "EOC Status",
          eocStatusReason: "EOC Status Reden",
          internallySwitched: "Intern geschakeld",
          underConstruction: "Under Construction",
          locked: "Locked",
          disconnected: "Disconnected",
          connected: "Connected",
          unlocked: "Unlocked",
          switchApproved: "Goedgekeurd",
          switchRejected: "Afgewezen",
          notSwtiched: "Niet geswitched",
          product: "Product",
          status: "Status",
          contractId: "Contract id"
        },
      },
      this.props.lang
    );
  }

  toggleDetails() {
    this.setState(
      {
        showDetails: !this.state.showDetails,
      },
      () => {
        $('[data-toggle="tooltip"]').tooltip();
      }
    );
  }

  saveVregIds(ean) {
    if (!this.props.selectedCompany) return;

    this.setState({
      error: undefined,
    });

    let vreg_id = this.state.vreg_id.trim().toUpperCase();
    if (!vreg_id) {
      this.setState({
        error: this.strings.errorReqired,
      });
      return;
    }

    const vreg_list = vreg_id.split(",").map((x) => x.trim());

    const check = vreg_list.find((x) => {
      return (
        x.startsWith("PVZG") ||
        x.startsWith("PVZ") ||
        x.startsWith("G") ||
        x.startsWith("WKK")
      );
    });

    if (!check) {
      this.setState({
        error: this.strings.errorWrongFormat,
      });
      return;
    }

    addVregIds(
      this.props.selectedCompany.company_id,
      ean,
      vreg_id,
      (err, res) => {
        if (err) return window.reportError.send(err);

        let sdp = this.props.sdps.sdps_injection_hourly.find(
          (x) => x.ean === ean
        );
        if (sdp) {
          sdp.vregProducer = vreg_id;
          this.forceUpdate();
        }
      }
    );
  }

  onFieldChange(e) {
    this.setState({
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  render() {
    if (!this.props.sdps) {
      return (
        <div className="mb-3">
          <Loader inline="true">{this.strings.fetching}</Loader>
        </div>
      );
    }

    if (this.props.sdps.sdps_injection_hourly.length === 0) {
      return (
        <div className="list-group mb-3">
          <p>{this.strings.notConnected}</p>
          <p>
            {this.strings.getStarted}{" "}
            <Link to="/services/onboarding#sdp">{this.strings.addSDP}</Link>.
          </p>
        </div>
      );
    }

    return (
      <div className="list-group">
        <div
          className="modal fade"
          id="vreg-help"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.strings.howTo1}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <p>
                    {this.strings.howTo2}{" "}
                    <strong>
                      <a
                        className="text-primary"
                        href="https://certificaatbeheer.vlaanderen.be/VREG.HandelsDatabank.Web "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.strings.howTo3}
                      </a>
                    </strong>
                    .
                  </p>
                  <ul>
                    <li>{this.strings.howTo4}</li>
                    <li>{this.strings.howTo5}</li>
                    <li>{this.strings.howTo6}</li>
                  </ul>
                  <p>
                    {this.strings.howTo7}{" "}
                    <strong>
                      <a
                        className="text-primary"
                        href="https://www.vreg.be/sites/default/files/Platform-VREG/handleiding_vreg-platform_15032019.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.strings.howTo8}
                      </a>
                    </strong>{" "}
                    {this.strings.howTo9}{" "}
                    <strong>
                      <a
                        className="text-primary"
                        href="https://www.vlaanderen.be/nl/aanmelden/help/mail.html?Bronpagina=aanmelden&Applicatie=certificaatbeheer&Authenticatiemiddel=none&Voortgang=Authenticatie%20pagina"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.strings.howTo10}
                      </a>
                    </strong>
                    .
                  </p>
                  <p>
                    <strong>{this.strings.howTo11}</strong>{" "}
                    {this.strings.howTo12}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {this.strings.close}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.sdps.sdps_injection_hourly.map((sdp) => {
          return (
            sdp.archived && !this.props.isArchived ? null :
              <DropdownPanel
                key={sdp.ean}
                sdp={sdp}
                isCollapsed="true"
                ean={sdp.ean}
                installation_id={sdp.installation_id}
                type={sdp.type}
                address={`${sdp.addressStreet} ${sdp.addressNumber}, ${sdp.addressZip} ${sdp.addressCity}, ${sdp.addressCountry} `}
                setDeleteSdp={this.props.setDeleteSdp}
                setArchiveSdp={this.props.setArchiveSdp}
                selectedCompany={this.props.selectedCompany}
                lang={this.props.lang}
                archived={sdp.archived}
                isArchived={this.state.isArchived}
              >
                {sdp.service_component ? null : (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6 className="text-danger font-weight-light m-0" color="red">
                      <strong>{this.strings.serviceComponentError}</strong>
                    </h6>
                  </div>
                )}
                {(sdp.physicalStatus === "E23" ||
                  sdp.physicalStatus === "E30" ||
                  sdp.physicalStatus === "Under construction" ||
                  sdp.physicalStatus === "Disconnected") &&
                  sdp.lock_status === "Locked" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {sdp.physicalStatus === "E23" ||
                          sdp.physicalStatus === "Disconnected" ? (
                          <span className="text-danger">
                            {this.strings.thisPointError}{" "}
                            {this.strings.disconnected}
                          </span>
                        ) : sdp.physicalStatus === "E30" ||
                          sdp.physicalStatus === "Under construction" ? (
                          <span className="text-danger">
                            {this.strings.thisPointError}{" "}
                            {this.strings.underConstruction}
                          </span>
                        ) : null}
                        /
                        {sdp.lock_status === "Locked" ? (
                          <span className="text-danger">
                            {this.strings.locked}
                          </span>
                        ) : null}
                      </strong>
                    </h6>
                  </div>
                ) : sdp.physicalStatus === "E23" ||
                  sdp.physicalStatus === "Disconnected" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {this.strings.thisPointError} {this.strings.disconnected}
                      </strong>
                    </h6>
                  </div>
                ) : sdp.physicalStatus === "E30" ||
                  sdp.physicalStatus === "Under construction" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {this.strings.thisPointError}{" "}
                        {this.strings.underConstruction}
                      </strong>
                    </h6>
                  </div>
                ) : sdp.lock_status === "Locked" ? (
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <h6>
                      <strong className="text-danger">
                        {this.strings.thisPointError} {this.strings.locked}
                      </strong>
                    </h6>
                  </div>
                ) : null}
                <div className="d-flex align-items-center mt-4 mb-3">
                  <h4 className="text-primary font-weight-light m-0">
                    <strong>{this.strings.technical}</strong> {this.strings.point}
                  </h4>
                  <span
                    className="link text-secondary cursor-pointer text-underline ml-auto"
                    onClick={this.toggleDetails}
                  >
                    {!this.state.showDetails
                      ? this.strings.more
                      : this.strings.less}{" "}
                    {this.strings.details}
                  </span>
                </div>
                <table className="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>{this.strings.ean}</td>
                      <td>{sdp.ean}</td>
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.installation}</td>
                          <td>{sdp.installation_id}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.meterType}</td>
                          <td>{sdp.meterType}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.dataAvailableFrom}</td>
                          <td>{sdp.first_mv_dtlt}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.technology}</td>
                          <td>{sdp.technology}</td>
                        </tr>,
                      ]
                      : null}
                    <tr>
                      <td>{this.strings.operator}</td>
                      <td>{sdp.dgo}</td>
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.tariff}</td>
                          <td>{sdp.dgo_tariff_id}</td>
                        </tr>,
                        <tr>
                          <td>{this.strings.voltage}</td>
                          <td>{sdp.voltageLevel}</td>
                        </tr>,
                      ]
                      : null}
                    <tr>
                      <td>
                        {this.strings.vreg}{" "}
                        <i
                          className="fas fa-info-circle ml-1 text-info"
                          data-toggle="tooltip"
                          data-boundary="viewport"
                          data-placement="right"
                          title={this.strings.vregDesc}
                        ></i>
                      </td>
                      <td className={!sdp.vregProducer ? "p-0" : ""}>
                        {sdp.vregProducer || (
                          <div className="d-flex align-items-center">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="vreg_id"
                                value={this.state.vreg_id}
                                onChange={this.onFieldChange}
                                style={{ padding: "2px 3px" }}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-warning border-dark"
                                  type="button"
                                  style={{ padding: "2px 10px" }}
                                  onClick={() => this.saveVregIds(sdp.ean)}
                                >
                                  <i className="fas fa-save"></i>
                                </button>
                              </div>
                            </div>
                            <i
                              className="fas fa-question-circle mx-2 text-info cursor-pointer"
                              data-toggle="modal"
                              data-target="#vreg-help"
                              title={this.strings.vregDesc2}
                            ></i>
                          </div>
                        )}
                      </td>
                    </tr>
                    {this.state.error ? (
                      <tr>
                        <td
                          colSpan="6"
                          className="alert alert-warning text-center"
                        >
                          {this.state.error}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>{this.strings.address}</td>
                      <td>
                        {sdp.addressStreet} {sdp.addressNumber}, {sdp.addressZip}{" "}
                        {sdp.addressCity}, {sdp.addressCountry}
                      </td>
                    </tr>
                    <tr>
                      <td>{this.strings.serviceComponent}</td>
                      <td>
                        {sdp.service_component ? sdp.service_component : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>{this.strings.serviceConfiguration}</td>
                      <td>{sdp.service_config ? sdp.service_config : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>{this.strings.physicalStatus}</td>

                      <td
                        className={
                          sdp.physicalStatus === "E23" ||
                            sdp.physicalStatus === "Disconnected" ||
                            sdp.physicalStatus === "E30" ||
                            sdp.physicalStatus === "Under construction"
                            ? "text-danger"
                            : ""
                        }
                      >
                        {sdp.physicalStatus === "E23" ||
                          sdp.physicalStatus === "Disconnected" ? (
                          <span>{this.strings.disconnected}</span>
                        ) : sdp.physicalStatus === "E30" ||
                          sdp.physicalStatus === "Under construction" ? (
                          <span>{this.strings.underConstruction}</span>
                        ) : sdp.physicalStatus === "E22" ||
                          sdp.physicalStatus === "Connected" ? (
                          <span>{this.strings.connected}</span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>{this.strings.lockStatus}</td>
                      {
                        <td
                          className={
                            sdp.lock_status === "Locked" ? "text-danger" : ""
                          }
                        >
                          {sdp.lock_status === "Locked" ? (
                            <span>{this.strings.locked}</span>
                          ) : sdp.lock_status === "Unlocked" ? (
                            <span>{this.strings.unlocked}</span>
                          ) : null}
                        </td>
                      }
                    </tr>
                    {this.state.showDetails
                      ? [
                        <tr>
                          <td>{this.strings.volume}</td>
                          <td>
                            {sdp.volume &&
                              sdp.volume !== 0 &&
                              sdp.volume !== null
                              ? sdp.volume + " MWh"
                              : "N/A"}
                          </td>
                        </tr>,
                        <>
                      {[
                        { key: 'power_solar', displayName: 'Solar Power' },
                        { key: 'power_wind', displayName: 'Wind Power' },
                        { key: 'power_hydro-electric head installations', displayName: 'Hydro Power' },
                        { key: 'power_thermal', displayName: 'Thermal Power' },
                        { key: 'power_other', displayName: 'Other Power' },
                        { key: 'power', displayName: 'Power' }
                      ].map(({ key, displayName }) => {
                        const powerValue = sdp[key];
                        return powerValue ? (
                          <tr key={key}>
                            <td>{displayName}</td>
                            <td>{powerValue + ' kVA'}</td>
                          </tr>
                        ) : null;
                      })}
                      </>,
                        <tr>
                          <td>{this.strings.preswitchDate}</td>
                          <td>
                            {sdp.preswitch_dtlt !== null
                              ? moment(
                                sdp.preswitch_dtlt,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD/MM/YYYY")
                              : "N/A"}
                          </td>
                        </tr>,
                      ]
                      : null}
                  </tbody>
                </table>
                <h4 className="text-primary mt-4 mb-3 font-weight-light">
                  <strong>{this.strings.mandate}</strong> {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>{this.strings.mandate}</td>
                      <td>
                        {sdp.mandate
                          ? sdp.validTo === null ||
                            moment(sdp.validTo).isAfter(moment())
                            ? this.strings.validMandate
                            : this.strings.endedMandate
                          : sdp.mandateSent
                            ? this.strings.requestSent
                            : this.strings.noMaterDataPermissions}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="text-primary mt-4 mb-3">
                  <strong>{this.strings.contract}</strong> {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  {sdp.hasContract ? (
                    <tbody>
                      {/* <tr>
                        <td>{this.strings.formula}</td>
                        <td>{this.strings.epexHourly}</td>
                      </tr> */}
                      <tr>
                        <td>{this.strings.start}</td>
                        <td>
                          {sdp.startDate
                            ? moment(sdp.startDate).format("DD-MM-YYYY")
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.end}</td>
                        <td>
                          {sdp.endDate ? (
                            <div>
                              {moment(sdp.endDate).format("DD-MM-YYYY")}
                              <small className="form-text text-muted">
                                <strong>{this.strings.note}</strong>{" "}
                                {this.strings.endDateNotIncluded}{" "}
                                {moment(sdp.endDate).format("DD-MM-YYYY")}
                                {this.strings.endDateNotIncluded2}
                              </small>
                            </div>
                          ) : (
                            this.strings.month
                          )}
                        </td>
                      </tr>

                      {sdp.fixedFee !== null && sdp.fixedFee !== 0 ? (
                        <tr>
                          <td>{this.strings.fixedFee}</td>
                          <td>
                            <strong>{sdp.fixedFee} EUR/MWh</strong>
                          </td>
                        </tr>
                      ) : null}
                      {sdp.imbCostPct !== 0 && sdp.imbCostPct !== null ? (
                        <tr>
                          <td>{this.strings.imbalanceIndex}</td>
                          <td>
                            <strong>
                              {sdp.imbCostPct * 100 + " %"}
                            </strong>
                          </td>
                        </tr>
                      ) : null}
                      {sdp.windImbCostPct !== 0 && sdp.windImbCostPct !== null ? (
                        <tr>
                          <td>{this.strings.windImbalanceIndex}</td>
                          <td>
                            <strong>{sdp.windImbCostPct * 100 + " %"}</strong>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>{this.strings.product}</td>
                        <td>
                          {sdp.product_name}
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.status}</td>
                        <td>
                          {sdp.cih_status}
                        </td>
                      </tr>
                      <tr>
                        <td>{this.strings.contractId}</td>
                        <td>
                          {sdp.contract_id}
                        </td>
                      </tr>
                      {this.state.showDetails ? (
                        <tr>
                          <td>{this.strings.go}</td>
                          <td>€ {numeral(sdp.GOprice).format("0.00")} / MWh</td>
                        </tr>
                      ) : null}
                    </tbody>
                  ) : null}
                  <tbody>
                    {!sdp.hasContract ? (
                      <tr>
                        <td
                          colSpan="2"
                          className="bg-danger text-white text-center py-3"
                        >
                          <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                          <strong>
                            {this.strings.noContract}{" "}
                            <Link
                              to={`/services/onboarding#contract`}
                              className="text-white"
                            >
                              {this.strings.noContract2}
                            </Link>
                          </strong>
                        </td>
                      </tr>
                    ) : !sdp.signatureDate ? (
                      [
                        <br />,
                        <tr>
                          <td
                            colSpan="2"
                            className="bg-danger text-white text-center py-3"
                          >
                            <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                            <strong>
                              {this.strings.noSignedContract1}{" "}
                              <Link
                                to={`/documents/pendingAgreement/${this.props.selectedCompany.companyNumber}/supply`}
                                className="text-white"
                              >
                                {this.strings.noSignedContract2}
                              </Link>
                            </strong>
                          </td>
                        </tr>,
                      ]
                    ) : sdp.contract_status === "ended" ? (
                      [
                        <br />,
                        <tr>
                          <td
                            colSpan="2"
                            className="bg-danger text-white text-center py-3"
                          >
                            <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                            <strong>
                              {this.strings.endedContract1} {sdp.endDate}.{" "}
                              <Link
                                to={`/admin/manage-company#contracts`}
                                className="text-white"
                              >
                                {this.strings.endedContract2}
                              </Link>
                            </strong>
                          </td>
                        </tr>,
                      ]
                    ) : null}
                  </tbody>
                </table>
                {sdp.hasContract
                  ? [
                    <h4 className="text-primary mt-4 mb-3 font-weight-light">
                      <strong>{this.strings.switchInfoYuso}</strong>{" "}
                      {this.strings.point}
                    </h4>,
                    <table className="table table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>{this.strings.switchState}</td>
                          <td
                            className={
                              (!sdp.withYuso && sdp.contract_status === "active") ||
                                (sdp.withYuso && sdp.contract_status !== "active")
                                ? "text-danger"
                                : null
                            }
                          >
                            {sdp.withYuso
                              ? this.strings.withYuso
                              : this.strings.notWithYuso}
                          </td>
                        </tr>
                        {sdp.switchDate ? (
                          <tr>
                            <td>{this.strings.switchDate}</td>
                            <td>
                              {moment(sdp.switchDate).format("DD-MM-YYYY")}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{this.strings.switchDate}</td>
                            <td>N/A</td>
                          </tr>
                        )}
                        {sdp.switchStatus === "39" ? (
                          <tr>
                            <td>{this.strings.switchStatus}</td>
                            <td>{this.strings.switchApproved}</td>
                          </tr>
                        ) :
                          (sdp.switchStatus === '41' ? <tr><td>{this.strings.switchStatus}</td><td>{this.strings.switchRejected}</td></tr>
                            :
                            <tr><td>{this.strings.switchStatus}</td><td>{this.strings.notSwtiched}</td></tr>
                          )}
                        {sdp.switchStatusReason !== null ? (
                          <tr>
                            <td>{this.strings.switchStatusReason}</td>
                            <td>{sdp.switchStatusReason}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{this.strings.switchStatusReason}</td>
                            <td>N/A</td>
                          </tr>
                        )}
                        {!this.state.showDetails && sdp.eocDate !== null ? (
                          <tr>
                            <td>{this.strings.eocDate}</td>
                            <td>{moment(sdp.eocDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        ) : null}
                        {/* {this.state.showDetails && sdp.switchDate
                        ? [
                          <tr>
                            <td>{this.strings.switchStatus}</td>
                            <td>{sdp.switchStatus}</td>
                          </tr>,
                          <tr>
                            <td>{this.strings.switchStatusReason}</td>
                            {sdp.switchType === "Internal Switch" ? (
                              <td>{this.strings.internallySwitched}</td>
                            ) : (
                              <td>
                                {sdp.switchStatusReason} (
                                {sdp.switchStatusReasonDesc})
                              </td>
                            )}
                          </tr>,
                        ]
                        : null} */}
                        {this.state.showDetails && sdp.eocDate
                          ? [
                            <tr>
                              <td>{this.strings.eocDate}</td>
                              <td>
                                {moment(sdp.eocDate).format("DD-MM-YYYY")}
                              </td>
                            </tr>,
                            <tr>
                              <td>{this.strings.eocStatus}</td>
                              <td>{sdp.eocStatus}</td>
                            </tr>,
                            <tr>
                              <td>{this.strings.eocStatusReason}</td>
                              {sdp.eocStatusReason === "COMPLETED" ? (
                                <td>
                                  {sdp.eocStatusReason} (
                                  {this.strings.internallySwitched})
                                </td>
                              ) : (
                                <td>
                                  {sdp.eocStatusReason} (
                                  {sdp.eocStatusReasonDesc})
                                </td>
                              )}
                            </tr>,
                          ]
                          : null}
                      </tbody>
                    </table>,
                  ]
                  : null}
                <h4 className="text-primary mt-4 mb-3 font-weight-light">
                  <strong>{this.strings.switchInfoMig6}</strong>{" "}
                  {this.strings.point}
                </h4>
                <table className="table table-striped table-sm">
                  <tbody>
                    {sdp.withYusoColumn === "Y" ? (
                      <tr>
                        <td>{this.strings.switchStatus}</td>
                        <td>{this.strings.inPortfolio}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.switchStatus}</td>
                        <td>{this.strings.notInPortfolio}</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_Startdate ? (
                      <tr>
                        <td>{this.strings.startDateMig6}</td>
                        <td>
                          {moment(sdp.runningprocesses_Startdate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.switchStatusReason}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_module ? (
                      <tr>
                        <td>{this.strings.moduleMig6}</td>
                        <td>{sdp.runningprocesses_module}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.moduleMig6}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_label ? (
                      <tr>
                        <td>{this.strings.labelMig6}</td>
                        <td>{sdp.runningprocesses_label}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{this.strings.labelMig6}</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {sdp.runningprocesses_RequestorInvolvedBEAssociatedParty ? (
                      <tr>
                        <td>{this.strings.supplierMig6}</td>
                        <td>
                          {
                            sdp.runningprocesses_RequestorInvolvedBEAssociatedParty
                          }
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <hr className="mb-4" />
              </DropdownPanel>
          );
        })}
      </div>
    );
  }
}

export default Injection;
