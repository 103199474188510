import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import SubHeader from '../../components/SubHeader';
import SdpOnboarding from './SdpOnboarding';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

class NewSdp extends Component {
  constructor(props) {
    super(props);

    this.strings = LocalizedStrings({
      en: {
        services: "Services",
        loading: "Loading...",
        demoAccount: "You are currently viewing the My Yuso demo account, which doesn't support adding of new installation points. If you want to add a new service installation point please first switch to your company"
      },
      nl: {
        services: "Diensten",
        loading: "Bezig met laden...",
        demoAccount: "U bekijkt momenteel het My Yuso demo-account, dat het toevoegen van nieuwe installatiepunten niet ondersteunt. Als u een nieuw service-installatiepunt wilt toevoegen, schakel dan eerst over naar jouw bedrijf"
      }
    }, this.props.lang);
  }

  render() {

    if (!(this.props.selectedCompany)) {
      return (
        <div>
          <SubHeader name={this.strings.services} type="Services" />
          <Loader>
            {this.strings.loading}
          </Loader>
        </div>
      );
    }

    return (
      <div>
        <SubHeader name={this.strings.services} type="Services" />
        <div className="spacer"></div>
        {
          this.props.selectedCompany.demo && this.props.user.username !== this.props.demo.contact.email ?
            <p className="alert alert-warning text-body">
              <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.demoAccount}
              &nbsp;
              <strong>
                <Link className="text-decoration-none text-body" to={'/account/company/switch/' + (this.props.defaultCompany ? this.props.defaultCompany.companyNumber : this.props.demo.company.kbo)}>
                  {this.props.defaultCompany ? this.props.defaultCompany.companyName : this.props.demo.company.name}
                </Link>.
              </strong>
            </p>
            :
            <SdpOnboarding
              selectedCompany={this.props.selectedCompany}
              updateCompanies={this.props.updateCompanies}
              defaultCompany={this.props.defaultCompany}
              info={this.props.info}
              updateInfo={this.props.updateInfo}
              lang={this.props.lang}
              user={this.props.user}
            />
        }
      </div>
    );
  }
}
export default NewSdp;