import React, { Component } from 'react';
import LocalizedStrings from '../components/Localization';

import LanguageChooser from '../components/LanguageChooser';
import Footer from '../components/Footer';

class UnsupportedBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: window.reactStorage.getItem('lang') || 'nl' // Use Nederlands as default language
    }

    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(lang) {
    this.setState({ lang: lang })
  }

  render() {
    this.strings = LocalizedStrings({
      en: {
        UnsupportedBrowser: "Unsupported browser",
        message: "Seems like you're running unsupported browser, for better experience with MY YUSO please use Chrome or Mozilla Firefox browser"
      },
      nl: {
        UnsupportedBrowser: "Niet-ondersteunde browser",
        message: "Het lijkt erop dat je een niet-ondersteunde browser gebruikt, voor een betere ervaring met MY YUSO gebruik je Chrome of Mozilla Firefox browser"
      }
    }, this.state.lang);
    return (

      <div className="container">

        <div className="container navigation-placeholder"></div>
        <div className="container navigation">
          <LanguageChooser lang={this.state.lang} changeLanguage={this.changeLanguage} />
          <img className="navbar-brand"
            style={{ width: '250px' }}
            src={`${process.env.PUBLIC_URL}/yuso-portal.png`}
            alt='Yuso'
          />
        </div>

        <div className="d-flex justify-content-center align-items-center py-5" style={{ flexDirection: "row" }}>
          <div className="text-center py-5">
            <h3 className="mb-3">{this.strings.UnsupportedBrowser}</h3>
            <p>
              {this.strings.message}
            </p>
          </div>
        </div>


        <Footer lang={this.state.lang} />
      </div>

    );
  }
}

export default UnsupportedBrowser;
