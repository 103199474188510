import config from './config';
import { getAccessToken } from './user';

const submit = (ean, source, direction, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/mig6/preswitch/${ean}/${source}/${direction}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'

                    },
                    method: 'GET'
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const submitPreswitchFUll = (ean, source, direction, installation_id, cb) =>{
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            
            fetch(
                `${config.BASE_URL}/api/mig6/preswitchFull/${ean}/${source}/${direction}/${installation_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'

                    },
                    method: 'GET'
                }
            ).then(
                (res) => {
                    res.json().then(
                        (resData) => {
                            cb(null, resData);
                        }
                        // (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const switchRequest = (company_id, sdp, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mig6/switchRequest/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sdp)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getMessages = (cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mig6/getMessages`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

export {
    submit,
    switchRequest,
    submitPreswitchFUll,
    getMessages
}