import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getPartners, deletePartner } from '../../api/partner';


class ManagePartners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: undefined
    }

    this.updatePartners = this.updatePartners.bind(this);
    this.onDeleteAttempt = this.onDeleteAttempt.bind(this);
    this.deletePartner = this.deletePartner.bind(this);

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        managePartners: "Manage My Yuso partners",
        loading: "Loading partners...",
        name: "Name",
        vat: "VAT number",
        phone: "Phone",
        defaultPartnerFee: "Default fee",
        discountFee: "Discount fee",
        fee: "fee:",
        discount: "disc:",
        type: "Type",
        subtype: "Sub-type",
        activeCommission: "Active commission",
        users: "Users",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        noPartners: "No partners found, add your first one",
        addPartner: "Add new partner",
        confirmDelete: "Confirm partner delete?",
        deleteOperations: "These operations will be executed:",
        delete1: "The partner will be removed from all linked companies and default to the Yuso partner",
        delete2: "The partner will be removed from all contracts and default to the Yuso partner",
        delete3: "All partner users will lose access to the companies linked to the partner",
        delete4: "All partner users will become regular users without access to partner functionality"
      },
      nl: {
        welcome: "Welkom",
        manage: "je kunt",
        managePartners: "Beheer My Yuso partners",
        loading: "Partners laden...",
        name: "Naam",
        vat: "BTW-nummer",
        phone: "Telefoon",
        defaultPartnerFee: "Standaardvergoeding",
        discountFee: "Kortingstarief",
        fee: "ver:",
        discount: "kort:",
        type: "Type",
        subtype: "Subtype",
        activeCommission: "Actieve commissie",
        users: "Gebruikers",
        actions: "Acties",
        edit: "Bewerk",
        delete: "Verwijderen",
        cancel: "Annuleer",
        noPartners: "Geen partners gevonden, voeg uw eerste toe",
        addPartner: "Nieuwe partner toevoegen",
        confirmDelete: "Partner verwijderen bevestigen?",
        deleteOperations: "Deze bewerkingen worden uitgevoerd:",
        delete1: "De partner wordt verwijderd uit alle gelinkte bedrijven en wordt standaard de Yuso-partner",
        delete2: "De partner wordt uit alle contracten verwijderd en gaat in gebreke bij de Yuso-partner",
        delete3: "Alle partnergebruikers verliezen de toegang tot de bedrijven die aan de partner zijn gekoppeld",
        delete4: "Alle partnergebruikers worden gewone gebruikers zonder toegang tot partnerfunctionaliteit"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.updatePartners();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  updatePartners() {
    this.setState({ partners: undefined })

    getPartners((err, res) => {
      if (err)
        return window.reportError.send(err);

      const active = res.filter(x => x.activeCommission).sort((x, y) => x.name > y.name ? 1 : -1),
        inactive = res.filter(x => !x.activeCommission).sort((x, y) => x.name > y.name ? 1 : -1)

      this.setState({
        partners: active.concat(inactive)
      })
    })
  }

  onDeleteAttempt(e) {
    this.setState({
      selectedPartner: e.target.id !== "cancelDeletion" ? e.target.id : undefined
    })
  }

  deletePartner() {
    deletePartner(this.state.selectedPartner, (err, res) => {
      if (err)
        return window.reportError.send(err);

      if (res.status === 'OK') {
        this.setState({
          selectedPartner: undefined
        });

        this.updatePartners();
      }
      else this.setState({
        error: res.error,
        selectedPartner: undefined
      })
    })
  }

  render() {
    if (!this.state.partners) {
      return <Loader>{this.strings.loading}</Loader>
    }

    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.managePartners}
            </div>

            <div>
              {
                !this.state.partners ?
                  <Loader inline="true" className="text-center" />
                  :
                  !this.state.partners.length ?
                    <div className="text-center my-4">
                      <h5 className="mb-4">{this.strings.noPartners}</h5>
                      <Link to="/admin/partner" className="btn btn-primary">{this.strings.addPartner}</Link>
                    </div>
                    :
                    <div>
                      <Link to="/admin/partner" className="btn btn-primary mb-4">{this.strings.addPartner}</Link>

                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col">{this.strings.name}</th>
                            <th scope="col">{this.strings.vat}</th>
                            <th scope="col">{this.strings.phone}</th>
                            <th scope="col">{this.strings.defaultPartnerFee} / {this.strings.discountFee}</th>
                            <th scope="col">{this.strings.type} / {this.strings.subtype}</th>
                            <th scope="col">{this.strings.activeCommission}</th>
                            <th scope="col">{this.strings.users}</th>
                            <th scope="col">{this.strings.actions}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.partners.map(partner => (
                              <tr key={partner.id} className={partner.activeCommission ? 'bg-light' : ''}>
                                <td>{partner.name}</td>
                                <td>{partner.vatNumber}</td>
                                <td>
                                  {
                                    partner.phone ?
                                      <a href={`tel:${partner.phone}`}>{partner.phone}</a>
                                      : null
                                  }
                                </td>
                                <td>{this.strings.fee} {partner.defaultPartnerFee} €/MWh<br />{this.strings.discount} {partner.discountFee} €/MWh</td>
                                <td>{partner.type}<br />{partner.subtype}</td>
                                <td>
                                  {
                                    partner.activeCommission ?
                                      <strong>YES</strong>
                                      : 'NO'
                                  }
                                </td>
                                <td title={partner.contacts && partner.contacts.split(',').join('<br/>')} data-html="true" data-toggle="tooltip">{partner.contacts_count}</td>
                                <td className="text-center">
                                  <Link to={'/admin/partner/' + partner.id} className="text-decoration-none" title={this.strings.edit}>
                                    <i className="fas fa-pencil-alt text-sucess cursor-pointer"></i>
                                  </Link>
                                  <span className="ml-3" title={this.strings.delete}>
                                    <i className="fas fa-times text-danger cursor-pointer" data-toggle="modal" id={partner.id} onClick={this.onDeleteAttempt} data-target="#confirm-partner-delete"></i>
                                  </span>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>

                      <div className="modal fade" id="confirm-partner-delete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                <i className="fas fa-exclamation-triangle mr-2 text-warning"></i> {this.strings.confirmDelete}
                              </h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <p>
                                {this.strings.deleteOperations}
                              </p>
                              <ul>
                                <li>{this.strings.delete1}</li>
                                <li>{this.strings.delete2}</li>
                                <li>{this.strings.delete3}</li>
                                <li>{this.strings.delete4}</li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" id="cancelDeletion" data-dismiss="modal" onClick={this.onDeleteAttempt}>{this.strings.cancel}</button>
                              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deletePartner}>{this.strings.delete}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              }
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ManagePartners;
