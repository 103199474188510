import React, { Component } from 'react';
import LocalizedStrings from './Localization';

class Footer extends Component {
  render() {
    let strings = LocalizedStrings({
      en: {
        support: "Support",
        contact: "Contact",
        privacy: "Privacy statement",
        address: "address",
        phone: "phone",
        mail: "mail",
        vat: "vat"
      },
      nl: {
        support: "Support",
        contact: "Contact",
        privacy: "Privacy verklaring",
        address: "adres",
        phone: "telefoon",
        mail: "mail",
        vat: "vat"
      }
    }, this.props.lang);

    return (
      <footer className="row">
        <div className="col-sm p-5">
          <div className="links-info d-flex align-items-center justify-content-sm-center flex-wrap px-2 mb-4">
            <img
              style={{ width: '150px' }}
              src={`${process.env.PUBLIC_URL}/yuso-logo-inverted.png`}
              alt='Yuso'
              className="mr-lg-auto"
            />

            <a className="ml-md-3 ml-0 mt-md-0 mt-2" href="https://support.yuso.be/en/">{strings.support}</a>
            <a className="ml-md-3 ml-0 mt-md-0 mt-2" href="https://yuso.be/contact/">{strings.contact}</a>
            <a className="ml-md-3 ml-0 mt-md-0 mt-2" href="https://yuso.be">yuso.be</a>
          </div>
          <div className="legal-info d-flex align-items-center justify-content-center flex-wrap">
            <div><span>{strings.address}</span> Zuiderlaan 11 bus 31, 8790 Waregem, Belgium</div>
            <div><span>{strings.phone}</span><a href="tel:+3256960124"> +32 56 960 124</a></div>
            <div><span>{strings.mail}</span> info@yuso.be</div>
            <div><span>{strings.vat}</span> BE 0848 296 771</div>
            <div><a href="https://yuso.be/privacy/">{strings.privacy}</a></div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
