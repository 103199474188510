import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import { CallToAction, CtaTitle, CtaContent } from './CallToAction';
import LocalizedStrings from './Localization';

import { getNotifications, setNotifications } from '../api/user';
import Loader from './Loader';


class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: undefined
    };

    this.changeNotifications = this.changeNotifications.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading...",
        follow: "Follow",
        energyPrices: "the energy prices?",
        getPrice: "Get daily price",
        notifications: "notifications",
        email: "via email.",
        notifyExtreme: "Notify me of extreme prices",
        extremeDesc: "Receive a notification when prices are higher than 100 EUR/MWh or lower than 10 EUR/MWh",
        notifyEveryDay: "Send me the prices every day",
        dontNotify: "Don't send any notifications"
      },
      nl: {
        loading: "Laden...",
        follow: "Volg",
        energyPrices: "de energieprijzen?",
        getPrice: "Krijg dagelijkse prijs",
        notifications: "meldingen",
        email: "via e-mail.",
        notifyExtreme: "Breng me op de hoogte van extreme prijzen",
        extremeDesc: "Ontvang een melding wanneer de prijzen hoger zijn dan 100 EUR/MWh of lager dan 10 EUR/MWh",
        notifyEveryDay: "Stuur mij elke dag de prijzen",
        dontNotify: "Stuur geen meldingen"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    getNotifications((err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({ notifications: res.notifications || 0 });
    })
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  changeNotifications(e) {
    let notifications = parseInt(e.target.value, 10) || 0
    this.setState({ notifications }, () => {

      setNotifications(this.state, (err, res) => {
        if (err)
          return window.reportError.send(err);
      })
    });

  }

  render() {
    return (
      <CallToAction className={this.props.className}>
        <CtaTitle><strong>{this.strings.follow}</strong> {this.strings.energyPrices}</CtaTitle>
        <CtaContent>
          <p>
            {this.strings.getPrice} <strong>{this.strings.notifications}</strong> {this.strings.email}
          </p>
        </CtaContent>
        {
          this.state.notifications === undefined ?
            <Loader inline="true">{this.strings.loading}</Loader>
            :
            <div className="notifications-dropdown">
              <div className={'btn text-left bg-white text-primary w-100 px-4 py-3 ' + (this.state.notifications === 0 ? '' : 'd-none')}>
                {this.strings.dontNotify}
              </div>
              <div className={'btn text-left bg-white text-primary w-100 px-4 py-3 ' + (this.state.notifications === 1 ? '' : 'd-none')}>
                {this.strings.notifyEveryDay}
              </div>
              <div className={'btn text-left bg-white text-primary w-100 px-4 py-3 ' + (this.state.notifications === 2 ? '' : 'd-none')}>
                {this.strings.notifyExtreme} <i className="fa fa-info-circle ml-1 text-info" data-toggle="tooltip" data-boundary="viewport" title={this.strings.extremeDesc}></i>
              </div>

              <div className="notifications-menu">
                <button type="button" className="btn text-left w-100 px-4 py-3" value="0" onClick={this.changeNotifications}>
                  <i className={'fas fa-check mr-1 ' + (this.state.notifications === 0 ? '' : 'invisible')}></i> {this.strings.dontNotify}
                </button>
                <button type="button" className="btn text-left w-100 px-4 py-3" value="1" onClick={this.changeNotifications}>
                  <i className={'fas fa-check mr-1 ' + (this.state.notifications === 1 ? '' : 'invisible')}></i> {this.strings.notifyEveryDay}
                </button>
                <button type="button" className="btn text-left w-100 px-4 py-3" value="2" onClick={this.changeNotifications}>
                  <i className={'fas fa-check mr-1 ' + (this.state.notifications === 2 ? '' : 'invisible')}></i> {this.strings.notifyExtreme} <i className="fa fa-info-circle ml-1 text-info" data-toggle="tooltip" data-boundary="viewport" title={this.strings.extremeDesc}></i>
                </button>
              </div>
            </div>
        }
      </CallToAction>
    );
  }
}

export default Notifications;
