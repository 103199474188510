import React, { Component } from 'react';
import SubHeader from '../../components/SubHeader';
import LocalizedStrings from '../../components/Localization';

import { getAgreementTemplate } from '../../api/documents';

class AgreementsTemplates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gtcVersion: "2023-04",
            loading: undefined
        };

        this.getAgreement = this.getAgreement.bind(this);

        this.strings = LocalizedStrings({
            en: {
                agreements: "Agreement templates",
                agreementsDesc: "Here you will find templates of all the agreement types and the general terms and conditions of Yuso in pdf and docx format.",
                supplyAgreement: "Supply agreement",
                injectionAgreement: "Injection agreement",
                gtc: "General Terms and Conditions",
                name: "Name",
                view: "View",
                offtake_fileName: "Offtake Agreement Template",
                injection_fileName: "Injection Agreement Template",
                gtc_fileName: "YUSO-general-terms-and-conditions"
            },
            nl: {
                agreements: "Overeenkomst templates",
                agreementsDesc: "Hier vind je templates van alle soorten overeenkomsten en de algemene voorwaarden van Yuso in pdf- en docx-formaat.",
                supplyAgreement: "Leveringsovereenkomst",
                injectionAgreement: "Injectieovereenkomst",
                gtc: "Algemene voorwaarden",
                name: "Name",
                view: "View",
                offtake_fileName: "Template afnameovereenkomst",
                injection_fileName: "Template voor injectieovereenkomst",
                gtc_fileName: "Contract-YUSO-algemene-voorwaarden"
            }
        }, this.props.lang);
    }

    getAgreement(e) {
        if (e.target.dataset.aggtype && e.target.dataset.filetype && (e.target.dataset.dmr !== undefined || e.target.dataset.aggtype === "gtc")) {
            this.setState({ loading: e.target.id })

            let fileName = e.target.dataset.aggtype === "gtc" ? `${this.state.gtcVersion}-` : ``
            fileName += `${this.strings[e.target.dataset.aggtype + "_fileName"]}${e.target.dataset.dmr === "true" ? " (DMR)" : ""}-${this.props.lang.toUpperCase()}.${e.target.dataset.filetype}`

            getAgreementTemplate({
                type: e.target.dataset.aggtype,
                dmr: e.target.dataset.dmr,
                fileType: e.target.dataset.filetype,
                fileName,
                lang: this.props.lang
            }, (err, res) => {
                this.setState({ loading: undefined })
            })
        }
    }

    render() {
        return (
            <div>
                <SubHeader name={this.strings.agreements} image="Documents" type="Agreements" />
                <div className="spacer"></div>
                <p className="p-2 px-xl-5 mb-3">
                    {this.strings.agreementsDesc}
                </p>
                <div className="p-2 px-xl-5 mb-5">
                    <table className="table table-sm table-hover mb-4">
                        <thead>
                            <tr>
                                <th>{this.strings.name}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="text-primary">
                                        {this.strings.supplyAgreement}
                                        <div className="float-right">
                                            <button className="btn btn-outline-primary btn-sm px-3 mr-2" data-aggtype="offtake" data-filetype="pdf" data-dmr={false} id="1" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "1" ? "fas fa-spinner fa-spin" : "fa-file-pdf"} mr-2`}></i> PDF
                                            </button>
                                            <button className={`btn btn-outline-primary btn-sm px-3`} data-aggtype="offtake" data-filetype="docx" data-dmr={false} id="2" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "2" ? "fas fa-spinner fa-spin" : "fa-file-download"} mr-2`}></i> DOCX
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="text-primary">
                                        {this.strings.supplyAgreement} (DMR)
                                        <div className="float-right">
                                            <button className="btn btn-outline-primary btn-sm px-3 mr-2" data-aggtype="offtake" data-filetype="pdf" data-dmr={true} id="3" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "3" ? "fas fa-spinner fa-spin" : "fa-file-pdf"} mr-2`}></i> PDF
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm px-3" data-aggtype="offtake" data-filetype="docx" data-dmr={true} id="4" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "4" ? "fas fa-spinner fa-spin" : "fa-file-download"} mr-2`}></i> DOCX
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="text-primary">
                                        {this.strings.injectionAgreement}
                                        <div className="float-right">
                                            <button className="btn btn-outline-primary btn-sm px-3 mr-2" data-aggtype="injection" data-filetype="pdf" data-dmr={false} id="5" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "5" ? "fas fa-spinner fa-spin" : "fa-file-pdf"} mr-2`}></i> PDF
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm px-3" data-aggtype="injection" data-filetype="docx" data-dmr={false} id="6" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "6" ? "fas fa-spinner fa-spin" : "fa-file-download"} mr-2`}></i> DOCX
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="text-primary">
                                        {this.strings.injectionAgreement} (DMR)
                                        <div className="float-right">
                                            <button className="btn btn-outline-primary btn-sm px-3 mr-2" data-aggtype="injection" data-filetype="pdf" data-dmr={true} id="7" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "7" ? "fas fa-spinner fa-spin" : "fa-file-pdf"} mr-2`}></i> PDF
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm px-3" data-aggtype="injection" data-filetype="docx" data-dmr={true} id="8" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "8" ? "fas fa-spinner fa-spin" : "fa-file-download"} mr-2`}></i> DOCX
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="text-primary">
                                        {this.strings.gtc}
                                        <div className="float-right">
                                            <button className="btn btn-outline-primary btn-sm px-3 mr-2" data-aggtype="gtc" data-filetype="pdf" id="9" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "9" ? "fas fa-spinner fa-spin" : "fa-file-pdf"} mr-2`}></i> PDF
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm px-3" data-aggtype="gtc" data-filetype="docx" id="10" onClick={this.getAgreement} disabled={this.state.loading}>
                                                <i className={`fas ${this.state.loading === "10" ? "fas fa-spinner fa-spin" : "fa-file-download"} mr-2`}></i> DOCX
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default AgreementsTemplates;
