import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import moment from 'moment-timezone'
import numeral from 'numeral';
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import XlsxPopulate from 'xlsx-populate';
import NumberInput from '../../components/NumberInput';
import LocalizedStrings from '../Localization';
import Loader from '../Loader';

import './simulation-print.scss'

import { getSdps } from '../../api/sdps';
import { getPeriods, simultateContract, comparePrices } from '../../api/simulations';

moment.tz.setDefault('Europe/Brussels')

const VolumeTooltip = props => {

    if (!props.active) return null

    return <DefaultTooltipContent
        label={props.strings.month + ': ' + props.label}
        payload={[
            {
                color: '#000',
                name: props.strings.totalVolume,
                unit: 'kWh',
                value: props.payload.reduce((acc, cur) => acc += cur.value, 0).toFixed(2),
            },
            ...props.payload
        ]}
    />;
};

class ContractSimilator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sdps: undefined,
            selectedEan: {},
            periods: undefined,
            simulation: undefined,
            compareType: '',
            lang: this.props.lang
        }

        this.timer = null;

        this.onFieldChange = this.onFieldChange.bind(this)
        this.onSimulate = this.onSimulate.bind(this)
        this.isDisabled = this.isDisabled.bind(this)
        this.monthFormatter = this.monthFormatter.bind(this)
        this.hourFormatter = this.hourFormatter.bind(this)
        this.onExport = this.onExport.bind(this)
        this.getMaxValueTooltip = this.getMaxValueTooltip.bind(this)
        this.calculateTotals = this.calculateTotals.bind(this)
        this.onRemoteCompare = this.onRemoteCompare.bind(this)
        this.manualPrice = this.manualPrice.bind(this)

        this.strings = LocalizedStrings({
            en: {
                loading: "Loading...",
                loadingEans: "Loading EANs...",
                ean: "EAN",
                startDate: "Start month",
                endDate: "End month",
                peakHours: "Peak hours",
                offpeakHours: "Off-peak hours",
                total: "Total",
                month: "Month",
                hour: "Hour",
                fee: "Yuso platfrom fee",
                simulate: "Simulate",
                fixed: "Fixed",
                variable: "Variable",
                manual: "Manual",
                simulating: "Simulating...",
                comparing: "Calculating...",
                volume: "Volume",
                avg: "Avg. price",
                export: "Export Excel",
                simulation: "Simulation",
                monthlyVolumes: "Monthly volumes",
                monthlyVolumesDesc: "This chart shows the volumes per month split in peak and off-peak volumes.",
                peakVolume: "Peak volume",
                offpeakVolume: "Offpeak volume",
                totalVolume: "Total volume",
                monthlyAvgPrices: "Monthly average prices",
                monthlyAvgPricesDesc: "The chart shows the average day ahead market prices and your average Yuso price. The difference between the two is caused by two elements:",
                monthlyAvgPricesDesc1: "The Yuso price includes the platform fee that covers all yuso costs. This is just a fixed value per MW so it just shifts the day ahead market prices curve on the vertical axes.",
                monthlyAvgPricesDesc2_injection: "Your average Yuso price is volume weighted. If you produce a lot during high price hours, your average Yuso price will be higher than the day ahead market price average. In the case of an optimal production pattern, your Yuso price can therefore become higher than the average day ahead market prices.",
                monthlyAvgPricesDesc2_offtake: "Your average Yuso price is volume weighted. If you consume a lot during low price hours, your average Yuso price will be below the day ahead market price average. In the case of an optimal consumption pattern, your Yuso price can therefore fall below the average day ahead market prices.",
                averagePrice: "Weighted avg. Yuso price",
                averageDayAheadPrices: "Avg. day ahead market base price",
                averageCompare: "Avg. compare price",
                hourlyAvgVolumes: "Hourly average volumes",
                hourlyAvgVolumesDesc: "This chart shows the average profile of your consumption or injection throughout a day ver the selected period.",
                averageVolume: "Average volume",
                hourlyAvgPrices: "Hourly average prices",
                hourlyAvgPricesDesc: "This chart shows the average price profile of the wholesale markets over the selected period. It is useful to compare this with the chart above. This way you can verify if your main volume matches with high or low prices on the day ahead market. Based on that you could decide to try to shift some load to time periods with a cheaper day ahead market prices.",
                volume2: "volume",
                average: "Average",
                price: "price",
                peak: "Maximum",
                peakValueInfo1: "Maximum volume in the selected period on",
                peakValueInfo2: "for the quarter hour",
                noPeak: "There's no peak value",
                print: "Print",
                report: "report",
                noData: "No data found for the selected period.",
                compareInfo: "Select the type of comparison",
                compareFixed: "Enter fixed prices",
                compareVariable: "Enter price formulas",
                compareManual: "Enter the peak and off-peak prices manually in the table below, calculations will be done automatically",
                compare: "Compare",
                type: "Type",
                peakPrice: "Peak price",
                offpeakPrice: "Off-peak price",
                peakFormula: "Peak formula",
                offpeakFormula: "Off-peak formula",
                results: "Simulation results",
                compareResults: "Comparison results",
                savingsInfoSupply: "If positive Yuso's offer is better",
                savingsInfoInjection: "If negative Yuso's offer is better",
                relative: "Relative",
                difference: "difference",
                compareValuesHelp: "Supported variables to be used in the formulas are:",
                compareDayAheadPricesHourly: "Day ahead market hourly price",
                compareDayAheadPricesMonthly: "Day ahead market monthly average price",
                compareDayAheadPeakPricesMonthly: "Day ahead market monthly average peak price",
                compareDayAheadOffpeakPricesMonthly: "Day ahead market monthly average offpeak price",
                compareImbalancePrices: "Quarter hour Elia imbalance prices",
                compareEndexMonthly: "Endex monthly: Price applicable for a month and equal to the average of the daily ICE baseload electricity price on the Belgian market for month M quoted during all calendar days of the preceding month M-1",
                compareEndexQuarterly: "Endex quarterly: Price applicable for a quarter and equal to the average of the daily ICE baseload electricity price on the Belgian market for quarter Q quoted during all calendar days of the preceding quarter Q-1",
                compareEBIQ: "EBIQ index: Price applicable for a quarter and equal to the average of the daily ICE baseload electricity price on the Belgian market for quarter Q quoted during the period starting on the 15th calendar day of the month M-2 up to and including the 14th calendar day of the month M-1, where the month M is the first month of the relevant quarter",
                xlsx_peak: "Peak hours",
                xlsx_offpeak: "Off-peak hours",
                xlsx_total: "Total",
                xlsx_month: "Month",
                xlsx_fee: "Platform fee",
                xlsx_volume: "Volume",
                xlsx_avg: "Avg. price",
                xlsx_difference: "Difference",
                xlsx_proposal: "Proposal",
                xlsx_comparison: "Comparison",
                xlsx_supply: "Supply",
                xlsx_injection: "Injection",
                error_0: "Only one variable is supported",
                error_1: "Invalid formula",
                error_2: "Division by zero",
                error_3: "Unknown function or variable name",
                error_4: "Value not available in formula",
                error_5: "Invalid number in formula",
                error_6: "Wrong argument type in formula",
                required: "Field is required",
                offtake: {
                    cost: "Cost",
                    cost2: "cost",
                    xlsx_cost: "Cost"
                },
                injection: {
                    cost: "Income",
                    cost2: "income",
                    xlsx_cost: "Income"
                }
            },
            nl: {
                loading: "Laden...",
                loadingEans: "EANs laden...",
                ean: "EAN",
                startDate: "Begin maand",
                endDate: "Einde maand",
                peakHours: "Piek",
                offpeakHours: "Daluren",
                total: "Totaal",
                month: "Maand",
                hour: "Uur",
                fee: "Yuso platformvergoeding",
                simulate: "Simuleren",
                fixed: "Vast tarief",
                variable: "Variabel",
                manual: "Handmatig",
                simulating: "Simuleren...",
                comparing: "Berekenen...",
                volume: "Volume",
                avg: "Gem. prijs",
                export: "Excel Exporteren",
                simulation: "Simulatie",
                monthlyVolumes: "Maandelijkse volumes",
                monthlyVolumesDesc: "Deze grafiek toont de volumes per maand opgesplitst in piek-en daluren volumes.",
                peakVolume: "Piekvolume",
                offpeakVolume: "Offpeak volume",
                totalVolume: "Totale volume",
                monthlyAvgPrices: "Maandelijkse gemiddelde prijzen",
                monthlyAvgPricesDesc: "De grafiek toont de gemiddelde day ahead marktprijzen en uw gemiddelde Yuso-prijs. Het verschil tussen de twee wordt veroorzaakt door twee elementen:",
                monthlyAvgPricesDesc1: "De Yuso-prijs is inclusief de platformvergoeding die alle yuso-kosten dekt. Dit is slechts een vaste waarde per MW, dus het verschuift gewoon de day ahead marktprijzen-curve op de verticale assen.",
                monthlyAvgPricesDesc2_injection: "Uw gemiddelde Yuso-prijs is volumegewogen. Als u tijdens hoge-prijsuren veel produceert, dan zal uw gemiddelde Yuso-prijs hoger liggen dan het day ahead marktprijzen-gemiddelde. In het geval van een optimaal productiepatroon kan uw Yuso-prijs dus hoger worden dan de gemiddelde day ahead marktprijzen.",
                monthlyAvgPricesDesc2_offtake: "Uw gemiddelde Yuso-prijs is volumegewogen. Als u tijdens lage-prijsuren veel consumeert, dan zal uw gemiddelde Yuso-prijs lager liggen dan het day ahead marktprijzen-gemiddelde. In het geval van een optimaal verbruikspatroon kan uw Yuso-prijs dus lager worden dan de gemiddelde day ahead marktprijzen.",
                averagePrice: "Gem. Yuso prijs",
                averageDayAheadPrices: "Gem. day ahead marktprijzen",
                averageCompare: "Gem. prijs vergelijken",
                hourlyAvgVolumes: "Gem. volume per uur",
                hourlyAvgVolumesDesc: "Deze grafiek toont het gemiddelde profiel van uw consumptie of injectie gedurende een dag doorheen de geselecteerde periode.",
                averageVolume: "Gemiddeld volume",
                hourlyAvgPrices: "Uurgemiddelde day ahead marktprijzen",
                hourlyAvgPricesDesc: "Deze grafiek toont het gemiddelde prijsprofiel van de groothandelsmarkt over de geselecteerde periode. Het is handig om dit te vergelijken met de bovenstaande grafiek. Op deze manier kunt u controleren of uw volume overeenkomt met hoge of lage prijzen op day ahead marktprijzen. Op basis daarvan kunt u besluiten om verbruik proberen te verschuiven naar tijdsperioden met een goedkopere day ahead marktprijzen.",
                volume2: "volume",
                average: "Gemiddelde",
                price: "prijs",
                peak: "Maximum",
                peakValueInfo1: "Maximaal volume in de geselecteerde periode op",
                peakValueInfo2: "voor het kwartier",
                noPeak: "Er is geen piekwaarde",
                print: "Print",
                report: "rapport",
                noData: "Geen gegevens gevonden voor de geselecteerde periode.",
                compareInfo: "Selecteer het type vergelijking",
                compareFixed: "Voer vaste prijzen in",
                compareVariable: "Voer prijsformules in",
                compareManual: "Voer de piek en dalprijzen handmatig in de onderstaande tabel in, de berekeningen worden automatisch uitgevoerd",
                compare: "Vergelijken",
                type: "Type",
                peakPrice: "Piekprijs",
                offpeakPrice: "Dalprijs",
                peakFormula: "Piekformule",
                offpeakFormula: "Daluren formule",
                results: "Simulatie resultaten",
                compareResults: "Vergelijkingsresultaten",
                savingsInfoSupply: "Indien positief is Yuso tarief beter",
                savingsInfoInjection: "Indien negatief is Yuso tarief beter",
                relative: "Procentueel",
                difference: "verschil",
                compareValuesHelp: "Ondersteunde variabelen die in de formules kunnen worden gebruikt, zijn:",
                compareDayAheadPricesHourly: "Day ahead markt uurprijs",
                compareDayAheadPricesMonthly: "Day ahead markt maandelijkse gemiddelde prijs",
                compareDayAheadPeakPricesMonthly: "Day ahead markt maandelijkse gemiddelde piekprijs",
                compareDayAheadOffpeakPricesMonthly: "Day ahead markt maandelijkse gemiddelde dalprijs",
                compareImbalancePrices: "Elia imbalans prijzen per kwartier",
                compareEndexMonthly: "Endex maandelijks: prijs van toepassing voor een maand en gelijk aan het gemiddelde van de dagelijkse ICE-basislaststroomprijs op de Belgische markt voor maand M genoteerd tijdens alle kalenderdagen van de voorafgaande maand M-1",
                compareEndexQuarterly: "Endex driemaandelijks: prijs van toepassing voor een kwartaal en gelijk aan het gemiddelde van de dagelijkse ICE-basislaststroomprijs op de Belgische markt voor kwartaal Q genoteerd tijdens alle kalenderdagen van het voorgaande kwartaal Q-1",
                compareEBIQ: "EBIQ-index: prijs van toepassing voor een kwartaal en gelijk aan het gemiddelde van de dagelijkse ICE-basislaststroomprijs op de Belgische markt voor kwartaal Q genoteerd in de periode vanaf de 15e kalenderdag van de maand M-2 tot en met de 14e kalender dag van de maand M-1, waarbij de maand M de eerste maand van het betreffende kwartaal is",
                xlsx_peak: "Piek",
                xlsx_offpeak: "Daluren",
                xlsx_total: "Totaal",
                xlsx_month: "Maand",
                xlsx_fee: "Platformkosten",
                xlsx_volume: "Volume",
                xlsx_avg: "Gem. prijs",
                xlsx_difference: "Verschil",
                xlsx_proposal: "Voorstel",
                xlsx_comparison: "Vergelijking",
                xlsx_supply: "Afname",
                xlsx_injection: "Injectie",
                error_0: "Er wordt slechts één variabele ondersteund",
                error_1: "Ongeldige formule",
                error_2: "Deling door nul",
                error_3: "Onbekende functie of variabelenaam",
                error_4: "Waarde niet beschikbaar in formule",
                error_5: "Ongeldig nummer in formule",
                error_6: "Onjuist argumenttype in formule",
                required: "Veld is verplicht",
                offtake: {
                    cost: "Kosten",
                    cost2: "kosten",
                    xlsx_cost: "Kosten"
                },
                injection: {
                    cost: "Inkomsten",
                    cost2: "inkomsten",
                    xlsx_cost: "Inkomsten"
                }
            }
        }, this.props.lang);

        this.errors = {
            '#MULVAR!': this.strings.error_0,
            '#ERROR!': this.strings.error_1,
            '#DIV/0!': this.strings.error_2,
            '#NAME?': this.strings.error_3,
            '#N/A': this.strings.error_4,
            '#NUM!': this.strings.error_5,
            '#VALUE!': this.strings.error_6
        }
    }

    componentDidMount() {
        if (!this.props.selectedCompany) return;

        getSdps(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                let sdps = res.sdps_offtake_hourly.concat(res.sdps_injection_hourly)

                // select the first ean as default
                let selectedEan = sdps[0] || {}

                this.setState({
                    sdps,
                    selectedEan,
                    ean: selectedEan.ean,
                    installation_id: selectedEan.installation_id,
                    fee: selectedEan.balancingFee
                }, () => {
                    this.onFieldChange({ target: { name: 'installation_id', value: selectedEan.installation_id } })
                });
            }
        );
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    onFieldChange(e) {
        let field = e.target.name
        let value = e.target.value

        this.setState({
            [field]: value
        }, () => {

            switch (field) {
                case 'installation_id':
                    let sdp = this.state.sdps.find(x => x.installation_id === value)

                    this.setState({
                        selectedEan: sdp || {},
                        ean: sdp.ean,
                        periods: undefined,
                        simulation: undefined,
                        compareType: ''
                    });

                    if (sdp)
                        getPeriods(this.props.selectedCompany.company_id, sdp.ean, sdp.type_sdp, (err, res) => {
                            if (err)
                                return window.reportError.send(err);

                            let periods = res

                            // by default select the previous year if possible
                            let startDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM')
                            let endDate = moment().subtract(1, 'year').endOf('year').format('YYYY-MM')

                            // try selecting the previous 12 months
                            if (periods.indexOf(startDate) === -1 || periods.indexOf(endDate) === -1) {
                                startDate = moment().subtract(13, 'months').startOf('month').format('YYYY-MM')
                                endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM')
                            }

                            if (periods.indexOf(startDate) === -1) {
                                startDate = periods[0]
                            }

                            if (periods.indexOf(endDate) === -1) {
                                endDate = periods[periods.length - 1]
                            }

                            this.setState({
                                periods,
                                startDate,
                                endDate
                            });
                        })

                    break;

                case 'startDate':
                    if (moment(value, 'YYYY-MM') > moment(this.state.endDate, 'YYYY-MM')) {
                        this.setState({ endDate: value });
                    }

                    break;

                case 'compareType':

                    let simulation = [...this.state.simulation]

                    simulation.forEach(x => {
                        x.compare_peak_avg = null
                        x.compare_peak_cost = null
                        x.compare_offpeak_avg = null
                        x.compare_offpeak_cost = null
                        x.compare_total_avg = null
                        x.compare_total_cost = null
                    })

                    this.setState({
                        simulation
                    })

                    break;

                default:
                    break;
            }
        })
    }

    onSimulate() {
        this.setState({ simulating: true, compareType: '', comparing: false })

        simultateContract(this.props.selectedCompany.company_id, this.state, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                ...res,
                simulating: false
            });
        })
    }

    isDisabled() {
        return !this.state.selectedEan.ean || !this.state.startDate || !this.state.endDate || !this.state.fee
    }

    monthFormatter(label) {
        return this.strings.month + ': ' + label
    }

    hourFormatter(label) {
        return this.strings.hour + ': ' + moment(label, 'H:mm').format('HH:00') + ' - ' + moment(label, 'H:mm').add(1, 'hour').format('HH:00')
    }

    async onExport() {

        let workbook = await XlsxPopulate.fromBlankAsync()

        let sheet = workbook.sheet(0)

        sheet.name(this.state.selectedEan.type_sdp === 'offtake' ? this.strings.xlsx_supply : this.strings.xlsx_injection)
        sheet.gridLinesVisible(false)

        sheet.column('A').style({ horizontalAlignment: 'center' })

        let simulation = this.state.simulation

        // if we have comparison results prepare different Excel
        if (this.state.compareType) {

            let excel = simulation.map(x => [
                x.period,
                parseFloat(x.peak_volume.toFixed(2)),
                parseFloat(x.peak_cost.toFixed(2)),
                parseFloat(x.peak_avg.toFixed(2)),
                parseFloat(x.compare_peak_cost ? x.compare_peak_cost.toFixed(2) : 0),
                parseFloat(x.compare_peak_avg ? x.compare_peak_avg.toFixed(2) : 0),

                parseFloat(x.offpeak_volume.toFixed(2)),
                parseFloat(x.offpeak_cost.toFixed(2)),
                parseFloat(x.offpeak_avg.toFixed(2)),
                parseFloat(x.compare_offpeak_cost ? x.compare_offpeak_cost.toFixed(2) : 0),
                parseFloat(x.compare_offpeak_avg ? x.compare_offpeak_avg.toFixed(2) : 0),

                parseFloat(x.total_volume.toFixed(2)),
                parseFloat(x.total_cost.toFixed(2)),
                parseFloat(x.total_avg.toFixed(2)),
                parseFloat(x.compare_total_cost ? x.compare_total_cost.toFixed(2) : 0),
                parseFloat(x.compare_total_avg ? x.compare_total_avg.toFixed(2) : 0),

                (parseFloat(x.compare_total_cost ? x.compare_total_cost.toFixed(2) : 0) - parseFloat(x.total_cost.toFixed(2))).toFixed(2)
            ])

            excel.unshift([
                this.strings.xlsx_month,
                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',

                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',

                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',

                this.strings.xlsx_difference + ' (€)'
            ])

            excel.unshift([
                '',
                '',
                this.strings.xlsx_proposal,
                '',
                this.strings.xlsx_comparison,
                '',
                '',
                this.strings.xlsx_proposal,
                '',
                this.strings.xlsx_comparison,
                '',
                '',
                this.strings.xlsx_proposal,
                '',
                this.strings.xlsx_comparison,
                '',
                this.strings.xlsx_difference
            ])

            excel.unshift([
                '',
                this.strings.xlsx_peak,
                '',
                '',
                '',
                '',
                this.strings.xlsx_offpeak,
                '',
                '',
                '',
                '',
                this.strings.xlsx_total,
                '',
                '',
                '',
                '',
                ''
            ])

            excel.push([
                this.strings.xlsx_total,
                parseFloat(this.state.totals.peak_volume.toFixed(2)),
                parseFloat(this.state.totals.peak_cost.toFixed(2)),
                parseFloat(this.state.totals.peak_avg.toFixed(2)),
                parseFloat(this.state.totals.compare_peak_cost ? this.state.totals.compare_peak_cost.toFixed(2) : 0),
                parseFloat(this.state.totals.compare_peak_avg ? this.state.totals.compare_peak_avg.toFixed(2) : 0),

                parseFloat(this.state.totals.offpeak_volume.toFixed(2)),
                parseFloat(this.state.totals.offpeak_cost.toFixed(2)),
                parseFloat(this.state.totals.offpeak_avg.toFixed(2)),
                parseFloat(this.state.totals.compare_offpeak_cost ? this.state.totals.compare_offpeak_cost.toFixed(2) : 0),
                parseFloat(this.state.totals.compare_offpeak_avg ? this.state.totals.compare_offpeak_avg.toFixed(2) : 0),

                parseFloat(this.state.totals.total_volume.toFixed(2)),
                parseFloat(this.state.totals.total_cost.toFixed(2)),
                parseFloat(this.state.totals.total_avg.toFixed(2)),
                parseFloat(this.state.totals.compare_total_cost ? this.state.totals.compare_total_cost.toFixed(2) : 0),
                parseFloat(this.state.totals.compare_total_avg ? this.state.totals.compare_total_avg.toFixed(2) : 0),

                (parseFloat(this.state.totals.compare_total_cost ? this.state.totals.compare_total_cost.toFixed(2) : 0) - parseFloat(this.state.totals.total_cost.toFixed(2))).toFixed(2)
            ])

            sheet.cell('A1').value(excel)
                .style({
                    horizontalAlignment: 'right',
                    verticalAlignment: 'center',
                    fontFamily: 'Arial',
                    fontGenericFamily: 2,
                    border: 'thin',
                    borderColor: 'dddddd',
                    numberFormat: '@'
                })

            const lastRow = sheet.usedRange().endCell().row().rowNumber()

            sheet.usedRange().forEach(cell => {
                cell.column().width(16)
                cell.row().height(20)

                if (['A', 'F', 'K', 'Q'].indexOf(cell.columnName()) > -1) {
                    cell.style({ rightBorder: 'medium', rightBorderColor: '000000' })
                }

                if (cell.rowNumber() === lastRow) {
                    cell.style({ bold: true, topBorder: 'medium', topBorderColor: '000000', bottomBorder: 'medium', bottomBorderColor: '000000' })
                }
            })

            // headers
            sheet.range('B1:F1').merged(true)
            sheet.range('G1:K1').merged(true)
            sheet.range('L1:Q1').merged(true)

            // sub-headers
            sheet.range('C2:D2').merged(true)
            sheet.range('E2:F2').merged(true)
            sheet.range('H2:I2').merged(true)
            sheet.range('J2:K2').merged(true)
            sheet.range('M2:N2').merged(true)
            sheet.range('O2:P2').merged(true)

            sheet.range('A1:Q2').style({ horizontalAlignment: 'center', bold: true, topBorder: 'medium', topBorderColor: '000000', bottomBorder: 'medium', bottomBorderColor: '000000' })

            sheet.range(`B2:B${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`D2:D${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`G2:G${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`I2:I${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`L2:L${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`N2:N${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`P2:P${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })

            sheet.range(`A1:A${lastRow}`).style({ horizontalAlignment: 'center', leftBorder: 'medium', leftBorderColor: '000000' })

            const lastCell = sheet.usedRange().endCell().address()

            sheet.range(`B4:${lastCell}`).style({ numberFormat: '#,##0.00' })
        }
        // else if we don't comparison results don't include them in the Excel
        else {

            let excel = simulation.map(x => [
                x.period,
                parseFloat(x.peak_volume.toFixed(2)),
                parseFloat(x.peak_cost.toFixed(2)),
                parseFloat(x.peak_avg.toFixed(2)),
                parseFloat(x.offpeak_volume.toFixed(2)),
                parseFloat(x.offpeak_cost.toFixed(2)),
                parseFloat(x.offpeak_avg.toFixed(2)),
                parseFloat(x.total_volume.toFixed(2)),
                parseFloat(x.total_cost.toFixed(2)),
                parseFloat(x.total_avg.toFixed(2))
            ])

            excel.unshift([
                this.strings.xlsx_month,
                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',
                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)',
                this.strings.xlsx_volume + ' (kWh)',
                this.strings[this.state.selectedEan.type_sdp].xlsx_cost + ' (€)',
                this.strings.xlsx_avg + ' (€/MWh)'
            ])

            excel.unshift([
                '',
                this.strings.xlsx_peak,
                '',
                '',
                this.strings.xlsx_offpeak,
                '',
                '',
                this.strings.xlsx_total,
                '',
                ''
            ])

            excel.push([
                this.strings.xlsx_total,
                parseFloat(this.state.totals.peak_volume.toFixed(2)),
                parseFloat(this.state.totals.peak_cost.toFixed(2)),
                parseFloat(this.state.totals.peak_avg.toFixed(2)),
                parseFloat(this.state.totals.offpeak_volume.toFixed(2)),
                parseFloat(this.state.totals.offpeak_cost.toFixed(2)),
                parseFloat(this.state.totals.offpeak_avg.toFixed(2)),
                parseFloat(this.state.totals.total_volume.toFixed(2)),
                parseFloat(this.state.totals.total_cost.toFixed(2)),
                parseFloat(this.state.totals.total_avg.toFixed(2))
            ])

            sheet.cell('A1').value(excel)
                .style({
                    horizontalAlignment: 'right',
                    verticalAlignment: 'center',
                    fontFamily: 'Arial',
                    fontGenericFamily: 2,
                    border: 'thin',
                    borderColor: 'cccccc',
                    numberFormat: '@'
                })

            const lastRow = sheet.usedRange().endCell().row().rowNumber()

            sheet.usedRange().forEach(cell => {
                cell.column().width(16)
                cell.row().height(20)

                if (['A', 'D', 'G', 'J'].indexOf(cell.columnName()) > -1) {
                    cell.style({ rightBorder: 'medium', rightBorderColor: '000000' })
                }

                if (cell.rowNumber() === lastRow) {
                    cell.style({ bold: true, topBorder: 'medium', topBorderColor: '000000', bottomBorder: 'medium', bottomBorderColor: '000000' })
                }
            })

            // headers
            sheet.range('B1:D1').merged(true)
            sheet.range('E1:G1').merged(true)
            sheet.range('H1:J1').merged(true)

            sheet.range('A1:J1').style({ horizontalAlignment: 'center', bold: true, topBorder: 'medium', topBorderColor: '000000', bottomBorder: 'medium', bottomBorderColor: '000000' })

            sheet.range(`B2:B${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`E2:E${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })
            sheet.range(`H2:H${lastRow}`).style({ rightBorder: 'medium', rightBorderColor: 'aaaaaa' })

            sheet.range(`A1:A${lastRow}`).style({ horizontalAlignment: 'center', leftBorder: 'medium', leftBorderColor: '000000' })

            const lastCell = sheet.usedRange().endCell().address()

            sheet.range(`B3:${lastCell}`).style({ numberFormat: '#,##0.00' })
        }

        const xlsx = await workbook.outputAsync()

        if (xlsx) {

            var blob = xlsx // Excel XLSX file

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `${this.state.selectedEan.ean}-${this.strings.simulation}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    getMaxValueTooltip() {
        if (!this.state.peak_qh)
            return this.strings.noPeak

        const qh = moment(this.state.peak_qh.dtlt, 'YYYY-MM-DD HH:mm:ss')

        return `${this.strings.peakValueInfo1} <strong>${qh.format('YYYY-MM-DD')}</strong> ${this.strings.peakValueInfo2} <strong>${qh.format('HH:mm')} – ${qh.add(15, 'minutes').format('HH:mm')}</strong>`
    }

    calculateTotals() {

        if (this.timer)
            clearTimeout(this.timer)

        this.timer = setTimeout(() => {

            let simulation = [...this.state.simulation]

            simulation.forEach(x => {
                x.compare_peak_cost = (x.peak_volume * x.compare_peak_avg) / 1000
                x.compare_offpeak_cost = (x.offpeak_volume * x.compare_offpeak_avg) / 1000
                x.compare_total_cost = parseFloat(x.compare_peak_cost.toFixed(5)) + parseFloat(x.compare_offpeak_cost.toFixed(5))

                x.compare_total_avg = parseFloat(((x.compare_total_cost / x.total_volume) * 1000).toFixed(2))
            })

            let totals = {
                ...this.state.totals,
                compare_total_cost: simulation.reduce((acc, cur) => acc + cur.compare_total_cost, 0),
                compare_peak_cost: simulation.reduce((acc, cur) => acc + cur.compare_peak_cost, 0),
                compare_offpeak_cost: simulation.reduce((acc, cur) => acc + cur.compare_offpeak_cost, 0)
            }

            totals.compare_total_avg = (totals.compare_total_cost / totals.total_volume) * 1000
            totals.compare_peak_avg = (totals.compare_peak_cost / totals.peak_volume) * 1000
            totals.compare_offpeak_avg = (totals.compare_offpeak_cost / totals.offpeak_volume) * 1000

            this.setState({
                simulation,
                totals
            })
        }, 500);

    }

    onRemoteCompare() {

        this.setState({
            error: undefined,
            peakPriceError: undefined,
            offpeakPriceError: undefined,
            peakFormulaError: undefined,
            offpeakFormulaError: undefined,
            comparing: true
        })

        switch (this.state.compareType) {

            case 'fixed':

                if (!this.state.peakPrice) {
                    this.setState({
                        comparing: false,
                        peakPriceError: this.strings.required
                    })
                    return;
                }

                if (!this.state.offpeakPrice) {
                    this.setState({
                        comparing: false,
                        offpeakPriceError: this.strings.required
                    })
                    return;
                }

                break;

            case 'variable':

                if (!this.state.peakFormula) {
                    this.setState({
                        comparing: false,
                        peakFormulaError: this.strings.required
                    })
                    return;
                }

                if (!this.state.offpeakFormula) {
                    this.setState({
                        comparing: false,
                        offpeakFormulaError: this.strings.required
                    })
                    return;
                }

                break;

            default:
                break;
        }

        comparePrices(this.props.selectedCompany.company_id, this.state, (err, res) => {
            if (err)
                return window.reportError.send(err);

            if (res.error) {
                this.setState({
                    ...res,
                    [res.error.name]: this.errors[res.error.code],
                    comparing: false
                }, this.calculateTotals);
            }

            this.setState({
                ...res,
                comparing: false
            }, this.calculateTotals);
        })
    }

    manualPrice(e, s) {
        let simulation = [...this.state.simulation]

        let sim = simulation.find(x => x.period === s.period)

        let value = e.target.value

        switch (e.target.name) {
            case 'comparePeakPrice':
                sim.compare_peak_avg = parseFloat(value)
                break;

            case 'compareOffpeakPrice':
                sim.compare_offpeak_avg = parseFloat(value)
                break;

            default:
                break;
        }

        this.setState({
            simulation
        }, this.calculateTotals)
    }

    render() {
        return (
            <div>
                <div className="text-center show-only-print">
                    <img src={`${process.env.PUBLIC_URL}/yuso-logo.png`} alt="Yuso" className="my-4" />

                    <div className="d-flex align-items-center mb-3">
                        <hr className="border-warning w-100" />
                        <h3 className="text-primary text-nowrap mx-5">{this.strings.results}</h3>
                        <hr className="border-warning w-100" />
                    </div>
                </div>

                <div className="show-only-print">
                    <div className="d-flex justify-content-center align-items-center mb-4">
                        <span className="sim-param">{this.strings.ean}:</span>
                        <span className="sim-val">{this.state.selectedEan.ean}</span>

                        <span className="sim-sep"></span>

                        <span className="sim-param">{this.strings.startDate}:</span>
                        <span className="sim-val">{this.state.startDate}</span>

                        <span className="sim-sep"></span>

                        <span className="sim-param">{this.strings.endDate}:</span>
                        <span className="sim-val">{this.state.endDate}</span>

                        <span className="sim-sep"></span>

                        <span className="sim-param">{this.strings.fee}:</span>
                        <span className="sim-val">{this.state.fee} €/MWh</span>
                    </div>
                </div>

                <div className="hide-print">
                    <div className="row">
                        <div className="col-sm py-2 mb-4">
                            <form className="form-inline d-flex flex-column flex-md-row justify-content-center align-items-center simulate-form" onSubmit={(e) => { e.preventDefault() }}>

                                {
                                    !this.state.sdps ?
                                        <div className="mb-2 mr-sm-2">
                                            <Loader inline="true">{this.strings.loadingEans}</Loader>
                                        </div>
                                        :
                                        <select className="form-control mb-2 mr-sm-2" style={{ width: '300px' }} name="installation_id" id="installation_id" value={this.state.selectedEan.installation_id || ''} onChange={this.onFieldChange}>
                                            <option value=""></option>
                                            {
                                                this.state.sdps.filter(sdp => sdp.hasContract).map(sdp => {
                                                    return <option key={sdp.ean} value={sdp.installation_id}>{sdp.ean} ({sdp.installation_id})</option>
                                                })
                                            }
                                        </select>
                                }
                                <select className="form-control mb-2 mr-sm-2" style={{ width: '100px' }} name="startDate" id="startDate" value={this.state.startDate || ''} onChange={this.onFieldChange} disabled={!this.state.periods} title={this.strings.startDate}>
                                    <option value="">{this.state.selectedEan.ean && !this.state.periods ? this.strings.loading : ''}</option>
                                    {
                                        this.state.periods && this.state.periods.map(period => {
                                            return <option key={period} value={period}>{period}</option>
                                        })
                                    }
                                </select>
                                <select className="form-control mb-2 mr-sm-2" style={{ width: '100px' }} name="endDate" id="endDate" value={this.state.endDate || ''} onChange={this.onFieldChange} disabled={!this.state.periods} title={this.strings.endDate}>
                                    <option value="">{this.state.selectedEan.ean && !this.state.periods ? this.strings.loading : ''}</option>
                                    {
                                        this.state.periods && this.state.periods.map(period => {
                                            return <option key={period} value={period} disabled={moment(period, 'YYYY-MM') < moment(this.state.startDate, 'YYYY-MM')}>{period}</option>
                                        })
                                    }
                                </select>
                                <NumberInput className="form-control mb-2 mr-sm-2 text-right" style={{ width: '80px' }} type="number" name="fee" id="fee" value={this.state.fee || ''} onChange={this.onFieldChange} disabled={!this.state.selectedEan.ean} placeholder={this.strings.fee} title={this.strings.fee} />
                                <button className="btn btn-primary btn-picker mb-2" type="button" name="simulate" id="simulate" onClick={this.onSimulate} disabled={this.isDisabled()}>
                                    {this.strings.simulate} <i className="fas fa-chevron-right ml-1"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        {
                            this.state.simulating ?
                                <Loader>{this.strings.simulating}</Loader>
                                :
                                this.state.simulation && !this.state.simulation.length ?
                                    <p className="alert alert-warning">
                                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.noData}
                                    </p>
                                    :
                                    this.state.simulation && (
                                        <div>
                                            <div className="compare-results">
                                                <div className="d-flex align-items-center mb-4">
                                                    <hr className="border-warning w-100" />
                                                    <h5 className="text-primary text-nowrap mx-5">{this.strings.compare}</h5>
                                                    <hr className="border-warning w-100" />
                                                </div>

                                                <div className="mb-2 d-flex justify-content-center">
                                                    <div className="mb-2 mr-sm-2">
                                                        <select className="form-control" id="compareType" name="compareType" value={this.state.compareType} onChange={this.onFieldChange} style={{ width: '120px' }} title={this.strings.type}>
                                                            <option value=""></option>
                                                            <option value="fixed">{this.strings.fixed}</option>
                                                            <option value="variable">{this.strings.variable}</option>
                                                            <option value="manual">{this.strings.manual}</option>
                                                        </select>
                                                    </div>

                                                    {
                                                        this.state.compareType === 'fixed' ?
                                                            [
                                                                <div className="mb-2 mr-sm-2" key={0}>
                                                                    <NumberInput className="form-control" type="number" id="peakPrice" name="peakPrice" onChange={this.onFieldChange} title={this.strings.peakPrice} style={{ width: '180px' }} placeholder={this.strings.peakPrice} />
                                                                    {
                                                                        this.state.peakPriceError ?
                                                                            <small className="form-text text-danger" style={{ width: '180px' }}>{this.state.peakPriceError}</small>
                                                                            : null
                                                                    }
                                                                </div>,
                                                                <div className="mb-2 mr-sm-2" key={1}>
                                                                    <NumberInput className="form-control" type="number" id="offpeakPrice" name="offpeakPrice" onChange={this.onFieldChange} title={this.strings.offpeakPrice} style={{ width: '180px' }} placeholder={this.strings.offpeakPrice} />
                                                                    {
                                                                        this.state.offpeakPriceError ?
                                                                            <small className="form-text text-danger" style={{ width: '180px' }}>{this.state.offpeakPriceError}</small>
                                                                            : null
                                                                    }
                                                                </div>
                                                            ]
                                                            : null
                                                    }

                                                    {
                                                        this.state.compareType === 'variable' ?
                                                            [
                                                                <div className="mb-2 mr-sm-2" key={0}>
                                                                    <input className="form-control" type="text" id="peakFormula" name="peakFormula" onChange={this.onFieldChange} title={this.strings.peakFormula} style={{ width: '180px' }} placeholder={this.strings.peakFormula} />
                                                                    {
                                                                        this.state.peakFormulaError ?
                                                                            <small className="form-text text-danger" style={{ width: '180px' }}>{this.state.peakFormulaError}</small>
                                                                            : null
                                                                    }
                                                                </div>,
                                                                <div className="mb-2 mr-sm-2" key={1}>
                                                                    <input className="form-control" type="text" id="offpeakFormula" name="offpeakFormula" onChange={this.onFieldChange} title={this.strings.offpeakFormula} style={{ width: '180px' }} placeholder={this.strings.offpeakFormula} />
                                                                    {
                                                                        this.state.offpeakFormulaError ?
                                                                            <small className="form-text text-danger" style={{ width: '180px' }}>{this.state.offpeakFormulaError}</small>
                                                                            : null
                                                                    }
                                                                </div>
                                                            ]
                                                            : null
                                                    }

                                                    {
                                                        this.state.compareType && this.state.compareType !== 'manual' ?
                                                            <div className="mb-2 mr-sm-2">
                                                                <button type="button" className="btn btn-primary btn-picker px-4" onClick={this.onRemoteCompare} disabled={this.state.comparing}>
                                                                    {this.state.comparing ? this.strings.comparing : this.strings.compare}
                                                                    &nbsp;
                                                                    {
                                                                        this.state.comparing ?
                                                                            <i className="fas fa-spinner fa-spin ml-1"></i>
                                                                            :
                                                                            <i className="fas fa-chevron-right ml-1"></i>
                                                                    }
                                                                </button>
                                                            </div>
                                                            : null
                                                    }

                                                    <div className="mb-2">
                                                        <p className="alert alert-info border border-info m-0">
                                                            <i className="fas fa-info-circle mr-2"></i>
                                                            &nbsp;
                                                            {
                                                                !this.state.compareType ? this.strings.compareInfo : null
                                                            }
                                                            {
                                                                this.state.compareType === 'manual' ? this.strings.compareManual : null
                                                            }
                                                            {
                                                                this.state.compareType === 'fixed' ? this.strings.compareFixed : null
                                                            }
                                                            {
                                                                this.state.compareType === 'variable' ? this.strings.compareVariable : null
                                                            }
                                                        </p>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.compareType === 'variable' ?
                                                        <div className="alert alert-light">
                                                            {this.strings.compareValuesHelp}
                                                            <ul>
                                                                <li>
                                                                    <code>bh</code> - {this.strings.compareDayAheadPricesHourly}
                                                                </li>
                                                                <li>
                                                                    <code>bm</code> - {this.strings.compareDayAheadPricesMonthly}
                                                                </li>
                                                                <li>
                                                                    <code>bmp</code> - {this.strings.compareDayAheadPeakPricesMonthly}
                                                                </li>
                                                                <li>
                                                                    <code>bmo</code> - {this.strings.compareDayAheadOffpeakPricesMonthly}
                                                                </li>
                                                                <li>
                                                                    <code>imb</code> - {this.strings.compareImbalancePrices}
                                                                </li>
                                                                <li>
                                                                    <code>em</code> - {this.strings.compareEndexMonthly}
                                                                </li>
                                                                <li>
                                                                    <code>eq</code> - {this.strings.compareEndexQuarterly}
                                                                </li>
                                                                <li>
                                                                    <code>ebiq</code> - {this.strings.compareEBIQ}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        : null
                                                }
                                            </div>

                                            <div className="mb-4"></div>

                                            <div className="hide-print">
                                                <div className="d-flex align-items-center mb-5">
                                                    <hr className="border-warning w-100" />
                                                    <h5 className="text-primary text-nowrap mx-5">{this.strings.results}</h5>
                                                    <hr className="border-warning w-100" />
                                                </div>
                                            </div>

                                            <div className="row mt-3 mb-5">
                                                <div className="col-sm d-flex justify-content-around flex-column flex-md-row">

                                                    <div className="mb-2 text-center">
                                                        <h5 className="text-primary font-weight-light mb-2">
                                                            {this.strings.total} <span className="font-weight-bold">{this.strings.volume2}</span>
                                                        </h5>
                                                        <h4 className="text-primary font-weight-light">
                                                            {numeral(this.state.totals.total_volume).format('0,000.00')} kWh
                                                        </h4>
                                                    </div>

                                                    <div className="border-right border-warning w-0 mb-2"></div>

                                                    <div className="mb-2 text-center">
                                                        <h5 className="text-primary font-weight-light mb-2">
                                                            {this.strings.total} <span className="font-weight-bold">{this.strings[this.state.selectedEan.type_sdp].cost2}</span>
                                                        </h5>
                                                        <h4 className="text-primary font-weight-light">
                                                            € {numeral(this.state.totals.total_cost).format('0,000.00')}
                                                        </h4>
                                                    </div>

                                                    <div className="border-right border-warning w-0 mb-2"></div>

                                                    <div className="mb-2 text-center">
                                                        <h5 className="text-primary font-weight-light mb-2">
                                                            {this.strings.average} <span className="font-weight-bold">{this.strings.price}</span>
                                                        </h5>
                                                        <h4 className="text-primary font-weight-light">
                                                            {numeral(this.state.totals.total_avg).format('0,000.00')} €/MWh
                                                        </h4>
                                                    </div>

                                                    <div className="border-right border-warning w-0 mb-2"></div>

                                                    <div className="mb-2 text-center">
                                                        <h5 className="text-primary font-weight-light mb-2">
                                                            {this.strings.peak} <span className="font-weight-bold">{this.strings.volume2}</span> <sup><i className="fas fa-info-circle text-info cursor-pointer" data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.getMaxValueTooltip()} style={{ fontSize: '1rem' }}></i></sup>
                                                        </h5>
                                                        <h4 className="text-primary font-weight-light">
                                                            {this.state.peak_qh ? numeral(this.state.peak_qh.mv).format('0,000.00') + ' kW' : 'N/A'}
                                                        </h4>
                                                    </div>

                                                    <div className="border-right border-warning w-0 mb-2 hide-print"></div>

                                                    <div className="mb-2 text-center hide-print">
                                                        <h5 className="text-primary font-weight-light mb-2">
                                                            <span className="font-weight-bold">{this.strings.print}</span> {this.strings.report}
                                                        </h5>
                                                        <h4 className="text-primary font-weight-light">
                                                            <span className="btn bg-white text-left text-primary cursor-pointer" style={{ fontSize: '1rem' }} onClick={() => {
                                                                let oldTitle = document.title
                                                                document.title += ` - ${this.props.selectedCompany.companyName} (${this.props.selectedCompany.companyNumber}) - ${this.state.selectedEan.type_sdp}`
                                                                window.print()
                                                                document.title = oldTitle
                                                            }}>
                                                                <i className="fas fa-print mr-2"></i> {this.strings.print}
                                                            </span>
                                                        </h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <table className="table table-bordered table-sm table-simulations">
                                                    <thead>
                                                        <tr>
                                                            <th className="heavy-border"></th>
                                                            <th colSpan="3" className="text-center heavy-border">{this.strings.peakHours}</th>
                                                            <th colSpan="3" className="text-center heavy-border">{this.strings.offpeakHours}</th>
                                                            <th colSpan="3" className="text-center">{this.strings.total}</th>
                                                        </tr>
                                                    </thead>
                                                    <thead className="simulations-subheader">
                                                        <tr>
                                                            <th className="heavy-border">{this.strings.month}</th>

                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                            <th className="heavy-border">{this.strings.avg}<br /><small>€/MWh</small></th>

                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                            <th className="heavy-border">{this.strings.avg}<br /><small>€/MWh</small></th>

                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                            <th>{this.strings.avg}<br /><small>€/MWh</small></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.simulation.map(s => {
                                                                return (
                                                                    <tr key={s.period}>
                                                                        <td className="heavy-border">{s.period}</td>

                                                                        <td>{numeral(s.peak_volume).format('0,000.00')}</td>
                                                                        <td>{numeral(s.peak_cost).format('0,000.00')}</td>
                                                                        <td className="heavy-border">{numeral(s.peak_avg).format('0,000.00')}</td>

                                                                        <td>{numeral(s.offpeak_volume).format('0,000.00')}</td>
                                                                        <td>{numeral(s.offpeak_cost).format('0,000.00')}</td>
                                                                        <td className="heavy-border">{numeral(s.offpeak_avg).format('0,000.00')}</td>

                                                                        <td>{numeral(s.total_volume).format('0,000.00')}</td>
                                                                        <td>{numeral(s.total_cost).format('0,000.00')}</td>
                                                                        <td>{numeral(s.total_avg).format('0,000.00')}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot className="simulations-footer">
                                                        <tr>
                                                            <th className="heavy-border">{this.strings.total}</th>

                                                            <th>{numeral(this.state.totals.peak_volume).format('0,000.00')}</th>
                                                            <th>{numeral(this.state.totals.peak_cost).format('0,000.00')}</th>
                                                            <th className="heavy-border">{numeral(this.state.totals.peak_avg).format('0,000.00')}</th>

                                                            <th>{numeral(this.state.totals.offpeak_volume).format('0,000.00')}</th>
                                                            <th>{numeral(this.state.totals.offpeak_cost).format('0,000.00')}</th>
                                                            <th className="heavy-border">{numeral(this.state.totals.offpeak_avg).format('0,000.00')}</th>

                                                            <th>{numeral(this.state.totals.total_volume).format('0,000.00')}</th>
                                                            <th>{numeral(this.state.totals.total_cost).format('0,000.00')}</th>
                                                            <th>{numeral(this.state.totals.total_avg).format('0,000.00')}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                {
                                                    this.state.compareType ?
                                                        (this.state.comparing ?
                                                            <Loader>{this.strings.comparing}</Loader>
                                                            :
                                                            <div>
                                                                <div className="d-flex align-items-center my-5 compare-header">
                                                                    <hr className="border-warning w-100" />
                                                                    <h5 className="text-primary text-nowrap mx-5">{this.strings.compareResults}</h5>
                                                                    <hr className="border-warning w-100" />
                                                                </div>

                                                                {
                                                                    this.state.compareType === 'fixed' || this.state.compareType === 'variable' ?
                                                                        <div className="show-only-print">
                                                                            <div className="d-flex justify-content-center align-items-center mb-5">
                                                                                <span className="sim-param">{this.strings.type}:</span>
                                                                                <span className="sim-val">{this.strings[this.state.compareType]}</span>

                                                                                <span className="sim-sep"></span>

                                                                                {
                                                                                    this.state.compareType === 'fixed' ?
                                                                                        [
                                                                                            <span className="sim-param" key={0}>{this.strings.peakPrice}:</span>,
                                                                                            <span className="sim-val" key={1}>{this.state.peakPrice} €/MWh</span>
                                                                                        ]
                                                                                        : null
                                                                                }

                                                                                {
                                                                                    this.state.compareType === 'variable' ?
                                                                                        [
                                                                                            <span className="sim-param" key={0}>{this.strings.peakFormula}:</span>,
                                                                                            <span className="sim-val" key={1}>{this.state.peakFormula} €/MWh</span>
                                                                                        ]
                                                                                        : null
                                                                                }

                                                                                <span className="sim-sep"></span>

                                                                                {
                                                                                    this.state.compareType === 'fixed' ?
                                                                                        [
                                                                                            <span className="sim-param" key={0}>{this.strings.offpeakPrice}:</span>,
                                                                                            <span className="sim-val" key={1}>{this.state.offpeakPrice} €/MWh</span>
                                                                                        ]
                                                                                        : null
                                                                                }

                                                                                {
                                                                                    this.state.compareType === 'variable' ?
                                                                                        [
                                                                                            <span className="sim-param" key={0}>{this.strings.offpeakFormula}:</span>,
                                                                                            <span className="sim-val" key={1}>{this.state.offpeakFormula} €/MWh</span>
                                                                                        ]
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }

                                                                <div className="row mt-3 mb-5">
                                                                    <div className="col-sm d-flex justify-content-around flex-column flex-md-row">

                                                                        <div className="mb-2 text-center">
                                                                            <h5 className="text-primary font-weight-light mb-2">
                                                                                {this.strings.total} <span className="font-weight-bold">{this.strings[this.state.selectedEan.type_sdp].cost2}</span>
                                                                            </h5>
                                                                            <h4 className="text-primary font-weight-light">
                                                                                {this.state.totals.compare_total_cost ? '€ ' + numeral(this.state.totals.compare_total_cost).format('0,000.00') : 'N/A'}
                                                                            </h4>
                                                                        </div>

                                                                        <div className="border-right border-warning w-0 mb-2"></div>

                                                                        <div className="mb-2 text-center">
                                                                            <h5 className="text-primary font-weight-light mb-2">
                                                                                {this.strings.average} <span className="font-weight-bold">{this.strings.price}</span>
                                                                            </h5>
                                                                            <h4 className="text-primary font-weight-light">
                                                                                {this.state.totals.compare_total_avg ? numeral(this.state.totals.compare_total_avg).format('0,000.00') + ' €/MWh' : 'N/A'}
                                                                            </h4>
                                                                        </div>

                                                                        <div className="border-right border-warning w-0 mb-2"></div>

                                                                        <div className="mb-2 text-center">
                                                                            <h5 className="text-primary font-weight-light mb-2">
                                                                                <span className="font-weight-bold">{this.strings[this.state.selectedEan.type_sdp].cost}</span> {this.strings.difference} <sup><i className="fas fa-info-circle text-info cursor-pointer" data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.state.selectedEan.type_sdp === 'offtake' ? this.strings.savingsInfoSupply : this.strings.savingsInfoInjection} style={{ fontSize: '1rem' }}></i></sup>
                                                                            </h5>
                                                                            <h4 className="text-primary font-weight-light">
                                                                                {this.state.totals.compare_total_cost ? '€ ' + numeral(parseFloat(this.state.totals.compare_total_cost - this.state.totals.total_cost).toFixed(5)).format('0,000.00') : 'N/A'}
                                                                            </h4>
                                                                        </div>

                                                                        <div className="border-right border-warning w-0 mb-2"></div>

                                                                        <div className="mb-2 text-center">
                                                                            <h5 className="text-primary font-weight-light mb-2">
                                                                                <span className="font-weight-bold">{this.strings.relative}</span> {this.strings.difference} <sup><i className="fas fa-info-circle text-info cursor-pointer" data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.state.selectedEan.type_sdp === 'offtake' ? this.strings.savingsInfoSupply : this.strings.savingsInfoInjection} style={{ fontSize: '1rem' }}></i></sup>
                                                                            </h5>
                                                                            <h4 className="text-primary font-weight-light">
                                                                                {this.state.totals.compare_total_cost ? numeral(((parseFloat(this.state.totals.compare_total_cost - this.state.totals.total_cost) / this.state.totals.compare_total_cost) * 100).toFixed(5)).format('0,000.00') + ' %' : 'N/A'}
                                                                            </h4>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <table className="table table-bordered table-sm table-simulations">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="heavy-border"></th>
                                                                            <th colSpan="3" className="text-center heavy-border">{this.strings.peakHours}</th>
                                                                            <th colSpan="3" className="text-center heavy-border">{this.strings.offpeakHours}</th>
                                                                            <th colSpan="3" className="text-center">{this.strings.total}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead className="simulations-subheader">
                                                                        <tr>
                                                                            <th className="heavy-border">{this.strings.month}</th>

                                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                                            <th className="heavy-border">{this.strings.avg}<br /><small>€/MWh</small></th>

                                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                                            <th className="heavy-border">{this.strings.avg}<br /><small>€/MWh</small></th>

                                                                            <th>{this.strings.volume}<br /><small>kWh</small></th>
                                                                            <th>{this.strings[this.state.selectedEan.type_sdp].cost}<br /><small>€</small></th>
                                                                            <th>{this.strings.avg}<br /><small>€/MWh</small></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.simulation.map(s => {
                                                                                return (
                                                                                    <tr key={s.period}>
                                                                                        <td className="heavy-border">{s.period}</td>

                                                                                        <td>{numeral(s.peak_volume).format('0,000.00')}</td>
                                                                                        <td>{s.compare_peak_cost ? s.compare_peak_cost.toFixed(2) : ''}</td>
                                                                                        {
                                                                                            this.state.compareType === 'manual' ?
                                                                                                <td className="competitor-price p-0 heavy-border">
                                                                                                    <div className="d-flex">
                                                                                                        <NumberInput type="number" className="form-control" name="comparePeakPrice" value={s.compare_peak_avg ? s.compare_peak_avg : ''} onChange={e => this.manualPrice(e, s)} />
                                                                                                    </div>
                                                                                                </td>
                                                                                                :
                                                                                                <td className="heavy-border">{s.compare_peak_avg ? s.compare_peak_avg.toFixed(2) : ''}</td>
                                                                                        }

                                                                                        <td>{numeral(s.offpeak_volume).format('0,000.00')}</td>
                                                                                        <td>{s.compare_offpeak_cost ? s.compare_offpeak_cost.toFixed(2) : ''}</td>
                                                                                        {
                                                                                            this.state.compareType === 'manual' ?
                                                                                                <td className="competitor-price p-0 heavy-border">
                                                                                                    <div className="d-flex">
                                                                                                        <NumberInput type="number" className="form-control" name="compareOffpeakPrice" value={s.compare_offpeak_avg ? s.compare_offpeak_avg : ''} onChange={e => this.manualPrice(e, s)} />
                                                                                                    </div>
                                                                                                </td>
                                                                                                :
                                                                                                <td className="heavy-border">{s.compare_offpeak_avg ? s.compare_offpeak_avg.toFixed(2) : ''}</td>
                                                                                        }

                                                                                        <td>{numeral(s.total_volume).format('0,000.00')}</td>
                                                                                        <td>{s.compare_total_cost ? s.compare_total_cost.toFixed(2) : ''}</td>
                                                                                        <td>{s.compare_total_avg ? s.compare_total_avg.toFixed(2) : ''}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    <tfoot className="simulations-footer">
                                                                        <tr>
                                                                            <th className="heavy-border">{this.strings.total}</th>

                                                                            <th>{numeral(this.state.totals.peak_volume).format('0,000.00')}</th>
                                                                            <th>{this.state.totals.compare_peak_cost ? this.state.totals.compare_peak_cost.toFixed(2) : ''}</th>
                                                                            <th className="heavy-border">{this.state.totals.compare_peak_avg ? this.state.totals.compare_peak_avg.toFixed(2) : ''}</th>

                                                                            <th>{numeral(this.state.totals.offpeak_volume).format('0,000.00')}</th>
                                                                            <th>{this.state.totals.compare_offpeak_cost ? this.state.totals.compare_offpeak_cost.toFixed(2) : ''}</th>
                                                                            <th className="heavy-border">{this.state.totals.compare_offpeak_avg ? this.state.totals.compare_offpeak_avg.toFixed(2) : ''}</th>

                                                                            <th>{numeral(this.state.totals.total_volume).format('0,000.00')}</th>
                                                                            <th>{this.state.totals.compare_total_cost ? this.state.totals.compare_total_cost.toFixed(2) : ''}</th>
                                                                            <th>{this.state.totals.compare_total_avg ? this.state.totals.compare_total_avg.toFixed(2) : ''}</th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>)
                                                        : null
                                                }

                                                <div className="d-flex justify-content-center mt-4 mb-5">
                                                    <button className="btn btn-primary px-5 py-3 btn-export" onClick={this.onExport}><i className="fas fa-file-excel mr-2"></i> {this.strings.export}</button>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="page-break-avoid">
                                                    <h5 className="text-center text-info mb-3">{this.strings.monthlyVolumes} (kWh)</h5>
                                                    <ResponsiveContainer width="100%" height={300} className="mb-2">
                                                        <BarChart data={this.state.simulation} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                            <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                            <XAxis dataKey="period" minTickGap={25} />
                                                            <YAxis type="number" domain={[0, 'auto']} />
                                                            <Tooltip content={<VolumeTooltip strings={this.strings} />} />
                                                            <Legend iconType="circle" />
                                                            <Bar dataKey="peak_volume" stackId="volume" name={this.strings.peakVolume} unit="kWh" fill="#006087" />
                                                            <Bar dataKey="offpeak_volume" stackId="volume" name={this.strings.offpeakVolume} unit="kWh" fill="#93b0c5" />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                    <p className="sim-chart-desc text-center">{this.strings.monthlyVolumesDesc}</p>
                                                    <hr className="border-warning w-75 my-3" />
                                                </div>

                                                <div className="page-break-avoid">
                                                    <h5 className="text-center text-info mb-3">{this.strings.monthlyAvgPrices} (€/MWh)</h5>
                                                    <ResponsiveContainer width="100%" height={300} className="mb-2">
                                                        <LineChart data={this.state.simulation} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                            <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                            <XAxis dataKey="period" minTickGap={25} />
                                                            <YAxis type="number" domain={[0, 'auto']} />
                                                            <Tooltip labelFormatter={this.monthFormatter} />
                                                            <Legend iconType="circle" />
                                                            <Line dataKey="total_avg" type="stepAfter" name={this.strings.averagePrice} unit="€/MWh" stroke="#006087" dot={false} />
                                                            <Line dataKey="belpex_avg" type="stepAfter" name={this.strings.averageDayAheadPrices} unit="€/MWh" stroke="#93b0c5" dot={false} />
                                                            {
                                                                this.state.compareType ?
                                                                    <Line dataKey="compare_total_avg" type="stepAfter" name={this.strings.averageCompare} unit="€/MWh" stroke="#ea5e5e" dot={false} />
                                                                    : null
                                                            }
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                    <p className="sim-chart-desc">{this.strings.monthlyAvgPricesDesc}</p>
                                                    <ol className="sim-chart-desc">
                                                        <li>{this.strings.monthlyAvgPricesDesc1}</li>
                                                        <li>{this.strings[`monthlyAvgPricesDesc2_${this.state.selectedEan.type_sdp}`]}</li>
                                                    </ol>
                                                    <hr className="border-warning w-75 my-3" />
                                                </div>

                                                <div className="page-break-avoid">
                                                    <h5 className="text-center text-info mb-3">{this.strings.hourlyAvgVolumes} (kWh)</h5>
                                                    <ResponsiveContainer width="100%" height={300} className="mb-2">
                                                        <BarChart data={this.state.hourly} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                            <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                            <XAxis dataKey="period" minTickGap={25} />
                                                            <YAxis type="number" domain={[0, 'auto']} />
                                                            <Tooltip labelFormatter={this.hourFormatter} />
                                                            <Legend iconType="circle" />
                                                            <Bar dataKey="average_volume" name={this.strings.averageVolume} unit="kWh" fill="#dddc01" />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                    <p className="sim-chart-desc text-center">{this.strings.hourlyAvgVolumesDesc}</p>
                                                    <hr className="border-warning w-75 my-3" />
                                                </div>

                                                <div className="page-break-avoid">
                                                    <h5 className="text-center text-info mb-3">{this.strings.hourlyAvgPrices} (€/MWh)</h5>
                                                    <ResponsiveContainer width="100%" height={300} className="mb-2">
                                                        <LineChart data={this.state.hourly} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                            <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                            <XAxis dataKey="period" minTickGap={25} />
                                                            <YAxis type="number" domain={[0, 'auto']} />
                                                            <Tooltip labelFormatter={this.hourFormatter} />
                                                            <Legend iconType="circle" />
                                                            <Line dataKey="average_belpex" type="stepAfter" name={this.strings.averageDayAheadPrices} unit="€/MWh" stroke="#006087" dot={false} />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                    <p className="sim-chart-desc">{this.strings.hourlyAvgPricesDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractSimilator;
