import config from './config';
import { getAccessToken } from './user';


const getPendingAgreements = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getPendingAgreements/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getPendingAgreement = (company_id, type, lang, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getPendingAgreement/${company_id}/${type}/${lang}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getCompletedAgreements = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getCompletedAgreements/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getCompletedAgreement = (company_id, document_key, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getCompletedAgreement/${company_id}?document_key=${encodeURIComponent(document_key)}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getGTC = (lang, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getGTC/${lang}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getBSI = (lang, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getBSI/${lang}`, 
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                }
            ).then(
                (res) => {
                    res.blob().then(
                        (blob) => {
                            cb(null, URL.createObjectURL(blob));
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getBONWI = (lang, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getBONWI/${lang}`, 
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                }
            ).then(
                (res) => {
                    res.blob().then(
                        (blob) => {
                            cb(null, URL.createObjectURL(blob));
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getSignedMandates = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getSignedMandates/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getSignedMandate = (company_id, document_key, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getSignedMandate/${company_id}?document_key=${encodeURIComponent(document_key)}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const downloadMandate = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/documents/downloadMandate/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status !== 204)
                        res.blob()
                            .then(blob => {
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                // the filename you want
                                a.download = data.mandate || `${data.company.companyNumber}-${data.today}-mandate-Yuso-${data.company.companyName}-unsigned.pdf`;
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                            })
                    else
                        cb({ no_eans_found: true })
                })
                .catch(err => cb(err));
        }
    );
}

const getSwitchConfirmations = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getSwitchConfirmations/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getSwitchConfirmation = (company_id, document_key, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getSwitchConfirmation/${company_id}?document_key=${encodeURIComponent(document_key)}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getEocConfirmations = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getEocConfirmations/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getEocConfirmation = (company_id, document_key, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getEocConfirmation/${company_id}?document_key=${encodeURIComponent(document_key)}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const uploadSignedAgreement = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/uploadSignedAgreement/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const uploadSignedMandate = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/uploadSignedMandate/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const uploadSwitchConfirmation = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/uploadSwitchConfirmation/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getStatements = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getStatements/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getStatement = (company_id, document_key, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getStatement/${company_id}?document_key=${encodeURIComponent(document_key)}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const checkGDriveContract = (company_id, filename, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/checkGDriveContract/${company_id}/${filename}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const syncGDriveContract = (company_id, filename, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/syncGDriveContract/${company_id}/${filename}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const deleteDocument = (type, filename, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/deleteDocument/${type}/${filename}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => {
                            cb(null, res);
                        }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );

}

const getAgreementTemplate = (data, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/documents/getAgreementTemplate`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(res => {
                res.blob()
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = data.fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })

                cb(null, true);
            }).catch(err => cb(err));
        }
    );

}

export {
    getPendingAgreement,
    getPendingAgreements,
    getCompletedAgreements,
    getCompletedAgreement,
    getAgreementTemplate,
    getGTC,
    getBSI,
    getBONWI,
    getSignedMandates,
    getSignedMandate,
    downloadMandate,
    getSwitchConfirmations,
    getSwitchConfirmation,
    getEocConfirmations,
    getEocConfirmation,
    uploadSignedAgreement,
    uploadSignedMandate,
    uploadSwitchConfirmation,
    getStatements,
    getStatement,
    checkGDriveContract,
    syncGDriveContract,
    deleteDocument
};
