import config from './config';
import { getAccessToken } from './user';


const getPeriods = (company_id, ean, type, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/simulations/getPeriods/${company_id}?ean=${ean}&type=${type}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const simultateContract = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/simulations/simultateContract/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const comparePrices = (company_id, data, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/simulations/comparePrices/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

export {
    getPeriods,
    simultateContract,
    comparePrices
};
