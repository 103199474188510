import React, { Component } from 'react';
import BillingDetails from '../../pages/management/BillingDetails';
import LocalizedStrings from '../Localization';


class EditBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleEdit: false
    }

    this.toggleEdit = this.toggleEdit.bind(this);

    this.strings = LocalizedStrings({
      en: {
        billing: "Billing Information"
      },
      nl: {
        billing: "Facturatie gegevens"
      }
    }, this.props.lang);
  }

  toggleEdit() {
    this.setState({
      toggleEdit: !this.state.toggleEdit
    })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.billing}
        </div>
        <div className="card-body clearfix">
          <BillingDetails
            selectedCompany={this.props.selectedCompany}
            updateCompanies={this.props.updateCompanies}
            adminSave="true"
            edit={this.state.toggleEdit}
            toggleEdit={this.toggleEdit}
            lang={this.props.lang}
          />
        </div>
      </div>
    );
  }
}

export default EditBilling;
