import React, { Component } from 'react';
import moment from 'moment-timezone'
import numeral from 'numeral';

import DropdownPanel from '../../components/DropdownPanel';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

moment.tz.setDefault('Europe/Brussels')


class Flexibility extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false
        }

        this.toggleDetails = this.toggleDetails.bind(this);

        this.strings = LocalizedStrings({
            en: {
                fetching: "Fetching flexibility data...",
                notConnected: "You have not yet connected flexibility to Yuso",
                flexibilityDesc: "A flexibility system, linked to local production, can avoid distribution and transport costs of your energy. Yuso helps you with dimensioning, financing and market-driven control of your flexibility system. Interested? Contact us at",
                technical: "Technical data",
                flexibility: "of the flexibility",
                more: "More",
                less: "Less",
                details: "details",
                name: "Name",
                batterySupplier: "Battery Integrator",
                power: "Nominal power",
                capacity: "Nominal energy content",
                maxDischargeRate: "Maximal Depth of Discharge",
                type: "Type",
                address: "Address",
                contract: "Contract parameters",
                fixedFee: "Fixed Remuneration",
                perMonth: "per month",
                yusoFee: "Yuso Performance Fee",
                startDate: "Start date",
                endDate: "End date",
                view: "View flexibility data"
            },
            nl: {
                fetching: "Flexibiliteitsgegevens ophalen ...",
                notConnected: "Je hebt flexibiliteit nog niet aan Yuso gekoppeld",
                flexibilityDesc: "Een flexibiliteitssysteem, gekoppeld aan lokale productie, kan distributie en transportkosten van uw energie vermijden. Yuso helpt u bij dimensionering, financiering en marktgestuurde controle van uw flexibiliteitssysteem. Geïnteresseerd? Contacteer ons op",
                technical: "Technische data",
                flexibility: "van de flexibiliteit",
                more: "Meer",
                less: "Minder",
                details: "gegevens",
                name: "Naam",
                batterySupplier: "Batterijleverancier",
                power: "Nominaal vermogen batterijsysteem",
                capacity: "Nominale energie-inhoud",
                maxDischargeRate: "Maximale Ontladingsgraad",
                type: "Type",
                address: "Adres",
                contract: "Contract parameters",
                fixedFee: "Vaste vergoeding",
                perMonth: "per maand",
                yusoFee: "Yuso Prestatievergoeding",
                startDate: "Startdatum",
                endDate: "Einddatum",
                view: "Bekijk flexibiliteitsgegevens"
            }
        }, this.props.lang);
    }

    toggleDetails() {
        this.setState({
            showDetails: !this.state.showDetails
        })
    }

    render() {
        if (!this.props.sdps) {
            return (
                <div className="mb-3">
                    <Loader inline="true">{this.strings.fetching}</Loader>
                </div>
            );
        }

        if (this.props.sdps.flexibility.length === 0) {
            return <div>
                <h4>{this.strings.notConnected}</h4>
                <p>
                    {this.strings.flexibilityDesc} <a href="mailto:info@yuso.be">info@yuso.be</a>.
                </p>
            </div>
        }

        return (
            <div>
                <div className="list-group">
                    {
                        this.props.sdps.flexibility.map(
                            (flexibility) => {
                                return (
                                    <DropdownPanel
                                        key={flexibility.id}
                                        sdp={flexibility}
                                        isCollapsed="true"
                                        flexibility={true}
                                        ean={flexibility.name}
                                        address={`${flexibility.addressStreet} ${flexibility.addressNumber}, ${flexibility.addressZip} ${flexibility.addressCity}, ${flexibility.addressCountry}`}
                                        selectedCompany={this.props.selectedCompany}
                                        lang={this.props.lang}
                                    >
                                        <div className="d-flex align-items-center mt-4 mb-3">
                                            <h4 className="text-primary font-weight-light m-0"><strong>{this.strings.technical}</strong> {this.strings.flexibility}</h4>
                                            <span className="link text-secondary cursor-pointer text-underline ml-auto" onClick={this.toggleDetails}>{(!this.state.showDetails ? this.strings.more : this.strings.less)} {this.strings.details}</span>
                                        </div>
                                        <table className="table table-striped table-sm">
                                            <tbody>
                                                <tr>
                                                    <td>{this.strings.name}</td><td>{flexibility.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>{this.strings.batterySupplier}</td><td>{flexibility.asset_specs.producer}</td>
                                                </tr>
                                                {
                                                    this.state.showDetails ?
                                                        (
                                                            <tr>
                                                                <td>{this.strings.power}</td><td>{flexibility.asset_specs.nominal_power / 1000} MW</td>
                                                            </tr>
                                                        ) : null
                                                }
                                                {
                                                    this.state.showDetails ?
                                                        (
                                                            <tr>
                                                                <td>{this.strings.capacity}</td><td>{flexibility.asset_specs.nominal_energy / 1000} MWh</td>
                                                            </tr>
                                                        ) : null
                                                }
                                                {
                                                    this.state.showDetails && flexibility.asset_specs.DoD !== undefined ?
                                                        (
                                                            <tr>
                                                                <td>{this.strings.maxDischargeRate}</td><td>{flexibility.asset_specs.DoD}%</td>
                                                            </tr>
                                                        ) : null
                                                }
                                                {
                                                    this.state.showDetails ?
                                                        (
                                                            <tr>
                                                                <td>{this.strings.type}</td><td>{flexibility.asset_specs.type}</td>
                                                            </tr>
                                                        ) : null
                                                }
                                                <tr>
                                                    <td>{this.strings.address}</td>
                                                    <td>
                                                    {flexibility.addressStreet} {flexibility.addressNumber}, {flexibility.addressZip} {flexibility.addressCity},
                                                    {flexibility.addressCountry}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h4 className="text-primary mt-4 mb-3 font-weight-light"><strong>{this.strings.contract}</strong> {this.strings.flexibility}</h4>
                                        <table className="table table-striped table-sm">
                                            <tbody>
                                                <tr>
                                                    <td>{this.strings.fixedFee}</td>
                                                    <td>€ {numeral(flexibility.steering_fee_fixed).format('0.00')} {this.strings.perMonth}</td>
                                                </tr>
                                                <tr>
                                                    <td>{this.strings.yusoFee}</td>
                                                    <td>{numeral(flexibility.steering_fee_performance_pct).format('0.00')} %</td>
                                                </tr>
                                                {
                                                    !flexibility.startDate ? null :
                                                        <tr>
                                                            <td>{this.strings.startDate}</td>
                                                            <td>{flexibility.startDate}</td>
                                                        </tr>
                                                }
                                                {
                                                    !flexibility.endDate ? null :
                                                        <tr>
                                                            <td>{this.strings.endDate}</td>
                                                            <td>{flexibility.endDate}</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            this.state.showDetails ?
                                                [

                                                    <h4 className="text-primary mt-4 mb-3 font-weight-light" key={0}><strong>Steering</strong> information</h4>,
                                                    <table className="table table-striped table-sm" key={1}>
                                                        <tbody>
                                                            <tr>
                                                                <td>{this.strings.algorithm}Algorithm</td>
                                                                <td>{flexibility.flex_portal_config.steering.assets.battery.algorithm.join(', ')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.markets}Markets</td>
                                                                <td>{flexibility.flex_portal_config.steering.assets.battery.markets.join(', ')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                ]
                                                : null
                                        }

                                        <a href={`https://flex.yuso.io/${flexibility.id}/monitoring`} className="btn btn-primary p-3 mt-3 w-100">{this.strings.view}</a>
                                        <hr className="mb-4" />
                                    </DropdownPanel>
                                );
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Flexibility;
