import React, { Component } from 'react';

import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import UploadMandate from '../../components/widgets/UploadMandate';
import SendMandate from '../../components/widgets/SendMandate';
import PendingAgreement from '../../pages/documents/PendingAgreement';
import moment from 'moment-timezone'
import { mandatesStatus, shortURL, mandateRequest, mandateUpdate } from '../../api/mandates';
import { getSdps } from '../../api/sdps';

import 'react-day-picker/lib/style.css'

moment.tz.setDefault('Europe/Brussels')

class Mandates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groupedSdps: [],
            activeSdps: undefined,
            openTab: undefined,
            expanded: false,
            syncingMandate: false,
            apiRequests: undefined,
            editing: false,
            updateState: undefined,
            AMRs: false,
            DMRs: false
        };

        this.setOpenTab = this.setOpenTab.bind(this);
        this.sendMandateRequest = this.sendMandateRequest.bind(this);
        this.syncMandate = this.syncMandate.bind(this);
        this.generateShortURL = this.generateShortURL.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.updateSdps = this.updateSdps.bind(this);

        this.strings = LocalizedStrings({
            en: {
                loadingUsers: "Loading...",
                ean: "EAN",
                operator: "Distribution operator",
                start: "Contract start",
                volume: "Annual volume",
                capacity: "Power capacity",
                meterDataPermissions: "Meterdata permissions",
                yourSDP: "for your measurement point",
                noSdps: "You have no SDPs.",
                sendRequest: "Send request to:",
                sendRequestDesc: "Send a request to the customer to sign a mandate in order to share their meter data with Yuso.",
                sendRequestDescApi: "Send a request to the customer to share their meter data with Yuso through MyFluvius portal.",
                meterDataPermissionsRequest: "Meterdata permissions request",
                emailMandateRequest: "Send email mandate request",
                apiMandateRequest: "Send Fluvius API mandate request",
                signMandate: "Sign mandate",
                requestUrl: "Request MyFluvius URL",
                apiRequests: "Fluvius API mandate requests",
                meterType: "Meter type",
                mandateRequest: "Mandate request",
                successfulRequest: "Mandate request was successfully sent.",
                validMandate: "Valid mandate",
                signedMandate: "Signed mandate",
                mandateType: "Mandate type",
                mandateStatus: "Mandate status",
                serviceType: "Service type",
                validFrom: "Valid from",
                validTo: "Valid to",
                created: "Created",
                status: "Status",
                url: "URL",
                expires: "Expires on",
                goToMyFluvius: "Go to MyFluvius",
                generateRequest: "Generate Fluvius API request",
                pleaceWait: "Please wait...",
                send: "Send",
                noValidMandateRequests: "You have no valid mandate requests.",
                active: "Active",
                inactive: "Inactive"
            },
            nl: {
                loadingUsers: "Bezig met laden...",
                ean: "EAN",
                operator: "Distributie-exploitant",
                start: "Contract start",
                meterDataPermissions: "Machtigingen voor meterdata",
                yourSDP: "voor uw meetpunt",
                noSdps: "U heeft geen SDP's.",
                sendRequest: "Verzoek sturen naar:",
                sendRequestDesc: "Stuur een verzoek naar de klant om een mandaat te ondertekenen om zijn metergegevens te delen met Yuso.",
                sendRequestDescApi: "Stuur een verzoek naar de klant om hun metergegevens te delen met Yuso via het MyFluvius-portaal.",
                meterDataPermissionsRequest: "Verzoek om machtigingen voor meterdata",
                emailMandateRequest: "Stuur een machtigingsverzoek per e-mail",
                apiMandateRequest: "Stuur Fluvius API-mandaatverzoek",
                signMandate: "Teken mandaat",
                requestUrl: "Vraag MyFluvius URL aan",
                apiRequests: "Fluvius API-mandaatverzoeken",
                meterType: "Metertype",
                mandateRequest: "Mandaatverzoek",
                successfulRequest: "Het machtigingsverzoek is succesvol verzonden.",
                validMandate: "Geldig mandaat",
                signedMandate: "Ondertekend mandaat",
                mandateType: "Type mandaat",
                mandateStatus: "Mandaatstatus",
                serviceType: "Dienst Type",
                validFrom: "Geldig vanaf",
                validTo: "Geldig tot",
                created: "Gemaakt",
                status: "Toestand",
                url: "URL",
                expires: "Verloopt op",
                goToMyFluvius: "Ga naar Mijn Fluvius",
                generateRequest: "Genereer Fluvius API-verzoek",
                pleaceWait: "Even geduld aub...",
                send: "Sturen",
                noValidMandateRequests: "U heeft geen geldige machtigingsverzoeken.",
                active: "Actief",
                inactive: "Inactief"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.setState({
            apiRequests: this.props.apiRequests,
            //tmp 
            AMRs: this.props.mandates.filter(x => x.meterType === "AMR" || x.meterType === null).length ? true : false,
            DMRs: this.props.mandates.filter(x => x.meterType === "DMR").length ? true : false,
        })
    }

    componentWillReceiveProps(newProps) {
        let groupedSdps = newProps.sdps.filter((sdp, idx) => {
            return (newProps.sdps.findIndex((e, i) => {
                // DMRs
                if (sdp.ean === e.ean && i !== idx) {
                    e.installation_ids = `${sdp.installation_id},${e.installation_id}`
                    e.type_sdp_label = e.installation_ids.includes('INJ') && e.installation_ids.includes('AFN') ? 'offtakeInjection' : e.type_sdp
                    e.type_sdp = 'offtake'
                }
                return sdp.ean === e.ean
            }) === idx && !sdp.ean.startsWith('EANTMP'))
        })

        this.setState({ groupedSdps })
    }

    syncMandate(e, ean) {
        e.preventDefault()

        if (ean) {
            this.setState({ syncingMandate: ean })

            mandatesStatus(
                this.props.selectedCompany.companyNumber,
                ean,
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({ syncingMandate: undefined })
                    this.props.updateMandates();
                }
            );

        }
    }

    setOpenTab(id) {
        this.setState({
            openTab: id,
            selectedUser: undefined
        })
    }

    sendMandateRequest(e) {
        const mandateType = e.target.name
        this.setState({ sendingMandateRequest: true })

        mandateRequest(
            this.props.selectedCompany.company_id,
            {
                mandateType,
                lang: this.props.lang,
                selectedUser: this.state.selectedUser
            },
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                if (res.error)
                    this.setState({
                        sendingMandateRequest: false
                        // sendingMandateRequestError: 'Error occured'
                    });
                else
                    this.setState({
                        sendingMandateRequest: false,
                        successfulRequest: mandateType,
                    },
                        () => {
                            setTimeout(() => this.setState({ successfulRequest: undefined }), 5000)
                            this.props.updateMandates()
                        }
                    )
            }
        );

    }

    onUserChange(e) {
        this.setState({ selectedUser: this.props.usersForCompany.find(x => x.id === parseInt(e.target.value)) })
    }

    updateSdps() {
        if (!this.props.selectedCompany) return;

        getSdps(
            this.props.selectedCompany.company_id,
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                let sdps = res.sdps_offtake_hourly.concat(res.sdps_injection_hourly).concat(res.sdps_production)

                let groupedSdps = sdps.filter((sdp, idx) => {
                    return (sdps.findIndex((e, i) => {
                        // DMRs
                        if (sdp.ean === e.ean && i !== idx) {
                            e.installation_ids = `${sdp.installation_id},${e.installation_id}`
                            e.type_sdp_label = e.installation_ids.includes('INJ') && e.installation_ids.includes('AFN') ? 'offtakeInjection' : e.type_sdp
                            e.type_sdp = 'offtake'
                        }
                        return sdp.ean === e.ean
                    }) === idx && !sdp.ean.startsWith('EANTMP'))
                })

                this.setState({ sdps, groupedSdps });
            }
        );
    }

    generateShortURL() {
        this.setState({ generatingShortUrl: true })

        if (!this.props.selectedCompany) return;

        shortURL(this.props.selectedCompany.company_id,
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                if (res.error)
                    this.setState({
                        sendMandateRequest: false
                        // sendApiRequestError: 'Error occured'
                    });
                else
                    this.setState({
                        generatingShortUrl: false,
                        apiRequests: res
                    });
            }
        );
    }

    toggleExpand(ean) {
        this.setState({ expanded: ean === this.state.expanded ? false : ean })
    }

    toggleEdit(mandate) {
        if (mandate === 'save') {
            this.setState({ updateState: 'saving' })
            mandateUpdate(
                this.state.editing,
                (err, res) => {

                    if (err)
                        return window.reportError.send(err);

                    if (res.error)
                        this.setState({
                            editing: false,
                            updateState: 'error'
                        },
                            () => {
                                setTimeout(() => this.setState({ updateState: undefined }), 3000)
                            }
                        );
                    else
                        this.setState({
                            editing: false,
                            updateState: 'ok'
                        },
                            () => {
                                setTimeout(() => this.setState({ updateState: undefined }), 3000)
                                this.props.updateMandates()
                            }
                        )
                }
            );
        }
        else this.setState({
            editing: mandate ? {
                ...mandate,
                type: "NPS",
                status: "Active",
                serviceType: "NPS_kwartier_uur",
                validFrom: mandate.validFrom ? moment(mandate.validFrom).format("YYYY-MM-DD") : mandate.validFrom,
                validTo: mandate.validTo ? moment(mandate.validTo).format("YYYY-MM-DD") : mandate.validTo
            } : false
        })
    }

    onFieldChange(e) {
        this.setState({
            editing: {
                ...this.state.editing,
                [e.target.id]: e.target.value
            }
        })
    }


    render() {
        return (
            <div className="row my-2">
                {
                    !this.props.mandates.length ?
                        <div>
                            <div className="col-12 p-4">
                                <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.meterDataPermissions}</strong>&nbsp;{this.strings.yourSDP}</h4>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-8"><p className="alert alert-info"><i className="fas fa-info-circle mr-2"></i>{this.strings.noSdps}</p></div>
                            <div className="col-2"></div>
                        </div>
                        :
                        <div>
                            <div className="col-12 p-4">
                                <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.meterDataPermissions}</strong>&nbsp;{this.strings.yourSDP}</h4>
                            </div>
                            <div className="col-12 px-4">
                                <p>{this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? `Send a mandate sign` : `Sign a`} request for historic metering data mandate of your SDPs for a simulation.Yuso will request the meterdata from the grid company.It can take a few days before we receive this.Once we receive the data Yuso will come back via email to go through the simulation and give you a contract proposal.</p>
                            </div>
                            <div className="col-12 px-4">
                                <table className="table table-striped table-sm mb-3 p-4">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: '50%' }}>{this.strings.ean}</th>
                                            <th scope="col" className="text-center">{this.strings.meterType}</th>
                                            {this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? <th scope="col" className="text-center">{this.strings.mandateRequest}</th> : null}
                                            <th scope="col" className="text-center">{this.strings.validMandate}</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.mandates.map(m => {
                                                return (
                                                    this.state.expanded === m.ean ?
                                                        [
                                                            <tr key={1}>
                                                                <td>{m.ean}</td>
                                                                <td className="text-center">{m.meterType || "N/A"}</td>
                                                                {this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? <td className="text-center">{m.mandateSent ? <i className="fas fa-check text-green" /> : <i className={`fas fa-times text-${m.validMandate ? 'secondary' : 'danger'}`} />} </td> : null}
                                                                <td className="text-center">{m.validMandate ? <i className="fas fa-check text-green" /> : <i className="fas fa-times text-danger" />}</td>
                                                                <td className="text-center"><div className="cursor-pointer text-primary" onClick={(e) => this.syncMandate(e, m.ean)}><i className={`fas fa-sync ${this.state.syncingMandate === m.ean ? 'fa-spin ' : ''}text-primary`} /></div></td>
                                                                <td onClick={e => { e.preventDefault(); this.toggleExpand(m.ean) }}><i className={'ml-2 ' + (this.state.expanded === m.ean ? 'fas fa-angle-up' : 'fas fa-angle-down')}></i></td>
                                                            </tr>,
                                                            <tr key={2}>
                                                                {
                                                                    this.state.syncingMandate === m.ean ?
                                                                        <td colSpan="6" className="text-center"><Loader inline="true" /></td>
                                                                        :
                                                                        <td colSpan="6" className="px-4 bg-white">
                                                                            <div className="card">
                                                                                <table className="table table-borderless table-sm m-0">
                                                                                    <tr className="border-bottom bg-white">
                                                                                        <td className="text-left font-weight-bold">{this.strings.mandateType}</td>
                                                                                        <td className="text-center font-weight-bold">{this.strings.status}</td>
                                                                                        <td className="text-center font-weight-bold">{this.strings.serviceType}</td>
                                                                                        <td className="text-center font-weight-bold">{this.strings.validFrom}</td>
                                                                                        <td className="text-center font-weight-bold">{this.strings.validTo}</td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                    {
                                                                                        m.mandates.map(m => {
                                                                                            return this.state.editing && this.state.editing.ean === m.ean && this.state.editing.referenceNumber === m.referenceNumber ?
                                                                                                (
                                                                                                    <tr className="bg-white">
                                                                                                        <td className="text-left">
                                                                                                            <select id="type" value={this.state.editing.type} onChange={this.onFieldChange}>
                                                                                                                <option value="NPS">NPS</option>
                                                                                                                <option value="EMAIL">EMAIL</option>
                                                                                                            </select>
                                                                                                        </td>
                                                                                                        <td className="text-center">
                                                                                                            <select id="status" value={this.state.editing.status} onChange={this.onFieldChange}>
                                                                                                                <option value="Active">{this.strings.active}</option>
                                                                                                                <option value="Inactive">{this.strings.inactive}</option>
                                                                                                            </select>
                                                                                                        </td>
                                                                                                        <td className="text-center">{this.state.editing.serviceType}</td>
                                                                                                        <td className="text-center">
                                                                                                            <DayPickerInput
                                                                                                                value={this.state.editing.validFrom}
                                                                                                                placeholder=""
                                                                                                                format="YYYY-MM-DD"
                                                                                                                formatDate={(date, format) => { return moment(date).format(format) }}
                                                                                                                inputProps={{ style: { width: 100 }, readOnly: 'readonly', required: 'required', name: 'validFrom', id: 'validFrom' }}
                                                                                                                dayPickerProps={{ selectedDays: this.state.editing.validFrom, initialMonth: new Date(moment()) }}
                                                                                                                onDayChange={(date) => this.onFieldChange({ target: { id: 'validFrom', value: moment(date).format("YYYY-MM-DD") } })}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="text-center"><DayPickerInput
                                                                                                            value={this.state.editing.validTo}
                                                                                                            placeholder=""
                                                                                                            format="YYYY-MM-DD"
                                                                                                            formatDate={(date, format) => { return moment(date).format(format) }}
                                                                                                            inputProps={{ style: { width: 100 }, readOnly: 'readonly', required: 'required', name: 'validTo', id: 'validTo' }}
                                                                                                            dayPickerProps={{ selectedDays: this.state.editing.validTo, initialMonth: new Date(moment()) }}
                                                                                                            onDayChange={(date) => this.onFieldChange({ target: { id: 'validTo', value: moment(date).format("YYYY-MM-DD") } })}
                                                                                                        /></td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                this.state.updateState !== 'saving' ?
                                                                                                                    <div>
                                                                                                                        <i className="ml-2 fas fa-save text-primary" onClick={e => { e.preventDefault(); this.toggleEdit("save") }} />
                                                                                                                        <i className="ml-2 fas fa-window-close text-danger" onClick={e => { e.preventDefault(); this.toggleEdit(false) }} />
                                                                                                                    </div>
                                                                                                                    : <Loader inline="true" />
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <tr className="bg-white">
                                                                                                        <td className="text-left">{m.ean2 ? m.type || "N/A" : <i className="fas fa-times text-secondary" />}</td>
                                                                                                        <td className="text-center">{m.ean2 ? m.status || "N/A" : <i className="fas fa-times text-secondary" />}</td>
                                                                                                        <td className="text-center">{m.ean2 ? m.serviceType || "N/A" : <i className="fas fa-times text-secondary" />}</td>
                                                                                                        <td className="text-center">{m.ean2 ? moment(m.validFrom).format("YYYY-MM-DD") : <i className="fas fa-times text-secondary" />}</td>
                                                                                                        <td className="text-center">{m.ean2 ? m.validTo !== null ? moment(m.validTo).format("YYYY-MM-DD") : "∞" : <i className="fas fa-times text-dark" />}</td>
                                                                                                        {
                                                                                                            this.props.selectedCompany.role === 'admin' && ["EMAIL", "NPS"].includes(m.type) ?
                                                                                                                !this.state.updateState ? <td onClick={e => { e.preventDefault(); this.toggleEdit(m) }}><i className="ml-2 fas fa-edit text-primary" /></td>
                                                                                                                    : <td><i className={`ml-2 fas fa-${this.state.updateState === 'ok' ? 'check-circle text-green' : 'exclamation-triangle text-danger'}`} /></td>
                                                                                                                : <td></td>
                                                                                                        }
                                                                                                    </tr>
                                                                                                )
                                                                                        })
                                                                                    }
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                }
                                                            </tr>
                                                        ]
                                                        :
                                                        <tr key={3}>
                                                            <td>{m.ean}</td>
                                                            <td className="text-center">{m.meterType || "N/A"}</td>
                                                            {this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? <td className="text-center">{m.mandateSent ? <i className="fas fa-check text-green" /> : <i className={`fas fa-times text-${m.validMandate ? 'secondary' : 'danger'}`} />} </td> : null}
                                                            <td className="text-center">{m.validMandate ? <i className="fas fa-check text-green" /> : <i className="fas fa-times text-danger" />}</td>
                                                            <td className="text-center"><div className="cursor-pointer text-primary" onClick={(e) => this.syncMandate(e, m.ean)}><i className={`fas fa-sync ${this.state.syncingMandate === m.ean ? 'fa-spin ' : ''}text-primary`} /></div></td>
                                                            {
                                                                m.mandates[0].ean2 ? <td onClick={e => { e.preventDefault(); this.toggleExpand(m.ean) }}><i className={'ml-2 ' + (this.state.expanded === m.ean ? 'fas fa-angle-up' : 'fas fa-angle-down')} /></td>
                                                                    : <td><i className='ml-2 fas fa-angle-down text-light' /></td>
                                                            }
                                                        </tr>
                                                )
                                            }
                                            )
                                        }
                                    </tbody>

                                </table>
                            </div>
                            <div className="col-12 px-4">
                                {
                                    !this.state.AMRs ? null : <button className={`btn btn-${this.state.openTab === 'emailMandate' ? 'primary' : 'secondary'} btn-lg border-right`} type="button" onClick={(e) => { this.setOpenTab('emailMandate'); }}>
                                        {this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? this.strings.emailMandateRequest : this.strings.signMandate}</button>
                                }
                                {
                                    !this.state.DMRs ? null : <button className={`btn btn-${this.state.openTab === 'api' ? 'primary' : 'secondary'} btn-lg`} type="button" onClick={(e) => { this.setOpenTab('api'); }}>
                                        {this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ? this.strings.apiMandateRequest : this.strings.requestUrl}</button>
                                }
                            </div>
                            <div className={"col-12 px-4" + (this.state.openTab ? "" : " d-none")}>
                                <div className="border border-secondary px-4 pt-4">
                                    {this.state.openTab === 'emailMandate' ?
                                        this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="card mb-4">
                                                        <div className="card-header">
                                                            {this.strings.meterDataPermissionsRequest}
                                                        </div>
                                                        <div className="card-body">
                                                            {this.strings.sendRequestDesc}
                                                            <div className="form-group row">
                                                                <label htmlFor="user" className="col-sm-4 col-form-label">{this.strings.sendRequest}</label>
                                                                <div className="col-sm-8">
                                                                    {
                                                                        this.props.usersForCompany ?
                                                                            <div>
                                                                                {
                                                                                    this.props.usersForCompany.length ?
                                                                                        <select className="form-control mb-3 required" onChange={this.onUserChange} id="user" name="user" value={this.state.selectedUser ? this.state.selectedUser.id : ""} required>
                                                                                            <option value=""></option>
                                                                                            {
                                                                                                this.props.usersForCompany.map(user => {
                                                                                                    return (
                                                                                                        user.role !== 'admin' ? <option value={user.id} key={user.email}>{user.firstName} {user.lastName} - {user.email}</option> : null
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        : <div className="col-form-label">{this.strings.noContacts}</div>
                                                                                }
                                                                            </div>
                                                                            : <Loader inline="true">{this.strings.loadingUsers}</Loader>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-12 text-right">
                                                                    <button className="btn btn-primary btn-lg" name="email" onClick={this.sendMandateRequest} disabled={!this.state.selectedUser}>{this.state.sendingMandateRequest ? <Loader inline="true">Please wait</Loader> : 'Send'}</button>
                                                                </div>
                                                            </div>
                                                            {this.state.successfulRequest === 'email' ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.strings.successfulRequest}</p> : null}
                                                        </div>
                                                    </div>

                                                    <UploadMandate
                                                        sdps={this.state.groupedSdps}
                                                        lang={this.props.lang}
                                                        selectedCompany={this.props.selectedCompany}
                                                        updateCompanies={this.props.updateCompanies}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <SendMandate
                                                        sdps={this.state.groupedSdps}
                                                        lang={this.props.lang}
                                                        selectedCompany={this.props.selectedCompany}
                                                        usersForCompany={this.props.usersForCompany}
                                                        updateSdps={this.updateSdps}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <PendingAgreement
                                                selectedCompany={this.props.selectedCompany}
                                                user={this.props.user}
                                                company_id={this.props.selectedCompany.company_id}
                                                type='mandate'
                                                info={this.props.info}
                                                lang={this.props.lang}
                                            />
                                        : this.state.openTab === 'api' ?
                                            <div className="row">
                                                {
                                                    this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                                        <div className="col-6">
                                                            <div className="card mb-4">
                                                                <div className="card-header">
                                                                    {this.strings.meterDataPermissionsRequest}
                                                                </div>
                                                                <div className="card-body">
                                                                    {this.strings.sendRequestDescApi}
                                                                    <div className="form-group row">
                                                                        <label htmlFor="user" className="col-sm-4 col-form-label">{this.strings.sendRequest}</label>
                                                                        <div className="col-sm-8 mt-1">
                                                                            {
                                                                                this.props.usersForCompany ?
                                                                                    <div>
                                                                                        {
                                                                                            this.props.usersForCompany.length ?
                                                                                                <select className="form-control mb-3 required" onChange={this.onUserChange} id="user" name="user" value={this.state.selectedUser ? this.state.selectedUser.id : ""} required>
                                                                                                    <option value=""></option>
                                                                                                    {
                                                                                                        this.props.usersForCompany.map(user => {
                                                                                                            return (
                                                                                                                user.role !== 'admin' ? <option value={user.id} key={user.email}>{user.firstName} {user.lastName} - {user.email}</option> : null
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                                : <div className="col-form-label">{this.strings.noContacts}</div>
                                                                                        }
                                                                                    </div>
                                                                                    : <Loader inline="true">{this.strings.loadingUsers}</Loader>
                                                                            }
                                                                        </div>
                                                                        <div className="col-sm-12 text-right">
                                                                            <button className="btn btn-primary btn-lg" name="api" onClick={this.sendMandateRequest} disabled={!this.state.selectedUser}>{this.state.sendingMandateRequest ? <Loader inline="true">Please wait</Loader> : 'Send'}</button>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.successfulRequest === 'api' ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.strings.successfulRequest}</p> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                <div className="col-6">
                                                    <div className="card mb-4">
                                                        <div className="card-header">
                                                            {this.strings.apiRequests}
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                this.state.apiRequests && this.state.apiRequests.length ?
                                                                    <table className="table table-sm mb-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{this.strings.created}</th>
                                                                                <th scope="col">{this.strings.status}</th>
                                                                                <th scope="col">{this.strings.url}</th>
                                                                                <th scope="col">{this.strings.expires}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.apiRequests.map(
                                                                                    (m) => {

                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{moment(m.referenceNumber.split('-')[2], "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm")}</td>
                                                                                                <td>{m.status}</td>
                                                                                                <td>
                                                                                                    <a href={`https://mijn.fluvius.be/verbruik/dienstverlener?id=${m.shortUrlIdentifier}`} target="_blank" rel="noopener noreferrer">{this.strings.goToMyFluvius}</a>
                                                                                                </td>
                                                                                                <td>{m.validTo}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                )
                                                                            }
                                                                        </tbody>

                                                                    </table>
                                                                    : <div>
                                                                        <p className="alert alert-info">
                                                                            <i className="fas fa-info-circle mr-2"></i>{this.strings.noValidMandateRequests}
                                                                        </p>
                                                                        <button className="btn btn-primary btn-lg" onClick={this.generateShortURL}>{this.state.generatingShortUrl ? <Loader inline="true">{this.strings.pleaceWait}</Loader> : this.strings.generateRequest}</button>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default Mandates;
