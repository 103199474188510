import React, { Component } from 'react';

import SubHeader from '../../components/SubHeader';
import Tabs from '../../components/Tabs';
import LocalizedStrings from '../../components/Localization';
import ContractSimilator from '../../components/widgets/ContractSimilator';
// import FlexibilitySimilator from '../../components/widgets/FlexibilitySimilator';


class Simulations extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        make: "make contract and flexibility",
        simulation: "simulation for",
        demoWarning: "You can't make simulations for the Demo Company since it's automatically generated.",
        contract: "Contract",
        flexibility: "Flexibility"
      },
      nl: {
        welcome: "Welkom",
        make: "maak contract en flexibiliteit",
        simulation: "simulatie voor",
        demoWarning: "U kunt geen simulaties maken voor Demo Company omdat deze automatisch wordt gegenereerd.",
        contract: "Contract",
        flexibility: "Flexibiliteit"
      }
    }, this.props.lang);
  }

  render() {

    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.make}<br />
              {this.strings.simulation} <strong>{this.props.selectedCompany.companyName}</strong>.
            </div>

            <Tabs titles={[
              { name: this.strings.contract, id: 'contract' }
              // { name: this.strings.flexibility, id: 'flexibility' }
            ]}>
              <div>
                <ContractSimilator
                  selectedCompany={this.props.selectedCompany}
                  lang={this.props.lang}
                />
              </div>

              <div>
                {/* <FlexibilitySimilator
                    selectedCompany={this.props.selectedCompany}
                    lang={this.props.lang}
                  /> */}
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default Simulations;
