import config from './config';
import { getAccessToken } from './user';


const getKbo = (query, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/onboarding/kbo?q=${query}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export { getKbo };
