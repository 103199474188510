import config from './config';
import { getAccessToken } from './user';
import moment from 'moment-timezone';


const getShardedMetervalues = (company_id, eans, direction, from, to, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            from = moment(from).tz('Europe/Brussels').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            to = moment(to).tz('Europe/Brussels').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            fetch(
                `${config.BASE_URL}/api/metervalues/getShardedMetervalues/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        eans,
                        direction,
                        from,
                        to
                    })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getMetervaluesInjectionHourly = (company_id, eans, from, to, grouped, hourly, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            from = moment(from).tz('Europe/Brussels').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            to = moment(to).tz('Europe/Brussels').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            fetch(
                `${config.BASE_URL}/api/metervalues/getMetervalues/injectionHourly/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        eans,
                        from,
                        to,
                        grouped,
                        hourly
                    })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getMetervaluesOfftakeHourly = (company_id, eans, from, to, grouped, hourly, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            from = moment(from).tz('Europe/Brussels').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            to = moment(to).tz('Europe/Brussels').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            fetch(
                `${config.BASE_URL}/api/metervalues/getMetervalues/offtakeHourly/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        eans,
                        from,
                        to,
                        grouped,
                        hourly
                    })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getMetervaluesProductionHourly = (company_id, eans, from, to, grouped, hourly, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            from = moment(from).tz('Europe/Brussels').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            to = moment(to).tz('Europe/Brussels').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            fetch(
                `${config.BASE_URL}/api/metervalues/getMetervalues/productionHourly/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        eans,
                        from,
                        to,
                        grouped,
                        hourly
                    })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}


export { getShardedMetervalues, getMetervaluesInjectionHourly, getMetervaluesOfftakeHourly, getMetervaluesProductionHourly };
