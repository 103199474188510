import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import LocalizedStrings from './Localization';


class DropdownPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: this.props.isCollapsed || false,
        };

        this.strings = LocalizedStrings({
            en: {
                view: "Click to view SDP information",
                deleteSdp: "Delete SDP",
                archiveSdp: "Archive SDP",
                contractEnded: "Contract has ended",
                contractExpired: "Contract proposal has expired",
                unArchiveSdp: "Unarchive SDP",
                notActive: "Contract ended on",
                noActiveContract: "No active contract yet.",
                noContract: "No contract",
                noMdPermissions: "No meter data permissions",
                futureContractNoSwitch: "SDP has a future contract and is not yet switched to Yuso",
                activeContractNoSwitch: "SDP has an active contract, but is not switched to Yuso",
                inactiveContractNoEOC: "SDP has inactive contract but no EOC date",
                noServiceComponent: "The correct service component is not yet available for this SDP. Contact",
                noServiceComponent1: "to solve this.",
                SDPNotWithYuso: "Contract is not signed, SDP not with Yuso",
                SDPWithYuso: "Contract is not signed, SDP with Yuso",
                SDPNotSwitched: "SDP not switched yet",
                switchPlanned: "SDP not with Yuso, switch planned on",
                issueWithPoints: "Active contract but SDP not with Yuso",
                issueWithPointsEOC: "Contract ended but no EOC date",
                historicPoints: "Contract has ended but point still in portfolio",
                switchDateDNBAdministration: "SDP not with Yuso, switch date depends on DNB administration",
                pointUnderConstruction: "This point is under construction",
                pointDisconnected: "This point is disconnected",
                pointConnected: "SDP in Yuso Portfolio"
            },
            nl: {
                view: "Klik om EAN info te bekijken",
                deleteSdp: "Verwijder SDP",
                archiveSdp: "Archiveer SDP",
                contractEnded: "Contract is beëindigd",
                contractExpired: "Contractvoorstel is niet meer geldig",
                unArchiveSdp: "Heractiveer SDP",
                notActive: "Contract beëindigd op",
                noActiveContract: "Contract niet getekend",
                noContract: "Geen contract",
                noMdPermissions: "Mandaat om metergegevens op te vragen is niet ondertekend",
                futureContractNoSwitch: "Contract start op [fill in start date contract], nog niet geswitcht",
                activeContractNoSwitch: "Contract is actief, nog niet geswitcht",
                inactiveContractNoEOC: "Contract inactief maar er is geen EOC datum ingevuld",
                noServiceComponent: "De juiste service component is nog niet beschikbaar voor dit SDP. Contacteer",
                noServiceComponent1: "om dit op te lossen.",
                SDPNotWithYuso: "Contract niet getekend",
                SDPWithYuso: "Contract niet getekend, SDP bij Yuso",
                SDPNotSwitched: "SDP is nog niet geswitcht",
                switchPlanned: "Switch is gepland op",
                issueWithPoints: "Actief contract maar SDP niet bij Yuso",
                issueWithPointsEOC: "Contract beëindigd, maar geen EOC-datum",
                historicPoints: "Contract is afgelopen, maar punt nog steeds in portefeuille",
                switchDateDNBAdministration: "SDP is geswitcht, switch datum hangt af van [fill in DSO]",
                pointUnderConstruction: "SDP is under construction",
                pointDisconnected: "SDP is disconnected",
                pointConnected: "SDP in Yuso Portfolio"
            }
        }, this.props.lang);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    toggleCollapse() {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }

    renderArchiveFolderIcon = () => {
        const { sdp, selectedCompany } = this.props;
        if (sdp.contract_status !== 'active' && (selectedCompany.role === 'admin' || selectedCompany.role === 'partner')) {
            return (
                <i className={`fas ${sdp.archived === 1 ? 'fa-folder-minus' : 'fa-folder-plus'} light-brown cursor-pointer ml-auto`}
                    title={sdp.archived === 1 ? this.strings.unArchiveSdp : this.strings.archiveSdp}
                    data-toggle="modal"
                    data-target="#archive-sdp"
                    onClick={() => { this.props.setArchiveSdp(this.props.installation_id, this.props.type, sdp.archived) }}>
                </i>
            );
        }
        return null;
    }

    renderIconBasedOnCondition = () => {
        const { sdp } = this.props;
        const icons = [];
        if (sdp.archived === 1 && !sdp.withYuso) {
            icons.push(<i className="fa-regular fa-folder-closed mr-2" title={this.strings.archiveSdp} data-toggle="tooltip"></i>);
        }

        if (['active', 'future'].includes(sdp.contract_status) && sdp.physicalStatus === 'Connected' && sdp.withYuso && sdp.switchStatus === '39') {
            if (sdp.hasFlexibility) {
                icons.push(<i className="fa-solid fa-battery-three-quarters text-primary mr-2" title={this.strings.pointConnected} data-toggle="tooltip"></i>);
            } else {
                icons.push(<i className="fa-solid fa-bolt text-primary mr-2" title={this.strings.pointConnected} data-toggle="tooltip"></i>);
            }
        }

        if (sdp.contract_status === 'unsigned' && sdp.withYuso) {
            if (sdp.hasFlexibility) {
                icons.push(<i className="fa-solid fa-battery-three-quarters text-primary mr-2" title={this.strings.SDPWithYuso} data-toggle="tooltip" />);
            } else {
                icons.push(<i className="fa-solid fa-file-signature text-danger mr-2" title={this.strings.SDPWithYuso} data-toggle="tooltip"></i>);
            }
        }

        if (sdp.archived !== 1) {
            if (!this.props.flexibility && this.props.type === 'production' && !this.props.mandate) {
                icons.push(<i className="fas fa-exclamation-circle text-danger mr-2" title={this.strings.noMdPermissions} data-toggle="tooltip"></i>);
            }
            if (!sdp.service_component && ['active', 'future'].includes(sdp.contract_status) && sdp.ean.startsWith("EAN54")) {
                icons.push(<i className="fa-solid fa-circle-xmark text-danger mr-2" title={`${this.strings.noServiceComponent} ${sdp.dgo} ${this.strings.noServiceComponent1}`} data-toggle="tooltip"></i>);
            }
            // if (!sdp.service_component && ['active', 'future'].includes(sdp.contract_status)) {
            //     icons.push(<i className="fa-solid fa-circle-xmark text-danger mr-2" title={`${this.strings.noServiceComponent} ${sdp.dgo} ${this.strings.noServiceComponent1}`} data-toggle="tooltip"></i>);
            // }
            if (sdp.hasContract === false) {
                icons.push(<i className="fa-solid fa-file-excel text-danger mr-2" title={this.strings.noContract} data-toggle="tooltip"></i>);
            }
            if ((sdp.switchStatus === null || sdp.switchStatus === '41') && ['active', 'future'].includes(sdp.contract_status) && !sdp.withYuso) {
                icons.push(<i className="fa-solid fa-shuffle text-danger mr-2" title={this.strings.SDPNotSwitched} data-toggle="tooltip"></i>);
            }
            // if (sdp.contract_status === 'ended' && !this.props.flexibility) {
            //  icons.push(<i className="fas fa-exclamation-circle text-warning mr-2" title={`${this.strings.notActive} ${sdp.endDate}`} data-toggle="tooltip"></i>);
            // }
            if (sdp.contract_status === 'ended' && sdp.withYuso && !sdp.switchStatus) {
                icons.push(<i class="fa-solid fa-landmark text-danger mr-2" title={this.strings.historicPoints} data-toggle="tooltip"></i>)
            }
            if (sdp.contract_status === 'ended' && !sdp.withYuso && sdp.switchStatus === '39' && !sdp.eocDate) {
                icons.push(<i className="fas fa-exclamation-circle text-danger mr-2" title={this.strings.inactiveContractNoEOC} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'ended' && !sdp.withYuso) {
                icons.push(<i className="fa-solid fa-landmark mr-2" title={this.strings.contractEnded} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'expired' && !sdp.withYuso) {
                icons.push(<i className="fa-solid fa-hourglass text-danger mr-2" title={this.strings.contractExpired} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'active' && !sdp.withYuso && sdp.switchStatus === '39' && !sdp.eocDate) {
                icons.push(<i class="fa-solid fa-triangle-exclamation text-danger mr-2" title={this.strings.issueWithPoints} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'ended' && !sdp.withYuso && sdp.switchStatus === '39' && !sdp.eocDate) {
                icons.push(<i class="fa-solid fa-landmark text-danger mr-2" title={this.strings.issueWithPointsEOC} data-toggle="tooltip"></i>)
            }
            if (['active', 'future'].includes(sdp.contract_status) && sdp.physicalStatus === 'Connected' && sdp.switchStatus === '39' && sdp.switchType === 'Supplier Switch' && !sdp.withYuso) {
                icons.push(<i class="fa-regular fa-clock mr-2" title={`${this.strings.switchPlanned} ${sdp.switchDate}`} data-toggle="tooltip"></i>);
            }
            if (['active', 'future'].includes(sdp.contract_status) && sdp.physicalStatus === 'Connected' && sdp.switchStatus === '39' && sdp.switchType === 'Move-in' && !sdp.withYuso) {
                icons.push(<i class="fa-regular fa-clock mr-2" title={this.strings.switchDateDNBAdministration} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'unsigned' && !sdp.withYuso) {
                icons.push(<i className="fa-solid fa-file-signature text-danger mr-2" title={this.strings.SDPNotWithYuso} data-toggle="tooltip"></i>);
            }
            if (sdp.contract_status === 'unsigned' && sdp.withYuso) {
                icons.push(<><i class="fa-solid fa-bolt text-primary mr-2"></i><i className="fa-solid fa-file-signature text-danger mr-2" title={this.strings.SDPWithYuso} data-toggle="tooltip"></i></>);
            }
            if (['active', 'future'].includes(sdp.contract_status) && sdp.physicalStatus === 'Under construction' && sdp.switchStatus === '39') {
                icons.push(<i className="fa-solid fa-person-digging text-danger mr-2" title={this.strings.pointUnderConstruction} data-toggle="tooltip"></i>);
            }
            if (['active', 'future'].includes(sdp.contract_status) && sdp.physicalStatus === 'Disconnected' && sdp.switchStatus === '39' && sdp.switchType === 'Move-in') {
                icons.push(<i class="fa-solid fa-plug-circle-xmark text-danger mr-2" title={this.strings.pointDisconnected} data-toggle="tooltip"></i>);
            }
        }

        return icons.filter(Boolean).map((icon, index) => <React.Fragment key={index}>{icon}</React.Fragment>);
    };


    renderDeleteSdpIcon = () => {
        const { selectedCompany } = this.props;
        if (selectedCompany.role === 'admin') {
            return (
                <i className="fas fa-times text-danger cursor-pointer ml-3"
                    title={this.strings.deleteSdp}
                    data-toggle="modal"
                    data-target="#delete-sdp"
                    onClick={() => { this.props.setDeleteSdp(this.props.installation_id, this.props.type) }}>
                </i>
            );
        }
        return null;
    }

    getTextStyle = () => {
        const { sdp } = this.props;
        if (sdp.archived === 1) return 'text-muted';
        if (['unsigned'].includes(sdp.contract_status)) return 'text-danger';
        return 'text-primary';
    }

    render() {
        const { checkbox, ean, className, address, children } = this.props;
        const { isCollapsed } = this.state;
        return (
            <div className="list-group-item p-0">
                <div className="d-flex align-items-center mb-3">
                    {checkbox && <input type="checkbox" value={ean} name="ean" id={`ean_${ean}`} className="mr-2" />}
                    <h4 onClick={this.toggleCollapse} title={isCollapsed ? this.strings.view : undefined} className={`${className} ${this.getTextStyle()} cursor-pointer m-0 font-weight-bold`}>
                        {this.renderIconBasedOnCondition()}
                        {ean} {address && (<span> - <small>{address}</small></span>)}
                        <i className={'ml-2 ' + (isCollapsed ? 'fas fa-angle-down' : 'fas fa-angle-up')}> </i>
                    </h4>
                    {this.renderArchiveFolderIcon()}
                    {this.renderDeleteSdpIcon()}
                </div>
                <div>{!isCollapsed ? children : null}</div>
            </div>
        );
    }
}

export default DropdownPanel;

