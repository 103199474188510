import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/tab'

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';

import SubHeader from '../../components/SubHeader';
import { CtaPanel } from '../../components/CallToAction';
import LocalizedStrings from '../../components/Localization';
import Loader from '../../components/Loader';

import { getPartners } from '../../api/partner'
import { getUsers } from '../../api/user';
import { filterCompanies, deleteCompany } from '../../api/company';
import { getAdvancedEans } from '../../api/eans';


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyFilter: '',
            contractFilter: '',
            partnerFilter: '',
            userFilter: '',
            eanFilter: '',
            eanKBOFilter: '',
            altEanFlag: false,
            eanError: false,
            isLoading: false,
            isCompaniesLoading: false,
            hideCompanies: true,
            deleteCompany: {},
            companies: [],
            eans: undefined
        }

        this.timer = null;

        this.renderCompanyList = this.renderCompanyList.bind(this);
        this.renderEanList = this.renderEanList.bind(this);
        this.filterCompanies = this.filterCompanies.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.showAllCompanies = this.showAllCompanies.bind(this);
        this.onUserSearch = this.onUserSearch.bind(this);
        this.onUserChanged = this.onUserChanged.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onInputEanSearch = this.onInputEanSearch.bind(this);
        this.onEanSearch = this.onEanSearch.bind(this);
        this.getDirection = this.getDirection.bind(this);
        this.getMeasuringMethod = this.getMeasuringMethod.bind(this);

        this.strings = LocalizedStrings({
            en: {
                welcome: "Welcome",
                current: "Current selected company",
                companies: "Companies",
                noCompaniesFound: "No companies found. Try adjusting your filter criteria.",
                noEansFound: "No EANs found. Try adjusting your search criteria.",
                add: "Add",
                customer: "new customer",
                filter: "Filter companies by name or KBO number...",
                searchEan: "Search EAN...",
                searchByAddress_streetName: "Street Name",
                searchByAddress_number: "Number",
                searchByAddress_city: "City",
                searchByAddress_zip: "Postcode",
                searchEanByKBO: "Search EAN by KBO...",
                searchByEAN: "Search by EAN",
                searchByADDRESS: "Search by ADDRESS",
                searchByKBO: "Search by KBO",
                lookupEan: "Lookup the EAN number above",
                searching: "Searching...",
                resultsEans: "EAN results will show here",
                newCustomerDesc: "This portal will allow you to register your service points for energy delivery and energy injection. Our dashboards will display your energy consumption or injection for every hour, as well as the wholesale price. These amounts determine the energy component of your invoice or self-bill. We'll gladly explain all functionalities to you. Feel free to contact us at",
                newCustomerTitle: "What is My Yuso?",
                info: "Detailed information about this portal",
                showing: "showing",
                of: "of",
                showingAll: "showing all",
                showAll: "Show all",
                companies2: "companies",
                sdpsAttached: "The company has SDPs attached to it. Please delete all SDPs associated with the company before deleting it.",
                errorDelete: "There was an error while deleting the company. Please try again.",
                companyDeleted: "Company successfully deleted!",
                confirmDelete: "Do you want to permanently delete",
                deleteWarning: "Be careful as this operation cannot be undone!",
                deleteCompany: "Delete company",
                delete: "Delete",
                close: "Close",
                contracts: "Contracts",
                active: "Active",
                ended: "Ended",
                expired: "Expired",
                unsigned: "Unsigned",
                future: "Future",
                unknown: "Unknown",
                partner: "Partner",
                loading: "Loading...",
                searchingCompanies: "Searching companies...",
                user: "User",
                ean: "EAN",
                altEans: "Other EAN(s) located on the street address above",
                eanError: "EAN number should contain 18 digits",
                eanAddressError: "Street Name field is required",
                supply: "Supply",
                injection: "Injection",
                continuous: "Continuous (AMR meter)",
                monthly: "Monthly",
                annually: "Annually",
                flat: "Flat rate",
                address: "Address",
                dgo: "DGO",
                meterNumber: "Meter number",
                direction: "Direction",
                measuringMethod: "Measuring method",
                linkedCompany: "Linked company in My Yuso",
                clickSwitch: "Click to switch to this company",
                linkedCompanyInfo: "Linked companies show only if your user has access to it",
                suggestedCompanies: "Suggested companies matched by the EAN address",
                phone: "phone",
                email: "email",
                web: "web",
                noLinkedCompanies: "No linked companies found",
                cta1: "Want to add",
                cta2: "new service delivery",
                cta3: "point?",
                cta4: "Add",
                cta5: "SDP"
            },
            nl: {
                welcome: "Welkom",
                current: "Huidig geselecteerd bedrijf",
                companies: "Bedrijven",
                noCompaniesFound: "Geen bedrijven gevonden. Pas jouw filtercriteria aan.",
                noEansFound: "Geen EANs gevonden. Probeer jouw zoekcriteria aan te passen.",
                add: "Toevoegen",
                customer: " van nieuwe klant",
                filter: "Filter bedrijven op naam of KBO-nummer...",
                searchEan: "Zoek EAN...",
                searchByAddress_streetName: "Straatnaam",
                searchByAddress_number: "Nummer",
                searchByAddress_city: "Stad",
                searchByAddress_zip: "Postcode",
                searchEanByKBO: "Zoek EAN op KBO...",
                searchByEAN: "Zoek op EAN",
                searchByADDRESS: "Zoek op ADDRESS",
                searchByKBO: "Zoek op KBO",
                lookupEan: "Zoek het EAN-nummer hierboven op",
                searching: "Zoeken...",
                resultsEans: "EAN-resultaten worden hier getoond",
                newCustomerDesc: "Via dit portaal kan je jouw aansluitingspunten voor levering en injectie toevoegen. Via dashboards kan je je energie verbruik en injectie voor elk uur bekijken. Daarnaast vind je de prijzen voor elk uur. Deze variabelen bepalen de energie component van je factuur en self-bill. Neem contact op als je hierover meer uitleg wenst. Neem gerust contact met ons op via",
                newCustomerTitle: "Wat is My Yuso?",
                info: "Meer informatie over deze portal ",
                showing: "weergegeven",
                of: "van",
                showingAll: "alles tonen",
                showAll: "Toon alles",
                companies2: "bedrijven",
                sdpsAttached: "Het bedrijf heeft SDPs die eraan zijn gekoppeld. Verwijder alle SDPs die aan het bedrijf zijn gekoppeld voordat u het verwijdert.",
                errorDelete: "Er is een fout opgetreden bij het verwijderen van het bedrijf. Probeer het opnieuw.",
                companyDeleted: "Bedrijf succesvol verwijderd!",
                confirmDelete: "Wilt u permanent verwijderen?",
                deleteWarning: "Wees voorzichtig want dit kan niet ongedaan worden gemaakt!",
                deleteCompany: "Bedrijf verwijderen",
                delete: "Verwijder",
                close: "Sluiten",
                contracts: "Contracten",
                active: "Actief",
                ended: "Beëindigd",
                expired: "Verlopen",
                unsigned: "Niet ondertekend",
                future: "Toekomst",
                unknown: "Onbekend",
                partner: "Partner",
                loading: "Laden...",
                searchingCompanies: "Bedrijven zoeken...",
                user: "User",
                ean: "EAN",
                altEans: "Andere EAN(s) op het bovenstaande adres",
                eanError: "EAN-nummer moet 18 cijfers bevatten",
                eanAddressError: "Het veld Straatnaam is verplicht",
                supply: "Afname",
                injection: "Injectie",
                continuous: "Continu (AMR meter)",
                monthly: "Maandelijks",
                annually: "Jaarlijks",
                flat: "Forfaitair",
                address: "Adres",
                dgo: "DGO",
                meterNumber: "Meter nummer",
                direction: "Richting",
                measuringMethod: "Metertype",
                linkedCompany: "Gekoppeld bedrijf in My Yuso",
                clickSwitch: "Klik om over te schakelen naar dit bedrijf",
                linkedCompanyInfo: "Gekoppelde bedrijven worden alleen weergegeven als uw gebruiker hier toegang toe heeft",
                suggestedCompanies: "Voorgestelde bedrijven die overeenkomen met het EAN-adres",
                phone: "tel",
                email: "email",
                web: "web",
                noLinkedCompanies: "Geen gekoppelde bedrijven gevonden",
                cta1: "Nieuw",
                cta2: "aansluitingspunt",
                cta3: "toevoegen?",
                cta4: "Toevoegen",
                cta5: "Aansluitingspunt"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.filterCompanies();

        getPartners((err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partners: res
            })
        })
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    renderCompanyList() {

        if (this.state.isCompaniesLoading)
            return <tr><td colSpan={2} className='bg-white'><Loader inline="true">{this.strings.searchingCompanies}</Loader></td></tr>

        if (this.state.companies.length) {
            return this.state.companies.map((company, idx) => {
                return (
                    <tr key={company.companyNumber} className={idx > 10 && this.state.hideCompanies ? 'd-none' : ''}>
                        <td>
                            <Link className="cursor-pointer text-primary" to={`/account/company/switch/${company.companyNumber}`}>{company.companyName} - {company.companyNumber}</Link>
                        </td>
                        {
                            this.props.selectedCompany.role === 'admin' ?
                                <td className="commands">
                                    <i className="fas fa-times text-danger cursor-pointer" title={this.strings.deleteCompany} data-toggle="modal" data-target="#delete-company" onClick={() => { this.setState({ deleteCompany: company }) }}></i>
                                </td>
                                : null
                        }
                    </tr>
                );
            })
        }
        else {
            return (
                <tr key="0">
                    <td colSpan="2" className="alert alert-warning">
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.noCompaniesFound}
                    </td>
                </tr>
            );
        }
    }

    renderEanList() {
        if (this.state.searching) {
            return (
                <tr key="0">
                    <td>
                        <Loader inline={true}>{this.strings.searching}</Loader>
                    </td>
                </tr>
            )
        }
        else if (!this.state.eans) {
            return (
                <tr key="0">
                    <td className="alert alert-light">
                        {this.strings.resultsEans}
                    </td>
                </tr>
            )
        }
        else if (!this.state.eans.length) {
            return (
                <tr key="0">
                    <td className="alert alert-warning">
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.noEansFound}
                    </td>
                </tr>
            );
        }
        else if (this.state.eans.length) {
            return this.state.eans.map((ean, idx) => {
                return (
                    [<tr key={ean.ean}>
                        <td>
                            <div className="text-primary">
                                {ean.ean}
                            </div>
                            <div className="mt-3">
                                <table className="table table-sm">
                                    <tbody>
                                        <tr>
                                            <th className="pl-0 font-weight-normal w-50">{this.strings.address}:</th>
                                            <td className="pr-0">
                                                {
                                                    ean.linked_company ?
                                                        <span>{ean.linked_company.street} {ean.linked_company.number}<br />{ean.linked_company.zip} {ean.linked_company.city}</span>
                                                        :
                                                        <span>{ean.street} {ean.number}<br />{ean.zip} {ean.city}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="pl-0 font-weight-normal">{this.strings.dgo}:</th>
                                            <td className="pr-0">{ean.dgo || (ean.linked_company && ean.linked_company.dgo) || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th className="pl-0 font-weight-normal">{this.strings.meterNumber}:</th>
                                            <td className="pr-0">{ean.meterNumbers || (ean.linked_company && ean.linked_company.meterNumbers) || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th className="pl-0 font-weight-normal">{this.strings.direction}:</th>
                                            <td className="pr-0">{this.getDirection(ean.meterType || (ean.linked_company && ean.linked_company.meterType)) || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th className="pl-0 font-weight-normal">{this.strings.measuringMethod}:</th>
                                            <td className="pr-0">{this.getMeasuringMethod(ean.measuringMethod || (ean.linked_company && ean.linked_company.measuringMethod)) || 'N/A'}</td>
                                        </tr>
                                        {
                                            ean.linked_company ?
                                                <tr>
                                                    <th className="pl-0 font-weight-normal">{this.strings.linkedCompany}:</th>
                                                    <td className="pr-0">
                                                        <Link className="cursor-pointer text-primary clearfix" to={`/account/company/switch/${ean.linked_company.company_id}`} title={this.strings.clickSwitch}>
                                                            {ean.linked_company.companyName} - {ean.linked_company.companyNumber}
                                                            <i className="fas fa-info-circle float-right text-info mt-1" title={this.strings.linkedCompanyInfo} data-toggle="tooltip"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        <tr>
                                            <td className="pl-0" colSpan="2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <th className="pl-0 font-weight-normal text-primary" colSpan="2">{this.strings.suggestedCompanies}:</th>
                                        </tr>
                                        {
                                            ean.companies.length ?
                                                ean.companies.map(c => {
                                                    return (
                                                        <tr key={c.kbo}>
                                                            <td className="pl-0" colSpan="2">
                                                                <strong>{c.company}</strong>
                                                                <br />
                                                                <small>
                                                                    {c.kbo}
                                                                    <span>&nbsp;|&nbsp;</span>
                                                                    <span>{this.strings.phone}:&nbsp;
                                                                        {
                                                                            c.phone ?
                                                                                <a href={`tel:${c.phone}`}>{c.phone}</a>
                                                                                : 'N/A'
                                                                        }
                                                                    </span>
                                                                    <span>&nbsp;|&nbsp;</span>
                                                                    <span>{this.strings.email}:&nbsp;
                                                                        {
                                                                            c.email ?
                                                                                <a href={`mailto:${c.email}`}>{c.email}</a>
                                                                                : 'N/A'
                                                                        }
                                                                    </span>
                                                                    <span>&nbsp;|&nbsp;</span>
                                                                    <span>{this.strings.web}:&nbsp;
                                                                        {
                                                                            c.web ?
                                                                                <a href={`http://${c.web}`}>{c.web}</a>
                                                                                : 'N/A'
                                                                        }
                                                                    </span>
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="pl-0" colSpan="2">{this.strings.noLinkedCompanies}</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>,
                    idx === 0 && this.state.altEanFlag && this.state.eans.length > 1 ?
                        <tr key="0">
                            <td className="bg-warning text-body font-weight-normal">
                                {this.strings.altEans}
                            </td>
                        </tr>
                        : null
                    ]
                )
            })
        }
    }

    filterCompanies() {

        let companyFilter = this.state.companyFilter || ''
        let contractFilter = this.state.contractFilter || ''
        let partnerFilter = this.state.partnerFilter || ''
        let userFilter = this.state.userFilter || ''

        this.setState({
            isCompaniesLoading: true
        });

        filterCompanies({ name_or_kbo: companyFilter, contract: contractFilter, partner: partnerFilter, user: userFilter }, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                isCompaniesLoading: false,
                companies: res.companies,
                total_companies: res.count
            });
        });
    }

    deleteCompany(company_id) {

        deleteCompany(company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.status === 'OK') {
                    this.setState({
                        status: this.strings.companyDeleted,
                        isDeleted: true
                    }, () => {
                        if (this.props.selectedCompany.company_id === company_id) {

                            setTimeout(() => {
                                $('#status-company').modal('show')
                            }, 1000);
                        }
                    })
                } else {

                    this.setState({
                        status: res.error.has_sdps ? this.strings.sdpsAttached : this.strings.errorDelete
                    });

                    setTimeout(() => {
                        $('#status-company').modal('show')
                    }, 1000);
                }

            }
        )
    }

    closeDelete() {
        if (this.state.isDeleted) {
            this.props.updateCompanies();
            return true;
        } else {
            return false;
        }
    }

    showAllCompanies() {
        this.setState({
            hideCompanies: !this.state.hideCompanies
        })
    }

    onUserSearch(query) {
        this.setState({ isLoading: true });

        if (query.length) {
            getUsers(null /* no company id specified, search all users */, query, (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    isLoading: false,
                    options: res
                });
            });
        }
    }

    onUserChanged(selected) {
        const user = selected[0]

        this.setState({
            user,
            userFilter: user ? user.email : ''
        }, this.filterCompanies)
    }

    onFilterChange(e) {

        if (e.target.name === 'eanFilter')
            e.target.value = 'EAN' + e.target.value.replace(/[^\d]/g, '').toUpperCase().trim();

        if (e.target.name === 'eanKBOFilter')
            e.target.value = e.target.value.replace(/[^\d]/g, '').trim();

        this.setState({
            [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }, () => {

            if (['companyFilter'].includes(e.target.id)) {

                if (this.timer)
                    clearTimeout(this.timer);

                this.timer = setTimeout(this.filterCompanies, 500);
            }

            if (['contractFilter', 'partnerFilter'].includes(e.target.id))
                this.filterCompanies();
        })
    }

    onInputEanSearch(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.onEanSearch(e);
        }
    }

    onEanSearch(e) {

        if (!e.target.name) return;

        this.setState({
            eanError: false
        })

        if (e.target.name === 'eanFilter') {

            if (this.state.eanFilter.length === 21 /* must be full EAN number */) {
                this.setState({ searching: true, eans: undefined });

                getAdvancedEans(this.state.eanFilter, 'eanSearch', (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        searching: false,
                        altEanFlag: true,
                        eans: res
                    });
                });
            }
            else
                this.setState({
                    eanError: this.strings.eanError
                })

        } else if (e.target.name.startsWith('eanAddressFilter')) {

            const eanAddressFilter = `${document.getElementById('eanAddressFilter-streetName').value.trim()};${document.getElementById('eanAddressFilter-number').value.trim()};${document.getElementById('eanAddressFilter-city').value.trim()};${document.getElementById('eanAddressFilter-zip').value.trim()}`

            if (document.getElementById('eanAddressFilter-streetName').value.trim().length > 0) {
                this.setState({ searching: true, eans: undefined, eanFilter: '' });

                getAdvancedEans(eanAddressFilter, 'addressSearch', (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        searching: false,
                        altEanFlag: false,
                        eans: res
                    });
                });
            }
            else this.setState({
                eanError: this.strings.eanAddressError
            })

        } else if (e.target.name === 'eanKBOFilter') {

            this.setState({ searching: true, eans: undefined, eanFilter: '' });

            getAdvancedEans(this.state.eanKBOFilter, 'kboSearch', (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    searching: false,
                    altEanFlag: false,
                    eans: res
                });
            });

        }

    }

    getDirection(direction) {

        switch (direction) {
            case 'Afname':
                return this.strings.supply

            case 'Injectie':
                return this.strings.injection

            default:
                return 'N/A'
        }
    }

    getMeasuringMethod(method) {

        switch (method) {
            case 'Continu':
                return this.strings.continuous

            case 'Maandelijks':
                return this.strings.monthly

            case 'Jaarlijks':
                return this.strings.annually

            case 'Forfaitair':
                return this.strings.flat

            default:
                return 'N/A'
        }
    }

    render() {

        return (
            <div>
                <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
                <div className="row">
                    <div className="col-md px-2 px-xl-5 py-4">
                        <CtaPanel
                            company={this.props.selectedCompany}
                            info={this.props.info}
                            lang={this.props.lang}
                        />
                    </div>
                    <div className="col-md px-2 px-xl-5 py-4">
                        <CtaPanel
                            demo={this.props.demo}
                            info={this.props.demoInfo}
                            lang={this.props.lang}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm px-2 px-xl-5 py-3">
                        {
                            this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' || this.state.companies.length > 1 ?
                                <div>
                                    {
                                        this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                            <p className="alert alert-info mb-4 d-flex align-items-center p-3">
                                                <i className="fas fa-info-circle mr-2"></i> {this.strings.current}:&nbsp;<strong>{this.props.selectedCompany.companyName}</strong>
                                                <span className="ml-auto">
                                                    <Link className="btn bg-white text-primary text-decoration-none ml-3" to="/admin/new-customer">
                                                        <i className="fas fa-user mr-2"></i> <strong>{this.strings.add}</strong> {this.strings.customer}
                                                    </Link>
                                                </span>
                                            </p>
                                            : null
                                    }
                                    <div className="row">
                                        <div className="col">
                                            <div>
                                                <input className="form-control" placeholder={this.strings.filter} type="text" id="companyFilter" name="companyFilter" value={this.state.companyFilter || ''} onChange={this.onFilterChange} autoComplete="off" />
                                                <small className="form-text">&nbsp;</small>
                                                {
                                                    this.props.selectedCompany.role === 'admin' ?
                                                        <div className="d-flex flex-column flex-md-row">
                                                            <div className="mb-2 mr-sm-2 w-100">
                                                                <label htmlFor="contractFilter">{this.strings.contracts}</label>
                                                                <select className="form-control" id="contractFilter" name="contractFilter" value={this.state.contractFilter || ''} onChange={this.onFilterChange}>
                                                                    <option value=""></option>
                                                                    <option value="active">{this.strings.active}</option>
                                                                    <option value="ended">{this.strings.ended}</option>
                                                                    <option value="expired">{this.strings.expired}</option>
                                                                    <option value="unsigned">{this.strings.unsigned}</option>
                                                                    <option value="future">{this.strings.future}</option>
                                                                    <option value="unknown">{this.strings.unknown}</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-2 mr-sm-2 w-100">
                                                                <label htmlFor="partnerFilter">{this.strings.partner}</label>
                                                                <select className="form-control" id="partnerFilter" name="partnerFilter" value={this.state.partnerFilter || ''} onChange={this.onFilterChange} disabled={!this.state.partners}>
                                                                    <option value="">{!this.state.partners ? this.strings.loading : ''}</option>
                                                                    {
                                                                        this.state.partners && this.state.partners.map(partner => {
                                                                            return <option value={partner.id} key={partner.id}>{partner.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="mb-2 w-100">
                                                                <label htmlFor="userFilter">{this.strings.user}</label>
                                                                <AsyncTypeahead
                                                                    id="userFilter"
                                                                    clearButton
                                                                    className="mb-3"
                                                                    name="userFilter"
                                                                    filterBy={['firstName', 'lastName', 'fullName', 'fullNameReverse', 'email']}
                                                                    labelKey={option => `${option.email}`}
                                                                    renderMenu={(results, menuProps) => (
                                                                        <Menu {...menuProps}>
                                                                            {results.map((result, index) => (
                                                                                <MenuItem option={result} position={index} key={result.id}>
                                                                                    {result.firstName} {result.lastName}
                                                                                    <br />
                                                                                    <small className="text-muted"><span>{result.email}</span></small>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Menu>
                                                                    )}
                                                                    inputProps={{ name: 'userFilter', id: 'userFilter', className: 'required' }}
                                                                    defaultInputValue=""
                                                                    isLoading={this.state.isLoading}
                                                                    placeholder={this.strings.lookup}
                                                                    options={this.state.options}
                                                                    onSearch={this.onUserSearch}
                                                                    onChange={this.onUserChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>

                                            <table className="table table-hover companies-list">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {this.strings.companies}
                                                            {
                                                                this.state.companies ?
                                                                    <small className="font-weight-normal">&nbsp; &ndash; {this.strings.showing} {this.state.hideCompanies && this.state.companies.length > 10 ? 10 : this.state.companies.length} {this.strings.of} {this.state.total_companies || 'N/A'} {this.strings.companies2}</small>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                !this.state.isCompaniesLoading && this.state.hideCompanies && this.state.companies.length > 10 ?
                                                                    <small onClick={this.showAllCompanies}>
                                                                        &nbsp; &ndash; &nbsp;
                                                                        <strong className="cursor-pointer text-primary text-underline">
                                                                            {this.strings.showAll}
                                                                        </strong>
                                                                    </small>
                                                                    : null
                                                            }
                                                        </th>
                                                        {
                                                            this.props.selectedCompany.role === 'admin' ?
                                                                <th></th>
                                                                : null
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderCompanyList()}
                                                    {
                                                        this.state.hideCompanies && this.state.companies.length > 10 ?
                                                            <tr>
                                                                <td className="text-center bg-light" colSpan={this.props.selectedCompany.role === 'admin' ? 2 : null}>
                                                                    <div className="cursor-pointer text-primary font-weight-bold" onClick={this.showAllCompanies}>
                                                                        {this.strings.showAll}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
                                                <div className="col-md-6">
                                                    <ul className="nav nav-pills small" role="tablist">
                                                        <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#ean" role="tab">{this.strings.searchByEAN}</a> </li>
                                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#address" role="tab">{this.strings.searchByADDRESS}</a> </li>
                                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#kbo" role="tab">{this.strings.searchByKBO}</a> </li>
                                                    </ul>

                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="ean" role="tabpanel">
                                                            <div className="input-group">
                                                                <input className="form-control" placeholder={this.strings.searchEan} type="text" id="eanFilter" name="eanFilter" value={this.state.eanFilter || ''} onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="EAN number" maxLength="21" minLength="21" autoComplete="off" />
                                                                <div className="input-group-append">
                                                                    <button type="button" onClick={() => this.onEanSearch({ target: { name: 'eanFilter' } })} className="btn btn-primary text-white px-3 border-dark">
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane" id="address" role="tabpanel">
                                                            <div className="input-group">
                                                                <input className="form-control" style={{ flexGrow: 2.2 }} placeholder={this.strings.searchByAddress_streetName} type="text" id="eanAddressFilter-streetName" name="eanAddressFilter-streetName" onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="Address" autoComplete="off" />
                                                                <input className="form-control" placeholder={this.strings.searchByAddress_number} type="text" id="eanAddressFilter-number" name="eanAddressFilter-number" onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="Address" autoComplete="off" />
                                                                <input className="form-control" placeholder={this.strings.searchByAddress_zip} type="text" id="eanAddressFilter-zip" name="eanAddressFilter-zip" onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="Address" autoComplete="off" />
                                                                <input className="form-control" placeholder={this.strings.searchByAddress_city} type="text" id="eanAddressFilter-city" name="eanAddressFilter-city" onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="Address" autoComplete="off" />
                                                                <div className="input-group-append ">
                                                                    <button type="button" onClick={() => this.onEanSearch({ target: { name: 'eanAddressFilter' } })} className="btn btn-primary text-white px-3 border-dark">
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane" id="kbo" role="tabpanel">
                                                            <div className="input-group">
                                                                <input className="form-control" placeholder={this.strings.searchEanByKBO} type="text" id="eanKBOFilter" name="eanKBOFilter" value={this.state.eanKBOFilter || ''} onChange={this.onFilterChange} onKeyDown={this.onInputEanSearch} aria-label="KBO number" autoComplete="off" />
                                                                <div className="input-group-append">
                                                                    <button type="button" onClick={() => this.onEanSearch({ target: { name: 'eanKBOFilter' } })} className="btn btn-primary text-white px-3 border-dark">
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <small className={'form-text text-danger ' + (this.state.eanError ? 'mb-1' : 'invisible')}>{this.state.eanError ? this.state.eanError : '*'}</small>
                                                    {
                                                        this.props.selectedCompany.role === 'admin' ?
                                                            <div className="mt-n1 mb-2 mr-sm-2 w-100">
                                                                <div className="alert alert-info border border-info mb-4">
                                                                    <i className="fas fa-info-circle mr-2"></i> {this.strings.lookupEan}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                    <table className="table companies-list">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {this.strings.ean}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderEanList()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className="modal fade" id="delete-company" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <p>
                                                        {this.strings.confirmDelete} <strong>{this.state.deleteCompany.companyName}</strong>? {this.strings.deleteWarning}
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { this.deleteCompany(this.state.deleteCompany.companyNumber) }}>{this.strings.delete}</button>
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="status-company" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <p>
                                                        {this.state.status}
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeDelete}>{this.strings.close}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="alert alert-light p-4">
                                    <h5>
                                        <i className="fas fa-info-circle mr-2"></i> {this.strings.newCustomerTitle}
                                    </h5>
                                    <p>
                                        {this.strings.newCustomerDesc} <strong><a href="mailto:support@yuso.be" target="_blank" rel="noopener noreferrer" className="text-primary text-decoration-none">support@yuso.be</a></strong>.
                                    </p>
                                    <br />
                                    <div className="text-center">
                                        <a href="/info" target="_blank" rel="noopener noreferrer" className="btn btn-primary text-white p-3 text-decoration-none">{this.strings.info}</a>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
