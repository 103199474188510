import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import IBAN from 'iban';

import { getPendingAgreement } from '../../api/documents';
import { signContract } from '../../api/user';

moment.tz.setDefault('Europe/Brussels')

class PendingAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreement: undefined,
      contractSigned: false,
      signature: null,
      hasSignature: false
    };

    this.iframe = 'agreement'

    this.updateAgreement = this.updateAgreement.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onEditContract = this.onEditContract.bind(this);
    this.onSignContract = this.onSignContract.bind(this);
    this.closeStartTooltip = this.closeStartTooltip.bind(this);
    this.openFinishTooltip = this.openFinishTooltip.bind(this);
    this.confirmSigning = this.confirmSigning.bind(this);
    this.guideUser = this.guideUser.bind(this);
    this.validEmail = this.validEmail.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading agreement...",
        error: "Error while loading the agreement.",
        notFound: "No agreement found.",
        backToAgreements: "Back to agreements",
        contractEmailed: "Your signed document was emailed to",
        newSignFeature: "NEW! Fill and sign documents online!",
        newSignFeatureInfo: "You can now fill and sign your documents in your browser without downloading, printing, signing, and scanning them.",
        newSignFeatureStart: "Let's start",
        submitAction: "Submit contract!",
        submitActionInfo: "Click to submit your signed contract.",
        fillAndSign: "Fill out and Sign",
        contract: "contract",
        mandate: "mandate",
        print: "Print",
        edit: "Edit",
        printAnyway: "Print anyway",
        submit: "Submit signed",
        confirmSigning: "Confirm contract signature",
        confirmSigningInfo1: "When you click on the",
        confirmSigningInfo2: "button below you'll get into legally binding agreement with",
        confirmSigningInfo3: "A copy of the signed document will be sent to your user's email",
        confirm: "Confirm",
        cancel: "Cancel",
        thinkBeforePrinting: "Save the environment",
        thinkBeforePrintingInfo: "Did you know you can fill and sign the documents directly in your browser without printing and scanning them?",
        clearSignature: "Clear signature",
        editField: "Edit field",
        next: "Next",
        prev: "Prev",
        exit: "Exit",
        done: "Done"
      },
      nl: {
        loading: "Overeenkomst worden geladen...",
        error: "Fout tijdens het laden van de overeenkomst.",
        notFound: "Geen overeenkomst gevonden.",
        backToAgreements: "Terug naar overeenkomsten",
        contractEmailed: "Uw ondertekende document is gemaild naar",
        newSignFeature: "NIEUW! Documenten online invullen en ondertekenen!",
        newSignFeatureInfo: "U kunt nu uw documenten in uw browser invullen en ondertekenen zonder ze te downloaden, af te drukken, te ondertekenen en te scannen.",
        newSignFeatureStart: "Laten we beginnen",
        submitAction: "Contract indienen!",
        submitActionInfo: "Klik om uw ondertekende contract in te dienen.",
        fillAndSign: "Invullen en Tekenen",
        contract: "contract",
        mandate: "mandaat",
        print: "Afdrukken",
        printAnyway: "Print toch",
        edit: "Bewerk",
        submit: "Verzend ondertekend",
        confirmSigning: "Bevestig de ondertekening van het contract",
        confirmSigningInfo1: "Wanneer u op het",
        confirmSigningInfo2: "knop hieronder waarmee u een juridisch bindende overeenkomst aangaat",
        confirmSigningInfo3: "Een kopie van het ondertekende document wordt naar het e-mailadres van uw gebruiker gestuurd",
        cancel: "Annuleren",
        thinkBeforePrinting: "Bespaar tijd en papier",
        thinkBeforePrintingInfo: "Wist u dat u de documenten rechtstreeks in uw browser kunt invullen en ondertekenen zonder ze af te drukken en te scannen?",
        clearSignature: "Duidelijke handtekening",
        editField: "Bewerk veld",
        next: "Volgende",
        prev: "Vorige",
        done: "Klaar"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (parseInt(nextProps.company_id) !== parseInt(nextProps.selectedCompany.company_id)) {

      let url = window.location.pathname.replace(nextProps.company_id.toString(), nextProps.selectedCompany.company_id.toString())

      return { redirectTo: url }
    }
    else

      return null
  }

  componentDidMount() {
    this.updateAgreement();
  }

  componentDidUpdate() {

    $('#sign-contract-btn').tooltip({
      container: '.sign-button-wrap',
      trigger: 'manual'
    });

    if (!this.state.contractSigning && !this.state.contractSigned) {

      $('#fill-contract-btn').tooltip({
        container: '.fill-button-wrap',
        trigger: 'manual'
      });

      setTimeout(() => {
        $('#fill-contract-btn').tooltip('show');
        $('.tooltip-close').on('click', e => $('#fill-contract-btn').tooltip('hide'));
        $('#fill-contract-tooltip-btn').on('click', e => $('#fill-contract-btn').trigger('click'));
      }, 100);
    }
    if (this.state.hasSignature) {
      const canvas = this.canvasRef.current;
      const context = canvas.getContext('2d');
      const signatureImage = new Image();
      signatureImage.src = this.state.signature;

      signatureImage.onload = () => {
        context.drawImage(signatureImage, 0, 0);
      };
    }
  }

  updateAgreement() {
    if (!this.props.selectedCompany) return;

    getPendingAgreement(
      this.props.company_id,
      this.props.type,
      this.props.lang,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          if (res.error.not_found) {
            this.setState({
              error: this.strings.notFound
            });
          }
          else {
            this.setState({
              error: this.strings.error
            });
          }
        }
        else {
          this.setState({
            agreement: res || { contract_html: 'about:blank' }
          }, () => {
            window.frames[this.iframe].document.open('text/html', 'replace')
            window.frames[this.iframe].document.write(this.state.agreement.contract_html)
            window.frames[this.iframe].document.close()

            const timer = setInterval(() => {

              // get the iframe window object
              const contract = window.frames[this.iframe]

              if (!contract.initGuideSuccess) {

                // init the guide in the iframe with props
                if (contract.initGuide)
                  contract.initGuide({
                    strings: this.strings,
                    iban: IBAN,
                    email: { isValid: this.validEmail },
                    moment,
                    type: this.props.type,
                    fn: {
                      closeStartTooltip: this.closeStartTooltip,
                      openFinishTooltip: this.openFinishTooltip
                    }
                  })
              }
              else if (timer)
                clearInterval(timer)
            }, 100);
          });
        }
      }
    );
  }

  onClickPrint() {
    window.frames[this.iframe].focus();
    let oldTitle = document.title
    document.title = `${this.state.agreement.companyName} (${this.state.agreement.companyNumber}) ${this.props.type} agreement`.toUpperCase()
    window.frames[this.iframe].print();
    document.title = oldTitle
  }

  onEditContract() {
    window.document.getElementById('agreement')
      .contentWindow.document.body.querySelectorAll('*').forEach(val => {
        val.setAttribute('contenteditable', 'true')
        val.setAttribute('spellcheck', 'false')
      })
  }

  onSignContract() {

    const contract_data = window.frames[this.iframe].getContractData()

    // only save signed contract if there's no errors
    if (!contract_data.error) {

      localStorage.setItem('signature', JSON.stringify(contract_data.signature));

      this.setState({ error: false, contractSigning: true })

      const data = {
        lang: this.props.lang,
        user_id: this.props.selectedCompany.contact_id,
        user: {
          email: this.props.selectedCompany.email,
          firstName: this.props.selectedCompany.firstName
        },
        type: {
          [this.props.type]: true
        },
        contract_data
      }

      signContract(this.props.selectedCompany.company_id, data,
        (err, res) => {
          if (err)
            return window.reportError.send(err);

          if (res.status === 'OK') {
            this.setState({ info: this.strings.contractEmailed, contractSigned: true })
          }

          this.setState({ error: false, contractSigning: false })
        })
    }
  }

  closeStartTooltip() {
    $('#fill-contract-btn, #sign-contract-btn').tooltip('hide')
  }

  openFinishTooltip() {
    $('#sign-contract-btn').tooltip('show');
    $('.tooltip-close').on('click', e => $('#sign-contract-btn').tooltip('hide'));
  }

  confirmSigning() {

    $('#sign-contract-btn').tooltip('hide')

    const contract_data = window.frames[this.iframe].getContractData()

    // show the confrimation modal only if there's no errors
    if (!contract_data.error)
      $('#confirm-contract-signing').modal('show')
  }

  guideUser() {
    // get the iframe window object
    const contract = window.frames[this.iframe]

    // start the guide in the iframe
    contract.startGuide()
  }

  validEmail(email) {
    return email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
  }

  render() {

    if (this.state.redirectTo)
      return <Redirect to={this.state.redirectTo} />

    if (this.state.error) {
      return (
        <div className="text-center">
          <div className="spacer"></div>
          <div className="spacer"></div>
          <h4 className="font-weight-light text-muted mb-4">{this.state.error}</h4>
          <Link to="/documents/agreements" className="btn btn-secondary">{this.strings.backToAgreements}</Link>
          <div className="spacer"></div>
          <div className="spacer"></div>
        </div>
      )
    }

    if (!this.state.agreement) {
      return (<Loader>{this.strings.loading}</Loader>);
    }

    return (
      <div className="row">
        <div className="col-sm p-0">
          <div className="container p-0 contract-actions-placeholder"></div>
          <div className="container contract-actions p-2 border-bottom border-light d-flex align-items-center justify-content-between position-fixed w-100">
            <div className="fill-button-wrap">
              <button
                type="button"
                id="fill-contract-btn"
                className="btn btn-primary px-3 mr-2"
                data-placement="bottom"
                data-html={true}
                title={`<div class="position-relative p-3">
                  <i class="fas fa-times text-light position-absolute cursor-pointer tooltip-close"></i>
                  <div class="mb-2 tooltip-header">
                    <strong>${this.strings.newSignFeature}</strong>
                  </div>
                  <div class="tooltip-body">
                    ${this.strings.newSignFeatureInfo}
                    <br/>
                    <span class="btn btn-success mt-3" id="fill-contract-tooltip-btn">${this.strings.newSignFeatureStart} <i class="fas fa-chevron-right ml-1"></i></span>
                  </div>
                </div>`}
                onClick={this.guideUser}
              >
                <i className="fas fa-file-signature mr-2"></i> {this.strings.fillAndSign}
              </button>

              <button
                type="button"
                className="btn btn-light px-3"
                data-toggle="modal"
                data-target="#print-contract"
              >
                <i className="fas fa-print mr-2"></i> {this.strings.print}
              </button>
            </div>

            <div className={'text-primary ' + (this.state.info ? '' : 'd-none')}>
              <i className="fas fa-check mr-2"></i> {this.state.info} {this.props.selectedCompany.email}
            </div>

            <div className="sign-button-wrap">
              {
                this.props.selectedCompany.role === 'admin' ?
                  <button
                    type="button"
                    className="btn btn-light px-3 mr-2"
                    onClick={this.onEditContract}
                  >
                    <i className="fas fa-pencil-alt mr-2"></i> {this.strings.edit}
                  </button>
                  : null
              }

              <button
                type="button"
                id="sign-contract-btn"
                className="btn btn-danger px-3"
                disabled={this.state.contractSigning}
                onClick={this.confirmSigning}
                data-placement="bottom"
                data-html={true}
                title={`<div class="position-relative p-3">
                  <i class="fas fa-times text-light position-absolute cursor-pointer tooltip-close"></i>
                  <div class="mb-2 tooltip-header">
                    <strong>${this.strings.submitAction}</strong>
                  </div>
                  <div class="tooltip-body">
                    ${this.strings.submitActionInfo}
                  </div>
                </div>`}
              >
                <i className={`mr-2 fas ${this.state.contractSigning ? 'fa-spinner fa-spin' : 'fa-cloud-upload-alt'}`}></i> {this.strings.submit} {this.props.type === 'mandate' ? this.strings.mandate : this.strings.contract}
              </button>
            </div>

          </div>
          <iframe name="agreement" id="agreement" width="100%" height="800px" title="agreement"></iframe>
          {this.state.hasSignature ? (
            <canvas
              className="signature-pad"
              data-field="signature"
              ref={this.canvasRef}
            ></canvas>
          ) : null}

          {/* <iframe name="agreement" id="agreement" width="100%" height="800px" title="agreement"></iframe> */}
        </div>

        <div className="modal fade" id="confirm-contract-signing" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <i className="fas fa-exclamation-triangle mr-2 text-warning"></i> {this.strings.confirmSigning}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  {this.strings.confirmSigningInfo1} <strong>{this.strings.confirm}</strong> {this.strings.confirmSigningInfo2} <strong>Yuso BV</strong>. {this.strings.confirmSigningInfo3} <strong><a href={'mailto:' + this.props.selectedCompany.email}>{this.props.selectedCompany.email}</a></strong>.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                <button type="button" className="btn btn-danger ml-auto" data-dismiss="modal" onClick={this.onSignContract}>
                  <i className="fas fa-check mr-2"></i> {this.strings.confirm}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="print-contract" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <i className="fas fa-leaf mr-2 text-success"></i> {this.strings.thinkBeforePrinting}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  {this.strings.thinkBeforePrintingInfo}
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.onClickPrint}>
                  <i className="mr-2 fas fa-print"></i> {this.strings.printAnyway}
                </button>
                <button type="button" className="btn btn-primary ml-auto" data-dismiss="modal" onClick={this.guideUser}>
                  <i className="fas fa-file-signature mr-2"></i> {this.strings.fillAndSign}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PendingAgreement;