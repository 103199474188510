import $ from 'jquery'
import 'bootstrap/js/dist/modal'
import React, { Component } from 'react';
import SubHeader from '../components/SubHeader';
import { approveUser, getUserInfoForApproval, rejectUser } from "../api/user";
import LocalizedStrings from "../components/Localization";

class ApprovalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      approved: false,
      rejected: false,
      user: this.props.user,
      lang:this.props.lang,
      managedBy: this.props.selectedCompany.fullName
    };

    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.fetchUserInfoForApproval = this.fetchUserInfoForApproval.bind(this);
    this.renderUserInfoTable = this.renderUserInfoTable.bind(this);
    
    this.strings = LocalizedStrings(
      {
        en: {
          welcome: "Welcome",
          theUser:"The user ",
          request: " is requesting a login for My Yuso",
          user: "User",
          message: "Please confirm or reject this request with the buttons below. ",
          linkValidation: "This link is valid for 72 hours.",
          approveButton:"Approve",
          rejectButton: "Reject",
          close: "Close",
          approved: "The user was successfully approved.",
          rejected: "The user was successfully rejected.",
          errorApprove: "There was an error while approving the user. Please try again.",
          errorReject: "There was an error while rejecting the user. Please try again.",
          confirmApprove: "Do you want to approve this user?",
          confirmReject: "Do you want to reject this user?",
          fullName: "Full Name",
          email: "Email",
          company: "Company"

        },
        nl: {
          welcome: "Welkom",
          beste:"Beste, de gebruiker vraagt toegang tot bedrijf XX on My Yuso.",
          user: "gebruiker",
          message:"Gelieve deze aanvraag te bevestigen of annuleren met de onderstaande knoppen. Deze link is 72 uur geldig.",
          theUser:"De gebruiker ",
          request: " vraagt een login aan voor My Yuso",
          approveButton:"Goedkeuren",
          rejectButton: "Weigeren",
          close: "Sluiten",
          approved: "De gebruiker is succesvol goedgekeurd.",
          rejected: "De gebruiker is succesvol afgewezen.",
          errorApprove: "Er is een fout opgetreden bij het goedkeuren van de gebruiker. Probeer het opnieuw.",
          errorReject: "Er is een fout opgetreden bij het weigeren van de gebruiker. Probeer het opnieuw.",
          fullName: "Volledige naam",
          email: "Email",
          company: "Bedrijf"
        },
      },
      this.props.lang
    );
  }

  componentDidMount() {
    this.fetchUserInfoForApproval(this.props.selectedCompany.company_id, this.state.user
      );
  }

  fetchUserInfoForApproval (companyId, user) {
    getUserInfoForApproval(companyId, user, (err, res) => {
      if (err) {
        window.reportError.send(err);
      } else {

        this.setState({
          userInfo: res,
        });
      }
    });
  };
  
  handleApprove() {
    const { userInfo, managedBy, user_status } = this.state; 

    if (!userInfo || userInfo.length === 0) {
      return;
    }
    
    const { id, email, firstName, lastName, companyName, company_id, created_at } = userInfo[0];
    const { user, lang, strings } = this.state;
    const userData = {
      user,
      id,
      email,
      firstName,
      lastName,
      created_at,
      companyName,
      company_id,
      managedBy,
      user_status,
      lang,
      strings
    };
  
    approveUser(userData,
        (err, res) => {
            if (err)
                return window.reportError.send(err);
            if (res.error) {
              this.setState({
                user_status: this.strings.errorApprove
              })

                  $('#status-user').modal('show')
              }

            if (res.status === 'OK') {
                this.setState({ user_status: this.strings.approved, approved: true })
                }
                $('#status-user').modal('show')
              }
    )}

  handleReject() {
    const { userInfo, managedBy, user_status } = this.state; 

    if (!userInfo || userInfo.length === 0) {
      return;
    }
  
  const { id, email, firstName, lastName, companyName, company_id } = userInfo[0];
  const { user, lang, strings } = this.state;
  const userData = {
    user,
    id,
    email,
    firstName,
    lastName,
    companyName,
    company_id,
    managedBy,
    user_status,
    lang,
    strings
  };

  rejectUser(userData,
      (err, res) => {
          if (err)
              return window.reportError.send(err);
          if (res.error) {
            this.setState({
              user_status: this.strings.errorReject
            })

                $('#status-user').modal('show')
            }

          if (res.status === 'OK') {
              this.setState({ user_status: this.strings.rejected, rejected: true })           
              }
              $('#status-user').modal('show')
            }
  )}

renderUserInfoTable () {
  const { userInfo} = this.state;
  const user = userInfo[0];

  if (userInfo.length === 0) {
    return <p>No user information available.</p>;
  }
  
  return (
    <table className="table table-striped table-sm">
    <thead>
      <tr>
        <th></th>
        <th scope="col">{this.strings.fullName}</th>
        <th scope="col">{this.strings.email}</th>
        <th scope="col">{this.strings.company}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>{user.firstName} {user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.companyName}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
  );
};
  
  render() {
    const { userInfo } = this.state;
    const user = userInfo[0];
    const todayDate = new Date();
    const createdAtDate = user ? new Date(user.created_at) : null;
    const timeDifference = createdAtDate ? todayDate - createdAtDate : null;
    const hoursDifference = timeDifference ? timeDifference / (1000 * 60 * 60) : null;
    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
         
{
  // Check if the link is expired
  hoursDifference && hoursDifference > 72 && this.props.selectedCompany.role !== 'admin' ? (
    // View when time difference is greater than 72 hours
    <div>
      <h3 style={{textAlign: 'center', paddingTop:'2rem'}}>Link expired.</h3>
    </div>
  ) : (
    // Check if user is already approved
    this.state.userInfo.length > 0 && this.state.userInfo[0].approved !== null ? (
      // View when user is already approved
      <div>
        <h3 style={{ textAlign: 'center', paddingTop: '2rem' }}>The user has already been{' '}
    {this.state.userInfo.length > 0 && this.state.userInfo[0].approved === 1
      ? 'approved'
      : 'rejected'}
    .</h3>
      </div>
    ) :
    userInfo.length > 0 ? (
      // View when user is not approved and link is not expired
      <>
        <div>
          <h3 style={{ margin: '20px 0px 20px' }}>
            {this.strings.theUser}
            {user ? `${user.firstName} ${user.lastName}` : ''}
            {this.strings.request}
          </h3>
        </div>
        <div>
          <p>
            {this.strings.message}
            {this.props.selectedCompany.role !== 'admin' ? this.strings.linkValidation : ''}
          </p>
          {this.renderUserInfoTable()}
          <div className="d-flex justify-content-left">
            <button onClick={this.handleApprove} className="btn btn-success mx-2" disabled={this.state.approved}>
              {this.strings.approveButton}
            </button>
            <button onClick={this.handleReject} className="btn btn-danger mx-2" disabled={this.state.rejected}>
              {this.strings.rejectButton}
            </button>
          </div>
        </div>
      </>
    ) : 
    <h3 style={{textAlign: 'center', paddingTop:'2rem'}}>No user information available</h3>
  )
}
          <div className="modal fade" id="status-user" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <p>
                            {this.state.user_status}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                    </div>
                </div>
            </div>
          </div>
        </div>  
    );
  }
}

export default ApprovalUser;