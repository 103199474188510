import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone'
// import numeral from 'numeral';

import DropdownPanel from '../../components/DropdownPanel';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

moment.tz.setDefault('Europe/Brussels')


class Production extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false,
            isArchived: false
        }

        this.toggleDetails = this.toggleDetails.bind(this);

        this.strings = LocalizedStrings({
            en: {
                fetching: "Fetching production points...",
                notConnected: "You have not yet connected any production points to Yuso.",
                getStarted: "To get started, please",
                addSDP: "add new production point",
                technical: "Technical data",
                point: "of the production point",
                preswitchDate: "Latest preswitch",
                more: "More",
                less: "Less",
                details: "details",
                ean: "EAN",
                meterType: "Meter type",
                dataAvailableFrom: "Data available from",
                technology: "Technology",
                operator: "Distribution operator",
                tariff: "Tariff type",
                voltage: "Voltage level",
                nace: "NACE code",
                address: "Address",
                mandate: "Meterdata permissions",
                validMandate: "Valid mandate",
                endedMandate: "Ended mandate",
                requestSent: "Mandate request sent to customer",
                noMaterDataPermissions: "No meter data permissions",
                contract: "Contract parameters",
                formula: "Formula",
                epexHourly: "EPEX Spot BE DA hourly prices",
                fee: "Platform fee",
                gsc: "Cost GSC",
                wkc: "Cost WKC",
                certificate_price: "EUR per certificate with contribution factor of",
                start: "Start contract",
                end: "End contract",
                endDateNotIncluded: "The contract ends on",
                endDateNotIncluded2: ", not including that date.",
                month: "Extended month-to-month",
                volume: "Annual volume",
                capacity: "Power capacity",
                installation: "Installation ID",
                noSignedContract1: "No active contract yet.",
                noSignedContract2: "You can view your contract here.",
                endedContract1: "You contract ended on",
                endedContract2: "You can renew it here.",
                serviceComponent: "Service Component",
                serviceConfiguration: "Service Configuration"
            },
            nl: {
                fetching: "Leveringspunten ophalen...",
                notConnected: "U hebt nog geen productiepunten aangesloten bij Yuso.",
                getStarted: "Om te beginnen,",
                addSDP: "voeg nieuw leveringspunt toe",
                serviceComponent: "Service Component",
                serviceConfiguration: "Service Configuratie",
                technical: "Technische data",
                point: "van het leveringspunt",
                preswitchDate: "Laatste preswitch",
                more: "Meer",
                less: "Minder",
                details: "gegevens",
                ean: "EAN",
                meterType: "Metertype",
                dataAvailableFrom: "Gegevens beschikbaar vanaf",
                technology: "Technologie",
                operator: "Distributienetbeheerder",
                tariff: "Tarieftype",
                voltage: "Spanningsniveau",
                nace: "NACE-code",
                address: "Adres",
                mandate: "Meterdata-machtigingen",
                validMandate: "Geldig mandaat",
                endedMandate: "Mandaat beëindigd",
                requestSent: "Mandaatverzoek verzonden naar klant",
                noMaterDataPermissions: "Geen machtigingen voor metergegevens",
                contract: "Contract parameters",
                formula: "Formule",
                epexHourly: "EPEX Spot BE DA uurtarieven",
                fee: "Platformkosten",
                gsc: "Kosten GSC",
                wkc: "Kosten WKC",
                certificate_price: "EUR per certificaat met bijdrage factor van",
                start: "Start contract",
                end: "Einde contract",
                endDateNotIncluded: "Het contract eindigt op",
                endDateNotIncluded2: ", exclusief die datum.",
                month: "Automatische maandelijks verlengd",
                volume: "Jaarvolume",
                capacity: "Vermogen capaciteit",
                installation: "Installatie ID",
                noSignedContract1: "Nog geen actief contract.",
                noSignedContract2: "U kunt uw contract hier bekijken.",
                endedContract1: "Uw contract is geëindigd op",
                endedContract2: "Je kunt het hier vernieuwen.",
            }
        }, this.props.lang);
    }

    toggleDetails() {
        this.setState({
            showDetails: !this.state.showDetails
        })
    }

    render() {
        if (!this.props.sdps) {
            return (
                <div className="mb-3">
                    <Loader inline="true">{this.strings.fetching}</Loader>
                </div>
            );
        }

        if ((this.props.sdps.sdps_production.length) === 0) {
            return (
                <div className="list-group mb-3">
                    <p>{this.strings.notConnected}</p>
                    <p>{this.strings.getStarted} <Link to="/services/onboarding#sdp">{this.strings.addSDP}</Link>.</p>
                </div>
            );
        }

        return (
            <div className="list-group">
                {
                    this.props.sdps.sdps_production.map(
                        (sdp) => {
                            return (
                            sdp.archived && !this.props.isArchived ? null :
                                <DropdownPanel
                                    key={sdp.ean}
                                    sdp={sdp}
                                    isCollapsed="true"
                                    ean={sdp.ean}
                                    installation_id={sdp.installation_id}
                                    type={sdp.type}
                                    address={`${sdp.addressStreet} ${sdp.addressNumber}, ${sdp.addressZip} ${sdp.addressCity}, ${sdp.addressCountry}`}
                                    setDeleteSdp={this.props.setDeleteSdp}
                                    setArchiveSdp={this.props.setArchiveSdp}
                                    selectedCompany={this.props.selectedCompany}
                                    lang={this.props.lang}
                                    archived={sdp.archived}
                                    isArchived={this.state.isArchived}
                                >
                                    <div className="d-flex align-items-center mt-4 mb-3">
                                        <h4 className="text-primary font-weight-light m-0"><strong>{this.strings.technical}</strong> {this.strings.point}</h4>
                                        <span className="link text-secondary cursor-pointer text-underline ml-auto" onClick={this.toggleDetails}>{(!this.state.showDetails ? this.strings.more : this.strings.less)} {this.strings.details}</span>
                                    </div>
                                    <table className="table table-striped table-sm">
                                        <tbody>
                                            <tr>
                                                <td>{this.strings.ean}</td><td>{sdp.ean}</td>
                                            </tr>
                                            {
                                                this.state.showDetails ?
                                                    [
                                                        <tr>
                                                            <td>{this.strings.installation}</td><td>{sdp.installation_id}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.meterType}</td><td>{sdp.meterType}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.dataAvailableFrom}</td><td>{sdp.first_mv_dtlt}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.technology}</td><td>{sdp.technology}</td>
                                                        </tr>
                                                    ] : null
                                            }
                                            <tr>
                                                <td>{this.strings.operator}</td><td>{sdp.dgo}</td>
                                            </tr>
                                            {
                                                this.state.showDetails ?
                                                    [
                                                        <tr>
                                                            <td>{this.strings.tariff}</td><td>{sdp.dgo_tariff_id}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.voltage}</td><td>{sdp.voltageLevel}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.nace}</td><td>{sdp.nacebelCode}</td>
                                                        </tr>
                                                    ] : null
                                            }
                                            <tr>
                                                <td>{this.strings.address}</td>
                                                <td>
                                                    {sdp.addressStreet} {sdp.addressNumber}, {sdp.addressZip} {sdp.addressCity}, {sdp.addressCountry}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.strings.serviceComponent}</td>
                                                <td>
                                                    {sdp.service_component ? sdp.service_component : 'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.strings.serviceConfiguration}</td>
                                                <td>
                                                    {sdp.service_config ? sdp.service_config : 'N/A'}
                                                </td>
                                            </tr>
                                            {
                                                this.state.showDetails ?
                                                    [
                                                        <tr>
                                                            <td>{this.strings.volume}</td><td>{(sdp.volume && sdp.volume !== 0 && sdp.volume !== null) ? sdp.volume + ' MWh' : 'N/A'}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.capacity}</td><td>{(sdp.power && sdp.power !== 0 && sdp.power !== null) ? sdp.power + ' kW' : 'N/A'}</td>
                                                        </tr>,
                                                        <tr>
                                                            <td>{this.strings.preswitchDate}</td><td>{sdp.preswitch_dtlt !== null ? moment(sdp.preswitch_dtlt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A'}</td>
                                                        </tr>
                                                    ] : null
                                            }
                                        </tbody>
                                    </table>
                                    <h4 className="text-primary mt-4 mb-3 font-weight-light"><strong>{this.strings.mandate}</strong> {this.strings.point}</h4>
                                    <table className="table table-striped table-sm">
                                        <tbody>
                                            <tr>
                                                <td>{this.strings.mandate}</td>
                                                <td>{sdp.mandate ? sdp.validTo === null || moment(sdp.validTo).isAfter(moment()) ? this.strings.validMandate : this.strings.endedMandate : sdp.mandateSent ? this.strings.requestSent : this.strings.noMaterDataPermissions}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr className="mb-4" />
                                </DropdownPanel>
                            );
                        }
                    )
                }
            </div>
        );
    }
}

export default Production;
