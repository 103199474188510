window.reactStorage = {
  setItem: (key, value) => {
    document.dispatchEvent(new CustomEvent('setItem', { detail: { key: key, value: value } } ));

    return window.localStorage.setItem(key, value);
  },
  getItem: key => {
    document.dispatchEvent(new CustomEvent('getItem', { detail: { key: key } } ));

    return window.localStorage.getItem(key);
  },
  removeItem: key => {
    document.dispatchEvent(new CustomEvent('removeItem', { detail: { key: key } } ));

    return window.localStorage.removeItem(key);
  },
  key: key => {
    document.dispatchEvent(new CustomEvent('key', { detail: { key: key } } ));
  
    return window.localStorage.key(key);
  },
  getItems: () => {
    return window.localStorage
  },
  clear: () => {
    document.dispatchEvent(new CustomEvent('clear'));
    
    return window.localStorage.clear();
  },
  length: window.localStorage.length
}

window.reportError = {
  send: error => {
    document.dispatchEvent(new CustomEvent('appError', { detail: { error: error } } ));
    // log the error to console
    console.warn(error);
  }
}

module.exports = {
  reactStorage: window.reactStorage,
  reportError: window.reportError
};