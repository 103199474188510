import React, { Component } from 'react';
import moment from 'moment-timezone'

import Loader from '../Loader';
import LocalizedStrings from '../Localization';

import { getSignedMandates, downloadMandate } from '../../api/documents';

moment.tz.setDefault('Europe/Brussels')

class DownloadMandate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signedMandates: undefined,
      include_eans: 'none'
    }

    this.onFieldChange = this.onFieldChange.bind(this);
    this.downloadMandate = this.downloadMandate.bind(this);

    this.strings = LocalizedStrings({
      en: {
        error: "Error while downloading the mandate. Please try again.",
        errors: "These errors occurred:",
        mandate: "Download Mandate",
        signed: "Signed mandates",
        noMandate: "No mandates found.",
        downloadMandate: "Download empty mandate",
        includeAllEANs: "Include all EANs",
        includeEANsWithoutContracts: "Include EANs without contracts",
        noEANs: "Download empty mandate",
        download: "Download",
        noEANsFound: "No EANs found"
      },
      nl: {
        error: "Fout tijdens het downloaden van het mandaat. Probeer het opnieuw.",
        errors: "Deze fouten zijn opgetreden:",
        mandate: "Mandaat downloaden",
        signed: "Getekende mandaten",
        noMandate: "Geen mandaten gevonden.",
        downloadMandate: "Download leeg mandaat",
        includeAllEANs: "Neem alle EANs op",
        includeEANsWithoutContracts: "Neem EANs op zonder contracten",
        noEANs: "Download leeg mandaat",
        download: "Download",
        noEANsFound: "Geen EANs gevonden"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    getSignedMandates(
      this.props.selectedCompany.company_id,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({
          signedMandates: res
        });
      });
  }

  onFieldChange(e) {
    this.setState({
      include_eans: e.target.value
    })
  }

  downloadMandate(e, mandate) {
    e.preventDefault();

    this.setState({ error: false });

    downloadMandate(
      this.props.selectedCompany.company_id,
      {
        ...this.state,
        lang: this.props.lang,
        mandate,
        company: this.props.selectedCompany,
        today: moment().format('YYYY-MM-DD')
      },
      (err, data) => {
        if (err) {
          if (err.no_eans_found)
            this.setState({ info: this.strings.noEANsFound });
          else {
            this.setState({ error: this.strings.error });

            return window.reportError.send(err);
          }
        }
      }
    );
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.mandate}
        </div>
        <div className="card-body">
          <h5 className="text-primary font-weight-light mb-2">{this.strings.signed}</h5>
          {
            !this.state.signedMandates ?
              <Loader inline="true" className="mb-2">{this.strings.loadingMandate}</Loader>
              :
              this.state.signedMandates.length ?
                <table className="table table-striped table-sm table-fixed">
                  <tbody>
                    {
                      this.state.signedMandates.map(
                        (mandate, j) => {
                          return (
                            <tr key={j}>
                              <td>
                                <div className="text-nowrap text-truncate w-100">
                                  <span className="text-primary text-underline cursor-pointer" onClick={event => this.downloadMandate(event, mandate.split('/')[1])}>
                                    {mandate.split('/')[1]}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )
                    }
                  </tbody>
                </table>
                :
                <p>
                  {this.strings.noMandate}
                </p>
          }
          <h5 className="text-primary font-weight-light mb-2">{this.strings.downloadMandate}</h5>

          <div className="form-check">
            <input className="form-check-input mr-1" type="radio" value="all" checked={this.state.include_eans === 'all'} name="include_eans" id="include_eans" onChange={this.onFieldChange} />
            <label className="form-check-label">
              {this.strings.includeAllEANs}
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input mr-1" type="radio" value="inactive" checked={this.state.include_eans === 'inactive'} name="include_eans" id="include_eans" onChange={this.onFieldChange} />
            <label className="form-check-label">
              {this.strings.includeEANsWithoutContracts}
            </label>
          </div>

          <div className="form-check mb-2">
            <input className="form-check-input mr-1" type="radio" value="none" checked={this.state.include_eans === 'none'} name="include_eans" id="include_eans" onChange={this.onFieldChange} />
            <label className="form-check-label">
              {this.strings.noEANs}
            </label>
          </div>

          <button type="button" className="btn btn-primary mb-3" onClick={event => this.downloadMandate(event)}>{this.strings.download}</button>

          <p className={'alert alert-warning ' + (this.state.error ? '' : 'd-none')}>
            <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
          </p>
          <p className={'alert alert-primary ' + (this.state.info ? '' : 'd-none')}>
            <i className="fas fa-info-circle mr-2"></i> {this.state.info}
          </p>
        </div>
      </div>
    )
  }
}

export default DownloadMandate;
