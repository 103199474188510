import config from './config';
import { getAccessToken } from './user';


const getEans = (query, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/eans/${query}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getAdvancedEans = (query, searchType, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/eans/advanced/${query}?searchType=${searchType}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const triggerAggregation = (ean, dates, cb) => {

  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/eans/aggregate?ean=${ean}&dates=${dates}`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export { getEans, getAdvancedEans, triggerAggregation };
