import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IBAN from 'iban';

import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import NotFound404 from '../NotFound404';

import { getUsers } from '../../api/user';
import { getPartner, getPartnerContacts, savePartner } from '../../api/partner';
import NumberInput from '../../components/NumberInput';

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: undefined,
      partnerContacts: undefined,
      options: [],
      isLoading: false,
      saving: false,
    };

    this.updatePartner = this.updatePartner.bind(this);
    this.updatePartnerContacts = this.updatePartnerContacts.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onPartnerSave = this.onPartnerSave.bind(this);
    this.unlinkPartnerContact = this.unlinkPartnerContact.bind(this);
    this.undoUnlinkPartnerContact = this.undoUnlinkPartnerContact.bind(this);
    this.onUserSearch = this.onUserSearch.bind(this);
    this.onUserChanged = this.onUserChanged.bind(this);
    this.linkUser = this.linkUser.bind(this);

    this.strings = LocalizedStrings(
      {
        en: {
          errorRequired: 'Missing required field',
          errorEmail: 'Not proper email field',
          errorGeneric:
            'There was an error while saving the data. Please try again',
          errorIban: 'The IBAN is invalid',
          errors: 'These errors occurred:',
          status: 'The partner was successfully saved!',
          welcome: 'Welcome',
          you: 'you can ',
          manage: 'manage',
          add: 'add new',
          partner: 'My Yuso partner below.',
          loading: 'Loading partner info...',
          back: 'Back to Partners',
          generalInfo: 'General info',
          name: 'Name',
          kbo: 'KBO',
          vat: 'VAT number',
          iban: 'IBAN number',
          phone: 'Phone',
          website: 'Website',
          defaultPartnerFee:
            'Partner fee on energy contracts (default = 1 €/MWh)',
          discountFee: 'Discount fee on energy contracts (default = 0 €/MWh)',
          type: 'Type',
          subtype: 'Sub-type',
          billingInfo: 'Billing info',
          activeCommission: 'Active commission',
          billingEmail: 'Billing e-mail',
          billingAddressStreet: 'Address street',
          billingAddressNumber: 'Address number',
          billingAddressCity: 'Address city',
          billingAddressZip: 'Address zip',
          users: 'Partner users',
          contactName: 'Name',
          contactEmail: 'E-mail',
          contactPhone: 'Phone',
          save: 'Save',
          revenueBESS: 'Revenue from flex steering',
          partnerFee: 'Partner Fee',
          profitShare: 'Profit Share',
          defaultFeeBess: 'Partner Fee (default = 2 €/MWh)',
          defaultProfitShareBESS: 'Profit Share (default = 0%)',
          noContacts: 'No contacts are linked to the selected partner',
          linkUser: 'Link existing user',
          lookup: 'Lookup user...',
          unlink: 'Unlink user',
          undoUnlink: 'Undo unlink user',
          unlinkInfo:
            'The marked user(s) in red will be unlinked from the partner and access will be revoked to all companies linked with this partner and all their contracts will be transfered to Yuso partner.',
          addNewPartnerUser: 'If you want to add new partner user please go to',
          manageUsers: 'Manage Users page',
          userExist: 'User is already added to this partner',
          linkedToPartner:
            'Selected user is already linked to a partner. To link user with',
          linkedToPartner2: 'please unlink the user from old partner',
          here: 'here.',
        },
        nl: {
          errorRequired: 'Ontbrekende verplicht veld',
          errorEmail: 'Geen correct e-mailadres',
          errorGeneric:
            'Er is een fout opgetreden tijdens het opslaan van de gegevens. Probeer het opnieuw',
          errorIban: 'De IBAN is ongeldig',
          errors: 'Deze fouten zijn opgetreden:',
          status: 'De partner is succesvol opgeslagen!',
          welcome: 'Welkom',
          manage: 'je kunt',
          add: 'nieuw toevoegen',
          partner: 'beheren My Yuso partner hieronder.',
          loading: 'Partnerinformatie laden...',
          back: 'Terug naar Partners',
          generalInfo: 'Algemene informatie',
          name: 'Naam',
          kbo: 'KBO',
          vat: 'BTW-nummer',
          iban: 'IBAN nummer',
          phone: 'Telefoon',
          website: 'Website',
          defaultPartnerFee:
            'Vaste vergoeding op energiecontracten (standaard = 1 €/MWh)',
          discountFee:
            'Kortingstarief op energiecontracten (standaard = 0 €/MWh)',
          type: 'Type',
          subtype: 'Subtype',
          billingInfo: 'Betaal informatie',
          activeCommission: 'Actieve commissie',
          billingEmail: 'Rekening e-mail',
          billingAddressStreet: 'Adres straat',
          billingAddressNumber: 'Adresnummer',
          billingAddressCity: 'Adres stad',
          billingAddressZip: 'Adres zip',
          users: 'Partnergebruikers',
          contactName: 'Naam',
          contactEmail: 'E-mail',
          contactPhone: 'Telefoon',
          save: 'Opslaan',
          revenueBESS: 'Opbrengst uit flex sturing',
          partnerFee: 'Vaste vergoeding',
          profitShare: 'Winstaandeel',
          defaultFeeBess: 'Vaste vergoeding (standaard = 2 €/MWh)',
          defaultProfitShareBESS: 'Winstaandeel (standaard = 0%)',
          noContacts:
            'Er zijn geen contacten gekoppeld aan de geselecteerde partner',
          linkUser: 'Koppel bestaande gebruiker',
          lookup: 'Gebruiker opzoeken...',
          unlink: 'Gebruiker ontkoppelen',
          undoUnlink: 'Ontkoppel gebruiker ongedaan maken',
          unlinkInfo:
            'De gemarkeerde gebruiker(s) in het rood worden ontkoppeld van de partner en de toegang wordt ingetrokken voor alle bedrijven die met deze partner zijn verbonden en al hun contracten worden overgedragen aan de Yuso partner.',
          addNewPartnerUser:
            'Ga naar als u een nieuwe partnergebruiker wilt toevoegen',
          manageUsers: 'Beheer gebruikers pagina',
          userExist: 'Gebruiker is al toegevoegd aan deze partner',
          linkedToPartner:
            'De geselecteerde gebruiker is al aan een partner gekoppeld. Om de gebruiker te koppelen aan',
          linkedToPartner2: 'ontkoppel de gebruiker van de oude partner',
          here: 'hier.',
        },
      },
      this.props.lang
    );
  }

  componentDidMount() {
    this.updatePartner();
  }

  updatePartner() {
    getPartner(this.props.partner_id, (err, res) => {
      if (err) return window.reportError.send(err);

      const partner = res;

      this.setState(
        {
          partner,
        },
        this.updatePartnerContacts
      );
    });
  }

  updatePartnerContacts() {
    getPartnerContacts(this.state.partner.id, (err, res) => {
      if (err) return window.reportError.send(err);

      const partnerContacts = res;

      this.setState({
        partnerContacts,
      });
    });
  }

  onFieldChange(e) {
    e.preventDefault();

    if (e.target.id === 'ibanNumber') {
      let target = e.target,
        position = target.selectionEnd,
        length = target.value.length;

      target.value = target.value
        .toString()
        .replace(/[^\da-zA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .toUpperCase()
        .trim();
      target.selectionEnd = position +=
        target.value.charAt(position - 1) === ' ' &&
        target.value.charAt(length - 1) === ' ' &&
        length !== target.value.length
          ? 1
          : 0;
    }

    if (e.target.id === 'kbo') {
      let clean_value = e.target.value
        ? e.target.value
            .toString()
            .replace(/\s+|[^0-9]+|\b0+/g, '')
            .trim()
        : '';

      this.setState({
        partner: {
          ...this.state.partner,
          vatNumber:
            'BE 0' + clean_value.replace(/(.{3})/g, '$1.').replace(/\.$/, ''),
          [e.target.id]:
            e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        },
      });
    }
    if (e.target.id === 'revenueBESS') {
      if (e.target.value === 'Partner Fee') {
        this.setState((prevState) => ({
          partner: {
            ...prevState.partner,
            defaultProfitShareBESS: null,
            defaultfeeBESS: 2,
          },
        }));

        this.setState({
          partner: { ...this.state.partner, [e.target.id]: e.target.value },
        });
      } else if (e.target.value === 'Profit Share') {
        this.setState((prevState) => ({
          partner: {
            ...prevState.partner,
            defaultfeeBESS: null,
            defaultProfitShareBESS: 0,
          },
        }));

        this.setState({
          partner: { ...this.state.partner, [e.target.id]: e.target.value },
        });
      } else if (e.target.value === '') {
        this.setState((prevState) => ({
          partner: {
            ...prevState.partner,
            defaultFeeBess: null,
            defaultProfitShareBESS: null,
          },
        }));

        this.setState({
          partner: { ...this.state.partner },
        });
      } else {
        this.setState({
          partner: { ...this.state.partner },
        });
      }
    }

    if (e.target.id === 'defaultfeeBESS') {
      this.setState({
        partner: {
          ...this.state.partner,
          [e.target.id]: parseInt(e.target.value),
        },
      });
    }

    if (e.target.id === 'defaultProfitShareBESS') {
      this.setState({
        partner: {
          ...this.state.partner,
          [e.target.id]: parseInt(e.target.value),
        },
      });
    } else {
      this.setState({
        partner: {
          ...this.state.partner,
          [e.target.id]:
            e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        },
      });
    }
  }

  onPartnerSave(e) {
    if (!this.props.selectedCompany) return;

    e.preventDefault();

    this.setState({ status: null, error: false, saving: true });

    const form = e.target.elements;

    let error = [];
    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        error.push(
          `${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`
        );
      }
      if (
        form[i].attributes.getNamedItem('email') &&
        form[i].value.trim().length &&
        !form[i].value
          .trim()
          .split(',')
          .reduce(
            (result, email) =>
              result && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()),
            true
          )
      ) {
        error.push(
          `${this.strings.errorEmail} ${form[i].name.split('_').join(' ')}`
        );
      }
      if (
        form[i].id === 'ibanNumber' &&
        form[i].value.trim().length &&
        !IBAN.isValid(form[i].value.trim())
      ) {
        error.push(this.strings.errorIban);
      }
      if (form[i].id === 'defaultfeeBESS' && !isNaN(form[i].value)) {
        if (form[i].value < 0) {
          error.push('Defailt Fee for BESS needs to be bigger than 0');
        }
      }
      if (form[i].id === 'defaultProfitShareBESS' && !isNaN(form[i].value)) {
        if (form[i].value < 0) {
          error.push('Defailt Profit Share for BESS needs to be bigger than 0');
        }
      }
    }

    if (error.length) {
      this.setState({ error, saving: false });
      return;
    }

    savePartner(this.state, (err, res) => {
      if (err) return window.reportError.send(err);

      if (res.error) {
        error.push(this.strings.errorGeneric);

        this.setState({ error, status: null, saving: false });
      } else {
        this.setState(
          {
            status: res,
            error: false,
            saving: false,
          },
          this.updatePartnerContacts
        );
      }
    });
  }

  unlinkPartnerContact(contact) {
    let partnerContacts = [...this.state.partnerContacts];

    if (contact.link)
      partnerContacts = partnerContacts.filter((x) => x.id !== contact.id);
    else partnerContacts.find((x) => x.id === contact.id).unlink = true;

    this.setState({
      partnerContacts,
    });
  }

  undoUnlinkPartnerContact(contact) {
    let partnerContacts = [...this.state.partnerContacts];

    partnerContacts.find((x) => x.id === contact.id).unlink = false;

    this.setState({
      partnerContacts,
    });
  }

  onUserSearch(query) {
    this.setState({ isLoading: true });

    if (query.length) {
      getUsers(
        null /* no company id specified, search all users */,
        query,
        (err, res) => {
          if (err) return window.reportError.send(err);

          this.setState({
            isLoading: false,
            options: res,
          });
        }
      );
    }
  }

  onUserChanged(selected) {
    const user = selected[0];
    let error = [];

    if (user && user.partner_id !== null)
      error.push(
        `${this.strings.linkedToPartner} ${this.state.partner.name} ${this.strings.linkedToPartner2}`
      );

    this.setState({
      user: !error.length ? user : undefined,
      error: error.length ? error : undefined,
      linkedTo: error.length ? user.partner_id : undefined,
    });
  }

  linkUser() {
    this.setState({
      userExist: false,
    });

    let partnerContacts = [...this.state.partnerContacts];

    const userExist = partnerContacts.find((x) => x.id === this.state.user.id);
    if (!userExist) partnerContacts.push({ ...this.state.user, link: true });

    this.setState({
      userExist,
      partnerContacts,
    });
  }

  render() {
    if (!this.state.partner) {
      return <Loader>{this.strings.loading}</Loader>;
    }

    if (this.props.partner_id && !this.state.partner.id) {
      return <NotFound404 lang={this.state.lang} />;
    }

    return (
      <div>
        <SubHeader
          name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`}
          image='Home'
          hideIcon='true'
          type='Home'
        />
        <div className='row'>
          <div className='col-sm px-2 px-xl-5 py-3'>
            <div className='title text-primary mb-5'>
              {this.strings.you}{' '}
              {this.props.partner_id ? this.strings.manage : this.strings.add}
              <br />
              {this.strings.partner}
            </div>

            <div>
              <form onSubmit={this.onPartnerSave} noValidate>
                <Link to='/admin/manage-partners' className='btn btn-primary'>
                  <i className='fas fa-arrow-left'></i> {this.strings.back}
                </Link>

                <div className='d-flex align-items-center mt-5 mb-4'>
                  <hr className='border-warning w-100' />
                  <h5 className='text-primary text-nowrap mx-5'>
                    {this.strings.generalInfo}
                  </h5>
                  <hr className='border-warning w-100' />
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='name'>{this.strings.name}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      name='name'
                      value={this.state.partner.name || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='kbo'>{this.strings.kbo}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='kbo'
                      name='KBO'
                      value={this.state.partner.kbo || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='vatNumber'>{this.strings.vat}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='vatNumber'
                      name='VAT_number'
                      value={this.state.partner.vatNumber || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='ibanNumber'>{this.strings.iban}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='ibanNumber'
                      name='IBAN_number'
                      value={this.state.partner.ibanNumber || ''}
                      onChange={this.onFieldChange}
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='phone'>{this.strings.phone}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='phone'
                      name='phone'
                      value={this.state.partner.phone || ''}
                      onChange={this.onFieldChange}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='website'>{this.strings.website}</label>
                    <input
                      type='text'
                      className='form-control'
                      id='website'
                      name='website'
                      value={this.state.partner.website || ''}
                      onChange={this.onFieldChange}
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='defaultPartnerFee'>
                      {this.strings.defaultPartnerFee}
                    </label>
                    <NumberInput
                      type='number'
                      className='form-control'
                      id='defaultPartnerFee'
                      name='default_partner_fee'
                      value={this.state.partner.defaultPartnerFee || 0}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='discountFee'>
                      {this.strings.discountFee}
                    </label>
                    <NumberInput
                      type='number'
                      className='form-control'
                      id='discountFee'
                      name='discount_fee'
                      value={this.state.partner.discountFee || 0}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-select required-label'>
                    <label htmlFor='type'>{this.strings.type}</label>
                    <select
                      className='form-control'
                      id='type'
                      name='type'
                      value={this.state.partner.type || ''}
                      onChange={this.onFieldChange}
                      required
                    >
                      <option value='' disabled></option>
                      <option value='Originator'>Originator</option>
                      <option value='Account manager'>Account manager</option>
                    </select>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='subtype'>{this.strings.subtype}</label>
                    <select
                      className='form-control'
                      id='subtype'
                      name='subtype'
                      value={this.state.partner.subtype || ''}
                      onChange={this.onFieldChange}
                    >
                      <option value='' disabled></option>
                      <option value='Energy manager'>Energy manager</option>
                      <option value='Asset manager'>Asset manager</option>
                      <option value='Solar developer'>Solar developer</option>
                      <option value='Battery developer'>
                        Battery developer
                      </option>
                      <option value='Certificate trader'>
                        Certificate trader
                      </option>
                      <option value='Other'>Other</option>
                    </select>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='revenueBESS'>
                      {this.strings.revenueBESS}
                    </label>
                    <select
                      className='form-control'
                      id='revenueBESS'
                      name='revenueBESS'
                      value={this.state.partner.revenueBESS || ''}
                      onChange={this.onFieldChange}
                    >
                      <option value=''></option>
                      <option value='Partner Fee'>
                        {this.strings.partnerFee}
                      </option>
                      <option value='Profit Share'>
                        {this.strings.profitShare}
                      </option>
                    </select>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='defaultfeeBESS'>
                      {this.strings.defaultFeeBess}
                    </label>
                    <NumberInput
                      disabled={
                        this.state.partner.revenueBESS === ''
                          ? true
                          : this.state.partner.revenueBESS === 'Partner Fee'
                          ? false
                          : true
                      }
                      type='number'
                      className='form-control'
                      id='defaultfeeBESS'
                      name='defaultfeeBESS'
                      value={
                        this.state.partner.revenueBESS === 'Partner Fee'
                          ? this.state.partner.defaultfeeBESS
                          : ''
                      }
                      onChange={(e) => {
                        this.onFieldChange(e);
                      }}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='defaultProfitShareBESS'>
                      {this.strings.defaultProfitShareBESS}
                    </label>
                    <NumberInput
                      disabled={
                        this.state.partner.revenueBESS === ''
                          ? true
                          : this.state.partner.revenueBESS === 'Profit Share'
                          ? false
                          : true
                      }
                      type='number'
                      className='form-control'
                      id='defaultProfitShareBESS'
                      name='defaultProfitShareBESS'
                      value={
                        this.state.partner.revenueBESS === 'Profit Share'
                          ? this.state.partner.defaultProfitShareBESS
                          : ''
                      }
                      onChange={(e) => {
                        this.onFieldChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className='d-flex align-items-center mt-5 mb-4'>
                  <hr className='border-warning w-100' />
                  <h5 className='text-primary text-nowrap mx-5'>
                    {this.strings.billingInfo}
                  </h5>
                  <hr className='border-warning w-100' />
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-select required-label'>
                    <label htmlFor='activeCommission'>
                      {this.strings.activeCommission}
                    </label>
                    <select
                      className='form-control'
                      id='activeCommission'
                      name='active_commission'
                      value={this.state.partner.activeCommission || 0}
                      onChange={this.onFieldChange}
                      required
                    >
                      <option value={1}>YES</option>
                      <option value={0}>NO</option>
                    </select>
                  </div>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='billingEmail'>
                      {this.strings.billingEmail}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='billingEmail'
                      name='billing_email'
                      email={this.state.partner.billingEmail}
                      value={this.state.partner.billingEmail || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='billingAddressStreet'>
                      {this.strings.billingAddressStreet}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='billingAddressStreet'
                      name='billing_address_street'
                      value={this.state.partner.billingAddressStreet || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='billingAddressNumber'>
                      {this.strings.billingAddressNumber}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='billingAddressNumber'
                      name='billing_address_number'
                      value={this.state.partner.billingAddressNumber || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='billingAddressZip'>
                      {this.strings.billingAddressZip}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='billingAddressZip'
                      name='billing_address_zip'
                      value={this.state.partner.billingAddressZip || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                  <div className='form-group col-md-6 required required-label'>
                    <label htmlFor='billingAddressCity'>
                      {this.strings.billingAddressCity}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='billingAddressCity'
                      name='billing_address_city'
                      value={this.state.partner.billingAddressCity || ''}
                      onChange={this.onFieldChange}
                      required
                    />
                  </div>
                </div>

                <div className='d-flex align-items-center mt-5 mb-4'>
                  <hr className='border-warning w-100' />
                  <h5 className='text-primary text-nowrap mx-5'>
                    {this.strings.users}
                  </h5>
                  <hr className='border-warning w-100' />
                </div>

                {!this.state.partnerContacts ? (
                  <Loader inline='true' className='text-center' />
                ) : !this.state.partnerContacts.length ? (
                  <div className='text-center my-4'>
                    <h5>{this.strings.noContacts}</h5>
                  </div>
                ) : (
                  <div>
                    <table className='table table-sm table-fixed'>
                      <thead>
                        <tr>
                          <th scope='col'>{this.strings.contactName}</th>
                          <th scope='col'>{this.strings.contactEmail}</th>
                          <th scope='col'>{this.strings.contactPhone}</th>
                          <th
                            scope='col'
                            className='text-center'
                            style={{ width: '25px' }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.partnerContacts.map((contact) => (
                          <tr
                            key={contact.id}
                            className={
                              contact.link
                                ? 'bg-success text-white'
                                : contact.unlink
                                ? 'bg-danger text-white'
                                : ''
                            }
                          >
                            <td>{contact.fullName}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phone}</td>
                            <td>
                              {!contact.unlink ? (
                                <i
                                  className='fas fa-times text-danger cursor-pointer'
                                  title={this.strings.unlink}
                                  onClick={() =>
                                    this.unlinkPartnerContact(contact)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className='fas fa-undo-alt text-white cursor-pointer'
                                  title={this.strings.undoUnlink}
                                  onClick={() =>
                                    this.undoUnlinkPartnerContact(contact)
                                  }
                                ></i>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {this.state.partnerContacts.filter((x) => x.unlink)
                      .length ? (
                      <p className='alert alert-info'>
                        <i className='fas fa-info-circle mr-2'></i>{' '}
                        {this.strings.unlinkInfo}
                      </p>
                    ) : null}
                  </div>
                )}

                <div className='input-group mt-3'>
                  <AsyncTypeahead
                    id='user_name'
                    clearButton
                    name='user_name'
                    filterBy={[
                      'firstName',
                      'lastName',
                      'fullName',
                      'fullNameReverse',
                      'email',
                    ]}
                    labelKey={(option) => `${option.email}`}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={result.id}
                          >
                            {result.firstName} {result.lastName}
                            <br />
                            <small className='text-muted'>
                              <span>{result.email}</span>
                            </small>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    inputProps={{ name: 'user_name' }}
                    defaultInputValue=''
                    isLoading={this.state.isLoading}
                    placeholder={this.strings.lookup}
                    options={this.state.options}
                    onSearch={this.onUserSearch}
                    onChange={this.onUserChanged}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      disabled={!this.state.user}
                      onClick={this.linkUser}
                    >
                      {this.strings.linkUser}
                    </button>
                  </div>
                </div>
                <small className='form-text text-muted'>
                  <em>
                    * {this.strings.addNewPartnerUser}{' '}
                    <Link to='/admin/manage-users'>
                      {this.strings.manageUsers}
                    </Link>
                    .
                  </em>
                </small>

                <div
                  className={
                    'alert alert-warning mt-3 ' +
                    (this.state.error ? 'd-block' : 'd-none')
                  }
                >
                  <i className='fas fa-exclamation-triangle mr-2'></i>{' '}
                  {this.strings.errors}
                  <ul>
                    {this.state.error
                      ? this.state.error.sort().map((error) => (
                          <li key={error}>
                            {error}{' '}
                            {this.state.linkedTo ? (
                              <a href={'/admin/partner/' + this.state.linkedTo}>
                                {this.strings.here}
                              </a>
                            ) : null}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>

                {this.state.userExist ? (
                  <p className='alert alert-warning mt-3'>
                    <i className='fas fa-exclamation-triangle mr-2'></i>{' '}
                    {this.strings.userExist}
                  </p>
                ) : null}

                <div
                  className={
                    'alert alert-info mt-3 ' +
                    (this.state.status ? 'd-block' : 'd-none')
                  }
                >
                  {this.strings.status}
                </div>

                <hr className='border-warning my-5' />

                <div className='d-flex justify-content-center mt-4'>
                  <button
                    type='submit'
                    className='btn btn-primary px-5 py-3'
                    disabled={this.state.saving}
                  >
                    <i
                      className={
                        !this.state.saving
                          ? 'fas fa-save mr-2'
                          : 'fa fa-spinner fa-spin mr-2'
                      }
                    ></i>{' '}
                    {this.strings.save}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
