import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import NumberInput from '../../components/NumberInput';
import { getCertificatePrices, addCertificatePrices, updateCertificatePrices, deleteCertificatePrices, generateCertificatePrices } from '../../api/certificates';


class CertificatePrices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleEdit: null,
      prices: undefined,
      selectedPeriod: {}
    }

    this.getOnlyPrices = this.getOnlyPrices.bind(this);
    this.validFields = this.validFields.bind(this);
    this.listCertificatePrices = this.listCertificatePrices.bind(this);
    this.onNewPeriod = this.onNewPeriod.bind(this);
    this.generatePeriods = this.generatePeriods.bind(this);
    this.saveNewCertificatePrices = this.saveNewCertificatePrices.bind(this);
    this.onCancelNewPeriod = this.onCancelNewPeriod.bind(this);
    this.onEditPeriod = this.onEditPeriod.bind(this);
    this.saveEditCertificatePrices = this.saveEditCertificatePrices.bind(this);
    this.onCancelEditPeriod = this.onCancelEditPeriod.bind(this);
    this.onDeleteCertificatePrices = this.onDeleteCertificatePrices.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "All fields are required.",
        errorExisting: "This period already exists, please specify new year and month.",
        welcome: "Welcome",
        manage: "manage the certificate pricing information.",
        loading: "Loading certificate prices...",
        add: "Add new certificate prices",
        generate: "Generate for next year",
        generateInfo: "You can generate certificate prices for the next year based on the latest entry. Note that if prices are already present they won't be changed.",
        period: "Period",
        GSCprice: "GSC price",
        contributionGSC: "Contribution GSC",
        WKCprice: "WKC price",
        contributionWKC: "Contribution WKC",
        priceGO_procurement: "GO price procurement",
        priceGO_market: "GO price market",
        date: "Date",
        active: "(Active price)",
        savePrices: "Save prices",
        cancelAdding: "Cancel adding",
        editPrices: "Edit prices",
        cancelEditing: "Cancel editing",
        deletePrices: "Delete prices",
        confirmDelete: "Confirm deleting",
        confirmDelete1: "Do you really want to delete these certificate prices?",
        confirmDelete2: "Be careful as this operation cannot be undone!",
        cancel: "Cancel",
        delete: "Delete",
        howTo1: "Manage",
        howTo2: "certificate prices",
        howTo3: "per month",
        howTo4: "Easily manage",
        howTo5: "certificate pricing",
        howTo6: "information from the list on the left.",
        howTo7: "Add new certificate pricing information.",
        howTo8: "Edit existing pricing information.",
        howTo9: "Delete certificate pricing information."
      },
      nl: {
        errorRequired: "Alle velden zijn verplicht.",
        errorExisting: "Deze periode bestaat al, geef nieuw jaar en maand aan.",
        welcome: "Welkom",
        manage: "beheer de prijsinformatie van het certificaat.",
        loading: "Certificaatprijzen laden...",
        add: "Voeg nieuwe certificaatprijzen toe",
        generate: "Genereer voor volgend jaar",
        generateInfo: "U kunt certificaatprijzen voor het volgende jaar genereren op basis van de laatste invoer. Merk op dat als prijzen al aanwezig zijn, deze niet zullen worden gewijzigd.",
        period: "Periode",
        GSCprice: "GSC prijs",
        contributionGSC: "Bijdrage GSC",
        WKCprice: "WKC prijs",
        contributionWKC: "Bijdrage WKC",
        priceGO_procurement: "GO prijs inkoop",
        priceGO_market: "GO prijs markt",
        date: "Datum",
        active: "(Actieve prijs)",
        savePrices: "Bewaar prijzen",
        cancelAdding: "Annuleer toevoegen",
        editPrices: "Prijzen bewerken",
        cancelEditing: "Bewerkingen annuleren",
        deletePrices: "Prijzen verwijderen",
        confirmDelete: "Bevestig het verwijderen",
        confirmDelete1: "Wilt u deze certificaatprijzen echt verwijderen?",
        confirmDelete2: "Wees voorzichtig, want deze bewerking kan niet ongedaan worden gemaakt!",
        cancel: "Annuleren",
        delete: "Verwijder",
        howTo1: "Beheren",
        howTo2: "certificaat prijzen",
        howTo3: "per maand",
        howTo4: "Gemakkelijk beheren",
        howTo5: "prijscertificering",
        howTo6: "informatie uit de lijst aan de linkerkant.",
        howTo7: "Nieuwe prijsinformatie voor certificaten toevoegen.",
        howTo8: "Bewerk bestaande prijsinformatie.",
        howTo9: "Certificaatprijsinformatie verwijderen."
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.listCertificatePrices();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  getOnlyPrices() {
    return this.state.prices.filter(x => !x.error)
  }

  validFields(newPeriod, cb) {
    this.setState({ error: undefined, prices: this.getOnlyPrices() }, () => {

      if (!(parseInt(this.state.selectedPeriod.year, 10) &&
        parseInt(this.state.selectedPeriod.month, 10) &&
        parseFloat(this.state.selectedPeriod.priceGSC) &&
        parseFloat(this.state.selectedPeriod.priceWKC) &&
        parseFloat(this.state.selectedPeriod.contributionGSC) &&
        parseFloat(this.state.selectedPeriod.contributionWKC) &&
        parseFloat(this.state.selectedPeriod.priceGO_procurement) &&
        parseFloat(this.state.selectedPeriod.priceGO_market))
      ) {

        let prices = [...this.getOnlyPrices()]
        let price = prices.find(x => x.year === parseInt(this.state.selectedPeriod.year, 10) && x.month === parseInt(this.state.selectedPeriod.month, 10))

        let index = prices.indexOf(price)

        const error = this.strings.errorRequired

        if (!newPeriod && index > -1)
          prices.splice(index + 1, 0, { error });

        this.setState({ error, prices }, () => {
          cb(false);
        })
      }
      else {
        cb(true);
      }
    })
  }

  listCertificatePrices() {
    getCertificatePrices(
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState(res, () => {
          this.setState({
            newPeriod: undefined,
            editPeriod: undefined,
            error: undefined,
            selectedPeriod: {}
          })
        });
      }
    );
  }

  onNewPeriod() {
    this.setState({
      error: undefined,
      newPeriod: true,
      editPeriod: undefined,
      selectedPeriod: this.state.prices[0] || {},
      prices: this.getOnlyPrices()
    })
  }

  generatePeriods() {
    generateCertificatePrices(
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.listCertificatePrices();
      }
    );
  }

  saveNewCertificatePrices() {

    this.validFields(true, (valid) => {

      if (valid)
        addCertificatePrices(
          this.state.selectedPeriod,
          (err, res) => {
            if (err)
              return window.reportError.send(err);

            if (res.error) {
              if (res.error.has_period) {
                let prices = [...this.getOnlyPrices()]

                const error = this.strings.errorExisting

                this.setState({ error, prices })
              }
            }
            else {
              this.listCertificatePrices();
            }
          }
        );
    });

  }

  onCancelNewPeriod() {
    this.setState({
      newPeriod: undefined,
      selectedPeriod: {},
      prices: this.getOnlyPrices()
    })
  }

  onEditPeriod(period) {
    this.setState({
      error: undefined,
      newPeriod: undefined,
      editPeriod: period,
      selectedPeriod: this.state.prices ? this.state.prices.find(p => p.period === period) : {},
      prices: this.getOnlyPrices()
    })
  }

  saveEditCertificatePrices() {

    this.validFields(false, (valid) => {

      if (valid)
        updateCertificatePrices(
          this.state.selectedPeriod,
          (err, res) => {
            if (err)
              return window.reportError.send(err);

            this.listCertificatePrices();
          }
        );
    });
  }

  onCancelEditPeriod() {
    this.setState({
      editPeriod: undefined,
      selectedPeriod: {},
      prices: this.getOnlyPrices()
    })
  }

  onDeleteCertificatePrices() {
    deleteCertificatePrices(
      this.state.deletePeriod,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.listCertificatePrices();
      }
    );
  }

  onFieldChange(e) {
    this.setState({
      selectedPeriod: { ...this.state.selectedPeriod, [e.target.name]: e.target.value }
    })
  }

  render() {
    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.manage}
            </div>

            {
              !this.state.prices ?
                <Loader inline="true">{this.strings.loading}</Loader>
                :
                <div>
                  <div className="d-flex">
                    <button type="button" className="btn btn-primary" onClick={this.onNewPeriod}>{this.strings.add}</button>
                    <button type="button" className="btn btn-secondary ml-auto" onClick={this.generatePeriods}>{this.strings.generate} <i className="fas fa-info-circle ml-2" data-toggle="tooltip" title={this.strings.generateInfo}></i></button>
                  </div>
                  <br />
                  <br />
                  <table className="certificate-prices table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>{this.strings.period}</th>
                        <th>{this.strings.GSCprice}</th>
                        <th>{this.strings.contributionGSC}</th>
                        <th>{this.strings.WKCprice}</th>
                        <th>{this.strings.contributionWKC}</th>
                        <th>{this.strings.priceGO_procurement}</th>
                        <th>{this.strings.priceGO_market}</th>
                        <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    {
                      this.state.newPeriod ?
                        <tbody>
                          <tr>
                            <td className="bg-white p-0">
                              <div className="extra-width">
                                <NumberInput type="number" className="form-control period-year" name="year" value={this.state.selectedPeriod.year || ''} onChange={this.onFieldChange} placeholder={'YYYY'} maxLength="4" />-<input type="number" className="form-control period-month" name="month" value={this.state.selectedPeriod.month || ''} onChange={this.onFieldChange} placeholder={'MM'} maxLength="2" />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="priceGSC" value={this.state.selectedPeriod.priceGSC || ''} onChange={this.onFieldChange} placeholder={this.strings.GSCprice} />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="contributionGSC" value={this.state.selectedPeriod.contributionGSC || ''} onChange={this.onFieldChange} placeholder={this.strings.contributionGSC} />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="priceWKC" value={this.state.selectedPeriod.priceWKC || ''} onChange={this.onFieldChange} placeholder={this.strings.WKCprice} />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="contributionWKC" value={this.state.selectedPeriod.contributionWKC || ''} onChange={this.onFieldChange} placeholder={this.strings.contributionWKC} />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="priceGO_procurement" value={this.state.selectedPeriod.priceGO_procurement || ''} onChange={this.onFieldChange} placeholder={this.strings.priceGO_procurement} />
                              </div>
                            </td>
                            <td className="bg-white p-0">
                              <div>
                                <NumberInput type="number" className="form-control" name="priceGO_market" value={this.state.selectedPeriod.priceGO_market || ''} onChange={this.onFieldChange} placeholder={this.strings.priceGO_market} />
                              </div>
                            </td>
                            <td className="bg-white"></td>
                            <td className="bg-white command">
                              <span className="cursor-pointer" title={this.strings.savePrices}><i className="fas fa-save text-primary" onClick={this.saveNewCertificatePrices}></i></span>
                            </td>
                            <td className="bg-white command">
                              <span className="cursor-pointer" title={this.strings.cancelAdding}><i className="fas fa-ban text-danger" onClick={this.onCancelNewPeriod}></i></span>
                            </td>
                          </tr>
                          {
                            this.state.error ?
                              <tr>
                                <td colSpan="10" className="alert alert-warning">
                                  <div className="w-100 text-center">{this.state.error}</div>
                                </td>
                              </tr> : null
                          }
                        </tbody> : null
                    }
                    <tbody>
                      {
                        this.state.prices.map(p => {
                          return (
                            p.error ?
                              <tr key={Math.random()}>
                                <td colSpan="10" className="alert alert-warning">
                                  <div className="w-100 text-center">{this.state.error}</div>
                                </td>
                              </tr>
                              :
                              <tr key={p.period}>
                                <td>
                                  <div className="extra-width">{p.year}-{p.month < 10 ? '0' + p.month : p.month}</div>
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="priceGSC" value={this.state.selectedPeriod.priceGSC || ''} onChange={this.onFieldChange} placeholder={this.strings.GSCprice} />
                                      </div>
                                      :
                                      <div>{p.priceGSC.toFixed(2)}</div>
                                  }
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="contributionGSC" value={this.state.selectedPeriod.contributionGSC || ''} onChange={this.onFieldChange} placeholder={this.strings.contributionGSC} />
                                      </div>
                                      :
                                      <div>{p.contributionGSC.toFixed(3)}</div>
                                  }
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="priceWKC" value={this.state.selectedPeriod.priceWKC || ''} onChange={this.onFieldChange} placeholder={this.strings.WKCprice} />
                                      </div>
                                      :
                                      <div>{p.priceWKC.toFixed(2)}</div>
                                  }
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="contributionWKC" value={this.state.selectedPeriod.contributionWKC || ''} onChange={this.onFieldChange} placeholder={this.strings.contributionWKC} />
                                      </div>
                                      :
                                      <div>{p.contributionWKC.toFixed(3)}</div>
                                  }
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="priceGO_procurement" value={this.state.selectedPeriod.priceGO_procurement || ''} onChange={this.onFieldChange} placeholder={this.strings.priceGO_procurement} />
                                      </div>
                                      :
                                      <div>{p.priceGO_procurement.toFixed(2)}</div>
                                  }
                                </td>
                                <td className={this.state.editPeriod === p.period ? 'p-0' : ''}>
                                  {
                                    this.state.editPeriod === p.period ?
                                      <div>
                                        <NumberInput type="number" className="form-control" name="priceGO_market" value={this.state.selectedPeriod.priceGO_market || ''} onChange={this.onFieldChange} placeholder={this.strings.priceGO_market} />
                                      </div>
                                      :
                                      <div>{p.priceGO_market.toFixed(2)}</div>
                                  }
                                </td>
                                <td></td>
                                <td className="command">
                                  {
                                    this.state.editPeriod === p.period ?
                                      <span className="cursor-pointer" title={this.strings.savePrices}><i className="fas fa-save text-primary" onClick={this.saveEditCertificatePrices}></i></span>
                                      :
                                      <span className="cursor-pointer" title={this.strings.editPrices}><i className="fas fa-pencil-alt text-primary" onClick={() => this.onEditPeriod(p.period)}></i></span>
                                  }
                                </td>
                                <td className="command">
                                  {
                                    this.state.editPeriod === p.period ?
                                      <span className="cursor-pointer" title={this.strings.cancelEditing}><i className="fas fa-ban text-danger" onClick={this.onCancelEditPeriod}></i></span>
                                      :
                                      <span className="cursor-pointer" title={this.strings.deletePrices} data-toggle="modal" data-target="#confirm-delete-price"><i className="fas fa-times text-danger" onClick={() => this.setState({ deletePeriod: p.period })}></i></span>
                                  }
                                </td>
                              </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                  <div className="modal fade" id="confirm-delete-price" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">{this.strings.confirmDelete}</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>
                            {this.strings.confirmDelete1}
                            <br />
                            {this.strings.confirmDelete2}
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                          <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.onDeleteCertificatePrices}>{this.strings.delete}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
          {/* <div className="col-lg-5 px-2 px-xl-5 py-3">
            <CallToAction className="my-4 ml-4">
              <CtaTitle>{this.strings.howTo1} <strong>{this.strings.howTo2}</strong> {this.strings.howTo3}</CtaTitle>
              <CtaContent>
                <p>
                  {this.strings.howTo4} <strong>{this.strings.howTo5}</strong> {this.strings.howTo6}
                </p>
                <ul>
                  <li>{this.strings.howTo7}</li>
                  <li>{this.strings.howTo8}</li>
                  <li>{this.strings.howTo9}</li>
                </ul>
              </CtaContent>
            </CallToAction>
          </div> */}
        </div>
      </div>
    );
  }
}

export default CertificatePrices;
