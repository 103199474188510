import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

class SwitchCompany extends Component {
    constructor(props) {
        super(props);

        this.timer = null;

        this.state = {
            switchingCompanyFinished: false
        }

        this.strings = LocalizedStrings({
            en: {
                welcome: "Welcome",
                switching: "Switching company..."
            },
            nl: {
                welcome: "Welkom",
                switching: "Bedrijf wisselen..."
            }
        }, this.props.lang);
    }

    componentDidMount() {

        if (this.props.selectedCompany && parseInt(this.props.selectedCompany.company_id) === parseInt(this.props.company_id, 10)) {
            this.setState({ switchingCompanyFinished: true });
            return;
        }

        this.timer = setTimeout(() => {

            this.props.onSwitchSelectedCompany(this.props.company_id);
            this.setState({ switchingCompanyFinished: true });
        }, 2000);
    }

    componentWillUnmount() {

        if (this.timer)
            clearTimeout(this.timer)
    }

    render() {
        if (!this.state.switchingCompanyFinished) {
            return (
                <div>
                    <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
                    <div className="row">
                        <div className="col-sm px-2 px-xl-5">
                            <Loader>{this.strings.switching}</Loader>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return <Redirect to={'/'} />
        }
    }
}

export default SwitchCompany;
