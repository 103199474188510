import React, { Component } from 'react';

import SubHeader from '../../components/SubHeader';
import { CtaPanel } from '../../components/CallToAction';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import 'react-day-picker/lib/style.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';


class NewSdpCompleted extends Component {
  constructor(props) {
    super(props);

    this.strings = LocalizedStrings({
      en: {
        services: "Services",
        loading: "Loading...",
        thanks: "Thank you! Your contract is ready",
        and: "and",
        signed: "waiting to be signed"
      },
      nl: {
        services: "Diensten",
        loading: "Bezig met laden...",
        thanks: "Bedankt! Jouw overeenkomst is klaar",
        and: "en",
        signed: "wacht op ondertekening"
      }
    }, this.props.lang);
  }

  render() {
    if (!(this.props.selectedCompany)) {
      return (
        <div>
          <SubHeader name={this.strings.services} type="Services" />
          <Loader>
            {this.strings.loading}
          </Loader>
        </div>
      );
    }

    return (
      <div>
        <SubHeader name={this.strings.services} type="Services" />
        <div className="row">
          <div className="col px-2 px-xl-5 py-3">
            <div className="spacer"></div>
            <div className="title text-primary mb-3">
              {this.strings.thanks}<br />
              {this.strings.and} <strong>{this.strings.signed}</strong>.
              </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 d-flex px-2 pl-xl-5 mb-3">
            <CtaPanel
              className="home-cta"
              link="/documents/agreements"
              type="sign_aggreements"
              lang={this.props.lang}
              buttonClassName="w-100" />
          </div>
          <div className="col-md-4 d-flex px-2 px-xl-4 mb-3">
            <CtaPanel
              className="home-cta"
              link={`/account/company/switch/${this.props.demo.company.kbo}?go=/dashboards`}
              type="demo_simulation"
              lang={this.props.lang}
              buttonClassName="w-100" />
          </div>
          <div className="col-md-4 d-flex px-2 pr-xl-5 mb-3">
            <CtaPanel
              className="home-cta"
              link={`/documents/pendingAgreement/${this.props.selectedCompany.company_id}/mandate`}
              type="request_simulation"
              lang={this.props.lang}
              buttonClassName="w-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default NewSdpCompleted;
