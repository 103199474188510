import React, { Component } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea } from 'recharts';
import moment from 'moment-timezone'
import { ContractAreaLineAndLabel } from './ContractAreaLineAndLabel';
import LocalizedStrings from '../../components/Localization';

import config from '../../api/config';

moment.tz.setDefault('Europe/Brussels')

class ChartPlatformPrice extends Component {
    constructor(props) {
        super(props);

        this.tickFormatter = this.tickFormatter.bind(this)
        this.labelFormatter = this.labelFormatter.bind(this)
        this.tooltipFormatter = this.tooltipFormatter.bind(this)

        this.strings = LocalizedStrings({
            en: {
                price: "Day ahead market price",
                noDayAheadPrice: "Day ahead market price is not available for the selected period.",
                limitedDayAheadPrices: "Without EAN(s) selected the day ahead market price is limited to Yesterday, Today, and Tomorrow.",
                tooltipLabelHour: "Hour",
                tooltipLabelDay: "Day",
                tooltipLabelMonth: "Month",
                mvChangesInfo: "Due to changes in our IT system, measurement data is not available at this moment"
            },
            nl: {
                price: "day ahead marktprijs",
                noDayAheadPrice: "Day ahead marktprijs is niet beschikbaar voor de geselecteerde periode.",
                limitedDayAheadPrices: "Zonder geselecteerde EAN(s) is de day ahead marktprijs beperkt tot Gisteren, Vandaag en Morgen.",
                priceLabel: "Day ahead marktprijs",
                tooltipLabelHour: "Uur",
                tooltipLabelDay: "Dag",
                tooltipLabelMonth: "Maand",
                mvChangesInfo: "Door aanpassingen in ons IT systeem, zijn meetwaarden enkele dagen niet beschikbaar"
            }
        }, this.props.lang);
    }

    tickFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.belpex[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                break;

            case 'M':
                format = 'YYYY-MM'
                break;

            default:
                break;
        }

        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    labelFormatter(index) {
        if (isNaN(index))
            return

        let value = this.props.belpex[index].dtlt
        let format = 'YYYY-MM-DD HH:mm'
        let tooltipLabel = this.strings.tooltipLabelHour
        switch (this.props.aggregation) {
            case 'D':
                format = 'YYYY-MM-DD'
                tooltipLabel = this.strings.tooltipLabelDay
                break;

            case 'M':
                format = 'YYYY-MM'
                tooltipLabel = this.strings.tooltipLabelMonth
                break;

            default:
                break;
        }

        return tooltipLabel + ': ' + moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    tooltipFormatter(value) {
        return parseFloat(value).toFixed(2) // always round belpex price to 2 decimals
    }

    render() {
        if (this.props.belpex && !this.props.belpex.length) {
            return (
                <div className="row">
                    <div className="col-sm text-center">
                        <div className="d-inline-block alert alert-info">
                            <i className="fas fa-info-circle mr-2"></i> {this.strings.noDayAheadPrice}
                        </div>
                    </div>
                </div>
            );
        }

        let start = this.props.belpex.find(x => x.dtlt === this.props.minContractDate + (this.props.aggregation === 'H' ? ' 23:00:00' : ' 00:00:00'))
        let index = this.props.belpex.indexOf(start)

        let last = this.props.belpex[this.props.belpex.length - 1].dtlt ? this.props.belpex[this.props.belpex.length - 1].dtlt : undefined

        if (moment(last, 'YYYY-MM-DD HH:mm:ss') < moment(this.props.minContractDate, 'YYYY-MM-DD'))
            index = this.props.belpex.length - 1

        return (
            <div>
                {
                    config.MAINTENANCE_MV ?
                        <div className="row" key="0">
                            <div className="col-sm text-center">
                                <div className="d-inline-block alert alert-danger px-5 py-4">
                                    <h5 className="m-0"><i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.mvChangesInfo}</h5>
                                </div>
                            </div>
                        </div>
                        :
                        !this.props.eanSelectedCount ?
                            <div className="row" key="0">
                                <div className="col-sm text-center">
                                    <div className="d-inline-block alert alert-info">
                                        <i className="fas fa-info-circle mr-2"></i> {this.strings.limitedDayAheadPrices}
                                    </div>
                                </div>
                            </div> : null
                }
                {
                    !this.props.belpex || !this.props.belpex.length ?
                        null :
                        <div>
                            <div className="row" key="1">
                                <div className="col-sm">
                                    <h5 className="text-center text-info mb-3">{this.strings.price} (€/MWh) <sup>*</sup></h5>
                                </div>
                            </div>
                            <div className="row mb-4" key="2">
                                <div className="col-sm" style={{ height: '300px' }}>
                                    <ResponsiveContainer>
                                        <LineChart data={this.props.belpex}>
                                            <XAxis dataKey="index" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip labelFormatter={this.labelFormatter} formatter={this.tooltipFormatter} />
                                            <Line
                                                name={this.strings.price}
                                                dataKey="price"
                                                unit=" €/MWh"
                                                stroke="#006087"
                                                type="stepAfter"
                                                dot={false}
                                            />
                                            <ReferenceArea
                                                x2={index}
                                                isFront={false} fill="#ccc"
                                                fillOpacity={0.25}
                                                label={<ContractAreaLineAndLabel
                                                    data={this.props.belpex}
                                                    lang={this.props.lang}
                                                    minContractDate={this.props.minContractDate}
                                                    showContractLine={index !== this.props.belpex.length - 1}
                                                    notStarted={this.props.notStarted} />}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default ChartPlatformPrice;
