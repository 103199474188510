import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';

import { getSignedMandate } from '../../api/documents';


class SignedMandate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mandate: undefined
    };

    this.updateMandate = this.updateMandate.bind(this);

    this.strings = LocalizedStrings({
      en: {
        loading: "Loading mandate...",
        notFound: "No mandate found.",
        backToAgreements: "Back to agreements"
      },
      nl: {
        loading: "Mandaat laden...",
        notFound: "Geen mandaat gevonden.",
        backToAgreements: "Terug naar overeenkomsten"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (parseInt(nextProps.company_id) !== parseInt(nextProps.selectedCompany.company_id)) {

      let url = window.location.pathname.replace(nextProps.company_id.toString(), nextProps.selectedCompany.company_id.toString())

      return { redirectTo: url }
    }
    else

      return null
  }

  componentDidMount() {
    this.updateMandate();
  }

  updateMandate() {
    if (!this.props.selectedCompany) return;

    getSignedMandate(
      this.props.company_id,
      this.props.document_key,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {

          this.setState({
            error: this.strings.notFound
          });
        }
        else {

          this.setState({
            mandate: res || 'about:blank'
          });
        }
      }
    );
  }

  render() {
    if (this.state.redirectTo)
      return <Redirect to={this.state.redirectTo} />

    if (this.state.error) {
      return (
        <div className="text-center">
          <div className="spacer"></div>
          <div className="spacer"></div>
          <h4 className="font-weight-light text-muted mb-4">{this.state.error}</h4>
          <Link to="/documents/agreements" className="btn btn-secondary">{this.strings.backToAgreements}</Link>
          <div className="spacer"></div>
          <div className="spacer"></div>
        </div>
      )
    }

    if (!this.state.mandate) {
      return (<Loader>{this.strings.loading}</Loader>);
    }

    return (
      <div className="row">
        <div className="col-sm p-0">
          <iframe name="mandate" id="mandate" width="100%" height="800px" title="mandate" src={this.state.mandate}></iframe>
        </div>
      </div>
    );
  }
}

export default SignedMandate;
