import React, { Component } from 'react';
import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import ContactNew from '../forms/ContactNew';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getUsers, getUsersForCompany, sendConfirmationEmail, checkCognitoAccount, unlinkUser, deleteUser, addUser, editUser, linkUser } from '../../api/user';

class CompanyContacts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usersForCompany: undefined,
            isAdminsListCollapsed: true,
            edit: false,
            add: false,
            save: false,
            isLoading: false,
            lookUpOptions: [],
            loading: false
        }

        this.unlinkUser = this.unlinkUser.bind(this);
        this.expandInfo = this.expandInfo.bind(this);
        this.renderCognitoStatus = this.renderCognitoStatus.bind(this);
        this.toggleState = this.toggleState.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onUserSearch = this.onUserSearch.bind(this);
        this.onUserChanged = this.onUserChanged.bind(this);
        this.linkUser = this.linkUser.bind(this);
        this.updateUsersForCompany = this.updateUsersForCompany.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.onEditUser = this.onEditUser.bind(this);
        this.saveNewUsers = this.saveNewUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);


        this.strings = LocalizedStrings({
            en: {
                title: "Manage company users",
                userUnlinked: "User was successfully unlinked!",
                unlinkUser: "Unlink user",
                loading: "Loading users...",
                confirmUnlink: "Confirm unlinking",
                confirmUnlink1: "Do you really want to unlink this user from",
                confirmUnlink2: "Note, this won't delete the user from My Yuso, it will unlink the user from the company.",
                cancel: "Cancel",
                unlink: "Unlink",
                showInfo: "View user info",
                name: "Name",
                email: "E-mail",
                phone: "Phone",
                notifications: "Notifications",
                type: "Type",
                role: "Role",
                userId: "User ID",
                cognitoAcc: "Cognito account",
                created: "Created",
                unsubscribed: "Unsubscribed from daily price email",
                subscribed: "Subscribed to daily price email",
                extreme: "Subscribed to extreme daily price email",
                noContacts: "No contacts found.",
                loadingStatus: "Loading Amazon Cognito status...",
                noCogntio: "No Amazon Cognito account",
                hasCognitoConfirmed: "Confirmed Amazon Cognito account",
                hasCognitoNotConfirmed: "NOT confirmed Amazon Cognito account",
                hasCognitoWithStatus: "Amazon Cognito account status: ",
                admins: "Administrators",
                save: "Save",
                confirmDelete: "Confirm deleting",
                confirmDelete1: "Do you really want to delete the following user from My Yuso?",
                confirmDelete1b: "Do you really want to delete and block the following user from My Yuso?",
                confirmDelete2: "Note, this will also delete the Amazon Cognito. Be careful as this operation cannot be undone!",
                userDeleted: "User was successfully deleted!",
                userEdited: "User was successfully edited!",
                userAdded: "User(s) was successfully added!",
                newUserAdded: "User was successfully added!",
                linkUser: "Link existing user(s) with",
                addUser: "Add a new user to",
                addUserBtn: "Add user(s)",
                linkUserBtn: "Link user",
                errorRequired: "Missing required field",
                errorEmail: "Not proper email field",
                addUserError: "Error while adding user. Please try again.",
                editUserError: "Error while editing user. Please try again.",
                errors: "These errors occurred:",
                editUser: "Edit user",
                user: "User:",
                sendEmail: "Send confirmation email",
                sendEmailTo: "Send confirmation email to",
                confirmSendEmail: "Confirm sending of confirmation email",
                info: "Sending confirmation email to a user will delete the current Amazon Cognito account if any and will create a new one for that user. This option is very useful when the user's Amazon Cognito account is not \"CONFIRMED\" and the initial password can't be retrieved or reset in any way. Be careful as confirmed users will need to confirm their account again.",
                cognitoExist: "This user already has Amazon Cognito user, this will delete his current Amazon Cognito account and create a new one.",
                cognitoError: "Error while re-activating user. Please try again.",
                emailSent: "Confirmation email was successfully sent!",
                contactExist: "A user already exists with that email. Please link the user using the link existing user option.",
                delete: "Delete",
                deleteBlock: "Delete and block",
                lookup: "Lookup user...",
            },
            nl: {
                title: "Beheer gebruikers van het bedrijf",
                userUnlinked: "De gebruiker is succesvol ontkoppeld!",
                unlinkUser: "Ontkoppel gebruiker",
                loading: "Gebruikers laden ...",
                confirmUnlink: "Bevestig het ontkoppelen",
                confirmUnlink1: "Wilt u deze gebruiker echt ontkoppelen van",
                confirmUnlink2: "Let op, hiermee wordt de gebruiker niet verwijderd uit My Yuso, maar wordt de gebruiker losgekoppeld van het bedrijf.",
                cancel: "Annuleren",
                unlink: "Ontkoppelen",
                showInfo: "Bekijk gebruikersinformatie",
                name: "Naam",
                email: "E-mail",
                phone: "Telefoon",
                notifications: "Meldingen",
                type: "Type",
                role: "Rol",
                userId: "Gebruikersnaam",
                cognitoAcc: "Cognito account",
                created: "Gemaakt",
                unsubscribed: "Afgemeld voor e-mail met dagelijkse prijs",
                subscribed: "Geabonneerd op e-mail met dagelijkse prijs",
                extreme: "Geabonneerd op e-mail met extreme dagelijkse prijzen",
                noContacts: "Geen contacten gevonden.",
                loadingStatus: "Amazon Cognito status laden ...",
                noCogntio: "Geen Amazon Cognito account",
                hasCognitoConfirmed: "Bevestigd Amazon Cognito account",
                hasCognitoNotConfirmed: "GEEN bevestigde Amazon Cognito account",
                hasCognitoWithStatus: "Amazon Cognito accountstatus: ",
                admins: "Beheerders",
                save: "Opslaan",
                confirmDelete: "Bevestig het verwijderen",
                confirmDelete1: "Wil je de volgende gebruiker echt uit My Yuso verwijderen?",
                confirmDelete1b: "Wilt u de volgende gebruiker echt verwijderen uit en blokkeren van My Yuso?",
                confirmDelete2: "Let op, hierdoor wordt ook de Amazon Cognito verwijderd. Wees voorzichtig, want deze handeling kan niet ongedaan worden gemaakt!",
                userDeleted: "Gebruiker is succesvol verwijderd!",
                userEdited: "Gebruiker is succesvol bewerkt!",
                userAdded: "Gebruiker (s) is succesvol toegevoegd!",
                newUserAdded: "Gebruiker is succesvol toegevoegd!",
                linkUser: "Koppel bestaande gebruiker (s) met",
                addUser: "Voeg een nieuwe gebruiker toe aan",
                addUserBtn: "Gebruiker(s) toevoegen",
                linkUserBtn: "Link gebruiker",
                errorRequired: "Ontbrekende vereiste veld",
                errorEmail: "Onjuist e-mailveld",
                addUserError: "Fout bij het toevoegen van een gebruiker. Probeer het a.u.b. opnieuw.",
                editUserError: "Fout bij het bewerken van gebruiker. Probeer het a.u.b. opnieuw.",
                errors: "Deze fouten zijn opgetreden:",
                editUser: "Bewerk gebruiker",
                user: "Gebruiker:",
                sendEmail: "Stuur een bevestigingsmail",
                sendEmailTo: "Stuur een bevestigingsmail naar",
                confirmSendEmail: "Bevestig het verzenden van een bevestigingsmail",
                info: "Door een bevestigingsmail naar een gebruiker te sturen, wordt het huidige Amazon Cognito-account verwijderd en wordt een nieuw account voor die gebruiker aangemaakt. Deze optie is erg handig als het Amazon Cognito-account van de gebruiker niet \"BEVESTIGD \" is en het oorspronkelijke wachtwoord op geen enkele manier kan worden opgehaald of gereset. Wees voorzichtig, want bevestigde gebruikers zullen hun account opnieuw moeten bevestigen.",
                cognitoExist: "Deze gebruiker heeft al een Amazon Cognito-gebruiker, hierdoor wordt zijn huidige Amazon Cognito-account verwijderd en wordt een nieuwe aangemaakt.",
                cognitoError: "Fout bij het opnieuw activeren van gebruiker. Probeer het a.u.b. opnieuw.",
                emailSent: "Bevestigingsmail is succesvol verzonden!",
                contactExist: "Er bestaat al een gebruiker met dat e-mailadres. Koppel de gebruiker aan de hand van de optie bestaande gebruikers koppelen.",
                delete: "Verwijderen",
                deleteBlock: "Verwijderen en blokkeren",
                lookup: "Gebruiker opzoeken ..."
            }
        }, this.props.lang);
    }

    updateUsersForCompany() {
        if (!this.props.selectedCompany) return;

        this.setState({ usersForCompany: undefined });

        getUsersForCompany(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                let usersForCompany = res

                this.setState({
                    usersForCompany
                }, () => {
                    usersForCompany.forEach(x => {
                        checkCognitoAccount(x.email,
                            (err, res) => {
                                if (err)
                                    return window.reportError.send(err);

                                x.cognito_status = res.cognito_status ? res.cognito_status : null

                                this.setState({ usersForCompany })
                            })
                    })
                });
            }
        );
    }

    componentDidMount() {
        this.updateUsersForCompany()
    }

    toggleState(e) {
        let edit = e.target.name === "edit" ? !this.state.edit : false,
            add = e.target.name === "add" ? !this.state.add : false,
            refreshFlag = e.target.name === "add" && this.state.add,
            selectedUser = e.target.name === "edit" && !this.state.edit ? { ...this.state.usersForCompany.find(u => u.showInfo && u.showInfo === true).user } : e.target.name === "add" && !this.state.edit ? { sendEmail: true } : {}

        this.setState({ add, edit, selectedUser }, () => {
            if (refreshFlag)
                this.updateUsersForCompany()
        })
    }

    onEditUser(e) {
        e.preventDefault()

        this.setState({ editUserError: undefined, editUserInfo: undefined, loading: true })

        editUser(this.props.selectedCompany.company_id, this.state.selectedUser,
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                if (res.error)
                    this.setState({ editUserError: this.strings.editUserError, loading: false })

                if (res.status === 'OK') {
                    this.setState({ editUserInfo: this.strings.userEdited, loading: false, edit: false })

                    setTimeout(() => {
                        this.setState({ editUserError: undefined, editUserInfo: undefined })
                        this.updateUsersForCompany()
                    }, 5000);
                }
            })
    }

    onUserChanged(selected) {
        this.setState({ lookUpSelectedUser: selected.length ? selected[0] : undefined })
    }

    linkUser() {
        if (this.state.lookUpSelectedUser) {
            let usersForCompany = this.state.usersForCompany
            let user = this.state.lookUpSelectedUser
            user.link = true
            usersForCompany.push(user)

            this.setState({
                usersForCompany,
                lookUpSelectedUser: undefined
            },
                () => {
                    checkCognitoAccount(user.email,
                        (err, res) => {
                            if (err)
                                return window.reportError.send(err);

                            usersForCompany.find(x => x.email === user.email).cognito_status = res.cognito_status ? res.cognito_status : null

                            this.setState({ usersForCompany })
                        })
                }
            )
        }
    }

    saveNewUsers(e) {
        e.preventDefault();

        this.setState({ addExistingUserError: undefined, saveNewUserError: undefined })

        let users = [], error = [];

        this.state.usersForCompany.forEach(u => {
            if (u.link)
                users.push({
                    id: u.id,
                    firstName: u.firstName,
                    lastName: u.lastName,
                    email: u.email
                })
        });

        if (users.length) {
            this.setState({ loading: true })

            linkUser(this.props.selectedCompany.company_id, users,
                (err, res) => {

                    if (err)
                        return window.reportError.send(err);

                    if (res.error) {
                        this.setState({ addExistingUserError: this.strings.addUserError, loading: false })
                        return;
                    }

                    if (res.status === 'OK') {
                        this.setState({ addExistingUserInfo: this.strings.userAdded, loading: this.state.selectedUser.email ? this.state.loading : false })

                        setTimeout(() => {
                            this.setState({ addExistingUserInfo: undefined })
                            if (!this.state.selectedUser.email)
                                this.updateUsersForCompany()
                        }, 3000);
                    }
                })
        }

        if (this.state.selectedUser.email) {
            const form = e.target.elements;

            for (let i = 0; i < form.length; i++) {
                if (form[i].required && !form[i].value.trim().length)
                    error.push(`${this.strings.errorRequired} ${form[i].name}`)

                if (form[i].attributes.getNamedItem('email') && form[i].value.trim().length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form[i].value.trim()))
                    error.push(`${this.strings.errorEmail} ${form[i].name}`)
            }

            if (error.length) {
                this.setState({ saveNewUserError: error, loading: false })
                return;
            }

            addUser(this.props.selectedCompany.company_id,
                {
                    ...this.state.selectedUser,
                    lang: this.props.lang,
                    strings: this.strings
                },
                (err, res) => {

                    if (err)
                        return window.reportError.send(err);

                    if (res.error) {
                        this.setState({ addNewUserError: res.error.contact_exist ? this.strings.contactExist : this.strings.addUserError, loading: false })
                        return;
                    }

                    if (res.status === 'OK') {
                        this.setState({ addNewUserInfo: this.strings.newUserAdded, loading: false })

                        setTimeout(() => {
                            this.setState({ addNewUserInfo: undefined, selectedUser: {} })
                            this.updateUsersForCompany()
                        }, 3000);
                    }
                })
        }

    }

    onFieldChange(e) {
        let selectedUser = this.state.selectedUser

        selectedUser[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        this.setState({ selectedUser })
    }

    unlinkUser() {
        unlinkUser(this.props.selectedCompany.company_id, this.state.user_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.status === 'OK') {
                    this.setState({ info: this.strings.userUnlinked, user_id: undefined }, () => {
                        setTimeout(() => {
                            this.setState({ info: undefined })
                            this.updateUsersForCompany()
                        }, 5000);
                    })
                }
            })
    }

    deleteUser() {
        deleteUser(this.props.selectedCompany.company_id, this.state.user_id, this.state.block_user ? true : false, this.state.comment ? this.state.comment : '',
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.status === 'OK') {
                    this.setState({ info: this.strings.userDeleted });

                    setTimeout(() => {
                        this.setState({ info: undefined })
                        this.updateUsersForCompany()
                    }, 5000);
                }
            })
    }

    expandInfo(user) {
        let usersForCompany = [...this.state.usersForCompany]
        let showInfo = { ...usersForCompany.find(x => x.showInfo) }

        usersForCompany = usersForCompany.filter(x => !x.showInfo)
        let idx = usersForCompany.map(x => x.email).indexOf(user.email)

        if (!showInfo || showInfo.idx !== idx) {
            usersForCompany.splice(idx + 1, 0, {
                showInfo: true,
                user: {
                    ...user
                },
                idx,
                role: user.role
            })
        }

        this.setState({
            usersForCompany,
            selectedUser: !showInfo || showInfo.idx !== idx ? user : {},
            edit: false
        })
    }

    onUserSearch(query) {
        this.setState({ isLoading: true });

        if (query.length) {
            getUsers(null /* no company id specified, search all users */, query, (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    isLoading: false,
                    lookUpOptions: res
                });
            });
        }
    }

    renderCognitoStatus(email, admin, details) {
        let user = this.state.usersForCompany.find(x => x.email === email)

        if (!user.cognito_status && user.cognito_status !== null)
            return <Loader inline="true" title={this.strings.loadingStatus}></Loader>

        if (!user.cognitoId || user.cognito_status === null)
            return details ? <div className="text-danger">{this.strings.noCogntio}</div> : <i className={`fas fa-user-times text-muted`} title={this.strings.noCogntio}></i>

        if (user.cognitoId) {
            if (user.cognito_status === 'CONFIRMED') {
                return details ? <div>{this.strings.hasCognitoConfirmed}</div> : <i className={`fas fa-user${admin ? '-ninja' : ''} text-primary`} title={`${this.strings.hasCognitoConfirmed}`}></i>
            }
            else if (user.cognito_status === 'UNCONFIRMED') {
                return details ? <div className="text-danger">{this.strings.hasCognitoNotConfirmed}</div> : <i className={`fas fa-user${admin ? '-ninja' : ''} text-danger`} title={`${this.strings.hasCognitoNotConfirmed}`}></i>
            }
            else {
                return details ? <div className="text-danger">{`${this.strings.hasCognitoWithStatus} ${user.cognito_status}`}</div> : <i className={`fas fa-user${admin ? '-ninja' : ''} text-warning`} title={`${this.strings.hasCognitoWithStatus} ${user.cognito_status}`}></i>
            }
        }
    }

    sendEmail(e) {
        e.preventDefault();
    
        this.setState({ 
            cognitoError: false,
            sendingEmail: true
        });
    
        sendConfirmationEmail(this.props.selectedCompany.company_id,
            {
                lang: this.props.lang,
                strings: this.strings,
                user: this.state.selectedUser
            },
            (err, res) => {
                if (err) {
                    window.reportError.send(err);
                    this.setState({
                        cognitoError: true,
                        sendingEmail: false
                    });
                } else {
                    if (res.error) {
                        if (res.error.user_exist)
                            this.setState({ cognitoError: this.strings.cognitoExist });
                        else
                            this.setState({ cognitoError: this.strings.cognitoError });
                    }
    
                    if (res.status === 'OK') {
                        this.setState({ 
                            cognitoInfo: this.strings.emailSent,
                            sendingEmail: false
                        }, () => {
                            setTimeout(() => {
                                this.setState({ cognitoInfo: undefined });
                            }, 5000);
                        });
                    }
                }
            });
    }

    generateTable(users, id) {

        return <tbody id={id}>{
            users.map(
                (user, idx) => {
                    if (user.showInfo) {
                        let user_info = user.user
                        return (
                            <tr key={user_info.id} className='px-10'>
                                <td colSpan="6" className="px-0 bg-white">
                                    <div className="card">
                                        <small>
                                            <table className="table table-sm table-borderless table">
                                                {
                                                    !this.state.edit ?
                                                        <tbody>
                                                            <tr>
                                                                <td>{this.strings.phone}</td>
                                                                <td>
                                                                    {
                                                                        user_info.phone ? user_info.phone.split(',').map(phone_no => { return <a href={`tel:${phone_no}`} key={phone_no}>{phone_no} </a> }) : <span>N/A</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.notifications}</td>
                                                                <td>
                                                                    {
                                                                        user_info.notifications === 0 ? this.strings.unsubscribed : user_info.notifications === 1 ? this.strings.subscribed : user_info.notifications === 2 ? this.strings.extreme : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.type}</td>
                                                                <td>{user_info.role}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.userId}</td>
                                                                <td>{user_info.id}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.cognitoAcc}</td>
                                                                <td>{this.renderCognitoStatus(user_info.email, false, true)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.strings.created}</td>
                                                                <td>{user_info.created_at}</td>
                                                            </tr>
                                                            <tr className="bg-white">
                                                                <td className="text-right border-top" colSpan="2">
                                                                    <button type="button" id="edit" name="edit" className="btn btn-primary mt-2 mr-2" onClick={this.toggleState}><i className="fas fa-user-edit text-white mr-2" ></i>{this.strings.editUser}</button>
                                                                    <button type="button" className="btn btn-primary mt-2 mr-2" data-toggle="modal" data-target="#send-confirmation"><i className="fas fa-user text-white mr-2" ></i>{this.strings.sendEmail}</button>
                                                                    <button type="button" className="btn btn-primary mt-2 mr-2" data-toggle="modal" data-target="#confirm-unlink" onClick={() => { this.setState({ user_id: user_info.id }) }} disabled={user_info.role === 'admin'}><i className="fas fa-user-times text-white mr-2"></i>{this.strings.unlinkUser}</button>
                                                                    {
                                                                        this.props.selectedCompany.role === "admin" ?
                                                                            <button type="button" className="btn btn-danger mt-2 mr-2" data-toggle="modal" data-target="#confirm-delete" onClick={() => { this.setState({ user_id: user_info.id }) }} disabled={user_info.role !== 'user'}><i className="fas fa-user-slash text-white mr-2"></i>Delete user</button>
                                                                            : null
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr className="bg-white">
                                                                <td colSpan="2">
                                                                    {this.state.info ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.state.info}</p> : null}
                                                                    {this.state.cognitoInfo ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.state.cognitoInfo}</p> : null}
                                                                    {this.state.cognitoError ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.state.cognitoError}</p> : null}
                                                                </td>
                                                            </tr>
                                                            <tr className="bg-white">
                                                                <td colSpan="2">
                                                                    {this.state.editUserInfo ? <div className='alert alert-info mt-3 mb-3'><i className="fas fa-exclamation-triangle mr-2"></i>{this.state.editUserInfo}</div> : null}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        :
                                                        <tbody>
                                                            <tr>
                                                                <td className="bg-white p-3">

                                                                    <div className="col-sm-12 col-md-6 mb-5">
                                                                        <ContactNew
                                                                            value={this.state.selectedUser}
                                                                            onFieldChange={this.onFieldChange}
                                                                            selectedCompany={this.props.selectedCompany}
                                                                            readOnlyEmail={true}
                                                                            fullForm={true}
                                                                            lang={this.props.lang}
                                                                            manageUsers={this.props.manageUsers}
                                                                        />

                                                                        <div className="text-right">
                                                                            <button className="btn btn-primary mr-2" onClick={this.onEditUser} disabled={this.state.loading}><i className={!this.state.loading ? "fas fa-save mr-2" : "fa fa-spinner fa-spin mr-2"}></i> {this.strings.save}</button>
                                                                            <button id="edit" name="edit" className="btn btn-secondary" onClick={this.toggleState} disabled={this.state.loading}>{this.strings.cancel}</button>
                                                                        </div>

                                                                        {this.state.editUserError ? <div className='alert alert-warning mt-3 mb-3'><i className="fas fa-exclamation-triangle mr-2"></i>{this.state.editUserError}</div> : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                }
                                            </table>
                                        </small>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    else {
                        let textClass = user.link ? "text-white" : "text-primary"

                        return (
                            < tr key={user.email} className={user.link ? 'bg-success text-white' : ''} >
                                <td>{this.renderCognitoStatus(user.email, user.role === 'admin')}</td>
                                <td><span className={`${textClass} cursor-pointer`} title={this.strings.showInfo} onClick={() => this.expandInfo(user)}>{user.firstName} {user.lastName}</span></td>
                                <td className={textClass}>{user.link ? user.email : <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">{user.email}</a>}</td>
                                {
                                    this.state.add ? <td className={textClass}>{user.role}</td> : null
                                }
                                <td className="text-right" colSpan="3">
                                    <span className="cursor-pointer mr-2" title={"User settings"} data-toggle="modal" data-target=""><i className="fas fa-user-cog text-primary" onClick={() => this.expandInfo(user)}></i></span>
                                </td>
                            </tr>
                        )
                    }
                }
            )}
        </tbody>
    }

    render() {
        return (
            <div>
                <h5 className="text-primary font-weight-light">{this.strings.title}</h5>
                {
                    this.state.usersForCompany ?
                        this.state.usersForCompany.length ?
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">{this.strings.name}</th>
                                        <th scope="col">{this.strings.email}</th>
                                        {
                                            this.state.add ?
                                                [
                                                    <th scope="col">{this.strings.phone}</th>,
                                                    <th scope="col">{this.strings.role}</th>
                                                ]
                                                : null
                                        }
                                        <th></th>
                                    </tr>
                                </thead>
                                {
                                    this.generateTable(this.state.usersForCompany.filter(x => x.role !== 'admin'), 'users')
                                }
                                {
                                    this.props.selectedCompany.role === "admin" ?
                                        <tbody className="table-borderless">
                                            <tr>
                                                <td colSpan="6" className="bg-white">
                                                    <strong className="text-primary cursor-pointer" onClick={() => this.setState({ isAdminsListCollapsed: !this.state.isAdminsListCollapsed })}>{this.strings.admins}<i className={'ml-2 ' + (!this.state.isAdminsListCollapsed ? 'fas fa-angle-up' : 'fas fa-angle-down')}></i></strong>
                                                </td>
                                            </tr>
                                        </tbody> : null
                                }
                                {
                                    !this.state.isAdminsListCollapsed ? this.generateTable(this.state.usersForCompany.filter(x => x.role === 'admin'), 'admins') : null
                                }
                            </table>
                            : <p>{this.strings.noContacts}</p>
                        : <Loader inline="true" className="mb-2">{this.strings.loading}</Loader>
                }

                {
                    !this.state.add ?
                        <button type="button" id="add" name="add" className="btn btn-primary mb-2" onClick={this.toggleState}><i className="fas fa-user-plus text-white mr-2" ></i>{this.strings.addUserBtn}</button>
                        :
                        <table className="table table-striped table-sm">
                            < tbody id="addUser">
                                <tr className="bg-white">
                                    <td colSpan="6">
                                        <form onSubmit={this.saveNewUsers} noValidate>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 px-3">
                                                    <label className="my-3">{this.strings.linkUser} {this.props.selectedCompany.companyName}</label>
                                                    <div className="input-group">
                                                        <AsyncTypeahead
                                                            id="user_name"
                                                            clearButton
                                                            name="user_name"
                                                            filterBy={['firstName', 'lastName', 'fullName', 'fullNameReverse', 'email']}
                                                            labelKey={option => `${option.email}`}
                                                            renderMenu={(results, menuProps) => (
                                                                <Menu {...menuProps}>
                                                                    {results.map((result, index) => (
                                                                        <MenuItem option={result} position={index} key={result.id}>
                                                                            {result.firstName} {result.lastName}
                                                                            <br />
                                                                            <small className="text-muted"><span>{result.email}</span></small>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Menu>
                                                            )}
                                                            inputProps={{ name: 'user_name' }}
                                                            defaultInputValue=""
                                                            isLoading={this.state.isLoading}
                                                            placeholder={this.strings.lookup}
                                                            options={this.state.lookUpOptions}
                                                            onSearch={this.onUserSearch}
                                                            onChange={this.onUserChanged}
                                                        />
                                                        <div className="input-group-append">
                                                            <button type="button" className="btn btn-secondary" disabled={!this.state.lookUpSelectedUser} onClick={this.linkUser}>{this.strings.linkUserBtn}</button>
                                                        </div>
                                                    </div>

                                                    <p className={'alert alert-warning mt-3 mb-3 ' + (this.state.addExistingUserError ? '' : 'd-none')}>
                                                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.addExistingUserError}
                                                    </p>

                                                    <p className={'alert alert-info mt-3 mb-3 ' + (this.state.addExistingUserInfo ? '' : 'd-none')}>
                                                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.addExistingUserInfo}
                                                    </p>
                                                </div>
                                                <div className="col-sm-12 col-md-6 px-3 border-warning border-left">
                                                    <label className="my-3">{this.strings.addUser} {this.props.selectedCompany.companyName}</label>
                                                    <ContactNew
                                                        value={this.state.selectedUser}
                                                        selectedCompany={this.props.selectedCompany}
                                                        hidePartner={true}
                                                        onFieldChange={this.onFieldChange}
                                                        lang={this.props.lang}
                                                        manageUsers={this.props.manageUsers}
                                                    />

                                                    {
                                                        this.state.selectedUser && this.state.selectedUser.email ?
                                                            <div className="form-check mb-3 d-block">
                                                                <input className="form-check-input" type="checkbox" name="sendEmail" checked={this.state.selectedUser.sendEmail} id="sendEmail" onChange={this.onFieldChange} />
                                                                <label className="form-check-label" htmlFor="sendEmail">{this.strings.sendEmailTo} <strong>{this.state.selectedUser.email}</strong>?</label>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={'alert alert-warning mt-3 mb-3 ' + (this.state.saveNewUserError ? 'd-block' : 'd-none')}>
                                                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.errors}
                                                        <ul>
                                                            {
                                                                this.state.saveNewUserError ? this.state.saveNewUserError.sort().map(error => (<li key={error}>{error}</li>)) : null
                                                            }
                                                        </ul>
                                                    </div>
                                                    {this.state.addNewUserInfo ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i>{this.state.addNewUserInfo}</p> : null}
                                                    {this.state.addNewUserError ? <p className='alert alert-warning'><i className="fas fa-info-circle mr-2"></i>{this.state.addNewUserError}</p> : null}
                                                </div>
                                                <div className="col-12 px-3 mt-3 text-center">
                                                    <button id="cancel" name="add" className="btn btn-secondary mr-2" disabled={this.state.loading} onClick={this.toggleState}>{this.strings.cancel}</button>
                                                    <button type="submit" id='save' name="add" className="btn btn-primary" disabled={this.state.loading}><i className={!this.state.loading ? "fas fa-save mr-2" : "fa fa-spinner fa-spin mr-2"}></i> {this.strings.save}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                }

                <div className="modal fade" id="send-confirmation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{this.strings.confirmSendEmail}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    {this.strings.info}
                                </p>
                                {
                                    this.state.selectedUser && this.state.selectedUser.cognito_status === "CONFIRMED"
                                        ? <p className='alert alert-info'><i className="fas fa-info-circle mr-2"></i> {this.strings.cognitoExist}</p>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.sendEmail}>{this.strings.sendEmail}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="confirm-unlink" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{this.strings.confirmUnlink}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    {this.strings.confirmUnlink1} <strong>{this.props.selectedCompany.companyName}?</strong>
                                    <br />
                                    {this.strings.confirmUnlink2}
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.unlinkUser}>{this.strings.unlink}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="confirm-delete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog alert-danger" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <svg className="bi bi-exclamation-triangle" width="2em" height="2em" viewBox="0 0 16 16" fill="#F32013" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.938 2.016a.146.146 0 00-.054.057L1.027 13.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L8.12 2.073a.146.146 0 00-.054-.057A.13.13 0 008.002 2a.13.13 0 00-.064.016zm1.044-.45a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" clipRule="evenodd" />
                                        <path d="M7.002 12a1 1 0 112 0 1 1 0 01-2 0zM7.1 5.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995z" />
                                    </svg>&nbsp;&nbsp;{this.strings.confirmDelete}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.selectedUser ?
                                        <p>
                                            {!this.state.block_user ? this.strings.confirmDelete1 : this.strings.confirmDelete1b}
                                            <br />
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp;{this.strings.user}&nbsp;<strong>{`${this.state.selectedUser.firstName} ${this.state.selectedUser.lastName}, `}</strong>{this.state.selectedUser.email}
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp;{this.strings.role}&nbsp;<strong>{this.state.selectedUser.role}</strong>
                                            <br />
                                            <br />{this.strings.confirmDelete2}
                                        </p> : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.cancel}</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteUser}>{!this.state.block_user ? this.strings.delete : this.strings.deleteBlock}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyContacts;
