import React, { Component } from 'react';

class Tabs extends Component {
    constructor(props){
        super(props);
        this.state = {
            openTab: 0
        };

        this.setOpenTab = this.setOpenTab.bind(this);
    }

    setOpenTab(index) {
        if (this.state.openTab !== index && index > -1) {
            this.setState({
                openTab: index
            });
            if (this.props.onTabChange) {
                this.props.onTabChange(index);
              }
        }
    }

    componentDidMount() {
        let index = this.props.titles.map(t => t.id).indexOf(window.location.hash.substring(1));
        this.setOpenTab(index)
    }

	renderTitles() {
		return (
            <ul className="nav nav-tabs">
				{
					this.props.titles.map(
						(title, index) => {
							return (
								<li
                                    className="nav-item"
                                    key={title.id}
                                >
									<a
                                        href={'#' + title.id}
                                        className={`nav-link cursor-pointer ${index === this.state.openTab ? 'active' : ''}`}
                                        onClick={(e) => {
                                            this.setOpenTab(index);
                                        }}
                                    >
                                        {title.name}
                                    </a>
								</li>
							)
						}
					)
				}
			</ul>
		);
	}

    renderPanes(){
        return (
            <div className="tab-content border border-warning p-4">
                {
                    this.props.children.map(
                        (child, index) => {
                            if(index !== this.state.openTab){
                                return (<div key={index}></div>);
                            }
                            return (
                                <div
                                    className={'tab-pane active ' + (index === this.state.openTab ? 'd-block' : 'd-none' )}
                                    aria-expanded={(index === this.state.openTab)}
                                    key={index}
                                >
                                    {child}
                                </div>
                            );
                        }
                    )
                }
            </div>
        )
    }

    render() {
	    return (
			<div>
				{this.renderTitles()}
			    {this.renderPanes()}
			</div>
		);
      }
}

export default Tabs;
