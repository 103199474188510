import React, { Component } from 'react';
import LocalizedStrings from '../Localization';
import Loader from '../Loader';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import { getPartners, getPartnerContacts, updateCompanySignupPartner } from '../../api/partner';


class ChangePartner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      partnerContacts: [],
      signup_partner_id: null,
      signup_contact_id: null,
      toggleEdit: false
    }

    this.updatePartners = this.updatePartners.bind(this);
    this.updatePartnerContacts = this.updatePartnerContacts.bind(this);
    this.onPartnerChange = this.onPartnerChange.bind(this);
    this.onPartnerContactChange = this.onPartnerContactChange.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.saveSignupPartner = this.saveSignupPartner.bind(this);

    this.strings = LocalizedStrings({
      en: {
        partnerChanged: "Partner was successfully changed!",
        partner: "Signup Partner",
        partnerContact: "Change Signup Contact",
        error: "Error while changing the partner. Please try again.",
        save: "Save",
        loading: "Loading partners...",
        signupPartner: "Signup Partner",
        signupContact: "Signup Person",
        edit: "Edit",
        cancel: "Cancel"
      },
      nl: {
        partnerChanged: "Partner is succesvol gewijzigd!",
        partner: "Aanmelden Partner",
        error: "Fout tijdens het wijzigen van de partner. Probeer het opnieuw.",
        save: "Opslaan",
        loading: "Partners laden...",
        signupPartner: "Aanmelden Partner",
        signupContact: "Aanmelden Persoon",
        edit: "Bewerk",
        cancel: "Annuleer"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    if (this.props.selectedCompany) {
      this.setState({
        signup_partner_id: this.props.selectedCompany.signup_partner_id,
        signup_contact_id: this.props.selectedCompany.signup_contact_id
      }, () => {
        this.updatePartners();
        this.updatePartnerContacts(this.props.selectedCompany.signup_partner_id)
      })
    }
    else this.updatePartners();
  }

  updatePartners() {
    getPartners((err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({ partners: res })
    })
  }

  updatePartnerContacts(partner_id) {
    getPartnerContacts(partner_id, (err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        partnerContacts: res,
        signup_contact_id: res.find(x => x.id === this.props.selectedCompany.signup_contact_id) ? this.props.selectedCompany.signup_contact_id : res.length ? res[0].id : null
      })
    })
  }

  onPartnerChange(e) {
    this.setState({ signup_partner_id: e.target.value, signup_contact_id: null })
    this.updatePartnerContacts(e.target.value)
  }

  onPartnerContactChange(e) {
    this.setState({ signup_contact_id: e.target.value })
  }

  toggleEdit() {
    this.setState({
      toggleEdit: !this.state.toggleEdit
    })
  }

  saveSignupPartner(e) {
    e.preventDefault()

    this.setState({ error: false })

    updateCompanySignupPartner(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.partnerChanged }, () => {
            setTimeout(() => {
              this.props.updateCompanies(this.props.selectedCompany.company_id, true);
              this.setState({ info: undefined })
            }, 3000);
          })
        }
      })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.partner}
        </div>
        <div className="card-body clearfix">
          {
            !this.state.partners.length ?
              <Loader className="mb-5" inline="true">{this.strings.loading}</Loader>
              :
              !this.state.toggleEdit ?
                <div>
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td style={{ width: '37.5%' }}>{this.strings.signupPartner}</td>
                        <td><strong>{this.props.selectedCompany.signup_partner_name}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.signupContact}</td>
                        <td><strong>{this.props.selectedCompany.signup_contact_person}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                  <button type="button" id="edit" className="btn btn-primary float-right" onClick={this.toggleEdit}><i className="fas fa-edit text-white mr-2" ></i>{this.strings.edit}</button>
                </div>
                :
                <form onSubmit={this.saveSignupPartner} noValidate>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="signup_partner_id">{this.strings.signupPartner}</label>
                    <div className="col-sm-9">
                      <select className="form-control mb-3" id="signup_partner_id" name="signup_partner_id" value={this.state.signup_partner_id} onChange={this.onPartnerChange}>
                        {
                          this.state.partners.map(partner => <option key={partner.id} value={partner.id}>{partner.name}</option>)
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="signup_contact_id">{this.strings.signupContact}</label>
                    <div className="col-sm-9">
                      <select className="form-control mb-3" id="signup_contact_id" name="signup_contact_id" value={this.state.signup_contact_id || null} onChange={this.onPartnerContactChange}>
                        {
                          this.state.partnerContacts.map(partnerContact => <option key={partnerContact.id} value={partnerContact.id}>{partnerContact.firstName} {partnerContact.lastName}</option>)
                        }
                      </select>
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <button id="cancel" className="btn btn-secondary ml-auto mr-2" onClick={this.toggleEdit}>{this.strings.cancel}</button>
                    <button type="submit" className="btn btn-primary"><i className="fas fa-save mr-2"></i> {this.strings.save}</button>
                  </div>

                  <p className={'alert alert-warning my-3 ' + (this.state.error ? '' : 'd-none')}>
                    <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                  </p>

                  <p className={'alert alert-info my-3 ' + (this.state.info ? '' : 'd-none')}>
                    <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                  </p>
                </form>
          }
        </div>
      </div>
    );
  }
}

export default ChangePartner;
