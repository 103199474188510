import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Item from './Navbar/Item';
import Dropdown from './Navbar/Dropdown';
import DropdownItem from './Navbar/DropdownItem';
import LocalizedStrings from './Localization';

import { filterCompanies } from '../api/company';
import Loader from './Loader';


class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: undefined,
            isCompaniesLoading: false,
            showMenu: false,
            companyFilter: ''
        }

        this.timer = null;

        this.filterCompanies = this.filterCompanies.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCompanySelect = this.onCompanySelect.bind(this);
    }

    componentDidMount() {
        this.filterCompanies();
    }

    toggleMenu() {
        this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
    };

    handleMenuClick() {
        this.setState({ showMenu: false });
    }

    onFilterChange(e) {
        this.setState({
            [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }, () => {

            if (e.target.id === 'companyFilter') {

                if (this.timer)
                    clearTimeout(this.timer);

                this.timer = setTimeout(this.filterCompanies, 500);
            }
        })
    }

    onCompanySelect() {
        this.setState({
            companyFilter: ''
        });
    }

    filterCompanies() {

        let companyFilter = this.state.companyFilter || ''

        this.setState({
            isCompaniesLoading: true
        });

        filterCompanies({ name_or_kbo: companyFilter }, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                isCompaniesLoading: false,
                companies: res.companies
            });
        });
    }

    render() {

        let strings = LocalizedStrings({
            en: {
                services: "Services",
                dashboards: "Dashboards",
                documents: "Documents",
                statements: "Statements",
                agreements: "Agreements",
                agreementTemplates: "Agreement templates",
                admin: "Admin",
                partner: "Partner",
                newCustomer: "New customer",
                netting: "Netting",
                manageCompany: "Manage company",
                managePartners: "Manage partners",
                manageUsers: "Manage users",
                partnerDashboard: "Partner dashboard",
                marketData: "Market Data",
                simulations: "Simulations",
                certificatePrices: "Certificate prices",
                company: "Company details",
                currentCompany: "Current selected company",
                latest: "Latest companies",
                currentUser: "Current logged user",
                user: "User details",
                about: "About My Yuso",
                logout: "Logout",
                demo: "Try Demo Company",
                loading: "Loading...",
                yourCompanies: "Your companies",
                filter: "Filter companies",
                searching: "Searching...",
                noCompaniesFound: "No companies found."
            },
            nl: {
                services: "Diensten",
                dashboards: "Dashboards",
                documents: "Documenten",
                statements: "Afrekeningen",
                agreements: "Overeenkomsten",
                agreementTemplates: "Overeenkomst templates",
                admin: "Beheerder",
                partner: "Partner",
                newCustomer: "Nieuwe klant",
                netting: "Netting",
                manageCompany: "Beheer bedrijf",
                managePartners: "Beheer partners",
                manageUsers: "Gebruikers beheren",
                partnerDashboard: "Partner dashboard",
                marketData: "Marktgegevens",
                simulations: "Simulaties",
                certificatePrices: "Certificaatprijzen",
                company: "Bedrijfsgegevens",
                currentCompany: "Huidig geselecteerde bedrijf",
                latest: "Laatste bedrijven",
                currentUser: "Huidige ingelogde gebruiker",
                user: "Gebruikersdetails",
                about: "Over My Yuso",
                logout: "Uitloggen",
                demo: "Probeer Demo Company",
                loading: "Laden...",
                yourCompanies: "Uw bedrijven",
                filter: "Bedrijven filteren",
                searching: "Zoeken...",
                noCompaniesFound: "Geen bedrijven gevonden."
            }
        }, this.props.lang);

        if (!(this.props.user && this.props.selectedCompany))
            return null;

        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid" >
                    <Link to="/" className="navbar-brand">
                        <img
                            style={{ width: '150px' }}
                            src={`${process.env.PUBLIC_URL}/yuso-portal.png`}
                            alt='Yuso'
                        />
                    </Link>
                </div>
                <button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={"collapse navbar-collapse " + (this.state.showMenu ? "show" : "")} id="navbar-collapse">
                    <ul className="navbar-nav menu" onClick={this.handleMenuClick}>
                        <Item name={strings.services} href="/services" />
                        <Item name={strings.dashboards} href="/dashboards" />
                        <Dropdown name={strings.documents} href="/documents" >
                            <DropdownItem name={strings.statements} href="/documents/statements" />
                            <div className="dropdown-divider"></div>
                            <DropdownItem name={strings.agreements} href="/documents/agreements" />
                        </Dropdown>
                        {
                            this.props.selectedCompany.role === 'admin' ?
                                <Dropdown name={strings.admin} href="/admin">
                                    <DropdownItem name={strings.newCustomer} href="/admin/new-customer" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.manageCompany} href="/admin/manage-company" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.manageUsers} href="/admin/manage-users" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.managePartners} href="/admin/manage-partners" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.partnerDashboard} href="/admin/partner-dashboard" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.marketData} href="/admin/market-data" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.simulations} href="/admin/simulations" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.certificatePrices} href="/admin/certificate-prices" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.agreementTemplates} href="/admin/agreements-templates" key={Math.random()} />
                                    <div className="dropdown-divider" key={Math.random()}></div>
                                    <DropdownItem name={strings.netting} href="/admin/netting" key={Math.random()} />
                                </Dropdown>
                                : this.props.selectedCompany.role === 'partner' ?
                                    <Dropdown name={strings.partner} href="/partner">
                                        <DropdownItem name={strings.newCustomer} href="/partner/new-customer" key={Math.random()} />
                                        <div className="dropdown-divider" key={Math.random()}></div>
                                        <DropdownItem name={strings.manageCompany} href="/partner/manage-company" key={Math.random()} />
                                        <div className="dropdown-divider" key={Math.random()}></div>
                                        <DropdownItem name={strings.partnerDashboard} href="/partner/partner-dashboard" key={Math.random()} />
                                        <div className="dropdown-divider" key={Math.random()}></div>
                                        <DropdownItem name={strings.marketData} href="/partner/market-data" key={Math.random()} />
                                        <div className="dropdown-divider" key={Math.random()}></div>
                                        <DropdownItem name={strings.simulations} href="/partner/simulations" key={Math.random()} />
                                    </Dropdown>
                                    // : this.props.selectedCompany.role === 'user' ?
                                    //     <Item name={strings.marketData} style={{ display: "inline-block" }} href="/market-data"  />
                                    : null
                        }
                    </ul>

                    <ul className="navbar-nav account ml-auto">
                        <Dropdown name={this.props.selectedCompany.companyName} icon="fa-building" title={strings.currentCompany}>
                            <DropdownItem name={strings.company} href="/account/company" />
                            {
                                this.state.companies ?
                                    <div>
                                        <span className="dropdown-item nav-section nav-title">
                                            {strings.yourCompanies}
                                        </span>
                                        <div className="input-group">
                                            <input
                                                className="form-control text-primary"
                                                placeholder={strings.filter}
                                                type="text"
                                                id="companyFilter"
                                                name="companyFilter"
                                                value={this.state.companyFilter || ''}
                                                onChange={this.onFilterChange}
                                                autoComplete="off"
                                            />
                                            {this.state.companyFilter && (
                                                <div className="input-group-append">
                                                    <div className="input-group-text"
                                                        onClick={() => {
                                                            this.setState({ companyFilter: '' }, this.filterCompanies);
                                                        }}>
                                                        <i className="fa fa-times text-danger" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="filter-results">
                                            {
                                                this.state.isCompaniesLoading ?
                                                    <Loader inline="true" className="dropdown-item">{strings.searching}</Loader>
                                                    :
                                                    this.state.companies.length ?
                                                        this.state.companies.map((company) => (
                                                            <DropdownItem key={company.companyNumber} name={company.companyName} href={`/account/company/switch/${company.companyNumber}`} onClick={this.onCompanySelect} />
                                                        ))
                                                        :
                                                        <div className='dropdown-item'>{strings.noCompaniesFound}</div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='dropdown-item'>{strings.loading}</div>
                            }

                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item bg-white text-primary font-weight-bold d-block p-1" to={'/account/company/switch/' + this.props.demo.company.kbo}>
                                <span className="d-block border border-primary w-100 p-2 bg-light">
                                    {strings.demo}
                                </span>
                            </Link>
                        </Dropdown>
                    </ul>

                    <ul className="navbar-nav account">
                        <Dropdown name={`${this.props.selectedCompany.firstName} ${this.props.selectedCompany.role !== "partner" ? this.props.selectedCompany.lastName : `(${this.props.selectedCompany.contact_partner_name})`}`} icon="fa-user" title={strings.currentUser}>
                            <DropdownItem name={strings.user} href="/account/user" />
                            <div className="dropdown-divider"></div>
                            <span className="dropdown-item cursor-pointer" onClick={this.props.onLogout}>{strings.logout}</span>
                        </Dropdown>
                    </ul>

                    <ul className="navbar-nav help">
                        <li className="nav-item">
                            <Link to="/info" title={strings.about} className="nav-link">
                                <i className="fas fa-question"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navbar;
