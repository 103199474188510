import React, { Component } from 'react';
import Company from '../forms/Company';
import Loader from '../Loader';
import LocalizedStrings from '../Localization';

import { getCompany, updateCompany } from '../../api/company';


class CompanyInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: undefined,
      toggleEdit: false,
    }

    this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setLegalForms = this.setLegalForms.bind(this);

    this.strings = LocalizedStrings({
      en: {
        company: "Company Information",
        legalForm: "Legal form",
        error: "Error while updating company details.",
        errorRequired: "All marked fields are required.",
        errorEmail: "Email is not properly formatted.",
        fetching: "Fetching company information...",
        companyUpdated: "Company was successfully updated!",
        kbo: "KBO Number",
        founded: "Founded",
        name: "Name",
        legalName: "Legal Name",
        vat: "VAT",
        email: "Email",
        website: "Website",
        phone: "Phone",
        nace: "Nace Code",
        street: "Street",
        number: "Number",
        zip: "Zip",
        city: "City",
        country: "Country",
        save: "Save",
        edit: "Edit",
        cancel: "Cancel"
      },
      nl: {
        company: "Bedrijfsinformatie",
        legalForm: "Rechtsvorm",
        error: "Fout bij het bijwerken van bedrijfsgegevens.",
        errorRequired: "Alle gemarkeerde velden zijn verplicht.",
        errorEmail: "E-mail is niet correct geformatteerd.",
        fetching: "Bedrijfsinformatie ophalen...",
        companyUpdated: "Bedrijf is succesvol geüpdatet!",
        kbo: "KBO-nummer",
        founded: "Opgericht",
        name: "Bedrijfsnaam",
        legalName: "Legale naam",
        vat: "BTW-nummer",
        email: "E-mail",
        website: "Website",
        phone: "Telefoon",
        nace: "Nace-code",
        street: "Straat",
        number: "Huisnummer",
        zip: "Postcode",
        city: "Stad",
        country: "Land",
        save: "Opslaan",
        edit: "Bewerk",
        cancel: "Annuleer"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.updateCompanyInfo();
  }

  setLegalForms(res) {

    this.setState({
      legalForms: res
    })
  }

  updateCompanyInfo() {
    if (!this.props.selectedCompany) return;

    getCompany(this.props.selectedCompany.company_id, (err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        company: res,
        company_id: res.id,
        company_name: res.companyName,
        company_legal_name: res.companyLegalName,
        company_vat: res.vatCode,
        company_address_street: res.addressStreet,
        company_address_number: res.addressNumber,
        company_address_zip: res.addressZip,
        company_address_city: res.addressCity,
        company_address_country: res.addressCountry,
        company_email: res.email,
        company_website: res.website,
        company_phone: res.phone,
        company_founded: res.foundingDate,
        company_nace_code: res.nacebelCode,
        company_legal_form: res.legalForm,
        company_legal_form_short: res.legalFormShort
      })
    })
  }

  onSubmit(e) {
    if (!this.props.selectedCompany) return;

    e.preventDefault();

    this.setState({ error: false });

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        this.setState({ error: this.strings.errorRequired })
        return;
      }
      if (form[i].attributes.getNamedItem('email') && form[i].value.trim().length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form[i].value.trim())) {
        this.setState({ error: this.strings.errorEmail })
        return;
      }
    }

    updateCompany(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.companyUpdated }, () => {
            this.updateCompanyInfo();
            this.props.updateCompanies(this.props.selectedCompany.company_id, true);

            if (this.props.toggleEdit)
              this.props.toggleEdit()

            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  toggleEdit() {
    this.setState({
      toggleEdit: !this.state.toggleEdit
    })
  }

  onFieldChange(e) {

    if (e.target) {

      this.setState({
        [e.target.id]: e.target.value
      })

      // generate the company name for the legal name and legal form
      if (e.target.id === 'company_legal_name') {

        this.setState({
          company_name: (e.target.value || '') + ' ' + (this.state.company_legal_form_short || '')
        })
      }

      if (e.target.id === 'company_legal_form_short') {

        this.setState({
          company_name: (this.state.company_legal_name || '') + ' ' + (e.target.value || '')
        })
      }
    }
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.company}
        </div>
        <div className="card-body clearfix">
          {
            !(this.props.selectedCompany && this.state.company) ?
              <Loader className="mb-5" inline="true">{this.strings.fetching}</Loader>
              :
              !this.state.toggleEdit ?
                <div>
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td style={{ width: '37.5%' }}>{this.strings.kbo}</td>
                        <td><strong>{this.props.selectedCompany ? this.props.selectedCompany.formattedNumber : 'N/A'}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.name}</td>
                        <td><strong>{this.state.company.companyName}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.legalName}</td>
                        <td><strong>{this.state.company.companyLegalName}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.vat}</td>
                        <td><strong>{this.state.company.vatCode}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.legalForm}</td>
                        <td><strong>{this.state.company.legalForm}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.founded}</td>
                        <td><strong>{this.state.company.foundingDate}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.email}</td>
                        <td><strong>{this.state.company.email}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.website}</td>
                        <td><strong>{this.state.company.website}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.phone}</td>
                        <td><strong>{this.state.company.phone}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.nace}</td>
                        <td><strong>{this.state.company.nacebelCode}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.street}</td>
                        <td><strong>{this.state.company.addressStreet}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.number}</td>
                        <td><strong>{this.state.company.addressNumber}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.zip}</td>
                        <td><strong>{this.state.company.addressZip}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.city}</td>
                        <td><strong>{this.state.company.addressCity}</strong></td>
                      </tr>
                      <tr>
                        <td>{this.strings.country}</td>
                        <td><strong>{this.state.company.addressCountry}</strong></td>
                      </tr>
                    </tbody>
                  </table>

                  <button type="button" id="edit" className="btn btn-primary float-right" onClick={this.toggleEdit}><i className="fas fa-edit text-white mr-2"></i>{this.strings.edit}</button>
                </div>
                :
                <form onSubmit={this.onSubmit} className="mb-5" noValidate>
                  <Company
                    value={this.state}
                    onFieldChange={this.onFieldChange}
                    selectedCompany={this.props.selectedCompany}
                    setLegalForms={this.setLegalForms}
                    adminSave="true"
                    lang={this.props.lang}
                  />

                  <p className={'alert alert-warning ' + (this.state.error ? 'd-block' : 'd-none')}>
                    <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                  </p>

                  <div className="d-flex mb-2">
                    <button id="cancel" className="btn btn-secondary ml-auto mr-2" onClick={this.toggleEdit}>{this.strings.cancel}</button>
                    <button type="submit" className="btn btn-primary"><i className="fas fa-save mr-2"></i> {this.strings.save}</button>
                  </div>
                </form>
          }
          <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
            <i className="fas fa-info-circle mr-2"></i> {this.state.info}
          </p>
        </div>
      </div>
    );
  }
}

export default CompanyInformation;
