import React, { Component } from 'react';
import { uploadSignedMandate } from '../../api/documents';

import moment from 'moment-timezone'
import LocalizedStrings from '../Localization';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-day-picker/lib/style.css'
import 'moment/locale/nl-be';

moment.tz.setDefault('Europe/Brussels')

class UploadMandate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      mandate_file: undefined,
      signatureDate: moment().format("YYYY-MM-DD"),
      selectedEANs: []
    }

    this.uploadMandate = this.uploadMandate.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSignatureDateChange = this.onSignatureDateChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.selectionChange = this.selectionChange.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Missing required field",
        error: "Error while uploading the mandate. Please try again.",
        errors: "These errors occurred:",
        errorBigFile: "Mandate file is too big.",
        errorBigFile1: "The mandate file is too big",
        errorBigFile2: "The maximum supported size is 25 MB, please consider",
        errorBigFile3: "compressing it",
        uploaded: "Mandate was successfully uploaded!",
        mandate: "Upload Mandate",
        mandateFile: "Choose mandate file",
        signatureDate: "Signature date",
        allEANs: "All EANs",
        eansWithoutContracts: "EANs without contracts",
        customEANs: "Custom EAN list",
        upload: "Upload",
        uploading: "Uploading...",
        selectEANs: "Select EANs..."
      },
      nl: {
        errorRequired: "Verplicht veld ontbreekt",
        error: "Fout tijdens het uploaden van het mandaat. Probeer het opnieuw.",
        errors: "Deze fouten zijn opgetreden:",
        errorBigFile: "Mandaatbestand is te groot.",
        errorBigFile1: "Het mandaatbestand is te groot",
        errorBigFile2: "De maximale ondersteunde grootte is 25 MB, alsjeblieft",
        errorBigFile3: "comprimeren",
        uploaded: "Mandaat is succesvol geüpload!",
        mandate: "Upload Mandaat",
        mandateFile: "Kies een bestand",
        signatureDate: "Datum handtekening",
        allEANs: "Alle EANs",
        eansWithoutContracts: "EANs op zonder contracten",
        customEANs: "Aangepaste EAN lijst",
        upload: "Uploaden",
        uploading: "Uploaden...",
        selectEANs: "Selecteer EANs..."
      }
    }, this.props.lang);
  }

  uploadMandate(e) {
    e.preventDefault();

    this.setState({ errorUploadMandate: false, uploading: true });

    let error = [];

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)
      }
    }

    if (this.state.errorBigFile) {
      error.push(this.strings.errorBigFile)
    }

    if (error.length) {
      this.setState({ errorUploadMandate: error })
      return;
    }

    uploadSignedMandate(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ errorUploadMandate: [this.strings.error] });
        }

        if (res.status === 'OK') {
          this.setState({
            info: this.strings.uploaded,
            uploading: false
          }, () => {
            setTimeout(() => {
              this.props.updateCompanies(this.props.selectedCompany.company_id, true);
            }, 3000);
          })
        }
      })
  }

  onFileChange(e) {
    const file = e.target.files[0];
    const _this = this;

    this.setState({ errorBigFile: false })

    if (file) {

      let reader = new FileReader();

      reader.onloadend = () => {
        _this.setState({
          mandate_file: {
            base64: reader.result.split(',').pop(),
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate
          }
        }, () => {
          if (file.size > 25 * 1000 * 1000) {
            this.setState({ errorBigFile: true })
          }
        })
      }

      reader.readAsDataURL(file);
    }
  }

  onSignatureDateChange(signatureDate) {
    this.setState({
      signatureDate
    });
  }

  onFieldChange(e) {
    let selections = []

    switch (e.target.value) {
      case "all":
        selections = this.props.sdps.map(x => x.ean)
        break;

      case "inactive":
        selections = this.props.sdps.filter(x => !x.signatureDate).map(x => x.ean)
        break;

      case "custom":
        break;

      default:
        break;
    }

    this.setState({
      eansList: e.target.value,
      selectedEANs: selections
    })
  }

  selectionChange(selections) {
    this.setState({ selectedEANs: selections.map(x => x.ean) })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.mandate}
        </div>
        <div className="card-body">
          <div>
            <form onSubmit={this.uploadMandate} noValidate>

              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="custom-file mb-3">
                    <input type="file" className="custom-file-input" name="mandate_file" onChange={this.onFileChange} required />
                    <label className="custom-file-label" htmlFor="mandate_file">{this.state.mandate_file ? this.state.mandate_file.name : this.strings.mandateFile}</label>
                  </div>
                  <small className={'form-text text-danger ' + (this.state.errorBigFile ? '' : 'd-none')}>
                    {this.strings.errorBigFile1} {this.state.mandate_file && (this.state.mandate_file.size / 1000 / 1000).toFixed(2)} MB. {this.strings.errorBigFile2} <a className="text-danger text-underline" href="https://smallpdf.com/compress-pdf" target="_blank" rel="noopener noreferrer">{this.strings.errorBigFile3}</a>.
                  </small>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="signature_date" className="col-sm-6 col-form-label">{this.strings.signatureDate}</label>
                <div className="col-sm-6">
                  <DayPickerInput
                    value={this.state.signatureDate}
                    required
                    format="YYYY-MM-DD"
                    placeholder="YYYY-MM-DD"
                    formatDate={(date, format) => { return moment(date).format(format) }}
                    inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'signature_date', name: 'signature_date', required: true }}
                    dayPickerProps={{
                      selectedDays: this.state.signatureDate,
                      disabledDays: { after: new Date() },
                      localeUtils: MomentLocaleUtils,
                      locale: this.props.lang
                    }}
                    onDayChange={this.onSignatureDateChange}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="form-check ml-3">
                  <input className="form-check-input" type="radio" value="all" checked={this.state.eansList === 'all'} id="all" onChange={this.onFieldChange} />
                  <label className="form-check-label">
                    {this.strings.allEANs}
                  </label>
                </div>

                <div className="form-check ml-3">
                  <input className="form-check-input" type="radio" value="inactive" checked={this.state.eansList === 'inactive'} id="inactive" onChange={this.onFieldChange} />
                  <label className="form-check-label">
                    {this.strings.eansWithoutContracts}
                  </label>
                </div>

                <div className="form-check ml-3">
                  <input className="form-check-input" type="radio" value="custom" checked={this.state.eansList === 'custom'} id="custom" onChange={this.onFieldChange} />
                  <label className="form-check-label">
                    {this.strings.customEANs}
                  </label>
                </div>
              </div>

              {
                this.state.eansList !== "custom" ? null : !this.props.sdps ? null :
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <div className="input-group special-select">
                        <Typeahead
                          id="eans"
                          multiple
                          options={this.props.sdps}
                          labelKey={x => `${x.ean} (${x.installation_ids || x.installation_id})`}
                          placeholder={this.strings.selectEANs}
                          clearButton
                          onChange={this.selectionChange}
                        />
                      </div>
                    </div>
                  </div>
              }

              <div className={'alert alert-warning mb-3 ' + (this.state.errorUploadMandate ? 'd-block' : 'd-none')}>
                <i className="fas fa-exclamation-triangle mr-2"></i>{this.strings.errors}
                <ul>
                  {
                    this.state.errorUploadMandate ? this.state.errorUploadMandate.sort().map(error => (<li key={error}>{error}</li>)) : null
                  }
                </ul>
              </div>

              <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                <i className="fas fa-info-circle mr-2"></i> {this.state.info}
              </p>

              <button type="submit" className="btn btn-primary float-right" disabled={this.state.uploading}><i className={!this.state.uploading ? "d-none" : "fa fa-spinner fa-spin mr-2"} />{!this.state.uploading ? this.strings.upload : this.strings.uploading}</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default UploadMandate;
