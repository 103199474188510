import config from './config';
import { getAccessToken } from './user';


const getCompanyInfo = (company_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);
      if(company_id){
        
        fetch(
          `${config.BASE_URL}/api/info/${company_id}`,
          {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }
        ).then(
          (res) => {
            res.json().then(
              (res) => { cb(null, res); }
            )
          }
        ).catch(
          (err) => { return cb(err); }
        );
      }
      }
      
  );
}

export { getCompanyInfo };

