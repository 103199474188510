import React, { Component } from 'react';
import LocalizedStrings from '../Localization';

import Address from './Address';


class Billing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locked: true
        }

        this.strings = LocalizedStrings({
            en: {
                iban: "IBAN",
                billing: "Billing E-mail",
                directDebit: "Direct Debit",
                activeDirectDebit: "Active Direct Debit",
                generate: "Generate Direct Debit mandate",
                invalidIban: "The IBAN is invalid, please corerct it.",
                unlockDDMandate: "Unlock DD Mandate field",
                lockDDMandate: "Lock DD Mandate field",
                warningDDMandate: "Be carefull when editing Direct Debit mandate as it could break the billing process. To activate or deactivate the DD mandate use the checkbox below."
            },
            nl: {
                iban: "IBAN",
                billing: "Facturatie e-mail",
                directDebit: "Direct Debit",
                activeDirectDebit: "Actieve Direct Debit",
                generate: "Genereer Direct Debit mandaat",
                invalidIban: "De IBAN is ongeldig, corercteer het alstublieft.",
                unlockDDMandate: "Ontgrendel DD mandaatveld",
                lockDDMandate: "Veld DD mandaat vergrendelen",
                warningDDMandate: "Wees voorzichtig bij het bewerken van een domiciliëringsmandaat, omdat dit het factureringsproces kan onderbreken. Gebruik het onderstaande selectievakje om het DD mandaat in of uit te schakelen."
            }
        }, this.props.lang);

        this.generateDDMandate = this.generateDDMandate.bind(this);
        this.toggleLockDDMandate = this.toggleLockDDMandate.bind(this);
    }

    generateDDMandate() {
        this.props.onFieldChange({
            target: {
                id: 'dd_mandate',
                value: 'YUSO' + this.props.selectedCompany.companyNumber
            }
        })
    }

    toggleLockDDMandate(e) {
        this.setState({
            locked: !this.state.locked
        })
    }

    render() {
        return (
            <div>
                {
                    [
                        { label: this.strings.billing, name: 'billing_email', required: true, email: 'yes' },
                        { label: this.strings.iban, name: 'iban', required: false, hide: this.props.hideIBAN },
                        { label: this.strings.directDebit, name: 'dd_mandate', required: true, hide: this.props.hideDDMandate },
                        { label: this.strings.activeDirectDebit, name: 'active_dd_mandate', required: false, hide: this.props.hideDDMandate }
                    ]
                        .map(item => {
                            let name = item.name;

                            if (item.hide) return null;

                            return (
                                <div className={'form-group row ' + (item.required ? 'required ' : '') + (name === 'dd_mandate' ? 'required-input-group' : '')} key={name}>
                                    {
                                        name === 'active_dd_mandate' ?
                                            [
                                                <div className="col-sm-3 d-flex align-items-center" key={0}>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        id={name}
                                                        name={name}
                                                        required={item.required}
                                                        email={item.email}
                                                        checked={this.props.value[name] || false}
                                                        onChange={this.props.onFieldChange} />
                                                </div>,
                                                <label htmlFor={name} className="col-sm-9 col-form-label" key={1}>
                                                    {item.label}
                                                </label>
                                            ]
                                            :
                                            [
                                                <label htmlFor={name} className="col-sm-3 col-form-label" key={0}>
                                                    {item.label}
                                                </label>,
                                                <div className="col-sm-9" key={1}>
                                                    <div className="input-group">
                                                        {
                                                            name === 'dd_mandate' ?
                                                                <div className="input-group-prepend cursor-pointer" title={this.state.locked ? this.strings.unlockDDMandate : this.strings.lockDDMandate} onClick={this.toggleLockDDMandate}>
                                                                    <div className="input-group-text">
                                                                        <i className={'fas mx-2 ' + (this.state.locked ? 'fa-lock text-danger' : 'fa-unlock text-success')}></i>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        <input
                                                            type="text"
                                                            className={'form-control ' + (name === 'dd_mandate' && this.state.locked ? 'bg-muted' : '')}
                                                            id={name}
                                                            name={name}
                                                            required={item.required}
                                                            readOnly={name === 'dd_mandate' && this.state.locked}
                                                            email={item.email}
                                                            value={this.props.value[name] || ''}
                                                            onChange={this.props.onFieldChange} />
                                                        {
                                                            name === 'dd_mandate' ?
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" title={this.strings.generate} onClick={this.generateDDMandate}><i className="fas fa-key"></i></button>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        name === 'iban' && this.props.invalidIban ?
                                                            <div className="alert alert-danger mt-3 mb-0">
                                                                {this.strings.invalidIban}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        name === 'dd_mandate' ?
                                                            <small className="form-text text-danger mt-2">
                                                                {this.strings.warningDDMandate}
                                                            </small>
                                                            : null
                                                    }
                                                </div>
                                            ]
                                    }
                                </div>
                            )
                        })
                }
                <Address name="billing_address" onFieldChange={this.props.onFieldChange} value={this.props.value} lang={this.props.lang} />
            </div>
        );
    }
}

export default Billing;
