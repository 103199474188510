import config from './config';

const getDaPrices = (agg, from, to, cb) => {

    fetch(
        `${config.BASE_URL}/api/market-data/daPrices?agg=${agg}&from=${from}&to=${to}`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}

const getImbalancePrices = (from, to, cb) => {

    fetch(
        `${config.BASE_URL}/api/market-data/imbalancePrices?from=${from}&to=${to}`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}

const getPriceIndexes = (from, to, cb) => {

    fetch(
        `${config.BASE_URL}/api/market-data/priceIndexes?from=${from}&to=${to}`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}
const getEliaSolarImbalanceCost = (from, cb) => {

    fetch(
        `${config.BASE_URL}/api/market-data/eliaSolarImbalanceIndex?from=${from}`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}

const getEliaWindOnshoreImbalanceCost = (from, cb) => {

    fetch(
        `${config.BASE_URL}/api/market-data/eliaWindOnshoreImbalanceIndex?from=${from}`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}

const getIceIndex = cb => {

    fetch(
        `${config.BASE_URL}/api/market-data/iceIndex`,
        {
            method: 'GET'
        }
    ).then(
        (res) => {
            res.json().then(
                (res) => { cb(null, res); }
            )
        }
    ).catch(
        (err) => { return cb(err); }
    );

}

export { getDaPrices, getImbalancePrices, getPriceIndexes, getIceIndex, getEliaSolarImbalanceCost, getEliaWindOnshoreImbalanceCost}
