import React, { Component } from 'react';

import SubHeader from '../../components/SubHeader';
import Tabs from '../../components/Tabs';

import UploadContract from '../../components/widgets/UploadContract';
import UploadMandate from '../../components/widgets/UploadMandate';
import DownloadMandate from '../../components/widgets/DownloadMandate';
import UploadSwitchConfirmation from '../../components/widgets/UploadSwitchConfirmation';
import ChangePartner from '../../components/widgets/ChangePartner';
import Contracts from '../../components/widgets/Contracts';
import EditSdp from '../../components/widgets/EditSdp';
import TriggerMvAggregation from '../../components/widgets/TriggerMvAggregation';
import SdpSwitch from '../../components/widgets/SdpSwitch';
import CompanyContacts from '../../components/widgets/CompanyContacts';
import ContractProposal from '../../components/widgets/ContractProposal';
import ContractActivation from '../../components/widgets/ContractActivation';
import SendMandate from '../../components/widgets/SendMandate';
import EditBilling from '../../components/widgets/EditBilling';
import CompanyInformation from '../../components/widgets/CompanyInformation';
import GenerateDemoAccount from '../../components/widgets/GenerateDemoAccount';
import ResetTestAccount from '../../components/widgets/ResetTestAccount';
import LocalizedStrings from '../../components/Localization';

import { getSdps } from '../../api/sdps';
import { getUsersForCompany } from '../../api/user';


class ManageCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sdps: undefined,
      groupedSdps: undefined,
      usersForCompany: undefined
    }

    this.updateSdps = this.updateSdps.bind(this);
    this.updateUsersForCompany = this.updateUsersForCompany.bind(this);

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        manage: "you can manage",
        sections: "with the sections below.",
        company: "Company",
        sdps: "SDPs",
        users: "Users",
        contracts: "Contracts",
        mandate: "Mandate",
        demo: "Demo & Test",
        demoWarning1: "You can't manage the Demo Company since it's automatically generated. If you want to change some data for the Demo Company you should make changes in the",
        demoWarning2: "file located in the source code of the My Yuso app in the AWS CodeCommit repository",
        demoWarning3: "After you've made the changes make sure it's valid JSON, otherwise generate demo account funntionality won't work. Then commit and push the code, wait for the app to get depoyed and retrigger the \"Generate\" button below."
      },
      nl: {
        welcome: "Welkom",
        manage: "je kunt",
        sections: "beheren met de onderstaande secties.",
        company: "Bedrijf",
        sdps: "SDPs",
        users: "Gebruikers",
        contracts: "Contracten",
        mandate: "Mandaat",
        demo: "Demo & Test",
        demoWarning1: "U kunt Demo Company niet beheren omdat deze automatisch wordt gegenereerd. Als u gegevens voor Demo Company wilt wijzigen, moet u wijzigingen aanbrengen in de",
        demoWarning2: "bestand in de broncode van de My Yuso-app in de AWS CodeCommit-repository",
        demoWarning3: "Nadat u de wijzigingen hebt aangebracht, controleert u of het JSON geldig is, anders werkt de demo-accountfunctionaliteit niet. Bevestig vervolgens en druk op de code, wacht tot de app wordt verwijderd en activeer de knop \"Genereren\" hieronder opnieuw."
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.updateSdps();
    this.updateUsersForCompany();
  }

  updateSdps() {
    if (!this.props.selectedCompany) return;

    getSdps(
      this.props.selectedCompany.company_id,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        let sdps = res.sdps_offtake_hourly.concat(res.sdps_injection_hourly).concat(res.sdps_production)

        let groupedSdps = sdps.filter((sdp, idx) => {
          return (sdps.findIndex((e, i) => {
            // DMRs
            if (sdp.ean === e.ean && i !== idx) {
              e.installation_ids = `${sdp.installation_id},${e.installation_id}`
              e.type_sdp_label = e.installation_ids.includes('INJ') && e.installation_ids.includes('AFN') ? 'offtakeInjection' : e.type_sdp
              e.type_sdp = 'offtake'
            }
            return sdp.ean === e.ean
          }) === idx && !sdp.ean.startsWith('EANTMP'))
        })

        this.setState({ sdps, groupedSdps });
      }
    );
  }

  updateUsersForCompany() {
    if (!this.props.selectedCompany) return;

    getUsersForCompany(
      this.props.selectedCompany.company_id,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({
          usersForCompany: res
        });
      }
    );
  }

  render() {

    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary mb-5">
              {this.strings.manage} <strong>{this.props.selectedCompany.companyName}</strong><br />
              {this.strings.sections}
            </div>

            {
              this.props.selectedCompany.demo ?
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 mb-4">
                    <p className="alert alert-warning text-body">
                      <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.demoWarning1} <code><a href="https://us-east-1.console.aws.amazon.com/codesuite/codecommit/repositories/my-yuso-server/browse/refs/heads/master/--/server/src/static/demo-data.json" className="text-danger" target="_blank" rel="noopener noreferrer" title="Click to edit the file online">demo-data.json</a></code> {this.strings.demoWarning2} <strong>my-yuso-server</strong>. {this.strings.demoWarning3}
                    </p>
                    <br />
                    <GenerateDemoAccount
                      lang={this.props.lang}
                    />
                  </div>
                </div>
                : this.props.selectedCompany.role === 'admin' ?
                  <Tabs titles={[
                    { name: this.strings.company, id: 'company' },
                    { name: this.strings.sdps, id: 'sdps' },
                    { name: this.strings.users, id: 'users' },
                    { name: this.strings.contracts, id: 'contracts' },
                    { name: this.strings.mandate, id: 'mandate' },
                    { name: this.strings.demo, id: 'demo' }
                  ]}>
                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <CompanyInformation
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <EditBilling
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />

                          <ChangePartner
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row date-picker-full">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <EditSdp
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateSdps={this.updateSdps}
                          />

                          <SdpSwitch
                            lang={this.props.lang}
                            sdps={this.state.groupedSdps}
                            selectedCompany={this.props.selectedCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <UploadSwitchConfirmation
                            lang={this.props.lang}
                            sdps={this.state.groupedSdps}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />

                          <TriggerMvAggregation
                            sdps={this.state.groupedSdps}
                            lang={this.props.lang}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 mb-4">
                          <CompanyContacts
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                          />
                        </div>

                      </div>
                    </div>

                    <div>
                      <div className="row date-picker-full">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <UploadContract
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                            type={this.props.type}
                          />

                          <Contracts
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <ContractProposal
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            usersForCompany={this.state.usersForCompany}
                          />

                          <ContractActivation
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            usersForCompany={this.state.usersForCompany}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <UploadMandate
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                            sdps={this.state.groupedSdps}
                          />

                          <DownloadMandate
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <SendMandate
                            lang={this.props.lang}
                            sdps={this.state.groupedSdps}
                            selectedCompany={this.props.selectedCompany}
                            usersForCompany={this.state.usersForCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <GenerateDemoAccount
                            lang={this.props.lang}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <ResetTestAccount
                            lang={this.props.lang}
                          />
                        </div>
                      </div>
                    </div>
                  </Tabs>
                  :
                  <Tabs titles={[
                    { name: this.strings.company, id: 'company' },
                    { name: this.strings.sdps, id: 'sdps' },
                    { name: this.strings.users, id: 'users' },
                    { name: this.strings.contracts, id: 'contracts' },
                    { name: this.strings.mandate, id: 'mandate' }
                  ]}>


                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <CompanyInformation
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <EditBilling
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row date-picker-full">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <EditSdp
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 mb-4">
                          <CompanyContacts
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                          />
                        </div>

                      </div>
                    </div>

                    <div>
                      <div className="row date-picker-full">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <ContractProposal
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            usersForCompany={this.state.usersForCompany}
                          />

                          <UploadContract
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <Contracts
                            lang={this.props.lang}
                            sdps={this.state.sdps}
                            selectedCompany={this.props.selectedCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <UploadMandate
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                            updateCompanies={this.props.updateCompanies}
                            sdps={this.state.groupedSdps}
                          />

                          <DownloadMandate
                            lang={this.props.lang}
                            selectedCompany={this.props.selectedCompany}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <SendMandate
                            lang={this.props.lang}
                            sdps={this.state.groupedSdps}
                            selectedCompany={this.props.selectedCompany}
                            usersForCompany={this.state.usersForCompany}
                            updateSdps={this.updateSdps}
                          />
                        </div>
                      </div>
                    </div>

                  </Tabs>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ManageCompany;
