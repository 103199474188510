import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import numeral from 'numeral';

import { getPartners, getPartnerContacts, getPartnerContracts } from '../../api/partner';

class PartnerDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: undefined,
            partnerContacts: undefined,
            partnerContracts: undefined,
            contract_status: "all"
        }

        this.updatePartners = this.updatePartners.bind(this)
        this.updatePartnerContracts = this.updatePartnerContracts.bind(this)

        this.strings = LocalizedStrings({
            en: {
                welcome: "Welcome",
                view: "Review",
                partnersContracts: "partner's contracts",
                contracts: "contracts",
                loading: "Loading partner's data...",
                companyName: "Company Name",
                ean: "EAN",
                partnerContact: "Partner contact",
                partner: "Partner",
                fee: "fee",
                total: "Total",
                volume: "volume",
                income: "income",
                contractType: "Contract type",
                status: "Status",
                offtake: "Offtake",
                injection: "Injection",
                noPartner: "No partner selected. Please select partner.",
                noContracts: "Partner company does not have any contracts.",
                selectPartner: "Select partner",
                all: "All",
                partnerUsers: "Partner users",
                myContracts: "Display my contracts only",
                contractStatus: "Contract status",
                active: "Active",
                ended: "Ended",
                expired: "Expired",
                unsigned: "Unsigned",
                future: "Future",
                unknown: "Unknown"
            },
            nl: {
                welcome: "Welkom",
                view: "Bekijk",
                partnersContracts: "partner contracten",
                contracts: "contracten",
                loading: "Gegevens van partner laden...",
                companyName: "Bedrijfsnaam",
                ean: "EAN",
                partnerContact: "Partner contact",
                partner: "Partner",
                fee: "vergoeding",
                total: "Totaal",
                volume: "volume",
                income: "inkomen",
                contractType: "Contract type",
                status: "Toestand",
                offtake: "Afname",
                injection: "Injectie",
                noPartner: "Geen partner geselecteerd. Selecteer een partner.",
                noContracts: "Partnerbedrijf heeft geen contracten.",
                selectPartner: "Selecteer partner",
                all: "Allemaal",
                partnerUsers: "Partner gebruikers",
                myContracts: "Laat alleen mijn contracten zien",
                active: "Actief",
                ended: "Beëindigd",
                expired: "Verlopen",
                unsigned: "Niet ondertekend",
                future: "Toekomst",
                unknown: "Onbekend"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        if (this.props.selectedCompany.role === 'partner')
            this.updatePartnerContacts(this.props.selectedCompany.contact_partner_id)
        else this.updatePartners()
    }

    updatePartners() {
        getPartners((err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partners: res
            })
        })
    }

    updatePartnerContacts(partner_id) {
        this.updatePartnerContracts(partner_id)

        getPartnerContacts(partner_id, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partnerContacts: res
            })
        })
    }

    updatePartnerContracts(partner_id) {
        this.setState({
            partner_id,
            partner_contact_id: undefined,
            contract_status: "all",
            loading: true
        })

        getPartnerContracts(partner_id, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partnerContracts: res,
                loading: false
            })
        })
    }

    render() {
        return (
            <div>
                <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
                <div className="row">
                    <div className="col-sm px-2 px-xl-5 py-3">
                        <div className="title text-primary mb-3">
                            {this.strings.view} {this.props.selectedCompany.role === 'partner' && (this.state.partnerContracts && this.state.partnerContracts.length) ? `${this.state.partnerContracts[0].partner_name} ${this.strings.contracts}` : this.strings.partnersContracts}
                        </div>
                    </div>
                </div>
                {
                    <div className="form-group row">
                        {
                            this.props.selectedCompany.role === 'admin' ?
                                <div className="col-sm-3">
                                    <h5 className="text-gray-800">{this.strings.partner}</h5>
                                    <select className="form-control mb-3" id="partner_id" name="partner" defaultValue="" onChange={e => this.updatePartnerContacts(e.target.value)}>
                                        <option hidden value="" disabled>{this.strings.selectPartner}</option>
                                        {
                                            this.state.partners ? this.state.partners.map(partner => <option key={partner.id} value={partner.id}>{partner.name}</option>) : null
                                        }
                                    </select>
                                </div>
                                : null
                        }
                        <div className="col-sm-3">
                            <h5 className="text-gray-800">{this.strings.partnerUsers}</h5>
                            <select className="form-control mb-3" id="partner_contact_id" name="partner_contact" onChange={e => this.setState({ partner_contact_id: parseInt(e.target.value) })} disabled={!this.state.partnerContacts || (this.state.partnerContacts && this.state.partnerContacts.length <= 1)}>
                                {
                                    this.state.partnerContacts && this.state.partnerContacts.length > 1 ? <option value={''}>{this.strings.all}</option> : null
                                }{
                                    this.state.partnerContacts && this.state.partnerContacts.length ? this.state.partnerContacts.map(contact => <option key={contact.id} value={contact.id}>{contact.fullName}</option>) : null
                                }
                            </select>
                        </div>
                        <div className="col-sm-3">
                            <h5 className="text-gray-800">{this.strings.contractStatus}</h5>
                            <select className="form-control mb-3" id="contract_status" name="contract_status" onChange={e => this.setState({ contract_status: e.target.value })} disabled={!(this.state.partnerContracts && this.state.partnerContracts.length)} value={this.state.contract_status || ''}>
                                <option value="all">{this.strings.all}</option>
                                <option value="active">{this.strings.active}</option>
                                <option value="ended">{this.strings.ended}</option>
                                <option value="expired">{this.strings.expired}</option>
                                <option value="unsigned">{this.strings.unsigned}</option>
                                <option value="future">{this.strings.future}</option>
                                <option value="unknown">{this.strings.unknown}</option>
                            </select>
                        </div>
                    </div>
                }
                {
                    this.props.selectedCompany.role === 'admin' && !this.state.partner_id ?
                        <div className="d-flex justify-content-center">
                            <p className="alert alert-info">
                                <i className="fas fa-info-circle mr-2"></i>{this.strings.noPartner}
                            </p>
                        </div>
                        : !this.state.partnerContracts || this.state.loading ?
                            <Loader>{this.strings.loading}</Loader>
                            : !this.state.partnerContracts.length ?
                                <div className="d-flex justify-content-center">
                                    <p className="alert alert-info">
                                        <i className="fas fa-info-circle mr-2"></i>{this.strings.noContracts}
                                    </p>
                                </div>
                                : <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">{this.strings.companyName}</th>
                                            <th scope="col">{this.strings.ean}</th>
                                            <th scope="col">{this.strings.contractType}</th>
                                            <th scope="col">{this.strings.status}</th>
                                            <th scope="col">{this.strings.partnerContact}</th>
                                            {
                                                this.state.partnerContracts[0].activeCommission ?
                                                    [
                                                        <th scope="col" key={1} className="text-right">{this.strings.partner}<br />{this.strings.fee}<br />(€/MWh)</th>,
                                                        <th scope="col" key={2} className="text-right">{this.strings.total}<br />{this.strings.volume}<br />(MWh)</th>,
                                                        <th scope="col" key={3} className="text-right">{this.strings.total}<br />{this.strings.income}<br />(€)</th>
                                                    ] : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.partnerContracts.map(contract => (
                                                (!this.state.partner_contact_id || contract.partner_contact_id === this.state.partner_contact_id) && (this.state.contract_status === "all" || contract.contract_status === this.state.contract_status) ?
                                                    <tr key={contract.id} className={contract.contract_status === 'active' ? 'bg-light' : ''}>
                                                        <td><Link className="cursor-pointer text-primary" to={`/account/company/switch/${contract.company_id}`}>{contract.companyName}</Link></td>
                                                        <td>{contract.ean}</td>
                                                        <td>{this.strings[contract.type]}</td>
                                                        <td>{this.strings[contract.contract_status]}</td>
                                                        <td>{contract.partner_contact}</td>
                                                        {
                                                            contract.activeCommission ?
                                                                [
                                                                    <td className="text-right">{contract.partnerFee}</td>,
                                                                    <td className="text-right">{numeral(contract.total_volume || 0).format('0,000,000.0')}</td>,
                                                                    <td className="text-right">{numeral(contract.total_income || 0).format('0,000,000.0')}</td>,
                                                                ] : null
                                                        }
                                                    </tr> : null
                                            ))
                                        }
                                    </tbody>
                                </table>
                }
            </div>



        )
    }
}

export default PartnerDashboard;
