import React, { Component } from 'react';

import SubHeader from '../../components/SubHeader';
import LocalizedStrings from '../../components/Localization';
import MarketDataDashboard from '../MarketDataDashboard';

class MarketData extends Component {
  constructor(props) {
    super(props);

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome"
      },
      nl: {
        welcome: "Welkom"

      }
    }, this.props.lang);
  }

  render() {

    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        < MarketDataDashboard
          lang={this.props.lang}
          internal={true}
          selectedCompany={this.props.selectedCompany}
        />
      </div>
    );
  }
}

export default MarketData;
