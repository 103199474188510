import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone'

import Loader from '../../components/Loader';
import LocalizedStrings from '../../components/Localization';
import PendingAgreement from '../../pages/documents/PendingAgreement';
import ContractGeneration from '../../components/forms/ContractGeneration';
import ContractProposal from '../../components/widgets/ContractProposal';
import UploadContract from '../../components/widgets/UploadContract';
import Mandates from '../../pages/services/Mandates';
import NewSdpForm from './NewSdpForm';
import Contracts from '../../components/widgets/Contracts';

import { getMandates } from '../../api/mandates';
import { getUsersForCompany } from '../../api/user';
import { getSdps } from '../../api/sdps';

moment.tz.setDefault('Europe/Brussels')

const STAGES = {
    sdp: {
        name: 'sdp',
        prev: null,
        next: 'mandate'
    },
    mandate: {
        name: 'mandate',
        prev: 'sdp',
        next: 'contract'
    },
    contract: {
        name: 'contract',
        prev: 'mandate',
        next: 'contractSigning'
    },
    contractSigning: {
        name: 'contractSigning',
        prev: 'contract',
        next: 'activeServices'
    },
    activeServices: {
        name: 'activeServices',
        prev: 'contractSigning',
        next: null
    }
}

class SdpOnboarding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usersForCompany: undefined,
            type: window.location.hash.substring(1),
            contractType: undefined,
            sdps: undefined,
            activeSdps: undefined,
            unsignedContracts: undefined,
            activeStage: window.location.hash.substring(1) || 'sdp',
            expanded: [],
            apiRequests: undefined,
            mandates: undefined
        };

        this.changeType = this.changeType.bind(this);
        this.changeContractType = this.changeContractType.bind(this);
        this.updateSdps = this.updateSdps.bind(this);
        this.activateStage = this.activateStage.bind(this);
        this.updateUsersForCompany = this.updateUsersForCompany.bind(this);
        this.updateMandates = this.updateMandates.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);

        this.strings = LocalizedStrings({
            en: {
                back: "Back",
                noUnsignedContracts: "There are no contracts that need to be signed.",
                noActivePoints: "There are no active points.",
                fillAndSign: "Fill and Sign",
                yourContract: "your contract",
                view: "Click to view SDP information",
                active: "Active",
                sdps: "service delivery points",
                demoAccount: "You are currently viewing the My Yuso demo account, which doesn't support adding of new installation points. If you want to add a new service installation point please first switch to your company",
                editContract: "Edit and review contract",
                ean: "EAN",
                operator: "Distribution operator",
                start: "Contract start",
                volume: "Annual volume",
                capacity: "Power capacity",
                measurementPoints: "Measurement points",
                meterDataPermissions: "Meterdata permissions",
                contractProposal: "Contract proposal",
                contractSigning: "Contract signing",
                activeServices: "Active services",
                injection: "Injection",
                supply: "Supply",
            },
            nl: {
                back: "Terug",
                noUnsignedContracts: "Er zijn geen contracten die moeten worden ondertekend.",
                noActivePoints: "Er zijn geen actieve punten.",
                fillAndSign: "Invullen en ondertekenen",
                yourContract: "uw contract",
                view: "Klik om SDP-informatie te bekijken",
                active: "Actief",
                sdps: "serviceleveringspunten",
                demoAccount: "U bekijkt momenteel de My Yuso-demo-account, die het toevoegen van nieuwe installatiepunten niet ondersteunt. Als u een nieuw service-installatiepunt wilt toevoegen, schakelt u eerst over naar uw bedrijf",
                editContract: "Contract bekijken",
                ean: "EAN",
                operator: "Distributie-exploitant",
                start: "Contract start",
                volume: "Jaarlijks volume",
                capacity: "Krachtcapaciteit",
                measurementPoints: "Meetpunten",
                meterDataPermissions: "Mandaten meterdata",
                contractProposal: "Contractvoorstel",
                contractSigning: "Contract ondertekenen",
                activeServices: "Actieve services",
                injection: "Injectie",
                supply: "Levering",
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.updateSdps()
        this.updateUsersForCompany()
        this.updateMandates()
    }

    updateMandates() {
        getMandates(
            this.props.selectedCompany.company_id,
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                this.setState({
                    mandates: res.mandates,
                    apiRequests: res.apiRequests
                });
            }
        );
    }

    updateUsersForCompany() {
        if (!this.props.selectedCompany) return;

        getUsersForCompany(
            this.props.selectedCompany.company_id,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    usersForCompany: res
                });
            }
        );
    }

    updateSdps(activeStage) {
        if (!this.props.selectedCompany) return;

        getSdps(
            this.props.selectedCompany.company_id,
            (err, res) => {

                if (err)
                    return window.reportError.send(err);

                let sdps = res.sdps_offtake_hourly.concat(res.sdps_injection_hourly)

                this.setState({
                    sdps,
                    activeSdps: sdps.filter(x => x.signatureDate !== null),
                    unsignedContracts: sdps.filter(x => !x.signatureDate),
                    activeStage: activeStage ? activeStage : this.state.activeStage
                });
            }
        );
    }


    activateStage(e, stage) {
        if (e) {
            if (STAGES[this.state.activeStage][e.target.id] !== null)
                this.setState({ activeStage: STAGES[this.state.activeStage][e.target.id] }, () => this.updateSdps())
        }
        else
            this.setState({ activeStage: stage }, () => this.updateSdps())
    }

    changeType(type) {
        this.setState({ type: false }, () => this.setState({ type: type }))
    }

    changeContractType(e) {
        this.setState({ contractType: e ? e.target.value : undefined })
    }

    toggleExpand(e) {
        let list = this.state.expanded,
            itemIndex = list.findIndex(x => x === e)

        if (itemIndex > -1)
            list.splice(itemIndex, 1)
        else
            list.push(e)

        this.setState({
            expanded: list
        });
    }

    render() {

        return (
            <div>
                <ol className='row steps'>
                    <li><i className={`ibtn fa fa-arrow-circle-left fa-2x text-primary${STAGES[this.state.activeStage].prev ? '' : ' d-none'}`} id="prev" onClick={this.activateStage} /></li>
                    <li className={this.state.activeStage === 'sdp' ? 'active' : ''} onClick={e => { e.preventDefault(); this.activateStage(undefined, "sdp") }}>
                        <span className="dot">1</span>
                        <h6>{this.strings.measurementPoints}</h6>
                    </li>
                    <li className={this.state.activeStage === 'mandate' ? 'active' : ''} onClick={e => { e.preventDefault(); this.activateStage(undefined, "mandate") }}>
                        <span className="dot">2</span>
                        <h6>{this.strings.meterDataPermissions}</h6>
                    </li>
                    <li className={this.state.activeStage === 'contract' ? 'active' : ''} onClick={e => { e.preventDefault(); this.activateStage(undefined, "contract") }}>
                        <span className="dot">3</span>
                        <h6>{this.strings.contractProposal}</h6>
                    </li>
                    <li className={this.state.activeStage === 'contractSigning' ? 'active' : ''} onClick={e => { e.preventDefault(); this.activateStage(undefined, "contractSigning") }}>
                        <span className="dot">4</span>
                        <h6>{this.strings.contractSigning}</h6>
                    </li>
                    <li className={this.state.activeStage === 'activeServices' ? 'active' : ''} onClick={e => { e.preventDefault(); this.activateStage(undefined, "activeServices") }}>
                        <span className="dot">5</span>
                        <h6>{this.strings.activeServices}</h6>
                    </li>
                    <li className="text-right"><i className={`ibtn fa fa-arrow-circle-right fa-2x ml-auto text-primary${STAGES[this.state.activeStage].next ? '' : ' d-none'}`} id="next" onClick={this.activateStage} /></li>
                </ol>

                {
                    this.state.activeStage === 'sdp' ?
                        this.props.selectedCompany.demo && this.props.user.username !== this.props.demo.contact.email ?
                            <div className="p-4">
                                <p className="alert alert-warning text-body">
                                    <i className="fas fa-exclamation-triangle mr-2"></i>{this.strings.demoAccount}&nbsp;
                                    <strong><Link className="text-decoration-none text-body" to={'/account/company/switch/' + (this.props.defaultCompany ? this.props.defaultCompany.companyNumber : this.props.demo.company.kbo)}> {this.props.defaultCompany ? this.props.defaultCompany.companyName : this.props.demo.company.name}</Link>.</strong>
                                </p>
                            </div>
                            :
                            <NewSdpForm
                                selectedCompany={this.props.selectedCompany}
                                info={this.props.info}
                                updateInfo={this.props.updateInfo}
                                type={this.props.type}
                                changeType={this.changeType}
                                activateStage={this.activateStage}
                                updateSdps={this.updateSdps}
                                updateMandates={this.updateMandates}
                                lang={this.props.lang}
                            />

                        : this.state.activeStage === 'mandate' ?
                            !this.state.mandates
                                ? <Loader />
                                : <Mandates
                                    selectedCompany={this.props.selectedCompany}
                                    mandates={this.state.mandates}
                                    apiRequests={this.state.apiRequests}
                                    updateMandates={this.updateMandates}
                                    sdps={this.state.sdps}
                                    usersForCompany={this.state.usersForCompany}
                                    updateCompanies={this.props.updateCompanies}
                                    lang={this.props.lang}
                                    updateSdps={this.updateSdps}
                                />

                            : this.state.activeStage === 'contract' ?
                                !this.state.sdps
                                    ? <Loader />
                                    : <ContractGeneration
                                        selectedCompany={this.props.selectedCompany}
                                        updateInfo={this.props.updateInfo}
                                        lang={this.props.lang}
                                        sdps={this.state.sdps.filter(x => !x.hasContract)}
                                        updateSdps={this.updateSdps}
                                    />

                                : this.state.activeStage === 'contractSigning' ?
                                    !this.state.unsignedContracts
                                        ? <Loader /> : !this.state.unsignedContracts.length ?
                                            <div className="row">
                                                <div className="col-12 p-4"><h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.fillAndSign}</strong>&nbsp;{this.strings.yourContract}</h4></div>
                                                <div className="col-2"></div>
                                                <div className="col-8"><p className="alert alert-info"><i className="fas fa-info-circle mr-2"></i> {this.strings.noUnsignedContracts}</p></div>
                                                <div className="col-2"></div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-12 p-4">
                                                    <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.fillAndSign}</strong>&nbsp;{this.strings.yourContract}</h4>
                                                </div>
                                                {
                                                    (this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner') && !this.state.contractType ?
                                                        [
                                                            <div className="col-sm-12 col-md-6 mb-4">
                                                                <ContractProposal
                                                                    selectedCompany={this.props.selectedCompany}
                                                                    usersForCompany={this.state.usersForCompany}
                                                                    sdps={this.state.sdps}
                                                                    lang={this.props.lang}
                                                                />
                                                                <Contracts
                                                                    lang={this.props.lang}
                                                                    sdps={this.state.sdps}
                                                                    selectedCompany={this.props.selectedCompany}
                                                                    updateSdps={this.updateSdps}
                                                                />
                                                            </div>,
                                                            <div className="col-sm-12 col-md-6 mb-4">
                                                                <UploadContract
                                                                    selectedCompany={this.props.selectedCompany}
                                                                    updateCompanies={this.props.updateCompanies}
                                                                    sdps={this.state.sdps}
                                                                    lang={this.props.lang}
                                                                />
                                                                <div className="card mb-4">
                                                                    <div className="card-header">{this.strings.editContract}</div>
                                                                    <div className="card-body clearfix">
                                                                        <select className="form-control mb-3 required" onChange={this.changeContractType} id="contractType" name="contractType" value={this.state.contractType} required>
                                                                            <option value=""></option>
                                                                            <option value={"injection"}>{this.strings.injection}</option>
                                                                            <option value={"supply"}>{this.strings.supply}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ]
                                                        :
                                                        [
                                                            <div className="col-sm-6 col-md-4">
                                                                <div className="d-flex">
                                                                    {
                                                                        this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner'
                                                                            ? <button className="btn btn-secondary text-white form-control mr-2" onClick={() => this.changeContractType(null)}><strong>{this.strings.back}</strong></button>
                                                                            : null
                                                                    }
                                                                    <select className="form-control" style={{ width: '200%' }} onChange={this.changeContractType} id="contractType" name="contractType" value={this.state.contractType} required>
                                                                        <option value=""></option>
                                                                        <option value={"injection"}>{this.strings.injection}</option>
                                                                        <option value={"supply"}>{this.strings.supply}</option>
                                                                    </select>
                                                                </div>
                                                            </div>,
                                                            <div className="col-12">
                                                                <PendingAgreement
                                                                    selectedCompany={this.props.selectedCompany}
                                                                    user={this.props.user}
                                                                    company_id={this.props.selectedCompany.company_id}
                                                                    type={this.state.contractType || 'supply'}
                                                                    info={this.props.info}
                                                                    lang={this.props.lang}
                                                                />
                                                            </div>
                                                        ]
                                                }
                                            </div>

                                    :

                                    this.state.activeStage === 'activeServices' ?
                                        !this.state.activeSdps
                                            ? <Loader />
                                            : !this.state.activeSdps.length ?
                                                <div className="row">
                                                    <div className="col-12 p-4"><h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.active}</strong>&nbsp;{this.strings.sdps}</h4></div>
                                                    <div className="col-2"></div>
                                                    <div className="col-8"><p className="alert alert-info"><i className="fas fa-info-circle mr-2"></i> {this.strings.noActivePoints}</p></div>
                                                    <div className="col-2"></div>
                                                </div>
                                                :
                                                <div className="p-4">
                                                    <h4 className="text-primary font-weight-light mb-4"><strong>{this.strings.active}</strong>&nbsp;{this.strings.sdps}</h4>
                                                    <table className="table table-sm mb-3">
                                                        <tbody>
                                                            {
                                                                this.state.activeSdps.map(sdp => {
                                                                    let expanded = this.state.expanded.length && this.state.expanded.findIndex(x => x === sdp.installation_id) > -1
                                                                    return <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <h5 onClick={() => this.toggleExpand(sdp.installation_id)} className={'text-primary cursor-pointer m-0 ' + (expanded ? 'font-weight-bold' : 'font-weight-light')} title={this.strings.view}>
                                                                                    {sdp.ean}<span className="text-secondary"> - <small> {sdp.addressStreet} {sdp.addressNumber}, {sdp.addressZip} {sdp.addressCity}, {sdp.addressCountry} </small></span>
                                                                                    <i className={'ml-2 ' + (expanded ? 'fas fa-angle-up' : 'fas fa-angle-down')}> </i>
                                                                                </h5>
                                                                            </div>
                                                                            {
                                                                                expanded ?
                                                                                    <div className="p-2">
                                                                                        <table className="table table-striped table-sm">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>{this.strings.ean}</td><td>{sdp.ean} ({sdp.installation_id})</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{this.strings.operator}</td><td>{sdp.dgo}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{this.strings.volume}</td><td>{sdp.volume || 'N/A'} {sdp.volume && 'MWh'}</td>
                                                                                                </tr>
                                                                                                {sdp.type_sdp !== 'injection' ? (
                                                                                                <tr> 
                                                                                                    <td>{this.strings.capacity}</td>
                                                                                                    <td>{sdp.power || 'N/A'}</td>
                                                                                                </tr>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {['power_solar', 'power_wind', 'power_hydro-electric head installations', 'power_thermal', 'power_other', 'power'].map((powerKey) => {
                                                                                                        const powerValue = sdp[powerKey];
                                                                                                        const displayName = {
                                                                                                            power_solar: 'Solar Power',
                                                                                                            power_wind: 'Wind Power',
                                                                                                            'power_hydro-electric head installations': 'Hydro Power',
                                                                                                            power_thermal: 'Thermal Power',
                                                                                                            power_other: 'Other Power',
                                                                                                            power: 'Power'
                                                                                                        }[powerKey];
                                                                                                        
                                                                                                        return powerValue !== null && powerValue !== 0 ? (
                                                                                                            <tr key={powerKey}>
                                                                                                            <td>{displayName}</td>
                                                                                                            <td>{powerValue + ' kW'}</td>
                                                                                                            </tr>
                                                                                                        ) : null;
                                                                                                        })}
                                                                                                    </> 
                                                                                                )}                                                                             
                                                                                                <tr>
                                                                                                    <td>{this.strings.meterDataPermissions}</td><td>{sdp.meterDataPermissions ? 'YES' : <span className="text-danger">NO</span>}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{this.strings.start}</td><td>{sdp.startDate ? moment(sdp.startDate).format('DD-MM-YYYY') : 'N/A'}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                        : null
                }
            </div >
        )
    }
}

export default SdpOnboarding;
