import React, { Component } from 'react';

class NumberInput extends Component {
  handleFocus = (event) => {
    if (event.target.type === 'number') {
      event.target.addEventListener('wheel', this.handleWheel, { passive: false });
    }
  };

  handleBlur = (event) => {
    if (event.target.type === 'number') {
      event.target.removeEventListener('wheel', this.handleWheel);
    }
  };

  handleWheel = (event) => {
    event.preventDefault();
  };

  render() {
    const { ...rest } = this.props;

    return (
      <input
        {...rest}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default NumberInput;
