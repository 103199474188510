import config from './config';
import { getAccessToken } from './user';


const getPartners = (cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/getPartners`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getPartner = (partner_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/getPartner?partner_id=${partner_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getPartnerContacts = (partner_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/getPartnerContacts?partner_id=${partner_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const getPartnerContracts = (partner_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/getPartnerContracts?partner_id=${partner_id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const updateCompanySignupPartner = (company_id, partner, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/updateCompanySignupPartner/${company_id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(partner)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const savePartner = (partner, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/savePartner`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(partner)
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

const deletePartner = (partner_id, cb) => {
  getAccessToken(
    (err, token) => {
      if (err) return cb(err);

      fetch(
        `${config.BASE_URL}/api/partners/deletePartner/${partner_id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        }
      ).then(
        (res) => {
          res.json().then(
            (res) => { cb(null, res); }
          )
        }
      ).catch(
        (err) => { return cb(err); }
      );
    }
  );
}

export {
  getPartners,
  getPartner,
  getPartnerContacts,
  getPartnerContracts,
  updateCompanySignupPartner,
  savePartner,
  deletePartner
};
