import React, { Component } from 'react';
import LocalizedStrings from '../Localization';

import { generateDemo } from '../../api/generate-demo';


class GenerateDemoAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generating: false
    }

    this.generateDemoAccount = this.generateDemoAccount.bind(this);

    this.strings = LocalizedStrings({
      en: {
        demoCreated: "The demo account was successfully re/generated!",
        generateDemo: "Generate Demo Account",
        generate: "Generate",
        generating: "Generating...",
        desc: "To generate (or fix broken) demo account on My Yuso use the \"Generate\" button below. The generate demo account will:",
        desc1: "re/create the demo company",
        desc2: "re/create the demo client",
        desc3: "re/link all existing users with the demo company",
        desc4: "re/create the demo injection point and contract",
        desc5: "re/create the demo supply point and contract",
        desc6: "re/create the demo@yuso.be Cognito account if necessary",
        desc7: "re/create and upload signed PDF contracts for the injection",
        desc8: "re/create and upload signed PDF contracts for the supply",
        error: "Error while generating the demo account. Please try again."
      },
      nl: {
        demoCreated: "Het demo-account is succesvol opnieuw gegenereerd/gegenereerd!",
        generateDemo: "Genereer Demo Account",
        generate: "Genereer",
        generating: "Het genereren van ...",
        desc: "Gebruik de knop \"Genereren\" hieronder om een demo-account op My Yuso te genereren (of te repareren). Het demo-account genereren:",
        desc1: "opnieuw/maak het demo-bedrijf",
        desc2: "maak/maak de demo-client aan",
        desc3: "koppel alle bestaande gebruikers opnieuw aan het demobedrijf",
        desc4: "maak/maak het demo-injectiepunt en contract",
        desc5: "opnieuw/maak het demo-leveringspunt en contract",
        desc6: "opnieuw/maak de demo@yuso.be Cognito-account indien nodig",
        desc7: "ondertekende PDF-contracten voor de injectie opnieuw maken/uploaden",
        desc8: "ondertekende PDF-contracten voor de levering opnieuw maken/uploaden",
        error: "Fout bij het genereren van het demo-account. Probeer het opnieuw."
      }
    }, this.props.lang);
  }

  generateDemoAccount(e) {
    e.preventDefault()

    this.setState({ error: false, generating: true })

    generateDemo(
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({ generating: false })

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.demoCreated }, () => {
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.generateDemo}
        </div>
        <div className="card-body clearfix">
          <form onSubmit={this.generateDemoAccount} noValidate>

            <div className="mb-3">
              {this.strings.desc}
              <ul>
                <li>{this.strings.desc1}</li>
                <li>{this.strings.desc2}</li>
                <li>{this.strings.desc3}</li>
                <li>{this.strings.desc4}</li>
                <li>{this.strings.desc5}</li>
                <li>{this.strings.desc6}</li>
                <li>{this.strings.desc7}</li>
                <li>{this.strings.desc8}</li>
              </ul>
            </div>
            
            <button type="submit" className="btn btn-primary mb-3" disabled={this.state.generating}>
              {
                this.state.generating ?
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                  : null
              }
              {this.state.generating ? this.strings.generating : this.strings.generate}
            </button>

            <p className={'alert alert-warning mb-3 ' + (this.state.error ? '' : 'd-none')}>
              <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
            </p>

            <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
              <i className="fas fa-info-circle mr-2"></i> {this.state.info}
            </p>
          </form>
        </div>
      </div>
    );
  }
}

export default GenerateDemoAccount;
