import '@fortawesome/fontawesome-free/css/all.css'

import React, { Component } from 'react';
import { Switch, Route, Redirect, Link, withRouter } from 'react-router-dom'

import Error from './components/Error';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Form from './components/Form';
import Loader from './components/Loader';
import LanguageChooser from './components/LanguageChooser';

// Pages
import Login from './pages/Login';
import ApprovalUser from './pages/ApprovalUser';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/home/Home';
import Info from './pages/Info';
import Services from './pages/services/Services';
import SwitchCompany from './pages/management/SwitchCompany';
import NewSdp from './pages/services/NewSdp';
import NewSdpCompleted from './pages/services/NewSdpCompleted';
import UserDetails from './pages/management/UserDetails';
import CompanyDetails from './pages/management/CompanyDetails';
import Agreements from './pages/documents/Agreements';
import PendingAgreement from './pages/documents/PendingAgreement';
import CompletedAgreement from './pages/documents/CompletedAgreement';
import GTC from './pages/documents/GTC';
import BSI from './pages/documents/BSI';
import BONWI from './pages/documents/BONWI';
import SignedMandate from './pages/documents/SignedMandate';
import SwitchConfirmation from './pages/documents/SwitchConfirmation';
import EocConfirmation from './pages/documents/EocConfirmation';
import Dashboards from './pages/dashboards/Dashboards';
import Statements from './pages/documents/Statements';
import Statement from './pages/documents/Statement';
import NewCustomer from './pages/admin/NewCustomer';
import ManageCompany from './pages/admin/ManageCompany';
import ManagePartners from './pages/admin/ManagePartners';
// import ManageUsers from './pages/admin/ManageUsers';
import PartnerDashboard from './pages/admin/PartnerDashboard';
import MarketData from './pages/admin/MarketData';
import Netting from './pages/admin/Netting';
import Partner from './pages/admin/Partner';
import Simulations from './pages/admin/Simulations';
import CertificatePrices from './pages/admin/CertificatePrices';
import AgreementsTemplates from './pages/admin/AgreementsTemplates';
import LocalizedStrings from './components/Localization';
import Unsubscribe from './pages/Unsubscribe';
import NotFound404 from './pages/NotFound404';
import UnsupportedBrowser from './pages/UnsupportedBrowser';
import MarketDataDashboard from './pages/MarketDataDashboard';

import METADATA from './metadata.json'

import { filterCompanies } from './api/company';
import { getUserCompany, userCheck } from './api/user';
import { getCompanyInfo } from './api/info';
import { getDemoData } from './api/demo';

import config from './api/config'


class App extends Component {
    constructor(props) {
        super(props);

        /**
         * FIRST CHECK THE JSON OBJECTS
         * If we have broken JSON object in localStorage
         * then clear the localStorage to prevent app issues
         */
        try {
            // Check only JSON objects
            window.reactStorage.getItem('user') && JSON.parse(window.reactStorage.getItem('user'))
            window.reactStorage.getItem('selectedCompany') && JSON.parse(window.reactStorage.getItem('selectedCompany'))
            window.reactStorage.getItem('datePeriodState') && JSON.parse(window.reactStorage.getItem('datePeriodState'))
            window.reactStorage.getItem('newCustomer') && JSON.parse(window.reactStorage.getItem('newCustomer'))
        } catch (err) {
            console.warn('Error while parsing "localStorage" objects, logging out...');

            // if the JSON parsing fails, clear the localStorage
            window.reactStorage.clear();
        }

        /**
         * SECOND CHECK THE APP VERSION
         * If we have new app version meaning we have deployed new code on the server
         * then clear the localStorage to prevent app issues due to breaking changes
         */
        if (METADATA.version !== window.reactStorage.getItem('version')) {
            console.warn(`New app version "${METADATA.version}" found, logging out...`);

            window.reactStorage.clear();

            // Save the new app version in localStorage
            window.reactStorage.setItem('version', METADATA.version)
        }

        this.state = {
            user: window.reactStorage.getItem('user') && JSON.parse(window.reactStorage.getItem('user')),
            userCheck: true,
            total_companies: undefined,
            selectedCompany: window.reactStorage.getItem('selectedCompany') && JSON.parse(window.reactStorage.getItem('selectedCompany')),
            defaultCompany: undefined,
            redirectToHome: false,
            redirectToSetupUrl: undefined,
            info: undefined,
            demo: undefined,
            lang: window.reactStorage.getItem('lang') || 'nl' // Use Nederlands as default language
        }

        document.getElementById('myyuso').setAttribute('lang', this.state.lang)

        this.checkUser = this.checkUser.bind(this);
        this.updateCompanies = this.updateCompanies.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onSwitchSelectedCompany = this.onSwitchSelectedCompany.bind(this);
        this.updateInfo = this.updateInfo.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    supportedBrowser() {
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        var isFirefox = typeof InstallTrigger !== 'undefined' || navigator.userAgent.indexOf('Mozilla') !== -1;

        var isSafari = navigator.userAgent.indexOf('Safari') !== -1;

        var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR') >= 0;

        return (isChrome || isFirefox || isSafari || isOpera)
    }

    componentDidMount() {
        // only set this for the production version
        if (!config.DEV) {
            this.props.piwik.push(['setCookieSameSite', 'None']);
            this.props.piwik.push(['setSecureCookie', true]);
        }

        this.checkUser();
        this.updateCompanies(this.state.selectedCompany ? this.state.selectedCompany.company_id : null);
    }

    componentDidUpdate() {

        window.reactStorage.setItem('activePage', '/' + window.location.pathname.split('/').filter(x => x.trim().length > 0)[0])

        if (window.reactStorage.getItem('activePage') !== this.state.activePage)
            this.setState({
                activePage: window.reactStorage.getItem('activePage')
            });
    }

    checkUser() {
        if (!this.state.user)
            return;

        // track the username
        this.props.piwik.setUserId(this.state.user.username);

        userCheck(this.state.user.username, (err, res) => {
            if (err)
                return window.reportError.send(err);

            if (res.success)
                this.setState({ userCheck: true })
            else
                this.onLogout();
        })
    }

    updateCompanies(company_id, managementPageLoading) {

        if (this.state.user && this.state.user.pool) {

            // firts get the demo data
            getDemoData((err, res) => {

                if (err)
                    return window.reportError.send(err);

                this.setState({
                    demo: res
                })
            })

            filterCompanies({}, (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    total_companies: res.count
                });
            });

            this.onSwitchSelectedCompany(company_id)

            this.setState({ managementPageLoading })
        }
    }

    onLogin(user, redirect) {

        this.setState({ redirectToHome: redirect, user: user }, () => {
            this.checkUser();
            window.reactStorage.setItem('user', JSON.stringify(user))
            this.updateCompanies();

            this.props.piwik.push(['trackPageView', 'My Yuso - Login']);

            this.setState({ redirectToHome: false });
        });
    }

    onLogout(redirectToSetupUrl) {

        // on logout delete all state and localy stored data
        let remove = {}
        for (let key in this.state)
            if (key !== 'lang')
                remove[key] = undefined;

        this.props.piwik.push(['setCustomUrl', '/']);
        this.props.piwik.push(['deleteCustomVariables', 'page']);
        this.props.piwik.push(['trackPageView', 'My Yuso - Logout']);
        this.props.piwik.push(['resetUserId']);

        this.setState({ ...remove, redirectToHome: true }, () => {
            for (let key in window.reactStorage.getItems())
                if (key !== 'lang' && key !== 'version')
                    window.reactStorage.removeItem(key);

            this.setState({ redirectToHome: false }, () => {
                if (redirectToSetupUrl)
                    this.setState({ redirectToSetupUrl: redirectToSetupUrl }, () => {
                        this.setState({ redirectToSetupUrl: undefined });
                    })
            });
        });
    }

    changeLanguage(lang) {
        this.setState({ lang }, () => document.getElementById('myyuso').setAttribute('lang', this.state.lang))
    }

    onSwitchSelectedCompany(company_id) {

        this.setState({ managementPageLoading: true });

        getUserCompany(
            null,
            (err, res) => {
                if (err) {
                    this.onLogout();
                    return window.reportError.send(err);
                }

                this.setState({
                    defaultCompany: res
                });
            })

        getUserCompany(
            company_id,
            (err, res) => {
                if (err) {
                    this.onLogout();
                    return window.reportError.send(err);
                }

                const selectedCompany = res

                this.setState({
                    selectedCompany
                });

                window.reactStorage.setItem('selectedCompany', JSON.stringify(selectedCompany))

                window.$crisp.push(['set', 'user:email', selectedCompany.email]);
                window.$crisp.push(['set', 'user:nickname', selectedCompany.firstName]);
                window.$crisp.push(['set', 'user:company', selectedCompany.companyName]);

                // get selected company info
                getCompanyInfo(selectedCompany.company_id, (err, info) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({ info });
                })

                // get demo company info
                getCompanyInfo(this.state.demo.company.kbo, (err, demoInfo) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({ demoInfo });
                })

                setTimeout(() => {
                    this.setState({ managementPageLoading: false });
                }, 1000);
            }
        );
    }

    // used to update the company info after adding an SDP
    updateInfo() {

        if (this.state.selectedCompany) {

            getCompanyInfo(this.state.selectedCompany.company_id, (err, info) => {
                if (err)
                    return window.reportError.send(err);
                this.setState({ info });
            })

            getCompanyInfo(this.state.demo.company.kbo, (err, demoInfo) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({ demoInfo });
            })
        }
    }

    render() {

        if (!this.supportedBrowser())
            return <UnsupportedBrowser lang={this.state.lang} />

        let strings = LocalizedStrings({
            en: {
                switchCompany: "You are viewing the My Yuso demo account.",
                clickHere: "Click here",
                switchBack: "to switch to your company",
                noCompanyLinked1: "It seems you don't have a company linked with your account.",
                noCompanyLinked2: "Please contact",
                noCompanyLinked3: "to resolve the issue.",
                logout: "Logout",
                updating: "Updating..."
            },
            nl: {
                switchCompany: "U bekijkt het demo-account van My Yuso.",
                clickHere: "Klik hier",
                switchBack: "om over te schakelen naar uw bedrijf",
                noCompanyLinked1: "Het lijkt erop dat u geen bedrijf heeft dat aan jouw account is gekoppeld.",
                noCompanyLinked2: "Neem contact op",
                noCompanyLinked3: "om het probleem op te lossen.",
                logout: "Uitloggen",
                updating: "Updaten..."
            }
        }, this.state.lang)

        if (this.state.redirectToHome)
            return <Redirect to="/" />

        if (this.state.redirectToSetupUrl)
            return <Redirect to={this.state.redirectToSetupUrl} />

        if (this.state.unsubscribe) {
            return (
                <div className="App">
                    <div className="container navigation-placeholder"></div>
                    <div className="container navigation">
                        <LanguageChooser lang={this.state.lang} changeLanguage={this.changeLanguage} />
                        <nav className="navbar navbar-expand-lg navbar-light bg-white">
                            <Link to="/" className="navbar-brand" onClick={() => { this.setState({ unsubscribe: false }) }}>
                                <img
                                    style={{ width: '150px' }}
                                    src={`${process.env.PUBLIC_URL}/yuso-portal.png`}
                                    alt='Yuso'
                                />
                            </Link>
                        </nav>
                    </div>

                    <div className="container main">
                        <div className="row">
                            <div className="col-sm pb-5">
                                <Route
                                    exact path="/unsubscribe/:email/:hash"
                                    component={({ match }) => (
                                        <Unsubscribe
                                            email={match.params.email}
                                            hash={match.params.hash}
                                            lang={this.state.lang}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <Footer lang={this.state.lang} />
                    </div>
                </div>
            )
        }

        if (window.location.pathname.startsWith("/market-data"))
            return <Route
                exact path={["/market-data", "/market-data/:lang"]}
                component={({ match }) => (
                    < MarketDataDashboard
                        lang={match.params.lang || 'en'}
                    />
                )}
            />

        if (!this.state.user) {
            return (
                <div className="App">
                    <Route
                        exact path="/unsubscribe/:email/:hash"
                        component={() => {
                            this.setState({ unsubscribe: true })
                            return null;
                        }}
                    />

                    <Error lang={this.state.lang} />

                    <div className="container">
                        <Form lang={this.state.lang} changeLanguage={this.changeLanguage}>
                            <Switch>
                                <Route
                                    exact path="/setup/:company/:user/:pass"
                                    component={({ match }) => (
                                        <Login
                                            onLogin={this.onLogin}
                                            company={match.params.company}
                                            user={match.params.user}
                                            pass={match.params.pass}
                                            firstTimeLogin={true}
                                            lang={this.state.lang}
                                        // approved={match.params.approved}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/forgot-password"
                                    component={() => (
                                        <ForgotPassword
                                            lang={this.state.lang}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/login/:user"
                                    component={({ match }) => (
                                        <Login
                                            onLogin={this.onLogin}
                                            user={match.params.user}
                                            emailLogin={true}
                                            lang={this.state.lang}
                                            approved={match.params.approved}
                                        />
                                    )}
                                />
                                <Route
                                    path="/"
                                    component={() => (
                                        <Login
                                            onLogin={this.onLogin}
                                            lang={this.state.lang}
                                            approved={this.state.approved}
                                        />
                                    )}
                                />
                            </Switch>
                        </Form>
                    </div>
                </div>
            )
        }

        if (this.state.total_companies === 0) {
            return (
                <div className="App">
                    <div className="container d-flex flex-column justify-content-center align-items-center p-5">
                        <div className="spacer"></div>
                        <h4 className="text-muted font-weight-light text-center mt-5">
                            <span className="display-1">
                                <i className="fas fa-exclamation-triangle text-danger"></i>
                            </span>
                            <br />
                            <br />
                            {strings.noCompanyLinked1}
                            <br />
                            {strings.noCompanyLinked2}
                            &nbsp;
                            <a className="text-primary" href="mailto:support@yuso.be">support@yuso.be</a>
                            &nbsp;
                            {strings.noCompanyLinked3}
                            <br />
                            <br />
                            <button className="btn btn-lg btn-primary" onClick={this.onLogout}>{strings.logout}</button>
                        </h4>
                        <div className="spacer"></div>
                    </div>
                </div>
            );
        }

        if (!(this.state.selectedCompany && this.state.info && this.state.total_companies !== undefined && this.state.userCheck && this.state.demo)) {
            return (
                <div className="App">
                    <Loader className="container" />
                </div>
            );
        }

        return (
            <div className="App">
                <Route
                    exact path="/unsubscribe/:email/:hash"
                    component={() => {
                        this.setState({ unsubscribe: true })
                        return null;
                    }}
                />

                <Error lang={this.state.lang} />

                <div>
                    <div className="container navigation-placeholder"></div>
                    <div className="container navigation">
                        <LanguageChooser lang={this.state.lang} changeLanguage={this.changeLanguage} />
                        <Navbar
                            user={this.state.user}
                            selectedCompany={this.state.selectedCompany}
                            onLogout={this.onLogout}
                            demo={this.state.demo}
                            lang={this.state.lang}
                        />
                    </div>
                </div>

                {
                    this.state.selectedCompany.demo && this.state.user.username !== this.state.demo.contact.email ?
                        <div>
                            <div className="container demo-info-placeholder"></div>
                            <div className="container demo-info p-0">
                                <div className="alert alert-primary d-flex align-items-center justify-content-center w-100 h-100 m-0">
                                    <i className="fas fa-info-circle mr-2"></i> {strings.switchCompany}
                                    &nbsp;
                                    <Link className="text-warning font-weight-bold" to={'/account/company/switch/' + (this.state.defaultCompany ? this.state.defaultCompany.companyNumber : this.state.demo.company.kbo)}>
                                        {strings.clickHere}
                                    </Link>
                                    &nbsp;
                                    {strings.switchBack}&nbsp;<strong>{this.state.defaultCompany ? this.state.defaultCompany.companyName : this.state.demo.company.name}</strong>
                                </div>
                            </div>
                        </div>
                        : null
                }

                <div className="container main">
                    <div className="row">
                        <div className="col-sm pb-5">
                            {/* if new or existing user is accessing My Yuso from the registration email link, log out the current user */}
                            <Route
                                exact path="/setup/:company/:user/:pass"
                                component={({ match }) => {
                                    this.onLogout(`setup/${match.params.company}/${match.params.user}/${match.params.pass}`);
                                    return null;
                                }}
                            />
                            <Route
                                exact path="/login/:user"
                                component={({ match }) => {
                                    this.onLogout(`login/${match.params.user}`);
                                    return null;
                                }}
                            />
                            <Switch>
                                <Route
                                    exact path="/"
                                    component={
                                        () => (
                                            <Home
                                                user={this.state.user}
                                                selectedCompany={this.state.selectedCompany}
                                                updateCompanies={this.updateCompanies}
                                                info={this.state.info}
                                                demoInfo={this.state.demoInfo}
                                                demo={this.state.demo}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/info"
                                    component={
                                        () => (
                                            <Info
                                                user={this.state.user}
                                                selectedCompany={this.state.selectedCompany}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/services"
                                    component={
                                        () => (
                                            <Services
                                                selectedCompany={this.state.selectedCompany}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/services/onboarding"
                                    component={
                                        () => (
                                            <NewSdp
                                                user={this.state.user}
                                                selectedCompany={this.state.selectedCompany}
                                                defaultCompany={this.state.defaultCompany}
                                                updateCompanies={this.updateCompanies}
                                                info={this.state.info}
                                                updateInfo={this.updateInfo}
                                                demo={this.state.demo}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/services/completed"
                                    component={
                                        () => (
                                            <NewSdpCompleted
                                                selectedCompany={this.state.selectedCompany}
                                                info={this.state.info}
                                                demo={this.state.demo}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/account/company"
                                    component={
                                        () => (
                                            <CompanyDetails
                                                selectedCompany={this.state.selectedCompany}
                                                updateSelectedCompany={this.updateSelectedCompany}
                                                updateCompanies={this.updateCompanies}
                                                user={this.state.user}
                                                info={this.state.info}
                                                demo={this.state.demo}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/account/company/switch/:company_id"
                                    component={
                                        ({ match, history, location }) => (
                                            <SwitchCompany
                                                selectedCompany={this.state.selectedCompany}
                                                onSwitchSelectedCompany={this.onSwitchSelectedCompany}
                                                company_id={match.params.company_id}
                                                history={history}
                                                location={location}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/account/user"
                                    component={
                                        () => (
                                            <UserDetails
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                onLogout={this.onLogout}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/agreements"
                                    component={
                                        () => (
                                            <Agreements
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/statements"
                                    component={
                                        () => (
                                            <Statements
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/pendingAgreement/:company_id/:type"
                                    component={
                                        ({ match }) => (
                                            <PendingAgreement
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                type={match.params.type}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/gtc", "/algemenevoorwaarden"]}
                                    component={
                                        ({ match }) => (
                                            <GTC
                                                selectedCompany={this.state.selectedCompany}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/bsi"]}
                                    component={
                                        ({ match }) => (
                                            <BSI
                                                selectedCompany={this.state.selectedCompany}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/bonwi"]}
                                    component={
                                        ({ match }) => (
                                            <BONWI
                                                selectedCompany={this.state.selectedCompany}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/completedAgreement/:company_id/:document_key"
                                    component={
                                        ({ match }) => (
                                            <CompletedAgreement
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                document_key={match.params.document_key}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/signedMandate/:company_id/:document_key"
                                    component={
                                        ({ match }) => (
                                            <SignedMandate
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                document_key={match.params.document_key}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/switchConfirmation/:company_id/:document_key"
                                    component={
                                        ({ match }) => (
                                            <SwitchConfirmation
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                document_key={match.params.document_key}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/eocConfirmation/:company_id/:document_key"
                                    component={
                                        ({ match }) => (
                                            <EocConfirmation
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                document_key={match.params.document_key}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/documents/statement/:company_id/:document_key"
                                    component={
                                        ({ match }) => (
                                            <Statement
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                company_id={match.params.company_id}
                                                document_key={match.params.document_key}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/dashboards"
                                    component={
                                        () => (
                                            <Dashboards
                                                selectedCompany={this.state.selectedCompany}
                                                user={this.state.user}
                                                info={this.state.info}
                                                lang={this.state.lang}
                                                updateCompanies={this.updateCompanies}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/admin/new-customer", "/partner/new-customer"]}
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' || this.state.selectedCompany.role === 'partner' ?
                                                <NewCustomer
                                                    updateCompanies={this.updateCompanies}
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/admin/manage-company", "/partner/manage-company"]}
                                    component={
                                        () => (
                                            this.state.managementPageLoading ?
                                                <Loader>{strings.updating}</Loader>
                                                :
                                                (
                                                    this.state.selectedCompany.role === 'admin' || this.state.selectedCompany.role === 'partner' ?
                                                        <ManageCompany
                                                            updateCompanies={this.updateCompanies}
                                                            selectedCompany={this.state.selectedCompany}
                                                            updateSelectedCompany={this.updateSelectedCompany}
                                                            user={this.state.user}
                                                            info={this.state.info}
                                                            lang={this.state.lang}
                                                        />
                                                        : <NotFound404 lang={this.state.lang} />
                                                )
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/manage-partners"
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <ManagePartners
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/manage-users"
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <div>
                                                    <br /><br />
                                                    Coming soon...
                                                </div>
                                                // <ManageUsers
                                                //     selectedCompany={this.state.selectedCompany}
                                                //     user={this.state.user}
                                                //     info={this.state.info}
                                                //     lang={this.state.lang}                                                   
                                                // />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/admin/partner-dashboard", "/partner/partner-dashboard"]}
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' || this.state.selectedCompany.role === 'partner' ?
                                                <PartnerDashboard
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/admin/market-data", "/partner/market-data"]}
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' || this.state.selectedCompany.role === 'partner' ?
                                                <MarketData
                                                    selectedCompany={this.state.selectedCompany}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/partner/:partner_id?"
                                    component={
                                        ({ match }) => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <Partner
                                                    selectedCompany={this.state.selectedCompany}
                                                    partner_id={match.params.partner_id}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path={["/admin/simulations", "/partner/simulations"]}
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' || this.state.selectedCompany.role === 'partner' ?
                                                <Simulations
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/netting"
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <Netting
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/certificate-prices"
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <CertificatePrices
                                                    selectedCompany={this.state.selectedCompany}
                                                    user={this.state.user}
                                                    info={this.state.info}
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route
                                    exact
                                    path="/approve/:user"
                                    component={
                                        ({ match }) => (
                                            <ApprovalUser
                                                selectedCompany={this.state.selectedCompany}
                                                user={match.params.user}
                                                lang={this.state.lang}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    exact path="/admin/agreements-templates"
                                    component={
                                        () => (
                                            this.state.selectedCompany.role === 'admin' ?
                                                <AgreementsTemplates
                                                    lang={this.state.lang}
                                                />
                                                : <NotFound404 lang={this.state.lang} />
                                        )
                                    }
                                />
                                <Route component={() => (<NotFound404 lang={this.state.lang} />)} />
                                <Route component={() => (<UnsupportedBrowser lang={this.state.lang} />)} />
                            </Switch>
                        </div>
                    </div>

                    <Footer lang={this.state.lang} />
                </div>
            </div>
        );
    }
}

export default withRouter(App);
