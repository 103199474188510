import React from 'react'
import moment from 'moment-timezone'

import LocalizedStrings from '../../components/Localization';

moment.tz.setDefault('Europe/Brussels')

export const ContractAreaLineAndLabel = props => {

    const strings = LocalizedStrings({
        en: {
            start: "Contract start",
            historic: "Historic values"
        },
        nl: {
            start: "Contract start",
            historic: "Historische waarden"
        }
    }, props.lang);

    const {
        fill, textAnchor,
        fontSize, viewBox, dy, dx,
        area,
        minContractDate,
        showContractLine,
        notStarted,
        isBarChart,
        addWidth
        // data
    } = props;

    return (
        <g>
            {
                viewBox.width > 25 ?
                    <text
                        x={viewBox.width < 100 ? viewBox.width + viewBox.x - 10 : viewBox.x + 10} y={viewBox.width < 100 ? viewBox.y + 10 : viewBox.y + 20}
                        dy={dy}
                        dx={dx}
                        fill={fill}
                        fontSize={fontSize || 14}
                        textAnchor={textAnchor}
                        writingMode={viewBox.width < 100 ? 'tb' : 'lr'}
                    >
                        {strings.historic}
                    </text>
                    : null
            }
            {
                !notStarted ?
                    <g>
                        {
                            showContractLine ?
                                [
                                    <line
                                        key={0}
                                        x={minContractDate + ' 00:00:00'}
                                        stroke="#da1100"
                                        strokeDasharray="5 3"
                                        fill="none"
                                        fillOpacity="1"
                                        strokeWidth="1"
                                        x1={viewBox.width + viewBox.x}
                                        y1={viewBox.height + + viewBox.y}
                                        x2={viewBox.width + viewBox.x}
                                        y2={viewBox.y}
                                        className="recharts-reference-line-line" />,
                                    <text
                                        key={1}
                                        x={viewBox.width + viewBox.x + 10} y={viewBox.y + 10}
                                        dy={dy}
                                        dx={dx}
                                        fill={fill}
                                        fontSize={fontSize || 14}
                                        textAnchor={textAnchor}
                                        writingMode="tb"
                                    >
                                        {strings.start}
                                    </text>
                                ]
                                : (isBarChart ?
                                    <rect x={viewBox.x + viewBox.width} y={viewBox.y} width={addWidth} height={viewBox.height} fill={area ? area.current && area.current.props.fill : null} fillOpacity={area ? area.current && area.current.props.fillOpacity : null} />
                                    : null)
                        }
                    </g>
                    : null
            }
        </g>
    )
}
