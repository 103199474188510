import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

import React, { Component } from 'react';
import numeral from 'numeral';
import moment from 'moment-timezone';

import DownloadButton from './DownloadButton';
import LocalizedStrings from '../../components/Localization';


class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxMV: {}
    }

    this.getMaxValueTooltip = this.getMaxValueTooltip.bind(this)

    this.strings = LocalizedStrings({
      en: {
        total: "Total",
        energy: "energy",
        injected: "injected",
        delivered: "delivered",
        produced: "produced",
        amount: "amount",
        average: "Average",
        price: "price",
        max: "Max",
        power: "power",
        export: "Export",
        csv: "CSV",
        maxValueInfo1: "Maximum power",
        maxValueInfo2: "in the selected period on",
        maxValueInfo3: "for",
        maxValueInfo4: "and",
        maxValueInfo5: "quarter hour",
        noMaxMV: "There's no maximum value"
      },
      nl: {
        total: "Totaal",
        energy: "energie",
        injected: "geïnjecteerd",
        delivered: "geleverd",
        produced: "geproduceerd",
        amount: "bedrag",
        average: "Gemiddelde",
        price: "prijs",
        max: "Max",
        power: "vermogen",
        value: "waarde",
        export: "Exporteren",
        csv: "CSV",
        maxValueInfo1: "Maximale vermogen",
        maxValueInfo2: "in de geselecteerde periode op",
        maxValueInfo3: "voor",
        maxValueInfo4: "en",
        maxValueInfo5: "kwartier",
        noMaxMV: "Er is geen maximale waarde"
      }
    }, this.props.lang);
  }

  static getDerivedStateFromProps(props, state) {
    let maxMV = props.isInjection ? props.maxInjectionMV : props.isProduction ? props.maxProductionMV : props.maxOfftakeMV

    if (maxMV !== state.maxMV)
      return { maxMV }

    // Return null if the state hasn't changed
    return null
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  getMaxValueTooltip() {
    if (!this.state.maxMV.max_mv_dtlt)
      return this.strings.noMaxMV

    const qh = moment(this.state.maxMV.max_mv_dtlt, 'YYYY-MM-DD HH:mm:ss')

    return `${this.strings.maxValueInfo1} ${this.props.isInjection ? this.strings.injected : this.props.isProduction ? this.strings.produced : this.strings.delivered} ${this.strings.maxValueInfo2} <strong>${qh.format('YYYY-MM-DD')}</strong><br/>${this.strings.maxValueInfo3} <strong>${this.state.maxMV.max_ean}</strong> ${this.strings.maxValueInfo4}<br/>${this.strings.maxValueInfo5} <strong>${qh.format('HH:mm')} – ${qh.add(15, 'minutes').format('HH:mm')}</strong>`
  }

  render() {

    if (!this.props.metervalues || !this.props.allSdps || !this.props.allSdps.length) {
      return <div />
    }

    if (!this.props.metervalues.length) {
      return <div />
    }

    const totalPL = this.props.metervalues.reduce(
      (sum, el) => sum + el.pl,
      0
    ).toFixed(2);

    const totalMV = this.props.metervalues.reduce(
      (sum, el) => sum + el.mv,
      0
    );

    const avgPrice = ((parseFloat(totalPL) / parseFloat(totalMV)) * 1000).toFixed(2);

    return (
      <div className="row mt-3 mb-5">
        <div className="col-sm d-flex justify-content-around flex-column flex-md-row">

          <div className="mb-2 text-center">
            <h5 className="text-primary font-weight-light mb-2">
              {this.strings.total} {this.strings.energy} <span className="font-weight-bold">{this.props.isInjection ? this.strings.injected : this.props.isProduction ? this.strings.produced : this.strings.delivered}</span>
            </h5>
            <h4 className="text-primary font-weight-light">
              {numeral(totalMV).format('0,000.00')} kWh
            </h4>
          </div>

          <div className="border-right border-warning w-0 mb-2"></div>

          {
            !this.props.isProduction  ? [

              <div className="mb-2 text-center" key={0}>
                <h5 className="text-primary font-weight-light mb-2">
                  {this.strings.total} <span className="font-weight-bold">{this.strings.amount}</span>
                </h5>
                <h4 className="text-primary font-weight-light">
                  {numeral(totalPL).format('0,000.00')} EUR
                </h4>
              </div>,

              <div className="border-right border-warning w-0 mb-2" key={1}></div>,

              <div className="mb-2 text-center" key={2}>
                <h5 className="text-primary font-weight-light mb-2">
                  {this.strings.average} <span className="font-weight-bold">{this.strings.price}</span>
                </h5>
                <h4 className="text-primary font-weight-light">
                  {numeral(avgPrice).format('0,000.00')} EUR/MWh
                </h4>
              </div>,

              <div className="border-right border-warning w-0 mb-2" key={3}></div>

            ] : null
          }

          <div className="mb-2 text-center">
            <h5 className="text-primary font-weight-light mb-2">
              {this.strings.max} <span className="font-weight-bold">{this.strings.power}</span> <sup><i className="fas fa-info-circle text-info cursor-pointer" data-toggle="tooltip" data-html="true" data-boundary="viewport" data-original-title={this.getMaxValueTooltip()} style={{ fontSize: '1rem' }}></i></sup>
            </h5>
            <h4 className="text-primary font-weight-light">
              {this.state.maxMV.max_mv ? numeral(this.state.maxMV.max_mv).format('0,000.00') + ' kW' : 'N/A'}
            </h4>
          </div>

          <div className="border-right border-warning w-0 mb-2"></div>

          <div className="mb-2 text-center">
            <h5 className="text-primary font-weight-light mb-2">
              <span className="font-weight-bold">{this.strings.export}</span> {this.strings.csv}
            </h5>
            <h4 className="text-primary font-weight-light" style={{ width: '130px', height: '38px' }}>
              <DownloadButton
                isInjection={this.props.isInjection}
                isProduction={this.props.isProduction}
                metervalues={this.props.metervalues}
                aggregation={this.props.aggregation}
                sdp={this.props.sdp}
                allSdps={this.props.allSdps}
                selectedCompany={this.props.selectedCompany}
                from={this.props.from}
                to={this.props.to}
                lang={this.props.lang}
              />
            </h4>
          </div>

        </div>
      </div>
    )
  }
}

export default Stats;