import React, { Component } from 'react';
import LocalizedStrings from '../Localization';

import { resetTest } from '../../api/reset-test';


class ResetTestAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetting: false
    }

    this.resetTestAccount = this.resetTestAccount.bind(this);

    this.strings = LocalizedStrings({
      en: {
        testReseted: "The test account was successfully reset!",
        resetTest: "Reset Test Account",
        reset: "Reset",
        resetting: "Resetting...",
        desc: "To reset the test account on My Yuso use the \"Reset\" button below. The reset test account will:",
        desc1: "remove the test company with KBO 123456789",
        desc2: "remove the test client test@yuso.be",
        desc3: "remove all links between test company and contacts",
        desc4: "remove the test injection points and contracts",
        desc5: "remove the test supply points and contracts",
        desc6: "remove the test@yuso.be Cognito account",
        error: "Error while resetting the test account. Please try again."
      },
      nl: {
        testReseted: "Het testaccount is succesvol gereset!",
        resetTest: "Testaccount opnieuw instellen",
        reset: "Reset",
        resetting: "Opnieuw instellen ...",
        desc: "Gebruik de knop \"Reset\" hieronder om het testaccount op My Yuso te resetten. Het reset-testaccount zal:",
        desc1: "verwijder het testbedrijf met KBO 123456789",
        desc2: "verwijder de testclient test@yuso.be",
        desc3: "verwijder alle koppelingen tussen testbedrijf en contacten",
        desc4: "verwijder de testinjectiepunten en contracten",
        desc5: "verwijder de testleveringspunten en contracten",
        desc6: "verwijder het test@yuso.be Cognito-account",
        error: "Fout bij het resetten van het testaccount. Probeer het opnieuw."
      }
    }, this.props.lang);
  }

  resetTestAccount(e) {
    e.preventDefault()

    this.setState({ error: false, resetting: true })

    resetTest(
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({ resetting: false })

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.testReseted }, () => {
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.resetTest}
        </div>
        <div className="card-body clearfix">
          <form onSubmit={this.resetTestAccount} noValidate>

            <div className="mb-3">
              {this.strings.desc}
              <ul>
                <li>{this.strings.desc1}</li>
                <li>{this.strings.desc2}</li>
                <li>{this.strings.desc3}</li>
                <li>{this.strings.desc4}</li>
                <li>{this.strings.desc5}</li>
                <li>{this.strings.desc6}</li>
              </ul>
            </div>
            
            <button type="submit" className="btn btn-primary mb-3" disabled={this.state.resetting}>
              {
                this.state.resetting ?
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                  : null
              }
              {this.state.resetting ? this.strings.resetting : this.strings.reset}
            </button>

            <p className={'alert alert-warning mb-3 ' + (this.state.error ? '' : 'd-none')}>
              <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
            </p>

            <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
              <i className="fas fa-info-circle mr-2"></i> {this.state.info}
            </p>
          </form>
        </div>
      </div>
    );
  }
}

export default ResetTestAccount;
