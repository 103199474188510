import $ from 'jquery'
import 'bootstrap/js/dist/modal'

import React, { Component } from 'react';

import Tabs from '../../components/Tabs';
import SubHeader from '../../components/SubHeader';
import { CallToAction, CtaTitle, CtaButton, CtaPanel } from '../../components/CallToAction';
import Injection from './Injection';
import Supply from './Supply';
import Production from './Production';
import Flexibility from './Flexibility';
import LocalizedStrings from '../../components/Localization';

import { getSdps, deleteSdp, archiveSdp } from '../../api/sdps';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sdps: undefined,
            showArchived: false,
            installation_id: '',
            type: '',
            archived: '',
            hasArchivedItems: {
                supply: false,
                injection: false,
                production: false
            },
            activeTab: ''
        };

        this.updateSdps = this.updateSdps.bind(this)
        this.setDeleteSdp = this.setDeleteSdp.bind(this)
        this.setArchiveSdp = this.setArchiveSdp.bind(this)
        this.deleteSdp = this.deleteSdp.bind(this)

        this.strings = LocalizedStrings({
            en: {
                services: "Services",
                desc: "An SDP (Service Delivery Point) refers to a measurement point and a Yuso service. An SDP has both technical and contractual parameters listed below.This data determines the various fees and costs(network costs, surcharges and taxes) that Yuso will charge you as a customer supplier.",
                supply: "Supply",
                injection: "Injection",
                production: "Production",
                flexibility: "Flexibility",
                errorDelete: "There was an error while deleting the SDP. Please try again.",
                errorArchive: "There was an error while archiving the SDP. Please try again.",
                errorUnArchive: "There was an error while unarchiving the SDP. Please try again.",
                sdpDeleted: "SDP successfully deleted!",
                sdpArchived: "SDP successfully archived!",
                sdpUnArchived: "SDP successfully unarchived!",
                confirmDelete: "Do you want to permanently delete",
                confirmArchive: "Do you want to archive",
                confirmUnArchive: "Do you want to unarchive",
                forCompany: "for company",
                deleteWarning: "Be careful as this operation cannot be undone!",
                archiveWarning: "If you proceed this SDP will be moved to archived SDPs!",
                delete: "Delete",
                archive: "Archive",
                unArchive: "Unarchive",
                showArchivedSdps: "Show archived SDPs",
                hideArchivedSdps: "Hide archived SDPs",
                close: "Close",
                cta1: "Want to add",
                cta2: "new service delivery",
                cta3: "point?",
                cta4: "Add",
                cta5: "SDP"
            },
            nl: {
                services: "Diensten",
                desc: "Een SDP (Service Delivery Point) of dienstverleningspunt verwijst naar een meetpunt en een bijhorende Yuso-service. Een SDP bevat zowel technische als contractuele parameters hieronder. Deze gegevens bepalen de verschillende vergoedingen en kosten (netwerkkosten, toeslagen en belastingen) die Yuso je zal aanrekenen.",
                supply: "Levering",
                injection: "Injectie",
                production: "Productie",
                flexibility: "Flexibiliteit",
                errorDelete: "Er is een fout opgetreden bij het verwijderen van de SDP. Probeer het opnieuw.",
                errorArchive: "Er is een fout opgetreden bij het archivieren van de SDP. Probeer het opnieuw",
                errorUnArchive: "Er is een fout opgetreden bij het dearchiveren van de SDP. Probeer het opnieuw.",
                sdpDeleted: "SDP succesvol verwijderd!",
                sdpArchived: "SDP succesvol gearchiveerd!",
                sdpUnArchived: "SDP is uit het archief gehaald!",
                confirmDelete: "Wilt u permanent verwijderen?",
                confirmArchive: "Wilt u archiveren",
                confirmUnArchive: "Wilt u dearchiveren",
                forCompany: "voor bedrijf",
                deleteWarning: "Wees voorzichtig want dit kan niet ongedaan worden gemaakt!",
                archiveWarning: "Als u doorgaat, wordt deze SDP verplaatst naar gearchiveerde SDPs!",
                delete: "Verwijder",
                archive: "Archiveer",
                unArchive: "Dearchiveren",
                showArchivedSdps: "Toon gearchiveerde SDPs",
                hideArchivedSdps: "Verberg gearchiveerde SDPs",
                close: "Sluiten",
                cta1: "Nieuw",
                cta2: "aansluitingspunt",
                cta3: "toevoegen?",
                cta4: "Toevoegen",
                cta5: "Aansluitingspunt"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.updateSdps();
    }

    updateSdps() {
        if (this.props.selectedCompany) {
            getSdps(
                this.props.selectedCompany.company_id,
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        sdps: res,
                        hasArchivedItems: {
                            supply: res.sdps_offtake_hourly && res.sdps_offtake_hourly.length ? res.sdps_offtake_hourly.some(item => item.archived === 1) : false,
                            injection: res.sdps_injection_hourly && res.sdps_injection_hourly.length ? res.sdps_injection_hourly.some(item => item.archived === 1) : false,
                            production: res.sdps_production && res.sdps_production.length ? res.sdps_production.some(item => item.archived === 1) : false
                        },
                    });
                }
            );
        }
    }

    setDeleteSdp(installation_id, type) {

        this.setState({
            installation_id,
            type
        })
    }

    setArchiveSdp(installation_id, type, archived) {
        this.setState({
            installation_id,
            type,
            archived
        })
    }

    archiveSdp() {
        if (!this.props.selectedCompany) return;

        archiveSdp(this.props.selectedCompany.company_id, this.state.installation_id, this.state.type, this.state.archived,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.error) {

                    this.setState({
                        status: this.state.archived === 1 ? this.strings.errorUnArchive : this.strings.errorArchive
                    })

                    $('#status-sdp').modal('show')
                }

                if (res.status === 'OK') {
                    this.setState({
                        status: this.state.archived === 1 ? this.strings.sdpUnArchived : this.strings.sdpArchived
                    })

                    this.updateSdps();

                    $('#status-sdp').modal('show')
                }

            }
        )
    }

    deleteSdp() {
        if (!this.props.selectedCompany) return;

        deleteSdp(this.props.selectedCompany.company_id, this.state.installation_id, this.state.type,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.error) {

                    this.setState({
                        status: this.strings.errorDelete
                    })

                    $('#status-sdp').modal('show')
                }

                if (res.status === 'OK') {
                    this.setState({
                        status: this.strings.sdpDeleted
                    })

                    this.updateSdps();

                    $('#status-sdp').modal('show')
                }

            }
        )
    }

    handleChangeCheckbox = () => {
        this.setState(() => ({
            showArchived: !this.state.showArchived
        }));
    };

    handleTabChange = (activeTabIndex) => {
        let tap
        if (activeTabIndex === 0) {
            tap = 'supply';
        } else if (activeTabIndex === 1) {
            tap = 'injection';
        } else if (activeTabIndex === 2) {
            tap = 'production';
        } else if (activeTabIndex === 3) {
            tap = 'flexibility';
        }
        this.setState({
            activeTab: tap
        })
    };

    render() {
        return (
            <div>
                <SubHeader name={this.strings.services} type="Services" />
                <div className="row">
                    <div className="col-lg-7 px-2 px-xl-5 py-3">
                        <div className="spacer"></div>
                        <p className="mb-5">
                            {this.strings.desc}
                        </p>
                        <Tabs titles={[
                            { name: this.strings.supply, id: 'supply' },
                            { name: this.strings.injection, id: 'injection' },
                            { name: this.strings.production, id: 'production' },
                            { name: this.strings.flexibility, id: 'flexibility' }
                        ]} onTabChange={this.handleTabChange} >
                            <Supply
                                sdps={this.state.sdps}
                                selectedCompany={this.props.selectedCompany}
                                updateSdps={this.updateSdps}
                                setDeleteSdp={this.setDeleteSdp}
                                setArchiveSdp={this.setArchiveSdp}
                                lang={this.props.lang}
                                isArchived={this.state.showArchived}
                            />
                            <Injection
                                sdps={this.state.sdps}
                                selectedCompany={this.props.selectedCompany}
                                updateSdps={this.updateSdps}
                                setDeleteSdp={this.setDeleteSdp}
                                setArchiveSdp={this.setArchiveSdp}
                                lang={this.props.lang}
                                isArchived={this.state.showArchived}
                            />
                            <Production
                                sdps={this.state.sdps}
                                selectedCompany={this.props.selectedCompany}
                                updateSdps={this.updateSdps}
                                setDeleteSdp={this.setDeleteSdp}
                                setArchiveSdp={this.setArchiveSdp}
                                lang={this.props.lang}
                                isArchived={this.state.showArchived}
                            />
                            <Flexibility
                                sdps={this.state.sdps}
                                selectedCompany={this.props.selectedCompany}
                                lang={this.props.lang}
                            />
                        </Tabs>

                        <div className="modal fade" id="delete-sdp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <p>
                                            {this.strings.confirmDelete} <strong>{this.state.installation_id}</strong> {this.strings.forCompany} <strong>{this.props.selectedCompany.companyName}</strong>? {this.strings.deleteWarning}
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { this.deleteSdp() }}>{this.strings.delete}</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.archived === 1 ?
                            <div className="modal fade" id="archive-sdp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <p>
                                                {this.strings.confirmUnArchive} <strong>{this.state.installation_id}</strong> {this.strings.forCompany} <strong>{this.props.selectedCompany.companyName}</strong>?
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { this.archiveSdp() }}>{this.strings.unArchive}</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="modal fade" id="archive-sdp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <p>
                                                {this.strings.confirmArchive} <strong>{this.state.installation_id}</strong> {this.strings.forCompany} <strong>{this.props.selectedCompany.companyName}</strong>? {this.strings.archiveWarning}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { this.archiveSdp() }}>{this.strings.archive}</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="modal fade" id="status-sdp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <p>
                                            {this.state.status}
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 px-2 px-xl-5 py-3 d-flex flex-column align-items-end"></div>
                        {this.state.hasArchivedItems[this.state.activeTab] ?
                            <div className="form-check d-flex justify-content-end">

                                <label className="form-check-label cursor-pointer ml-auto" htmlFor="showArchivedCheckbox" onClick={this.handleChangeCheckbox}>
                                    <i
                                        className={this.state.showArchived ? "fas fa-arrow-up text-danger" : "fas fa-arrow-down text-danger"}
                                    ></i>
                                    {this.state.showArchived ? this.strings.hideArchivedSdps : this.strings.showArchivedSdps}
                                </label>
                            </div> : null
                        }
                    </div>
                    <div className="col-lg-5 px-2 px-xl-5 py-3">
                        <CallToAction className="my-4 ml-4">
                            <CtaTitle>{this.strings.cta1} <strong>{this.strings.cta2}</strong> {this.strings.cta3}</CtaTitle>
                            <CtaButton className="w-100" link={'/services/onboarding'}>
                                <strong>{this.strings.cta4}</strong> {this.strings.cta5}
                            </CtaButton>
                        </CallToAction>

                        <CtaPanel
                            className={'my-4 ml-4 ' + (!this.props.info.sdps.has_sdps ? 'd-none' : '')}
                            link="/services#flexibility"
                            type="request_flexibility"
                            lang={this.props.lang}
                            buttonClassName="w-100" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
