import config from './config';
import { getAccessToken } from './user';
import moment from 'moment-timezone';


const groupList = (groupId, cb) => {
    getAccessToken((err, token) => {
        if (err) return cb(err)
        fetch(`${config.BASE_URL}/api/netting/groupList/${groupId}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                },
                method: 'GET'
            }
        ).then(
            (res) => {
                res.json().then(
                    (res) => { cb(null, res) }
                )
            }
        ).catch(
            (err) => { return cb(err) }
        )
    }
    )
}
const uploadingAgreement = (agreement, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/netting/uploadFile`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'

                    },
                    method: 'POST',
                    body: JSON.stringify(agreement)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}
const deleteGroup = (groupId, cb) => {
    getAccessToken((err, token) => {
        if (err) return cb(err)
        fetch(`${config.BASE_URL}/api/netting/deleteGroup/${groupId}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                },
                method: 'GET'
            }
        ).then(
            (res) => {
                res.json().then(
                    (res) => { cb(null, res) }
                )
            }
        ).catch(
            (err) => { return cb(err) }
        )
    }
    )
}

const save = (group, saveMethod, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/netting/saveNettingGroup/${saveMethod}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'

                    },
                    method: 'POST',
                    body: JSON.stringify(group)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const downloadAgreementFile = (agreementFileName, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);

            fetch(
                `${config.BASE_URL}/api/netting/downloadAgreementFile/${agreementFileName}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                })
                .then(res => {
                    if (res.status !== 204)
                        res.blob()
                            .then(blob => {
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                // the filename you want
                                a.download = agreementFileName
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                            })
                    else
                        cb({ no_eans_found: true })
                })
                .catch(err => cb(err));
        }
    );
}
const getVolumes = (companyId, from, to, agg, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            from = moment(from).tz('Europe/Brussels').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            to = moment(to).tz('Europe/Brussels').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            fetch(
                `${config.BASE_URL}/api/netting/getNetting/${companyId}/${from}/${to}/${agg}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'

                    },
                    method: 'POST',
                    body: JSON.stringify({
                        companyId,
                        from,
                        to,
                        agg
                    })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

export {
    save,
    getVolumes,
    groupList,
    deleteGroup,
    uploadingAgreement,
    downloadAgreementFile
}