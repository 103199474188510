import $ from 'jquery'
import 'bootstrap/js/dist/modal'

import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import moment from 'moment-timezone'
import LocalizedStrings from '../Localization';
import MomentLocaleUtils from 'react-day-picker/moment';

import Address from './Address';

import { getKbo } from '../../api/kbo';
import { getLegalForm } from '../../api/company';

import 'react-day-picker/lib/style.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import 'moment/locale/nl-be';

const fromMonth = new Date(1900, 0);
const toMonth = new Date();

function YearMonthForm({ date, localeUtils, locale, onChange }) {

    const months = localeUtils.getMonths(locale);

    let years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i++) {
        years.push(i);
    }

    const handleChange = function handleChange(e) {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <div className="DayPicker-Caption">
            <div className="d-flex">
                <select name="month" onChange={handleChange} value={date.getMonth()} className="form-control date-chooser mr-1" style={{ width: '105px' }}>
                    {months.map((month, i) => (
                        <option key={month} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
                <select name="year" onChange={handleChange} value={date.getFullYear()} className="form-control date-chooser" style={{ width: '65px' }}>
                    {years.reverse().map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

class Company extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
            month: toMonth,
            legalForms: [],
            selectedLegalForm: ''
        };

        this.onKboSearch = this.onKboSearch.bind(this);
        this.onKboChanged = this.onKboChanged.bind(this);
        this.onFoundedDateChange = this.onFoundedDateChange.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.cleanUpKbo = this.cleanUpKbo.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.handleLegalForm = this.handleLegalForm.bind(this);

        this.strings = LocalizedStrings({
            en: {
                kbo: "KBO Number",
                lookup: "Lookup by typing company number or name",
                founded: "Founded",
                dateFounded: "Foundation date of the company",
                name: "Name",
                legalName: "Legal Name",
                vat: "VAT",
                legalForm: "Legal form",
                noSuchLegalForm: "is not supported legal form. Please select another one from the dropdown above.",
                email: "Email",
                website: "Website",
                phone: "Phone",
                nace: "Nace Code",
                vatInfo: "The VAT number is automatically generated from the KBO number entered above.",
                close: "Close"
            },
            nl: {
                kbo: "KBO-nummer",
                lookup: "Opzoeken via ondernemingsnummer of bedrijfsnaam",
                founded: "Opgericht",
                dateFounded: "Oprichtingsdatum van het bedrijf",
                name: "Bedrijfsnaam",
                legalName: "Legale naam",
                vat: "BTW-nummer",
                legalForm: "Rechtsvorm",
                noSuchLegalForm: "is geen ondersteunde rechtsvorm. Selecteer een andere uit de vervolgkeuzelijst hierboven.",
                email: "E-mail",
                website: "Website",
                phone: "Telefoon",
                nace: "Nace-code",
                vatInfo: "Het btw-nummer wordt automatisch gegenereerd op basis van het hierboven ingevoerde KBO-nummer.",
                close: "Sluiten"
            }
        }, this.props.lang);
    }

    onKboSearch(query) {
        this.setState({ isLoading: true });

        if (query.length) {
            getKbo(query, (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    isLoading: false,
                    options: res
                });
            });
        }
    }

    onKboChanged(selected) {
        let value = selected.length ? selected[0].number : ''
        if (value)
            this.props.onFieldChange({ target: { id: 'kbo_number', value: value }, company: selected[0] })
    }

    onFoundedDateChange(foundedDate) {
        this.props.onFieldChange({ target: { id: 'company_founded', value: moment(foundedDate).format('YYYY-MM-DD') } })
    }

    onFieldChange(value) {
        this.props.onFieldChange({ target: { id: 'kbo_number', value: value } })
    }

    cleanUpKbo(e) {
        if (e.target.id === 'kbo_number') {

            const clean_value = e.target.value ? String(e.target.value).replace(/\s+|[^0-9]+|\b0+/g, '').trim() : '';

            e.target.value = clean_value;
            e.target.defaultValue = clean_value;
        }
    }

    onFocus(e) {
        if (e.target.id === 'company_vat') {
            $('#vat-help').modal('show')
        }
    }

    handleYearMonthChange(month) {
        this.setState({ month });
    }

    componentDidMount() {
        getLegalForm((err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                legalForms: res
            });

            this.props.setLegalForms(res);
        });
    }

    handleLegalForm(event) {
        this.setState({
            selectedLegalForm: event.target.value,
        });
    }

    render() {
        return (
            <div>
                <div className="form-group row required">
                    <label htmlFor="kbo_number" className="col-sm-3 col-form-label">
                        {this.strings.kbo}
                    </label>
                    <div className="col-sm-9">
                        {
                            this.props.adminSave ?
                                <input type="text" className="form-control" readOnly value={this.props.selectedCompany ? this.props.selectedCompany.formattedNumber : 'N/A'} />
                                :
                                <AsyncTypeahead
                                    key={this.props.resetKey}
                                    id="kbo_number"
                                    clearButton
                                    name="kbo_number"
                                    required
                                    useCache={false}
                                    filterBy={
                                        (option, props) => {

                                            let q = String(props.text).trim().toLowerCase().replace(/\.+/g, '')

                                            if (q.substring(0, 2).toUpperCase() === 'BE' && /^[0-9]+$/.test(q.substring(2).trim()))
                                                q = q.substring(2).trim()

                                            q = q.replace(/\b0+/g, '')

                                            if (!option)
                                                return false

                                            return (
                                                (String(option.number) && String(option.number).toLowerCase().indexOf(q)) !== -1 ||
                                                (option.name && option.name.toLowerCase().indexOf(q)) !== -1
                                            );
                                        }
                                    }
                                    labelKey={option => `${option.number}`}
                                    renderMenu={(results, menuProps) => (
                                        <Menu {...menuProps}>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={result.number}>
                                                    {result.name}
                                                    <br />
                                                    <small className="text-muted">{result.formattedNumber}<span className={!result.city ? 'd-none' : ''}>&nbsp;- {result.city}</span></small>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    )}
                                    inputProps={{ required: true, name: 'kbo_number', id: 'kbo_number', ref: el => this.kbo = el }}
                                    onInputChange={this.onFieldChange}
                                    onFocus={this.cleanUpKbo}
                                    onBlur={this.cleanUpKbo}
                                    defaultInputValue={(this.props.value['kbo_number'] || '').toString()}
                                    isLoading={this.state.isLoading}
                                    placeholder={this.strings.lookup}
                                    options={this.state.options}
                                    onSearch={this.onKboSearch}
                                    onChange={this.onKboChanged}
                                    resetForm={this.props.resetForm}
                                />
                        }
                    </div>
                </div>
                {
                    [
                        { label: this.strings.name, name: 'name', required: true, readonly: true },
                        { label: this.strings.legalName, name: 'legal_name', required: true },
                        { label: this.strings.vat, name: 'vat', required: true, readonly: false },
                        { label: this.strings.legalForm, name: 'legal_form_short', required: true, readonly: false },
                        { label: this.strings.founded, name: 'founded', required: true, readonly: false },
                        { label: this.strings.email, name: 'email', required: true, email: 'yes' },
                        { label: this.strings.website, name: 'website', required: false },
                        { label: this.strings.phone, name: 'phone', required: false },
                        { label: this.strings.nace, name: 'nace_code', required: false }
                    ]
                        .map(item => {
                            let name = 'company_' + item.name;

                            return (
                                item.name === 'company_founded' ?
                                    <div className="form-group row">
                                        <label htmlFor="company_founded" className="col-sm-3 col-form-label">
                                            {this.strings.founded}
                                        </label>
                                        <div className="col-sm-9 date-picker-full">
                                            <DayPickerInput
                                                value={this.props.value['company_founded'] || ''}
                                                placeholder={this.strings.dateFounded}
                                                format="YYYY-MM-DD"
                                                formatDate={(date, format) => { return moment(date).format(format) }}
                                                inputProps={{ className: 'form-control', readOnly: 'readonly', name: 'company_founded', id: 'company_founded' }}
                                                onDayChange={this.onFoundedDateChange}
                                                dayPickerProps={{
                                                    month: this.state.month,
                                                    fromMonth: fromMonth,
                                                    toMonth: toMonth,
                                                    localeUtils: MomentLocaleUtils,
                                                    locale: this.props.lang,
                                                    disabledDays: (date) => moment(date) > moment(),
                                                    captionElement: ({ date, localeUtils, locale }) => (
                                                        <YearMonthForm
                                                            date={date}
                                                            localeUtils={localeUtils}
                                                            locale={locale}
                                                            onChange={this.handleYearMonthChange}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div> :
                                    <div className={'form-group row ' + (item.required ? 'required' : '')} key={name}>
                                        <label htmlFor={name} className="col-sm-3 col-form-label">
                                            {item.label}
                                        </label>
                                        <div className="col-sm-9">
                                            {item.name === 'legal_form_short' ? (
                                                [
                                                    <select
                                                        key={1}
                                                        className='form-control'
                                                        id={name}
                                                        name={name}
                                                        required={item.required}
                                                        readOnly={item.readonly}
                                                        onFocus={this.onFocus}
                                                        value={this.props.value[name] || ''}
                                                        onChange={this.props.onFieldChange}
                                                    >
                                                        <option value="" disabled></option>
                                                        {this.state.legalForms.map(x => (
                                                            <option key={x.legalFormShort} value={x.legalFormShort}>
                                                                {x.legalFormLong} - {x.legalFormShort}
                                                            </option>
                                                        ))}
                                                    </select>,
                                                    <div key={2} className={'text-muted mt-2' + (this.props.value.display_company_legal_form ? '' : ' d-none')}>
                                                        <i className="fas fa-exclamation-triangle text-warning"></i> <strong>{this.props.value.display_company_legal_form}</strong> {this.strings.noSuchLegalForm}
                                                    </div>
                                                ]
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={name}
                                                    name={name}
                                                    required={item.required}
                                                    readOnly={item.readonly}
                                                    value={this.props.value[name] || ''}
                                                    onFocus={this.onFocus}
                                                    onChange={this.props.onFieldChange} />
                                            )}
                                        </div>
                                    </div>
                            )
                        })
                }
                <Address name="company_address" onFieldChange={this.props.onFieldChange} value={this.props.value} lang={this.props.lang} />

                {/* <div className="modal fade" id="vat-help" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p>
                                    {this.strings.vatInfo}
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.strings.close}</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default Company;
