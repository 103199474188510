import React, { Component } from 'react';

class Dropdown extends Component {
    render() {
        return (
			<li className={'nav-item dropdown ' + (this.props.className || '') + (this.props.href === window.reactStorage.getItem('activePage') ? 'active' : '')}>
          		<span
					className="nav-link dropdown-toggle d-flex justify-content-between align-items-center"
					role="button"
					title={this.props.title || null}
                >
					<i className={'fas mr-2 ' + (this.props.icon || 'd-none')}></i>
					<span className="text-uppercase text-truncate d-inline-block" style={{ maxWidth: this.props.maxWidth || '120px' }}>{this.props.name}</span>
					<i className="fas fa-angle-down ml-2"></i>
				</span>
          		<div className="dropdown-menu">
	            	{this.props.children}
          		</div>
        	</li>
        );
    }
}

export default Dropdown
