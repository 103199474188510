import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IBAN from 'iban';

import SubHeader from '../../components/SubHeader';
import FormPanel from '../../components/forms/FormPanel';
import Contact from '../../components/forms/Contact';
import Company from '../../components/forms/Company';
import Billing from '../../components/forms/Billing';
import { CallToAction, CtaTitle, CtaContent } from '../../components/CallToAction';
import LocalizedStrings from '../../components/Localization';
import { companyCheck, contactCheck } from '../../api/company';

import config from '../../api/config';

import { addNewCustomer } from '../../api/new-customer';
import { sendApprovalEmail } from '../../api/user';


class NewCustomer extends Component {
    constructor(props) {
        super(props);

        this.newCustomer = {}

        const newCustomer = window.reactStorage.getItem('newCustomer')
        if (newCustomer)
            this.newCustomer = JSON.parse(newCustomer);

        // delete the partner id if the logged in user is not 'admin'
        if (this.props.selectedCompany && this.props.selectedCompany.role !== 'admin')
            delete this.newCustomer.partner_id;

        this.initialState = {
            stage: 'contact',
            send_email: false,
            status: null,
            invalidIban: false,
            unknownContact: false,
            companyExists: false,
            contactExists: false
        };

        // set default partner Yuso if the logged in user is 'admin'
        if (this.props.selectedCompany && this.props.selectedCompany.role === 'admin')
            this.initialState.partner_id = config.YUSO_PARTNER_ID;

            this.state = {
                ...this.initialState,
                ...this.newCustomer,
                error: false,
                customerAdded: false,
                addingCustomer: false,
                lang: this.props.lang,
                legalForms: [],
                resetKey: 0,
                showModal: false,
                showApprovalModal: false,
                showInfoModal: false
            };
            

        this.onSubmit = this.onSubmit.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.activateStage = this.activateStage.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.addingFinished = this.addingFinished.bind(this);
        this.setResetKey = this.setResetKey.bind(this);
        this.setLegalForms = this.setLegalForms.bind(this);
        this.companyExistance = this.companyExistance.bind(this);
        this.contactExistance = this.contactExistance.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);


        this.strings = LocalizedStrings({
            en: {
                errorRequired: "Missing required field",
                errorEmail: "Not proper email field",
                errorEmailExists: "This contact email already exists.",
                errorKboLength: "The KBO number must be 8 to 10 digits.",
                errorCompanyExists: "This company number already exists.",
                errorUserExists: "This user already exists in Amazon Congnito.",
                errorUserCompanyExists: "User and company already exist and they are linked",
                errorGeneric: "There was an error while saving the data. Please try again.",
                errorUserNotAuth: "Not authorized to perform this operation",
                errorIban: "The IBAN is invalid.",
                welcome: "Welcome",
                addUser: "you can add new customer",
                form: "with the form below.",
                continueDesc: "It seems you started adding a new user with company but didn't finish. So we populated your fields in case you want to continue. Otherwise you can",
                clearForm: "clear the form",
                start: "and start fresh.",
                contactDetails: "Contact details",
                unknownContact: "Unknown contact details. I will act as contact person for now.",
                companyDetails: "Company details",
                billingDetails: "Billing details",
                next: "Next",
                back: "Back",
                clear: "Clear",
                finalize: "Finalize",
                review: "Review",
                data: "data",
                errors: "These errors occurred:",
                sendEmail: "Send confirmation email to",
                sendApprovalEmail: "Send approval email to",
                save: "Save",
                addNew: "Add New",
                status: "This is the status:",
                switch: "Add SDP for company",
                status1: "The user was successfully created",
                status2: "The user already exists",
                status3: "The company was successfully created",
                status4: "The company already exists",
                status5: "The user and the company were successfully linked",
                status6: "The user and the company were already linked",
                status7: "The Cognito user was successfully created",
                status8: "The Cognito user already exists",
                howTo1: "How to",
                howTo2: "create a new",
                howTo3: "user?",
                howTo4: "Follow the steps in the",
                howTo5: "form on the left",
                howTo6: "and fill out all details in order to create new user with company and billing information.",
                howTo7: "First enter the contact person details.",
                howTo8: "Then enter the company information.",
                howTo9: "At the end enter the billing details.",
                grantAccess1: "This company already exists in My Yuso and you are not currently a registered user. To grant you access to this company we will first send an email for approval to the company. After confirmation, you will receive an email to log in for this company.",
                grantAccess2: "This company already exists in My Yuso. To grant the user access to this company, we will first send an email for approval to the company. After confirmation, the user will receive an email to log in for this company.",
                pressOK: "Press OK to continue.",
                switch1: "This company already exists in My Yuso. Click OK to switch to this company.",
                emailSent: "The account was created and approval email was successfully sent to",
                company: "the company",
                cancel: "Cancel",
                close: "Close"
            },
            nl: {
                errorRequired: "Ontbrekende verplicht veld",
                errorEmail: "Geen correct e-mailadres",
                errorEmailExists: "Dit gebruikers e-mailadres bestaat al",
                errorKboLength: "Het KBO-nummer moet uit 8 tot 10 cijfers bestaan.",
                errorCompanyExists: "Dit ondernemingsnummer werd al gebruikt.",
                errorUserExists: "Deze gebruiker bestaat al in Amazon Congnito.",
                errorUserCompanyExists: "Gebruiker en bedrijf bestaan al en zijn reeds gelinkt",
                errorGeneric: "Er is een fout opgetreden tijdens het opslaan van de gegevens. Probeer het opnieuw.",
                errorUserNotAuth: "U hebt geen toestemming voor het uitvoeren van deze operatie",
                errorIban: "De IBAN is ongeldig.",
                welcome: "Welkom",
                addUser: "je kunt een nieuwe klant toevoegen",
                form: "met het onderstaande formulier.",
                continueDesc: "Het lijkt erop dat je reeds was begonnen met het toevoegen van een nieuwe klant maar niet hebt voltooid. We hebben de velden reeds ingevuld voor het geval je verder wilt gaan voor deze klant.",
                clearForm: "wis het formulier",
                start: "en begin vers.",
                contactDetails: "Contact details",
                unknownContact: "Onbekende contactgegevens. Voorlopig fungeer ik als contactpersoon.",
                companyDetails: "Bedrijfsgegevens",
                billingDetails: "Factureringsgegevens",
                next: "Volgende",
                back: "Terug",
                clear: "Wis",
                finalize: "Afronden",
                review: "Ter Controle",
                data: "gegevens",
                errors: "Deze fouten zijn opgetreden:",
                sendEmail: "Stuur bevestigingsmail naar",
                sendApprovalEmail: "Stuur een e-mail met goedkeuring naar",
                save: "Opslaan",
                addNew: "Voeg nieuw toe",
                status: "Status:",
                switch: "SDP toevoegen voor bedrijf",
                status1: "De gebruiker werd succesvol aangemaakt",
                status2: "De gebruiker bestaat al",
                status3: "Het bedrijf werd succesvol aangemaakt",
                status4: "Dit bedrijf bestaat al",
                status5: "De gebruiker en het bedrijf zijn met succes gekoppeld",
                status6: "De gebruiker en het bedrijf waren al gelinkt",
                status7: "De Cognito-gebruiker is succesvol gemaakt",
                status8: "De Cognito-gebruiker bestaat al",
                howTo1: "Hoe",
                howTo2: "maak je een nieuw",
                howTo3: "gebruiker aan?",
                howTo4: "Volg de stappen in het",
                howTo5: "formulier aan de linkerkant",
                howTo6: "en vul alle details in om een nieuwe gebruiker aan te maken met bedrijfs- en factuurinformatie.",
                howTo7: "Vul eerst de gegevens van de contactpersoon in.",
                howTo8: "Vul vervolgens de bedrijfsinformatie in.",
                howTo9: "Vul ten slotte de facturatiegegevens toe.",
                grantAccess1: "Dit bedrijf bestaat al in My Yuso en je bent momenteel geen geregistreerd gebruiker. Om je toegang te verlenen tot dit bedrijf sturen we eerst een email ter goedkeuring naar het bedrijf. Na bevestiging zal je een mail ontvangen om in te loggen voor dit bedrijf.",
                grantAccess2: "Dit bedrijf bestaat al in My Yuso. Om de gebruiker toegang te verlenen tot dit bedrijf sturen we eerst een e-mail ter goedkeuring naar het bedrijf. Na bevestiging ontvangt de gebruiker een e-mail om in te loggen voor dit bedrijf.",
                pressOK: "Klik op OK om door te gaan.",
                switch1: "Dit bedrijf bestaat al in My Yuso. Klik op OK om over te schakelen naar dit bedrijf.",
                emailSent: "De account is aangemaakt en er is met succes een goedkeuringse-mail verzonden naar",
                company: "het bedrijf",
                cancel: "Annuleren",
                close: "Sluiten"
            }
        }, this.props.lang);
    }

    componentDidUpdate() {
        window.reactStorage.setItem('newCustomer', JSON.stringify(this.state))
    }

    setLegalForms(res) {

        this.setState({
            legalForms: res
        })
    }

    handleCancelClick() {
        this.setState({ showApprovalModal: false }, this.resetForm);
    }

    handleCloseClick() {
        this.setState({ showInfoModal: false },this.resetForm);
    }

    companyExistance() {
        companyCheck(this.state.kbo_number, (err, res) => {
            if (err) {
                window.reportError.send(err);
            } else {
                const companyExists = res.length > 0 && res[0].company_exist === 1;
                const isAdmin = this.props.selectedCompany.role === 'admin'; 
                const isPartner = this.props.selectedCompany.role === 'partner';
                
                if (companyExists) {
                    if (isAdmin) {
                        this.setState({ showModal: true});
                    } else if (isPartner) {
                        this.contactExistance(); 
                    }
                    this.setState({ companyExists: true });   
                } else
                this.setState({ companyExists: false });   
            }
        });
    }

    contactExistance() {
        contactCheck(this.state.kbo_number, this.props.selectedCompany.contact_id, (err, res) => {
            if (err) {
                window.reportError.send(err);
            } else {
                const contactExists = res.length > 0 && res[0].contact_exist === 1;
                if (contactExists) {
                    this.setState({ showModal: true, contactExists: true});
                } else {
                    this.setState({ showApprovalModal: true,  contactExists: false }); 
                }
            }
        });
    }

    handleSwitch () {
        const companyId = this.state.kbo_number; 
        window.location.href = `/account/company/switch/${companyId}`;
    }

    onSubmit(e) {
        if (!this.props.selectedCompany) return;

        e.preventDefault();

        this.setState({ error: false, customerAdded: false });

        const form = e.target.elements;

        let error = [];
        for (let i = 0; i < form.length; i++) {
            if (form[i].required && !form[i].value.trim().length) {
                error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)
            }
            if (form[i].attributes.getNamedItem('email') && form[i].value.trim().length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form[i].value.trim())) {
                error.push(`${this.strings.errorEmail} ${form[i].name.split('_').join(' ')}`)
            }
            if (form[i].id === 'kbo_number' && ![8, 9, 10].includes(form[i].value.length)) {
                error.push(this.strings.errorKboLength)
            }
            if (form[i].id === 'iban' && form[i].value.trim().length && !IBAN.isValid(form[i].value.trim())) {
                error.push(this.strings.errorIban)
            }
        }

        if (error.length) {
            this.setState({ error })
            return;
        }

        this.setState({ addingCustomer: true })


        if(this.props.selectedCompany.role === "partner" && !this.state.contactExists && this.state.companyExists){
            sendApprovalEmail(this.state.kbo_number,
                {
                    lang: this.props.lang,
                    contact_first_name: this.state.contact_first_name,
                    contact_last_name: this.state.contact_last_name,
                    contact_email: this.state.contact_email,
                    companyName: this.state.company_legal_name,
                    loggedUser: this.props.selectedCompany.fullName

                },
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);
                    else {
                        this.setState({
                            status: res,
                            addingCustomer: false,
                            error: false
                        });
                    }
                })
        } 

        addNewCustomer(this.state,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                if (res.error) {

                    if (res.error.not_authorized)
                        error.push(this.strings.errorUserNotAuth)
                    else if (res.error.existing)
                        error.push(this.strings.errorUserCompanyExists)
                    else if (res.error.missing_kbo)
                        error.push(this.strings.errorRequired + ' kbo number')
                    else if (res.error.wrong_kbo)
                        error.push(this.strings.errorKboLength)
                    else
                        error.push(this.strings.errorGeneric)

                    this.setState({ error, addingCustomer: false })
                }
                else {
                    this.setState({
                        status: res,
                        customerAdded: true,
                        addingCustomer: false,
                        error: false,
                        showInfoModal: true,
                        showApprovalModal: false
                    });
                }
            })
    }

    onFieldChange(e) {

        if (e.target) {

            if (e.target.id === 'iban') {
                let target = e.target, position = target.selectionEnd, length = target.value.length;

                target.value = target.value.toString().replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').toUpperCase().trim();
                target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);

                this.setState({
                    invalidIban: target.value.trim() && !IBAN.isValid(target.value.trim())
                })
            }

            if (e.target.id === 'unknownContact') {
                this.setState({
                    contact_first_name: e.target.checked ? this.props.selectedCompany.firstName : '',
                    contact_last_name: e.target.checked ? this.props.selectedCompany.lastName : '',
                    contact_phone: e.target.checked ? this.props.selectedCompany.phone : '',
                    contact_email: e.target.checked ? this.props.selectedCompany.email : '',
                    billing_email: e.target.checked ? this.props.selectedCompany.email : ''
                })
            }

            this.setState({
                [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
            })

            if (e.target.id === 'contact_email') {
                this.setState({
                    billing_email: e.target.value
                });
            }

            // generate the company name for the legal name and legal form
            if (e.target.id === 'company_legal_name') {

                this.setState({
                    company_name: e.target.value + ' ' + this.state.company_legal_form_short
                })
            }

            if (e.target.id === 'company_legal_form_short') {

                this.setState({
                    company_name: this.state.company_legal_name + ' ' + e.target.value
                })
            }

            // if we select KBO number we have to populate the other company fields
            if (e.target.id === 'kbo_number') {

                let clean_value = e.target.value ? String(e.target.value).replace(/\s+|[^0-9]+|\b0+/g, '').trim() : '';

                const full_kbo_number = (clean_value.length === 9 ? '0' : '') + clean_value // including leading zero

                const vat_code = 'BE ' + full_kbo_number[0] + full_kbo_number.substring(1).split('').reduce((acc, cur, i, arr) => acc += cur + ((i + 1) % 3 === 0 && i < arr.length - 1 ? '.' : ''), '')

                this.setState({
                    [e.target.id]: clean_value,
                    company_vat: vat_code
                })

                if (e.company) {

                    let legal_form_short = this.state.legalForms && this.state.legalForms.find(x => x.legalFormLong.toLowerCase() === (e.company.legalForm || '').toLowerCase())

                    legal_form_short = (legal_form_short && legal_form_short.legalFormShort) || ''

                    this.setState({
                        company_name: (e.company.name || '') + ' ' + (legal_form_short || ''),
                        company_legal_name: e.company.name,
                        company_address_street: e.company.street,
                        company_address_number: e.company.streetNumber,
                        company_address_zip: e.company.postal,
                        company_address_city: e.company.city,
                        company_address_country: 'Belgium',
                        company_email: e.company.emailAddress,
                        company_website: e.company.webAddress,
                        company_phone: e.company.telephone,
                        company_type: e.company.type,
                        company_founded: e.company.startDate,
                        company_legal_form_short: legal_form_short,
                        display_company_legal_form: legal_form_short ? null : e.company.legalForm,
                        company_nace_code: e.company.NACECode,
                        billing_address_street: e.company.street,
                        billing_address_number: e.company.streetNumber,
                        billing_address_zip: e.company.postal,
                        billing_address_city: e.company.city,
                        billing_address_country: 'Belgium'
                    }, this.companyExistance)
                }
            }
        }
    }

    activateStage(stage) {
        this.setState({ stage, error: false })
    }

    resetForm() {

        this.newCustomer = {};

        let remove = {}
        for (let key in this.state)
            remove[key] = "";

        const resetKey = Date.now();
        this.setState({ ...remove, ...this.initialState, kbo_number: '', resetKey, legalForms: this.state.legalForms }, () => {
            window.reactStorage.removeItem('newCustomer');
        });
    }

    setResetKey(resetKey) {
        this.setState({ resetKey });
    }

    isEmpty() {
        for (let key in this.newCustomer)
            if (this.newCustomer[key] && this.newCustomer[key].toString().trim().length > 0 &&
                key !== 'stage' &&
                key !== 'error' &&
                key !== 'addingCustomer' &&
                key !== 'customerAdded' &&
                key !== 'send_email' &&
                key !== 'invalidIban' &&
                key !== 'status' &&
                key !== 'unknownContact' &&
                key !== 'legalForms' &&
                key !== 'display_company_legal_form' &&
                key !== 'resetKey' &&
                key !== 'lang'
            )
                return false;

        return true;
    }

    addingFinished() {
        if (!this.state.status) return null;
    
        let info = []
    
        if (this.state.status.companyCheck)
            info.push({ text: this.strings.status4, style: { color: 'red'} });
        else
            info.push({ text: this.strings.status3, style: {} });
    
        if (!this.state.status.check.contact_exist)
            info.push({ text: this.strings.status1, style: {} });
        else
            info.push({ text: this.strings.status2, style: { color: 'red' } }); 
    
        if (!this.state.status.check.user_linked)
            info.push({ text: this.strings.status5, style: {} });
        else
            info.push({ text: this.strings.status6, style: { color: 'red' } }); 
    
        if (!this.state.status.check.cognito_exist)
            info.push({ text: this.strings.status7, style: {} });
        else
            info.push({ text: this.strings.status8, style: { color: 'red' } }); 
    
        return (
            <ul>
                {
                    info.map((item, index) => (
                        <li key={index} style={item.style}>
                            {item.text}
                        </li>
                    ))
                }
            </ul>
        )
    }

    render() {
        const {contact_email, send_email, company_email, showModal, showApprovalModal, showInfoModal } = this.state;
        

        return (
            <div>
                <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
                <div className="row">
                    <div className="col-lg-7 px-2 px-xl-5 py-3">
                        <div className="title text-primary mb-5">
                            {this.strings.addUser},<br />
                            {this.strings.form}
                        </div>
                        <form onSubmit={this.onSubmit} noValidate>
                            <div className={!this.state.stage ? 'd-none' : ''}>
                                <p className={'alert alert-info mb-5 ' + (this.isEmpty() ? 'd-none' : '')}>
                                    <i className="fas fa-info-circle mr-2"></i> {this.strings.continueDesc} <span className="text-primary cursor-pointer text-underline" onClick={this.resetForm}>{this.strings.clearForm}</span> {this.strings.start}
                                </p>

                                <FormPanel type="contact" icon="fa-user" title={this.strings.contactDetails} stage={this.state.stage} activateStage={this.activateStage}>
                                    <span><input type="checkbox" name="unknownContact" id="unknownContact" className="mr-2" onChange={this.onFieldChange} checked={this.state.unknownContact} /><label htmlFor="unknownContact">{this.strings.unknownContact}</label></span>

                                    <Contact value={this.state} onFieldChange={this.onFieldChange} selectedCompany={this.props.selectedCompany} lang={this.props.lang} disabled={this.state.unknownContact} />

                                    <div className="d-flex mb-4">
                                        <button type="button" className="btn btn-primary ml-auto" onClick={() => { this.activateStage('company') }}>{this.strings.next} <i className="fas fa-angle-right ml-2"></i></button>
                                    </div>
                                </FormPanel>

                                <FormPanel type="company" icon="fa-building" title={this.strings.companyDetails} stage={this.state.stage} activateStage={this.activateStage}>
                                    <Company value={this.state} onFieldChange={this.onFieldChange} lang={this.props.lang} resetForm={this.resetForm}
                                        resetKey={this.state.resetKey} // pass resetKey as a prop
                                        setResetKey={this.setResetKey} // pass function to update resetKey
                                        setLegalForms={this.setLegalForms}
                                    />
                                    <div className="d-flex mb-4">
                                        <button type="button" className="btn btn-secondary" onClick={() => { this.activateStage('contact') }}><i className="fas fa-angle-left mr-2"></i> {this.strings.back}</button>
                                        <button type="button" className="btn btn-primary ml-auto" onClick={() => { this.activateStage('billing'); this.companyExistance()}}>{this.strings.next} <i className="fas fa-angle-right ml-2"></i></button>
                                    </div>
                                </FormPanel>

                                <FormPanel type="billing" icon="fa-dollar-sign" title={this.strings.billingDetails} stage={this.state.stage} activateStage={this.activateStage}>
                                    <Billing
                                        value={this.state}
                                        onFieldChange={this.onFieldChange}
                                        lang={this.props.lang}
                                        invalidIban={this.state.invalidIban}
                                        hideDDMandate={true}
                                        selectedCompany={this.props.selectedCompany}
                                    />

                                    <div className="d-flex mb-4">
                                        <button type="button" className="btn btn-secondary" onClick={() => { this.activateStage('company') }}><i className="fas fa-angle-left mr-2"></i> {this.strings.back}</button>
                                        <button type="button" className="btn btn-primary ml-auto" onClick={() => { this.activateStage(undefined); this.companyExistance() }}>{this.strings.next} <i className="fas fa-angle-right ml-2"></i></button>
                                    </div>
                                </FormPanel>
                                <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    {this.strings.switch1} 
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger" onClick={this.handleSwitch}>OK</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`modal ${showApprovalModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showApprovalModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                     {this.state.unknownContact ? this.strings.grantAccess1 : this.strings.grantAccess2 }
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="submit" className="btn btn-danger">OK</button>
                                                    <button type="button" className="btn btn-danger" onClick={this.handleCancelClick}>{this.strings.cancel}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`modal ${showInfoModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showInfoModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    {this.strings.emailSent} {company_email ? company_email : 'the company'}.
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger" onClick={this.handleCloseClick}>{this.strings.close}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                <div className="mt-4">
                                    <div className="d-flex mb-4">
                                        <button type="button" className="btn btn-secondary" onClick={this.resetForm}><i className="fas fa-times mr-2"></i> {this.strings.clear}</button>
                                        <button type="button" className="btn btn-primary ml-auto" onClick={() => { this.activateStage(undefined); this.companyExistance() }}><i className="fas fa-check mr-2"></i> {this.strings.finalize}</button>
                                    </div>
                                </div>
                            </div>

                            <div className={'mt-5 ' + (this.state.stage ? 'd-none' : '')}>
                                <h4 className="font-weight-light mb-4"><strong>{this.strings.review}</strong> {this.strings.data}</h4>
                                <table className="table table-striped table-sm">
                                    <tbody>
                                        {
                                            Object.keys(this.state).sort().map(key => {
                                                return key !== 'stage' &&
                                                    key !== 'error' &&
                                                    key !== 'addingCustomer' &&
                                                    key !== 'customerAdded' &&
                                                    key !== 'companyExists' &&
                                                    key !== 'contactExists' &&
                                                    key !== 'showModal' &&
                                                    key !== 'showApprovalModal' &&
                                                    key !== 'showInfoModal' &&
                                                    key !== 'send_email' &&
                                                    key !== 'companyCheck' &&
                                                    key !== 'contactCheck' &&
                                                    key !== 'invalidIban' &&
                                                    key !== 'status' &&
                                                    key !== 'unknownContact' &&
                                                    key !== 'legalForms' &&
                                                    key !== 'display_company_legal_form' &&
                                                    key !== 'resetKey' &&
                                                    key !== 'lang' ? (
                                                    <tr key={key}>
                                                        <td className="text-capitalize" style={{ width: '200px' }}>{key.split('_').join(' ')}</td>
                                                        <th>{this.state[key]}</th>
                                                    </tr>
                                                ) : null
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className={'alert alert-warning mb-3 ' + (this.state.error ? 'd-block' : 'd-none')}>
                                    <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.errors}
                                    <ul>
                                        {
                                            this.state.error ? this.state.error.sort().map(error => (<li key={error}>{error}</li>)) : null
                                        }
                                    </ul>
                                </div>
                                <div>
                                    <div className={'form-check mb-5 ' + (contact_email ? 'd-block' : 'd-none')}>
                                        <input className="form-check-input" type="checkbox" name="send_email" checked={send_email} id="send_email" onChange={this.onFieldChange} />
                                        <label className="form-check-label" htmlFor="send_email">
                                            {this.strings.sendEmail} <strong>{contact_email}</strong>?
                                        </label>
                                    </div>
                                </div>
                                {
                                    !this.state.customerAdded ? null :
                                        <div className={'alert alert-info mb-3 d-block'}>
                                            <h4>{this.strings.status}</h4>
                                            {this.addingFinished()}
                                            <Link className="btn btn-primary w-100 p-3 font-weight-bold text-truncate" to={'/account/company/switch/' + this.state.status.company_id + '?go=/services/onboarding'}>
                                                {this.strings.switch} {this.state.status ? this.state.company_name : 'N/A'}
                                            </Link>
                                        </div>
                                }

                                <div className="d-flex mb-4">
                                    <button type="button" className={'btn btn-secondary ' + (this.state.customerAdded ? 'd-none' : '')} onClick={() => { this.activateStage('billing') }}><i className="fas fa-angle-left mr-2"></i> {this.strings.back}</button>

                                    <button type="submit" className={'btn btn-primary ml-auto ' + (this.state.customerAdded ? 'd-none' : '')} disabled={this.state.addingCustomer ? 'disabled' : undefined}><i className="fas fa-save mr-2"></i> {this.strings.save}</button>

                                    <button type="button" onClick={this.resetForm} className={'btn btn-primary ml-auto ' + (!this.state.customerAdded ? 'd-none' : '')}><i className="fas fa-user-plus mr-2"></i> {this.strings.addNew}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-5 px-2 px-xl-5 py-3">
                        <CallToAction className="my-4 ml-4">
                            <CtaTitle>{this.strings.howTo1} <strong>{this.strings.howTo2}</strong> {this.strings.howTo3}</CtaTitle>
                            <CtaContent>
                                <p>
                                    {this.strings.howTo4} <strong>{this.strings.howTo5}</strong> {this.strings.howTo6}
                                </p>
                                <ol>
                                    <li>{this.strings.howTo7}</li>
                                    <li>{this.strings.howTo8}</li>
                                    <li>{this.strings.howTo9}</li>
                                </ol>
                            </CtaContent>
                        </CallToAction>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewCustomer;
