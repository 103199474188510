import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LocalizedStrings from './Localization';

class CallToAction extends Component {
  render() {
    return (
      <div className={'cta-box d-flex flex-column bg-warning p-4 ' + this.props.className || ''}>
        {this.props.children}
      </div>
    );
  }
}

class CtaTitle extends Component {
  render() {
    return (
      <h4 className="text-white mb-3">
        {this.props.children} <img className={this.props.warning ? '' : 'd-none'} style={{ width: '25px' }} src={`${process.env.PUBLIC_URL}/warning-white.png`} alt="Warning" />
      </h4>
    );
  }
}

class CtaContent extends Component {
  render() {
    return (
      <div className={'mb-3 h-100 ' + (this.props.className || '')}>
        {this.props.children}
      </div>
    );
  }
}

class CtaButton extends Component {
  render() {
    if (this.props.mailto) {
      return (
        <a href={`mailto:${this.props.mailto}`} className={'btn bg-white text-primary px-4 py-3 ' + this.props.className || ''}>
          {this.props.children}
        </a>
      )
    }

    return (
      <Link to={(this.props.link || '/services')} className={'btn bg-white text-primary px-4 py-3 ' + this.props.className || ''}>
        {this.props.children}
      </Link>
    );
  }
}

class CtaPanel extends Component {
  constructor(props) {
    super(props);

    this.getCta = this.getCta.bind(this);

    this.strings = LocalizedStrings({
      en: {
        injection: "Injection",
        supply: "Supply",
        production: "Production",
        flexibility: "Flexibility"
      },
      nl: {
        injection: "Injectie",
        supply: "Levering",
        production: "Productie",
        flexibility: "Flexibiliteit"
      }
    }, this.props.lang)

    this.cta_strings = LocalizedStrings({
      en: {

        add_supply: {
          title: "We only need <strong>a few details</strong> from you",
          content: "To <strong>finalise your delivery agreement</strong>, we only need a few details from you. This concerns the technical data of your installation, but we also need some payment details. <strong>Click further to complete this information.</strong>",
          button: "<strong>Receive</strong> a supply contract proposal today"
        },

        add_injection: {
          title: "<strong>Inject</strong> your green energy into the Yuso network",
          content: "<ul><li><strong>Get the highest possible price</strong>, easily 20% higher, for the energy that you produce.</li><li>Use our <strong>self-billing system</strong>, and receive your money within a month.</li><li>We provide <strong>expert advice</strong> on the performance of your installation.</li></ul>",
          button: "<strong>Receive</strong> an injection contract proposal today"
        },

        contrast_proposal: {
          title: "<strong>Request</strong> a contract proposal",
          content: "We only need a few <strong>details</strong> for your SPD in order to <strong>prepare your agreement</strong>.",
          button: "<strong>Receive</strong> your contract proposal today"
        },

        request_simulation: {
          title: "Request a <strong>simulation</strong>",
          content: "<ol><li>Complete the missing information on the mandate and send a <strong>signed version</strong> to <a href=\"mailto:sales@yuso.be\" class=\"text-primary\" target=\"_blank\" rel=\"noopener noreferrer\">sales@yuso.be</a>.</li><li>Yuso will request the meterdata with the grid company. It can take a few days before we receive this.</li><li>Once we receive the data Yuso will come back via email to go through the simulation.</li></ol>",
          button: "Give <strong>mandate for meterdata</strong>"
        },

        request_flexibility: {
          title: "Do you want to <strong>use your</strong> green energy <strong>as optimally as possible</strong>?",
          content: "Yuso provides the <strong>best flexibility solutions</strong> for your company. Discover the benefits of our approach!",
          button: "<strong>Request</strong> your flexibility analysis"
        },

        request_supply: {
          title: "Request Supply Analysis",
          content: "Request Supply Analysis",
          button: "Request Supply Analysis"
        },

        request_injection: {
          title: "Request Injection Analysis",
          content: "Request Injection Analysis",
          button: "Request Injection Analysis"
        },

        sign_contract: {
          title: "<strong>Agreements</strong> are waiting for you <strong>to be signed</strong>",
          content: "Please <strong>sign</strong> you latest agreement in order to enjoy fully the Yuso experience.",
          button: "<strong>Sign</strong> your latest contracts"
        },

        add_sdp: {
          title: "<strong>Onboard</strong> your first SDP",
          content: "We only need a few <strong>technical details</strong> for your installation like the <strong>EAN</strong> number, installation address, DGO, capacity, and volume in order to <strong>prepare your agreement</strong>.",
          button: "<strong>Make an offer</strong> for your connection point"
        },

        add_service: {
          title: "<strong>Have</strong> another SDP?",
          content: "Add <strong>another service delivery point</strong> for your company. Keep on hand the <strong>EAN</strong> number, installation address, DGO, capacity, and volume.",
          button: "<strong>Add</strong> another SDP"
        },

        add_billing: {
          title: "We only need <strong>a few details</strong> from you",
          content: "To <strong>finalise your agreement</strong>, we first need the technical data of your installation. Don’t forget afterwards to complete some payment details in your account settings. <strong>Click further to complete this information</strong>.",
          button: "Complete your <strong>billing info</strong>"
        },

        sign_aggreements: {
          title: "Your <strong>contract</strong> is ready",
          content: "We got all the information we need about your installation and your <strong>contract is ready to be signed</strong>.",
          button: "<strong>Sign</strong> your contract now"
        },

        demo_simulation: {
          title: "Demo <strong>price simulation</strong>",
          content: "This simulation using <strong>demo data</strong> gives you insight into the prices that are applicable for a typical customer with a PV installation.",
          button: "See <strong>demo price</strong> simulation"
        },

        try_demo: {
          title: "Try our <strong>fully functional</strong> demo account",
          content: "See how the <strong>My Yuso portal</strong> looks like before activating your installation. With our fully featured demo account you can <strong>preview all aspects of the pratform</strong>.",
          button: "<strong>Try out</strong> the demo account"
        }
      },
      nl: {
        add_supply: {
          title: "We hebben slechts <strong>een paar details</strong> van u nodig",
          content: "Om jouw leveringsovereenkomst <strong>te voltooien</strong>, hebben we slechts enkele gegevens van u nodig. Dit betreft de technische gegevens van jouw installatie, maar we hebben ook een aantal betalingsgegevens nodig. <strong>Klik verder om deze informatie te voltooien.</strong>",
          button: "<strong>Ontvang</strong> een contractvoorstel voor levering"
        },

        add_injection: {
          title: "<strong>Injecteer jouw groene energie in het Yuso-netwerk</strong>",
          content: "<ul><li><strong>Krijg de hoogst mogelijke prijs</strong>, gemakkelijk 20% hoger voor de energie die u produceert.</li><li>Gebruik ons ​​<strong>systeem voor automatische facturering </strong>, en ontvang jouw geld binnen een maand.</li><li>We bieden <strong>deskundig advies</strong> over de prestaties van jouw installatie.</li></ul>",
          button: "<strong>Ontvang</strong> een contractvoorstel voor injectie"
        },

        contrast_proposal: {
          title: "<strong>Verzoek</strong> een contractvoorstel",
          content: "We hebben er maar een paar nodig <strong>details</strong> voor uw SPD om <strong>uw overeenkomst voor te bereiden</strong>.",
          button: "<strong>Ontvang</strong> uw contractvoorstel"
        },

        request_simulation: {
          title: "Vraag een <strong>simulatie</strong> aan",
          content: "<ol><li>Vul de ontbrekende informatie in het mandaat in en stuur een <strong>ondertekende versie</strong> naar <a href=\"mailto:sales@yuso.be\" class=\"text-primary\" target=\"_blank\" rel=\"noopener noreferrer\">sales@yuso.be</a>.</li><li>Yuso zal de meterdata opvragen bij het netwerkbeheerder. Het kan enkele dagen duren voordat we dit ontvangen.</li><li>Zodra we de gegevens hebben ontvangen, komt Yuso via e-mail terug om de simulatie te doorlopen.</li></ol>",
          button: "Geef <strong>toegang tot meetwaarden</strong>"
        },

        request_flexibility: {
          title: "Wil je <strong>je</strong> groene energie <strong>zo optimaal mogelijk gebruiken</strong>?",
          content: "Yuso biedt de <strong>beste flexibiliteitsoplossingen</strong> voor uw bedrijf. Ontdek de voordelen van onze aanpak!",
          button: "<strong>Vraag</strong> uw flexibiliteitsanalyses aan"
        },

        request_supply: {
          title: "Vraag aanbodanalyse",
          content: "Vraag aanbodanalyse",
          button: "Vraag aanbodanalyse"
        },

        request_injection: {
          title: "Verzoek om injectie-analyse",
          content: "Verzoek om injectie-analyse",
          button: "Verzoek om injectie-analyse"
        },

        sign_contract: {
          title: "<strong>Overeenkomsten</strong> wachten op u <strong>wordt ondertekend</strong>",
          content: "<strong>teken</strong> je laatste overeenkomst om volledig te kunnen genieten van de Yuso-ervaring.",
          button: "<strong>Onderteken</strong> jouw laatste contracten"
        },

        add_sdp: {
          title: "<strong>Aan boord</strong> jouw eerste aansluitingspunt",
          content: "We hebben slechts een paar <strong>technische details</strong> voor jouw installatie nodig, zoals het <strong>EAN</strong> nummer, installatieadres, DGO, capaciteit en volume om <strong>jouw overeenkomst voor te bereiden</strong>.",
          button: "<strong>Maak een offerte</strong> voor jouw aansluitingspunt"
        },

        add_service: {
          title: "<strong>Heeft</strong> u nog een ander aansluitingspunt?",
          content: "Voeg <strong>een ander aansluitingspunt</strong> toe voor uw bedrijf. Houd het <strong>EAN</strong> nummer, installatieadres, DGO, capaciteit en geschatte volume bij de hand.",
          button: "Aansluitingspunt <strong>toevoegen</strong>"
        },

        add_billing: {
          title: "We hebben slechts <strong>een paar details</strong> van u nodig",
          content: "Om <strong>jouw overeenkomst te finaliseren</strong>, hebben we eerst de technische gegevens van jouw installatie nodig. Vergeet daarna niet om een ​​aantal betalingsgegevens in jouw accountinstellingen in te vullen. <strong>Klik verder om deze informatie te voltooien</strong>.",
          button: "Vul jouw <strong>factuurgegevens in</strong>"
        },

        sign_aggreements: {
          title: "Je <strong>contract</strong> is klaar",
          content: "We hebben alle informatie die we nodig hebben over jouw installatie en uw <strong>contract is klaar om te worden ondertekend</strong>.",
          button: "<strong>Bekijk</strong> uw contractvoorstel"
        },

        demo_simulation: {
          title: "Demo <strong>prijssimulatie</strong>",
          content: "Deze simulatie met behulp van <strong>demogegevens</strong> geeft u inzicht in de prijzen die van toepassing zijn op een typische klant met een PV-installatie.",
          button: "Zie simulatie <strong>demoprijs</strong>",
        },

        try_demo: {
          title: "Probeer <strong>volledig functionele</strong> demo",
          content: "Bekijk hoe het <strong>My Yuso-portaal</strong> eruit ziet voordat u jouw installatie activeert. Met ons volledig uitgeruste demo-account kunt u <strong>een voorbeeld van alle aspecten van het platform bekijken</strong>.",
          button: "<strong>Probeer</strong> de demo-account"
        }
      }
    }, this.props.lang)
  }

  getCta() {
    // demo
    if (this.props.demo) {

      return {
        url: '/account/company/switch/' + this.props.demo.company.kbo,
        ...this.cta_strings['try_demo']
      }
    }
    // other companies
    else if (this.props.info) {

      if (this.props.info.sdps && !this.props.info.sdps.has_sdps) {
        return {
          url: '/services/onboarding#sdp',
          ...this.cta_strings['add_sdp']
        }
      }
      else if (this.props.info.pending_contracts && this.props.info.pending_contracts.missing_contracts) {
        return {
          url: '/services/onboarding#contract',
          ...this.cta_strings['contrast_proposal']
        }
      }
      else if (this.props.info.pending_contracts && this.props.info.pending_contracts.has_contracts) {
        return {
          url: '/services/onboarding#contractSigning',
          ...this.cta_strings['sign_aggreements']
        }
      }
      else if (this.props.info.sdps && this.props.info.sdps.offtake_hourly.length && !this.props.info.sdps.injection_hourly.length) {
        return {
          url: '/services/onboarding#sdp',
          ...this.cta_strings['add_injection']
        }
      }
      else if (this.props.info.sdps && !this.props.info.sdps.offtake_hourly.length && this.props.info.sdps.injection_hourly.length) {
        return {
          url: '/services/onboarding#sdp',
          ...this.cta_strings['add_supply']
        }
      }
      else if (this.props.info.sdps && this.props.info.sdps.offtake_hourly.length && this.props.info.sdps.injection_hourly.length && !this.props.info.flexibility.length) {
        return {
          url: '/services#flexibility',
          ...this.cta_strings['request_flexibility']
        }
      }

      // default
      return {
        url: '/services/onboarding',
        ...this.cta_strings['add_service']
      }
    }
  }

  render() {

    let cta = this.getCta()

    if (this.props.company || this.props.demo) {
      return (
        <div className="cta-box bg-warning d-flex flex-column h-100">
          <h4 className={'text-white px-4 py-3 text-center m-0 ' + (this.props.demo ? 'bg-primary' : 'text-body font-weight-bold')}>
            {this.props.demo ? this.props.demo.company.name : this.props.company.companyName}
          </h4>

          {
            this.props.company ?
              <div className="border-bottom border-dark mx-4 h-0"></div>
              : null
          }

          <div className="p-4 d-flex flex-column flex-grow-1">
            <div className="d-flex justify-content-between flex-row">

              <div className="mb-2 text-center bg-white p-2" style={{ width: '23%' }}>
                <span className="text-body font-weight-light mb-2">
                  {this.strings.injection}
                </span>
                <h5 className="text-body m-0 p-1">
                  {this.props.info && this.props.info.sdps && this.props.info.sdps.injection_hourly.length}
                </h5>
              </div>

              <div className="mb-2 text-center bg-white p-2" style={{ width: '23%' }}>
                <span className="text-body font-weight-light mb-2">
                  {this.strings.supply}
                </span>
                <h5 className="text-body m-0 p-1">
                  {this.props.info && this.props.info.sdps && this.props.info.sdps.offtake_hourly.length}
                </h5>
              </div>

              <div className="mb-2 text-center bg-white p-2" style={{ width: '23%' }}>
                <span className="text-body font-weight-light mb-2">
                  {this.strings.production}
                </span>
                <h5 className="text-body m-0 p-1">
                  {this.props.info && this.props.info.sdps && this.props.info.sdps.production.length}
                </h5>
              </div>

              <div className="mb-2 text-center bg-white p-2" style={{ width: '23%' }}>
                <span className="text-body font-weight-light mb-2">
                  {this.strings.flexibility}
                </span>
                <h5 className="text-body m-0 p-1">
                  {this.props.info && this.props.info.flexibility && this.props.info.flexibility.length}
                </h5>
              </div>

            </div>
            {
              cta.content ?
                <CtaContent className="flex-grow-1">
                  <span dangerouslySetInnerHTML={{ __html: cta.content }} />
                </CtaContent> : null
            }
            {
              cta.button ?
                <CtaButton link={cta.url} mailto={this.props.mailto} className="w-100">
                  <span dangerouslySetInnerHTML={{ __html: cta.button }} />
                </CtaButton> : null
            }
          </div>
        </div>
      )
    }

    let strings = this.cta_strings[this.props.type]

    return (
      <CallToAction className={this.props.className}>
        {
          strings.title ?
            <CtaTitle warning={this.props.warning}>
              <span dangerouslySetInnerHTML={{ __html: strings.title }} />
            </CtaTitle> : null
        }
        {
          strings.content ?
            <CtaContent>
              <span dangerouslySetInnerHTML={{ __html: strings.content }} />
            </CtaContent> : null
        }
        {
          strings.button ?
            <CtaButton link={this.props.link} mailto={this.props.mailto} className={this.props.buttonClassName}>
              <span dangerouslySetInnerHTML={{ __html: strings.button }} />
            </CtaButton> : null
        }
      </CallToAction>
    );
  }
}


export { CallToAction, CtaTitle, CtaContent, CtaButton, CtaPanel }
