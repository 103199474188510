import React, { Component } from 'react';
import IBAN from 'iban';


import Loader from '../../components/Loader';
import Billing from '../../components/forms/Billing';
import LocalizedStrings from '../../components/Localization';
import { groupList } from '../../api/netting';
import { getBilling, updateBilling } from '../../api/billing';

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billing: undefined,
      invalidIban: false,
      groupName: undefined
    }

    this.updateBillingInfo = this.updateBillingInfo.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getNettingInfo = this.getNettingInfo.bind(this)

    this.strings = LocalizedStrings({
      en: {
        error: "Error while updating billing details.",
        errorRequired: "All marked fields are required.",
        errorEmail: "Email is not properly formatted.",
        errorIban: "The IBAN is invalid.",
        fetching: "Fetching billing information...",
        billingUpdated: "Billing was successfully updated!",
        billing: "Billing information",
        company: "Company",
        email: "Billing E-mail",
        iban: "IBAN",
        directDebit: "Direct Debit",
        street: "Billing Address Street",
        number: "Billing Address Nº",
        zip: "Billing Address Zip",
        city: "Billing Address City",
        country: "Billing Address Country",
        updated: "Last updated",
        contact: "Wrong billing details? Contact us at",
        save: "Save",
        edit: "Edit",
        cancel: "Cancel",
        nettingAgreement: "Netting Agreement"
      },
      nl: {
        error: "Fout tijdens het bijwerken van factuurgegevens.",
        errorRequired: "Alle gemarkeerde velden zijn verplicht.",
        errorEmail: "E-mail is niet correct geformatteerd.",
        errorIban: "De IBAN is ongeldig.",
        fetching: "Facturatiegegevens ophalen...",
        billingUpdated: "Facturering is succesvol bijgewerkt!",
        billing: "Facturatie gegevens",
        company: "Bedrijf",
        email: "Facturatie e-mail",
        iban: "IBAN",
        directDebit: "Direct Debit",
        street: "Factuuradres Straat",
        number: "Factuuradres Nº",
        zip: "Factuuradres Zip",
        city: "Factuuradres Stad",
        country: "Factuuradres Land",
        updated: "Laatst bijgewerkt",
        contact: "Verkeerde factuurgegevens? Contacteer ons op",
        save: "Opslaan",
        edit: "Bewerk",
        cancel: "Annuleer",
        nettingAgreement: "Netting Agreement"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.updateBillingInfo();
  }
  getNettingInfo() {
    let groupName, beneficiaryCompanyName
    groupList(null,
      (err, res) => {
        if (err) {
          return window.reportError.send(err)
        } else {
          groupName = res.groupList.map(x => {
            return {
              id: x.id,

              companies: x.companies ? x.companies.split('-;-') : []
            }
          }).map(x => x.companies.includes(this.props.selectedCompany.companyName) ? x.name : null).find(x => x !== null)
          beneficiaryCompanyName = res.groupList.find(x => x.benificiaryCompany === this.props.selectedCompany.companyName) ? res.groupList.find(x => x.benificiaryCompany === this.props.selectedCompany.companyName).name : 'N/A'
  
          if (groupName) {
            this.setState({
              groupName: groupName
            })
          } else {
            this.setState({
              groupName: beneficiaryCompanyName
            })
          }
        }
      }
    )
  }
  
  updateBillingInfo() {
    if (!this.props.selectedCompany) return;
    this.getNettingInfo()
    getBilling(this.props.selectedCompany.company_id, (err, res) => {
      if (err)
        return window.reportError.send(err);


      this.setState({
        billing: res,
        iban: res.iban,
        dd_mandate: res.dd_mandate,
        active_dd_mandate: res.active_dd_mandate,
        billing_email: res.billing_email,
        billing_address_street: res.billing_address_street,
        billing_address_number: res.billing_address_number,
        billing_address_zip: res.billing_address_zip,
        billing_address_city: res.billing_address_city,
        billing_address_country: res.billing_address_country
      })
    })
  }

  onFieldChange(e) {
    if (e.target) {
      if (e.target.id === 'iban') {
        let target = e.target, position = target.selectionEnd, length = target.value.length;

        target.value = target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').toUpperCase().trim();
        target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);

        this.setState({
          invalidIban: target.value.trim() && !IBAN.isValid(target.value.trim())
        })
      }

      this.setState({
        [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      })

    }
  }

  onSubmit(e) {
    if (!this.props.selectedCompany) return;

    e.preventDefault();

    this.setState({ error: false });

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if (form[i].required && !form[i].value.trim().length) {
        this.setState({ error: this.strings.errorRequired })
        return;
      }
      if (form[i].attributes.getNamedItem('email') && form[i].value.trim().length && !form[i].value.trim().split(',').reduce((result, email) => result && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()), true)) {
        this.setState({ error: this.strings.errorEmail })
        return;
      }
      if (form[i].id === 'iban' && form[i].value.trim().length && !IBAN.isValid(form[i].value.trim())) {
        this.setState({ error: this.strings.errorIban })
        return;
      }
    }

    updateBilling(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.billingUpdated }, () => {
            this.updateBillingInfo();
            this.props.updateCompanies(this.props.selectedCompany.company_id, true);

            if (this.props.toggleEdit)
              this.props.toggleEdit()

            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {
    if (!(this.props.selectedCompany && this.state.billing && this.state.groupName)) {
      return (
        <Loader className="mb-5" inline="true">{this.strings.fetching}</Loader>
      );
    }

    return (
      <div className={this.props.adminSave ? '' : 'mb-5'}>
        {
          (!this.props.adminSave && this.state.billing.iban) || (this.props.adminSave && !this.props.edit) ?
            (
              <div>
                <table className="table table-striped">
                  <thead className={this.props.adminSave ? 'd-none' : ''}>
                    <tr>
                      <th colSpan="2">{this.strings.billing}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '37.5%' }}>{this.strings.company}</td>
                      <td><strong>{this.props.selectedCompany ? this.props.selectedCompany.companyName : 'N/A'}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.email}</td>
                      <td>
                        <strong>
                          {
                            this.state.billing.billing_email ? this.state.billing.billing_email.replace(/\s+/g, '').split(',').map(email => {
                              return (
                                <span key={email}>{email}<br /></span>
                              )
                            }) : null
                          }
                        </strong>
                      </td>
                    </tr>
                    {
                      this.props.selectedCompany.role === 'admin' ?
                        <tr>
                          <td>{this.strings.iban}</td>
                          <td><strong>{this.state.billing.iban}</strong></td>
                        </tr>
                        : null
                    }
                    {
                      this.state.billing.active_dd_mandate || this.props.selectedCompany.role === 'admin' ?
                        <tr>
                          <td>{this.strings.directDebit}</td>
                          <td><strong>{this.state.billing.active_dd_mandate ? this.state.billing.dd_mandate : 'N/A'}</strong></td>
                        </tr>
                        : null
                    }
                    <tr>
                      <td>{this.strings.street}</td>
                      <td><strong>{this.state.billing.billing_address_street}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.number}</td>
                      <td><strong>{this.state.billing.billing_address_number}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.zip}</td>
                      <td><strong>{this.state.billing.billing_address_zip}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.city}</td>
                      <td><strong>{this.state.billing.billing_address_city}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.country}</td>
                      <td><strong>{this.state.billing.billing_address_country}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.updated}</td>
                      <td><strong>{this.state.billing.updated_at}</strong></td>
                    </tr>
                    <tr>
                      <td>{this.strings.nettingAgreement}</td>
                      <td><strong>{this.state.groupName ? this.state.groupName : 'N/A'}</strong></td>
                    </tr>
                    <tr className={this.props.adminSave ? 'd-none' : ''}>
                      <td colSpan="2" className="bg-white text-muted pt-3"><small>{this.strings.contact} <a href="mailto:info@yuso.be">info@yuso.be</a></small></td>
                    </tr>
                  </tbody>
                </table>

                {
                  this.props.toggleEdit ?
                    <button type="button" id="edit" className="btn btn-primary float-right" onClick={this.props.toggleEdit}><i className="fas fa-edit text-white mr-2"></i>{this.strings.edit}</button>
                    : null
                }
              </div>
            ) : (
              <form onSubmit={this.onSubmit} className="mb-5" noValidate>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    {this.strings.company}
                  </label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" readOnly value={this.props.selectedCompany ? this.props.selectedCompany.companyName : 'N/A'} />
                  </div>
                </div>

                <Billing
                  onFieldChange={this.onFieldChange}
                  value={this.state}
                  lang={this.props.lang}
                  invalidIban={this.state.invalidIban}
                  selectedCompany={this.props.selectedCompany}
                  hideIBAN={this.props.selectedCompany.role !== 'admin'}
                  hideDDMandate={this.props.hideDDMandate || this.props.selectedCompany.role !== 'admin'}
                />

                <p className={'alert alert-warning ' + (this.state.error ? 'd-block' : 'd-none')}>
                  <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                </p>

                <div className="d-flex mb-2">
                  <button id="cancel" className="btn btn-secondary ml-auto mr-2" onClick={this.props.toggleEdit}>{this.strings.cancel}</button>
                  <button type="submit" className="btn btn-primary"><i className="fas fa-save mr-2"></i> {this.strings.save}</button>
                </div>
              </form>
            )
        }
        <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
          <i className="fas fa-info-circle mr-2"></i> {this.state.info}
        </p>
      </div>
    );
  }
}

export default BillingDetails;
