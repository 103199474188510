import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import MomentLocaleUtils from 'react-day-picker/moment';

import moment from 'moment-timezone'

import 'moment/locale/nl-be';

import EanPicker from './EanPicker'
import LocalizedStrings from '../../components/Localization';

import config from '../../api/config';

moment.tz.setDefault('Europe/Brussels')

const periods = {
    'tomorrow': {
        from: moment().add(1, 'days').startOf('day').toDate(),
        to: moment().add(1, 'days').endOf('day').toDate()
    },
    'today': {
        from: moment().startOf('day').toDate(),
        to: moment().endOf('day').toDate()
    },
    'yesterday': {
        from: moment().subtract(1, 'days').startOf('day').toDate(),
        to: moment().subtract(1, 'days').endOf('day').toDate()
    },
    'div1': {
        divider: true
    },
    'thisWeek': {
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate()
    },
    'previousWeek': {
        from: moment().subtract(1, 'weeks').startOf('week').toDate(),
        to: moment().subtract(1, 'weeks').endOf('week').toDate()
    },
    'thisMonth': {
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
    },
    'previousMonth': {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().subtract(1, 'month').endOf('month').toDate()
    },
    'thisQuarter': {
        from: moment().quarter(moment().quarter()).startOf('quarter').toDate(),
        to: moment().quarter(moment().quarter()).endOf('quarter').toDate()
    },
    'previousQuarter': {
        from: moment().quarter(moment().quarter()).subtract(1, 'quarters').startOf('quarter').toDate(),
        to: moment().quarter(moment().quarter()).subtract(1, 'quarters').endOf('quarter').toDate()
    },
    'thisYear': {
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate()
    },
    'previousYear': {
        from: moment().subtract(1, 'years').startOf('year').toDate(),
        to: moment().subtract(1, 'years').endOf('year').toDate()
    },
    'div2': {
        divider: true
    },
    'contractStart': {
        from: null,
        to: moment().endOf('day').toDate()
    },
    'allTime': {
        from: null,
        to: moment().endOf('day').toDate()
    },
    'custom': {
        from: null,
        to: null
    }
}

class Pickers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            period: 'previousWeek',
            from: moment().subtract(1, 'weeks').startOf('week').toDate(),
            to: moment().subtract(1, 'weeks').endOf('week').toDate(),
            selectedSdps: [],
            sdpsList: [],
            minDate: undefined,
            minContractDate: undefined
        }

        const datePeriodState = window.reactStorage.getItem('datePeriodState')

        if (datePeriodState) {

            let state = JSON.parse(datePeriodState)

            state.from = new Date(state.from)
            state.to = new Date(state.to)

            this.state = state
        }

        this.onChange = this.onChange.bind(this)
        this.onPeriodChange = this.onPeriodChange.bind(this)
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this)
        this.pastDays = this.pastDays.bind(this)
        this.futureDays = this.futureDays.bind(this)
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
        this.isDisabledPeriod = this.isDisabledPeriod.bind(this);

        this.strings = LocalizedStrings({
            en: {
                today: "Today",
                yesterday: "Yesterday",
                tomorrow: "Tomorrow",
                thisWeek: "This Week",
                thisMonth: "This Month",
                thisQuarter: "This Quarter",
                thisYear: "This Year",
                previousWeek: "Previous Week",
                previousMonth: "Previous Month",
                previousQuarter: "Previous Quarter",
                previousYear: "Previous Year",
                allTime: "All Time",
                contractStart: "Contract Start",
                custom: "Custom",
                from: "From",
                to: "To",
                apply: "Apply"
            },
            nl: {
                today: "Vandaag",
                yesterday: "Gisteren",
                tomorrow: "Morgen",
                thisWeek: "Deze Week",
                thisMonth: "Deze Maand",
                thisQuarter: "Dit kwartaal",
                thisYear: "Dit Jaar",
                previousWeek: "Vorige Week",
                previousMonth: "Vorige Maand",
                previousQuarter: "Vorige Kwartaal",
                previousYear: "Vorig Jaar",
                allTime: "Volledig",
                contractStart: "Contract Start",
                custom: "Vrije Keuze",
                from: "Van",
                to: "Naar",
                apply: "Toepassen"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.onDateRangeChanged(true, false)
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.minDate !== this.state.minDate || prevProps.minContractDate !== this.state.minContractDate) {

            if (this.state.period === 'allTime' || this.state.period === 'contractStart') {

                this.onPeriodChange({ target: { value: this.state.period } }, () => {
                    this.onDateRangeChanged(false, true)

                    if (this.props.onChange)
                        this.props.onChange(this.state.selectedSdps, this.state.sdpsList, false)
                })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.minDate !== state.minDate || props.minContractDate !== state.minContractDate)
            return {
                minDate: props.minDate,
                minContractDate: props.minContractDate
            }

        // Return null if the state hasn't changed
        return null
    }

    onChange(selectedSdps, sdpsList, firstTime) {
        this.setState({ selectedSdps, sdpsList }, () => {
        if (this.props.onSelectedSdpsChange) {
            this.props.onSelectedSdpsChange(selectedSdps);
        }

        if (!sdpsList.length && ['tomorrow', 'today', 'yesterday'].indexOf(this.state.period) === -1) {
            this.onPeriodChange({ target: { value: 'today', manual: true } }, () => {
                this.onDateRangeChanged(firstTime, false)

                if (this.props.onChange)
                    this.props.onChange(selectedSdps, sdpsList, firstTime)
            })
        }
        else {
            this.onPeriodChange({ target: { value: this.state.period, manual: true } }, () => {
                this.onDateRangeChanged(firstTime, true)

                if (this.props.onChange)
                    this.props.onChange(selectedSdps, sdpsList, firstTime)
            })
        }
    })
    }

    onPeriodChange(event, cb) {
        let period = event.target.value,
            manual = event.target.manual,
            from = this.state.from,
            to = this.state.to;

        if (period === 'custom') {
            if (manual) {
                this.setState({ period, from, to }, cb && cb)
            }
            else {
                this.from.getInput().focus()
                this.setState({ period })
            }
        }
        else {
            const p = periods[period]
            from = p.from
            to = p.to

            if (period === 'allTime')
                from = this.state.minDate ? moment(this.state.minDate, 'YYYY-MM-DD').toDate() : this.state.from
            else if (period === 'contractStart')
                from = this.state.minContractDate ? moment(this.state.minContractDate, 'YYYY-MM-DD').toDate() : this.state.from

            this.setState({ period, from, to }, cb && cb)
        }
    }

    onDateRangeChanged(firstTime, localSave) {

        const { from, to } = this.state;

        this.props.onChangePeriod(moment(from).startOf('day'), moment(to).endOf('day'), firstTime)

        if (localSave)
            window.reactStorage.setItem('datePeriodState', JSON.stringify(this.state))
    }

    pastDays() {
        return this.state.minDate ? moment(this.state.minDate).startOf('day').toDate() : null
    }

    futureDays() {
        return moment().add(1, 'days').startOf('day').toDate()
    }

    showFromMonth() {
        const { from, to } = this.state;

        if (!from)
            return;

        if (moment(to).diff(moment(from), 'months') < 2)
            this.to.getDayPicker().showMonth(from);

    }

    handleFromChange(from) {

        this.setState({ from }, () => {

            let period = Object.keys(periods).find(period => {
                let p = periods[period]
                return (moment(p.from).isSame(moment(this.state.from).startOf('day')) && moment(p.to).isSame(moment(this.state.to).endOf('day')))
            })

            this.setState({ period: period || 'custom' })
            // this.onDateRangeChanged()
        });
    }

    handleToChange(to) {
        this.setState({ to }, () => {
            let period = Object.keys(periods).find(period => {
                let p = periods[period]
                return (moment(p.from).isSame(moment(this.state.from).startOf('day')) && moment(p.to).isSame(moment(this.state.to).endOf('day')))
            })

            this.setState({ period: period || 'custom' })
            // this.onDateRangeChanged()
            this.showFromMonth()
        });
    }

    isDisabled() {
        return !this.props.metervalues // || !this.props.allSdps || !this.props.allSdps.length
    }

    isDisabledPeriod(period) {
        if (['tomorrow', 'today', 'yesterday'].indexOf(period) === -1 && (!this.props.sdp || !this.props.sdp.length))
            return true

        return false
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <form className="form-inline d-flex flex-column flex-md-row justify-content-center" onSubmit={(e) => { e.preventDefault() }}>
                <div className={config.MAINTENANCE_MV ? 'd-none' : (!this.state.sdpsList || !this.state.sdpsList.length ? 'w-100' : '')}>
                    <EanPicker
                        isInjection={this.props.isInjection}
                        isProduction={this.props.isProduction}
                        disabled={this.isDisabled()}
                        onChange={this.onChange}
                        selectedCompany={this.props.selectedCompany}
                        lang={this.props.lang}
                        hideFlexibility={this.props.hideFlexibility}
                    />
                </div>
                <select className="form-control mb-2 mr-sm-2" onChange={this.onPeriodChange} value={this.state.period} disabled={this.isDisabled()}>
                    {
                        Object.keys(periods).map(period => {
                            if (periods[period].divider)
                                return <option key={period} disabled value="">────────────</option>

                            return <option key={period} value={period} disabled={this.isDisabledPeriod(period)} onClick={this.onPeriodChange}>{this.strings[period]}</option>
                        })
                    }
                </select>
                <div className="date-picker from pickers">
                    <DayPickerInput
                        ref={el => (this.from = el)}
                        value={from}
                        placeholder={this.strings.from}
                        format="DD-MM-YYYY"
                        formatDate={(date, format) => { return moment(date).format(format) }}
                        inputProps={{ className: 'form-control mb-2 mr-sm-2', readOnly: 'readonly', disabled: this.isDisabled() }}
                        dayPickerProps={{
                            selectedDays: { from, to },
                            disabledDays: { before: this.pastDays(), after: this.futureDays() },
                            modifiers,
                            firstDayOfWeek: 1,
                            numberOfMonths: 2,
                            localeUtils: MomentLocaleUtils,
                            locale: this.props.lang
                        }}
                        onDayChange={this.handleFromChange}
                    />
                </div>
                <div className="date-picker to pickers">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder={this.strings.to}
                        localeUtils={MomentLocaleUtils}
                        locale={this.props.lang}
                        format="DD-MM-YYYY"
                        formatDate={(date, format) => { return moment(date).format(format) }}
                        inputProps={{ className: 'form-control mb-2 mr-sm-2', readOnly: 'readonly', disabled: this.isDisabled() }}
                        dayPickerProps={{
                            selectedDays: { from, to },
                            disabledDays: { before: from, after: this.futureDays() },
                            modifiers,
                            firstDayOfWeek: 1,
                            numberOfMonths: 2,
                            localeUtils: MomentLocaleUtils,
                            locale: this.props.lang
                        }}
                        onDayChange={this.handleToChange}
                    />
                </div>
                <button type="button" className="btn btn-primary btn-picker mb-2" onClick={() => this.onDateRangeChanged(false, true)} disabled={this.isDisabled()}>
                    {this.strings.apply} <i className="fas fa-chevron-right ml-1"></i>
                </button>
            </form>
        )
    }
}

export default Pickers
