import React, { Component } from 'react';
import LocalizedStrings from '../Localization';



class Address extends Component {
    constructor(props) {
        super(props);

        this.strings = LocalizedStrings({
            en: {
                street: "Street",
                number: "Number",
                zip: "Zip",
                city: "City",
                country: "Country",
                belgium: "Belgium",
                theNetherlands: "The Netherlands",
                luxembourg: "Luxembourg",
                unitedKingdom: "United Kingdom"

            },
            nl: {
                street: "Straat",
                number: "Huisnummer",
                zip: "Postcode",
                city: "Stad",
                country: "Land",
                belgium: "België",
                theNetherlands: "Nederland",
                luxembourg: "Luxemburg",
                unitedKingdom: "Verenigd Koninkrijk"
            }
        }, this.props.lang);
    }

    render() {
        return (
            <div>
                {
                    [
                        { label: this.strings.street, name: 'street', required: true },
                        { label: this.strings.number, name: 'number', required: true },
                        { label: this.strings.zip, name: 'zip', required: true },
                        { label: this.strings.city, name: 'city', required: true },
                        { label: this.strings.country, name: 'country', required: true }
                    ]
                        .map(item => {
                            let name = this.props.name + '_' + item.name;
                            return (
                                <div className={'form-group row ' + (item.required ? 'required' : '')} key={name}>
                                    <label htmlFor={name} className="col-sm-3 col-form-label">
                                        {item.label}
                                    </label>
                                    {
                                        item.name === "country" ?
                                            <div className="col-sm-9">
                                                <select
                                                    className='form-control'
                                                    type="dropdown"
                                                    id={name}
                                                    required={item.required}
                                                    name={name}
                                                    value={this.props.value[name] || ''}
                                                    onChange={this.props.onFieldChange}
                                                >
                                                    <option value="" disabled></option>
                                                    <option value="Belgium">{this.strings.belgium}</option>
                                                    <option value="The Netherlands">{this.strings.theNetherlands}</option>
                                                    <option value="Luxembourg">{this.strings.luxembourg}</option>
                                                    <option value="United Kingdom">{this.strings.unitedKingdom}</option>
                                                </select>
                                            </div>
                                            :
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={name}
                                                    name={name}
                                                    required={item.required}
                                                    value={this.props.value[name] || ''}
                                                    onChange={this.props.onFieldChange} />
                                            </div>
                                    }
                                </div>
                            )
                        })
                }
            </div>
        );
    }
}

export default Address;
