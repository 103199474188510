import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import EanPicker from './EanPicker';
import LocalizedStrings from '../Localization';
import Loader from '../Loader';

import { sendContractActivation } from '../../api/user';


class ContractActivation extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      selectedEANs: [],
      notSwitchedEANs: []
    }

    this.state = {
      ...this.initialState,
      user_id: '',
      user: null,
      lang: this.props.lang
    }

    this.onEanChange = this.onEanChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onSendContractActivation = this.onSendContractActivation.bind(this);

    this.strings = LocalizedStrings({
      en: {
        errorRequired: "User and EAN fields are required.",
        error: "Error while sending contract activation. Please try again.",
        emailSent: "Contract activation email was successfully sent!",
        activation: "Send Contract Activation",
        sendEmail: "Send contract activation email to the selected user:",
        loadingUsers: "Loading users...",
        user: "User",
        noContacts: "No contacts found",
        sendContractActivation: "Send contract activation e-mail",
        ean: "EAN",
        loadingEans: "Loading EANs...",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        notSwitchedEANs: "You've selected EAN(s) without switch confirmation. Please switch them first."
      },
      nl: {
        errorRequired: "Gebruikers en EAN velden zijn vereist.",
        error: "Fout bij het verzenden van contractactivering. Probeer het opnieuw.",
        emailSent: "Contractactivatie-e-mail is succesvol verzonden!",
        activation: "Stuur Contract Activatie",
        sendEmail: "Stuur contract activatie e-mail naar de geselecteerde gebruiker:",
        loadingUsers: "Gebruiker opzoeken...",
        user: "Gebruiker",
        noContacts: "Geen contacten gevonden",
        sendContractActivation: "Verstuur contract activatie e-mail",
        ean: "EAN",
        loadingEans: "EANs laden...",
        noSdp: "Het bedrijf heeft geen SDPs.",
        addSdp: "Voeg nieuwe SDP toe",
        notSwitchedEANs: "U hebt EAN(s) geselecteerd zonder switchbevestiging. Schakel ze eerst om."
      }
    }, this.props.lang);
  }

  onEanChange(selectedEANs) {
    this.setState({
      selectedEANs,
      notSwitchedEANs: this.props.sdps.filter(sdp => selectedEANs.indexOf(sdp.ean) > -1 && (!isNaN(sdp.switchStatus) && sdp.switchStatus !== '39') /* MySupplierWeb not approved */)
    })
  }

  onUserChange(e) {
    this.setState({
      user_id: e.target.value,
      user: this.props.usersForCompany.find(user => user.id === parseInt(e.target.value, 10))
    })
  }

  onSendContractActivation(e) {
    e.preventDefault();

    this.setState({ error: false })

    if (!this.state.user_id || !this.state.selectedEANs.length) {
      this.setState({ error: this.strings.errorRequired })
      return;
    }

    sendContractActivation(this.props.selectedCompany.company_id, this.state,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ error: this.strings.error })
        }

        if (res.status === 'OK') {
          this.setState({ info: this.strings.emailSent }, () => {
            setTimeout(() => {
              this.setState({ info: undefined })
            }, 5000);
          })
        }
      })
  }

  render() {
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.activation}
        </div>
        <div className="card-body clearfix">
          {
            !this.props.sdps ?
              <Loader inline="true">{this.strings.loadingEans}</Loader>
              :
              <div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.onSendContractActivation} noValidate>
                      <p>
                        {this.strings.sendEmail}
                      </p>
                      <div className="form-group row required required-select">
                        <label htmlFor="user_id_activation" className="col-sm-3 col-form-label">{this.strings.user}</label>
                        <div className="col-sm-9">
                          {
                            this.props.usersForCompany ?
                              <div>
                                {
                                  this.props.usersForCompany.length ?
                                    <select className="form-control mb-3 required" onChange={this.onUserChange} id="user_id_activation" name="user_id_activation" value={this.state.user_id || ''} required>
                                      <option value="" disabled></option>
                                      {
                                        this.props.usersForCompany.map(user => {
                                          return (
                                            user.role !== 'admin' ? <option value={user.id} key={user.email}>{user.firstName} {user.lastName} - {user.email}</option> : null
                                          )
                                        })
                                      }
                                    </select>
                                    : <div className="col-form-label">{this.strings.noContacts}</div>
                                }
                              </div>
                              : <Loader inline="true">{this.strings.loadingUsers}</Loader>
                          }
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="contractActivationEAN" className="col-sm-3 col-form-label">{this.strings.ean}</label>
                        <div className="col-sm-9">
                          <EanPicker
                            sdps={this.props.sdps}
                            onEanChange={this.onEanChange}
                            id="contractActivationEAN"
                            lang={this.props.lang}
                            checkbox={true}
                            switchStatus={true}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className={'alert alert-danger mb-3 ' + (this.state.notSwitchedEANs.length ? '' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.notSwitchedEANs}
                        <br />
                        <ul>
                          {
                            this.state.notSwitchedEANs.map(sdp => {
                              return <li key={sdp.ean}>{sdp.ean}</li>
                            })
                          }
                        </ul>
                      </div>

                      <p className={'alert alert-warning mb-3 ' + (this.state.error ? '' : 'd-none')}>
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                      </p>

                      <p className={'alert alert-info ' + (this.state.info ? '' : 'd-none')}>
                        <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                      </p>

                      <button type="submit" className="btn btn-primary float-right">{this.strings.sendContractActivation}</button>
                    </form>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ContractActivation;
