import React, { Component } from 'react';
import LocalizedStrings from '../components/Localization';
import SubHeader from '../components/SubHeader';

class Info extends Component {
  constructor(props) {
    super(props);

    this.strings = LocalizedStrings({
      en: {
        welcome: "Welcome",
        greeting: "to the <strong>My Yuso</strong> platform.<br />The place to gain <strong>insights into your energy flows.</strong>",
        intro1: "On the Yuso portal you can find <strong>all the information you need</strong> regarding your electricity contracts and flows.",
        intro2: "As all this information can be a bit overwhelming we want to give you a short manual on how to work with the Yuso portal.",
        login: "Login Profile",
        login1: "Your user profile (displayed on the top corner on the right) is linked to your personal identity. As a person you can have <strong>access to one or multiple companies</strong>. The company profile that you are looking into is displayed on the left of your username. You can click on the user button to view the user details. This page will display your user rights and all the companies you have. If you want <strong>change to another company</strong> you can click the company name and navigate to company details.",
        login2: "Once you are on the company profile page you can see all the information of your selected company and  you can use the <strong>switch company button to change to the company</strong> you want to look into. If you don't see the company you want your user is not yet registered as a representative of the respective company. Please contact <a href='mailto:support@yuso.be'>support@yuso.be</a> to request access.",
        topMenu: "Top Menu Bar",
        topMenu1: "The top menu bar is the main navigator to explore your data on the my Yuso portal.",
        topMenu2: "The <strong>Yuso services tab</strong> gives you access to a clear <strong>overview</strong> of the services that Yuso is offering to your company.",
        topMenu3: "The <strong>Yuso dashboards tab</strong> is the place to be to get <strong>insight in the electricity flows and prices</strong>.",
        topMenu4: "And finally the <strong>Yuso documents tab</strong> gives you access to all official documents between Yuso and your company. Here we make a distinction between <strong>contractual agreement documents and statement documents</strong> that are linked to the monthly settlement.",
        services: "Yuso Services",
        services1: "Contractually the Yuso services are always linked to 1 specific measurement point.",
        services2: "<strong>The combination between a measurement point and a Yuso service contract is called a SDP (Service Delivery Point)</strong>.",
        services3: "On the services pages you can navigate to your activated services via the tabs 'supply', 'injection', or 'Flexibility'.",
        services4: "For each SDP a list of both technical and contractual parameters is shown. This data determines the various fees and costs (network costs, surcharges and taxes) that Yuso will charge you as a customer supplier.",
        services5: "If you have any questions regarding these parameters you can always contact the yuso team via <a href='mailto:support@yuso.be'>support@yuso.be</a>.",
        dashboard: "Yuso Dashboard",
        dashboard1: "The Yuso dashboard gives you insights into all your energy data:",
        dashboard2: "Your <strong>consumption volume</strong> per hour",
        dashboard3: "Your <strong>consumption prices</strong> per hour",
        dashboard4: "Your <strong>injection volume</strong> per hour",
        dashboard5: "Your <strong>injection prices</strong> per hour",
        dashboard6: "In the top bar you can decide for which SDP and over which time interval you want to visualise the data.",
        dashboard7: "On top of the visualisations you can see some simple metrics.",
        dashboard8: "Finally, if you want to have a closer look at the data, you can download the data as a csv which can be imported in excel or other analytical tools.",
        documents: "Yuso Documents",
        documents1: "Here you can download all the documents that have been issued between Yuso and your company. You can choose between 'agreements' or 'statements'. On the first page you can find all contracts and on the second page you can find a history of the settlement documents that were distributed each month.",
        documents2: "The monthly settlement consists of <strong>4 documents</strong>:",
        documents3: "<strong>An overview</strong> that balances the amounts to be received and paid",
        documents4: "<strong>A self-bill</strong> with the total receivables to be received (e.g. revenue from injection energy)",
        documents5: "<strong>An invoice</strong> with the total amount of credit to be paid (e.g. supplies of energy or grid costs related to the injection)",
        documents6: "<strong>A detail document</strong> which gives more information about the calculations of the amounts included in the invoice and self-bill. Each installation has a separate detail document for each month.",
        documents7: "For your own administration, you must include the self-bill and the invoice in your accounting (the self-bill in the sales diary and the invoice in the purchase diary). In view of the application of self-billing, you as a producer no longer have to make an invoice and deliver it to Yuso.",
        help: "Need Further Help?",
        help1: "If you <strong>have any questions</strong> regarding your My Yuso account you can <strong>always contact us</strong> at <a href='mailto:support@yuso.be'>support@yuso.be</a>."
      },
      nl: {
        welcome: "Welkom",
        greeting: "Op <strong>My Yuso</strong>. <br />Waar je <strong>inzicht</strong> krijgt <strong>in jouw energie flows.</strong>",
        intro1: "Op het Yuso portal vind je <strong>alle informatie die je nodig hebt</strong> over je elekticiteit contracten en stromen.",
        intro2: "Aangezien al deze informatie een beetje overweldigend kan zijn, geven we je graag een kort overzicht van wat je met My Yuso kan doen.",
        login: "Login Profiel",
        login1: "Je login profiel (weergegeven in de rechter bovenhoek) is gekoppeld aan je persoonlijke identiteit. Als persoon heb je <strong>toegang tot één of meerdere bedrijven</strong>. Het bedrijf dat je bekijkt is weergegeven links van je gebruikersnaam in de rechter bovenhoek. Details van je gebruiker kan je bekijken door te klikken op je gebruikersnaam. Deze pagina heeft je gebruikersrechten en alle bedrijven waartoe je toegang het weer. Indien je wenst <strong>te veranderen naar een ander bedrijf</strong> kan dit door de naar de detail pagina van het huidige bedrijf te navigeren.",
        login2: "Op de pagina van jouw bedrijfsprofiel word de informatie van het geselecteerde bedrijf weergegeven. Je kan via <strong>de verander bedrijf knop</strong> een ander bedrijf selecteren. Indien je het gewenste bedrijf nog niet ziet staan is je gebruiker nog niet geregistereerd als een vertegenwoordiger voor dit bedrijf. Neem dan contact op met <a href='mailto:support@yuso.be'>support@yuso.be</a> om toegang aan te vragen.",
        topMenu: "Bovenste menubalk",
        topMenu1: "Via de bovenste menubalk kan je navigeren op het my Yuso portaal.",
        topMenu2: "De <strong>Yuso services tab</strong> heeft je toegang tot een duidelijk <strong>overzicht van</strong>de verschillende diensten die Yuso leverd aan jouw bedrijf.",
        topMenu3: "De <strong>Yuso dashboards tab</strong> is de plaats waar je <strong>inzicht kan verwerven in je elektriciteit stromen en de respectievelijke prijzen</strong>.",
        topMenu4: "Tenslotte kan je via <strong>Yuso documenten tab</strong>  toegang krijgen tot alle officiële documenten die worden gecommuniceerd tussen je bedrijf en Yuso. We maken hier een onderscheid tussen <strong>contractuele overeenkomsten en afrekeningsdocumenten</strong> die gelinkt zijn aan de maandelijkse afrekening.",
        services: "Yuso Diensten",
        services1: "Contractueel zijn de Yuso diensten steeds verboden aan 1 specifiek meetpunt.",
        services2: "<strong>De combinate van een meetpunt met een Yuso diensten contract word een SDP(Service Delivery Point) genoemd</strong>.",
        services3: "De diensten pagina heeft een lijst weer van je SDPs per categorie 'levering', 'injectie', en 'flexibiliteit' diensten.",
        services4: "Elke SDP heeft een lijst weer van de technische en contractuele parameters. Deze gegevens bepalen de verschillende vergoedingen en kosten (netwerk kosten, taxen) die Yuso zal aanrekenen als leverancier.",
        services5: "Indien je vragen hebt in verband met deze parameters kan je steeds contact opnemen met Yuso team via <a href='mailto:support@yuso.be'>support@yuso.be</a>.",
        dashboard: "Yuso Dashboard",
        dashboard1: "Het Yuso dashboard heeft je inzicht in al jouw energie data:",
        dashboard2: "Het <strong>afnamevolume</strong> per uur",
        dashboard3: "De <strong>afnameprijzen</strong> per uur",
        dashboard4: "Het <strong>injectievolume</strong> per uur",
        dashboard5: "De <strong>injectieprijzen</strong> per uur",
        dashboard6: "Via de keuzemenu's kan je kiezen voor welke SDP en over welk tijdsinterval je de data wilt visualiseren.",
        dashboard7: "Naast de visualisaties worden bovenaan ook enkele duidelijke kwantitatieve waarden weergegeven die je meteen inzicht geven in de totale kosten en/of opbrengsten.",
        dashboard8: "Indien tenslotte de visualisaties nog niet genoeg zijn kan je de data steeds downloaden in csv formaat. Dit bestand kan makkelijk worden geimporteerd in excel of andere analytische tools.",
        documents: "Yuso Documenten",
        documents1: "Hier kan u terecht voor het downloaden van alle documenten die werden gecommuniceerd tussen jouw bedrijf en Yuso. Je kan kiezen tussen contractuele overeenkomsten of de historiek van alle afrekenings-documenten die maandelijks werden verstuurd.",
        documents2: "De maandelijkse afrekening bestaan steeds uit <strong>4 delen</strong>:",
        documents3: "<strong>Een overzicht</strong> dat het saldo maakt van de te betalen en te ontvangen bedragen",
        documents4: "<strong>Een self-bill</strong>  met een overzicht van alle bedragen in jouw voordeel.(bv inkomsten uit een injectie contract)",
        documents5: "<strong>Een factuur</strong> met een totaal van alle te betalen kredieten (bv levering van energie of de netwerk kosten horende bij injectie)",
        documents6: "<strong>Een detail document</strong> dat de berekening van alle bedragen weergeeft per aansluitingspunt.",
        documents7: "Voor je eigen adminstratie, moeten enkel de factuur en self-bill worden verwerkt in de dagboeken van jouw bedrijf (de self-bill in een dagboek verkoop en de factuur in een dagboek voor aankoop). Door middel van het gebruik van self-billing dient u als producent zelf geen factuur meer te maken ten opzichte van Yuso.",
        help: "Nog meer hulp nodig?",
        help1: "Als <strong>je verder nog vragen zou hebben</strong>in verband met je my Yuso account kan je via mail steeds contact opnemen met<a href='mailto:support@yuso.be'>support@yuso.be</a>."
      }
    }, this.props.lang);
  }

  render() {
    return (
      <div>
        <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <div className="title text-primary" dangerouslySetInnerHTML={{ __html: this.strings.greeting }}></div>
          </div>
        </div>
        <div className="row info-page">
          <div className="col-sm px-2 px-xl-5 py-3">
            <p dangerouslySetInnerHTML={{ __html: this.strings.intro1 }}></p>
            <p dangerouslySetInnerHTML={{ __html: this.strings.intro2 }}></p>

            <h2>{this.strings.login}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.strings.login1 }}></p>
            <p dangerouslySetInnerHTML={{ __html: this.strings.login2 }}></p>

            <h2>{this.strings.topMenu}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.strings.topMenu1 }}></p>
            <p dangerouslySetInnerHTML={{ __html: this.strings.topMenu2 }}></p>
            <p dangerouslySetInnerHTML={{ __html: this.strings.topMenu3 }}></p>
            <p dangerouslySetInnerHTML={{ __html: this.strings.topMenu4 }}></p>

            <h2>{this.strings.services}</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <p dangerouslySetInnerHTML={{ __html: this.strings.services1 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.services2 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.services3 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.services4 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.services5 }}></p>
              </div>
              <div className="col-sm-12 col-md-6">
                <img className="w-100" src={`${process.env.PUBLIC_URL}/portal/Services.jpg`} alt="Services" />
              </div>
            </div>

            <h2>{this.strings.dashboard}</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <p dangerouslySetInnerHTML={{ __html: this.strings.dashboard1 }}></p>
                <ul>
                  <li dangerouslySetInnerHTML={{ __html: this.strings.dashboard2 }}></li>
                  <li dangerouslySetInnerHTML={{ __html: this.strings.dashboard3 }}></li>
                  <li dangerouslySetInnerHTML={{ __html: this.strings.dashboard4 }}></li>
                  <li dangerouslySetInnerHTML={{ __html: this.strings.dashboard5 }}></li>
                </ul>
                <p dangerouslySetInnerHTML={{ __html: this.strings.dashboard6 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.dashboard7 }}></p>
                <p dangerouslySetInnerHTML={{ __html: this.strings.dashboard8 }}></p>
              </div>
              <div className="col-sm-12 col-md-6">
                <img className="w-100" src={`${process.env.PUBLIC_URL}/portal/Dashboard.jpg`} alt="Dashboard" />
              </div>
            </div>

            <h2>{this.strings.documents}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.strings.documents1 }}></p>
            <div className="row my-5">
              <div className="col-sm-12 col-md-4">
                <img className="w-100" src={`${process.env.PUBLIC_URL}/portal/Overview.jpg`} alt="Overview Document" />
              </div>
              <div className="col-sm-12 col-md-4">
                <img className="w-100" src={`${process.env.PUBLIC_URL}/portal/Invoice.jpg`} alt="Invoice Document" />
              </div>
              <div className="col-sm-12 col-md-4">
                <img className="w-100" src={`${process.env.PUBLIC_URL}/portal/Self-bill.jpg`} alt="Self-bill Document" />
              </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: this.strings.documents2 }}></p>
            <ul>
              <li dangerouslySetInnerHTML={{ __html: this.strings.documents3 }}></li>
              <li dangerouslySetInnerHTML={{ __html: this.strings.documents4 }}></li>
              <li dangerouslySetInnerHTML={{ __html: this.strings.documents5 }}></li>
              <li dangerouslySetInnerHTML={{ __html: this.strings.documents6 }}></li>
            </ul>
            <p dangerouslySetInnerHTML={{ __html: this.strings.documents7 }}></p>

            <h2>{this.strings.help}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.strings.help1 }}></p>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
