
const LocalizedStrings = (strings, lang) => {
  if(!strings)
    return console.warn('No strings specified.')

  if (typeof strings !== 'object')
    return console.warn('Strings should be JSON object.')

  if (!Object.keys(strings).length)
    return console.warn('Strings should have at least one language defined.')

  let default_lang = Object.keys(strings)[0]

  let selected_lang = lang || default_lang

  let s = Object.assign({}, strings[default_lang], strings[selected_lang])

  return s
}

export default LocalizedStrings
