import 'react-bootstrap-typeahead/css/Typeahead.css'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import React, { Component } from 'react'
import 'bootstrap/js/dist/tooltip'
import $ from 'jquery'
import 'bootstrap/js/dist/modal'
import { save, groupList, deleteGroup, uploadingAgreement, downloadAgreementFile } from '../../api/netting'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import SubHeader from '../../components/SubHeader'
import Loader from '../../components/Loader'
import LocalizedStrings from '../../components/Localization'
import moment from 'moment-timezone'
import MomentLocaleUtils from 'react-day-picker/moment';
import NumberInput from '../../components/NumberInput';
import { filterCompanies } from '../../api/company'


import 'moment/locale/nl-be'

moment.tz.setDefault('Europe/Brussels')

class Netting extends Component {

    constructor(props) {

        super(props);
        this.onFiledChange = this.onFiledChange.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.onAddCompany = this.onAddCompany.bind(this)
        this.removeCompany = this.removeCompany.bind(this)
        this.editCompany = this.editCompany.bind(this)
        this.uploadAgreementFile = this.uploadAgreementFile.bind(this)
        this.onDeleteAttempt = this.onDeleteAttempt.bind(this)
        this.deleteGroup = this.deleteGroup.bind(this)
        this.onChangeCompany = this.onChangeCompany.bind(this)
        this.nettingGroupSave = this.nettingGroupSave.bind(this)
        this.onStartDateChange = this.onStartDateChange.bind(this)
        this.clearStartDate = this.clearStartDate.bind(this)
        this.onEndDateChange = this.onEndDateChange.bind(this)
        this.onRequestDateChange = this.onRequestDateChange.bind(this)
        this.clearEndDate = this.clearEndDate.bind(this)
        this.clearSignatureDate = this.clearSignatureDate.bind(this)
        this.getGroups = this.getGroups.bind(this)
        this.toggleState = this.toggleState.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.onRenew = this.onRenew.bind(this)
        this.onSwitchPage = this.onSwitchPage.bind(this)

        this.onKboSearch = this.onKboSearch.bind(this);
        this.onKboChanged = this.onKboChanged.bind(this);
        this.cleanUpKbo = this.cleanUpKbo.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.state = {
            netting: {
                name: '',
                id: null,
                nettingFee: '',
                startDate: null,
                confirmation_file: undefined,
                signatureDate: null,
                endDate: null,
                benificiaryCompany: undefined,
                companies: [],
                agreementFile: undefined
            },
            options: [],
            selectedDates: true,
            requiredFields: null,
            noStartDate: false,
            invalidDate: false,
            companyForEdit: undefined,
            selectedGroup: [],
            uploadingFile: false,
            showDetails: false,
            deleting: false,
            withOtherGroup: false,
            addingCompany: false,
            manageGroups: true,
            editGroup: false,
            addGroup: false,
            errorAddingGroup: undefined,
            renew: false,
            groupList: [],
            companyList: [],
            benificiaryCompanySelector: [],
            isLoading: false,
            saving: false,
            selectedCompany: undefined,
            companyAdded: false
        }
        this.strings = LocalizedStrings({
            en: {
                loading: "Loading",
                welcome: "Welcome",
                addGroup: "Add Group",
                manageGroups: "Manage Groups",
                nettingGroups: "Netting Groups",
                addNettingGroup: "Add Netting Group",
                groupName: "Group Name",
                benificiaryCompany: "Benificiary Company",
                startDate: "Start Date",
                endDate: "End Date",
                signatureDate: "Signature Date",
                nettingFee: "Netting Fee",
                companies: "Companies",
                agreementFile: "Agreement File",
                edit: "Edit",
                delete: "Delete",
                renew: "Renew",
                upload: "Upload",
                download: "Download",
                confirmDelete: "Delete netting group and everything linked to it?",
                nettingGroupName: "Netting Group Name",
                noteCompanies: "You must select start date and add some companies to select",
                clear: "Clear",
                cancel: "Cancel",
                companyKbo: "Company KBO",
                nettingGroupStartDate: "Group Start Date",
                nettingGroupEndDate: "Group End Date",
                companyStartDate: "Company Start Date",
                companyEndDate: "Company End Date",
                save: "SAVE",
                chooseAgreementFile: "Choose agreement file",
                addCompany: "Add Company",
                startEndDatePicker: "Date must be between the netting group start and end date",
                requiredFields: "Required fields are missing...",
                addedCompany: "Company is already added",
                withOtherGroup: "Company is already with another group",
                uploadingFile: "Uploading the file...",
                selectStartDate: "Please first select start date for the netting group",
                loadingGroups: "Loading groups...",
                serachCompanies: "Search by typing company number or name",
                isSelected: "is selected",
                removingGroup: "Removing group...",
                confirmGroupDelete: "Confirm Group Delete",
                overlappingContracts: "There are companies overlapping with other contracts:",
                editStartDate: "You will edit start date for",
                editEndDate: "You will edit end date for",
                currentNettingGroup: "current netting group."
            },
            nl: {
                loading: "Loading",
                welcome: "Welkom",
                addGroup: "Groep toevoegen",
                manageGroups: "Groepen beheren",
                nettingGroups: "Netting Groepen",
                addNettingGroup: "Add Netting Groepen",
                groupName: "Groepen Naam",
                benificiaryCompany: "Benificiary Company",
                startDate: "Start Datum",
                endDate: "End Datum",
                signatureDate: "Handtekening Datum",
                nettingFee: "Netting Tarief",
                companies: "Companies",
                agreementFile: "Overeenkomst bestand",
                edit: "Edit",
                delete: "Delete",
                renew: "Vernieuwen",
                upload: "Uploaden",
                cancel: "Cancel",
                download: "Downloaden",
                confirmDelete: "Nettinggroep en alles wat daaraan gekoppeld is verwijderen?",
                nettingGroupName: "Netting Groepen Naam",
                noteCompanies: "U moet de startdatum selecteren en een aantal bedrijven toevoegen om te selecteren",
                clear: "Duidelijk",
                companyKbo: "Company KBO",
                nettingGroupStartDate: "Groepen Start Datum",
                nettingGroupEndDate: "Groepen End Datum",
                companyStartDate: "Company Groepen Datum",
                companyEndDate: "Company End Datum",
                save: "OPSLAAN",
                chooseAgreementFile: "Kies overeenkomst bestand",
                addCompany: "Add Company",
                startEndDatePicker: "Datum moet tussen begin en einde zijn Datum",
                requiredFields: "Verplichte velden ontbreken...",
                addedCompany: "Bedrijf is al toegevoegd",
                withOtherGroup: "Bedrijf zit al bij een andere groep",
                uploadingFile: "Het bestand uploaden...",
                selectStartDate: "Selecteer eerst de startdatum voor de verrekeningsgroep",
                loadingGroups: "Loading groups...",
                serachCompanies: "Zoek door het bedrijfsnummer of de naam te typen",
                isSelected: "is geselecteerd",
                removingGroup: "Groep verwijderen...",
                confirmGroupDelete: "Bevestig groepsverwijdering",
                overlappingContracts: "Er zijn bedrijven die overlappen met andere contracten:",
                editStartDate: "U bewerkt de startdatum voor",
                editEndDate: "U bewerkt de einddatum voor",
                currentNettingGroup: "huidige netgroep."
            }
        }, this.props.lang)
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        groupList(null,
            (err, res) => {
                if (err) {
                    return window.reportError.send(err)
                } else {
                    this.setState({
                        groupList: res.groupList,
                        companyList: res.groupCompanies,
                        idAggrements: res.idAggrements,
                        idGroups: res.idGroups + 1,
                    })
                }
            }
        )
    }

    onFileChange(e) {

        const file = e.target.files[0]

        if (file) {

            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState({
                    netting: {
                        ...this.state.netting,
                        confirmation_file: {
                            base64: reader.result.split(',').pop(),
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            lastModified: file.lastModified,
                            lastModifiedDate: file.lastModifiedDate
                        }
                    }

                })
            }
            reader.readAsDataURL(file);
        }
        if (e.target.id === 'upload') {
            this.setState({
                netting: {
                    ...this.state.netting,
                    id: e.target.name,
                    signatureDate: moment().format('YYYY-MM-DD')
                },
                uploadingFile: true

            }, () => {
                setTimeout(() => {
                    this.uploadAgreementFile()
                }, 4000);
            })
        }
    }

    onChangeCompany(event) {
        let newBenificiaryCompanySelector = this.state.benificiaryCompanySelector.filter(x => x.companyName !== event.target.value)
        if (event) {
            let newBenificiaryCompany = this.state.netting.companies.find(x => x.companyName === event.target.value)
            this.setState({
                netting: { ...this.state.netting, benificiaryCompany: newBenificiaryCompany },
                benificiaryCompanySelector: newBenificiaryCompanySelector
            })
        } else {
            this.setState({
                netting: { ...this.state.netting, benificiaryCompany: undefined, benificiaryCompanySelector: [] }
            })
        }

    }

    editCompany(company) {

        if (!this.state.companyForEdit) {
            this.setState({
                companyForEdit: company
            })
        } else {
            if (this.state.companyForEdit.company_id === company.company_id) {
                this.setState({
                    companyForEdit: undefined
                })
            } else {
                this.setState({
                    companyForEdit: company
                })
            }
        }
    }

    removeCompany(company) {

        let newCompanies = this.state.netting.companies.filter(x => x.companyNumber !== company.companyNumber)

        if (!newCompanies.length) {
            this.setState({
                netting: {
                    ...this.state.netting,
                    companies: newCompanies,
                    benificiaryCompany: undefined
                }
            })
        } else {
            if (this.state.netting.benificiaryCompany && company.companyNumber === this.state.netting.benificiaryCompany.companyNumber) {
                this.setState({
                    netting: {
                        ...this.state.netting,
                        companies: newCompanies,
                        benificiaryCompany: newCompanies[0]
                    }
                })
            } else {
                this.setState({
                    netting: {
                        ...this.state.netting,
                        companies: newCompanies
                    }
                })
            }
        }
    }

    onSwitchPage(e) {

        if (e.target.id === 'addGroup') {
            this.setState({
                netting: {
                    ...this.state.netting,
                    name: '',
                    nettingFee: '',
                    id: this.state.idGroups,
                    startDate: null,
                    endDate: null,
                    signatureDate: null,
                    agreementFile: undefined,
                    benificiaryCompany: undefined,
                    companies: []
                },
                selectedGroup: [],
                benificiaryCompanySelector: [],
                addGroup: true,
                companyForEdit: undefined,
                manageGroups: false,
                editGroup: false
            })
        } else {
            this.setState({
                netting: {
                    ...this.state.netting,
                    name: '',
                    nettingFee: '',
                    id: null,
                    startDate: null,
                    endDate: null,
                    benificiaryCompany: undefined,
                    companies: []
                },
                selectedGroup: [],
                benificiaryCompanySelector: [],
                companyForEdit: undefined,
                addGroup: false,
                manageGroups: true,
                editGroup: false
            })
        }
    }

    onEdit(e) {
        console.log(e.target.id)
        let editGroup = this.state.groupList.find(x => x.id === Number(e.target.id))
        let editCompanies = this.state.companyList.filter(x => x.nettingGroupId === Number(e.target.id))
        let editBenificiaryCompany = this.state.companyList.find(x => x.companyNumber === editGroup.beneficiaryCompanyId)
        this.setState({
            netting: {
                ...this.state.netting,
                benificiaryCompany: editBenificiaryCompany,
                id: editGroup.id,
                companyNumber: editGroup.companyNumber,
                companies: editCompanies,
                name: editGroup.name,
                nettingFee: editGroup.nettingFee,
                startDate: editCompanies[0] ? editCompanies[0].nettingStartDate ? editCompanies[0].nettingStartDate : null : null,
                endDate: editCompanies[0] ? editCompanies[0].nettingEndDate ? editCompanies[0].nettingEndDate : null : null
            },
            selectedGroup: [],
            editGroup: true,
            renew: false,
            manageGroups: false
        })
    }

    uploadAgreementFile() {
        uploadingAgreement(this.state.netting,
            (err, res) => {
                if (err) {
                    return window.reportError.send(err)
                } else {
                    this.setState({
                        uploadingFile: false
                    }, () => {
                        this.getGroups()
                    })
                }
            })
    }

    onRenew(e) {
        let renewGroup = this.state.groupList.find(x => x.id === Number(e.target.id))
        let renewCompanies = this.state.companyList.filter(x => x.nettingGroupId === Number(e.target.id))
        let renewBenificiaryCompany = this.state.companyList.find(x => x.companyNumber === renewGroup.beneficiaryCompanyId)
        this.setState({
            netting: {
                ...this.state.netting,
                benificiaryCompany: renewBenificiaryCompany,
                oldId: renewGroup.id,
                id: this.state.idGroups,
                companyNumber: renewGroup.companyNumber,
                companies: renewCompanies,
                name: renewGroup.name,
                nettingFee: renewGroup.nettingFee,
                startDate: null,
                endDate: null,
                signatureDate: null
            },
            selectedGroup: [],
            renew: true,
            editGroup: false,
            manageGroups: false
        })
    }

    nettingGroupSave(e) {

        e.preventDefault()

        let companiesEditCheck, previousCompaniesCheck

        if (this.state.editGroup === true) {

            companiesEditCheck = this.state.companyList.filter(x => this.state.netting.companies.includes(x));

            previousCompaniesCheck = companiesEditCheck.filter(x => x.nettingGroupId ? x.nettingGroupId !== this.state.netting.id && (moment(this.state.netting.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment(x.nettingStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD') && moment(this.state.netting.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') <= moment(x.nettingEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined)
        }
        if (previousCompaniesCheck === undefined || !previousCompaniesCheck.length) {
            const form = e.target.elements
            this.setState({ saving: true });
            for (let i = 0; i < form.length; i++) {
                if (form[i].required && !form[i].value.trim().length) {
                    if (this.state.requiredFields === null)
                        this.setState({
                            requiredFields: `Reqired: ${form[i].name.split('_').join(' ')}`
                        })
                    else this.setState({
                        requiredFields: `${this.state.requiredFields}, ${form[i].name.split('_').join(' ')}`
                    })
                }
            }

            if (this.state.netting.startDate === null)
                this.setState({
                    requiredFields: `Reqired: Start Date`
                })

            let saveMethod = this.state.editGroup === true ? 'edit' : this.state.renew === true ? 'renew' : 'save'

            if (this.state.requiredFields === null) {

                save(this.state.netting, saveMethod,
                    (err, res) => {
                        if (err) {
                            return window.reportError.send(err)
                        } else {
                            this.setState({
                                saving: false,
                                editGroup: false,
                                renew: false,
                                addGroup: false,
                                manageGroups: true
                            }, () => {
                                this.getGroups()
                            })
                        }
                    })
            } else {
                this.setState({ saving: false },
                    () => {
                        setTimeout(() => {
                            this.setState({ requiredFields: null })
                        }, 4000);
                    })
            }
        } else {
            this.setState({
                errorAddingGroup: previousCompaniesCheck.map(x => x.companyName)
            },
                () => {
                    setTimeout(() => {
                        this.setState({ errorAddingGroup: undefined })
                    }, 4000);
                })
        }


    }

    toggleState(e) {

        let groupId = e.target.id.split('-')[1].trim()
        if (this.state.selectedGroup.length && this.state.selectedGroup[0].nettingGroupId === Number(groupId)) {
            this.setState({
                selectedGroup: []
            })

        } else {

            let listOfCompanies = this.state.companyList.filter(x => x.nettingGroupId === Number(groupId))

            this.setState({
                selectedGroup: listOfCompanies
            })

        }

    }

    handleFile(e) {

        let groupId = e.target.id.split('-')[1].trim()
        let agreementFilename = this.state.groupList.find(x => x.id === Number(groupId)).agreementFile
        downloadAgreementFile(agreementFilename,
            (err, res) => {
                if (err) {
                    return window.reportError.send(err)
                }
            })
    }

    onStartDateChange(startDate, modifiers, event) {


        if (event.props.inputProps.name.toString() !== 'startDate') {
            if (moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment(this.state.netting.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') && moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') < moment(this.state.netting.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                this.state.netting.companies.forEach(x => {
                    if (x.companyNumber === this.state.companyForEdit.companyNumber) {
                        x.nettingStartDate = moment(startDate).format('YYYY-MM-DD')
                    }
                })
            this.setState({
                netting: { ...this.state.netting }
            })
        } else {
            this.state.netting.companies.forEach(x => {
                x.nettingStartDate = moment(startDate).format('YYYY-MM-DD')
            })
            this.setState({ netting: { ...this.state.netting, startDate: moment(startDate).format('YYYY-MM-DD') } })
        }
    }

    onRequestDateChange(signatureDate) {

        this.setState({
            netting: { ...this.state.netting, signatureDate: moment(signatureDate).format('YYYY-MM-DD') }
        })

    }

    clearSignatureDate() {
        this.setState({ netting: { ...this.state.netting, signatureDate: null } })
    }

    onEndDateChange(endDate, modifiers, event) {
        if (event.props.inputProps.name.toString() !== 'endDate') {

            if (moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') <= moment(this.state.netting.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') && moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') > moment(this.state.netting.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) {
                this.state.netting.companies.forEach(x => {
                    if (x.companyNumber === this.state.companyForEdit.companyNumber) {
                        x.nettingEndDate = moment(endDate).format('YYYY-MM-DD')
                    }
                })
                this.setState({
                    netting: { ...this.state.netting }
                })
            } else {
                this.setState({
                    invalidDate: true
                }, () => {
                    setTimeout(() => {
                        this.setState({ invalidDate: false })
                    }, 4000);
                })
            }

        } else {
            this.state.netting.companies.forEach(x => {
                x.nettingEndDate = moment(endDate).format('YYYY-MM-DD')
            })
            this.setState({ netting: { ...this.state.netting, endDate: moment(endDate).format('YYYY-MM-DD') } })
        }

    }

    clearStartDate() {
        this.setState({ netting: { ...this.state.netting, startDate: null } })
    }

    onDeleteAttempt(e) {
        this.setState({
            netting: {
                ...this.state.netting,
                deleteId: e.target.id !== "cancelDeletion" ? e.target.id : undefined,
                deleting: true
            }
        })
        $('#confirm-group-delete').modal('show')
    }

    deleteGroup() {
        deleteGroup(this.state.netting.deleteId,
            (err, res) => {
                if (err) {
                    return window.reportError.send(err)
                } else {
                    this.setState({
                        manageGroups: true,
                        deleting: false
                    }, () => {
                        this.getGroups()
                    })
                }
            })
    }

    clearEndDate() {
        this.setState({ netting: { ...this.state.netting, endDate: null } })
    }

    onAddCompany() {

        this.setState({ selectedGroup: [] })

        if (this.state.netting.startDate !== null || this.state.renew === true) {

            let checkCompany = this.state.netting.companies.find(x => x.companyNumber === this.state.selectedCompany.companyNumber)

            let alreadyAdded = this.state.companyList.find(x => x.companyNumber === this.state.selectedCompany.companyNumber && moment(this.state.netting.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment(x.nettingStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD') && moment(this.state.netting.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') <= moment(x.nettingEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))

            this.setState({
                addingCompany: true,
                selectedCompany: {
                    id: this.state.idAggrements + 1,
                    nettingStartDate: this.state.netting.startDate,
                    nettingEndDate: this.state.netting.endDate
                }
            })

            if (!alreadyAdded || this.state.renew === true) {
                if (!checkCompany) {
                    const companies = [...this.state.netting.companies]
                    companies.push(this.state.selectedCompany)

                    this.setState({
                        netting: {
                            ...this.state.netting,
                            companies,
                            benificiaryCompany: companies[0]
                        },
                        idAggrements: this.state.idAggrements + 1,
                        companyAdded: false,
                        addingCompany: false
                    })
                } else {
                    this.setState({
                        companyAdded: true,
                        addingCompany: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ companyAdded: false })
                        }, 4000);
                    })
                }
            } else {
                this.setState({
                    addingCompany: false,
                    withOtherGroup: true
                },
                    () => {
                        setTimeout(() => {
                            this.setState({ withOtherGroup: false })
                        }, 4000);
                    })

            }
        } else {

            if (this.state.selectedCompany) {
                this.setState({
                    selectedDates: false
                },
                    () => {
                        setTimeout(() => {
                            this.setState({ selectedDates: true })
                        }, 4000);
                    })
            }

        }
    }

    onFiledChange(e) {

        if (e.target.id === 'selectCompany') {
            let newBenificiaryCompanySelector = this.state.benificiaryCompanySelector.filter(x => x.companyName !== e.target.value)
            if (e) {
                let newBenificiaryCompany = this.state.netting.companies.find(x => x.companyName === e.target.value)
                this.setState({
                    netting: { ...this.state.netting, benificiaryCompany: newBenificiaryCompany },
                    name: `${newBenificiaryCompany.companyName} 'GROEP'`,
                    benificiaryCompanySelector: newBenificiaryCompanySelector
                })
            } else {
                this.setState({
                    netting: { ...this.state.netting, benificiaryCompany: undefined, benificiaryCompanySelector: [] }
                })
            }
        }
        if (e.target.id === 'nettingName') {
            this.setState({
                netting: { ...this.state.netting, name: e.target.value }
            })
            // this.props.netting.name = e.target.value
        }
        if (e.target.id === "nettingFee") {
            this.setState({
                netting: { ...this.state.netting, nettingFee: e.target.value }

            })
        }
    }

    onKboSearch(query) {
        this.setState({ isLoading: true });

        if (query.length) {
            filterCompanies({ name_or_kbo: query }, (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    isLoading: false,
                    options: res.companies
                });
            });
        }
    }

    onKboChanged(selected) {

        const company = selected[0]

        if (company) {

            let addCompany = this.state.companyList.find(x => x.companyNumber === company.company_id)

            this.setState({
                selectedCompany: addCompany ? addCompany : company
            })
        }
    }

    cleanUpKbo(e) {
        if (e.target.id === 'kbo_number') {

            const clean_value = e.target.value ? String(e.target.value).replace(/\s+|[^0-9]+|\b0+/g, '').trim() : '';

            e.target.value = clean_value;
            e.target.defaultValue = clean_value;
        }
    }

    onFocus(e) {
        if (e.target.id === 'company_vat') {
            $('#vat-help').modal('show')
        }
    }

    render() {
        return (
            this.state.groupList.length && this.state.deleting === false ?
                <div>
                    <SubHeader name={`${this.strings.welcome} ${this.props.selectedCompany.firstName}`} image="Home" hideIcon="true" type="Home" />
                    <div className="d-flex align-items-center mt-5 mb-4">
                        <hr className="border-warning w-100" />
                        <h5 className="text-primary text-nowrap mx-5">{this.state.manageGroups === true ? 'Netting Groups' : 'Add Netting Group'} </h5>
                        <hr className="border-warning w-100" />

                    </div>
                    <div>
                        {
                            this.state.manageGroups === true && this.state.editGroup === false && this.state.addGroup === false ?
                                <div className="mb-4">
                                    <button id="addGroup" type="button" className="btn btn-primary mb-0" onClick={this.onSwitchPage}>
                                        <i className="text-white mr-0"></i>{this.strings.addGroup}</button>
                                    {/* </div> : this.state.manageGroups === false && this.state.editGroup === true || this.state.addGroup === true || this.state.renew === true ?  
                                //To be checked if correction is correct @Nikolce */}
                                </div> : this.state.manageGroups === false && (this.state.editGroup === true || this.state.addGroup === true || this.state.renew === true) ?
                                    <div className="mb-4">
                                        <button id="manageGroups" type="button" className="btn btn-primary mb-0" onClick={this.onSwitchPage}>
                                            <i className="text-white mr-0"></i>{this.strings.manageGroups}</button>
                                    </div> : null
                        }
                    </div>
                    {
                        this.state.manageGroups === true && this.state.editGroup === false && this.state.addGroup === false ?
                            <div>
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">{this.strings.groupName}</th>
                                            <th scope="col">{this.strings.benificiaryCompany}</th>
                                            {/* <th scope="col">Benificiary Company KBO</th> */}
                                            <th scope="col">{this.strings.startDate}</th>
                                            <th scope="col">{this.strings.endDate}</th>
                                            <th scope="col">{this.strings.signatureDate}</th>
                                            <th scope="col">{this.strings.nettingFee}</th>
                                            <th scope="col">{this.strings.companies}</th>
                                            <th scope='col'>{this.strings.agreementFile}</th>
                                            <th scope="col">{this.strings.edit}</th>
                                            <th scope="col">{this.strings.delete}</th>
                                            <th scope="col">{this.strings.renew}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.groupList.map((group, index) => (
                                                <tr key={index}>
                                                    <td>{group.name}</td>
                                                    <td>{group.benificiaryCompany + ' (' + group.beneficiaryCompanyId + ')'}</td>
                                                    <td>{group.startDate}</td>
                                                    <td>{group.endDate}</td>
                                                    <td>{group.signatureDate}</td>
                                                    <td>{group.nettingFee} €/MWh<br /></td>
                                                    <td className="borderless bg-white text-center">{<div className="btn-link d-inline-block cursor-pointer" id={`showDetails - ${group.id}`} onClick={this.toggleState}>{group.company_count}</div>}</td>
                                                    <td className="borderless bg-white text-center">
                                                        <span className="ml-3" title={group.agreementFile ? group.agreementFile : this.strings.upload}>
                                                            {
                                                                group.agreementFile ?
                                                                    <i className={group.agreementFile ? "fas fa-download mr-2 cursor-pointer" : "fas fa-upload mr-2 cursor-pointer"} onClick={this.handleFile} id={`agreementFile - ${group.id}`}>{group.agreementFile ? this.strings.download : this.strings.upload}</i>
                                                                    : <span className="ml-3">
                                                                        <input type="file" name={group.id} onChange={this.onFileChange} id="upload" style={{ display: "none" }} />
                                                                        <label htmlFor="upload" className={group.agreementFile ? "fas fa-download mr-2 cursor-pointer" : "fas fa-upload mr-2 cursor-pointer"}>{this.strings.upload}</label>
                                                                    </span>

                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="ml-3" title={this.strings.edit}>
                                                            <i className="fas fa-pencil-alt text-sucess cursor-pointer" id={group.id} onClick={this.onEdit}></i>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="ml-3" title={this.strings.delete}>
                                                            <i className="fas fa-times text-danger cursor-pointer" data-toggle="modal" id={group.id} onClick={this.onDeleteAttempt} data-target="confirm-group-delete"></i>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="ml-3" title={this.strings.renew}>
                                                            <i className="fas fa-redo text-black mr-2 cursor-pointer" data-toggle="modal" onClick={this.onRenew} id={group.id}></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="modal fade" id="confirm-group-delete" tabIndex="-1" role="dialog"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    <i className="fas fa-exclamation-triangle mr-2 text-warning"></i> {this.strings.confirmGroupDelete}
                                                </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <p>
                                                    {this.strings.confirmDelete}
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" id="cancelDeletion" data-dismiss="modal" onClick={this.onDeleteAttempt}>{this.strings.cancel}</button>
                                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteGroup}>{this.strings.delete}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : this.state.editGroup === true || this.state.addGroup === true || this.state.renew === true ?
                                <div>


                                    <div className="form-group"></div>

                                    <form onSubmit={this.nettingGroupSave} noValidate>
                                        <div className="form-row">
                                            <div className="form-group col-md-7">
                                                <div>
                                                    <label htmlFor="start_date">{this.strings.startDate}</label>
                                                    <div className="input-group mb-7 d-flex flex-nowrap">
                                                        <DayPickerInput
                                                            value={this.state.companyForEdit ? moment(this.state.companyForEdit.nettingStartDate, 'YYYY-MM-DD').toDate() : !this.state.companyForEdit && this.state.netting.startDate !== null ? moment(this.state.netting.startDate, 'YYYY-MM-DD').toDate() : ''}
                                                            required
                                                            format="DD-MM-YYYY"
                                                            placeholder="DD-MM-YYYY"
                                                            formatDate={(date, format) => { return moment(date).format(format) }}
                                                            inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'start_date', name: this.state.companyForEdit ? 'companyForEdit' : 'startDate' }}
                                                            dayPickerProps={{
                                                                selectedDays: this.state.companyForEdit ? moment(this.state.companyForEdit.nettingStartDate, 'YYYY-MM-DD').toDate() : !this.state.companyForEdit && this.state.netting.startDate !== null ? moment(this.state.netting.startDate, 'YYYY-MM-DD').toDate() : null,
                                                                localeUtils: MomentLocaleUtils,
                                                                locale: this.props.lang,
                                                            }}
                                                            onDayChange={this.onStartDateChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <button type="button" className="btn btn-secondary px-3" onClick={this.clearStartDate}><i className='fas fa-times mr-2'></i>{this.strings.clear}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <i className="fas fa-exclamation-triangle mr-2 text-warning"></i>
                                                    {
                                                        this.state.companyForEdit ?
                                                            <span>{this.strings.editStartDate} {this.state.companyForEdit.companyName}</span> :
                                                            <span>{this.strings.editStartDate} {this.strings.currentNettingGroup}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group col-md-7 required required-label">
                                                <label htmlFor="name">{this.strings.nettingGroupName}</label>
                                                <input value={this.state.name !== '' ? this.state.netting.name : null} type="text" className="form-control" id="nettingName" name="name" onChange={this.onFiledChange} required />
                                            </div>
                                            <div className="form-group col-md-7">
                                                {
                                                    !this.state.netting.companies ?
                                                        <Loader inline="true" className="text-center" />
                                                        :
                                                        !this.state.netting.companies.length ?
                                                            <div className="text-center my-4">
                                                                <h5>No companies selected</h5>
                                                            </div>
                                                            :
                                                            <div>
                                                                <table className="table table-sm table-fixed">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{this.strings.companyName}</th>
                                                                            <th scope="col">{this.strings.companyKBO}</th>
                                                                            <th>{this.strings.nettingGroupStartDate}</th>
                                                                            <th>{this.strings.nettingGroupEndDate}</th>
                                                                            <th>{this.strings.companyStartDate}</th>
                                                                            <th>{this.strings.companyEndDate}</th>
                                                                            <th scope="col" className="text-center" style={{ width: '25px' }}></th>
                                                                            <th scope="col" className="text-center" style={{ width: '25px' }}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.netting.companies.map((company, index) => (
                                                                                <tr className='bg-success text-white' id={company.companyName} key={index}>
                                                                                    <td>{company.companyName}</td>
                                                                                    <td>{company.companyNumber}</td>
                                                                                    <td>{this.state.netting.startDate}</td>
                                                                                    <td>{this.state.netting.endDate}</td>
                                                                                    <td>{company.nettingStartDate}</td>
                                                                                    <td>{company.nettingEndDate}</td>
                                                                                    {
                                                                                        this.state.renew !== true ?
                                                                                            <td>
                                                                                                <i className="fas fa-pencil-alt text-sucess cursor-pointer" title={this.strings.edit} onClick={() => this.editCompany(company)}></i>
                                                                                            </td> : null
                                                                                    }
                                                                                    <td>
                                                                                        {
                                                                                            <i className="fas fa-times text-danger cursor-pointer" title={this.strings.delete} onClick={() => this.removeCompany(company)}></i>
                                                                                        }
                                                                                    </td>

                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                }
                                                <div>
                                                    <div className="input-group mt-3">
                                                        <AsyncTypeahead
                                                            id="kbo_number"
                                                            clearButton
                                                            name="kbo_number"
                                                            required
                                                            useCache={false}
                                                            filterBy={
                                                                (option, props) => {

                                                                    let q = String(props.text).trim().toLowerCase().replace(/\.+/g, '')

                                                                    if (q.substring(0, 2).toUpperCase() === 'BE' && /^[0-9]+$/.test(q.substring(2).trim()))
                                                                        q = q.substring(2).trim()

                                                                    q = q.replace(/\b0+/g, '')

                                                                    if (!option)
                                                                        return false

                                                                    return (
                                                                        (String(option.companyNumber) && String(option.companyNumber).toLowerCase().indexOf(q)) !== -1 ||
                                                                        (option.companyName && option.companyName.toLowerCase().indexOf(q)) !== -1
                                                                    );
                                                                }
                                                            }
                                                            labelKey={option => `${option.companyName}`}
                                                            renderMenu={(results, menuProps) => (
                                                                <Menu {...menuProps}>
                                                                    {results.map((result, index) => (
                                                                        <MenuItem option={result} position={index} key={result.companyNumber}>
                                                                            {result.companyName}
                                                                            <br />
                                                                            <small className="text-muted">{result.formattedNumber}<span className={!result.addressCity ? 'd-none' : ''}>&nbsp;- {result.addressCity}</span></small>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Menu>
                                                            )}
                                                            inputProps={{ required: true, name: 'kbo_number', id: 'kbo_number', ref: el => this.kbo = el }}
                                                            onFocus={this.cleanUpKbo}
                                                            onBlur={this.cleanUpKbo}
                                                            defaultInputValue={''}
                                                            isLoading={this.state.isLoading}
                                                            placeholder={this.strings.serachCompanies}
                                                            options={this.state.options}
                                                            onSearch={this.onKboSearch}
                                                            onChange={this.onKboChanged}
                                                            resetForm={this.props.resetForm}
                                                        />
                                                        <div className="input-group-append">
                                                            <button type="button" className="btn btn-secondary" onClick={this.onAddCompany}>
                                                                <i className={!this.state.addingCompany === true ? "fas fa-save mr-2" : "fa fa-spinner fa-spin mr-2"}></i>
                                                                {this.strings.addCompany}</button>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.withOtherGroup === true ?
                                                            <p className="alert alert-warning mt-3">
                                                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.withOtherGroup}
                                                            </p>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group col-md-7 required required-label">
                                                <label>{this.strings.benificiaryCompany}</label>
                                                <select name='selectCompany' id='selectCompany' aria-label='Add Netting Group' className="form-control required-select" value={this.state.netting.benificiaryCompany ? this.state.benificiaryCompany : ''} disabled={!this.state.netting.companies && this.state.netting.companies.length} onChange={this.onFiledChange} required>
                                                    {
                                                        this.state.netting.companies.map((x, index) => {
                                                            return (
                                                                <option key={index} id={x.company_id}>{x.companyName}</option>

                                                            )
                                                        })}
                                                </select>
                                                <div className='mt-2'>
                                                    {
                                                        // added till here
                                                        !this.state.netting.benificiaryCompany ?
                                                            <span><i className='fas fa-exclamation-triangle text-warning mr-2'></i>{this.strings.noteCompanies}</span>
                                                            :
                                                            <span>{this.state.netting.benificiaryCompany.companyName} {this.strings.isSelected}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group col-md-7 required required-label">
                                                <label htmlFor="kbo">{this.strings.nettingFee}</label>
                                                <NumberInput type="number" className="form-control" value={this.state.netting.nettingFee} id="nettingFee" name="nettingFee" onChange={this.onFiledChange} min="0" required />
                                            </div>

                                            <div className="form-group col-md-7">
                                                <label htmlFor="start_date">{this.strings.endDate}</label>
                                                <div className="input-group mb-7 d-flex flex-nowrap">
                                                    <DayPickerInput
                                                        value={this.state.companyForEdit ? moment(this.state.companyForEdit.nettingEndDate, 'YYYY-MM-DD').toDate() : !this.state.companyForEdit && this.state.netting.endDate !== null ? moment(this.state.netting.endDate, 'YYYY-MM-DD').toDate() : ''}
                                                        format="DD-MM-YYYY"
                                                        placeholder="DD-MM-YYYY"
                                                        formatDate={(date, format) => { return moment(date).format(format) }}
                                                        inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'end_date', name: this.state.companyForEdit ? 'companyForEdit' : 'endDate' }}
                                                        dayPickerProps={{
                                                            selectedDays: this.state.companyForEdit ? moment(this.state.companyForEdit.nettingEndDate, 'YYYY-MM-DD').toDate() : !this.state.companyForEdit && this.state.netting.endDate !== null ? moment(this.state.netting.endDate, 'YYYY-MM-DD').toDate() : null,
                                                            localeUtils: MomentLocaleUtils,
                                                            locale: this.props.lang,
                                                        }}
                                                        onDayChange={this.onEndDateChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type="button" className="btn btn-secondary px-3" onClick={this.clearEndDate}><i className='fas fa-times mr-2'></i> {this.strings.clear}</button>
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <i className="fas fa-exclamation-triangle mr-2 text-warning"></i>
                                                    {
                                                        this.state.companyForEdit ?
                                                            <span>{this.strings.editEndDate} {this.state.companyForEdit.companyName}.</span> :
                                                            <span>{this.strings.editEndDate} {this.strings.currentNettingGroup}</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group col-md-7">

                                                <label htmlFor="request_date">{this.strings.signatureDate}</label>
                                                <div className="input-group mb-7 d-flex flex-nowrap">
                                                    <DayPickerInput
                                                        value={this.state.netting.signatureDate !== null ? moment(this.state.netting.signatureDate, 'YYYY-MM-DD').toDate() : ''}
                                                        format="DD-MM-YYYY"
                                                        placeholder="DD-MM-YYYY"
                                                        formatDate={(date, format) => { return moment(date).format(format) }}
                                                        inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'request_date', name: 'request_date' }}
                                                        dayPickerProps={{
                                                            selectedDays: this.state.netting.signatureDate !== null ? moment(this.state.netting.signatureDate, 'YYYY-MM-DD').toDate() : null,
                                                            localeUtils: MomentLocaleUtils,
                                                            locale: this.props.lang
                                                        }}
                                                        onDayChange={this.onRequestDateChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type="button" className="btn btn-secondary px-3" onClick={this.clearSignatureDate}><i className='fas fa-times mr-2'></i>{this.strings.clear}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-7">
                                                <div className="input-group mb-2 d-flex flex-nowrap">
                                                    <input type="file" className="custom-file-input" name="confirmation_file" onChange={this.onFileChange} />
                                                    <label className="custom-file-label" htmlFor="confirmation_file">{this.state.netting.confirmation_file ? this.state.netting.confirmation_file.name : this.strings.chooseAgreementFile} </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-4">
                                            <button type="submit" className="btn btn-primary px-5 py-3" disabled={this.state.saving}>
                                                <i className={!this.state.saving ? "fas fa-save mr-2" : "fa fa-spinner fa-spin mr-2"}></i> {this.strings.save}
                                            </button>
                                        </div>
                                    </form >
                                </div > : null
                    }
                    {
                        this.state.selectedGroup.length ?
                            <div className="d-flex align-items-center mt-5 mb-4">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">{this.strings.companyName}</th>
                                            <th scope="col">{this.strings.signatureDate}</th>
                                            <th scope="col">{this.strings.startDate}</th>
                                            <th scope="col">{this.strings.endDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.selectedGroup.map((group, index) => (
                                                <tr key={index} className=''>
                                                    <td>{group.companyName}</td>
                                                    <td>{group.signatureDate}</td>
                                                    <td>{group.nettingStartDate}<br /></td>
                                                    <td>{group.nettingEndDate}<br /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div> : null
                    }
                    {
                        this.state.invalidDate ?
                            <p className="alert alert-warning mt-3">
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.startEndDatePicker}
                            </p>
                            : null
                    }
                    {
                        this.state.requiredFields !== null ?
                            <p className="alert alert-warning mt-3">
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.requiredFields}
                            </p>
                            : null
                    }
                    {
                        this.state.companyAdded ?
                            <p className="alert alert-warning mt-3">
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.addedCompany}
                            </p>
                            : null
                    }
                    {
                        this.state.uploadingFile === true ?
                            <p className="alert alert-info">
                                <i className="fa fa-spinner fa-spin mr-2"></i> {this.strings.uploadingFile}
                            </p>
                            : null
                    }
                    {
                        this.state.selectedDates === false ?
                            <p className="alert alert-warning mt-3">
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.selectStartDate}
                            </p>
                            : null
                    }
                    {
                        this.state.errorAddingGroup ?
                            <p className="alert alert-warning mt-3">
                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.overlappingContracts} {this.state.errorAddingGroup.toString()}
                            </p>
                            : null
                    }
                </div > : this.state.deleting === true ? <Loader>{this.strings.removingGroup}</Loader> : <Loader>{this.strings.loadingGroups}</Loader>
        );
    }
}
export default Netting;