import config from './config';
import { getAccessToken } from './user';

const getDgos = (cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/getDgos`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getSdps = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/getSdps/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getTMPEans = (cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/getTMPEans`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getContracts = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/getContracts/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const checkSdp = (company_id, sdp, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/checkSdp/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sdp)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const addSdp = (company_id, sdp, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/addSdp/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sdp)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getFeesAndPrices = (company_id, contract_params, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/getFeesAndPrices/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contract_params)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getFixedFee = (company_id, data, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(`${config.BASE_URL}/api/sdps/getFixedFee/${company_id}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    )}

const feesCalc = (data, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/feesCalc`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const updatePreswitchFull = (company_id, sdp, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/updatePreswitchFull/${company_id}`, 
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sdp)
                }
            ).then(
                (res) => {
                    if(!res.ok) {
                        throw new Error(`HTTP error! status: ${res.status}`);
                    }
                    return res.json();
                }
            ).then(
                (res) => { cb(null, res); }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
};

const updateSdp = (company_id, sdp, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/updateSdp/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sdp)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const addContract = (company_id, contract, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/addContract/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contract)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}


const deleteSdp = (company_id, installation_id, type, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/deleteSdp/${company_id}/${installation_id}/${type}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const removeSdp = (company_id, installation_id, type, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/removeSdp/${company_id}/${installation_id}/${type}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}



const archiveSdp = (company_id, installation_id, type, archived, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/archiveSdp/${company_id}/${installation_id}/${type}/${archived}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const updateContract = (company_id, contract, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/updateContract/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contract)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}


const updateAllContracts = (company_id, contract, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/updateAllContracts/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contract)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const renewContract = (company_id, contract, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/renewContract/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contract)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}


const removeContract = (company_id, installation_id, type, id, cb) => {
    getAccessToken((err, token) => {
        if (err) return cb(err);
        fetch(
            `${config.BASE_URL}/api/sdps/removeContract/${company_id}/${installation_id}/${type}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then(
            (res) => {
                res.json().then(
                    (res) => { cb(null, res); }
                )
            }
        ).catch(
            (err) => { return cb(err); }
        );
    });
}

const addVregIds = (company_id, ean, vreg_id, cb) => {

    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/sdps/addVregIds/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ean, vreg_id })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

export {
    getFeesAndPrices,
    getFixedFee,
    getDgos,
    getSdps,
    getTMPEans,
    getContracts,
    archiveSdp,
    checkSdp,
    addSdp,
    updatePreswitchFull,
    updateSdp,
    addContract,
    deleteSdp,
    removeSdp,
    updateContract,
    updateAllContracts,
    renewContract,
    removeContract,
    addVregIds,
    feesCalc
};
