import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone'

import LocalizedStrings from '../../components/Localization';

import { getShardedMetervalues, getMetervaluesInjectionHourly, getMetervaluesOfftakeHourly, getMetervaluesProductionHourly } from '../../api/metervalues';


moment.tz.setDefault('Europe/Brussels')


class DownloadButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            aggregation: undefined,
            loading: false
        }

        this.csvLink = React.createRef()

        this.getQhData = this.getQhData.bind(this);
        this.getData = this.getData.bind(this);
        this.formatData = this.formatData.bind(this);
        this.getAggregation = this.getAggregation.bind(this);

        this.strings = LocalizedStrings({
            en: {
                ean: "EAN",
                datetime: "Datetime (Europe/Brussels)",
                datetimeUTC: "Datetime (UTC)",
                energy: "Energy (kWh)",
                price: "Price (EUR/MWh)",
                revenueCost: "Revenue/Cost (EUR)",
                peakPower: "Peak Power (kW)",
                ppTimestamp: "Time of Peak Power",
                download: "Download",
                qh: "Quarter Hour values",
                hourly: "Hourly values",
                aggregated: "Aggregated values"
            },
            nl: {
                ean: "EAN",
                datetime: "Datum en tijdstip (Europa/Brussel)",
                datetimeUTC: "Datum en tijdstip (UTC)",
                energy: "Energie (kWh)",
                price: "Prijs (EUR/MWh)",
                revenueCost: "Opbrengst/kosten (EUR)",
                peakPower: "Piekvermogen (kW)",
                ppTimestamp: "Tijd van piekvermogen",
                download: "Download",
                qh: "Kwartuurwaarden",
                hourly: "Uurwaarden",
                aggregated: "Geaggregeerde waarden"
            }
        }, this.props.lang);
    }

    getQhData() {

        this.setState({ loading: true })

        getShardedMetervalues(
            this.props.selectedCompany.company_id,
            this.props.sdp.map(x => x.ean),
            this.props.isInjection ? 'injection' : this.props.isProduction ? 'production' : 'offtake',
            this.props.from,
            this.props.to,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    data: res.map(x => {
                        return {
                            [this.strings.ean]: x.ean,
                            [this.strings.datetimeUTC]: x.dtutc,
                            [this.strings.datetime]: x.dtlt,
                            [this.strings.energy]: x.mv
                        }
                    }),
                    aggregation: "QH",
                    loading: false
                }, () => {
                    setTimeout(() => {
                        this.csvLink.current.link.click()
                    }, 0);
                });
            }
        );
    }

    getData(hourly) {

        this.setState({ loading: true })

        if (this.props.isInjection) {
            getMetervaluesInjectionHourly(
                this.props.selectedCompany.company_id,
                this.props.sdp.map(x => x.ean),
                this.props.from,
                this.props.to,
                true, // grouped
                hourly || false, // hourly
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        data: this.formatData(res.mv),
                        aggregation: res.aggregation,
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.csvLink.current.link.click()
                        }, 0);
                    });
                }
            );
        } else if (this.props.isProduction) {
            getMetervaluesProductionHourly(
                this.props.selectedCompany.company_id,
                this.props.sdp.map(x => x.ean),
                this.props.from,
                this.props.to,
                true, // grouped
                hourly || false, // hourly
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        data: this.formatData(res.mv),
                        aggregation: res.aggregation,
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.csvLink.current.link.click()
                        }, 0);
                    });
                }
            );
        } else {
            getMetervaluesOfftakeHourly(
                this.props.selectedCompany.company_id,
                this.props.sdp.map(x => x.ean),
                this.props.from,
                this.props.to,
                true, // grouped
                hourly || false, // hourly
                (err, res) => {
                    if (err)
                        return window.reportError.send(err);

                    this.setState({
                        data: this.formatData(res.mv),
                        aggregation: res.aggregation,
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.csvLink.current.link.click()
                        }, 0);
                    });
                }
            );
        }
    }

    formatData(input) {

        return input.map(x => {
            return {
                [this.strings.ean]: x.ean,
                [this.strings.datetime]: x.dtlt,
                [this.strings.energy]: x.mv,
                [this.strings.price]: x.price,
                [this.strings.revenueCost]: x.pl,
                [this.strings.peakPower]: x.max_mv,
                [this.strings.ppTimestamp]: x.max_mv_dtlt
            }
        })
    }

    getAggregation() {
        switch (this.state.aggregation || this.props.aggregation) {
            case 'QH':
                return 'quarter-hour'

            case 'H':
                return 'hourly'

            case 'D':
                return 'daily-averages'

            case 'W':
                return 'weekly-averages'

            case 'M':
                return 'monthly-averages'

            default:
                break;
        }
    }

    render() {
        if (!this.props.metervalues || !this.props.allSdps || !this.props.allSdps.length) {
            return <div />
        }

        if (!this.props.metervalues.length) {
            return <div />
        }

        if (this.state.loading) {
            return <i className="fas fa-spinner fa-spin" />
        }

        return (
            <div>
                <div className="notifications-dropdown">
                    <div className="btn bg-white text-left text-primary font-weight-light cursor-default" style={{ fontSize: '1rem' }}>
                        <i className="fas fa-download mr-2"></i> {this.strings.download}
                    </div>

                    <div className="notifications-menu download-menu">
                        <button type="button" className="btn text-left w-100 pl-4 py-3" value="0" onClick={() => this.getQhData()}>
                            {this.strings.qh}
                        </button>
                        <button type="button" className="btn text-left w-100 px-4 py-3" value="1" onClick={() => this.getData(true)}>
                            {this.strings.hourly}
                        </button>
                        <button type="button" className="btn text-left w-100 px-4 py-3" value="2" onClick={() => this.getData(false)}>
                            {this.strings.aggregated}
                        </button>
                    </div>
                </div>

                <CSVLink
                    className="d-none"
                    data={this.state.data}
                    separator=";"
                    filename={`Yuso-${this.getAggregation()}-${this.props.isInjection ? 'injection' : this.props.isProduction ? 'production' : 'supply'}-report-${this.props.selectedCompany.companyName.replace(" ", "-")}-${this.props.selectedCompany.companyNumber}-${moment().format('YYYYMMDDHHmm')}.csv`}
                    header={[this.strings.ean, this.strings.datetime, this.strings.energy, this.strings.price, this.strings.revenueCost]}
                    ref={this.csvLink}
                />
            </div>
        );
    }
}

export default DownloadButton;
