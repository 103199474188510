import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Item extends Component {
    render () {
        return (
            <li className={'nav-item ' + (this.props.href === window.reactStorage.getItem('activePage') ? 'active' : '')}><Link className="nav-link" to={this.props.href}>{this.props.name}</Link></li>
        );
    }
}

export default Item
