import config from './config';
import { getAccessToken } from './user';

const getMandates = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/getMandates/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const mandatesStatus = (company_number, ean, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/mandatesStatus/${company_number}/${ean}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const mandateRequest = (company_id, info, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/mandateRequest/${company_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const shortURL = (company_id, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/shortURL/${company_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const mandateUpdate = (mandate, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/mandateUpdate`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(mandate)
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

const getMandateEANs = (company_number, mandate_file, cb) => {
    getAccessToken(
        (err, token) => {
            if (err) return cb(err);
            fetch(
                `${config.BASE_URL}/api/mandates/getMandateEANs/${company_number}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ mandate_file })
                }
            ).then(
                (res) => {
                    res.json().then(
                        (res) => { cb(null, res); }
                    )
                }
            ).catch(
                (err) => { return cb(err); }
            );
        }
    );
}

export {
    getMandates,
    mandatesStatus,
    mandateRequest,
    shortURL,
    mandateUpdate,
    getMandateEANs
};
